import React, { useMemo } from "react";
import { RateCardContext } from "./RateCardContext";
import { Column, Table } from "tenaissance/components/Table";
import { Schema } from "../Schema";
import { Toggle } from "tenaissance/components/Toggle";
import { renderDateTimeInUTC } from "lib/time";
import { dayjs } from "lib/dayjs";
import { ProductListItem } from "types/generated-graphql/__types__";
import { Button } from "tenaissance/components/Button";
import { useNavigate } from "react-router-dom";
import { urlPrefix } from "./RateCardCreateV2";
import { IconButton } from "tenaissance/components/IconButton";
import { v4 as uuid } from "uuid";
import { RateInput } from "./RateInput";
import { PopoverMenu } from "components/PopoverMenu";
import { Icon } from "tenaissance/components/Icon";

export type ProductTypename = NonNullable<ProductListItem["__typename"]>;
const productsMap = new Map<
  string,
  { id: string; name: string; typename: ProductTypename }
>([
  [
    `pr-1`,
    { id: `pr-1`, name: `Input tokens`, typename: "UsageProductListItem" },
  ],
  [
    `pr-2`,
    { id: `pr-2`, name: `Output tokens`, typename: "UsageProductListItem" },
  ],
  [
    `pr-3`,
    { id: `pr-3`, name: `Images tokens`, typename: "UsageProductListItem" },
  ],
  [`pr-3`, { id: `pr-3`, name: `Images`, typename: "UsageProductListItem" }],
  [
    `pr-4`,
    { id: `pr-4`, name: `Support`, typename: "CompositeProductListItem" },
  ],
  [
    `pr-5`,
    { id: `pr-5`, name: `Seats`, typename: "SubscriptionProductListItem" },
  ],
  [
    "pr-6",
    {
      id: "pr-6",
      name: "Instance time for dedicated resources",
      typename: "UsageProductListItem",
    },
  ],
]);

interface RateRow {
  id: string;
  product: {
    id: string;
    name: string;
  };
  rate: Schema.Types.Rate;
}

export const Step3: React.FC = () => {
  const navigate = useNavigate();
  const { ctrl, fiatCreditTypes, customCreditTypes } =
    RateCardContext.useContainer();
  const rates = ctrl.get("rates") || [];

  const onAddRate = (
    previousRate: Schema.Types.Rate,
    update: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
  ) => {
    const rates = ctrl.get("rates") || [];
    const originalIndex = rates.findIndex((r) => r.id === previousRate.id);

    ctrl.update({
      rates: [
        ...rates.slice(0, originalIndex),
        update,
        ...rates.slice(originalIndex),
      ],
    });
  };

  const onEditRate = (
    update: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
  ) => {
    const rates = ctrl.get("rates") || [];
    const originalIndex = rates.findIndex((r) => r.id === update.id);
    if (originalIndex >= 0) {
      ctrl.update({
        rates: [
          ...rates.slice(0, originalIndex),
          update,
          ...rates.slice(originalIndex + 1),
        ],
      });
    }
  };

  const onRemoveRate = (
    update: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
  ) => {
    const rates = ctrl.get("rates") || [];
    const originalIndex = rates.findIndex((r) => r === update);
    if (originalIndex >= 0) {
      ctrl.update({
        rates: [
          ...rates.slice(0, originalIndex),
          ...rates.slice(originalIndex + 1),
        ],
      });
    }
  };

  /**
   * Remove all the rates for the given producp
   * @param productId product ID to remove rates for
   */
  const onRemoveProduct = (productId: string) => {
    const rates = ctrl.get("rates") || [];
    const startIndex = rates.findIndex((r) => r.productId === productId);
    const endIndex = rates.findLastIndex((r) => r.productId === productId);
    if (startIndex >= 0 && endIndex >= 0) {
      ctrl.update({
        rates: [...rates.slice(0, startIndex), ...rates.slice(endIndex + 1)],
      });
    }
  };

  const productRateColumns: Column<RateRow>[] = useMemo(
    () => [
      {
        id: "1",
        header: "Product",
        accessorKey: "product.name",
        supportingText: (row) =>
          row.rate.pricingGroupValues
            ? Object.values(row.rate.pricingGroupValues).join(", ")
            : "",
        cell: (props) => props.getValue(),
        enableSorting: false,
      },
      {
        id: "2",
        header: () => {
          return (
            <div className="flex flex-row items-center gap-4">
              <IconButton
                icon="edit01"
                theme="tertiary"
                size="sm"
                onClick={() => {
                  console.log("edit");
                }}
              />
              <div>Starting at (UTC)*</div>
            </div>
          );
        },
        enableSorting: false,
        accessorFn: (props) => props,
        cell: (props: { getValue: () => RateRow }) => {
          const row = props.getValue();
          return row.rate.startingAt
            ? renderDateTimeInUTC(dayjs.utc(row.rate.startingAt).toDate(), true)
            : "--";
        },
      },
      {
        id: "3",
        header: () => {
          return (
            <div className="flex flex-row items-center gap-4">
              <IconButton
                icon="edit01"
                theme="tertiary"
                size="sm"
                onClick={() => {
                  console.log("edit");
                }}
              />
              <div>Ending before (UTC)</div>
            </div>
          );
        },
        enableSorting: false,
        accessorFn: (props) => props,
        cell: (props: { getValue: () => RateRow }) => {
          const row = props.getValue();
          return row.rate.endingBefore
            ? renderDateTimeInUTC(
                dayjs.utc(row.rate.endingBefore).toDate(),
                true,
              )
            : "--";
        },
      },
      {
        id: "4",
        header: "Entitlement*",
        enableSorting: false,
        accessorFn: (props) => props,
        cell: (props: { getValue: () => RateRow }) => {
          const row = props.getValue();
          return (
            <Toggle
              toggled={row.rate.entitled === "enable"}
              onChange={() => {
                const entitled =
                  row.rate.entitled === "enable" ? "disable" : "enable";
                onEditRate({
                  ...row.rate,
                  entitled,
                });
              }}
              label=""
            />
          );
        },
      },
      {
        id: "5",
        header: "Rate*",
        enableSorting: false,
        accessorFn: (props) => props,
        cell: (props: { getValue: () => RateRow }) => {
          const row = props.getValue();
          return (
            <RateInput
              rate={row.rate}
              fiatCreditTypes={fiatCreditTypes}
              customCreditTypes={customCreditTypes}
              onChange={() => {
                // TODO(ekaragiannis - use form controller here)
              }}
            />
          );
        },
      },
      {
        id: "6",
        header: "",
        isDisplay: true,
        accessorFn: (props) => props,
        cell: (props: { getValue: () => RateRow }) => {
          const row = props.getValue();
          const index = rates.findIndex((r) => r === row.rate);
          const isFirstRate =
            index <= 0 ||
            rates[index - 1].productId !== row.rate.productId ||
            rates[index - 1].pricingGroupValues !== row.rate.pricingGroupValues;

          const hasTiers = row.rate.price.type === "tiered";
          return (
            <div className="flex flex-row gap-4">
              {isFirstRate ? (
                <IconButton
                  onClick={() => {
                    onAddRate(row.rate, {
                      ...row.rate,
                      id: uuid(),
                    });
                  }}
                  icon="calendarPlus02"
                  theme="tertiary"
                />
              ) : (
                <IconButton
                  onClick={() => {
                    onRemoveRate(row.rate);
                  }}
                  icon="calendarMinus02"
                  theme="tertiary"
                  className="text-error-500"
                />
              )}
              <PopoverMenu
                positions={["bottom"]}
                align="end"
                options={[
                  {
                    content: (
                      <span className="p-4 text-sm font-semibold text-gray-700">
                        Rate options
                      </span>
                    ),
                    underlined: true,
                    disabled: true,
                  },
                  {
                    content: (
                      <div className="flex flex-row items-center gap-8 p-4 text-sm font-medium text-gray-800">
                        <Icon
                          icon="dotpoints01"
                          size={20}
                          className="text-gray-800"
                        />
                        {hasTiers ? "Remove tiers" : "Add tiers"}
                      </div>
                    ),
                    underlined: true,
                    onClick: () => {
                      // toggles from flat to tiered and vice versa
                      let newPrice: Schema.Types.Rate["price"];
                      if (row.rate.price.type === "flat") {
                        newPrice = {
                          type: "tiered",
                          tiers: [
                            {
                              unitPrice: row.rate.price.price,
                            },
                          ],
                        };
                      } else if (row.rate.price.type === "tiered") {
                        newPrice = {
                          type: "flat",
                          price: row.rate.price.tiers[0].unitPrice,
                        };
                      } else {
                        return;
                      }

                      onEditRate({
                        ...row.rate,
                        price: newPrice,
                      });
                    },
                  },
                  {
                    content: (
                      <div className="flex flex-row items-center gap-8 p-4 text-sm font-medium text-gray-600">
                        <Icon
                          icon="trash01"
                          size={20}
                          className="text-gray-800"
                        />
                        Remove product
                      </div>
                    ),
                    underlined: true,
                    onClick: () => {
                      onRemoveProduct(row.rate.productId);
                    },
                  },
                ]}
              >
                {(onClick) => (
                  <div onClick={onClick}>
                    <IconButton theme="tertiary" icon="dotsVertical" />
                  </div>
                )}
              </PopoverMenu>
            </div>
          );
        },
      },
    ],
    [onAddRate],
  );

  const filterRates = (typename: ProductTypename) =>
    rates
      .filter((r) => {
        const product = productsMap.get(r.productId);
        if (!product) {
          return false;
        }

        return product.typename === typename;
      })
      .map((rate) => {
        const product = productsMap.get(rate.productId);
        return {
          id: rate.id,
          product: {
            id: rate.productId,
            name: product?.name ?? "Unknown",
          },
          rate,
        };
      });

  const usageRates = useMemo(
    () => filterRates("UsageProductListItem"),
    [rates],
  );
  const compositeRates = useMemo(
    () => filterRates("CompositeProductListItem"),
    [rates],
  );
  const subscriptionRates = useMemo(
    () => filterRates("SubscriptionProductListItem"),
    [rates],
  );

  const dimensionalPricingButton = usageRates.some(
    (r) =>
      r.rate.pricingGroupValues &&
      Object.keys(r.rate.pricingGroupValues).length > 0,
  )
    ? [
        <Button
          theme="secondary"
          text="Dimensional pricing"
          leadingIcon="layersThree01"
          onClick={() => {
            navigate(`${urlPrefix}/dimensional-pricing`);
          }}
        />,
      ]
    : [];

  return (
    <div className="flex flex-col gap-32">
      {usageRates.length > 0 && (
        <Table
          title="Usage"
          data={usageRates}
          columns={productRateColumns}
          topBarActions={dimensionalPricingButton}
        />
      )}
      {compositeRates.length > 0 && (
        <Table
          title="Composite"
          data={compositeRates}
          columns={productRateColumns}
        />
      )}
      {subscriptionRates.length > 0 && (
        <Table
          title="Subscription"
          data={subscriptionRates}
          columns={productRateColumns}
        />
      )}
    </div>
  );
};
