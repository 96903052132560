import React, { useState, useEffect } from "react";

import {
  ProductsDocument,
  ProductsQuery,
  useProductsQuery,
} from "./data/queries.graphql";

import { AvatarWithName, Input, Badge, Tooltip } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { EmptyState } from "components/EmptyState";
import { Filter, OptionType } from "components/Filter";
import { CopyableID } from "components/CopyableID";
import { SearchTooltip } from "components/SearchTooltip";
import ArchiveProductModal from "./components/ArchiveProductModal";
import useDebounce from "lib/debounce";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { renderDate } from "lib/time";
import { useNavigate } from "lib/useNavigate";
import { useSearchParam } from "lib/routes/useSearchParam";
import { PRODUCT_OPTIONS, PRODUCT_OPTIONS_DEFAULT } from "./filters";
import { gatedAction, useAuthCheck } from "lib/useAuthCheck";
import { NewProductDocument } from "pages/NewProduct/queries.graphql";
import { ArchiveProductDocument } from "./components/ArchiveProductModal/queries.graphql";
import { EditProductDocument } from "pages/EditProduct/data/queries.graphql";
import { ProductsContainer } from "./ProductsContainer";
import { Column, SimpleTable } from "components/SimpleTable";
import { MenuItemProps, PopoverMenu } from "components/PopoverMenu";
import { TableSkeleton } from "components/Table";
import pluralize from "pluralize";
import {
  ProductTypeEnum,
  ProductsSortingOrderByEnum,
} from "types/generated-graphql/__types__";
import { GatedButton } from "../../components/GatedButton";
import { useUIMode } from "../../lib/useUIMode";

type Product = ProductsQuery["products"][0];
const NUM_ROWS = 15;

const Products: React.FC = () => {
  const { environmentType } = useEnvironment();

  const [filters, setFilters] = useState<readonly OptionType[]>(
    PRODUCT_OPTIONS_DEFAULT,
  );
  const [productToArchive, setProductToArchive] = useState<Product | null>(
    null,
  );

  const types: ProductTypeEnum[] = [];
  const includeActive = filters.some(
    (f) => f.value === "has_plan" || f.value === "no_plan",
  );
  const includeArchived = filters.some((f) => f.value === "archived");

  if (includeActive) types.push(ProductTypeEnum.Active);
  if (includeArchived) types.push(ProductTypeEnum.Archived);

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useSearchParam("q");
  const debouncedSearchQuery = useDebounce(searchQuery.trim(), 400);

  const [pageNumberToCursor, setPageNumberToCursor] = React.useState<string[]>(
    [],
  );
  const [currentPage, setCurrentPage] = React.useState(0);
  const [currentCursor, setCurrentCursor] = React.useState<string | undefined>(
    undefined,
  );

  const [sortOrder, setSortOrder] = useState<{
    column: string;
    direction: "asc" | "desc";
  } | null>(null);

  useEffect(() => {
    // Reset cursor and page when changing a filter or adding a search param
    setPageNumberToCursor([]);
    setCurrentPage(0);
    setCurrentCursor(undefined);
  }, [debouncedSearchQuery, filters]);

  const { data, loading, error } = useProductsQuery({
    variables: {
      environment_type: environmentType,
      types,
      limit: NUM_ROWS + 1,
      cursor: currentCursor,
      search: debouncedSearchQuery || undefined,
      sort: !sortOrder
        ? undefined
        : {
            order_by:
              {
                name: ProductsSortingOrderByEnum.Name,
                created_by: ProductsSortingOrderByEnum.CreatedBy,
                last_edited: ProductsSortingOrderByEnum.UpdatedAt,
                id: ProductsSortingOrderByEnum.Id,
              }[sortOrder.column] ?? ProductsSortingOrderByEnum.Id,
            ascending: sortOrder.direction === "asc",
          },
    },
    skip: types.length === 0,
  });

  const allProducts = data?.products || [];
  const currentPageProducts = allProducts.slice(0, NUM_ROWS);
  const hasMore = allProducts.length > NUM_ROWS;
  const { newUIEnabled } = useUIMode();

  // We need to filter client-side for "has_plan" and "no_plan" since the
  // graphql query doesn't have active_plan_count to differentiate between the two
  const filteredProducts = currentPageProducts.filter((p) => {
    for (const filterOption of filters) {
      if (filterOption.group === "product_status") {
        switch (filterOption.value) {
          case "has_plan": // "Active"
            if (!p.deprecated_at && p.active_plan_count) return true;
            break;
          case "no_plan": // "Published"
            if (!p.deprecated_at && p.active_plan_count === 0) return true;
            break;
          case "archived":
            if (p.deprecated_at) return true;
            break;
        }
      }
    }
    return false;
  });

  useEffect(() => {
    // This handles an edge case with client-side filtering for "Active / has_plan"
    // and "Published / no_plan". See note on `filteredProducts`
    // ---
    // EDGE CASE: We have full page of results, but there are 0 products matching
    // client-side filter for the current page. There are more pages...
    // In this case, we need to fetch the next page.
    //
    // EXAMPLE:
    //   - Say page size is 2
    //   - Products are: [no_plan_prod1, no_plan_prod2, no_plan_prod3, has_plan_prod4]
    //   - We are filtering client-side for "active / has_plan"
    //   - In this case, page 1 will display 0 results, so we need to trigger next page
    //   - The next page will then display the last item: has_plan_prod4
    if (filteredProducts.length === 0 && hasMore) {
      goNextPage();
    }
  }, [filteredProducts]);

  const goNextPage = () => {
    const newCursor = allProducts[NUM_ROWS - 1].id;
    setPageNumberToCursor({
      ...pageNumberToCursor,
      [currentPage + 1]: newCursor,
    });
    setCurrentPage(currentPage + 1);
    setCurrentCursor(newCursor);
  };

  const goPrevPage = () => {
    const newCursor = pageNumberToCursor[currentPage - 1];
    if (!newCursor) {
      setCurrentPage(0);
      setCurrentCursor(undefined);
    } else {
      setCurrentCursor(newCursor);
      setCurrentPage(currentPage - 1);
    }
  };

  const canArchiveProduct = !!useAuthCheck(ArchiveProductDocument, true)
    .allowed;
  const canEditProduct = !!useAuthCheck(EditProductDocument, true).allowed;

  const getActions = (product: Product) => {
    let actions: MenuItemProps[] = [];
    if (!product) {
      throw new Error("Error creating Product actions");
    }
    if (product.deprecated_at === null) {
      actions = [
        {
          content: "Manage custom fields...",
          onClick: () => navigate(`/custom-fields/product/${product.id}`),
        },
        gatedAction(canEditProduct, {
          content: "Edit product...",
          onClick: () => navigate(`/products/${product.id}/edit`),
        }),
        gatedAction(canArchiveProduct, {
          disabled: !!product.active_plan_count,
          onClick: () => setProductToArchive(product),
          content: product.active_plan_count ? (
            <Tooltip content="Products in use cannot be archived">
              Archive product...
            </Tooltip>
          ) : (
            "Archive product..."
          ),
        }),
      ];
    }
    return actions;
  };

  const archiveModal = productToArchive && (
    <ArchiveProductModal
      onClose={() => setProductToArchive(null)}
      productId={productToArchive.id}
      productName={productToArchive.name}
    />
  );

  let columns: (Column<Product> & { key: string })[] = [
    {
      header: "Product name",
      key: "name",
      sort: sortOrder?.column === "name" ? sortOrder.direction : "none",
      alignment: "top-left",
      /**
       * this strange max-width style ensures that long descriptions
       * are truncated rather than stretching the table cell. 900px
       * is a rough guess at the amount of space necessary for the
       * side nav and the other table columns
       */
      headerClassName: "max-w-[calc(100vw_-_900px)]",
      render: (product) => (
        <>
          <div>
            <CopyableID id={product.id} label="product ID" hideID />
            {product.name}
          </div>
          {product.description && (
            <div
              className="truncate text-xs text-gray-medium"
              title={product.description}
            >
              {product.description}
            </div>
          )}
        </>
      ),
    },
    {
      header: "Plans",
      key: "active_plan_count",
      alignment: "top-right",
      render: (product) =>
        `${product.active_plan_count} ${pluralize(
          "Plan",
          product.active_plan_count,
        )}`,
    },
    {
      header: "Charges",
      key: "charges",
      alignment: "top-right",
      render: (product) => {
        const count =
          product.ProductPricingFactors_aggregate.aggregate?.count ?? 0;
        return `${count} ${pluralize("Charge", count)}`;
      },
    },
    {
      header: "Created by",
      key: "created_by",
      alignment: "top-left",
      sort: sortOrder?.column === "created_by" ? sortOrder.direction : "none",
      render: (product) =>
        product.Actor && (
          <Tooltip
            content={
              <>
                Created by {product.Actor.name}
                <br />
                {renderDate(new Date(product.created_at), {
                  isUtc: false,
                })}
              </>
            }
          >
            <AvatarWithName {...product.Actor} />
          </Tooltip>
        ),
    },
    {
      header: "Last edited",
      key: "last_edited",
      alignment: "top-right",
      sort: sortOrder?.column === "last_edited" ? sortOrder.direction : "none",
      render: (product) =>
        renderDate(new Date(product.updated_at), { isUtc: false }),
    },
    {
      header: "Status",
      key: "status",
      alignment: "top-left",
      render: (product) =>
        product.deprecated_at ? (
          <Badge theme="warning" type="light">
            ARCHIVED
          </Badge>
        ) : product.active_plan_count ? (
          <Badge theme="primary" type="light">
            ACTIVE
          </Badge>
        ) : (
          <Badge theme="success" type="light">
            PUBLISHED
          </Badge>
        ),
    },
    {
      header: "",
      alignment: "top-right",
      key: "actions",
      render: (product) => (
        <PopoverMenu
          positions={["bottom"]}
          align="end"
          options={getActions(product)}
        >
          {(onClick) => (
            <IconButton
              onClick={onClick}
              theme="tertiary"
              icon="dotsVertical"
            />
          )}
        </PopoverMenu>
      ),
    },
  ];

  return (
    <ProductsContainer
      authDoc={ProductsDocument}
      action={
        <div className="flex flex-row items-center">
          <SearchTooltip searchText="products">
            <Input
              type="search"
              placeholder="Search"
              value={searchQuery}
              onChange={setSearchQuery}
              leftIcon="search"
              className="w-[208px]"
            />
          </SearchTooltip>
          <Filter
            value={filters}
            options={PRODUCT_OPTIONS}
            onChange={setFilters}
            onReset={() => setFilters(PRODUCT_OPTIONS_DEFAULT)}
          />
          <GatedButton
            doc={NewProductDocument}
            className="ml-12"
            onClick={() =>
              navigate(`${newUIEnabled ? "/offering" : ""}/products/new`)
            }
            text="Add new product"
            theme="primary"
            leadingIcon="plus"
            size="sm"
          />
        </div>
      }
    >
      {error && (
        <EmptyState
          title="We ran into an issue loading your products"
          subtitle="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
          icon="shoppingCart01"
        />
      )}
      {!error &&
        !loading &&
        filteredProducts.length === 0 &&
        (debouncedSearchQuery.length === 0 &&
        allProducts.length === 0 &&
        includeActive ? (
          <EmptyState
            title="You don't have any products yet."
            subtitle="Once you add products, you'll see their details here."
            buttonAuthDoc={NewProductDocument}
            buttonText="Add new product"
            onClick={() =>
              navigate(`${newUIEnabled ? "/offering" : ""}/products/new`)
            }
            icon="shoppingCart01"
            buttonIcon="plus"
          />
        ) : (
          <EmptyState
            title="No products found"
            subtitle="No products match this filter."
            icon="shoppingCart01"
          />
        ))}
      {loading && (
        <TableSkeleton
          numRows={NUM_ROWS}
          columnNames={columns.map((c) =>
            typeof c.header === "string" ? c.header : "",
          )}
        />
      )}
      {!loading && filteredProducts.length > 0 && (
        <>
          {archiveModal}
          <SimpleTable
            data={filteredProducts}
            rowRoutePath={(product) => `/products/${product.id}`}
            columns={columns}
            onSortClick={(index) => {
              const columnKey = columns[index].key;
              if (sortOrder?.column !== columnKey) {
                setSortOrder({ column: columnKey, direction: "asc" });
              } else if (sortOrder.direction === "asc") {
                setSortOrder({ column: columnKey, direction: "desc" });
              } else {
                setSortOrder(null);
              }
              setCurrentCursor(undefined);
              setPageNumberToCursor([]);
              setCurrentPage(0);
            }}
            paginationButtons={[
              {
                page: "prev",
                disabled: currentPage == 0,
                onClick: () => goPrevPage,
              },
              ...(currentPage > 0
                ? [
                    {
                      page: currentPage,
                      onClick: goPrevPage,
                    },
                  ]
                : []),
              {
                page: currentPage + 1,
                onClick: () => {},
                selected: true,
              },
              ...(hasMore
                ? [
                    {
                      page: currentPage + 2,
                      onClick: goNextPage,
                    },
                  ]
                : []),
              {
                page: "next",
                onClick: goNextPage,
                disabled: !hasMore,
              },
            ]}
          />
        </>
      )}
    </ProductsContainer>
  );
};

export default Products;
