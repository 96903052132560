import classNames from "classnames";
import React from "react";
import { InternalLink } from "components/Typography";

import styles from "./index.module.less";
import { ReactComponent as Image } from "./logo.svg";

type Props = {
  className?: string;
};

export const Logo: React.FC<Props> = (props) => {
  return (
    <InternalLink
      routePath="/"
      className={classNames(styles.link, props.className)}
    >
      <Image />
    </InternalLink>
  );
};
