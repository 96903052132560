import React from "react";
import { TextSkeleton } from "components/Skeleton";
import { useCustomerCountForPlanQuery } from "pages/Plans/data/queries.graphql";
import { Badge } from "design-system";

const StatusCell: React.FC<{ planId: string }> = ({ planId }) => {
  const { data, loading, error } = useCustomerCountForPlanQuery({
    variables: { plan_id: planId },
  });

  if (loading) {
    return <TextSkeleton />;
  }
  if (error) {
    return <span>-</span>;
  }
  const customerCount = data?.Plan_by_pk?.customer_count ?? 0;
  if (customerCount > 0) {
    return (
      <Badge type="light" theme="primary">
        ACTIVE
      </Badge>
    );
  } else {
    return (
      <Badge type="light" theme="success">
        PUBLISHED
      </Badge>
    );
  }
};

export default StatusCell;
