import React from "react";
import { Navigate, RouteObject } from "react-router-dom";

import { useAuth } from "lib/auth";

import APITokens from "pages/APITokens";
import Team from "pages/Team";
import { BillableMetricRoutes } from "pages/BillableMetric";
import BillableMetrics from "pages/BillableMetrics";
import Seats from "pages/BillableMetrics/seats";
import { GeneralSettingsRoute } from "pages/GeneralSettings";
import StripeRedirect from "pages/StripeRedirect";
import NotFoundPage from "pages/404";
import Products from "pages/Products";
import Product from "pages/Product";
import { NewMetricRoutes } from "pages/NewBillableMetric";
import NewProduct from "pages/NewProduct";
import EditProduct from "pages/EditProduct";
import Plans from "pages/Plans";
import { PlanRoute } from "pages/Plan";
import NewPlan from "pages/PlanWizards/NewPlan";
import EditPlan from "pages/PlanWizards/EditPlan";
import Events from "pages/Events";
import Webhooks from "pages/Webhooks";
import Account from "pages/Account";
import Alerts from "pages/Alerts";
import Alert from "pages/Alert";
import NewAlert from "pages/NewAlert";
import Reports from "pages/Reports";
import NewDataExportDestination from "pages/NewDataExportDestination";
import UpdateDataExportDestination from "pages/UpdateDataExportDestination";
import CustomFields from "pages/CustomFields";
import { EnvSlug$ } from "lib/environmentSwitcher/context";
import { ContractCustomersRoutes } from "pages/Contracts/ContractRoutes";
import { CensusDashboard } from "pages/GeneralSettings/CensusDashboard";
import { CensusDestinationTypeEnum } from "./types/generated-graphql/__types__";

const Logout: React.FC = () => {
  const auth = useAuth();
  auth.logout();
  return null;
};

const ENV_SLUGS = ["/sandbox", "/env-uat", "/env-qa", "/env-staging"];

/**
 * Routes that exist both in and out of sandbox mode.
 */
const EnvRoutes: RouteObject[] = [
  ...ContractCustomersRoutes,

  GeneralSettingsRoute,
  { path: "settings/stripe/redirect_callback/*", element: <StripeRedirect /> },
  { path: "developer/events/*", element: <Events /> },
  { path: "developer/tokens", element: <APITokens /> },
  { path: "developer/webhooks", element: <Webhooks /> },
  { path: "products", element: <Products /> },
  { path: "products/new", element: <NewProduct /> },
  { path: "products/:id/edit", element: <EditProduct /> },
  { path: "products/:id", element: <Product /> },
  { path: "billable-metrics", element: <BillableMetrics /> },
  { path: "billable-metrics/seats", element: <Seats /> },
  ...NewMetricRoutes,
  ...BillableMetricRoutes,
  { path: "plans", element: <Plans /> },
  { path: "plans/new/:id?", element: <NewPlan /> },
  { path: "plans/edit/:id", element: <EditPlan /> },
  PlanRoute,
  { path: "alerts", element: <Alerts /> },
  { path: "alerts/new", element: <NewAlert /> },
  { path: "alerts/:id", element: <Alert /> },
  { path: "reports", element: <Reports /> },
  { path: "custom-fields/:entity/:id", element: <CustomFields /> },
  // Data Export edit.  Yes data export can only be done once per client, but confluent
  // has a special client which doesnt have the PRODUCTION metronome env enabled
  // so this 404s.  But they also cant run the one at root because then it will use
  // the production scoped jwt, which doesnt have perms to data export :(
  {
    path: "settings/general/integrations/data-export/update/:destinationId",
    element: <UpdateDataExportDestination />,
  },
  {
    path: "settings/general/integrations/salesforce",
    element: (
      <CensusDashboard destinationType={CensusDestinationTypeEnum.Salesforce} />
    ),
  },
  {
    path: "settings/general/integrations/salesforce/incomplete",
    element: (
      <CensusDashboard
        destinationType={CensusDestinationTypeEnum.Salesforce}
        showIncompleteSyncs
      />
    ),
  },
];

export const AppRoutes: RouteObject[] = [
  /* --------------------------------------------------- */
  /* Redirects from old pages to new pages */
  /* --------------------------------------------------- */
  {
    path: "/developer/integration-pipeline/*",
    element: <Navigate to="/developer/events" replace={true} />,
  },
  {
    path: "/sandbox/developer/integration-pipeline/*",
    element: <Navigate to="/developer/events" replace={true} />,
  },
  {
    path: "/settings/billing-configuration",
    element: <Navigate to="/settings/general/integrations" replace={true} />,
  },
  {
    path: "/settings/billing-configuration/billing-providers",
    element: <Navigate to="/settings/general/integrations" replace={true} />,
  },
  {
    path: "/settings/billing-configuration/pricing-units",
    element: <Navigate to="/settings/general/pricing-units" replace={true} />,
  },
  {
    path: "/settings/stripe",
    element: <Navigate to="/settings/general/integrations" replace={true} />,
  },

  /* --------------------------------------------------- */
  /* Routes that don't exist in sandbox mode */
  /* --------------------------------------------------- */
  {
    path: "/settings/general/integrations/data-export/new",
    element: <NewDataExportDestination />,
  },
  { path: "/settings/team", element: <Team /> },
  { path: "/account", element: <Account /> },
  { path: "/logout", element: <Logout /> },

  /* --------------------------------------------------- */
  /* Routes that exist in and outside environments */
  /* --------------------------------------------------- */
  ...ENV_SLUGS.map(
    (slug): RouteObject => ({
      path: `${slug}/*`,
      handle: {
        [EnvSlug$]: slug,
      },
      children: [...EnvRoutes, { path: "*", element: <NotFoundPage /> }],
    }),
  ),
  // include the default "PRODUCTION" environment
  {
    path: "/*",
    handle: {
      [EnvSlug$]: "",
    },
    children: [...EnvRoutes, { path: "*", element: <NotFoundPage /> }],
  },
];
