/* 5961c6f2f29b9042ba54a28f7bf0b49254aa3731
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillableMetricDetailQueryVariables = Types.Exact<{
  billable_metric_id: Types.Scalars['uuid'];
}>;


export type BillableMetricDetailQuery = { __typename?: 'Query', BillableMetric: { __typename?: 'BillableMetric', id: string, name: string, aggregate: Types.BillingMetricAggregateEnum_Enum, aggregate_keys: object | null, group_keys: object | null, filter: object, created_at: string, is_draft: boolean, deleted_at: string | null, active_plan_count: number, active_product_count: number, sql: string | null, Creator: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null } | null };

export type BillableMetricDetailsFragment = { __typename?: 'BillableMetric', id: string, name: string, aggregate: Types.BillingMetricAggregateEnum_Enum, aggregate_keys: object | null, group_keys: object | null, filter: object, created_at: string, is_draft: boolean, deleted_at: string | null, active_plan_count: number, active_product_count: number, sql: string | null, Creator: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null };

export type SetBillableMetricNameMutationVariables = Types.Exact<{
  billable_metric_id: Types.Scalars['uuid'];
  name: Types.Scalars['String'];
}>;


export type SetBillableMetricNameMutation = { __typename?: 'Mutation', set_billable_metric_name: { __typename?: 'BillableMetric', id: string, name: string } };

export const BillableMetricDetailsFragmentDoc = gql`
    fragment BillableMetricDetails on BillableMetric {
  id
  name
  aggregate
  aggregate_keys
  group_keys
  filter
  created_at
  is_draft
  deleted_at
  Creator {
    name
    id
    deprecated_at
  }
  active_plan_count
  active_product_count
  sql
  __environment_type: environment_type
}
    `;
export const BillableMetricDetailDocument = gql`
    query BillableMetricDetail($billable_metric_id: uuid!) {
  BillableMetric: BillableMetric_by_pk(id: $billable_metric_id) {
    id
    ...BillableMetricDetails
    __environment_type: environment_type
  }
}
    ${BillableMetricDetailsFragmentDoc}`;

/**
 * __useBillableMetricDetailQuery__
 *
 * To run a query within a React component, call `useBillableMetricDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillableMetricDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillableMetricDetailQuery({
 *   variables: {
 *      billable_metric_id: // value for 'billable_metric_id'
 *   },
 * });
 */
export function useBillableMetricDetailQuery(baseOptions: Apollo.QueryHookOptions<BillableMetricDetailQuery, BillableMetricDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillableMetricDetailQuery, BillableMetricDetailQueryVariables>(BillableMetricDetailDocument, options);
      }
export function useBillableMetricDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillableMetricDetailQuery, BillableMetricDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillableMetricDetailQuery, BillableMetricDetailQueryVariables>(BillableMetricDetailDocument, options);
        }
export type BillableMetricDetailQueryHookResult = ReturnType<typeof useBillableMetricDetailQuery>;
export type BillableMetricDetailLazyQueryHookResult = ReturnType<typeof useBillableMetricDetailLazyQuery>;
export type BillableMetricDetailQueryResult = Apollo.QueryResult<BillableMetricDetailQuery, BillableMetricDetailQueryVariables>;
export const SetBillableMetricNameDocument = gql`
    mutation SetBillableMetricName($billable_metric_id: uuid!, $name: String!) {
  set_billable_metric_name(id: $billable_metric_id, name: $name) {
    id
    name
    __environment_type: environment_type
  }
}
    `;
export type SetBillableMetricNameMutationFn = Apollo.MutationFunction<SetBillableMetricNameMutation, SetBillableMetricNameMutationVariables>;

/**
 * __useSetBillableMetricNameMutation__
 *
 * To run a mutation, you first call `useSetBillableMetricNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBillableMetricNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBillableMetricNameMutation, { data, loading, error }] = useSetBillableMetricNameMutation({
 *   variables: {
 *      billable_metric_id: // value for 'billable_metric_id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSetBillableMetricNameMutation(baseOptions?: Apollo.MutationHookOptions<SetBillableMetricNameMutation, SetBillableMetricNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBillableMetricNameMutation, SetBillableMetricNameMutationVariables>(SetBillableMetricNameDocument, options);
      }
export type SetBillableMetricNameMutationHookResult = ReturnType<typeof useSetBillableMetricNameMutation>;
export type SetBillableMetricNameMutationResult = Apollo.MutationResult<SetBillableMetricNameMutation>;
export type SetBillableMetricNameMutationOptions = Apollo.BaseMutationOptions<SetBillableMetricNameMutation, SetBillableMetricNameMutationVariables>;