import React, { useState } from "react";
import { Toggle } from "design-system";
import { StripeSettingsToggleProps } from ".";

export const LeaveInvoicesInDraftToggle: React.FC<
  StripeSettingsToggleProps
> = ({ isChecked, handleToggleOnChange }) => {
  const [isCheckedValue, setIsCheckedValue] = useState(isChecked);

  return (
    <>
      <Toggle
        checked={isCheckedValue}
        label="Leave invoices as drafts"
        onChange={(value) =>
          handleToggleOnChange(
            value,
            "leave_invoices_in_draft",
            setIsCheckedValue,
          )
        }
      />
      <div className="leading-tight text-sm font-normal text-[#475466]">
        You will have to manually advance them before collection
      </div>
    </>
  );
};
