import React from "react";

interface Props {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  withIndicator?: boolean;
}

export const CellWithSubtitle: React.FC<Props> = ({
  title,
  subtitle,
  withIndicator,
}) => {
  const titles = (
    <>
      <div className="text-xs font-medium">{title}</div>
      <div className="text-xxs font-normal text-gray-medium">{subtitle}</div>
    </>
  );

  if (!withIndicator) {
    return titles;
  }

  return (
    <div className="flex">
      <div className="mr-8 grow-0 rounded-small bg-primary-200 p-[2px]" />
      <div className="grow">{titles}</div>
    </div>
  );
};
