import { ReportConfig } from "../..";
import { dayjs } from "lib/dayjs";
import {
  BillingProviderInvoiceDocument,
  BillingProviderInvoiceQuery,
} from "./queries.graphql";
import { DEFAULT_PAGE_SIZE, REPORT_DATE_FORMAT } from "../consts";

const report: ReportConfig<BillingProviderInvoiceQuery> = {
  name: "Billing Provider Invoices",
  needsDates: true,
  needsEnvironment: false,
  pageSize: DEFAULT_PAGE_SIZE,
  dataToCSV: (pages) => {
    const header = [
      "Billing provider",
      "Billing provider error",
      "Billing provider invoice ID",
      "Due date",
      "External status",
      "Invoice ID",
      "Customer ID",
      "Issued at",
    ];

    const rows = pages.flatMap((page) =>
      page.BillingProviderInvoice.map((billingProviderInvoice) => {
        return [
          billingProviderInvoice.billing_provider,
          billingProviderInvoice.billing_provider_error ?? "",
          billingProviderInvoice.billing_provider_invoice_id ?? "",
          billingProviderInvoice.due_date
            ? dayjs
                .utc(billingProviderInvoice.due_date)
                .format(REPORT_DATE_FORMAT)
            : "",
          billingProviderInvoice.external_status ?? "",
          billingProviderInvoice.invoice_id,
          billingProviderInvoice.customer?.id ?? "",
          dayjs
            .utc(billingProviderInvoice.issued_at)
            .format(REPORT_DATE_FORMAT),
        ];
      }),
    );
    return [header, ...rows];
  },
  queryDocument: BillingProviderInvoiceDocument,
  isAllowedForUser: (ldClient) =>
    ldClient
      ?.variation("available-report-types", [])
      .includes("BILLING_PROVIDER_INVOICES"),
  nextCursor: (page) => page.BillingProviderInvoice.at(-1)?.id ?? null,
};

export default report;
