import React from "react";
import styles from "./index.module.less";
import { Copy } from "components/Copy";
import { Icon, Tooltip } from "design-system";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/components/prism-sql";
import "prismjs/themes/prism-tomorrow.css";

type CodeBlockProps = {
  code: string;
  title: string;
  language: "json" | "sql";
  onChange?: (code: string) => void;
  minWidth?: number;
  readOnly?: boolean;
  error?: string | boolean;
  valid?: boolean;
  formatFn?: (code: string) => void;
};

const PIXELS_PER_CHARACTER = 9;

export const CodeBlock: React.FC<CodeBlockProps> = ({
  code,
  title,
  onChange,
  minWidth,
  readOnly,
  language,
  formatFn,
}) => {
  const lines = code.split("\n");
  /* Avoids text wrapping */
  const width: number = lines.reduce((maxLineLength, line) => {
    return Math.max(
      line.length * PIXELS_PER_CHARACTER,
      maxLineLength,
      minWidth ?? 500,
    );
  }, 0);

  const highlightWithLineNumber = (code: string): React.ReactNode => {
    return highlight(code, languages[language], language)
      .split("\n")
      .map(
        (line, i) =>
          `<span><span class="lineNumber">${i + 1}</span>${line}</span>`,
      )
      .join("\n");
  };
  return (
    <div className={styles.codeBlock}>
      <div className={styles.titleBar}>
        {title}
        <div className={styles.rightBar}>
          {formatFn && (
            <a onClick={() => formatFn(code)} className={styles.copyPayload}>
              <Tooltip content="Format payload">
                <Icon icon="documentText" />
              </Tooltip>
            </a>
          )}
          <Copy text={code}>
            <a className={styles.copyPayload}>
              <Tooltip content="Copy payload">
                <Icon icon="copy" />
              </Tooltip>
            </a>
          </Copy>
        </div>
      </div>
      <div className={styles.codeEditor}>
        <div style={{ minWidth: width }}>
          <div className={styles.editorDivider}>
            <div className={styles.editorDividerLineNumber} />
          </div>
          <Editor
            value={code}
            onValueChange={
              !readOnly && onChange ? (code) => onChange(code) : () => {}
            }
            highlight={highlightWithLineNumber}
            className="editor"
            readOnly={readOnly}
          />
          <div className={styles.editorDivider}>
            <div className={styles.editorDividerLineNumber} />
          </div>
        </div>
      </div>
    </div>
  );
};
