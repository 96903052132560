import React from "react";
import Decimal from "decimal.js";

import { useSnackbar } from "components/Snackbar";
import { CreditMetric } from "components/CreditMetric";
import { TextSkeleton } from "components/Skeleton";
import { useApolloResp } from "pages/Contracts/lib/ApolloResp";
import NotFoundPage from "pages/404";
import { Commit } from "pages/Contracts/lib/Commit";
import { useCustomerFromRoute } from "pages/Contracts/lib/Customer";

import { Breadcrumbs } from "lib/breadcrumbs";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { useNavigate } from "lib/useNavigate";
import { getUtcStartOfDay } from "lib/time";
import { UserFacingError } from "lib/errors/errorHandling";
import { USD_CREDIT_TYPE, displayCreditTypeName } from "lib/credits";

import { CommitsOverview } from "../Overview/CommitsOverview";
import { AsyncCommitUsage, useAsyncAllCommitUsage } from "../Usage";
import { CommitFlyover } from "../Create/Sections/Commits";
import { DefaultTimeframe } from "../Create/lib/DefaultTimeframe";
import {
  CommitType,
  ExternalCommitType,
} from "types/generated-graphql/__types__";
import { formatBillingSchedule } from "../Create/convertFormToMutationVars";
import { CustomerLayout } from "../../CustomerLayout";

import {
  CommitDetailsFragment,
  useAllCommitsAndCustomCreditTypesQuery,
  useCreateCustomerCommitMutation,
} from "./data.graphql";
import {
  filterAndSortCreditTypes,
  findCreditType,
  isFiat,
} from "pages/Contracts/lib/CreditTypes";
import { Select } from "design-system";
import { GatedButton } from "../../../../../components/GatedButton";

export const CustomerCommitsOverview: React.FC = () => {
  const req = useCustomerFromRoute();

  return (
    <CustomerLayout
      rootReq={req}
      breadcrumbs={({ customer }) =>
        Breadcrumbs.from(
          {
            type: "back",
            label: "Back to customer list",
            routePath: "/customers",
          },
          {
            label: customer.name,
            routePath: `/customers/${customer.id}`,
          },
        )
      }
      content={({ customer }) => (
        <div className="flex h-full flex-col pt-12">
          <CustomerCommitsContent customerId={customer.id} />
        </div>
      )}
    />
  );
};

const calculateTotals = (
  commits: CommitDetailsFragment[],
  usage: AsyncCommitUsage,
) => {
  if (usage.loading) {
    return {
      loading: true,
      prepaid: new Decimal(0),
      postpaid: new Decimal(0),
      credit: new Decimal(0),
    } as const;
  }

  return commits.reduce(
    (acc, commit) => {
      if (commit.__typename === "PrepaidCommit") {
        const key = commit.external_type === "COMMIT" ? "prepaid" : "credit";
        return {
          ...acc,
          [key]: acc[key].plus(
            usage.forCommit(commit.id)?.activeRemaining ?? new Decimal(0),
          ),
        };
      }

      if (commit.__typename === "PostpaidCommit") {
        return {
          ...acc,
          postpaid: acc.postpaid.plus(
            usage.forCommit(commit.id)?.activeRemaining ?? new Decimal(0),
          ),
        };
      }

      commit satisfies never;
      return acc;
    },
    {
      loading: false,
      prepaid: new Decimal(0),
      postpaid: new Decimal(0),
      credit: new Decimal(0),
    } as const,
  );
};

const CustomerCommitsContent: React.FC<{ customerId: string }> = ({
  customerId,
}) => {
  const navigate = useNavigate();
  const pushMessage = useSnackbar();

  const req = useApolloResp(
    useAllCommitsAndCustomCreditTypesQuery({
      variables: { customerId },
    }),
  );
  const usage = useAsyncAllCommitUsage({ customerId });
  const [createCommit, createCommitMutationResult] =
    useCreateCustomerCommitMutation();

  const [addCommitOpen, setAddCommitOpen] = React.useState<
    false | "commit" | "credit"
  >(false);
  const [selectedCreditTypeId, setSelectedCreditTypeId] =
    React.useState<string>();

  if (req.state === "error") {
    return (
      <ErrorEmptyState
        title="We were unable to load this contract"
        error={req.error}
      />
    );
  }

  if (req.state === "loading") {
    return <TextSkeleton className="mt-12" />;
  }

  if (req.state === "not found") {
    return <NotFoundPage />;
  }

  const allCommits = [
    ...req.customer.contracts.flatMap((c) => c.commits_union),
    ...req.customer.contracts.flatMap((c) =>
      c.amendments.flatMap((a) => a.commits_union),
    ),
    ...req.customer.commits,
  ].filter((c) => !c.contract?.archived_at);

  const allCommitCreditTypeOptions = Array.from(
    new Set(
      allCommits.map((c) => JSON.stringify(c.access_schedule.credit_type)),
    ),
  ).map((ct) => JSON.parse(ct));
  const commitFiatCreditTypeOptions = allCommitCreditTypeOptions.filter((ct) =>
    isFiat(ct),
  );
  const commitCustomCreditTypeOptions = allCommitCreditTypeOptions.filter(
    (ct) => !isFiat(ct),
  );
  if (!selectedCreditTypeId && allCommitCreditTypeOptions.length > 1) {
    setSelectedCreditTypeId(
      (commitFiatCreditTypeOptions[0] ?? commitCustomCreditTypeOptions[0]).id,
    );
  }

  const commitsForCreditType = selectedCreditTypeId
    ? allCommits.filter(
        (c) => c.access_schedule.credit_type.id === selectedCreditTypeId,
      )
    : undefined;
  const totals = calculateTotals(commitsForCreditType ?? allCommits, usage);

  const allCreditTypes = [USD_CREDIT_TYPE, ...req.CreditType];
  const { fiatCreditTypes, customCreditTypes } =
    filterAndSortCreditTypes(allCreditTypes);
  const defaultCreditType = selectedCreditTypeId
    ? findCreditType(selectedCreditTypeId, allCreditTypes)
    : commitFiatCreditTypeOptions[0] ??
      commitCustomCreditTypeOptions[0] ??
      USD_CREDIT_TYPE;

  return (
    <>
      {addCommitOpen && (
        <DefaultTimeframe.Provider
          startingAt={getUtcStartOfDay(new Date()).toISOString()}
        >
          <CommitFlyover
            defaultCreditType={defaultCreditType}
            edit={undefined}
            onClose={() => setAddCommitOpen(false)}
            onSave={async (commit) => {
              if (createCommitMutationResult.loading) {
                return;
              }

              try {
                const result = await createCommit({
                  variables: {
                    customerId,
                    commit: {
                      type: commit.commit.type,
                      external_type:
                        addCommitOpen === "credit"
                          ? ExternalCommitType.Credit
                          : ExternalCommitType.Commit,
                      name: commit.name,
                      description: commit.description,
                      netsuite_sales_order_id: commit.netsuiteSalesOrderId,
                      applicable_product_ids: commit.applicableProductIds,
                      applicable_tags: commit.applicableProductTags,
                      applicable_contract_ids: commit.applicableContractIds,
                      access_schedule: {
                        schedule_items: commit.commit.accessSchedule.map(
                          (item) => ({
                            amount: new Decimal(item.amount).toString(),
                            date: item.date,
                            end_date: item.endDate,
                          }),
                        ),
                        credit_type_id:
                          commit.commit.type === CommitType.Prepaid
                            ? commit.commit.accessScheduleCreditTypeId
                            : USD_CREDIT_TYPE.id,
                      },
                      invoice_schedule:
                        commit.commit.type === CommitType.Prepaid
                          ? formatBillingSchedule(commit.commit.billingSchedule)
                          : formatBillingSchedule({
                              type: "fixed",
                              items: commit.commit.billingSchedule,
                            }),
                      invoice_contract_id: commit.commit.invoiceContractId,
                      priority: new Decimal(commit.commit.priority).toString(),
                      product_id: commit.productId,
                    },
                  },
                  update(cache) {
                    cache.evict({ id: `Customer:${customerId}` });
                  },
                });

                const newCommit = result.data?.create_customer_commit;
                if (!newCommit) {
                  throw new UserFacingError(
                    `Failed to create ${addCommitOpen}, server did not respond as expected`,
                  );
                }

                navigate(Commit.getRoutePath(newCommit));
                pushMessage({
                  type: "success",
                  content: `New ${addCommitOpen} successfully created`,
                });
              } catch (err) {
                pushMessage({
                  type: "error",
                  content:
                    err instanceof UserFacingError
                      ? err.message
                      : `Failed to create ${addCommitOpen}, an unexpected error occurred`,
                });
              }
            }}
            options={{
              level: "customer",
              asCredit: addCommitOpen === "credit",
              customerId,
            }}
            fiatCreditTypes={fiatCreditTypes}
            customCreditTypes={customCreditTypes}
          />
        </DefaultTimeframe.Provider>
      )}
      <div className="-mt-12 flex h-[72px] items-center justify-between">
        <div className="flex">
          <CreditMetric
            isPrimary
            label="Active Prepaid Commits"
            loading={totals.loading}
            amount={totals.prepaid}
            creditType={defaultCreditType}
            suffix=" remaining"
          />
          <CreditMetric
            isPrimary
            label="Active Postpaid Commits"
            loading={totals.loading}
            amount={totals.postpaid}
            creditType={defaultCreditType}
            suffix=" remaining"
          />
          <CreditMetric
            isPrimary
            label="Active Credits"
            loading={totals.loading}
            amount={totals.credit}
            creditType={defaultCreditType}
            suffix=" remaining"
          />
        </div>
        <div className="flex flex-row items-center">
          {allCommitCreditTypeOptions.length > 1 ? (
            <Select
              name=""
              placeholder="Pricing unit"
              value={selectedCreditTypeId}
              options={[
                {
                  label: "Currency",
                  options: commitFiatCreditTypeOptions.map((ct) => ({
                    label: displayCreditTypeName(ct),
                    value: ct.id,
                  })),
                },
                {
                  label: "Custom pricing unit",
                  options: commitCustomCreditTypeOptions.map((ct) => ({
                    label: displayCreditTypeName(ct),
                    value: ct.id,
                  })),
                },
              ]}
              multiSelect={false}
              onChange={(v) => setSelectedCreditTypeId(v)}
              className="w-[150px] text-xxs text-grey-500"
            />
          ) : (
            ""
          )}

          <GatedButton
            className="ml-12"
            onClick={() => setAddCommitOpen("commit")}
            doc={undefined}
            text="Add a commit"
            theme="primary"
          />
          <GatedButton
            className="ml-12"
            onClick={() => setAddCommitOpen("credit")}
            doc={undefined}
            text="Add a credit"
            theme="primary"
          />
        </div>
      </div>
      <CommitsOverview
        level="customer"
        commits={(commitsForCreditType ?? allCommits).filter(
          (c) => !Commit.isCredit(c),
        )}
        asyncUsage={usage}
        className="mt-12"
        shouldSortAndDisplayExpiredBadges={true}
      />
      <CommitsOverview
        level="customer"
        asCredit
        commits={(commitsForCreditType ?? allCommits).filter((c) =>
          Commit.isCredit(c),
        )}
        asyncUsage={usage}
        className="mt-12"
        shouldSortAndDisplayExpiredBadges={true}
      />
    </>
  );
};
