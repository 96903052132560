import React from "react";
import { Badge } from "design-system";

export default function MetricBadge({
  deletedAt,
}: {
  deletedAt: string | null;
}) {
  if (deletedAt !== null) {
    return (
      <Badge theme="warning" type="dark" className="mr-8">
        ARCHIVED
      </Badge>
    );
  }

  return null;
}
