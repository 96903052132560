import { FormController } from "lib/FormController";
import { Schema } from "../../Schema";
import { CommitConsumptionRateTypeEnum } from "types/generated-graphql/__types__";

export type CommitFlyoverController = ReturnType<
  typeof useCommitFlyoverController
>;

export const useCommitFlyoverController = FormController.createHook(
  Schema.CommitFlyoverRoot,
  {
    init: (
      options: {
        commitOrCredit: "commit" | "credit";
        // TODO: Remove this once commit rates is GA'd.
        allowCommitRate?: boolean;
      },
      edit?: Schema.Types.CommitFlyoverRoot,
    ) => {
      return (
        edit ??
        (options.allowCommitRate
          ? {
              rateType:
                options.commitOrCredit === "commit"
                  ? CommitConsumptionRateTypeEnum.CommitRate
                  : CommitConsumptionRateTypeEnum.ListRate,
            }
          : {})
      );
    },
  },
);
