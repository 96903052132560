import React from "react";
import { Select } from "../../../../design-system";
import { useRateCardContext } from "./RateCardContext";

export const Step2: React.FC = () => {
  const { ctrl } = useRateCardContext();
  const rates = ctrl.get("rates") || [];

  return (
    <div>
      <div className="mb-[10px]">Step 2 content</div>
      <div className="mb-[10px]">
        Current products: {rates.map((rate) => rate.productId).join(", ")}
      </div>
      <Select
        name="add product id"
        placeholder=""
        options={[
          {
            value: "product-3",
            label: "product-3",
          },
          {
            value: "product-4",
            label: "product-4",
          },
        ]}
        onChange={(v) => {
          ctrl.update({
            rates: [
              ...rates,
              {
                productId: v,
              },
            ],
          });
        }}
      />
    </div>
  );
};
