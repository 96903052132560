import React from "react";
import { Schema } from "../Schema";
import { CreditType } from "types/credit-types";
import { RateFlat } from "./RateFlat";

interface Props {
  rate: Schema.Types.Rate;
  fiatCreditTypes: CreditType[];
  customCreditTypes: CreditType[];
  onChange: (rate: Schema.Types.Rate) => void;
}

export function RateInput({
  rate,
  fiatCreditTypes,
  customCreditTypes,
  onChange,
}: Props) {
  // TODO(ekaragiannis) - properly figure out FormController here (child controller?)
  const type = rate.price.type;
  return type === "tiered" ? (
    <div>TODO: tiered rate</div> /*
            <RateTiered
              parent={ctrl}
              isCommitRate={false}
              fiatCreditTypes={fiatCreditTypes}
              customCreditTypes={customCreditTypes}
            />
            */
  ) : type === "percentage" ? null : type === // <RatePercent parent={ctrl} isCommitRate={false} />
    "subscription" ? null : (
    /*
            <RateSubscription
              parent={ctrl}
              customCreditTypes={customCreditTypes}
              fiatCreditTypes={fiatCreditTypes}
              isCommitRate={false}
            />
            */
    <RateFlat
      rate={rate}
      isCommitRate={false}
      fiatCreditTypes={fiatCreditTypes}
      customCreditTypes={customCreditTypes}
      onChange={onChange}
    />
  );
}
