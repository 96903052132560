import React from "react";
import BillableMetricPlans from "./tabs/Plans";
import BillableMetricProducts from "./tabs/Products";
import BillableMetricSummary from "./tabs/Summary";
import { RouteObject } from "react-router-dom";
import { useRequiredParam } from "lib/routes/params";

import {
  BillableMetricDetailDocument,
  useBillableMetricDetailQuery,
  useUpdateBillableMetricNameMutation,
} from "./queries.graphql";
import { PageContainer } from "components/PageContainer";

import NotFoundPage from "pages/404";
import { TextSkeleton } from "components/Skeleton";
import { EmptyState } from "components/EmptyState";
import { PopoverMenu } from "components/PopoverMenu";
import { useActions } from "lib/billableMetrics/actions";
import { IconButton } from "tenaissance/components/IconButton";
import { EditNameButton } from "components/EditNameButton";
import { MetricType } from "lib/billableMetrics/types";
import { useSeatMetricDetailQuery } from "pages/NewBillableMetric/queries.graphql";

interface Tab {
  link: string;
  name: string;
  component: React.FC<{ billableMetricId: string; metricType: MetricType }>;
}

const tabs: Tab[] = [
  {
    link: "",
    name: "Summary",
    component: BillableMetricSummary,
  },
  {
    link: "plans",
    name: "Plans",
    component: BillableMetricPlans,
  },
  {
    link: "products",
    name: "Products",
    component: BillableMetricProducts,
  },
];

const BillableMetric: React.FC<{ tab: Tab; metricType: MetricType }> = ({
  tab,
  metricType,
}) => {
  const billableMetricId = useRequiredParam("id");
  const {
    data: billableMetricDetail,
    loading: billableMetricLoading,
    error: billableMetricError,
  } = useBillableMetricDetailQuery({
    variables: {
      billable_metric_id: billableMetricId,
    },
    skip: metricType !== "billable",
  });

  const {
    data: seatMetricDetail,
    loading: seatMetricLoading,
    error: seatMetricError,
  } = useSeatMetricDetailQuery({
    variables: {
      seat_metric_id: billableMetricId,
    },
    skip: metricType !== "seat",
  });

  const [updateBillableMetricNameMutation, updateBillableMetricNameResults] =
    useUpdateBillableMetricNameMutation();
  const { getActions, archiveModal, copyMetricModal } = useActions();
  const error = billableMetricError || seatMetricError;
  const loading = billableMetricLoading || seatMetricLoading;
  const metric =
    billableMetricDetail?.BillableMetric ?? seatMetricDetail?.seat_metric;

  if (loading) {
    return (
      <PageContainer title="Loading ...">
        <div className="pt-12">
          <TextSkeleton />
          <TextSkeleton />
          <TextSkeleton />
          <TextSkeleton />
        </div>
      </PageContainer>
    );
  } else if (error) {
    return (
      <PageContainer title="Error">
        <EmptyState
          title="We ran into an issue loading this billable metric"
          subtitle="Don't worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
          icon="barLineChart"
        />
      </PageContainer>
    );
  } else if (!metric) {
    return <NotFoundPage />;
  }

  const actions = getActions({ ...metric, metricType });

  return (
    <PageContainer
      authDoc={BillableMetricDetailDocument}
      authVariables={{ billable_metric_id: billableMetricId }}
      title={metric.name}
      tabs={tabs.map((t) => ({
        ...t,
        routePath: `/billable-metrics/${
          metricType === "seat" ? "seats/" : ""
        }${billableMetricId}${t.link === "" ? "" : `/${t.link}`}`,
      }))}
      action={
        actions && (
          <PopoverMenu positions={["bottom"]} align="end" options={actions}>
            {(onClick) => (
              <IconButton
                onClick={onClick}
                theme="secondary"
                icon="dotsVertical"
              />
            )}
          </PopoverMenu>
        )
      }
      badge={
        metric.deleted_at !== null
          ? { theme: "warning", type: "dark", children: "ARCHIVED" }
          : undefined
      }
      editButton={
        <EditNameButton
          title="Edit billable metric name"
          body="Change the name of this billable metric in Metronome. This will not affect any existing products, plans, or invoices."
          label="Billable metric name"
          currentName={metric.name}
          updateName={async (name: string) => {
            await updateBillableMetricNameMutation({
              variables: {
                billable_metric_id: metric.id,
                name,
              },
            });
          }}
          loading={updateBillableMetricNameResults.loading}
        />
      }
    >
      {archiveModal}
      {copyMetricModal}
      <tab.component
        billableMetricId={billableMetricId}
        metricType={metricType}
      />
    </PageContainer>
  );
};

export const BillableMetricRoutes: RouteObject[] = [
  {
    path: "billable-metrics/:id",
    children: [
      ...tabs.map((tab) => ({
        ...(tab.link ? { path: tab.link } : { index: true }),
        element: <BillableMetric tab={tab} metricType="billable" />,
      })),
      { path: "*", element: <NotFoundPage /> },
    ],
  },
  {
    path: "billable-metrics/seats/:id",
    children: [
      ...tabs.map((tab) => ({
        ...(tab.link ? { path: tab.link } : { index: true }),
        element: <BillableMetric tab={tab} metricType="seat" />,
      })),
      { path: "*", element: <NotFoundPage /> },
    ],
  },
];
