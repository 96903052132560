import React, { useState, useEffect } from "react";
import { useRequiredParam } from "lib/routes/params";
import { isBefore } from "date-fns";

import classnames from "classnames";
import styles from "./index.module.less";
import { CustomerTab } from "pages/Customer/components/CustomerTab";
import { PageHeader } from "components/PageHeader";
import { Subtitle } from "design-system";
import { Graph } from "components/Graph";
import { TextSkeleton } from "components/Skeleton";

import {
  useListMetricsOnCustomerPlansQuery,
  useMetricUsageQuery,
  ListMetricsOnCustomerPlansQuery,
} from "./usage.graphql";
import { idToGraphColor } from "lib/idToColor";
import { useEnvironment } from "lib/environmentSwitcher/context";
import {
  RelativeDateRangeSelector,
  DateRange,
} from "components/RelativeDateRangeSelector";
import { useFeatureFlag } from "lib/launchdarkly";
import { filterInvoiceByType } from "lib/invoices/typeGuard";
import { dayjs } from "lib/dayjs";
import { EmptyState } from "components/EmptyState";
import { Filter, OptionType, FilterOptions } from "components/Filter";
import { removeEmpty } from "lib/util";
import { UI_MODE, useUIMode } from "../../../../lib/useUIMode";

type UsageProductUpdate = {
  effective_at: string | null;
  created_at?: string;
  billable_metric: {
    typename?: "BillableMetric";
    id: string;
  } | null;
};

const contractMetricsToBillableMetrics = (
  d?: ListMetricsOnCustomerPlansQuery,
) => {
  return d?.Customer_by_pk?.contracts.flatMap((contract) => {
    const contractStart = dayjs(contract.starting_at);
    const contractEnd = contract.ending_before
      ? dayjs(contract.ending_before)
      : undefined;

    // Billable metric is relevant if:
    // a. it is effective after the contract start and before the contract end, or
    // b. it directly precedes the first update is effective after the contract start, or
    // c. it is the only billable metric, or
    // d. it is the last update
    const selectRelevantBillableMetrics = (...bms: UsageProductUpdate[]) => {
      return bms
        .sort(
          (a, b) =>
            dayjs(a.effective_at || a.created_at).unix() -
            dayjs(b.effective_at || b.created_at).unix(),
        )
        .filter((u) => u.billable_metric)
        .filter((u, index, filteredBms) => {
          const nextUpdate = filteredBms[index + 1];
          if (!nextUpdate) return true;
          if (!nextUpdate.billable_metric) return true;

          if (contractEnd) {
            if (dayjs(u.effective_at).isAfter(contractEnd)) return false;
          }

          const nextUpdateStart = dayjs(nextUpdate.effective_at);
          return (
            dayjs(u.effective_at).isSameOrAfter(contractStart) ||
            dayjs(nextUpdateStart).isSameOrAfter(contractStart)
          );
        })
        .map((u) => u.billable_metric?.id);
    };

    return contract.rate_card?.products.flatMap((product) => {
      if (product.__typename === "UsageProductListItem") {
        return selectRelevantBillableMetrics(
          product.initial,
          ...product.updates,
        );
      }
      if (product.__typename === "CompositeProductListItem") {
        return [
          ...(product.initial.composite_products ?? []).flatMap((cp) => {
            if (cp.__typename === "UsageProductListItem") {
              return selectRelevantBillableMetrics(cp.initial, ...cp.updates);
            }
          }),
          ...product.updates.flatMap((cp) => {
            return cp.composite_products?.flatMap((cp) => {
              if (cp.__typename === "UsageProductListItem") {
                return selectRelevantBillableMetrics(cp.initial, ...cp.updates);
              }
            });
          }),
        ];
      }
    });
  });
};

type PlanMetrics = {
  billable_metrics: string[];
  seat_metrics: string[];
};

const customerPlansToPlanMetrics = (
  data?: ListMetricsOnCustomerPlansQuery,
): PlanMetrics => {
  const planMetrics: PlanMetrics = { billable_metrics: [], seat_metrics: [] };
  if (!data || !data.Customer_by_pk?.CustomerPlans) {
    return planMetrics;
  }
  for (const customerPlan of data.Customer_by_pk.CustomerPlans) {
    for (const pricedProducts of customerPlan.Plan.PricedProducts) {
      for (const factor of pricedProducts.Product.ProductPricingFactors) {
        if (factor.BillableMetric && !factor.BillableMetric.deleted_at) {
          planMetrics.billable_metrics.push(factor.BillableMetric.id);
        }
        if (factor.seat_metric && !factor.seat_metric.deleted_at) {
          planMetrics.seat_metrics.push(factor.seat_metric.id);
        }
      }
    }
  }
  return planMetrics;
};

const getUsageFilterOptions = (mode: UI_MODE): FilterOptions => ({
  usage_filter: {
    label: "Usage filter",
    options: [
      {
        label: "All",
        value: "all",
        group: "usage_filter",
        type: "single",
      },
      {
        label: "Non-zero usage",
        value: "non_zero_usage",
        group: "usage_filter",
        type: "single",
      },
      {
        label: `Assigned to ${mode === "plans-only" ? "plan" : mode === "contracts-only" ? "contract" : "plan / contract"}`,
        value: "assigned",
        group: "usage_filter",
        type: "single",
      },
    ],
  },
});

export const Usage: React.FC = () => {
  const { mode } = useUIMode();
  const usageFilterOptions = getUsageFilterOptions(mode);
  const compoundMetrics = useFeatureFlag(
    "compound-metric-configuration",
    {} as Record<string, string[]>,
  );
  const { environmentType } = useEnvironment();
  const customerId = useRequiredParam("customerId");

  const [selectedDateRange, setSelectedDateRange] = useState<
    DateRange | undefined
  >();
  const [usageFilter, setUsageFilter] = useState<OptionType[]>([
    usageFilterOptions.usage_filter.options[0],
  ]);

  const { data: metricsData, loading: planQueryLoading } =
    useListMetricsOnCustomerPlansQuery({
      variables: {
        customer_id: customerId,
        environment_type: environmentType,
        start_date: selectedDateRange?.inclusiveStart.toISOString() ?? "",
        end_date: selectedDateRange?.exclusiveEnd.toISOString() ?? "",
      },
      skip: !selectedDateRange,
    });

  const contractBillableMetrics = contractMetricsToBillableMetrics(metricsData);
  const planMetrics = customerPlansToPlanMetrics(metricsData);

  const allBillableMetrics =
    metricsData?.billable_metrics.map((bm) => bm.id) ?? [];
  const allSeatMetrics =
    metricsData?.seat_metrics.metrics.map((sm) => sm.id) ?? [];

  const planAndContractBillableMetrics = removeEmpty([
    ...(planMetrics.billable_metrics ?? []),
    ...(contractBillableMetrics ?? []),
  ]);
  const planSeatMetrics = planMetrics.seat_metrics ?? [];

  const hasPlanOrContractMetrics = planAndContractBillableMetrics.length > 0;

  useEffect(() => {
    if (hasPlanOrContractMetrics) {
      setUsageFilter([usageFilterOptions.usage_filter.options[2]]);
    }
  }, [hasPlanOrContractMetrics]);

  const fetchAllMetrics = ["all", "non_zero_usage"].includes(
    usageFilter[0].value,
  );

  const billableMetricsToFetch = Array.from(
    new Set(
      fetchAllMetrics ? allBillableMetrics : planAndContractBillableMetrics,
    ),
    // filter out compound metrics because they don't support daily granularity
  ).filter((bmId) => bmId && compoundMetrics && !compoundMetrics[bmId]);

  const seatMetricsToFetch = Array.from(
    new Set(fetchAllMetrics ? allSeatMetrics : planSeatMetrics),
  );

  const {
    data: {
      BillableMetric: billableMetricsData = [],
      seat_metrics: { metrics: seatMetricsData = [] } = {},
    } = {},
    loading: metricsLoading,
    refetch,
    error,
  } = useMetricUsageQuery({
    variables: {
      start_date: selectedDateRange?.inclusiveStart.toISOString() ?? "",
      end_date: selectedDateRange?.exclusiveEnd.toISOString() ?? "",
      customer_id: customerId,
      billable_metrics: billableMetricsToFetch,
      seat_metrics: seatMetricsToFetch,
    },
    skip:
      !selectedDateRange ||
      (!billableMetricsToFetch.length && !seatMetricsToFetch.length),
  });

  const loading = planQueryLoading || metricsLoading;

  // Refetch usage every 60 seconds. This is so changes show up in
  // pseudo-realtime, enabling (at the very least) better demos.
  useEffect(() => {
    const interval = setInterval(() => {
      void refetch();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // Because we load the usage and metrics as part of one query, as the user changes the start/end
  // date, we "refetch" all the metrics. This causes the UI to revert back into the skeleton loading
  // state. In order to make this less disruptive for the user, we store a "high water mark" of the
  // number of metrics we're displaying, so we can show the correct number of skeleton graphs instead
  // of just reverting back to 1 as you change the date range
  const [numberOfMetrics, setNumberOfMetrics] = useState(0);
  useEffect(() => {
    setNumberOfMetrics(
      Math.max(
        numberOfMetrics,
        billableMetricsToFetch.length + seatMetricsToFetch.length,
      ),
    );
  }, [billableMetricsToFetch, seatMetricsToFetch]);

  const filteredBillableMetrics =
    usageFilter[0].value === "non_zero_usage"
      ? billableMetricsData.filter((m) =>
          m.usage.some((d) => Number(d.metric_value) !== 0),
        )
      : billableMetricsData;
  const filteredSeatMetrics =
    usageFilter[0].value === "non_zero_usage"
      ? seatMetricsData.filter((m) =>
          // NOTE: This is a paginated API but this query seems capable of returning at
          // least a year's worth of daily metrics so we probably don't need to support
          // pagination for now.
          m.usage.data.some((d) => parseInt(d.value) !== 0),
        )
      : seatMetricsData;

  const graphProps: MetricsGraphProps[] = [];
  for (const metric of filteredBillableMetrics) {
    graphProps.push({
      key: metric.id,
      metricId: metric.id,
      title: metric.name,
      data: metric.usage.map((d) => ({
        start_date: new Date(d.start),
        value: Number(d.metric_value),
      })),
    });
  }
  for (const metric of filteredSeatMetrics) {
    graphProps.push({
      key: metric.id,
      metricId: metric.id,
      title: metric.name,
      data: metric.usage.data.map((d) => ({
        start_date: new Date(d.inclusive_start_date),
        value: Number(d.value),
      })),
    });
  }
  graphProps.sort((a, b) => a.title.localeCompare(b.title));

  const newServicePeriodRefMarker = (
    metricsData?.Customer_by_pk?.invoices.invoices ?? []
  )
    .filter(filterInvoiceByType("ArrearsInvoice"))
    .map((i) => ({
      date: new Date(i.inclusive_start_date),
      label: "New service period",
    }));
  if (error) {
    return (
      <div className="my-32">
        <EmptyState
          title="We ran into an issue loading usage"
          subtitle="Don't worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
          icon="barLineChart"
        />
      </div>
    );
  }

  return (
    <>
      <PageHeader
        title="Usage"
        type="secondary"
        action={
          <div className="flex flex-row items-center gap-12">
            <Filter
              options={usageFilterOptions}
              value={usageFilter}
              onChange={setUsageFilter}
              className="w-[250px] py-[6px] [&>div.mr-8]:text-gray-900 [&>span]:text-gray-900"
              showCurrentValue={!loading}
            />
            <RelativeDateRangeSelector
              defaultValue="30d"
              onChange={setSelectedDateRange}
              utc
            />
          </div>
        }
      />
      <div className={styles.container}>
        {loading ? (
          [...Array(Math.max(1, numberOfMetrics))].map((_, i) => (
            <div key={i} className={styles.metric}>
              <TextSkeleton />
              <div className={styles.chart}>
                <Graph key={i} loading />
              </div>
            </div>
          ))
        ) : graphProps.length === 0 ? (
          <div className="my-32">
            <EmptyState
              title="No usage found. Try changing the filter or date range."
              subtitle=""
              icon="barChart08"
            />
          </div>
        ) : (
          graphProps.map((props) => (
            <MetricsGraph
              {...props}
              referenceMarkers={newServicePeriodRefMarker}
              fullWidth={graphProps.length <= 2}
            />
          ))
        )}
      </div>
    </>
  );
};

type MetricsGraphProps = {
  key?: string;
  metricId: string;
  title: string;
  data: Array<{
    start_date: Date;
    value: number | null;
  }>;
  referenceMarkers?: Array<{
    date: Date;
    label: string;
  }>;
  fullWidth?: boolean;
};

const MetricsGraph: React.FC<MetricsGraphProps> = (props) => {
  return (
    <div
      key={props.metricId}
      className={classnames(styles.metric, {
        [styles.fullWidthMetric]: props.fullWidth,
      })}
    >
      <Subtitle level={1}>{props.title}</Subtitle>
      <div className={styles.chart}>
        <Graph
          referenceMarkers={props.referenceMarkers}
          lines={[
            {
              name: props.title,
              color: idToGraphColor(props.metricId || ""),
              data: (props.data || [])
                .map((d) => ({ date: d.start_date, value: Number(d.value) }))
                .filter(({ date }) => isBefore(date, new Date())),
            },
          ]}
          isUTC
        />
      </div>
    </div>
  );
};

const UsageTab: React.FC = () => (
  <CustomerTab>
    <Usage />
  </CustomerTab>
);

export default UsageTab;
