import React from "react";

import { useUpdateCustomerNameMutation } from "./queries.graphql";

import { EditNameButton } from "components/EditNameButton";

interface EditNameModalProps {
  customerId: string;
  currentName: string;
}

export const EditCustomerNameButton: React.FC<EditNameModalProps> = ({
  customerId,
  currentName,
}) => {
  const [updateCustomerNameMutation, updateNameResults] =
    useUpdateCustomerNameMutation();
  return (
    <EditNameButton
      title="Edit customer name"
      body="Change this customer's name in Metronome."
      label="Customer name"
      currentName={currentName}
      updateName={async (name: string) => {
        await updateCustomerNameMutation({
          variables: {
            customer_id: customerId,
            name,
          },
        });
      }}
      loading={updateNameResults.loading}
    />
  );
};
