import React from "react";
import {
  ProductCardDetailsFragment,
  useBillableMetricProductsQuery,
} from "./queries.graphql";
import { useSeatMetricProductsQuery } from "./queries.graphql";
import { CardProps, Cards } from "components/Cards";
import { EmptyState } from "components/EmptyState";
import { MetricType } from "lib/billableMetrics/types";
import { Badge, AvatarWithName, Tooltip } from "design-system";
import { CopyableID } from "components/CopyableID";
import { renderDate } from "lib/time";

const productCardDetails = (product: ProductCardDetailsFragment): CardProps => {
  return {
    id: product.id,
    routePath: `/products/${product.id}`,
    title: product.name,
    content: product.description,
    subtitle: <CopyableID id={product.id} label="product ID" />,
    badge:
      product.deprecated_at !== null ? (
        <Badge theme="warning" type="dark">
          ARCHIVED
        </Badge>
      ) : undefined,
    statistics: [
      {
        label: "Charges",
        icon: "pricetag",
        value: product.ProductPricingFactors_aggregate.aggregate?.count ?? 0,
      },
      {
        label: "Plans",
        icon: "receipt",
        value: product.active_plan_count,
      },
    ],
    footer:
      product.Actor != null ? (
        <Tooltip
          content={
            <>
              Created by {product.Actor.name}
              <br />
              {renderDate(new Date(product.created_at), {
                isUtc: false,
              })}
            </>
          }
        >
          <AvatarWithName {...product.Actor} />
        </Tooltip>
      ) : undefined,
  };
};

const BillableMetricProducts: React.FC<{
  billableMetricId: string;
  metricType: MetricType;
}> = ({ billableMetricId, metricType }) => {
  const {
    data: billableMetricProducts,
    loading: billableMetricProductsLoading,
  } = useBillableMetricProductsQuery({
    variables: { billable_metric_id: billableMetricId },
    skip: metricType !== "billable",
  });

  const { data: seatMetricProducts, loading: seatMetricProductsLoading } =
    useSeatMetricProductsQuery({
      variables: { seat_metric_id: billableMetricId },
      skip: metricType !== "seat",
    });

  const loading = billableMetricProductsLoading || seatMetricProductsLoading;
  const data =
    billableMetricProducts?.BillableMetric?.products ??
    seatMetricProducts?.seat_metric.products;
  const products = data ?? [];
  if (!loading && products.length === 0) {
    return (
      <EmptyState
        title="This billable metric has no active products"
        subtitle="Once you add this billable metric to a product, you'll see the details here."
        icon="shoppingCart01"
      />
    );
  }

  const cards = products.map((product) => productCardDetails(product));

  return <Cards cards={cards} loading={loading} />;
};

export default BillableMetricProducts;
