import React from "react";
import { DocumentNode } from "graphql";

import { ProductQuery } from "./data/queries.graphql";
import { Breadcrumbs } from "lib/breadcrumbs";
import { useContractsEnabled } from "lib/contracts/useContractsEnabled";
import { AppShell, PageContainer } from "components/PageContainer";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { useUIMode } from "../../lib/useUIMode";

interface ProductContainerProps extends React.PropsWithChildren {
  product?: "loading" | ProductQuery["Product"];
  error?: unknown;
  action?: React.ReactNode;
  authDoc?: DocumentNode;
}

export const ProductContainer: React.FC<ProductContainerProps> = (props) => {
  const contractsEnabled = useContractsEnabled();
  const { newUIEnabled } = useUIMode();

  if (props.error) {
    return (
      <PageContainer title="Error" hideHeader>
        <ErrorEmptyState
          error={props.error}
          title="We ran into an issue loading this product"
          buttonText="View play overview"
          buttonRoutePath="/products"
        />
      </PageContainer>
    );
  }

  return newUIEnabled ? (
    <AppShell
      title={(props.product !== "loading" && props.product?.name) || ""}
      headerProps={{ actions: [props.action] }}
    >
      {props.children}
    </AppShell>
  ) : (
    <PageContainer
      title={
        !contractsEnabled
          ? props.product === "loading"
            ? Breadcrumbs.loading
            : props.product?.name ?? Breadcrumbs.loading
          : Breadcrumbs.from(
              {
                type: "back",
                label: "Back to plan overview",
                routePath: "/products",
              },
              !props.product
                ? null
                : props.product === "loading"
                  ? Breadcrumbs.loading
                  : {
                      type: "breadcrumb",
                      label: props.product.name,
                      routePath: `/products/${props.product.id}`,
                    },
            )
      }
      action={props.action}
      badge={
        typeof props.product !== "string" &&
        props.product?.deprecated_at !== null
          ? { theme: "warning", type: "dark", children: "ARCHIVED" }
          : undefined
      }
    >
      {props.children}
    </PageContainer>
  );
};
