import React, { useCallback, useEffect, useState } from "react";
import { PageContainer } from "components/PageContainer";
import {
  Body,
  DateInput,
  Headline,
  HelpCircleTooltip,
  Input,
  Select,
  Subtitle,
  Tooltip,
} from "design-system";
import { Button } from "tenaissance/components/Button";
import { useApolloClient } from "@apollo/client";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { dayjs } from "lib/dayjs";
import { useEnvironment } from "lib/environmentSwitcher/context";

import { useAuthCheck } from "lib/useAuthCheck";
import { useSnackbar } from "components/Snackbar";
import { Popup } from "components/Popup";
import {
  Launch_Sox_Report_GeneratorDocument,
  Launch_Sox_Report_GeneratorMutation,
  Launch_Sox_Report_GeneratorMutationVariables,
} from "lib/reports/reportTypes/soxReports/queries.graphql";
import { reportToSentry } from "lib/errors/sentry";
import { downloadCSV, emailValidation, REPORTS } from "lib/reports";
import { ReportConfig, SOXReport } from "lib/reports/reportTypes/reportConfig";

const Reports: React.FC<{}> = () => {
  const ldClient = useLDClient();
  const canGenerateSOXReport = !!useAuthCheck(
    Launch_Sox_Report_GeneratorDocument,
  ).allowed;

  const availableReports = REPORTS.filter(
    (report) =>
      report.isAllowedForUser(ldClient) &&
      (report.type !== "email" || canGenerateSOXReport),
  );
  return (
    <PageContainer title="Reports">
      <div className="max-w-[750px] py-24">
        <div className="flex flex-col gap-4">
          <Headline level={6}>Report builder</Headline>
          <Body>
            Use this tool to generate reports about your customers and revenue.
            All revenue data is based on finalized invoices.
          </Body>
          <ReportsBody availableReports={availableReports} />
        </div>
      </div>
    </PageContainer>
  );
};

const ReportsBody: React.FC<{
  availableReports: (ReportConfig<any> | SOXReport)[];
}> = ({ availableReports }) => {
  const [selectedReport, setSelectedReport] = useState<
    (ReportConfig<any> | SOXReport) | null
  >(availableReports[0]);
  const [buildingReport, setBuildingReport] = useState(false);
  const [dates, setDates] = useState<{
    inclusiveStartDate?: Date;
    exclusiveEndDate?: Date;
  } | null>({});
  const [datesError, setDatesError] = useState(false);
  const [reportEmail, setReportEmail] = useState<string | undefined>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const client = useApolloClient();
  const pushMessage = useSnackbar();
  const { environment, prefixUrl } = useEnvironment();
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (availableReports.length && !selectedReport) {
      setSelectedReport(availableReports[0]);
    }
  }, [availableReports, selectedReport]);

  const doesAnyAvailableReportTypeSupportDates = availableReports.some(
    (r) => r.needsDates,
  );

  /* Handles csv updates and completion of CSV download */
  const datesAreValid =
    !selectedReport?.needsDates ||
    (dates?.inclusiveStartDate &&
      dates.exclusiveEndDate &&
      dayjs(dates.inclusiveStartDate).isBefore(dates.exclusiveEndDate));

  const generateReport = async () => {
    const reportConfig = selectedReport as ReportConfig<any>;
    if (!reportConfig) {
      return;
    }
    if (!datesAreValid) {
      setDatesError(true);
      return;
    }
    if (buildingReport) {
      return;
    }
    const { pageSize } = reportConfig;
    setBuildingReport(true);

    const query = reportConfig.queryDocument;
    const pages = [];
    let cursor = undefined;
    try {
      while (true) {
        const response = await client.query({
          query,
          variables: {
            limit: pageSize + 1,
            cursor,
            snapshot_start: dates?.inclusiveStartDate,
            snapshot_end: dates?.exclusiveEndDate,
          },
        });
        const page: any = response.data;
        pages.push(page);
        const nextCursor = reportConfig.nextCursor(page);
        if (nextCursor) {
          cursor = nextCursor;
          continue;
        }
        break;
      }
    } catch (e: any) {
      pushMessage({
        content: "Failed to generate report.",
        type: "error",
      });
      /* Enable more downloads */
      setBuildingReport(false);
      return;
    }
    let rows: string[][] = [];
    if (reportConfig.needsEnvironment) {
      rows = reportConfig.dataToCSV(pages, environment, prefixUrl);
    } else if (reportConfig.needsDates) {
      if (!dates?.inclusiveStartDate || !dates.exclusiveEndDate) {
        return;
      }
      rows = reportConfig.dataToCSV(
        pages,
        dates.inclusiveStartDate,
        dates?.exclusiveEndDate,
      );
    } else {
      rows = reportConfig.dataToCSV(pages);
    }
    const reportName = reportConfig.needsDates
      ? `${reportConfig.name} ${dayjs(dates?.inclusiveStartDate).format(
          "YYYY-MM-DD",
        )}-${dayjs(dates?.exclusiveEndDate).format("YYYY-MM-DD")}`
      : reportConfig.name;
    downloadCSV(`${reportName}.csv`, rows);
    /* Enable more downloads */
    setBuildingReport(false);
  };

  const generateSOXReport = useCallback(async () => {
    if (
      dates?.inclusiveStartDate &&
      dates?.exclusiveEndDate &&
      reportEmail &&
      selectedReport
    ) {
      try {
        const data = await apolloClient.mutate<
          Launch_Sox_Report_GeneratorMutation,
          Launch_Sox_Report_GeneratorMutationVariables
        >({
          mutation: Launch_Sox_Report_GeneratorDocument,
          variables: {
            startDate: dates?.inclusiveStartDate.toISOString(),
            endDate: dates?.exclusiveEndDate.toISOString(),
            reportEmail: reportEmail,
            customReportType: (selectedReport as SOXReport).customReportType,
          },
        });
        setModalOpen(false);
        if (!data) {
          const errorMessage =
            "Failed to generate sox compliance report: no data returned";
          reportToSentry(new Error(errorMessage));
          throw new Error(errorMessage);
        }
      } catch (e) {
        const errorMessage = `Failed to generate sox compliance report: ${e}`;
        reportToSentry(new Error(errorMessage));
        throw new Error(errorMessage);
      }
    }
  }, [dates, reportEmail, selectedReport]);

  const handleGenerateReportClick = useCallback(async () => {
    if (selectedReport?.type === "email") {
      setModalOpen(true);
    } else {
      await generateReport();
    }
  }, [dates]);

  return (
    <>
      {(selectedReport &&
        "additionalDescription" in selectedReport &&
        selectedReport?.additionalDescription) ??
        null}
      <div className="mt-8 flex justify-between gap-8 rounded-large border border-grey-100 bg-grey-50 p-8">
        <div className="flex w-[200px] flex-col">
          <Subtitle level={4} className="text-grey-600">
            Report
          </Subtitle>
          <Select
            placeholder=""
            options={availableReports.map((report) => ({
              label: report.name,
              value: report.name,
            }))}
            value={selectedReport?.name}
            onChange={(value) => {
              setSelectedReport(
                availableReports.find((report) => report.name === value) ??
                  null,
              );
            }}
            disabled={buildingReport}
          />
        </div>
        {doesAnyAvailableReportTypeSupportDates && (
          <>
            <div>
              <Subtitle level={4} className="flex items-center text-grey-600">
                Start date
                <HelpCircleTooltip content="Inclusive UTC based date set to the beginning of the day" />
              </Subtitle>
              <DateInput
                value={dates?.inclusiveStartDate}
                onChange={(value) => {
                  setDatesError(false);
                  setDates({
                    ...dates,
                    inclusiveStartDate: dayjs
                      .utc(value)
                      .startOf("day")
                      .toDate(),
                  });
                }}
                placement="bottom"
                isUTC
                disabled={buildingReport || !selectedReport?.needsDates}
                error={datesError}
              />
            </div>
            <div>
              <Subtitle level={4} className="flex items-center text-grey-600">
                End date
                <HelpCircleTooltip content="Inclusive UTC based date set to the end of the day" />
              </Subtitle>
              <DateInput
                value={
                  dates?.exclusiveEndDate
                    ? dayjs
                        .utc(dates.exclusiveEndDate)
                        .subtract(1, "day")
                        .toDate()
                    : undefined
                }
                onChange={(value) => {
                  setDatesError(false);
                  setDates({
                    ...dates,
                    exclusiveEndDate: dayjs.utc(value).add(1, "day").toDate(),
                  });
                }}
                placement="bottom"
                isUTC
                disabled={buildingReport || !selectedReport?.needsDates}
                error={datesError}
              />
            </div>
          </>
        )}
        <div className="self-end">
          <Tooltip
            disabled={datesAreValid}
            content="The start date must be before the end date."
          >
            <Button
              disabled={!datesAreValid}
              onClick={handleGenerateReportClick}
              loading={buildingReport}
              text="Generate report"
              theme="primary"
              leadingIcon={buildingReport ? "plusCircle" : "download02"}
            />
          </Tooltip>
        </div>
        <Popup
          actions={
            <>
              <Button
                onClick={() => setModalOpen(false)}
                text="Cancel"
                theme="linkGray"
              />
              <Button
                key="primary"
                disabled={
                  !reportEmail ||
                  (!!reportEmail && !emailValidation.test(reportEmail))
                }
                onClick={generateSOXReport}
                text="Email Report"
                theme="primary"
              />
            </>
          }
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          title={selectedReport?.name || "SOX Compliance Invoice report"}
        >
          <Body level={2}>
            Note that the{" "}
            {selectedReport?.name || "SOX Compliance Invoice report"} report
            will be delivered via email within 12 hours of requesting. Please
            enter an email address to receive the report below:
            <div className="mt-12">
              <Input
                className="max-w-xs"
                placeholder="email"
                type="text"
                value={reportEmail}
                onChange={setReportEmail}
                name="Enter your email"
                error={
                  reportEmail &&
                  !emailValidation.test(reportEmail) &&
                  "Invalid Email"
                }
              />
            </div>
          </Body>
        </Popup>
      </div>
    </>
  );
};

export default Reports;
