import React from "react";

import { AlertRulesDocument, useAlertRulesQuery } from "./queries.graphql";

import { Input } from "design-system";
import { AlertsTable } from "components/AlertsTable";
import { EmptyState } from "components/EmptyState";
import { PageContainer } from "components/PageContainer";
import { SearchTooltip } from "components/SearchTooltip";
import NotFoundPage from "../404";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useHasAccessToAlerts } from "../../lib/alerts";
import { useNavigate } from "lib/useNavigate";
import { useSearchParam } from "lib/routes/useSearchParam";
import useDebounce from "lib/debounce";
import { CreateAlertDocument } from "pages/NewAlert/queries.graphql";
import { GatedButton } from "../../components/GatedButton";

const Alerts: React.FC = () => {
  const { environmentType } = useEnvironment();
  const alertsEnabled = useHasAccessToAlerts();

  const [searchQuery, setSearchQuery] = useSearchParam("q");
  const debouncedSearchQuery = useDebounce(searchQuery.trim(), 400);
  const { data, loading } = useAlertRulesQuery({
    variables: {
      environment_type: environmentType,
      search: debouncedSearchQuery,
    },
  });
  const navigate = useNavigate();

  if (alertsEnabled === undefined) {
    return null;
  }
  if (!alertsEnabled) {
    return <NotFoundPage />;
  }

  const headerActions = (
    <div className="flex items-center">
      <SearchTooltip searchText="alerts">
        <Input
          type="search"
          placeholder="Search"
          value={searchQuery}
          onChange={setSearchQuery}
          leftIcon="search"
          className="mr-12 w-[208px]"
        />
      </SearchTooltip>
      <GatedButton
        doc={CreateAlertDocument}
        onClick={() => navigate("/alerts/new")}
        text="Create alert"
        theme="primary"
        leadingIcon="plus"
        size="sm"
      />
    </div>
  );

  return (
    <PageContainer
      title="Alerts overview"
      action={headerActions}
      authDoc={AlertRulesDocument}
    >
      {(data?.alerts.length ?? 0) === 0 && !loading ? (
        debouncedSearchQuery ? (
          <EmptyState
            title="No matching alerts found."
            subtitle="Try a different search term."
            icon="helpCircle"
          />
        ) : (
          <EmptyState
            title="You haven't created any alerts yet."
            subtitle="Create your first alert."
            buttonAuthDoc={CreateAlertDocument}
            buttonText="Create alert"
            onClick={() => navigate("/alerts/new")}
            icon="bell03"
            buttonIcon="plus"
          />
        )
      ) : (
        <AlertsTable
          alerts={data?.alerts ?? []}
          loading={loading}
          singleCustomer={false}
        />
      )}
    </PageContainer>
  );
};

export default Alerts;
