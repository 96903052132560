import React from "react";
import { PlansTable } from "./PlansTable";
import { ProductsTable } from "./ProductsTable";

export const PlansContainer: React.FC = () => {
  const [isPlans, setIsPlans] = React.useState(true);

  const buttons = [
    {
      text: "Plans",
      onClick: () => setIsPlans(true),
      isActive: isPlans,
    },
    {
      text: "Products",
      onClick: () => setIsPlans(false),
      isActive: !isPlans,
    },
  ];
  return isPlans ? (
    <PlansTable titleButtons={buttons} />
  ) : (
    <ProductsTable titleButtons={buttons} />
  );
};
