import React from "react";
import { Button } from "tenaissance/components/Button";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { Takeover } from "tenaissance/components/Takeover";
// import { useSnackbar } from "components/Snackbar";
import { RateCardContext } from "./RateCardContext";
//import { useUpdateRateCardMutation } from "../CreateAndEditRateCard/data.graphql";
import { useUIMode } from "../../../../lib/useUIMode";

const EditRateCard: React.FC = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const { clearSnapshot, existingRateCardId, dimensionalProducts } =
    RateCardContext.useContainer();

  const { newUIEnabled } = useUIMode();

  const urlPrefix = `${newUIEnabled ? "/offering" : "/contract-pricing"}/rate-cards/${existingRateCardId}/edit2`;

  const getCurrentStepName = () => {
    return location.pathname.split(`${urlPrefix}/`)[1];
  };

  const isFirstStep = !getCurrentStepName();
  const isLastStep = getCurrentStepName() === "rate-products";

  const backToRateCardList = () => {
    clearSnapshot();
    navigate(
      newUIEnabled ? "/offering/rate-cards" : "/contract-pricing/rate-cards",
    );
  };

  const hasDimensionalPricingRates = dimensionalProducts.length > 0;
  const onSubmit = () => {};
  /*
  const [updateRateCardMutation, updateRateCardResult] =
    useUpdateRateCardMutation();

  const pushMessage = useSnackbar();
  const onSubmit = FormController.useSubmitHandler(ctrl, async (valid) => {
    try {
      const result = await updateRateCardMutation({
        variables: {
          rateCardId: existingRateCardId,
          name: valid.name.trim(),
          description: valid.description?.trim(),
          additionalRates: formRatesAsGraphqlRates(
            [
              valid.usageRates ?? [],
              valid.subscriptionRates ?? [],
              valid.compositeRates ?? [],
            ].flat(),
          ),
          aliases: formAliasesAsGraphqlAliases(valid.aliases ?? []),
        },
        update(cache) {
          cache.evict({ fieldName: "products_and_rate_cards" });
          cache.evict({ fieldName: "contract_pricing" });
        },
      });
      const id = result.data?.update_rate_card?.id;
      if (id) {
        clearSnapshot();
        navigate(
          `${newUIEnabled ? "/offering" : "/contract-pricing"}/rate-cards/${existingRateCardId}`,
        );
        pushMessage({
          type: "success",
          content: "Rate card updated",
        });
      }
    } catch (e) {
      reportToSentry(e);
      pushMessage({
        content: `Failed to edit rate card: ${e}`,
        type: "error",
      });
    }
  });
  */

  return (
    <Takeover
      maxContainerWidth="max-w-[1400px]"
      isOpen={true}
      onClose={() => backToRateCardList()}
      title="Edit your rate card"
      footerTrailingButtons={[
        <Button
          onClick={() => {
            if (isLastStep && hasDimensionalPricingRates) {
              navigate(`${urlPrefix}/dimensional-pricing`);
            } else {
              navigate(`${urlPrefix}/`);
            }
          }}
          text="Back"
          theme="secondary"
          disabled={isFirstStep}
        />,
        <Button
          onClick={() => {
            if (isLastStep) {
              onSubmit();
            } else {
              if (isFirstStep && hasDimensionalPricingRates) {
                navigate(`${urlPrefix}/dimensional-pricing`);
              } else {
                navigate(`${urlPrefix}/rate-products`);
              }
            }
          }}
          text={isLastStep ? "Save" : "Next"}
          loading={
            isLastStep ? /* updateRateCardResult.loading */ false : false
          }
          theme="primary"
          disabled={
            isLastStep
              ? /*!ctrl.appearsValid() ||  updateRateCardResult.loading */ false
              : false
          }
        />,
      ]}
      children={
        <div>
          {/* Display the current step */}
          <Outlet />
        </div>
      }
    ></Takeover>
  );
};
export const EditRateCardV2: React.FC = () => {
  return (
    <RateCardContext.Provider>
      <EditRateCard />
    </RateCardContext.Provider>
  );
};
