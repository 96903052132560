import React, { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { BadgeGroup } from "tenaissance/components/BadgeGroup";
import {
  GetSalesforceCensusProvisionedStateDocument,
  useCreateCensusWorkspaceMutation,
} from "./queries.graphql";

interface CreateCensusWorkspaceStepProps {
  isWorkspaceCreated: boolean;
  setIsWorkspaceCreated: Dispatch<SetStateAction<boolean>>;
}

export type SalesforceCensusBadgeProps = {
  badgeGroupMainLabel: string;
  badgeGroupIcon: "arrowNarrowRight" | "check" | "loading02";
  badgeTheme: "gray" | "success" | "error";
  onClick?: (e: React.MouseEvent) => void;
};

export const CreateCensusWorkspaceStep: React.FC<
  CreateCensusWorkspaceStepProps
> = ({ isWorkspaceCreated, setIsWorkspaceCreated }) => {
  const [
    createCensusWorkspaceMutation,
    {
      data: createCensusWorkspaceData,
      loading: createCensusWorkspaceLoading,
      error: createCensusWorkspaceError,
    },
  ] = useCreateCensusWorkspaceMutation();

  const createCensusWorkspaceOnClick = async () => {
    try {
      await createCensusWorkspaceMutation({
        refetchQueries: [
          {
            query: GetSalesforceCensusProvisionedStateDocument,
          },
        ],
      });
      if (!!createCensusWorkspaceData?.create_census_workspace) {
        setIsWorkspaceCreated(true);
      }
    } catch (e) {
      setIsWorkspaceCreated(false);
    }
  };

  const getBadgeProps = (): SalesforceCensusBadgeProps => {
    if (createCensusWorkspaceError) {
      return {
        badgeGroupMainLabel: "Failed to activate. Try again",
        badgeGroupIcon: "arrowNarrowRight",
        badgeTheme: "error",
        onClick: async () => createCensusWorkspaceMutation(),
      };
    } else if (createCensusWorkspaceLoading) {
      return {
        badgeGroupMainLabel: "Activating Census",
        badgeGroupIcon: "loading02",
        badgeTheme: "gray",
        onClick: undefined,
      };
    } else if (
      isWorkspaceCreated ||
      createCensusWorkspaceData?.create_census_workspace?.id
    ) {
      return {
        badgeGroupMainLabel: "Activated",
        badgeGroupIcon: "check",
        badgeTheme: "success",
        onClick: undefined,
      };
    } else {
      return {
        badgeGroupMainLabel: "Activate now",
        badgeGroupIcon: "arrowNarrowRight",
        badgeTheme: "gray",
        onClick: async () => createCensusWorkspaceOnClick(),
      };
    }
  };

  const badgeProps = getBadgeProps();

  return (
    <div className="mb-3xl">
      <h4 className="mb-lg text-sm font-semibold text-black">
        2. Setup your Census workspace
      </h4>
      <div className="mb-lg text-sm text-gray-600">
        Once you've activated the connector package, you'll need to{" "}
        <Link to="https://developers.getcensus.com/getting-started/introduction">
          <u>activate your Census workspace</u>
        </Link>
        .
      </div>
      <BadgeGroup
        badgeLabel="Census workspace"
        mainLabel={badgeProps.badgeGroupMainLabel}
        icon={badgeProps.badgeGroupIcon}
        theme={badgeProps.badgeTheme}
        onClick={badgeProps.onClick}
      />
    </div>
  );
};
