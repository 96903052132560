import { EmptyState } from "components/EmptyState";
import { CopyableID } from "components/CopyableID";
import {
  Body,
  Headline,
  Hyperlink,
  LoadingSpinner,
  Subtitle,
} from "design-system";
import { EntityMappingModal } from "components/EntityMapping";
import { GatedButton } from "components/GatedButton";
import { useSnackbar } from "components/Snackbar";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useFeatureFlag } from "lib/launchdarkly";
import { renderDateTime } from "lib/time";
import { INVOICING_TAB_FEATURE_FLAG, TabFilter } from "pages/GeneralSettings";
import {
  DeleteVendorEntityMappingDocument,
  SaveVendorEntityMappingsDocument,
  useCheckForConnectedAccountsQuery,
  useGetCustomFieldKeysQuery,
  useGetVendorEntityMappingsQuery,
} from "pages/GeneralSettings/queries.graphql";
import React, { useEffect, useState } from "react";
import {
  BillingProviderDeliveryMethod_Enum,
  BillingProviderEnum_Enum,
  CensusDestinationTypeEnum,
  EnvironmentTypeEnum_Enum,
  ManagedFieldKey,
  ManagedVendorEnum_Enum,
} from "types/generated-graphql/__types__";
import { ReactComponent as AzureMarketplaceLogo } from "../../azure.svg";
import { ReactComponent as AWSLogo } from "../../aws.svg";
import { ReactComponent as GCPLogo } from "../../gcp.svg";
import { PageContainer } from "../../../../components/PageContainer";
import { TabProps } from "../../../../components/Tabs";
import AzureMarketplaceConfigModal from "../AzureMarketplaceConfigModal";
import DeleteStripeSettingsModal from "../DeleteStripeSettingsModal";
import { DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersDocument } from "../DeleteStripeSettingsModal/queries.graphql";
import { IntegrationCard } from "../IntegrationCard";
import { WorkatoIntegrationCard } from "../IntegrationCard/workatoIntegrationCard";
import { StripeInvoiceSettings } from "../StripeInvoiceSettings";
import styles from "./index.module.less";
import {
  useGenerateWorkatoSsoUrlMutation,
  useGetClientConfigQuery,
  useGetDeliveryMethodsQuery,
  useGetSalesforceWorkspaceQuery,
} from "./queries.graphql";
import { MarketplaceCard } from "../MarketplaceCard";
import { SectionHeader } from "tenaissance/components/SectionHeader";
import { Button } from "tenaissance/components/Button";
import { generateStripeRedirectUrl } from "lib/stripe";
import { IntegrationRow, IntegrationsTable } from "../IntegrationsTable";
import { IntegrationDetails } from "../IntegrationsTable/IntegrationDetails";
import { allVendors, mapVendorEntityData } from "lib/vendorMapping";
import { AzureSideSheet } from "../AzureSideSheet";
import { StripeSettingsSideSheet } from "../StripeInvoiceSettings/components/StripeSettingsSideSheet";
import { StripeIntegrationCard } from "../IntegrationCards/StripeIntegrationCard";
import { AzureIntegrationCard } from "../IntegrationCards/AzureIntegrationCard";
import { AwsIntegrationCard } from "../IntegrationCards/AwsIntegrationCard";
import { SalesforceIntegrationCard } from "../IntegrationCards/SalesforceIntegrationCard";
import { SalesforceOnboardingSideSheet } from "../SalesforceOnboardingSideSheet";

const INVOICE_WITH_STRIPE_DOCS_URL =
  "https://docs.metronome.com/invoicing/invoicing-with-stripe/";
const AWS_MARKETPLACE_DOCS_URL =
  "https://docs.metronome.com/aws-marketplace/overview/";

type ManagedIntegrationEnum =
  | "CHARGEBEE"
  | "NETSUITE"
  | "STRIPE"
  | "QUICKBOOKS_ONLINE"
  | "WORKDAY";

interface IntegrationsTabProps {
  tabs: TabProps[];
}

const RedesignedIntegrationsTab: React.FC<IntegrationsTabProps> = ({
  tabs,
}) => {
  const INTEGRATION_DOCS = "https://docs.metronome.com/integrations/";
  const { environmentType, prefixUrl } = useEnvironment();
  const pushMessage = useSnackbar();

  const managedIntegrationsFeatureFlags = useFeatureFlag(
    "managed-integrations",
    [] as ManagedIntegrationEnum[],
  );

  const azureMarketplaceEnabled = useFeatureFlag<boolean>(
    "azure-marketplace-integration",
    false,
  );

  const salesforceOnBoardingEnabled = useFeatureFlag<boolean>(
    "salesforce-self-serve-onboarding",
    false,
  );

  const invoicingEnabled =
    useFeatureFlag(INVOICING_TAB_FEATURE_FLAG, false) || false;

  const [
    azureMarketplaceConfigModalIsOpen,
    setAzureMarketplaceConfigModalIsOpen,
  ] = useState(false);

  const [
    salesforceSettingsSideSheetIsOpen,
    setSalesforceSettingsSideSheetIsOpen,
  ] = useState(false);

  const [
    netsuiteEntityMappingsModalIsOpen,
    setNetsuiteEntityMappingsModalIsOpen,
  ] = useState(false);

  const [stripeEntityMappingsModalIsOpen, setStripeEntityMappingsModalIsOpen] =
    useState(false);

  const [azureMarketplaceSideSheetIsOpen, setAzureMarketplaceSideSheetIsOpen] =
    useState(false);

  const [stripeSettingsSideSheetIsOpen, setStripeSettingsSlideSheetIsOpen] =
    useState(false);

  const [connectedIntegrations, setConnectedIntegrations] = useState<
    IntegrationRow[]
  >([]);

  const [availableIntegrations, setAvailableIntegrations] = useState<
    React.ReactElement[] | []
  >([]);

  const {
    data,
    loading: checkForConnectedAccountsLoading,
    error: checkForConnectedAccountsError,
  } = useCheckForConnectedAccountsQuery({
    variables: {
      environment_type: environmentType,
    },
  });

  const {
    data: customFieldKeys,
    loading: customFieldKeysLoading,
    error: customFieldKeysError,
  } = useGetCustomFieldKeysQuery({
    variables: {
      environment_type: environmentType,
    },
  });

  const {
    data: getVendorEntityMappingsData,
    loading: getVendorEntityMappingsLoading,
    error: getVendorEntityMappingsError,
  } = useGetVendorEntityMappingsQuery({
    variables: {
      vendors: allVendors,
    },
  });

  const {
    data: salesforceData,
    loading: salesforceDataLoading,
    error: salesForceDataQueryError,
  } = useGetSalesforceWorkspaceQuery({
    variables: {
      destinationType: CensusDestinationTypeEnum.Salesforce,
    },
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const salesforceOnboardingRedirect = params.get(
      "salesforce_redirect_callback",
    );

    // If the workspace is already provisioned then we should
    // not open the side sheet
    if (
      salesforceOnboardingRedirect &&
      salesforceData &&
      !salesforceData.get_census_workspace?.is_provisioned
    ) {
      setSalesforceSettingsSideSheetIsOpen(true);
    }
  }, [salesforceData]);

  const [generateWorkatoSsoUrlMutation, { loading: workatoSsoUrlLoading }] =
    useGenerateWorkatoSsoUrlMutation();

  const fetchWorkatoSsoUrl = async () => {
    try {
      const { data: workatoData } = await generateWorkatoSsoUrlMutation({
        variables: {},
      });
      if (!workatoData) {
        throw new Error("Failed to generate Workato SSO URL: no data returned");
      }
      if (!workatoData.generate_workato_sso_url.sso_url) {
        throw new Error(
          "Failed to generate Workato SSO URL: sso_url was not returned",
        );
      }
      return workatoData?.generate_workato_sso_url?.sso_url;
    } catch (_) {
      const msg = `Failed to generate Workato SSO URL`;
      pushMessage({
        content: msg,
        type: "error",
      });
    }
  };

  const redirectToWorkatoUrl = async () => {
    const url = await fetchWorkatoSsoUrl();
    if (url) window.open(url, "_blank");
  };

  const stripeToken = data?.stripeTokens[0] ?? null;
  const azureMarketplaceToken = data?.azureMarketplaceTokens[0] ?? null;
  const azureMarketplaceIsConfigured = !!azureMarketplaceToken?.created_at;
  const { azureClientId } = JSON.parse(
    azureMarketplaceToken?.token_string ||
      `{ "azureClientId": "", "azureClientSecret": "OBSCURED" }`,
  );
  const awsMarketplaceToken = data?.awsMarketplaceTokens[0] ?? null;
  const isSalesforceProvisioned =
    salesforceData?.get_census_workspace?.is_provisioned ?? false;
  /** 
  this variable is used to gate the workato integration settings buttons. 
  We only support Production environment for Workato integrations because we don't have separate environments in Workato.
  */
  const isProduction = environmentType === EnvironmentTypeEnum_Enum.Production;

  /** Integration map to be used to render the integrations table
   * toDo: add the salesforce integration to the integration map
   */
  const integrationMap: Readonly<Record<string, IntegrationRow>> = {
    NetSuite: {
      integrationDisplayDetails: (
        <IntegrationDetails
          integrationName="NetSuite"
          supportingText="Accounting/Revenue"
        />
      ),
      rowButtonOptions: {
        isSettingsDisabled: !isProduction || workatoSsoUrlLoading,
        isEditMappingDisabled: false,
        editMappingButtonOnClick: () =>
          setNetsuiteEntityMappingsModalIsOpen(true),
        settingsButtonOnClick: async () => await redirectToWorkatoUrl(),
        toolTipText: "Manage your integration via Workato",
      },
    },
    Workday: {
      integrationDisplayDetails: (
        <IntegrationDetails
          integrationName="Workday"
          supportingText="Human Resources"
        />
      ),
      rowButtonOptions: {
        isSettingsDisabled: !isProduction || workatoSsoUrlLoading,
        isEditMappingDisabled: true,
        editMappingButtonOnClick: () => {},
        settingsButtonOnClick: async () => await redirectToWorkatoUrl(),
        toolTipText: "Manage your integration via Workato",
      },
    },
    Quickbooks_Online: {
      integrationDisplayDetails: (
        <IntegrationDetails
          integrationName="Quickbooks Online"
          supportingText="Accounting"
        />
      ),
      rowButtonOptions: {
        isSettingsDisabled: !isProduction || workatoSsoUrlLoading,
        isEditMappingDisabled: true,
        editMappingButtonOnClick: () => {},
        settingsButtonOnClick: async () => await redirectToWorkatoUrl(),
        toolTipText: "Manage your integration via Workato",
      },
    },
    Stripe: {
      integrationDisplayDetails: (
        <IntegrationDetails
          integrationName="Stripe"
          supportingText="Payment Processing"
        />
      ),
      ids: [stripeToken?.account_id ?? ""],
      dateConnected: stripeToken?.created_at
        ? new Date(stripeToken?.created_at)
        : undefined,
      rowButtonOptions: {
        isSettingsDisabled: !stripeToken,
        isEditMappingDisabled: false,
        settingsButtonOnClick: () => setStripeSettingsSlideSheetIsOpen(true),
        editMappingButtonOnClick: () =>
          setStripeEntityMappingsModalIsOpen(true),
      },
    },
    AzureMarketplace: {
      integrationDisplayDetails: (
        <IntegrationDetails
          integrationName="Azure Marketplace"
          supportingText="Marketplace"
        />
      ),
      ids: [azureMarketplaceToken?.account_id ?? "", azureClientId ?? ""],
      dateConnected: azureMarketplaceToken?.created_at
        ? new Date(azureMarketplaceToken.created_at)
        : undefined,
      rowButtonOptions: {
        isSettingsDisabled: false,
        isEditMappingDisabled: true,
        editMappingButtonOnClick: () => {},
        settingsButtonOnClick: () => setAzureMarketplaceSideSheetIsOpen(true),
      },
    },
    AwsMarketplace: {
      integrationDisplayDetails: (
        <IntegrationDetails
          integrationName="AWS Marketplace"
          supportingText="Marketplace billing Provider"
        />
      ),
      ids: [
        awsMarketplaceToken?.account_id ?? "",
        awsMarketplaceToken?.token_string ?? "",
      ],
      dateConnected: awsMarketplaceToken?.created_at
        ? new Date(awsMarketplaceToken?.created_at)
        : undefined,
      rowButtonOptions: {
        isSettingsDisabled: true,
        isEditMappingDisabled: true,
        editMappingButtonOnClick: () => {},
        settingsButtonOnClick: () => {},
        toolTipText: "Contact Metronome to manage this integration",
      },
    },
    Salesforce: {
      integrationDisplayDetails: (
        <IntegrationDetails
          integrationName="Salesforce"
          supportingText="Customer relationship management"
        />
      ),
      dateConnected: salesforceData?.get_census_workspace?.created_at
        ? new Date(salesforceData.get_census_workspace.created_at)
        : undefined,
      rowButtonOptions: {
        isSettingsDisabled: true,
        isEditMappingDisabled: true,
        editMappingButtonOnClick: () => {},
        settingsButtonOnClick: () => setSalesforceSettingsSideSheetIsOpen(true),
        toolTipText: "Manage your integration via Salesforce",
      },
      rowRoutePath: prefixUrl("/settings/general/integrations/salesforce"),
    },
  };

  const fetchAndSetConnectedIntegrations = () => {
    if (!data) return;

    const integrationSet = new Set<IntegrationRow>();

    managedIntegrationsFeatureFlags?.forEach((integration) => {
      switch (integration) {
        case "NETSUITE":
          integrationSet.add(integrationMap.NetSuite);
          break;
        case "WORKDAY":
          integrationSet.add(integrationMap.Workday);
          break;
        case "QUICKBOOKS_ONLINE":
          integrationSet.add(integrationMap.Quickbooks_Online);
          break;
      }
    });

    if (stripeToken?.created_at) {
      integrationSet.add(integrationMap.Stripe);
    }
    if (azureMarketplaceToken?.created_at) {
      integrationSet.add(integrationMap.AzureMarketplace);
    }
    if (awsMarketplaceToken?.created_at) {
      integrationSet.add(integrationMap.AwsMarketplace);
    }
    if (isSalesforceProvisioned && salesforceOnBoardingEnabled) {
      integrationSet.add(integrationMap.Salesforce);
    }

    setConnectedIntegrations([...integrationSet]);
  };

  useEffect(() => {
    fetchAndSetConnectedIntegrations();
  }, [data, salesforceData]);

  /** 
  currently, we are only supporting stripe, azure, and aws integrations as available integrations.
  toDo: add support for salesforce integration in the available state.
  */
  const getAvailableIntegrationCard = (integration: string) => {
    switch (integration) {
      case "stripe":
        return <StripeIntegrationCard />;
      case "azuremarketplace":
        return (
          <AzureIntegrationCard
            setAzureMarketplaceConfigModalIsOpen={
              setAzureMarketplaceConfigModalIsOpen
            }
          />
        );
      case "awsmarketplace":
        return <AwsIntegrationCard />;
      case "salesforce":
        if (!salesforceOnBoardingEnabled) return;
        return (
          <SalesforceIntegrationCard
            setSalesforceSettingsSideSheetIsOpen={
              setSalesforceSettingsSideSheetIsOpen
            }
          />
        );
    }
  };

  const fetchAndSetAvailableIntegrationCards = () => {
    if (connectedIntegrations.length === 0) return null;
    const formattedIntegrationEnumValues = [
      ...Object.values(BillingProviderEnum_Enum),
      CensusDestinationTypeEnum.Salesforce,
    ].map((integration) => {
      const formattedIntegration = integration
        .replace(/[\s_]+/g, "")
        .toLowerCase();
      // transform azure to azuremarketplace to match the connected integrations display name
      return formattedIntegration === "azure"
        ? "azuremarketplace"
        : formattedIntegration;
    });

    const formattedConnectedIntegrations = connectedIntegrations.map(
      (integration) => {
        const integrationName =
          React.isValidElement(integration.integrationDisplayDetails) &&
          integration.integrationDisplayDetails.props.integrationName;
        return integrationName.replace(/[\s_]+/g, "").toLowerCase();
      },
    );
    const filteredIntegrations = formattedIntegrationEnumValues.filter(
      (integration) => !formattedConnectedIntegrations.includes(integration),
    );
    const availableIntegrationCards = filteredIntegrations
      .map((integration) => getAvailableIntegrationCard(integration))
      .filter((card): card is React.ReactElement => card !== undefined);

    setAvailableIntegrations(availableIntegrationCards);
  };

  useEffect(() => {
    fetchAndSetAvailableIntegrationCards();
  }, [connectedIntegrations]);

  const error =
    checkForConnectedAccountsError ||
    customFieldKeysError ||
    salesForceDataQueryError;
  const loading =
    checkForConnectedAccountsLoading ||
    customFieldKeysLoading ||
    salesforceDataLoading;

  const vendorEntityMappingData =
    getVendorEntityMappingsData &&
    mapVendorEntityData(getVendorEntityMappingsData);

  return (
    <PageContainer
      title="General settings"
      // this filtering has to happen here, since we can't modify the routes in
      // GeneralSettings/index.tsx at runtime
      tabs={tabs.filter(new TabFilter(invoicingEnabled).filter)}
    >
      {error ? (
        <EmptyState
          title="We ran into an issue loading this page"
          subtitle="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
          icon="layersThree02"
        />
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <div className="mt-12 flex flex-col gap-[20px]">
          {azureMarketplaceConfigModalIsOpen && (
            <AzureMarketplaceConfigModal
              onClose={() => {
                setAzureMarketplaceConfigModalIsOpen(false);
              }}
              isConfigured={azureMarketplaceIsConfigured}
            />
          )}
          {salesforceSettingsSideSheetIsOpen && salesforceOnBoardingEnabled && (
            <SalesforceOnboardingSideSheet
              onClose={setSalesforceSettingsSideSheetIsOpen}
              isOpen={salesforceSettingsSideSheetIsOpen}
            />
          )}
          {stripeSettingsSideSheetIsOpen && stripeToken ? (
            <StripeSettingsSideSheet
              setStripeSettingsSlideSheetIsOpen={
                setStripeSettingsSlideSheetIsOpen
              }
              isOpen={stripeSettingsSideSheetIsOpen}
              connectedOn={new Date(stripeToken.created_at)}
              tokenID={stripeToken.account_id}
            />
          ) : null}
          {netsuiteEntityMappingsModalIsOpen && (
            <EntityMappingModal
              key="NetSuite"
              onClose={() => {
                setNetsuiteEntityMappingsModalIsOpen(false);
              }}
              vendor={ManagedVendorEnum_Enum.NetSuite}
              vendorEntityMappings={vendorEntityMappingData?.NetSuite || null}
              customFieldKeys={
                (customFieldKeys?.ManagedFieldKey ?? []) as ManagedFieldKey[]
              }
              loading={getVendorEntityMappingsLoading}
              error={getVendorEntityMappingsError}
            />
          )}
          {stripeEntityMappingsModalIsOpen && (
            <EntityMappingModal
              key="Stripe"
              onClose={() => {
                setStripeEntityMappingsModalIsOpen(false);
              }}
              vendor={ManagedVendorEnum_Enum.Stripe}
              vendorEntityMappings={vendorEntityMappingData?.Stripe || null}
              customFieldKeys={
                (customFieldKeys?.ManagedFieldKey ?? []) as ManagedFieldKey[]
              }
              loading={getVendorEntityMappingsLoading}
              error={getVendorEntityMappingsError}
            />
          )}
          {azureMarketplaceSideSheetIsOpen && azureMarketplaceEnabled ? (
            <AzureSideSheet
              onClose={setAzureMarketplaceSideSheetIsOpen}
              isOpen={azureMarketplaceSideSheetIsOpen}
              azureClientID={azureClientId}
              azureTenantID={azureMarketplaceToken?.account_id}
              connectedOn={
                azureMarketplaceToken?.created_at
                  ? new Date(azureMarketplaceToken?.created_at)
                  : undefined
              }
            />
          ) : null}
          {connectedIntegrations.length > 0 && (
            <IntegrationsTable rows={connectedIntegrations} />
          )}
          {availableIntegrations.length > 0 && (
            <SectionHeader
              title="Available integrations"
              subtitle="Setup seamless payment processing and a variety of additional data sources to enrich your customer data."
              actions={[
                <Button
                  text="View documentation"
                  theme="secondary"
                  leadingIcon="arrowUpRight"
                  onClick={() => window.open(INTEGRATION_DOCS, "_blank")}
                />,
              ]}
              bottomBorder={false}
            />
          )}
          <div className="grid grid-cols-1 gap-24 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
            {availableIntegrations.map((integration, index) => (
              <div key={index}>{integration}</div>
            ))}
          </div>
        </div>
      )}
    </PageContainer>
  );
};

const OriginalIntegrationsTab: React.FC<IntegrationsTabProps> = ({ tabs }) => {
  const managedIntegrationsFeatureFlag = useFeatureFlag(
    "managed-integrations",
    [] as ManagedIntegrationEnum[],
  );
  const azureMarketplaceEnabled = useFeatureFlag<boolean>(
    "azure-marketplace-integration",
    false,
  );
  const gcpMarketplaceEnabled = useFeatureFlag<boolean>(
    "gcp-marketplace-integration",
    false,
  );

  const { environmentType, environment } = useEnvironment();
  const {
    data,
    loading: checkForConnectedAccountsLoading,
    error: checkForConnectedAccountsError,
  } = useCheckForConnectedAccountsQuery({
    variables: {
      environment_type: environmentType,
    },
  });
  const [deleteStripeSettingsModalIsOpen, setDeleteStripeSettingsModalIsOpen] =
    useState(false);
  const [stripeEntityMappingsModalIsOpen, setStripeEntityMappingsModalIsOpen] =
    useState(false);
  const [
    chargebeeEntityMappingsModalIsOpen,
    setChargebeeEntityMappingsModalIsOpen,
  ] = useState(false);
  const [
    netsuiteEntityMappingsModalIsOpen,
    setNetsuiteEntityMappingsModalIsOpen,
  ] = useState(false);
  const [
    azureMarketplaceConfigModalIsOpen,
    setAzureMarketplaceConfigModalIsOpen,
  ] = useState(false);

  const stripeToken = data?.stripeTokens[0] ?? null;
  const awsMarketplaceToken = data?.awsMarketplaceTokens[0] ?? null;
  const azureMarketplaceToken = data?.azureMarketplaceTokens[0] ?? null;

  const { data: clientConfig } = useGetClientConfigQuery();

  const clientHasDeltaStreamEnabled = clientConfig?.is_delta_stream_enabled;
  const clientHasBillingProviderConfigEnabled =
    clientConfig?.is_customer_billing_provider_configuration_enabled;

  const pushMessage = useSnackbar();

  const [generateWorkatoSsoUrlMutation, { loading: workatoSsoUrlLoading }] =
    useGenerateWorkatoSsoUrlMutation();
  const fetchWorkatoSsoUrl = async () => {
    try {
      const { data: workatoData } = await generateWorkatoSsoUrlMutation({
        variables: {},
      });
      if (!workatoData) {
        throw new Error("Failed to generate Workato SSO URL: no data returned");
      }
      if (!workatoData.generate_workato_sso_url.sso_url) {
        throw new Error(
          "Failed to generate Workato SSO URL: sso_url was not returned",
        );
      }
      return workatoData?.generate_workato_sso_url?.sso_url;
    } catch (_) {
      const msg = `Failed to generate Workato SSO URL`;
      pushMessage({
        content: msg,
        type: "error",
      });
    }
  };

  const {
    data: customFieldKeys,
    loading: customFieldKeysLoading,
    error: customFieldKeysError,
  } = useGetCustomFieldKeysQuery({
    variables: {
      environment_type: environmentType,
    },
  });

  const {
    data: getVendorEntityMappingsData,
    loading: getVendorEntityMappingsLoading,
    error: getVendorEntityMappingsError,
  } = useGetVendorEntityMappingsQuery({
    variables: {
      vendors: allVendors,
    },
  });

  // Note: Even though this is a list resolver, we do not implement
  // pagination here since we do not expect this to return more than 100
  // delivery methods (the default page size). Even if all billing providers
  // configure all 3 delivery methods, there would be max ~30 entries.
  const {
    data: getDeliveryMethodsData,
    loading: getDeliveryMethodsLoading,
    error: getDeliveryMethodsError,
  } = useGetDeliveryMethodsQuery();
  const deliveryMethodMapping:
    | Record<BillingProviderEnum_Enum, BillingProviderDeliveryMethod_Enum[]>
    | undefined =
    getDeliveryMethodsData?.list_delivery_methods.delivery_methods.reduce(
      (acc, method) => {
        if (!(method.billing_provider in acc)) {
          acc[method.billing_provider as BillingProviderEnum_Enum] =
            [] as BillingProviderDeliveryMethod_Enum[];
        }
        acc[method.billing_provider as BillingProviderEnum_Enum].push(
          method.delivery_method,
        );
        return acc;
      },
      {} as Record<
        BillingProviderEnum_Enum,
        BillingProviderDeliveryMethod_Enum[]
      >,
    );

  const loading =
    checkForConnectedAccountsLoading ||
    customFieldKeysLoading ||
    getVendorEntityMappingsLoading ||
    getDeliveryMethodsLoading;
  const error =
    checkForConnectedAccountsError ??
    customFieldKeysError ??
    getVendorEntityMappingsError ??
    getDeliveryMethodsError ??
    null;

  const invoicingEnabled =
    useFeatureFlag(INVOICING_TAB_FEATURE_FLAG, false) || false;

  const azureMarketplaceIsConfigured = !!azureMarketplaceToken?.created_at;
  const { azureClientId } = JSON.parse(
    azureMarketplaceToken?.token_string ||
      `{ "azureClientId": "", "azureClientSecret": "OBSCURED" }`,
  );

  const vendorEntityMappingData =
    getVendorEntityMappingsData &&
    mapVendorEntityData(getVendorEntityMappingsData);

  return (
    <PageContainer
      title="General settings"
      // this filtering has to happen here, since we can't modify the routes in
      // GeneralSettings/index.tsx at runtime
      tabs={tabs.filter(new TabFilter(invoicingEnabled).filter)}
    >
      <div className={styles.integrations}>
        {error ? (
          <EmptyState
            title="We ran into an issue loading this page"
            subtitle="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
            icon="layersThree02"
          />
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Headline level={6}>Metronome integrations</Headline>
            <Body level={2} className={styles.maxWidth}>
              Integrations allow for seamless payment processing and a variety
              of additional data sources to enrich your customer data. To create
              a connection, you’ll first need to follow each application's
              required authentication process.
            </Body>

            <Subtitle level={1}>Marketplaces</Subtitle>
            <Body level={2} className="mb-12">
              Bill through the marketplace. Use AWS and Azure Marketplaces as
              billing providers to automatically invoice your customers.{" "}
              <Hyperlink target="_blank" routePath={AWS_MARKETPLACE_DOCS_URL}>
                Learn more.
              </Hyperlink>
            </Body>
            <div className="flex space-x-8 ">
              <MarketplaceCard
                logo={<AWSLogo />}
                createdAt={awsMarketplaceToken?.created_at}
                name="AWS Marketplace"
                tokenIDs={
                  awsMarketplaceToken
                    ? [
                        {
                          id: awsMarketplaceToken.token_string ?? undefined,
                          label: "AWS role ARN",
                        },
                        {
                          id: awsMarketplaceToken.account_id,
                          label: "External ID",
                        },
                      ]
                    : undefined
                }
                isDeltaStream={clientHasDeltaStreamEnabled}
                deliveryMethods={
                  deliveryMethodMapping?.[
                    BillingProviderEnum_Enum.AwsMarketplace
                  ]
                }
              />
              {azureMarketplaceEnabled && (
                <MarketplaceCard
                  buttonOptions={
                    clientHasDeltaStreamEnabled
                      ? undefined
                      : {
                          buttonOnClick: () =>
                            setAzureMarketplaceConfigModalIsOpen(true),
                          buttonText: "Edit Configuration",
                        }
                  }
                  createdAt={azureMarketplaceToken?.created_at}
                  logo={
                    <AzureMarketplaceLogo className="h-[50px] w-[50px] p-[9px]" />
                  }
                  name="Azure Marketplace"
                  tokenIDs={
                    azureMarketplaceToken && azureClientId
                      ? [
                          {
                            id: azureClientId,
                            label: "App Client ID",
                          },
                          {
                            id: azureMarketplaceToken?.account_id,
                            label: "Tenant ID",
                          },
                        ]
                      : undefined
                  }
                  isDeltaStream={clientHasDeltaStreamEnabled}
                  deliveryMethods={
                    deliveryMethodMapping?.[BillingProviderEnum_Enum.Azure]
                  }
                />
              )}
              {/* 
              TODO: Once GCP becomes a viable provider, we can enable them to render. Currently going to be FF
            */}
              {gcpMarketplaceEnabled && (
                <MarketplaceCard
                  logo={<GCPLogo className="h-[50px] w-[50px] p-8 pt-[10px]" />}
                  name="GCP Marketplace"
                  isDeltaStream={clientHasDeltaStreamEnabled}
                  deliveryMethods={
                    deliveryMethodMapping?.[BillingProviderEnum_Enum.Gcp]
                  }
                />
              )}
            </div>
            {azureMarketplaceConfigModalIsOpen && azureMarketplaceEnabled ? (
              <AzureMarketplaceConfigModal
                onClose={() => {
                  setAzureMarketplaceConfigModalIsOpen(false);
                }}
                isConfigured={azureMarketplaceIsConfigured}
              />
            ) : null}
            <div className={styles.integration}>
              {deleteStripeSettingsModalIsOpen && stripeToken ? (
                <DeleteStripeSettingsModal
                  onClose={() => {
                    setDeleteStripeSettingsModalIsOpen(false);
                  }}
                />
              ) : null}
              {stripeEntityMappingsModalIsOpen ? (
                <EntityMappingModal
                  key="Stripe"
                  onClose={() => {
                    setStripeEntityMappingsModalIsOpen(false);
                  }}
                  vendor={ManagedVendorEnum_Enum.Stripe}
                  vendorEntityMappings={vendorEntityMappingData?.Stripe || null}
                  customFieldKeys={
                    (customFieldKeys?.ManagedFieldKey ??
                      []) as ManagedFieldKey[]
                  }
                  loading={getVendorEntityMappingsLoading}
                  error={getVendorEntityMappingsError}
                />
              ) : null}
              {clientHasDeltaStreamEnabled &&
              clientHasBillingProviderConfigEnabled ? (
                <IntegrationCard
                  type={BillingProviderEnum_Enum.Stripe}
                  titleNode={
                    <div className={styles.title}>
                      <Subtitle level={2}>Stripe</Subtitle>
                    </div>
                  }
                  info={
                    stripeToken?.account_id ? (
                      <div className={styles.providerId}>
                        <CopyableID
                          id={stripeToken.account_id}
                          label="Stripe ID"
                        />
                      </div>
                    ) : null
                  }
                  connectionDetails={
                    <div className="flex flex-row items-center space-x-8">
                      {stripeToken?.created_at ? (
                        <div className={styles.connectionDate}>
                          {renderDateTime(
                            new Date(stripeToken.created_at),
                            true,
                            "Connected on",
                          )}
                        </div>
                      ) : environmentType ===
                        EnvironmentTypeEnum_Enum.Sandbox ? (
                        <Subtitle level={4}>
                          Invoices will go to Stripe test mode
                        </Subtitle>
                      ) : null}
                      <GatedButton
                        onClick={() => setStripeEntityMappingsModalIsOpen(true)}
                        doc={[
                          SaveVendorEntityMappingsDocument,
                          DeleteVendorEntityMappingDocument,
                        ]}
                        text="Edit Mapping"
                        theme="secondary"
                      />
                    </div>
                  }
                  buttonText={stripeToken ? "Disable" : "Enable"}
                  buttonTheme={stripeToken ? "secondary" : "primary"}
                  buttonDisabled={true}
                  buttonTooltip="Stripe cannot be configured in the Metronome UI for clients with active Delta Stream integrations."
                  buttonOnClick={() => {}}
                />
              ) : (
                <IntegrationCard
                  type={BillingProviderEnum_Enum.Stripe}
                  titleNode={
                    <div className={styles.title}>
                      <Subtitle level={2}>Stripe</Subtitle>
                    </div>
                  }
                  info={
                    stripeToken?.account_id ? (
                      <div className={styles.providerId}>
                        <CopyableID
                          id={stripeToken.account_id}
                          label="Stripe ID"
                        />
                      </div>
                    ) : null
                  }
                  connectionDetails={
                    <div className="flex flex-row items-center space-x-8">
                      {stripeToken?.created_at ? (
                        <div className={styles.connectionDate}>
                          {renderDateTime(
                            new Date(stripeToken.created_at),
                            true,
                            "Connected on",
                          )}
                        </div>
                      ) : environmentType ===
                        EnvironmentTypeEnum_Enum.Sandbox ? (
                        <Subtitle level={4}>
                          Invoices will go to Stripe test mode
                        </Subtitle>
                      ) : null}
                      <GatedButton
                        onClick={() => setStripeEntityMappingsModalIsOpen(true)}
                        doc={[
                          SaveVendorEntityMappingsDocument,
                          DeleteVendorEntityMappingDocument,
                        ]}
                        text="Edit Mapping"
                        theme="secondary"
                      />
                    </div>
                  }
                  buttonText={stripeToken ? "Disable" : "Enable"}
                  buttonTheme={stripeToken ? "secondary" : "primary"}
                  buttonOnClick={() => {
                    if (stripeToken) {
                      setDeleteStripeSettingsModalIsOpen(true);
                    } else {
                      window.location.href = `https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${
                        environmentType === EnvironmentTypeEnum_Enum.Production
                          ? process.env.STRIPE_LIVE_CLIENT_ID
                          : process.env.STRIPE_TEST_CLIENT_ID
                      }&scope=read_write&redirect_uri=${encodeURIComponent(
                        generateStripeRedirectUrl(environment.urlSlug),
                      )}`;
                    }
                  }}
                  buttonAuthDoc={
                    DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersDocument
                  }
                  docLink={INVOICE_WITH_STRIPE_DOCS_URL}
                >
                  {stripeToken ? <StripeInvoiceSettings /> : null}
                </IntegrationCard>
              )}
            </div>
            {managedIntegrationsFeatureFlag?.includes("CHARGEBEE") ? (
              <div className={styles.integration}>
                {chargebeeEntityMappingsModalIsOpen && (
                  <EntityMappingModal
                    key="Chargebee"
                    onClose={() => {
                      setChargebeeEntityMappingsModalIsOpen(false);
                    }}
                    vendorEntityMappings={
                      vendorEntityMappingData?.Chargebee || null
                    }
                    vendor={ManagedVendorEnum_Enum.Chargebee}
                    customFieldKeys={
                      (customFieldKeys?.ManagedFieldKey ??
                        []) as ManagedFieldKey[]
                    }
                    loading={getVendorEntityMappingsLoading}
                    error={getVendorEntityMappingsError}
                  />
                )}
                <WorkatoIntegrationCard
                  integrationCardType="CHARGEBEE"
                  humanReadableCardType="Chargebee"
                  environmentType={environmentType}
                  fetchWorkatoSsoUrl={fetchWorkatoSsoUrl}
                  workatoSsoUrlLoading={workatoSsoUrlLoading}
                  setEntityMappingModalIsOpen={
                    setChargebeeEntityMappingsModalIsOpen
                  }
                />
              </div>
            ) : null}
            {managedIntegrationsFeatureFlag?.includes("NETSUITE") ? (
              <div className={styles.integration}>
                {netsuiteEntityMappingsModalIsOpen && (
                  <EntityMappingModal
                    key="NetSuite"
                    onClose={() => {
                      setNetsuiteEntityMappingsModalIsOpen(false);
                    }}
                    vendor={ManagedVendorEnum_Enum.NetSuite}
                    vendorEntityMappings={
                      vendorEntityMappingData?.NetSuite || null
                    }
                    customFieldKeys={
                      (customFieldKeys?.ManagedFieldKey ??
                        []) as ManagedFieldKey[]
                    }
                    loading={getVendorEntityMappingsLoading}
                    error={getVendorEntityMappingsError}
                  />
                )}
                <WorkatoIntegrationCard
                  integrationCardType="NETSUITE"
                  humanReadableCardType="NetSuite"
                  environmentType={environmentType}
                  fetchWorkatoSsoUrl={fetchWorkatoSsoUrl}
                  workatoSsoUrlLoading={workatoSsoUrlLoading}
                  setEntityMappingModalIsOpen={
                    setNetsuiteEntityMappingsModalIsOpen
                  }
                />
              </div>
            ) : null}
            {managedIntegrationsFeatureFlag?.includes("QUICKBOOKS_ONLINE") ? (
              <div className={styles.integration}>
                <WorkatoIntegrationCard
                  integrationCardType="QUICKBOOKS_ONLINE"
                  humanReadableCardType="QuickBooks Online"
                  environmentType={environmentType}
                  fetchWorkatoSsoUrl={fetchWorkatoSsoUrl}
                  workatoSsoUrlLoading={workatoSsoUrlLoading}
                  // TODO(WKF-225): setup Quickbooks Online EntityMappingModal
                />
              </div>
            ) : null}
            {managedIntegrationsFeatureFlag?.includes("WORKDAY") ? (
              <div className={styles.integration}>
                <WorkatoIntegrationCard
                  integrationCardType="WORKDAY"
                  humanReadableCardType="Workday"
                  environmentType={environmentType}
                  fetchWorkatoSsoUrl={fetchWorkatoSsoUrl}
                  workatoSsoUrlLoading={workatoSsoUrlLoading}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    </PageContainer>
  );
};

const IntegrationsTab: React.FC<IntegrationsTabProps> = ({ tabs }) => {
  const useRefreshedPage = useFeatureFlag(
    "integrations-tab-ui-redesign-2024",
    false,
  );

  return useRefreshedPage ? (
    <RedesignedIntegrationsTab tabs={tabs} />
  ) : (
    <OriginalIntegrationsTab tabs={tabs} />
  );
};

export default IntegrationsTab;
