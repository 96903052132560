import React, { useState } from "react";
import { Label, NumericInput, Select } from "design-system";

import type { CreateContractCtrl } from "../../ContractCreate";
import { Section } from "../../components/Section";
import {
  ContractIntegrations,
  getIntegrationsDescription,
} from "../ContractIntegrations";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useGetBillableMetricsQuery } from "./data.graphql";
import { BillingProviderEnum_Enum } from "../../../../../../../types/generated-graphql/__types__";
import {
  Dropdown,
  DropdownHeader,
  DropdownItem,
} from "../../../../../../../tenaissance/components/Dropdown";
import {
  useGetActiveMarketplaceContractsQuery,
  useGetBillingProvidersSettingsQuery,
  useGetClientConfigQuery,
} from "../../../../../../Customer/tabs/Settings/sections/BillingProvider/queries.graphql";
import {
  billingProviderToName,
  getCustomerIntegrationCardVisibility,
  parseIntegrationCustomerSettings,
} from "../../../../../../../lib/billingProvider/billingProviderSettings";
import {
  parseActiveContractsAndPlan,
  parseClientGracePeriodHours,
} from "../../../../../../Customer/tabs/Settings/sections/BillingProvider";
import { useFeatureFlag } from "../../../../../../../lib/launchdarkly";
import AWSMarketplaceSettingsModal from "../../../../../../Customer/tabs/Settings/components/AWSMarketplaceSettingsModal";
import AzureMarketplaceSettingsModal from "../../../../../../Customer/tabs/Settings/components/AzureMarketplaceSettingsModal";
import StripeSettingsModal from "../../../../../../Customer/tabs/Settings/components/StripeSettingsModal";

interface Props {
  ctrl: CreateContractCtrl;
  customerID: string;
  options?: {
    salesforceEnabled?: boolean;
    netsuiteEnabled?: boolean;
  };
}

export const ContractBillingConfigurationSection: React.FC<Props> = (props) => {
  const { environmentType } = useEnvironment();
  const [tags, setTags] = useState<Array<string>>([]);
  const [tagSearch, setTagSearch] = useState("");

  const { data: billableMetricsData, loading: billableMetricsLoading } =
    useGetBillableMetricsQuery({
      variables: {
        environment_type: environmentType,
      },
    });
  const groupKeys = Array.from(
    new Set(
      billableMetricsData?.billable_metrics.flatMap((bm) => {
        const keys = bm.group_keys as Array<string | string[]>;
        return keys?.flat();
      }),
    ),
  ).filter((key) => key);

  const { salesforceEnabled, netsuiteEnabled } = props.options ?? {};

  const {
    data: billingProviderSettingsData,
    loading: billingProviderSettingsLoading,
  } = useGetBillingProvidersSettingsQuery({
    variables: {
      customer_id: props.customerID,
      environment_type: environmentType,
    },
  });

  const billingProviderSettings =
    billingProviderSettingsData &&
    parseIntegrationCustomerSettings(billingProviderSettingsData);

  const gcpMarketplaceEnabled = useFeatureFlag<boolean>(
    "gcp-marketplace-integration",
    false,
  );

  const { data: clientConfig } = useGetClientConfigQuery();
  const clientHasDeltaStreamEnabled =
    clientConfig?.is_delta_stream_enabled ?? false;

  const GRACE_PERIOD_BUFFER_HOURS = 2;
  const grace_period_hours =
    parseClientGracePeriodHours(clientConfig) + GRACE_PERIOD_BUFFER_HOURS;

  const activeAwsContractsAndPlan = parseActiveContractsAndPlan(
    useGetActiveMarketplaceContractsQuery({
      variables: {
        billing_provider: BillingProviderEnum_Enum.AwsMarketplace,
        customer_id: props.customerID,
        grace_period_hours,
      },
    }).data,
    BillingProviderEnum_Enum.AwsMarketplace,
  );
  const hasActiveAwsContractsOrPlan =
    !!activeAwsContractsAndPlan.contracts?.length ||
    !!activeAwsContractsAndPlan.plan;

  const activeAzureContractsAndPlan = parseActiveContractsAndPlan(
    useGetActiveMarketplaceContractsQuery({
      variables: {
        billing_provider: BillingProviderEnum_Enum.Azure,
        customer_id: props.customerID,
        grace_period_hours,
      },
    }).data,
    BillingProviderEnum_Enum.Azure,
  );
  const hasActiveAzureContractsOrPlan =
    !!activeAzureContractsAndPlan.contracts?.length ||
    !!activeAzureContractsAndPlan.plan;

  const activeGcpContractsAndPlan = parseActiveContractsAndPlan(
    useGetActiveMarketplaceContractsQuery({
      variables: {
        billing_provider: BillingProviderEnum_Enum.Gcp,
        customer_id: props.customerID,
        grace_period_hours,
      },
    }).data,
    BillingProviderEnum_Enum.Gcp,
  );
  const hasActiveGcpContractsOrPlan =
    !!activeGcpContractsAndPlan.contracts?.length ||
    !!activeGcpContractsAndPlan.plan;

  const activeStripeContractsAndPlan = parseActiveContractsAndPlan(
    useGetActiveMarketplaceContractsQuery({
      variables: {
        billing_provider: BillingProviderEnum_Enum.Stripe,
        customer_id: props.customerID,
        grace_period_hours,
      },
    }).data,
    BillingProviderEnum_Enum.Stripe,
  );
  const hasActiveStripeContractsOrPlan =
    !!activeStripeContractsAndPlan.contracts?.length ||
    !!activeStripeContractsAndPlan.plan;

  const {
    aws: { isConnected: awsIsConnected },
    azure: { isConnected: azureIsConnected },
    stripe: { isConnected: stripeIsConnected },
  } = getCustomerIntegrationCardVisibility(
    billingProviderSettings?.connectedBPs,
    {
      aws: hasActiveAwsContractsOrPlan,
      azure: hasActiveAzureContractsOrPlan,
      gcp: hasActiveGcpContractsOrPlan,
      stripe: hasActiveStripeContractsOrPlan,
    },
    {
      aws: !!billingProviderSettings?.clientHasAws,
      azure: !!billingProviderSettings?.clientHasAzure,
      stripe: !!billingProviderSettings?.clientHasStripe,
    },
    {
      deltaStream: clientHasDeltaStreamEnabled,
      gcp: !!gcpMarketplaceEnabled,
    },
  );

  const [awsMarketplaceSettingsModalOpen, setAWSMarketplaceSettingsModalOpen] =
    useState(false);
  const [
    azureMarketplaceSettingsModalOpen,
    setAzureMarketplaceSettingsModalOpen,
  ] = useState(false);
  const [stripeSettingsModalOpen, setStripeSettingsModalOpen] = useState(false);

  const stripeMultiSettings =
    billingProviderSettings?.stripeMultiSettings ?? [];
  const azureMultiSettings = billingProviderSettings?.azureMultiSettings ?? [];
  const awsMultiSettings = billingProviderSettings?.awsMultiSettings ?? [];

  // NOTE: if an integration is connected to a contract, there's a customerBillingProviderConfigurationID for it
  const existingBillingProviderConfigOptions = [
    // NOTE: stripe doesn't support multiple configs per customer, but marketplaces do.
    // We're still doing this in case that changes for Stripe/for consistency
    ...stripeMultiSettings.map((stripeSettingsForContracts) => ({
      label: `Stripe`,
      subtext: `Customer ID: ${stripeSettingsForContracts.customerId}, Collection Method: ${stripeSettingsForContracts.collectionMethod}`,
      value: BillingProviderEnum_Enum.Stripe,
      isConnected: true,
      onConnectExisting: () => {
        props.ctrl.update({
          billingProvider: BillingProviderEnum_Enum.Stripe,
          billingProviderConfiguration: {
            billing_provider_configuration_id:
              stripeSettingsForContracts.customerBillingProviderConfigurationID,
            configuration: {
              stripe_collection_method:
                stripeSettingsForContracts.collectionMethod,
              stripe_customer_id: stripeSettingsForContracts.customerId,
            },
          },
        });
      },
    })),
    ...azureMultiSettings.map((azureSettingsForContracts) => ({
      label: `Azure`,
      subtext: `Subscription ID: ${azureSettingsForContracts.subscriptionId}`,
      value: BillingProviderEnum_Enum.Azure,
      isConnected: true,
      onConnectExisting: () => {
        props.ctrl.update({
          billingProvider: BillingProviderEnum_Enum.Azure,
          billingProviderConfiguration: {
            billing_provider_configuration_id:
              azureSettingsForContracts.customerBillingProviderConfigurationID,
            configuration: {
              azure_subscription_id: azureSettingsForContracts.subscriptionId,
            },
          },
        });
      },
      onAddNew: () => {
        setAzureMarketplaceSettingsModalOpen(true);
      },
    })),
    ...awsMultiSettings.map((awsSettingsForContracts) => ({
      label: `AWS`,
      subtext: `Customer ID: ${awsSettingsForContracts.customerId}, Product Code: ${awsSettingsForContracts.productCode}, Region: ${awsSettingsForContracts.region}`,
      value: BillingProviderEnum_Enum.AwsMarketplace,
      isConnected: true,
      onConnectExisting: () => {
        props.ctrl.update({
          billingProvider: BillingProviderEnum_Enum.AwsMarketplace,
          billingProviderConfiguration: {
            billing_provider_configuration_id:
              awsSettingsForContracts.customerBillingProviderConfigurationID,
            configuration: {
              aws_customer_id: awsSettingsForContracts.customerId,
              aws_product_code: awsSettingsForContracts.productCode,
              aws_region: awsSettingsForContracts.region,
              aws_is_subscription_product:
                awsSettingsForContracts.isSubscriptionProduct,
            },
          },
        });
      },
    })),
  ];

  const newBillingProviderConfigOptions = [
    {
      label: `Stripe`,
      value: BillingProviderEnum_Enum.Stripe,
      isConnected: stripeIsConnected,
      onAddNew: () => {
        setStripeSettingsModalOpen(true);
      },
    },
    {
      label: `Azure`,
      value: BillingProviderEnum_Enum.Azure,
      isConnected: azureIsConnected,
      onAddNew: () => {
        setAzureMarketplaceSettingsModalOpen(true);
      },
    },
    {
      label: `AWS`,
      value: BillingProviderEnum_Enum.AwsMarketplace,
      isConnected: awsIsConnected,
      onAddNew: () => {
        setAWSMarketplaceSettingsModalOpen(true);
      },
    },
  ].filter(
    (v) =>
      !v.isConnected ||
      // allow creating additional marketplace configs for contracts customers
      v.value === BillingProviderEnum_Enum.Azure ||
      v.value === BillingProviderEnum_Enum.AwsMarketplace,
  );

  const loading = billableMetricsLoading || billingProviderSettingsLoading;

  const selectedBillingProvider = props.ctrl.get("billingProvider");
  return (
    <Section
      title="Billing configuration"
      className="flex max-w-[1000px] flex-col gap-24 py-12"
      description="Set up billing and invoicing terms."
    >
      <div className="-mb-12 text-sm text-gray-700">
        <span className="font-medium">
          Net payment terms and billing provider:
        </span>{" "}
        Set how long a customer has to remit payment and where to invoice.
      </div>
      <div className="grid grid-cols-3 gap-12">
        <NumericInput
          {...props.ctrl.props.NumericInput("netPaymentTermsDays", {
            placeholder: "30 days",
            name: "Net payment terms (optional)",
          })}
        />
        <div className="flex flex-col">
          <Label>Billing provider</Label>
          <Dropdown
            label={
              (selectedBillingProvider &&
                billingProviderToName[selectedBillingProvider]) ||
              "Connect a billing provider"
            }
            buttonSize="sm"
          >
            <DropdownHeader text="Connected billing provider" />
            {existingBillingProviderConfigOptions
              .filter((config) => "isConnected" in config && config.isConnected)
              .map(({ label, value, subtext, onConnectExisting }, index) => (
                <DropdownItem
                  key={"connected-bp-" + index}
                  label={label}
                  subtext={subtext}
                  value={value}
                  selected={props.ctrl.get("billingProvider") === value}
                  onClick={onConnectExisting}
                />
              ))}

            <DropdownHeader text="Add new billing provider" />
            {newBillingProviderConfigOptions
              .filter((config) => !config.isConnected)
              .map(({ label, value, onAddNew }, index) => (
                <DropdownItem
                  key={"new-bp-" + index}
                  label={label}
                  value={value}
                  onClick={onAddNew}
                  selected={props.ctrl.get("billingProvider") === value}
                />
              ))}
          </Dropdown>
        </div>
      </div>

      {/* Setup Integration Modals */}
      {awsMarketplaceSettingsModalOpen && (
        <AWSMarketplaceSettingsModal
          onClose={(result) => {
            setAWSMarketplaceSettingsModalOpen(false);
            result?.isSuccess &&
              props.ctrl.update({
                billingProvider: BillingProviderEnum_Enum.AwsMarketplace,
              });
          }}
          plansAndOrContracts="contracts_only"
          customerId={props.customerID}
        />
      )}

      {azureMarketplaceSettingsModalOpen && (
        <AzureMarketplaceSettingsModal
          onClose={(result) => {
            setAzureMarketplaceSettingsModalOpen(false);
            result?.isSuccess &&
              props.ctrl.update({
                billingProvider: BillingProviderEnum_Enum.Azure,
              });
          }}
          plansAndOrContracts="contracts_only"
          customerID={props.customerID}
        />
      )}

      {stripeSettingsModalOpen && (
        <StripeSettingsModal
          onClose={(_, result) => {
            setStripeSettingsModalOpen(false);
            result?.isSuccess &&
              props.ctrl.update({
                billingProvider: BillingProviderEnum_Enum.Stripe,
              });
          }}
          plansAndOrContracts="contracts_only"
          customerId={props.customerID}
        />
      )}

      {(salesforceEnabled || netsuiteEnabled) && (
        <>
          <div className="-mb-12 text-sm text-gray-700">
            <span className="font-medium">Integrations:</span>{" "}
            {getIntegrationsDescription({ salesforceEnabled, netsuiteEnabled })}
          </div>

          <div className="grid grid-cols-3 gap-12">
            <ContractIntegrations
              ctrl={props.ctrl}
              options={{
                salesforceEnabled,
                netsuiteEnabled,
              }}
            />
          </div>
        </>
      )}

      <div className="-mb-12 text-sm text-gray-700">
        <span className="font-medium">Usage filter (optional):</span> For
        customers with overlapping contracts, you can determine how to route
        usage to the correct contract by selecting a group key/value pair.
      </div>
      <div className="grid grid-cols-3 gap-12">
        <Select
          {...props.ctrl.props.Select("usageFilterGroupKey", {
            placeholder: "",
            name: "Group key",
            loading,
            disabled: loading,
            menuPlacement: "top",
            options: [
              {
                label: "None",
                value: "",
              },
            ].concat(
              groupKeys.map((k) => ({
                label: k,
                value: k,
              })),
            ),
          })}
        />
        <Select
          __internalComponentOverrides={{
            DropdownIndicator: () => null,
            Menu: () => null,
          }}
          placeholder="Type a group value and press enter"
          name="Group values"
          className="col-span-2"
          disabled={!props.ctrl.get("usageFilterGroupKey")}
          options={tags
            .map((t) => ({ label: t, value: t }))
            .concat(tagSearch ? [{ label: tagSearch, value: tagSearch }] : [])
            .concat(
              props.ctrl
                .get("usageFilterGroupValues")
                ?.split(",")
                .filter((v) => v !== "")
                .map((v) => ({
                  label: v,
                  value: v,
                })) ?? [],
            )}
          onSearch={setTagSearch}
          value={
            tags.length > 0
              ? tags
              : props.ctrl.get("usageFilterGroupValues")?.split(",") ?? []
          }
          multiSelect
          onChange={(v) => {
            setTags(v);
            props.ctrl.update({
              usageFilterGroupValues: v.join(","),
            });
          }}
        />
      </div>
    </Section>
  );
};
