import React from "react";
import "/src/tenaissance/tenaissance.css";
import { Tooltip } from "../Tooltip";
import { Icon } from "../Icon";
import { Badge } from "../Badge";
import { Chart } from "../Chart";
import { CardContext } from "./CardContext";
import { twMerge } from "tenaissance/twMerge";
import { LoadingBlob } from "../LoadingBlob";

interface CardProps {
  /** Default: bottom - If a Chart is present, this will orient the position of the Chart. */
  chartPosition?: "bottom" | "end";
  /**
   * Default: full - If a Chart is present, this will resize the Chart appropriately;
   *  mini on the end or bottom and full on the bottom  */
  chartSize?: "mini" | "full";
  /** Children should always be of Card.Chart */
  children?: React.ReactNode;
  /** An optional array of metrics to render in the Card, with label, value, tooltip and more */
  metrics?: Metric[];
  /** If provided, the title will render at the top of the card, left of any actions */
  title?: string;
  /** Default: true - If set to false, the card will be full width of the container */
  wrapContents?: boolean;
  /** If provided, a footer will be added to the card with actions */
  actions?: React.ReactNode[]; // Actions to be rendered within the card
  /** Show loading animation in place of content */
  loading?: boolean;
}

interface CardSubComponents {
  /** The Chart component will act as a sub-component for the Card */
  Chart: typeof Chart;
}

interface Metric {
  /** The label for Metric, rendered top / left of the Metric */
  label?: string;
  /** The Metric value, often a string or number */
  value: any;
  /** Optionally render a positive or negative change in the value, styled with success or error respectively */
  delta?: "positive" | "negative" | undefined;
  /** Optional value change to be rendered in a Badge */
  deltaValue?: string;
  /** Optional context for a given Metric, this will render a small icon that will show a Tooltip on hover */
  tooltipContent?: string;
}

const Metric: React.FC<Metric> = ({
  value,
  delta,
  deltaValue,
  label,
  tooltipContent,
}) => {
  return (
    <div>
      {label && (
        <div>
          <span className="text-sm, font-medium text-gray-600">{label}</span>
          {tooltipContent && (
            <Tooltip label={tooltipContent}>
              <Icon icon="helpCircle" size={14} />
            </Tooltip>
          )}
        </div>
      )}
      <div className="flex items-center">
        <h4 className="text-display-sm font-semibold">{value}</h4>
        {delta && deltaValue && (
          <Badge
            className="ml-xl"
            label={deltaValue}
            theme={delta === "positive" ? "success" : "error"}
            icon={delta === "positive" ? "arrowUp" : "arrowDown"}
          />
        )}
      </div>
    </div>
  );
};

/**
 * !! PARTIAL IMPLEMENTATION !!
 *  Cards are useful for presenting key data points or numbers to users.
 *  They’re commonly used in dashboards to quickly summarise changes in data.
 */
export const Card: React.FC<CardProps> & CardSubComponents = ({
  chartPosition,
  chartSize,
  children,
  title,
  metrics,
  wrapContents = true,
  actions,
  loading,
}) => {
  return (
    <div className={wrapContents ? "inline-block" : "block"}>
      {title && (
        <div className="text-md rounded-t-xl px-xl border border-b-0 border-gray-200 py-[18px] font-semibold text-black">
          {title}
        </div>
      )}
      <div
        className={twMerge(
          "px-xl border border-gray-200",
          !!title ? "py-lg" : "py-3xl rounded-xl",
          actions ? "rounded-b-none" : "shadow-xs rounded-b-xl",
        )}
      >
        {metrics && (
          <div className="mb-lg flex space-x-[40px]">
            {metrics.map((metric, idx) =>
              loading ? (
                <LoadingBlob />
              ) : (
                <Metric
                  key={idx}
                  value={metric.value}
                  delta={metric.delta}
                  deltaValue={metric.deltaValue}
                  label={metric.label}
                  tooltipContent={metric.tooltipContent}
                />
              ),
            )}
          </div>
        )}
        {/* Leaving in placeholder for CardContext; will come into play for
        next Card/Chart changes. See GET-4811 for more */}
        <CardContext.Provider value={1322}>
          {loading ? <LoadingBlob className="h-2xl w-[150px]" /> : children}
        </CardContext.Provider>
      </div>
      {actions && (
        <div className="rounded-b-xl flex flex-col items-center self-stretch border border-t-0 border-gray-200">
          <div className="p-xl flex flex-row-reverse justify-between self-stretch">
            {actions.map((action, i) => (
              <div className="self-center" key={i}>
                {action}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Card.Chart = Chart;

Card.displayName = "Card";
