import { SOXReport } from "../..";

const report: SOXReport = {
  name: "SOX Compliance Invoice Report",
  needsDates: true,
  needsEnvironment: false,
  type: "email",
  isAllowedForUser: (ldClient) =>
    ldClient
      ?.variation("available-report-types", [])
      .includes("SOX_COMPLIANCE_INVOICE"),
};

export default report;
