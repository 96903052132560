import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useMatches } from "react-router-dom";
import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";
import { isObj } from "lib/typeHelpers/object";
import { useEnvironmentsQuery } from "./environments.graphql";
import { useAuth } from "lib/auth";
import { createApolloClient } from "lib/apollo";
import { shouldPrefixRoutePath } from "./shouldPrefixRoutePath";

export const EnvSlug$ = Symbol("environment slug attached to route handles");
function hasEnvSlug(r: unknown): r is { handle: { [EnvSlug$]: string } } {
  return isObj(r) && isObj(r.handle) && EnvSlug$ in r.handle;
}

export interface Environment {
  urlSlug: string;
  name: string;
  id: string;
  type: EnvironmentTypeEnum_Enum | null;
  enabled: boolean;
}

const defaultEnvironments = [
  {
    urlSlug: "",
    name: "Production",
    id: "PRODUCTION",
    type: EnvironmentTypeEnum_Enum.Production,
    enabled: true,
  },
  {
    urlSlug: "/sandbox",
    name: "Sandbox",
    id: "SANDBOX",
    type: EnvironmentTypeEnum_Enum.Sandbox,
    enabled: true,
  },
];

type Context = {
  cachedEnvironment: Environment | undefined;
  setCachedEnvironment: (environment: Environment | undefined) => void;
  environments: Environment[];
};

const Context = React.createContext<Context>(null as any);
export { Context as __Testing__EnvironmentContext };

// This context is used as a sort of "cache" of the current sandbox mode. Ideally we'd just always trust the URL, but
// if someone goes from a page that supports sandbox mode (e.g. /sandbox/customers) to a page that doesn't (e.g. /team)
// and then back to a page that does (e.g "products") then we want to "remember" that the user was in sandbox mode before
// they went to the page that doesn't support sandbox mode.
export const EnvironmentProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [cachedEnvironment, setCachedEnvironment] = useState<
    Environment | undefined
  >(undefined);

  const auth = useAuth();
  const client = createApolloClient(
    process.env.GRAPHQL_URL as string,
    auth,
    null,
    process.env.USE_METRONOME_SYSTEM_HEADERS === "1",
  );

  const { data } = useEnvironmentsQuery({ client, errorPolicy: "all" });
  const environments = React.useMemo(
    () =>
      data?.environments?.map((e) => ({
        ...e,
        urlSlug: e.slug ? `/${e.slug}` : "",
      })) ?? defaultEnvironments,
    [data, defaultEnvironments],
  );

  const ctxValue = React.useMemo(
    () => ({ cachedEnvironment, setCachedEnvironment, environments }),
    [cachedEnvironment, setCachedEnvironment, environments],
  );

  return <Context.Provider value={ctxValue}>{children}</Context.Provider>;
};

function useEnvSlug() {
  const matches = useMatches();
  for (const match of matches) {
    if (hasEnvSlug(match)) {
      return match.handle[EnvSlug$];
    }
  }
}

// may not return / may break the page, use only in contexts where you know the component will be unmounted before the environment provider is mounted
export function useEnvironment__unsafe() {
  const ctx = React.useContext(Context);
  if (ctx) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useEnvironment();
  } else {
    return null;
  }
}

export function useEnvironment() {
  const location = useLocation();
  const navigate = useNavigate();
  const ctx = React.useContext(Context);

  // we need to get the environment from the url string first if it is there.
  // this will be our default value used for the environment.
  // the issue arise when we have a page like a redirect which
  // relies on the environment type on load.  For example, the StripeConnect redirect page
  // gets hit, loads the environment (but it defaults to Production until the useEffect below runs).
  const urlSlug = useEnvSlug();

  // If sandbox mode is supported on this page then there will be a urlSlug in the route
  // stack, otherwise the urlSlug will be undefined. If the urlSlug is "" then we're in the
  // production environment, so we have to check for undefined explicitly.
  const sandboxModeSupported = urlSlug !== undefined;

  // TODO(ekaragianni) - this will fix Sandbox mode but will have an issue when
  // a user tries to connect stripe to a new environment like UAT.  This is because the update
  // to the "environments" list happens asynchronously.
  // the proper fix here will be to add an "EnvironmentsLoaded" field to the "useEnvironments" hook I'm guessing
  // and not use the useFeatureFlag hook.
  const envFromUrl = ctx.environments.find((e) => e.urlSlug === urlSlug);
  const environment =
    ctx.cachedEnvironment ?? envFromUrl ?? ctx.environments[0];
  const expectedCache = envFromUrl ?? environment;

  useEffect(() => {
    if (!sandboxModeSupported) {
      // don't update the cache if we are on a page which doesn't support sandbox mode
      return;
    }

    if (ctx.cachedEnvironment !== expectedCache) {
      ctx.setCachedEnvironment(envFromUrl);
    }
  }, [sandboxModeSupported, ctx.cachedEnvironment, expectedCache]);

  return React.useMemo(() => {
    // This is a mega hack but - If you're viewing a specific item we want to disallow you from switching envs
    // (since the item would 404 in the new env) our best hueristic for this if the url has a UUID in it.
    const isChangingSupported =
      !/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i.test(
        location.pathname,
      );

    function prefixEnvironmentUrl(targetEnvironment: Environment, url: string) {
      if (!shouldPrefixRoutePath(url, targetEnvironment.urlSlug)) {
        return url;
      }

      return `${targetEnvironment.urlSlug}${url}`;
    }

    return {
      environment,
      environments: ctx.environments,
      supported: sandboxModeSupported,
      changingSupported: isChangingSupported,
      // We use the non-null operator here because all environments today have a type in the enum.
      // Before we release custom environments, we'll want to have removed this enum everywhere.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      environmentType: environment.type!,
      prefixEnvironmentUrl,
      prefixUrl: (url: string) => prefixEnvironmentUrl(environment, url),
      setEnvironment: (env: Environment) => {
        if (!sandboxModeSupported || !isChangingSupported) {
          return;
        }
        if (env === environment) {
          return;
        }
        const newLocation = `${env.urlSlug}${location.pathname.replace(
          /^\/(sandbox|env-\w+)/,
          "",
        )}`;
        if (newLocation !== location.pathname) {
          navigate(`${newLocation}`);
        } else {
        }
      },
    };
  }, [ctx, location, environment, sandboxModeSupported, navigate]);
}
