/* 1d0dae53e1074e3e514bf6085d95b99e90970253
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VoidInvoiceMutationVariables = Types.Exact<{
  invoice_id: Types.Scalars['uuid'];
}>;


export type VoidInvoiceMutation = { __typename?: 'Mutation', mri_void_invoice: { __typename?: 'AdHocPlanInvoice', id: string } | { __typename?: 'AdhocContractUsageInvoice', id: string } | { __typename?: 'AdvanceInvoice', id: string } | { __typename?: 'ArrearsInvoice', id: string } | { __typename?: 'ContractPostpaidTrueupInvoice', id: string } | { __typename?: 'ContractProServiceInvoice', id: string } | { __typename?: 'ContractRefundInvoice', id: string } | { __typename?: 'ContractScheduledInvoice', id: string } | { __typename?: 'ContractUsageInvoice', id: string } | { __typename?: 'CorrectionInvoice', id: string } | { __typename?: 'CreditPurchaseInvoice', id: string } | { __typename?: 'ParentInvoice', id: string } | { __typename?: 'SeatPurchaseInvoice', id: string } };


export const VoidInvoiceDocument = gql`
    mutation VoidInvoice($invoice_id: uuid!) {
  mri_void_invoice(id: $invoice_id) {
    id
  }
}
    `;
export type VoidInvoiceMutationFn = Apollo.MutationFunction<VoidInvoiceMutation, VoidInvoiceMutationVariables>;

/**
 * __useVoidInvoiceMutation__
 *
 * To run a mutation, you first call `useVoidInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidInvoiceMutation, { data, loading, error }] = useVoidInvoiceMutation({
 *   variables: {
 *      invoice_id: // value for 'invoice_id'
 *   },
 * });
 */
export function useVoidInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<VoidInvoiceMutation, VoidInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoidInvoiceMutation, VoidInvoiceMutationVariables>(VoidInvoiceDocument, options);
      }
export type VoidInvoiceMutationHookResult = ReturnType<typeof useVoidInvoiceMutation>;
export type VoidInvoiceMutationResult = Apollo.MutationResult<VoidInvoiceMutation>;
export type VoidInvoiceMutationOptions = Apollo.BaseMutationOptions<VoidInvoiceMutation, VoidInvoiceMutationVariables>;