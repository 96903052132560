import React from "react";
import { RouteObject, Navigate, Outlet, useLocation } from "react-router-dom";

import { ContractsRouteSwitch } from "lib/contracts/ContractsRouteSwitch";
import { EnvironmentRedirect } from "lib/environmentSwitcher/EnvironmentRedirect";

// Legacy customer routes
import Overview from "pages/Overview";
import AddPlanToCustomer from "pages/AddPlanToCustomer";
import NewCustomer from "pages/NewCustomer";
import UsageTab from "pages/Customer/tabs/Usage";
import PlansTab from "pages/Customer/tabs/Plans";
import InvoicesTab from "pages/Customer/tabs/Invoices";
import SettingsTab from "pages/Customer/tabs/Settings";
import CreditsTab from "pages/Customer/tabs/Credits";
import AlertsTab from "pages/Customer/tabs/Alerts";
import AuditLogsTab from "pages/Customer/tabs/AuditLogs";

// Contract-based customer routes
import { CustomerBillingOverview } from "./Customer/BillingOverview/BillingOverview";
import { CustomersList } from "./CustomersList/CustomersList";
import { CustomerCredits } from "./Customer/Credits/CustomerCredits";
import { CustomerAlerts } from "./Customer/Alerts/CustomerAlerts";
import { CustomerSettings } from "./Customer/Settings/CustomerSettings";
import { CustomerAuditLogs } from "./Customer/AuditLogs/AuditLogs";
import {
  ContractCreatePage,
  AmendmentCreate,
  CustomerContractOverview,
  CustomerContractLifecycle,
  CustomerContractInvoices,
  CustomerContractCommits,
  CustomerContractSettings,
} from "./Customer/Contracts";
import {
  ContractCustomerPlanOverview,
  ContractCustomerPlanInvoices,
} from "./Customer/Plans";

// contract-pricing routes
import { ContractProductList } from "./Pricing/ContractProductList";
import { RateCardsList } from "./Pricing/RateCardsList";
import { RateCardDetails } from "./Pricing/RateCardsDetails";

// Other routes
import { CorrectInvoice } from "../CorrectInvoice";
import { CustomerUsage } from "./Customer/Usage/CustomerUsage";
import { RateCardCreate } from "./Pricing/CreateAndEditRateCard/RateCardCreate";
import { EditRateCard } from "./Pricing/CreateAndEditRateCard";
import {
  CustomerCommitOverview,
  CustomerCommitsOverview,
} from "./Customer/Contracts/Commits";
import { GigaRateCardSwitch } from "./lib/GigaRateCard";
import NotFoundPage from "pages/404";
import { RateCardCreateV2 } from "./Pricing/CreateAndEditRateCardV2/RateCardCreateV2";
import { Step1 } from "./Pricing/CreateAndEditRateCardV2/Step1";
import { Step2 } from "./Pricing/CreateAndEditRateCardV2/Step2";
import { Step3 } from "./Pricing/CreateAndEditRateCardV2/Step3";

export const CommitsRedirect: React.FC = () => {
  const { pathname } = useLocation();
  const newPath = pathname.replace(
    /\/commits([\/?#]|$)/,
    "/commits-and-credits$1",
  );
  return <Navigate to={newPath} replace />;
};

export const BalancesRedirect: React.FC = () => {
  const { pathname } = useLocation();
  const newPath = pathname.replace(
    /\/balances([\/?#]|$)/,
    "/commits-and-credits$1",
  );
  return <Navigate to={newPath} replace />;
};

export const ContractCustomersRoutes: RouteObject[] = [
  {
    index: true,
    element: (
      <ContractsRouteSwitch
        enabled={<EnvironmentRedirect to="/customers" />}
        disabled={<EnvironmentRedirect to="/overview" />}
      />
    ),
  },
  {
    path: "overview",
    element: (
      <ContractsRouteSwitch
        enabled={<Navigate to="../customers" />}
        disabled={<Overview />}
      />
    ),
  },
  {
    path: "customers",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: (
          <ContractsRouteSwitch
            enabled={<CustomersList />}
            disabled={<Navigate to="../overview" />}
          />
        ),
      },
      {
        path: "new",
        element: <NewCustomer />,
      },
      {
        path: ":customerId",
        children: [
          {
            index: true,
            element: (
              <ContractsRouteSwitch
                enabled={<CustomerBillingOverview />}
                disabled={<InvoicesTab />}
              />
            ),
          },

          // CUSTOMER LEVEL STUFF
          {
            path: "commits/*",
            element: <CommitsRedirect />,
          },
          {
            path: "balances/*",
            element: <BalancesRedirect />,
          },
          {
            path: "commits-and-credits",
            children: [
              {
                index: true,
                element: (
                  <ContractsRouteSwitch enabled={<CustomerCommitsOverview />} />
                ),
              },
              {
                path: ":commitId",
                children: [
                  {
                    index: true,
                    element: (
                      <ContractsRouteSwitch
                        enabled={<CustomerCommitOverview />}
                      />
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: "credits",
            element: (
              <ContractsRouteSwitch
                enabled={<CustomerCredits />}
                disabled={<CreditsTab />}
              />
            ),
          },
          {
            path: "usage",
            element: (
              <ContractsRouteSwitch
                enabled={<CustomerUsage />}
                disabled={<UsageTab />}
              />
            ),
          },
          {
            path: "alerts",
            element: (
              <ContractsRouteSwitch
                enabled={<CustomerAlerts />}
                disabled={<AlertsTab />}
              />
            ),
          },
          {
            path: "settings",
            element: (
              <ContractsRouteSwitch
                enabled={<CustomerSettings />}
                disabled={<SettingsTab />}
              />
            ),
          },
          {
            path: "audit-logs",
            element: (
              <ContractsRouteSwitch
                enabled={<CustomerAuditLogs />}
                disabled={<AuditLogsTab />}
              />
            ),
          },
          {
            path: "invoices",
            children: [
              {
                path: ":invoiceId",
                children: [
                  {
                    index: true,
                    element: (
                      <ContractsRouteSwitch disabled={<InvoicesTab />} />
                    ),
                  },
                  {
                    path: "correct",
                    element: (
                      <ContractsRouteSwitch disabled={<CorrectInvoice />} />
                    ),
                  },
                ],
              },
            ],
          },

          {
            path: "contracts/add",
            children: [
              {
                index: true,
                element: (
                  <ContractsRouteSwitch enabled={<ContractCreatePage />} />
                ),
              },
            ],
          },

          // CUSTOMER CONTRACT
          {
            path: "contracts/:contractId",
            children: [
              {
                index: true,
                element: (
                  <ContractsRouteSwitch
                    enabled={<CustomerContractOverview />}
                  />
                ),
              },
              {
                path: "amend",
                element: <ContractsRouteSwitch enabled={<AmendmentCreate />} />,
              },
              {
                path: "lifecycle",
                children: [
                  {
                    index: true,
                    element: (
                      <ContractsRouteSwitch
                        enabled={<CustomerContractLifecycle />}
                      />
                    ),
                  },
                  {
                    path: ":lifecycleId",
                    children: [
                      {
                        index: true,
                        element: (
                          <ContractsRouteSwitch
                            enabled={<CustomerContractLifecycle />}
                          />
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "invoices",
                children: [
                  {
                    index: true,
                    element: (
                      <ContractsRouteSwitch
                        enabled={<CustomerContractInvoices />}
                      />
                    ),
                  },
                  {
                    path: ":invoiceId",
                    children: [
                      {
                        index: true,
                        element: (
                          <ContractsRouteSwitch
                            enabled={<CustomerContractInvoices />}
                          />
                        ),
                      },
                      {
                        path: "correct",
                        element: (
                          <ContractsRouteSwitch enabled={<CorrectInvoice />} />
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "commits/*",
                element: <CommitsRedirect />,
              },
              {
                path: "balances/*",
                element: <BalancesRedirect />,
              },
              {
                path: "commits-and-credits",
                children: [
                  {
                    index: true,
                    element: (
                      <ContractsRouteSwitch
                        enabled={<CustomerContractCommits />}
                      />
                    ),
                  },
                  {
                    path: ":commitId",
                    element: (
                      <ContractsRouteSwitch
                        enabled={<CustomerContractCommits />}
                      />
                    ),
                  },
                ],
              },
              {
                path: "settings",
                element: (
                  <ContractsRouteSwitch
                    enabled={<CustomerContractSettings />}
                  />
                ),
              },
            ],
          },

          // PLANS
          {
            path: "plans",
            children: [
              {
                index: true,
                element: <ContractsRouteSwitch disabled={<PlansTab />} />,
              },
              {
                path: "add",
                element: <AddPlanToCustomer />,
              },
              {
                path: ":customerPlanId",
                children: [
                  {
                    index: true,
                    element: (
                      <ContractsRouteSwitch
                        enabled={<ContractCustomerPlanOverview />}
                      />
                    ),
                  },
                  {
                    path: "invoices",
                    children: [
                      {
                        index: true,
                        element: (
                          <ContractsRouteSwitch
                            enabled={<ContractCustomerPlanInvoices />}
                          />
                        ),
                      },
                      {
                        path: ":invoiceId",
                        children: [
                          {
                            index: true,
                            element: (
                              <ContractsRouteSwitch
                                enabled={<ContractCustomerPlanInvoices />}
                              />
                            ),
                          },
                          {
                            path: "correct",
                            element: (
                              <ContractsRouteSwitch
                                enabled={<CorrectInvoice />}
                              />
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "contract-pricing",
    element: <ContractsRouteSwitch enabled={<Outlet />} />,
    children: [
      {
        path: "rate-cards",
        element: <RateCardsList />,
      },
      {
        path: "rate-cards/:id",
        element: <RateCardDetails />,
      },
      {
        path: "rate-cards/new",
        element: (
          <GigaRateCardSwitch
            enabled={<NotFoundPage />}
            disabled={<RateCardCreate />}
          />
        ),
      },
      {
        path: "rate-cards/new2",
        element: <RateCardCreateV2 />,
        children: [
          { path: "new-rate-card", element: <Step1 /> },
          { path: "dimensional-pricing", element: <Step2 /> },
          { path: "rate-products", element: <Step3 /> },
        ],
      },
      {
        path: "rate-cards/:id/edit",
        element: <EditRateCard />,
      },
      {
        path: "products",
        children: [
          {
            index: true,
            element: <ContractProductList />,
          },
          {
            path: ":id",
            element: <ContractProductList />,
          },
        ],
      },
    ],
  },
];
