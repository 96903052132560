import React, { useEffect, useState } from "react";
import { Table, SortFunctions } from "components/Table";
import {
  useGetCustomerCustomFieldsLazyQuery,
  useGetChargeCustomFieldsLazyQuery,
  GetCustomerCustomFieldsQuery,
  GetChargeCustomFieldsQuery,
  useDeleteManagedFieldOnCustomerMutation,
  useDeleteManagedFieldOnChargeMutation,
  useDeleteManagedFieldOnProductMutation,
  useDeleteManagedFieldOnCreditGrantMutation,
  useGetProductCustomFieldsLazyQuery,
  useGetCreditGrantCustomFieldsLazyQuery,
  GetProductCustomFieldsQuery,
  GetCreditGrantCustomFieldsQuery,
  GetPlanCustomFieldsQuery,
  useDeleteManagedFieldOnPlanMutation,
  useGetPlanCustomFieldsLazyQuery,
  SetManagedFieldOnChargeDocument,
  SetManagedFieldOnCommitDocument,
  SetManagedFieldOnContractDocument,
  SetManagedFieldOnContractProductDocument,
  SetManagedFieldOnCreditGrantDocument,
  SetManagedFieldOnCustomerDocument,
  SetManagedFieldOnCustomerPlanDocument,
  SetManagedFieldOnPlanDocument,
  SetManagedFieldOnProductDocument,
  SetManagedFieldOnRateCardDocument,
  SetManagedFieldOnAlertDocument,
  SetManagedFieldOnInvoiceDocument,
  SetManagedFieldOnContractCreditDocument,
  SetManagedFieldOnScheduledChargeDocument,
  SetManagedFieldOnProServiceDocument,
  DeleteManagedFieldOnChargeDocument,
  DeleteManagedFieldOnCommitDocument,
  DeleteManagedFieldOnContractDocument,
  DeleteManagedFieldOnContractProductDocument,
  DeleteManagedFieldOnCreditGrantDocument,
  DeleteManagedFieldOnCustomerPlanDocument,
  DeleteManagedFieldOnPlanDocument,
  DeleteManagedFieldOnProductDocument,
  DeleteManagedFieldOnRateCardDocument,
  DeleteManagedFieldOnAlertDocument,
  DeleteManagedFieldOnInvoiceDocument,
  DeleteManagedFieldOnContractCreditDocument,
  DeleteManagedFieldOnScheduledChargeDocument,
  DeleteManagedFieldOnProServiceDocument,
  useDeleteManagedFieldOnCustomerPlanMutation,
  useGetCustomerPlanCustomFieldsLazyQuery,
  GetCustomerPlanCustomFieldsQuery,
  useGetCommitCustomFieldsLazyQuery,
  useDeleteManagedFieldOnCommitMutation,
  GetCommitCustomFieldsQuery,
  useGetContractCustomFieldsLazyQuery,
  useDeleteManagedFieldOnContractMutation,
  GetContractCustomFieldsQuery,
  useGetContractProductCustomFieldsLazyQuery,
  useDeleteManagedFieldOnContractProductMutation,
  GetContractProductCustomFieldsQuery,
  useGetRateCardCustomFieldsLazyQuery,
  useDeleteManagedFieldOnRateCardMutation,
  GetRateCardCustomFieldsQuery,
  useDeleteManagedFieldOnAlertMutation,
  useDeleteManagedFieldOnInvoiceMutation,
  useDeleteManagedFieldOnContractCreditMutation,
  useDeleteManagedFieldOnScheduledChargeMutation,
  useDeleteManagedFieldOnProServiceMutation,
  GetAlertCustomFieldsQuery,
  GetInvoiceCustomFieldsQuery,
  GetContractCreditCustomFieldsQuery,
  GetScheduledChargeCustomFieldsQuery,
  GetProServiceCustomFieldsQuery,
  useGetAlertCustomFieldsLazyQuery,
  useGetInvoiceCustomFieldsLazyQuery,
  useGetContractCreditCustomFieldsLazyQuery,
  useGetProServiceCustomFieldsLazyQuery,
  useGetScheduledChargeCustomFieldsLazyQuery,
} from "./customFields.graphql";
import { CopyableID } from "components/CopyableID";
import { EmptyState } from "components/EmptyState";
import { AppShell, PageContainer } from "components/PageContainer";
import { AvatarWithName, Tooltip } from "design-system";
import { useRequiredParams } from "lib/routes/params";
import { renderDateTime } from "lib/time";
import { SetCustomFieldModal } from "./components/SetCustomFieldModal";
import { useSnackbar } from "components/Snackbar";
import { useNavigate } from "lib/useNavigate";
import { DeleteManagedFieldOnCustomerDocument } from "./customFields.graphql";
import { GatedButton, GatedIconButton } from "../../components/GatedButton";
import { useUIMode } from "../../lib/useUIMode";
import { twMerge } from "tenaissance/twMerge";
import { Breadcrumbs } from "../../lib/breadcrumbs";

type CustomerManagedFields =
  GetCustomerCustomFieldsQuery["ManagedFieldKey"][0]["CustomerManagedFields"];
type ChargeManagedFields =
  GetChargeCustomFieldsQuery["ManagedFieldKey"][0]["ChargeManagedFields"];
type ProductManagedFields =
  GetProductCustomFieldsQuery["ManagedFieldKey"][0]["ProductManagedFields"];
type CreditGrantManagedFields =
  GetCreditGrantCustomFieldsQuery["ManagedFieldKey"][0]["CreditGrantManagedFields"];
type CustomerPlanManagedFields =
  GetCustomerPlanCustomFieldsQuery["ManagedFieldKey"][0]["CustomerPlanManagedFields"];
type PlanManagedFields =
  GetPlanCustomFieldsQuery["ManagedFieldKey"][0]["PlanManagedFields"];
type CommitManagedFields =
  GetCommitCustomFieldsQuery["ManagedFieldKey"][0]["CommitManagedFields"];
type ContractManagedFields =
  GetContractCustomFieldsQuery["ManagedFieldKey"][0]["ContractManagedFields"];
type ContractProductManagedFields =
  GetContractProductCustomFieldsQuery["ManagedFieldKey"][0]["ContractProductManagedFields"];
type RateCardManagedFields =
  GetRateCardCustomFieldsQuery["ManagedFieldKey"][0]["RateCardManagedFields"];
type AlertManagedFields =
  GetAlertCustomFieldsQuery["ManagedFieldKey"][0]["AlertManagedFields"];
type InvoiceManagedFields =
  GetInvoiceCustomFieldsQuery["ManagedFieldKey"][0]["InvoiceManagedFields"];
type ContractCreditManagedFields =
  GetContractCreditCustomFieldsQuery["ManagedFieldKey"][0]["ContractCreditManagedFields"];
type ScheduledChargeManagedFields =
  GetScheduledChargeCustomFieldsQuery["ManagedFieldKey"][0]["ScheduledChargeManagedFields"];
type ProServiceManagedFields =
  GetProServiceCustomFieldsQuery["ManagedFieldKey"][0]["ProServiceManagedFields"];

interface RawCustomFieldKey {
  id: string;
  key: string;
  entity: string;
  CustomerManagedFields?: CustomerManagedFields;
  ChargeManagedFields?: ChargeManagedFields;
  ProductManagedFields?: ProductManagedFields;
  CreditGrantManagedFields?: CreditGrantManagedFields;
  CustomerPlanManagedFields?: CustomerPlanManagedFields;
  PlanManagedFields?: PlanManagedFields;
  CommitManagedFields?: CommitManagedFields;
  ContractManagedFields?: ContractManagedFields;
  ContractProductManagedFields?: ContractProductManagedFields;
  RateCardManagedFields?: RateCardManagedFields;
  AlertManagedFields?: AlertManagedFields;
  InvoiceManagedFields?: InvoiceManagedFields;
  ContractCreditManagedFields?: ContractCreditManagedFields;
  ScheduledChargeManagedFields?: ScheduledChargeManagedFields;
  ProServiceManagedFields?: ProServiceManagedFields;
}

interface Actor {
  id: string;
  deprecated_at: string | null;
  name: string;
}

type CustomFieldValue = {
  id: string;
  value: string;
  updater: Actor | null;
  updated_at: string;
};

type CustomFieldKey = {
  id: string;
  value: string;
};

export type CustomField = {
  key: CustomFieldKey;
  entity: string;
  value?: CustomFieldValue;
};

interface CustomFieldTableProps {
  customFields: CustomField[];
  loading: boolean;
  deleteRow: (c: CustomField) => Promise<void>;
}

const formatCustomFieldValue = (
  rawManagedKey: RawCustomFieldKey,
): CustomFieldValue | undefined => {
  let customFieldValue;
  switch (rawManagedKey.entity) {
    case "Customer":
      // There should only be one custom field value per entity ID
      customFieldValue = rawManagedKey?.CustomerManagedFields?.[0];
      break;
    case "Charge":
      customFieldValue = rawManagedKey?.ChargeManagedFields?.[0];
      break;
    case "Product":
      customFieldValue = rawManagedKey?.ProductManagedFields?.[0];
      break;
    case "CreditGrant":
      customFieldValue = rawManagedKey?.CreditGrantManagedFields?.[0];
      break;
    case "CustomerPlan":
      customFieldValue = rawManagedKey?.CustomerPlanManagedFields?.[0];
      break;
    case "Plan":
      customFieldValue = rawManagedKey?.PlanManagedFields?.[0];
      break;
    case "Commit":
      customFieldValue = rawManagedKey?.CommitManagedFields?.[0];
      break;
    case "Contract":
      customFieldValue = rawManagedKey?.ContractManagedFields?.[0];
      break;
    case "ContractProduct":
      customFieldValue = rawManagedKey?.ContractProductManagedFields?.[0];
      break;
    case "RateCard":
      customFieldValue = rawManagedKey?.RateCardManagedFields?.[0];
      break;
    case "Alert":
      customFieldValue = rawManagedKey?.AlertManagedFields?.[0];
      break;
    case "Invoice":
      customFieldValue = rawManagedKey?.InvoiceManagedFields?.[0];
      break;
    case "ContractCredit":
      customFieldValue = rawManagedKey?.ContractCreditManagedFields?.[0];
      break;
    case "ScheduledCharge":
      customFieldValue = rawManagedKey?.ScheduledChargeManagedFields?.[0];
      break;
    case "ProService":
      customFieldValue = rawManagedKey?.ProServiceManagedFields?.[0];
      break;
    default:
      break;
  }
  if (customFieldValue) {
    return {
      id: customFieldValue.id,
      value: customFieldValue.value,
      updater: customFieldValue.Updater,
      updated_at: customFieldValue.updated_at,
    };
  }
  return undefined;
};

export const formatCustomFields = (
  rawCustomFieldKeys: RawCustomFieldKey[],
): CustomField[] => {
  return rawCustomFieldKeys.map((rawCustomFieldKey) => {
    return {
      entity: rawCustomFieldKey.entity,
      key: {
        id: rawCustomFieldKey.id,
        value: rawCustomFieldKey.key,
      },
      value: formatCustomFieldValue(rawCustomFieldKey),
    };
  });
};

const CustomFieldsTable: React.FC<CustomFieldTableProps> = ({
  customFields,
  loading,
  deleteRow,
}) => {
  return (
    <Table
      data={customFields}
      defaultSortBy={[{ id: "date_updated" as const, desc: true }]}
      getRowTheme={(cf) => (!!cf.value ? "enabled" : "disabled")}
      columns={[
        {
          id: "key",
          header: "Field name",
          sortable: true,
          comparator: SortFunctions.String((cf) => {
            return cf.key.value;
          }),
          textWrappable: true,
          render: (cf) => cf.key.value,
        },
        {
          header: "Last updated by",
          id: "updated_by",
          sortable: true,
          comparator: SortFunctions.String((cf) => {
            if (cf.value?.updater) {
              return cf.value.updater.name;
            }
            return "";
          }),
          render: (cf) => {
            if (cf.value?.updater) {
              return <AvatarWithName {...cf?.value?.updater} />;
            }
            return <></>;
          },
        },
        {
          header: "Updated at",
          id: "date_updated",
          sortable: true,
          comparator: SortFunctions.Date((cf) => {
            if (cf.value?.updated_at) {
              return new Date(cf.value.updated_at);
            }
          }),
          render: (cf) => {
            if (cf.value?.updated_at) {
              return renderDateTime(new Date(cf.value.updated_at), true);
            }
            return <></>;
          },
        },
        {
          id: "value",
          header: "Field value",
          sortable: true,
          comparator: SortFunctions.String((cf) => {
            if (cf.value?.value) {
              return cf.value.value;
            }
            return "";
          }),
          render: (cf) => {
            if (cf.value?.value) {
              return <CopyableID id={cf.value.value} label={cf.value.value} />;
            }
            return "No value has been set";
          },
        },
        {
          header: "",
          id: "actions",
          render: (customField) => {
            return (
              <Tooltip content="Remove value" disabled={!customField.value}>
                <GatedIconButton
                  onClick={async () => deleteRow(customField)}
                  disabled={!customField.value}
                  doc={
                    {
                      Charge: DeleteManagedFieldOnChargeDocument,
                      Commit: DeleteManagedFieldOnCommitDocument,
                      Contract: DeleteManagedFieldOnContractDocument,
                      ContractProduct:
                        DeleteManagedFieldOnContractProductDocument,
                      CreditGrant: DeleteManagedFieldOnCreditGrantDocument,
                      Customer: DeleteManagedFieldOnCustomerDocument,
                      CustomerPlan: DeleteManagedFieldOnCustomerPlanDocument,
                      Plan: DeleteManagedFieldOnPlanDocument,
                      Product: DeleteManagedFieldOnProductDocument,
                      RateCard: DeleteManagedFieldOnRateCardDocument,
                      Alert: DeleteManagedFieldOnAlertDocument,
                      Invoice: DeleteManagedFieldOnInvoiceDocument,
                      ContractCredit:
                        DeleteManagedFieldOnContractCreditDocument,
                      ScheduledCharge:
                        DeleteManagedFieldOnScheduledChargeDocument,
                      ProService: DeleteManagedFieldOnProServiceDocument,
                    }[customField.entity]
                  }
                  theme="tertiary"
                  icon="trash03"
                  size="sm"
                />
              </Tooltip>
            );
          },
          align: "right",
        },
      ]}
      loading={loading}
      maxPageSize={20}
    />
  );
};

const CustomFieldsPage: React.FC<{}> = () => {
  const { id, entity } = useRequiredParams("id", "entity");
  const pushMessage = useSnackbar();
  const navigate = useNavigate();
  const [showSetCustomFieldModal, setshowSetCustomFieldModal] = useState(false);
  const [customFields, setCustomFields] = useState<CustomField[] | undefined>(
    undefined,
  );
  const [
    customerCustomFields,
    { data: customerData, loading: customerLoading },
  ] = useGetCustomerCustomFieldsLazyQuery();
  const [chargeCustomFields, { data: chargeData, loading: chargeLoading }] =
    useGetChargeCustomFieldsLazyQuery();
  const [productCustomFields, { data: productData, loading: productLoading }] =
    useGetProductCustomFieldsLazyQuery();
  const [
    creditGrantCustomFields,
    { data: creditGrantData, loading: creditGrantLoading },
  ] = useGetCreditGrantCustomFieldsLazyQuery();
  const [
    customerPlanCustomFields,
    { data: customerPlanData, loading: customerPlanLoading },
  ] = useGetCustomerPlanCustomFieldsLazyQuery();
  const [planCustomFields, { data: planData, loading: planLoading }] =
    useGetPlanCustomFieldsLazyQuery();
  const [commitCustomFields, { data: commitData, loading: commitLoading }] =
    useGetCommitCustomFieldsLazyQuery();
  const [
    contractCustomFields,
    { data: contractData, loading: contractLoading },
  ] = useGetContractCustomFieldsLazyQuery();
  const [
    contractProductCustomFields,
    { data: contractProductData, loading: contractProductLoading },
  ] = useGetContractProductCustomFieldsLazyQuery();
  const [
    rateCardCustomFields,
    { data: rateCardData, loading: rateCardLoading },
  ] = useGetRateCardCustomFieldsLazyQuery();
  const [alertCustomFields, { data: alertData, loading: alertLoading }] =
    useGetAlertCustomFieldsLazyQuery();
  const [invoiceCustomFields, { data: invoiceData, loading: invoiceLoading }] =
    useGetInvoiceCustomFieldsLazyQuery();
  const [
    contractCreditCustomFields,
    { data: contractCreditData, loading: contractCreditLoading },
  ] = useGetContractCreditCustomFieldsLazyQuery();
  const [
    scheduledChargeCustomFields,
    { data: scheduledChargeData, loading: scheduledChargeLoading },
  ] = useGetScheduledChargeCustomFieldsLazyQuery();
  const [
    proServiceCustomFields,
    { data: proServiceData, loading: proServiceLoading },
  ] = useGetProServiceCustomFieldsLazyQuery();
  const [deleteCustomerCustomFieldValue] =
    useDeleteManagedFieldOnCustomerMutation();
  const [deleteChargeCustomFieldValue] =
    useDeleteManagedFieldOnChargeMutation();
  const [deleteProductCustomFieldValue] =
    useDeleteManagedFieldOnProductMutation();
  const [deleteCreditGrantCustomFieldValue] =
    useDeleteManagedFieldOnCreditGrantMutation();
  const [deleteCustomerPlanCustomFieldValue] =
    useDeleteManagedFieldOnCustomerPlanMutation();
  const [deletePlanCustomFieldValue] = useDeleteManagedFieldOnPlanMutation();
  const [deleteCommitCustomFieldValue] =
    useDeleteManagedFieldOnCommitMutation();
  const [deleteContractCustomFieldValue] =
    useDeleteManagedFieldOnContractMutation();
  const [deleteContractProductCustomFieldValue] =
    useDeleteManagedFieldOnContractProductMutation();
  const [deleteRateCardCustomFieldValue] =
    useDeleteManagedFieldOnRateCardMutation();
  const [deleteAlertCustomFieldValue] = useDeleteManagedFieldOnAlertMutation();
  const [deleteInvoiceCustomFieldValue] =
    useDeleteManagedFieldOnInvoiceMutation();
  const [deleteContractCreditCustomFieldValue] =
    useDeleteManagedFieldOnContractCreditMutation();
  const [deleteScheduledChargeCustomFieldValue] =
    useDeleteManagedFieldOnScheduledChargeMutation();
  const [deleteProServiceCustomFieldValue] =
    useDeleteManagedFieldOnProServiceMutation();
  const { newUIEnabled } = useUIMode();

  useEffect(() => {
    switch (entity) {
      case "customer":
        void customerCustomFields({
          variables: {
            customer_id: id,
          },
        });
        break;
      case "charge":
        void chargeCustomFields({
          variables: {
            ppf_id: id,
          },
        });
        break;
      case "product":
        void productCustomFields({
          variables: {
            product_id: id,
          },
        });
        break;
      case "credit-grant":
        void creditGrantCustomFields({
          variables: {
            credit_grant_id: id,
          },
        });
        break;
      case "customer-plan":
        void customerPlanCustomFields({
          variables: {
            customer_plan_id: id,
          },
        });
        break;
      case "plan":
        void planCustomFields({
          variables: {
            plan_id: id,
          },
        });
        break;
      case "commit":
        void commitCustomFields({
          variables: {
            commit_id: id,
          },
        });
        break;
      case "contract":
        void contractCustomFields({
          variables: {
            contract_id: id,
          },
        });
        break;
      case "contract-product":
        void contractProductCustomFields({
          variables: {
            contract_product_id: id,
          },
        });
        break;
      case "rate-card":
        void rateCardCustomFields({
          variables: {
            rate_card_id: id,
          },
        });
        break;
      case "alert":
        void alertCustomFields({
          variables: {
            alert_id: id,
          },
        });
        break;
      case "invoice":
        void invoiceCustomFields({
          variables: {
            invoice_id: id,
          },
        });
        break;
      case "contract-credit":
        void contractCreditCustomFields({
          variables: {
            contract_credit_id: id,
          },
        });
        break;
      case "scheduled-charge":
        void scheduledChargeCustomFields({
          variables: {
            scheduled_charge_id: id,
          },
        });
        break;
      case "professional-service":
        void proServiceCustomFields({
          variables: {
            pro_service_id: id,
          },
        });
        break;
      default:
        throw new Error(`Entity not supported: ${entity}`);
    }
  }, [entity, id]);

  useEffect(() => {
    if (
      customerData ||
      chargeData ||
      productData ||
      creditGrantData ||
      customerPlanData ||
      planData ||
      commitData ||
      contractData ||
      contractProductData ||
      rateCardData ||
      alertData ||
      invoiceData ||
      contractCreditData ||
      scheduledChargeData ||
      proServiceData
    ) {
      switch (entity) {
        case "customer":
          setCustomFields(
            formatCustomFields(customerData?.ManagedFieldKey ?? []),
          );
          break;
        case "charge":
          setCustomFields(
            formatCustomFields(chargeData?.ManagedFieldKey ?? []),
          );
          break;
        case "product":
          setCustomFields(
            formatCustomFields(productData?.ManagedFieldKey ?? []),
          );
          break;
        case "credit-grant":
          setCustomFields(
            formatCustomFields(creditGrantData?.ManagedFieldKey ?? []),
          );
          break;
        case "customer-plan":
          setCustomFields(
            formatCustomFields(customerPlanData?.ManagedFieldKey ?? []),
          );
          break;
        case "plan":
          setCustomFields(formatCustomFields(planData?.ManagedFieldKey ?? []));
          break;
        case "commit":
          setCustomFields(
            formatCustomFields(commitData?.ManagedFieldKey ?? []),
          );
          break;
        case "contract":
          setCustomFields(
            formatCustomFields(contractData?.ManagedFieldKey ?? []),
          );
          break;
        case "contract-product":
          setCustomFields(
            formatCustomFields(contractProductData?.ManagedFieldKey ?? []),
          );
          break;
        case "rate-card":
          setCustomFields(
            formatCustomFields(rateCardData?.ManagedFieldKey ?? []),
          );
          break;
        case "alert":
          setCustomFields(formatCustomFields(alertData?.ManagedFieldKey ?? []));
          break;
        case "invoice":
          setCustomFields(
            formatCustomFields(invoiceData?.ManagedFieldKey ?? []),
          );
          break;
        case "contract-credit":
          setCustomFields(
            formatCustomFields(contractCreditData?.ManagedFieldKey ?? []),
          );
          break;
        case "scheduled-charge":
          setCustomFields(
            formatCustomFields(scheduledChargeData?.ManagedFieldKey ?? []),
          );
          break;
        case "professional-service":
          setCustomFields(
            formatCustomFields(proServiceData?.ManagedFieldKey ?? []),
          );
          break;
        default:
          throw new Error(`Entity not supported: ${entity}`);
      }
    }
  }, [
    customerData,
    chargeData,
    productData,
    creditGrantData,
    customerPlanData,
    planData,
    commitData,
    contractData,
    contractProductData,
    rateCardData,
    alertData,
    invoiceData,
    contractCreditData,
    scheduledChargeData,
    proServiceData,
  ]);

  const loading =
    customerLoading ||
    chargeLoading ||
    productLoading ||
    creditGrantLoading ||
    customerPlanLoading ||
    planLoading ||
    commitLoading ||
    contractLoading ||
    contractProductLoading ||
    rateCardLoading ||
    alertLoading ||
    invoiceLoading ||
    contractCreditLoading ||
    scheduledChargeLoading ||
    proServiceLoading;
  const isEmptyState =
    !loading && customFields !== undefined && customFields.length === 0;

  const content = (
    <>
      {isEmptyState ? (
        <EmptyState
          title="No custom fields are set"
          subtitle="Add a custom field to see it here"
          buttonText="Create a custom field"
          onClick={() =>
            navigate(
              newUIEnabled
                ? "/connections/custom-fields"
                : "/settings/general/custom-fields",
            )
          }
          icon="barLineChart"
          buttonIcon="plus"
        />
      ) : (
        <div className={twMerge(!newUIEnabled && "pt-12")}>
          {customFields ? (
            <SetCustomFieldModal
              id={id}
              customFields={customFields ?? []}
              entity={entity}
              onClose={() => setshowSetCustomFieldModal(false)}
              isOpen={showSetCustomFieldModal}
            />
          ) : null}
          <CustomFieldsTable
            customFields={customFields ?? []}
            deleteRow={async (customField: CustomField) => {
              if (customField.value) {
                switch (customField.entity) {
                  case "Customer":
                    await deleteCustomerCustomFieldValue({
                      variables: {
                        id: customField.value.id,
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "ManagedFieldKey",
                        });
                      },
                    });
                    break;
                  case "Charge":
                    await deleteChargeCustomFieldValue({
                      variables: {
                        id: customField.value.id,
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "ManagedFieldKey",
                        });
                      },
                    });
                    break;
                  case "Product":
                    await deleteProductCustomFieldValue({
                      variables: {
                        id: customField.value.id,
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "ManagedFieldKey",
                        });
                      },
                    });
                    break;
                  case "CreditGrant":
                    await deleteCreditGrantCustomFieldValue({
                      variables: {
                        id: customField.value.id,
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "ManagedFieldKey",
                        });
                      },
                    });
                    break;
                  case "CustomerPlan":
                    await deleteCustomerPlanCustomFieldValue({
                      variables: {
                        id: customField.value.id,
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "ManagedFieldKey",
                        });
                      },
                    });
                    break;
                  case "Plan":
                    await deletePlanCustomFieldValue({
                      variables: {
                        id: customField.value.id,
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "ManagedFieldKey",
                        });
                      },
                    });
                    break;
                  case "Commit":
                    await deleteCommitCustomFieldValue({
                      variables: {
                        id: customField.value.id,
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "ManagedFieldKey",
                        });
                      },
                    });
                    break;
                  case "Contract":
                    await deleteContractCustomFieldValue({
                      variables: {
                        id: customField.value.id,
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "ManagedFieldKey",
                        });
                      },
                    });
                    break;
                  case "ContractProduct":
                    await deleteContractProductCustomFieldValue({
                      variables: {
                        id: customField.value.id,
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "ManagedFieldKey",
                        });
                      },
                    });
                    break;
                  case "RateCard":
                    await deleteRateCardCustomFieldValue({
                      variables: {
                        id: customField.value.id,
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "ManagedFieldKey",
                        });
                      },
                    });
                    break;
                  case "Alert":
                    await deleteAlertCustomFieldValue({
                      variables: {
                        id: customField.value.id,
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "ManagedFieldKey",
                        });
                      },
                    });
                    break;
                  case "Invoice":
                    await deleteInvoiceCustomFieldValue({
                      variables: {
                        id: customField.value.id,
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "ManagedFieldKey",
                        });
                      },
                    });
                    break;
                  case "ContractCredit":
                    await deleteContractCreditCustomFieldValue({
                      variables: {
                        id: customField.value.id,
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "ManagedFieldKey",
                        });
                      },
                    });
                    break;
                  case "ScheduledCharge":
                    await deleteScheduledChargeCustomFieldValue({
                      variables: {
                        id: customField.value.id,
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "ManagedFieldKey",
                        });
                      },
                    });
                    break;
                  case "ProService":
                    await deleteProServiceCustomFieldValue({
                      variables: {
                        id: customField.value.id,
                      },
                      update(cache) {
                        cache.evict({
                          fieldName: "ManagedFieldKey",
                        });
                      },
                    });
                    break;
                  default:
                    break;
                }
                pushMessage({
                  content: `Custom field key "${customField.key.value}" with value "${customField.value.value}" deleted`,
                  type: "success",
                  durationMS: 3000,
                });
              }
            }}
            loading={loading}
          />
        </div>
      )}
    </>
  );

  const headerAction = (
    <GatedButton
      onClick={() => setshowSetCustomFieldModal(true)}
      disabled={isEmptyState}
      doc={
        {
          "contract-credit": SetManagedFieldOnContractCreditDocument,
          "contract-product": SetManagedFieldOnContractProductDocument,
          "credit-grant": SetManagedFieldOnCreditGrantDocument,
          "customer-plan": SetManagedFieldOnCustomerPlanDocument,
          "professional-service": SetManagedFieldOnProServiceDocument,
          "rate-card": SetManagedFieldOnRateCardDocument,
          "scheduled-charge": SetManagedFieldOnScheduledChargeDocument,
          alert: SetManagedFieldOnAlertDocument,
          charge: SetManagedFieldOnChargeDocument,
          commit: SetManagedFieldOnCommitDocument,
          contract: SetManagedFieldOnContractDocument,
          customer: SetManagedFieldOnCustomerDocument,
          invoice: SetManagedFieldOnInvoiceDocument,
          plan: SetManagedFieldOnPlanDocument,
          product: SetManagedFieldOnProductDocument,
        }[entity]
      }
      text="Set custom field"
      theme="primary"
      leadingIcon="plus"
    />
  );

  return newUIEnabled ? (
    <AppShell
      title="Manage custom fields"
      headerProps={{
        actions: headerAction,
        breadcrumbs: Breadcrumbs.from(
          { type: "back", label: "" },
          {
            label: "Custom fields",
            routePath: "/connections/custom-fields",
          },
        ),
      }}
    >
      {content}
    </AppShell>
  ) : (
    <PageContainer title="Manage custom fields" action={headerAction}>
      {content}
    </PageContainer>
  );
};

export default CustomFieldsPage;
