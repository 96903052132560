import React from "react";
import {
  Environment,
  useEnvironment,
} from "../../../lib/environmentSwitcher/context";
import { EnvironmentTypeEnum_Enum } from "../../../types/generated-graphql/__types__";
import { Tooltip } from "../Tooltip";
import { Dropdown, DropdownItem } from "../Dropdown";

const environmentOrder = [
  EnvironmentTypeEnum_Enum.Production,
  EnvironmentTypeEnum_Enum.Uat,
  EnvironmentTypeEnum_Enum.Qa,
  EnvironmentTypeEnum_Enum.Staging,
  EnvironmentTypeEnum_Enum.Sandbox,
];

export const EnvironmentSwitcher: React.FC<{}> = () => {
  const { environments, environment, changingSupported, setEnvironment } =
    useEnvironment();

  const options = environmentOrder
    .map((et) => environments.find((e) => e.type === et))
    .filter<Environment>((e): e is Environment => !!e && e.enabled)
    .map((e) => (
      <DropdownItem
        key={e.id}
        label={e.name}
        onClick={({ value }) => {
          const newEnvironment = environments.find((e) => e.id === value);

          if (newEnvironment) {
            setEnvironment(newEnvironment);
          }
        }}
        value={e.id}
      />
    ));

  return (
    <div className="max-w-[128px] flex-1">
      <Tooltip
        label={"Can't switch environments while viewing a specific resource."}
        disabled={changingSupported}
      >
        <Dropdown
          disabled={!changingSupported}
          selectedOption={environment.name}
          label={environment.name}
          fullWidth={true}
        >
          {options}
        </Dropdown>
      </Tooltip>
    </div>
  );
};
