import React from "react";
import classNames from "classnames";

import { Breadcrumbs } from "lib/breadcrumbs";
import { useRequiredParam } from "lib/routes/params";
import { AppShell, PageContainer } from "components/PageContainer";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { NotFoundPage } from "pages/404";
import { ParagraphSkeleton } from "components/Skeleton";

import { Contract } from "../../../lib/Contract";
import {
  ApolloResp,
  ApolloSuccess,
  isLoaded,
} from "pages/Contracts/lib/ApolloResp";
import { useFeatureFlag } from "lib/launchdarkly";
import { useUIMode } from "../../../../../lib/useUIMode";

type MinReq = ApolloResp<{
  customer: { name: string; contract: Contract.NameFragment | null };
}>;

type RenderProps<Req extends MinReq> = ApolloSuccess<Req> & {
  contract: NonNullable<ApolloSuccess<Req>["customer"]["contract"]>;
};

interface Props<Req extends MinReq> {
  rootReq: Req;
  tabsAction?: React.ReactNode;
  disableContainerScroll?: boolean | ((props: RenderProps<Req>) => boolean);
  content: (props: RenderProps<Req>) => React.ReactNode;
}

function toRenderProps<Req extends MinReq>(req: Req): RenderProps<Req> {
  if (!isLoaded(req) || !req.customer.contract) {
    throw new Error("req must be loaded");
  }

  return {
    ...req,
    contract: req.customer.contract,
  };
}

export function CustomerContractLayout<Cust extends MinReq>({
  rootReq,
  tabsAction,
  disableContainerScroll,
  content,
}: Props<Cust>) {
  const { newUIEnabled } = useUIMode();
  const customerId = useRequiredParam("customerId");
  const contractId = useRequiredParam("contractId");

  const editEnabled = useFeatureFlag<boolean>("allow-contract-editing", false);

  if (
    rootReq.state === "not found" ||
    // customer loaded but contract id is invalid or not found
    (isLoaded(rootReq) && !rootReq.customer.contract)
  ) {
    return <NotFoundPage />;
  }

  if (rootReq.state === "error") {
    const errorContent = (
      <ErrorEmptyState
        title="We were unable to load this contract"
        error={rootReq.error}
      />
    );
    return newUIEnabled ? (
      <AppShell title="Unable to load contract">{errorContent}</AppShell>
    ) : (
      <PageContainer title="Unable to load contract" hideHeader>
        {errorContent}
      </PageContainer>
    );
  }
  const routePath = `/customers/${customerId}/contracts/${contractId}`;

  // disable container scroll if displaying empty state for contract commits
  const scrollDisabled =
    typeof disableContainerScroll === "function"
      ? isLoaded(rootReq)
        ? disableContainerScroll(toRenderProps(rootReq))
        : true
      : !!disableContainerScroll;

  const pageContent = (
    <div
      className={classNames("max-w-[2400px]", "mb-12", {
        ["flex h-full flex-col overflow-hidden"]: scrollDisabled,
      })}
    >
      {isLoaded(rootReq) ? (
        content(toRenderProps(rootReq))
      ) : (
        <ParagraphSkeleton numLines={6} className="w-4/5 xl:w-3/4" />
      )}
    </div>
  );

  return newUIEnabled ? (
    <AppShell
      title={
        rootReq.state === "loading" || !rootReq.customer.contract
          ? "Loading..."
          : Contract.getName(rootReq.customer.contract)
      }
      headerProps={{
        actions: tabsAction,
        tabs: [
          { name: "Contract overview", path: routePath, exactMatch: true },
          {
            name: "Invoices",
            path: `${routePath}/invoices`,
          },
          {
            name: "Balances",
            path: `${routePath}/commits-and-credits`,
          },
          ...(editEnabled
            ? []
            : [
                {
                  name: "Lifecycle",
                  path: `${routePath}/lifecycle`,
                },
              ]),
        ],
        breadcrumbs: Breadcrumbs.from({
          label: rootReq.customer?.name ?? "",
          routePath: `/customers/${customerId}`,
        }),
      }}
    >
      {pageContent}
    </AppShell>
  ) : (
    <PageContainer
      disableContainerScroll={scrollDisabled}
      title={Breadcrumbs.from(
        {
          type: "back",
          label: "Go back to Customer",
          routePath: `/customers/${customerId}`,
        },
        rootReq.state === "loading"
          ? Breadcrumbs.loading
          : {
              label: rootReq.customer.name,
              routePath: `/customers/${customerId}`,
            },
        rootReq.state === "loading" || !rootReq.customer.contract
          ? Breadcrumbs.loading
          : {
              label: Contract.getName(rootReq.customer.contract),
              routePath,
            },
      )}
      tabs={[
        { name: "Contract overview", routePath },
        {
          name: "Invoices",
          routePath: `${routePath}/invoices`,
          activePatterns: [`${routePath}/invoices/*`],
        },
        {
          name: "Commits and Credits",
          routePath: `${routePath}/commits-and-credits`,
          activePatterns: [`${routePath}/commits-and-credits/*`],
        },
        ...(editEnabled
          ? []
          : [
              {
                name: "Lifecycle",
                routePath: `${routePath}/lifecycle`,
                activePatterns: [`${routePath}/lifecycle/*`],
              },
            ]),
      ]}
      tabsAction={tabsAction}
    >
      {pageContent}
    </PageContainer>
  );
}
