import React, { useEffect, useState } from "react";

import {
  BillableMetricsDocument,
  BillableMetricsQuery,
  useBillableMetricsQuery,
} from "./queries.graphql";
import {
  BillableMetricGroupKeyFilterEnum,
  BillableMetricSortingOrderByEnum,
  BillableMetricTypeEnum,
} from "types/generated-graphql/__types__";

import { AvatarWithName, Input } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { AggregationBadge } from "components/BillableMetricsBadge";
import { CopyableID } from "components/CopyableID";
import { EmptyState } from "components/EmptyState";
import { Filter, FilterOptions, OptionType } from "components/Filter";
import { PageContainer } from "components/PageContainer";
import { Column, SimpleTable } from "components/SimpleTable";
import { PopoverMenu } from "components/PopoverMenu";
import { TableSkeleton } from "components/Table";
import { SearchTooltip } from "components/SearchTooltip";
import { useActions } from "lib/billableMetrics/actions";
import useDebounce from "lib/debounce";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { renderDate, renderDateTimeInUTC } from "lib/time";
import { useNavigate } from "lib/useNavigate";
import { useSearchParam } from "lib/routes/useSearchParam";
import { InsertBillableMetricDocument } from "pages/NewBillableMetric/queries.graphql";
import { useFeatureFlag } from "lib/launchdarkly";
import MetricBadge from "./components/MetricBadge";
import { GatedButton } from "../../components/GatedButton";

const NUM_ROWS = 15;

type Metric = BillableMetricsQuery["billable_metrics"][0];

const BillableMetrics: React.FC = () => {
  const navigate = useNavigate();
  const flexAggEnabled = useFeatureFlag<boolean>("flex-agg-ui", false);

  const statusOptions: OptionType[] = [
    {
      label: "Active",
      value: BillableMetricTypeEnum.Active,
      group: "status",
      type: "multi",
    },
    {
      label: "Archived",
      value: BillableMetricTypeEnum.Archived,
      group: "status",
      type: "multi",
    },
  ];

  const groupKeyOptions: OptionType[] = [
    {
      label: "Billable Metrics with group keys",
      value: BillableMetricGroupKeyFilterEnum.NonEmpty,
      group: "group_key",
      type: "multi",
    },
    {
      label: "Billable Metrics without group keys",
      value: BillableMetricGroupKeyFilterEnum.Empty,
      group: "group_key",
      type: "multi",
    },
  ];

  const filterOptions: FilterOptions = {
    status: {
      label: "Billable Metric Status",
      options: statusOptions,
    },
    group_key: {
      label: "Group key",
      options: groupKeyOptions,
    },
  };

  const [filters, setFilters] = useState<readonly OptionType[]>([
    statusOptions[0],
    ...filterOptions.group_key.options,
  ]);

  const resetFilters = () => {
    setFilters([statusOptions[0], ...filterOptions.group_key.options]);
  };

  const [sortOrder, setSortOrder] = useState<{
    column: string;
    direction: "asc" | "desc";
  } | null>(null);

  const [searchQuery, setSearchQuery] = useSearchParam("q");
  const debouncedSearchQuery = useDebounce(searchQuery.trim(), 400);

  const [pageNumberToCursor, setPageNumberToCursor] = React.useState<string[]>(
    [],
  );
  const [currentPage, setCurrentPage] = React.useState(0);
  const [currentCursor, setCurrentCursor] = React.useState<string | undefined>(
    undefined,
  );

  const { environmentType } = useEnvironment();

  const groupKeyFilters = filters.filter((f) => f.group === "group_key");

  useEffect(() => {
    setPageNumberToCursor([]);
    setCurrentPage(0);
    setCurrentCursor(undefined);
  }, [debouncedSearchQuery, filters]);

  const { data, loading } = useBillableMetricsQuery({
    variables: {
      environment_type: environmentType,
      types: filters
        .filter((f) => f.group === "status")
        .map((f) => f.value as BillableMetricTypeEnum),
      search: debouncedSearchQuery || undefined,
      sort: !sortOrder
        ? undefined
        : {
            order_by:
              {
                name: BillableMetricSortingOrderByEnum.Name,
                aggregate: BillableMetricSortingOrderByEnum.Aggregate,
                created_by: BillableMetricSortingOrderByEnum.CreatedBy,
                created_at: BillableMetricSortingOrderByEnum.CreatedAt,
                id: BillableMetricSortingOrderByEnum.Id,
              }[sortOrder.column] ?? BillableMetricSortingOrderByEnum.Id,
            ascending: sortOrder.direction === "asc",
          },
      limit: NUM_ROWS + 1,
      cursor: currentCursor,
      group_key_filter:
        groupKeyFilters.length === 1
          ? (groupKeyFilters[0].value as BillableMetricGroupKeyFilterEnum)
          : undefined,
    },
  });

  const allMetrics = data?.billable_metrics || [];
  const filteredMetrics = allMetrics.slice(0, NUM_ROWS);
  const hasMore = allMetrics.length > NUM_ROWS;

  const goNextPage = () => {
    const newCursor = allMetrics[NUM_ROWS - 1].id;
    setPageNumberToCursor({
      ...pageNumberToCursor,
      [currentPage + 1]: newCursor,
    });
    setCurrentPage(currentPage + 1);
    setCurrentCursor(newCursor);
  };

  const goPrevPage = () => {
    const newCursor = pageNumberToCursor[currentPage - 1];
    if (!newCursor) {
      setCurrentPage(0);
      setCurrentCursor(undefined);
    } else {
      setCurrentCursor(newCursor);
      setCurrentPage(currentPage - 1);
    }
  };

  const { getActions, archiveModal, copyMetricModal } = useActions();

  const columns: (Column<Metric> & { key: string })[] = [
    {
      header: "Name",
      key: "name",
      sort: sortOrder?.column === "name" ? sortOrder.direction : "none",
      render: (m) => (
        <span>
          <MetricBadge deletedAt={m.deleted_at} />
          <CopyableID id={m.id} label="metric ID" hideID />
          {m.name}
        </span>
      ),
    },
    {
      header: "Created by",
      key: "created_by",
      sort: sortOrder?.column === "created_by" ? sortOrder.direction : "none",
      render: (m) => m.Creator && <AvatarWithName {...m.Creator} />,
    },
    {
      header: "Date Created (UTC)",
      key: "created_at",
      sort: sortOrder?.column === "created_at" ? sortOrder.direction : "none",
      render: (m) => (
        <span title={renderDateTimeInUTC(new Date(m.created_at), false)}>
          {renderDate(new Date(m.created_at), {
            isUtc: true,
            excludeUtcLabel: true,
          })}
        </span>
      ),
      alignment: "right",
    },
    ...(flexAggEnabled
      ? [
          // hardcoded - need to sub for actual flex-agg type, and update BM type
          {
            header: "Type",
            key: "type",
            render: (m: Metric) => (m.sql ? "SQL" : "Basic"),
          },
        ]
      : []),
    {
      header: "Products",
      key: "products",
      render: (m) => `${m.active_product_count} products`,
      alignment: "right",
    },
    {
      header: "Plans",
      key: "plans",
      render: (m) => `${m.active_plan_count} plans`,
      alignment: "right",
    },
    {
      header: "Aggregate",
      key: "aggregate",
      sort: sortOrder?.column === "aggregate" ? sortOrder.direction : "none",
      render: (m) =>
        m.sql ? undefined : (
          <AggregationBadge
            aggregation={m.aggregate}
            keys={(m.aggregate_keys as string[]) ?? []}
          />
        ),
    },
    {
      header: "",
      alignment: "right",
      key: "actions",
      render: (m) => (
        <PopoverMenu
          positions={["bottom", "top"]}
          align="end"
          options={getActions({ ...m, metricType: "billable" })}
        >
          {(onClick) => (
            <IconButton
              onClick={onClick}
              theme="tertiary"
              icon="dotsVertical"
              size="sm"
            />
          )}
        </PopoverMenu>
      ),
    },
  ];

  const seatsEnabled = useFeatureFlag<boolean>("seats", false);

  const tabActions = (
    <div className="flex flex-row items-center">
      <SearchTooltip searchText="metrics">
        <Input
          type="search"
          placeholder="Search"
          value={searchQuery}
          onChange={setSearchQuery}
          leftIcon="search"
          className="w-[208px]"
        />
      </SearchTooltip>
      <Filter
        value={filters}
        options={filterOptions}
        onChange={setFilters}
        onReset={resetFilters}
      />
      <GatedButton
        doc={InsertBillableMetricDocument}
        onClick={() => navigate("/billable-metrics/new")}
        className="ml-12"
        text="Add new Billable Metric"
        theme="primary"
        leadingIcon="plus"
        size="sm"
      />
    </div>
  );

  return (
    <PageContainer
      title="Billable metrics overview"
      authDoc={BillableMetricsDocument}
      tabs={
        seatsEnabled
          ? [
              {
                name: "Standard metric",
                active: true,
                routePath: "/billable-metrics",
              },
              {
                name: "Seats",
                active: false,
                routePath: "/billable-metrics/seats",
              },
            ]
          : undefined
      }
      action={seatsEnabled ? undefined : tabActions}
      tabsAction={seatsEnabled ? tabActions : undefined}
    >
      {!loading && allMetrics.length === 0 ? (
        <EmptyState
          title="You don't have any billable metrics yet."
          subtitle="Once you add billable metrics, you'll see their details here."
          buttonAuthDoc={InsertBillableMetricDocument}
          buttonText="Add new billable metric"
          onClick={() => navigate("/billable-metrics/new")}
          icon="barLineChart"
          buttonIcon="plus"
        />
      ) : /*
        When allMetrics is loaded filters will not be updated. This will filter everything out because no group keys match.
        Once filters are updated even if there are 0 group keys the default "None" filter will bring the filter count from 3 to 4.
      */
      !loading && filteredMetrics.length === 0 && filters.length !== 3 ? (
        <EmptyState
          title="No matching metrics found."
          subtitle={`Try a different ${
            debouncedSearchQuery.length ? "search term" : "filter"
          }.`}
          icon="barLineChart"
        />
      ) : loading ? (
        <TableSkeleton
          numRows={NUM_ROWS}
          columnNames={columns.map((c) =>
            typeof c.header === "string" ? c.header : "",
          )}
        />
      ) : (
        <>
          {archiveModal}
          {copyMetricModal}

          <SimpleTable
            data={filteredMetrics}
            rowRoutePath={(m) => `/billable-metrics/${m.id}`}
            onSortClick={(index) => {
              const columnKey = columns[index].key;
              if (sortOrder?.column !== columnKey) {
                setSortOrder({ column: columnKey, direction: "asc" });
              } else if (sortOrder.direction === "asc") {
                setSortOrder({ column: columnKey, direction: "desc" });
              } else {
                setSortOrder(null);
              }
              setCurrentCursor(undefined);
              setPageNumberToCursor([]);
              setCurrentPage(0);
            }}
            paginationButtons={[
              {
                page: "prev",
                disabled: currentPage == 0,
                onClick: goPrevPage,
              },
              ...(currentPage > 0
                ? [
                    {
                      page: currentPage,
                      onClick: goPrevPage,
                    },
                  ]
                : []),
              {
                page: currentPage + 1,
                onClick: () => {},
                selected: true,
              },
              ...(hasMore
                ? [
                    {
                      page: currentPage + 2,
                      onClick: goNextPage,
                    },
                  ]
                : []),
              {
                page: "next",
                onClick: goNextPage,
                disabled: !hasMore,
              },
            ]}
            columns={columns}
          />
        </>
      )}
    </PageContainer>
  );
};

export default BillableMetrics;
