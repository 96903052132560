import { Toggle } from "design-system";
import React, { Dispatch, useEffect, useState } from "react";
import { TextInput } from "tenaissance/components/Input";
import { StripeSettingsDataType } from "..";

interface InvoiceDaysUntilDueProps {
  invoiceDaysUntilDue?: string;
  setStripeSettingsData: Dispatch<React.SetStateAction<StripeSettingsDataType>>;
  setShouldSaveStripeChanges: Dispatch<React.SetStateAction<boolean>>;
}

export const InvoiceDaysUntilDue: React.FC<InvoiceDaysUntilDueProps> = ({
  invoiceDaysUntilDue,
  setStripeSettingsData,
  setShouldSaveStripeChanges,
}) => {
  const [isCheckedValue, setIsCheckedValue] = useState<boolean | undefined>(
    !!invoiceDaysUntilDue,
  );
  const [isInputValid, setIsInputValid] = useState(true);
  const [inputHelpText, setInputHelpText] = useState("");
  const [initialInvoicesUntilDue] = useState(invoiceDaysUntilDue);
  const [newInvoiceDaysUntilDueValue, setNewInvoiceDaysUntilDueValue] =
    useState<string | null>(null);
  const [hasInteractedWithInput, setHasInteractedWithInput] = useState(false);

  const handleEmptyValue = () => {
    if (hasInteractedWithInput) {
      setInputHelpText("Please enter a valid number.");
    }
    setIsInputValid(true);
    setInputHelpText("");
    setShouldSaveStripeChanges(false);
  };

  const handleInvalidInput = (helpText: string) => {
    if (hasInteractedWithInput) {
      setInputHelpText(helpText);
    }
    setIsInputValid(false);
    setShouldSaveStripeChanges(false);
  };

  const validateInvoiceDaysUntilDueValue = (value: string) => {
    if (!value) return handleEmptyValue();
    const numValue = Number(value);
    if (isNaN(numValue))
      return handleInvalidInput("Please enter a valid number.");
    if (numValue < 0)
      return handleInvalidInput("Must be a non-negative integer.");
    if (numValue >= 1827)
      return handleInvalidInput("Must be less than 1827 days.");
    if (!Number.isInteger(numValue))
      return handleInvalidInput("Must be an integer.");

    setIsInputValid(true);
    setInputHelpText("");
    setNewInvoiceDaysUntilDueValue(value);
  };

  const updateInvoiceDaysUntilDue = () => {
    if (
      !isCheckedValue ||
      !isInputValid ||
      newInvoiceDaysUntilDueValue === null
    ) {
      return;
    }

    setStripeSettingsData((data) => ({
      ...data,
      invoice_days_until_due: newInvoiceDaysUntilDueValue,
    }));
  };

  useEffect(() => {
    updateInvoiceDaysUntilDue();
  }, [newInvoiceDaysUntilDueValue]);

  const handleToggleChange = (value: boolean) => {
    setHasInteractedWithInput(false);
    setIsCheckedValue(value);
    setShouldSaveStripeChanges(true);
    if (!value) {
      setStripeSettingsData((data) => ({
        ...data,
        invoice_days_until_due: "",
      }));
    }
  };

  const handleInputChange = (value: string) => {
    if (value !== initialInvoicesUntilDue) {
      setShouldSaveStripeChanges(true);
    }
    validateInvoiceDaysUntilDueValue(value);
  };

  return (
    <>
      <Toggle
        checked={!!isCheckedValue}
        label="Invoice Days Until Due"
        onChange={(value) => handleToggleChange(value)}
      />
      <div className="leading-tight text-sm font-normal text-gray-600">
        {isCheckedValue ? "" : "By default this is 30 days"}
      </div>
      {isCheckedValue && (
        <TextInput
          className="font-normal"
          isInvalid={!isInputValid}
          hintText={inputHelpText}
          value={initialInvoicesUntilDue}
          placeholder="Enter number of days"
          onChange={(meta: { value: string }) => {
            setHasInteractedWithInput(true);
            handleInputChange(meta.value);
          }}
        />
      )}
    </>
  );
};
