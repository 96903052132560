/* 884bac9f4eae1d60e9d5d4908da46dd8ea57eff1
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GeneratePrequelAuthTokenMutationVariables = Types.Exact<{
  applicationOrigin: Types.Scalars['String'];
  vendor?: Types.InputMaybe<Types.Scalars['String']>;
  host?: Types.InputMaybe<Types.Scalars['String']>;
  bucketName?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GeneratePrequelAuthTokenMutation = { __typename?: 'Mutation', generate_prequel_auth_token: { __typename?: 'PrequelAuthTokenResponse', token_string: string } };

export type GetClientQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetClientQuery = { __typename?: 'Query', Client: Array<{ __typename?: 'Client', id: string, name: string }> };


export const GeneratePrequelAuthTokenDocument = gql`
    mutation GeneratePrequelAuthToken($applicationOrigin: String!, $vendor: String, $host: String, $bucketName: String) {
  generate_prequel_auth_token(
    application_origin: $applicationOrigin
    vendor: $vendor
    host: $host
    bucket_name: $bucketName
  ) {
    token_string
  }
}
    `;
export type GeneratePrequelAuthTokenMutationFn = Apollo.MutationFunction<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>;

/**
 * __useGeneratePrequelAuthTokenMutation__
 *
 * To run a mutation, you first call `useGeneratePrequelAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePrequelAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePrequelAuthTokenMutation, { data, loading, error }] = useGeneratePrequelAuthTokenMutation({
 *   variables: {
 *      applicationOrigin: // value for 'applicationOrigin'
 *      vendor: // value for 'vendor'
 *      host: // value for 'host'
 *      bucketName: // value for 'bucketName'
 *   },
 * });
 */
export function useGeneratePrequelAuthTokenMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>(GeneratePrequelAuthTokenDocument, options);
      }
export type GeneratePrequelAuthTokenMutationHookResult = ReturnType<typeof useGeneratePrequelAuthTokenMutation>;
export type GeneratePrequelAuthTokenMutationResult = Apollo.MutationResult<GeneratePrequelAuthTokenMutation>;
export type GeneratePrequelAuthTokenMutationOptions = Apollo.BaseMutationOptions<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>;
export const GetClientDocument = gql`
    query GetClient {
  Client {
    id
    name
  }
}
    `;

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientQuery(baseOptions?: Apollo.QueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
      }
export function useGetClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
        }
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>;
export type GetClientLazyQueryHookResult = ReturnType<typeof useGetClientLazyQuery>;
export type GetClientQueryResult = Apollo.QueryResult<GetClientQuery, GetClientQueryVariables>;