import React, { useMemo, useState } from "react";
import "/src/tenaissance/tenaissance.css";

import { Card, Metric } from "tenaissance/components/Card";
import { Dropdown, DropdownItem } from "tenaissance/components/Dropdown";
import { Tooltip } from "tenaissance/components/Tooltip";
import { Icon } from "tenaissance/components/Icon";
import { useUIMode } from "lib/useUIMode";
import { Maybe } from "types/generated-graphql/__types__";
import { dayjs } from "lib/dayjs";
import { Link } from "react-router-dom";
import {
  USD_CREDIT_ID,
  prefixForCreditType,
  roundedCurrencyString,
} from "lib/credits";
import { CreditType } from "types/credit-types";
import Decimal from "decimal.js";
import { ClientOverviewQuery } from "./queries.graphql";

type BillingsProps = {
  data?: ClientOverviewQuery;
  loading?: boolean;
};

type ContentResults = {
  amount_billed: string;
  month_over_month_percent_change: Maybe<string>;
  name: string;
  link: string;
  deltaTooltipLabel: string;
  deltaTooltipSubLabel: string;
};

type BillingsContent = {
  month?: string;
  noContentForCreditType?: React.ReactNode;
  totalBillings?: string;
  lastMonthBillings?: string;
  contentResults?: ContentResults[];
  noContentMessage?: React.ReactNode;
};

const convertCreditTypeLabel = (creditType: CreditType) =>
  `${prefixForCreditType(creditType)}${creditType.id === USD_CREDIT_ID ? "USD" : creditType.name}`;

const Billings: React.FC<BillingsProps> = ({ data, loading }) => {
  const overviewData = data?.client_overview;
  const [billingsCreditType, setBillingsCreditType] = useState<CreditType>();
  const { mode } = useUIMode();

  const noContentMessage = () => (
    <div className="mt-3xl flex w-full flex-col items-center text-gray-600">
      <h3 className="text-md font-semibold text-black">Fetching data...</h3>
    </div>
  );

  const noContentForCreditType = (label: string, month: string) => (
    <div className="mt-3xl flex w-full flex-col items-center text-gray-600">
      <h3 className="text-md font-semibold text-black">
        No {label} spend in {month}
      </h3>
      <div>You did not incur usage that resulted to line item spend.</div>
    </div>
  );

  const billingsFilterOptions: CreditType[] = useMemo(() => {
    if (overviewData?.total_billings?.results) {
      // sort to have fiat currencies at the top
      const options = [...overviewData.total_billings?.results]
        .sort((a, _) => (a.credit_type.client_id === null ? -1 : 1))
        .map((result) => result.credit_type);
      setBillingsCreditType(options[0]);
      return options;
    }
    return [];
  }, [overviewData]);

  const billingsContent: BillingsContent = useMemo(() => {
    if (overviewData) {
      const month = dayjs
        .utc(overviewData?.total_billings?.ending_before)
        .subtract(1, "month")
        .format("MMMM");
      const topContent = overviewData.top_products ?? overviewData.top_plans;

      if (topContent && billingsCreditType) {
        const totalBillings = roundedCurrencyString(
          new Decimal(
            overviewData.total_billings?.results.find(
              (r) => r.credit_type.id === billingsCreditType.id,
            )?.amount ?? 0,
          ),
          billingsCreditType,
          true,
        );
        const lastMonthBillings = roundedCurrencyString(
          new Decimal(
            overviewData.current_period_billings?.results.find(
              (r) => r.credit_type.id === billingsCreditType.id,
            )?.amount ?? 0,
          ),
          billingsCreditType,
          true,
        );

        // filter by the billingsCreditType
        const filteredContent = topContent.results.filter(
          (r) => r.credit_type.id === billingsCreditType?.id,
        );

        if (filteredContent.length === 0) {
          // There are no products or plans for this credit_type
          return {
            month,
            noContentForCreditType: noContentForCreditType(
              convertCreditTypeLabel(billingsCreditType),
              month,
            ),
            totalBillings,
            lastMonthBillings,
          };
        }

        const contentResults = filteredContent.map((r) => {
          let name = "",
            link = "";
          if (r.__typename === "TopPlans_Result") {
            name = r.plan.name ?? "";
            link = `/offering/plans/${r.plan.id}`;
          } else if (r.__typename === "TopProducts_Result") {
            name = r.product.name ?? "";
            link = `/offering/plans/${r.product.id}`;
          } else {
            r.__typename satisfies undefined;
          }

          const amountBilled = roundedCurrencyString(
            new Decimal(r.amount_billed ?? 0),
            billingsCreditType,
            true,
          );

          const previousMonth = dayjs(
            r.month_over_month_change.previous_period_ending_before,
          )
            .subtract(1, "month")
            .format("MMMM");
          const previousTotal = roundedCurrencyString(
            new Decimal(
              r.month_over_month_change.previous_period_total_billed ?? 0,
            ),
            billingsCreditType,
            true,
          );
          const delta = Number(
            Number(r.month_over_month_change.percent_change).toFixed(0),
          );
          let deltaTooltipLabelPrefix = "";
          if (delta > 0) {
            deltaTooltipLabelPrefix = `Up ${delta}%`;
          } else if (delta < 0) {
            deltaTooltipLabelPrefix = `Down ${delta}%`;
          } else if (
            r.month_over_month_change.percent_change === null ||
            delta === 0
          ) {
            deltaTooltipLabelPrefix = "No change";
          }

          return {
            amount_billed: amountBilled,
            month_over_month_percent_change:
              r.month_over_month_change.percent_change,
            name: name,
            link: link,
            deltaTooltipLabel: `${deltaTooltipLabelPrefix} from ${previousMonth}`,
            deltaTooltipSubLabel: previousTotal,
          };
        });

        return {
          month,
          contentResults,
          totalBillings,
          lastMonthBillings,
        };
      } else {
        // no topContent or billingsCreditType
        const month = dayjs().subtract(1, "month").format("MMMM");
        return {
          totalBillings: "0",
          lastMonthBillings: "0",
          month: month,
          noContentForCreditType: noContentForCreditType(
            billingsCreditType
              ? convertCreditTypeLabel(billingsCreditType)
              : "",
            month,
          ),
        };
      }
    } else {
      // no overviewData
      return {
        noContentMessage: noContentMessage(),
      };
    }
  }, [overviewData, billingsCreditType]);

  let cardContent = noContentMessage();
  if (billingsContent) {
    cardContent = (
      <div>
        {billingsContent.noContentMessage ?? (
          <>
            <div className="mb-3xl">
              <div className="mb-lg gap-x-xs flex items-center">
                <h3 className="text-md font-semibold">Total Invoiced</h3>
                <Tooltip label="All finalized invoices in the selected currency or pricing unit. This includes commit and credit purchases.">
                  <Icon icon="helpCircle" size={14} />
                </Tooltip>
              </div>
              <div className="mb-lg flex gap-x-[40px]">
                <Metric
                  value={billingsContent.totalBillings}
                  label="All time"
                  tooltipContent={`Total invoiced over the lifetime on Metronome. This includes all finalized invoices through the month of ${billingsContent?.month}.`}
                />
                <Metric
                  value={billingsContent.lastMonthBillings}
                  label={`Last month (${billingsContent.month})`}
                  tooltipContent={`Total invoiced over the month of ${billingsContent.month}. This includes all finalized invoices.`}
                />
              </div>
            </div>

            <div className="mb-lg gap-x-xs flex items-center">
              <h3 className="text-md font-semibold">
                Top {mode === "plans-only" ? "plans" : "products"} in{" "}
                {billingsContent?.month}
              </h3>
              <Tooltip
                label={`Top ${mode === "plans-only" ? "plans" : "products"} are calculated by the line item spend before commits or credits are applied.`}
              >
                <Icon icon="helpCircle" size={14} />
              </Tooltip>
            </div>
            <div className="mb-lg flex gap-x-[40px]">
              {billingsContent.noContentForCreditType ??
                billingsContent.contentResults?.map((c, idx) => {
                  const delta = Number(
                    Number(c.month_over_month_percent_change).toFixed(0),
                  );
                  return (
                    <Metric
                      key={`${idx} - ${c.amount_billed}`}
                      value={c.amount_billed}
                      label={
                        <>
                          <Link to={c.link} className="flex items-center">
                            <Icon
                              icon="arrowNarrowUpRight"
                              size={16}
                              className="mr-sm"
                            />
                            {c.name}
                          </Link>
                        </>
                      }
                      deltaValue={`${c.month_over_month_percent_change === null ? "--" : delta}%`}
                      delta={delta >= 0 ? "positiveTrend" : "negativeTrend"}
                      deltaColor={delta >= 0 ? "success" : "gray"}
                      badgeTooltipLabel={c.deltaTooltipLabel}
                      badgeTooltipSubLabel={c.deltaTooltipSubLabel}
                    />
                  );
                })}
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <Card
      title="Billings"
      loading={loading}
      headerActions={
        billingsFilterOptions.length > 0
          ? [
              <Dropdown
                selectedOption={
                  billingsCreditType
                    ? convertCreditTypeLabel(billingsCreditType)
                    : undefined
                }
                icon="filterLines"
                label="Credit Type"
                children={billingsFilterOptions.map((o) => (
                  <DropdownItem
                    label={convertCreditTypeLabel(o)}
                    value={o.id}
                    onClick={(meta) => {
                      setBillingsCreditType(
                        billingsFilterOptions.find((o) => o.id === meta.value),
                      );
                    }}
                  />
                ))}
              />,
            ]
          : undefined
      }
      children={cardContent}
    />
  );
};

export default Billings;
