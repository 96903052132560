import React from "react";
import { twMerge } from "../../twMerge";
import { Icon, IconName } from "../Icon";
import { ReactComponent as Rings } from "./rings.svg";

export interface EmptyStateProps {
  /**
   * Optional actions a user can take to interact with the EmptyState, usually a Button.
   * Use this let a user add new data or clear their search field.
   *  */
  actions?: React.ReactElement[];
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /** Add an icon from the library to sit at the center of the component, above `mainText` */
  icon: IconName;
  /** Main copy to let the user know there is no content or no results */
  mainText: string;
  /**
   * De-emphsasized text that lives under the `mainText`.
   * Use this to provide context to the user about why there may not be results listed.
   * */
  supportingText?: string;
}

/**
 * Empty states are moments in an app where there is no data to display to the user, or if a search query returns zero results. Empty states are often overlooked in UX design, but are easy to get right. They are a simple yet extremely powerful way to keep a user informed, supported, and on a productive path.
 *  A good empty state should clearly state why there is no data to be displayed, and provide constructive guidance about next steps and clear actions that the user can take. With just enough guidance, empty states ensure a smooth experience, especially when things aren’t working as expected.
 */
export const EmptyState: React.FC<EmptyStateProps> = ({
  actions,
  className,
  icon,
  mainText,
  supportingText,
}: EmptyStateProps) => {
  const classnames = twMerge("w-[480px] h-[480px]", className);

  return (
    <div className={classnames}>
      <div className="relative flex h-[inherit] flex-col items-center">
        <Rings />
        <div className="absolute top-[48%] flex flex-col items-center ">
          <Icon className="mb-4xl" icon={icon} size={20} />
          <div className="mb-2xl text-center">
            <div className="text-md mb-xs font-semibold">{mainText}</div>
            {supportingText && (
              <div className="text-sm font-normal">{supportingText}</div>
            )}
          </div>
          {actions && (
            <div className="flex space-x-[12px]">{actions.map((a) => a)}</div>
          )}
        </div>
      </div>
    </div>
  );
};
