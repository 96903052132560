import React from "react";
import Fuse from "fuse.js";
import { get } from "lib/typeHelpers/object";

export type SearchKeys<T> = Array<keyof T>;
export interface SearcherConfig<T> extends Fuse.IFuseOptions<T> {
  keys: Fuse.FuseOptionKey<T>[];
  uuidKeys?: SearchKeys<T>;
}

export function makeSearcher<T extends object>(
  items: T[],
  config: SearcherConfig<T>,
) {
  const index = new Fuse(items, config);

  return (query: string) => {
    if (!query) {
      return items;
    }

    const uuidKeys = config.uuidKeys ?? ["id"];
    const uuidMatches = items.filter((item) =>
      uuidKeys.some((key) => get(item, key) === query),
    );

    // if the query matches any uuid in the items then limit the results to those items
    if (uuidMatches.length) {
      return uuidMatches;
    }

    return index.search(query).map((match) => {
      return match.item;
    });
  };
}

export function useSearcher<T extends object>(
  items: T[],
  config: SearcherConfig<T>,
) {
  return React.useMemo(() => {
    const prevResult: { input?: string; results?: T[] } = {};

    const searcher = makeSearcher(items, config);

    return (query: string) => {
      if (prevResult.results && prevResult.input === query) {
        return prevResult.results;
      }

      const results = searcher(query);
      prevResult.input = query;
      prevResult.results = results;
      return results;
    };
  }, [items, JSON.stringify(config)]);
}
