import { BillingProviderDeliveryMethod_Enum } from "../../types/generated-graphql/__types__";

export const formatDeliveryMethod = (
  billingProvider: BillingProviderDeliveryMethod_Enum | undefined | null,
): string => {
  switch (billingProvider) {
    case BillingProviderDeliveryMethod_Enum.DirectToBillingProvider:
      return "direct to billing provider";
    case BillingProviderDeliveryMethod_Enum.AwsSqs:
      return "SQS queue";
    case BillingProviderDeliveryMethod_Enum.AwsSns:
      return "SNS topic";
    default:
      return "unknown";
  }
};
