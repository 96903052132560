/* d016cdba183369b9a87c4f254f4a2b2515d519d1
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRateCardMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  description?: Types.InputMaybe<Types.Scalars['String']>;
  rates?: Types.InputMaybe<Array<Types.RateCardEntryInput> | Types.RateCardEntryInput>;
  aliases?: Types.InputMaybe<Array<Types.RateCardAliasInput> | Types.RateCardAliasInput>;
  fiatCreditTypeId: Types.Scalars['String'];
  creditTypeConversions?: Types.InputMaybe<Array<Types.RateCardCreditTypeConversionInput> | Types.RateCardCreditTypeConversionInput>;
}>;


export type CreateRateCardMutation = { __typename?: 'Mutation', create_rate_card: { __typename?: 'RateCard', id: string } };

export type ProductsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProductsQuery = { __typename?: 'Query', contract_pricing: { __typename?: 'ContractPricing', products: Array<{ __typename: 'CompositeProductListItem', id: string, current: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', created_at: string }> } | { __typename: 'FixedProductListItem', id: string, current: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', created_at: string }> } | { __typename: 'ProServiceProductListItem', id: string, current: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', created_at: string }> } | { __typename: 'SubscriptionProductListItem', id: string, current: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', created_at: string }> } | { __typename: 'UsageProductListItem', id: string, current: { __typename?: 'UsageProductListItemState', name: string, pricing_group_key: Array<string> | null, presentation_group_key: Array<string> | null, billable_metric: { __typename?: 'BillableMetric', id: string, sql: string | null, aggregate: Types.BillingMetricAggregateEnum_Enum } }, updates: Array<{ __typename?: 'ProductListItemUpdate', created_at: string }> }> } };


export const CreateRateCardDocument = gql`
    mutation CreateRateCard($name: String!, $description: String, $rates: [RateCardEntryInput!], $aliases: [RateCardAliasInput!], $fiatCreditTypeId: String!, $creditTypeConversions: [RateCardCreditTypeConversionInput!]) {
  create_rate_card(
    name: $name
    description: $description
    rates: $rates
    aliases: $aliases
    fiatCreditTypeId: $fiatCreditTypeId
    creditTypeConversions: $creditTypeConversions
  ) {
    id
  }
}
    `;
export type CreateRateCardMutationFn = Apollo.MutationFunction<CreateRateCardMutation, CreateRateCardMutationVariables>;

/**
 * __useCreateRateCardMutation__
 *
 * To run a mutation, you first call `useCreateRateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRateCardMutation, { data, loading, error }] = useCreateRateCardMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      rates: // value for 'rates'
 *      aliases: // value for 'aliases'
 *      fiatCreditTypeId: // value for 'fiatCreditTypeId'
 *      creditTypeConversions: // value for 'creditTypeConversions'
 *   },
 * });
 */
export function useCreateRateCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateRateCardMutation, CreateRateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRateCardMutation, CreateRateCardMutationVariables>(CreateRateCardDocument, options);
      }
export type CreateRateCardMutationHookResult = ReturnType<typeof useCreateRateCardMutation>;
export type CreateRateCardMutationResult = Apollo.MutationResult<CreateRateCardMutation>;
export type CreateRateCardMutationOptions = Apollo.BaseMutationOptions<CreateRateCardMutation, CreateRateCardMutationVariables>;
export const ProductsDocument = gql`
    query Products {
  contract_pricing {
    products(archived: NOT_ARCHIVED) {
      __typename
      ... on UsageProductListItem {
        id
        current {
          name
          pricing_group_key
          presentation_group_key
          billable_metric {
            id
            sql
            aggregate
            __environment_type: environment_type
          }
        }
        updates {
          created_at
        }
      }
      ... on SubscriptionProductListItem {
        id
        current {
          name
        }
        updates {
          created_at
        }
      }
      ... on FixedProductListItem {
        id
        current {
          name
        }
        updates {
          created_at
        }
      }
      ... on CompositeProductListItem {
        id
        current {
          name
        }
        updates {
          created_at
        }
      }
      ... on ProServiceProductListItem {
        id
        current {
          name
        }
        updates {
          created_at
        }
      }
    }
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;