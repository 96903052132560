import React, { useEffect } from "react";
import "/src/tenaissance/tenaissance.css";
import { twMerge } from "tenaissance/twMerge";
import { IconButton } from "../IconButton";
import ReactModal from "react-modal";
import { Button, ButtonProps } from "../Button";

if (process.env.NODE_ENV !== "test") {
  if (!!document.getElementById("storybook-root")) {
    ReactModal.setAppElement("#storybook-root");
  } else {
    ReactModal.setAppElement("#root");
  }
}

export type ModalProps = {
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /** Content to be placed inside the Takeover */
  children?: React.ReactNode;
  /** Used to open and cloe the Modal */
  isOpen: boolean;
  /** Small, grey link Buttons, rendered at the end of the Takeover header */
  headerButtons?: React.ReactElement<ButtonProps>[];
  /** Small Button, rendered at the beginning of the Takeover footer */
  footerLeadingButton?: React.ReactElement<ButtonProps>;
  /** Small Buttons, rendered at the end of the Takeover footer */
  footerTrailingButtons?:
    | [React.ReactElement<ButtonProps>]
    | [React.ReactElement<ButtonProps>, React.ReactElement<ButtonProps>];
  /** Callback handler for closing the Modal. */
  onClose: () => void;
  /** Main text string for the Takeover */
  title?: string;
  /**
   * Default - "max-w-[1140px]"
   * Set a maximum to the width for container and footer actions. This should be in the
   * format of the tailwind class "max-w-[WIDTHpx]". For example, "max-w-[560px]".
   * */
  maxContainerWidth?: string;
};

/**
 * Takeover components are great for focused wizards, forms, or other jobs where a tasks
 * need to be completed in a focused view.
 */
export const Takeover: React.FC<ModalProps> = ({
  className,
  children,
  footerLeadingButton,
  footerTrailingButtons,
  headerButtons,
  isOpen = false,
  maxContainerWidth = "max-w-[1140px]",
  onClose,
  title,
}) => {
  useEffect(() => {
    // prevent background scrolling
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <ReactModal
      className={twMerge(
        "rounded-xl relative h-full w-full overflow-hidden bg-white shadow-xl",
        className,
      )}
      isOpen={isOpen}
      onRequestClose={onClose}
      closeTimeoutMS={300}
    >
      <div className="flex h-full flex-col">
        {/* Header */}
        <div className="px-3xl flex h-[56px] w-full items-center border-b border-gray-200 py-[10px]">
          <div className="flex-1 text-center text-sm font-semibold text-black">
            {title}
          </div>
          <div className="flex h-full">
            <div className="gap-x-md mr-xl flex h-full">
              {headerButtons?.map((a, idx) => (
                <React.Fragment key={idx}>
                  <Button {...a.props} size="sm" theme="linkGray" />
                </React.Fragment>
              ))}
              {headerButtons && (
                <div className="h-full w-0 border-r border-gray-200" />
              )}
            </div>
            <IconButton
              icon="xClose"
              onClick={onClose}
              theme="secondary"
              size="sm"
              className=""
            />
          </div>
        </div>
        {/* Body */}
        <div className="px-3xl py-4xl mb-[56px] flex h-full w-full justify-center overflow-y-scroll bg-white text-black">
          <div className={twMerge("w-full", maxContainerWidth)}>{children}</div>
        </div>
        {/* Footer */}
        <div className="px-3xl bottom-none fixed flex h-[56px] w-full items-center justify-center border-t border-gray-200 bg-white py-[10px]">
          <div className={twMerge("flex w-full", maxContainerWidth)}>
            <div className="flex-1">
              {footerLeadingButton && (
                <Button {...footerLeadingButton.props} size="sm" />
              )}
            </div>
            <div className="gap-x-md flex">
              {footerTrailingButtons?.map((a, idx) => (
                <React.Fragment key={idx}>
                  <Button {...a.props} size="sm" />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
