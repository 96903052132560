import { PageContainer } from "components/PageContainer";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useFeatureFlag } from "lib/launchdarkly";
import { Tooltip } from "design-system";

import { Input } from "design-system";

import {
  CreateCompositeProductDocument,
  CreateFixedProductDocument,
  CreateUsageProductDocument,
  CreateProductModal,
} from "./CreateAndEditProductModal";
import { CreateRateCardDocument } from "./CreateAndEditRateCard";
import { DocumentNode } from "graphql";
import { GatedButton } from "../../../components/GatedButton";
import { useNavigate } from "lib/useNavigate";
import { useUIMode } from "../../../lib/useUIMode";

interface Props extends React.PropsWithChildren {
  searchPlaceholder: string;
  searchTerm: string;
  onSearchTermChange: (term: string) => void;
  newButtonRoutePath?: string;
  newButtonText: "rate card" | "product";
}

const getGatedButtonDoc = (
  newButtonText: "rate card" | "product",
): DocumentNode | DocumentNode[] => {
  switch (newButtonText) {
    case "rate card":
      return CreateRateCardDocument;
    case "product":
      return [
        CreateCompositeProductDocument,
        CreateFixedProductDocument,
        CreateUsageProductDocument,
      ];
  }
};

export const ContractPricingWrapper: React.FC<Props> = ({
  children,
  searchPlaceholder,
  searchTerm,
  onSearchTermChange,
  newButtonRoutePath,
  newButtonText,
}) => {
  const gigaRateCardSupportEnabled = useFeatureFlag(
    "giga-rate-card-support",
    false,
  );
  const dimensionalPricingEnabled = useFeatureFlag(
    "dimensional-pricing",
    false,
  );
  const navigate = useNavigate();
  const [showNewProductModal, setShowNewProductModal] = useState(false);
  const { newUIEnabled } = useUIMode();

  const actions = (
    <>
      <Input
        type="search"
        placeholder={searchPlaceholder}
        value={searchTerm}
        onChange={onSearchTermChange}
        className="w-[250px]"
      />

      {showNewProductModal && (
        <CreateProductModal onClose={() => setShowNewProductModal(false)} />
      )}
      <Tooltip //tooltip for rate card button only when giga rate card support is enabled
        disabled={newButtonText !== "rate card" || !gigaRateCardSupportEnabled}
        content="Please use API for this action"
      >
        <GatedButton
          doc={getGatedButtonDoc(newButtonText)}
          disabled={newButtonText === "rate card" && gigaRateCardSupportEnabled}
          onClick={() => {
            if (newButtonText === "product") {
              if (dimensionalPricingEnabled || newUIEnabled) {
                navigate(
                  newUIEnabled
                    ? "/offering/pricing-units" //TODO: @alison update this route
                    : "/contract-pricing/products/create",
                );
              } else {
                setShowNewProductModal(true);
              }
            }
          }}
          className="ml-12"
          text={`Add new ${newButtonText}`}
          theme="primary"
          leadingIcon="plus"
          linkTo={newButtonRoutePath}
          size="sm"
        />
      </Tooltip>
    </>
  );
  return newUIEnabled ? (
    <>
      <div className="flex flex-row items-center gap-12">{actions}</div>
      {children}
    </>
  ) : (
    <PageContainer
      title="Contract Pricing"
      tabsAction={actions}
      tabs={[
        {
          name: "Rate cards",
          routePath: "/contract-pricing/rate-cards",
          activePatterns: [
            "/contract-pricing/rate-cards",
            "/contract-pricing/rate-cards/:id",
          ],
        },
        {
          name: "Product list",
          routePath: "/contract-pricing/products",
          activePatterns: [
            "/contract-pricing/products",
            "/contract-pricing/products/:id",
          ],
        },
      ]}
    >
      {children}
      <Outlet />
    </PageContainer>
  );
};
