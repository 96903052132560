import React, { useState } from "react";
import { SortFunctions, Table } from "components/Table";
import { usePricingUnitsQuery } from "./pricingUnits.graphql";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { CreditType } from "types/credit-types";
import { displayCreditTypeName } from "lib/credits";
import { CopyableID } from "components/CopyableID";
import { Subtitle } from "design-system";
import styles from "./index.module.less";
import { EmptyState } from "components/EmptyState";
import { TabProps } from "components/Tabs";
import { PageContainer } from "components/PageContainer";
import NewPricingUnitModal from "components/PricingUnitSelector/NewPricingUnitModal";
import { InsertCreditTypeDocument } from "components/PricingUnitSelector/queries.graphql";
import { INVOICING_TAB_FEATURE_FLAG, TabFilter } from "pages/GeneralSettings";
import { useFeatureFlag } from "lib/launchdarkly";
import { GatedButton } from "../../../../components/GatedButton";

interface PricingUnitsTableProps {
  creditTypes: CreditType[];
  loading: boolean;
}

const PricingUnitsTable: React.FC<PricingUnitsTableProps> = ({
  creditTypes,
  loading,
}) => {
  return (
    <Table
      data={creditTypes}
      columns={[
        {
          id: "name",
          header: "Name",
          textWrappable: true,
          render: (ct) => displayCreditTypeName(ct),
          sortable: true,
          comparator: SortFunctions.String<CreditType>((ct) =>
            displayCreditTypeName(ct),
          ),
        },
        {
          id: "id",
          header: "ID",
          render: (ct) => <CopyableID id={ct.id} label={ct.id} />,
          sortable: true,
          comparator: SortFunctions.String<CreditType>((ct) => ct.id),
          align: "right",
        },
      ]}
      loading={loading}
      maxPageSize={10}
    />
  );
};
interface PricingUnitsTabProps {
  tabs: TabProps[];
}

const PricingUnitsTab: React.FC<PricingUnitsTabProps> = ({ tabs }) => {
  const { environmentType } = useEnvironment();
  const { data, loading } = usePricingUnitsQuery({
    variables: {
      environment_type: environmentType,
    },
  });

  const [showCreatePricingUnitModal, setShowCreatePricingUnitModal] =
    useState(false);

  const creditTypes: CreditType[] = data?.CreditType ?? [];

  const customPricingUnits = creditTypes.filter((ct) => ct.client_id !== null);

  const invoicingEnabled =
    useFeatureFlag(INVOICING_TAB_FEATURE_FLAG, false) || false;

  return (
    <PageContainer
      title="General settings"
      // this filtering has to happen here, since we can't modify the routes in
      // GeneralSettings/index.tsx at runtime
      tabs={tabs.filter(new TabFilter(invoicingEnabled).filter)}
      tabsAction={
        <GatedButton
          onClick={() => setShowCreatePricingUnitModal(true)}
          doc={InsertCreditTypeDocument}
          text="New pricing unit"
          theme="primary"
          leadingIcon="plus"
        />
      }
    >
      <div className={styles.content}>
        {showCreatePricingUnitModal ? (
          <NewPricingUnitModal
            onClose={() => setShowCreatePricingUnitModal(false)}
            creditTypes={creditTypes}
          />
        ) : null}
        <Subtitle level={4}>Currencies</Subtitle>
        <PricingUnitsTable
          creditTypes={creditTypes.filter((ct) => ct.client_id === null)}
          loading={loading}
        />
        <Subtitle level={4}>Custom pricing units</Subtitle>
        {!loading && customPricingUnits.length === 0 ? (
          <div className={styles.noCustomPricingUnits}>
            <EmptyState
              title="No custom pricing units"
              subtitle="Add a custom pricing unit to see it here"
              buttonText="New pricing unit"
              buttonAuthDoc={InsertCreditTypeDocument}
              onClick={() => setShowCreatePricingUnitModal(true)}
              icon="currencyDollar"
              buttonIcon="plus"
            />
          </div>
        ) : (
          <PricingUnitsTable
            creditTypes={customPricingUnits}
            loading={loading}
          />
        )}
      </div>
    </PageContainer>
  );
};

export default PricingUnitsTab;
