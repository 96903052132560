import React from "react";
import "/src/tenaissance/tenaissance.css";
import { twMerge } from "tenaissance/twMerge";
import { IconName, Icon } from "../Icon";
import { Badge } from "../Badge";

const BADGE_GROUP_BORDER_VARIANTS = {
  gray: "border-gray-200",
  error: "border-error-200",
  warning: "border-warning-200",
  success: "border-success-200",
};

const BADGE_GROUP_VARIANTS = {
  gray: "bg-gray-200/40 text-black",
  error: "bg-error-200/40 text-error-800",
  warning: "bg-warning-200/40 text-warning-800",
  success: "bg-success-200/40 text-success-800",
};

const CLICK_HOVER_VARIANTS = {
  gray: "hover:bg-gray-200/80",
  error: "hover:bg-error-200/80",
  warning: "hover:bg-warning-200/80",
  success: "hover:bg-success-200/80",
};

interface BadgeGroupProps {
  /** Label string for the Badge */
  badgeLabel: string;
  /** Default: start - Choose whether the Badge should sit at the start or end of the mainLabel */
  badgePosition?: "start" | "end";
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /** The icon from the library to sit within the BadgeGroup */
  icon: IconName;
  /** Label string for the main body of the BadgeGroup */
  mainLabel: string;
  /** Add a click handler for BadgeGroup; imposes the "button" ARIA role on the div and adds hover states */
  onClick?: (e: React.MouseEvent) => void;
  /** Default: md - md: 30px, lg: 32px */
  size?: "md" | "lg";
  /** Default: gray - Sets the colors of the background, border, icon and text of the Badge */
  theme?: keyof typeof BADGE_GROUP_VARIANTS;
}

/**
 * Badge Groups build on top of the Badge component, giving further context to important information,
 * and always come with an icon, either inside the Badge or simply right of the main label.
 */
export const BadgeGroup: React.FC<BadgeGroupProps> = ({
  badgeLabel,
  badgePosition = "start",
  className,
  icon,
  mainLabel,
  onClick,
  size,
  theme = "gray",
}) => {
  const classnames = twMerge(
    "border border-black rounded-2xl inline-flex items-center max-w-[500px]",
    size === "lg"
      ? [
          "h-[32px]",
          "text-sm",
          "p-xs",
          badgePosition === "end" ? "pl-xl" : "pr-md",
        ]
      : [
          "h-[30px]",
          "p-xs",
          "text-xs",
          badgePosition === "end" ? "pl-lg pr-xxs" : "pr-md pl-xxs",
        ],
    BADGE_GROUP_VARIANTS[theme],
    BADGE_GROUP_BORDER_VARIANTS[theme],
    !!onClick && CLICK_HOVER_VARIANTS[theme],
    className,
  );

  const badgeClasses = twMerge(
    "bg-white truncate",
    BADGE_GROUP_BORDER_VARIANTS[theme],
    size === "lg" ? "text-sm" : "text-xs",
    badgePosition === "start" ? "mr-md" : "ml-md",
  );
  return (
    <div
      className={classnames}
      role={!!onClick ? "button" : "status"}
      onClick={onClick}
    >
      {badgePosition === "start" && (
        <Badge className={badgeClasses} label={badgeLabel} />
      )}
      <span title={mainLabel} aria-label={mainLabel} className="truncate">
        {mainLabel}
      </span>
      {badgePosition === "end" ? (
        <Badge
          className={badgeClasses}
          label={badgeLabel}
          icon={icon}
          iconPosition="right"
        />
      ) : (
        <Icon className="ml-sm" icon={icon} size={16} />
      )}
    </div>
  );
};
