/* a43a25439f3901b62eb1242f3c1802705424a678
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductQueryVariables = Types.Exact<{
  product_id: Types.Scalars['uuid'];
}>;


export type ProductQuery = { __typename?: 'Query', Product: { __typename?: 'Product', id: string, name: string, group_key: string | null, description: string, deprecated_at: string | null, active_plan_count: number, Actor: { __typename?: 'Actor', id: string, name: string, deprecated_at: string | null } | null, ProductPricingFactors: Array<{ __typename?: 'ProductPricingFactor', id: string, name: string, charge_type_enum: Types.ChargeTypeEnum_Enum, BillableMetric: { __typename?: 'BillableMetric', name: string, id: string } | null, seat_metric: { __typename?: 'SeatMetric', name: string, id: string } | null }> } | null };


export const ProductDocument = gql`
    query Product($product_id: uuid!) {
  Product: Product_by_pk(id: $product_id) {
    id
    name
    group_key
    description
    deprecated_at
    active_plan_count
    Actor {
      id
      name
      deprecated_at
    }
    ProductPricingFactors(order_by: {ordering: asc}) {
      id
      name
      charge_type_enum
      BillableMetric {
        name
        id
        __environment_type: environment_type
      }
      seat_metric {
        name
        id
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;