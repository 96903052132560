export const CustomerPageTabs = [
  {
    paths: ["", "invoices/:invoiceId"],
    name: "Summary",
  },
  {
    paths: ["plans"],
    name: "Plans",
  },
  {
    paths: ["credits"],
    name: "Credits",
  },
  {
    paths: ["usage"],
    name: "Usage",
  },
  {
    paths: ["alerts"],
    name: "Alerts",
  },
  {
    paths: ["settings"],
    name: "Settings",
  },
  {
    paths: ["audit-logs"],
    name: "Audit Logs",
  },
];
