import React, { useMemo } from "react";
import "/src/tenaissance/tenaissance.css";
import { AppShell } from "components/PageContainer";
import { Button } from "tenaissance/components/Button";

import {
  useClientOverviewQuery,
  useCustomerCountQuery,
} from "./queries.graphql";

import { Card } from "tenaissance/components/Card";
import { Icon } from "tenaissance/components/Icon";
import { useUIMode } from "lib/useUIMode";
import { dayjs } from "lib/dayjs";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useEventCountsQuery } from "pages/Events/queries.graphql";
import { useCurrentUser } from "lib/auth";
import NotFoundPage from "pages/404";
import Billings from "./Billings";
import { Link } from "react-router-dom";

export const Overview: React.FC = () => {
  const { newUIEnabled } = useUIMode();
  const { environmentType } = useEnvironment();
  const { data, loading } = useClientOverviewQuery();
  const { data: customerCountData } = useCustomerCountQuery({
    variables: {
      environmentType: environmentType,
      isArchived: false,
    },
  });
  const { user } = useCurrentUser();

  const today = dayjs().utc().endOf("day");
  const { data: eventData, loading: eventLoading } = useEventCountsQuery({
    variables: {
      environment_type: environmentType,
      starting_after: today.subtract(30, "days").format(),
      ending_before: today.format(),
    },
  });

  const customerGrowthData = useMemo(() => {
    if (data?.client_overview.new_customers) {
      const {
        total: monthTotal,
        ending_before,
        by_day: customerDataRaw,
      } = data?.client_overview.new_customers;
      const monthName = dayjs
        .utc(ending_before)
        .subtract(1, "month")
        .format("MMMM");
      const customerData = [
        {
          name: "Customers Added",
          yAxisUnit: "customers",
          data: customerDataRaw.map((d) => ({
            date: dayjs(d.date).toDate(),
            value: Number(d.count),
          })),
        },
      ];
      const xAxisLabel = dayjs
        .utc(ending_before)
        .subtract(1, "month")
        .format("MMMM YYYY");

      const customerGrowthMetrics = [];

      if (customerCountData?.Client[0].customer_count.count) {
        customerGrowthMetrics.push({
          value: Number(
            customerCountData?.Client[0].customer_count.count,
          ).toLocaleString(),
          label: "Total",
          tooltipContent:
            "Total number of customers - excluding archived customers.",
        });
      }
      if (monthTotal) {
        customerGrowthMetrics.push({
          value: Number(monthTotal).toLocaleString(),
          label: monthName,
          tooltipContent: `Total number of customers added in ${monthName}.`,
        });
      }
      return {
        xAxisLabel,
        customerData,
        customerGrowthMetrics,
      };
    }
    // no new customers
    const lastMonthName = dayjs().subtract(1, "month").format("MMMM");
    const customerGrowthMetrics = [];

    if (customerCountData?.Client[0].customer_count.count) {
      customerGrowthMetrics.push({
        value: Number(
          customerCountData?.Client[0].customer_count.count,
        ).toLocaleString(),
        label: "Total",
        tooltipContent:
          "Total number of customers - excluding archived customers.",
      });
    }
    customerGrowthMetrics.push({
      value: 0,
      label: lastMonthName,
      tooltipContent: `Total number of customers added in ${lastMonthName}.`,
    });
    const xAxisLabel = dayjs().subtract(1, "month").format("MMMM YYYY");
    const customerData = [
      {
        name: "Customers Added",
        yAxisUnit: "customers",
        data: Array.from(
          { length: dayjs().subtract(1, "month").daysInMonth() },
          (_, i) => ({
            date: dayjs()
              .subtract(1, "month")
              .startOf("month")
              .add(i, "day")
              .toDate(),
            value: 0,
          }),
        ),
      },
    ];
    return {
      xAxisLabel,
      customerData,
      customerGrowthMetrics,
    };
  }, [data]);

  const eventStreamData = useMemo(() => {
    if (eventData) {
      const { duplicates, non_duplicates } = eventData;
      if (duplicates.length !== non_duplicates.length) {
        throw new Error("Data length mismatch");
      }

      const totalNonDuplicates = (non_duplicates ?? []).reduce(
        (acc, cur) => acc + Number(cur.count),
        0,
      );

      const totalDuplicates = (duplicates ?? []).reduce(
        (acc, cur) => acc + Number(cur.count),
        0,
      );

      const totalEvents = (
        totalNonDuplicates + totalDuplicates
      ).toLocaleString();
      const mergedArray = duplicates.map((item1) => {
        const item2 = non_duplicates.find(
          (item) => item.starting_on === item1.starting_on,
        );

        return {
          date: dayjs(item1.starting_on).endOf("day").toDate(),
          value: Number(item1.count) + Number(item2?.count),
        };
      });
      const eventChartData = [
        {
          name: "Total",
          yAxisUnit: "events",
          data: mergedArray.sort((a, b) => a.date.valueOf() - b.date.valueOf()),
        },
        {
          name: "Duplicates",
          yAxisUnit: "duplicates",
          data: duplicates
            .map((d) => ({
              date: dayjs(d.starting_on).endOf("day").toDate(),
              value: Number(d.count),
            }))
            .sort((a, b) => a.date.valueOf() - b.date.valueOf()),
        },
      ];

      return {
        totalDuplicates: totalDuplicates.toLocaleString(),
        xAxisLabel: "Last 30 days",
        monthTotal: totalEvents,
        eventChartData,
      };
    }
    return null;
  }, [data, eventData]);

  if (!newUIEnabled) {
    return <NotFoundPage />;
  }
  return (
    <AppShell title={`Welcome${user?.name && " " + user?.name}!`}>
      <div>
        <div className="gap-xs flex flex-col">
          {loading ? (
            <div id="spinner" className="!z-[1]" />
          ) : (
            <div className="gap-y-2xl flex flex-col">
              <Billings loading={loading} data={data} />
              <Card
                title="Customer Growth"
                loading={loading}
                headerActions={[
                  <Button
                    text="Customers"
                    leadingIcon="arrowNarrowUpRight"
                    linkTo="/customers"
                    theme="secondary"
                  />,
                ]}
                metrics={customerGrowthData?.customerGrowthMetrics}
                children={
                  customerGrowthData?.customerData ? (
                    <Card.Chart
                      chartData={customerGrowthData?.customerData}
                      type="area"
                      yAxisLabel="Customers"
                      xAxisLabel={customerGrowthData?.xAxisLabel}
                      legendPostion="top"
                    />
                  ) : null
                }
              />

              <Card
                title="Event Stream"
                loading={eventLoading}
                headerActions={[
                  <Button
                    text="Events"
                    leadingIcon="arrowNarrowUpRight"
                    linkTo="/connections/events"
                    theme="secondary"
                  />,
                ]}
                metrics={[
                  {
                    value: eventStreamData?.monthTotal,
                    label: "Total",
                    tooltipContent:
                      "Total number of events sent in the last 30 days.",
                  },
                  {
                    value: eventStreamData?.totalDuplicates,
                    label: (
                      <>
                        <Link
                          to="/connections/events?duplicates=true"
                          className="flex items-center"
                        >
                          <Icon
                            icon="arrowNarrowUpRight"
                            size={16}
                            className="mr-sm"
                          />
                          Duplicates
                        </Link>
                      </>
                    ),
                    tooltipContent:
                      "Events sent in the last 30 days with a transaction ID that matched an existing event.",
                  },
                ]}
                children={
                  eventStreamData?.eventChartData ? (
                    <Card.Chart
                      chartData={eventStreamData.eventChartData}
                      type="area"
                      yAxisLabel="Customers"
                      xAxisLabel={eventStreamData?.xAxisLabel}
                      legendPostion="top"
                    />
                  ) : null
                }
              />
            </div>
          )}
        </div>
      </div>
    </AppShell>
  );
};

export default Overview;
