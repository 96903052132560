import React, { useEffect, useState } from "react";

import {
  GetAuditLogsQuery,
  useGetAuditLogsLazyQuery,
} from "./auditlogs.graphql";

import { Table } from "tenaissance/components/Table";
import { Timestamp } from "tenaissance/components/Timestamp";
import { AvatarWithName } from "design-system";
import { Badge } from "tenaissance/components/Badge";
import { AuditLogStatusPill } from "./components/AuditLogStatusPill";

import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { AuditLogPane } from "./components/AuditLogPane";
import { ClientResourceTooltip } from "./components/ClientResourceTooltip";
import { linkFromResource, prettyResourceType } from "./lib/resources";
import { Button } from "tenaissance/components/Button";
import { EmptyState } from "tenaissance/components/EmptyState";
import { DownloadAuditLogsButton } from "./components/DownloadAuditLogsButton";

type AuditLog = GetAuditLogsQuery["audit_logs"]["data"][number];

const TABLE_PAGE_SIZE = 10;

export const AuditLogTable: React.FC<{
  resourceID?: string;
  resourceType?: string;
  startingOn?: Date;
  endingBefore?: Date;
}> = (props) => {
  const location = useLocation();
  const [urlSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [cursor, setCursor] = useState<string | null>(null);
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const [noMoreLogs, setNoMoreLogs] = useState(false);

  const variables = {
    limit: TABLE_PAGE_SIZE,
    starting_after: props.startingOn?.toISOString(),
    ending_before: props.endingBefore?.toISOString(),
    ...(props.resourceType && props.resourceID
      ? {
          resource_type: props.resourceType,
          resource_id: props.resourceID.toLowerCase(),
        }
      : {}),
  };

  const [getAuditLogs, { loading }] = useGetAuditLogsLazyQuery({
    variables,
  });

  useEffect(() => {
    setAuditLogs([]);
    setPage(0);
    setNoMoreLogs(false);
    void (async () => {
      const { data } = await getAuditLogs({ variables });
      if (!data) {
        return;
      }
      setAuditLogs(data.audit_logs?.data);
      setCursor(data.audit_logs.next_cursor);
      if (!data.audit_logs.next_cursor) {
        setNoMoreLogs(true);
      }
    })();
  }, [
    props.resourceID,
    props.resourceType,
    props.startingOn,
    props.endingBefore,
  ]);

  const openAuditLogID = urlSearchParams.get("id");
  const openAuditLogTS = urlSearchParams.get("request_timestamp");

  return (
    <>
      <Table
        topBarActions={[<DownloadAuditLogsButton key="download" {...props} />]}
        emptyState={
          <EmptyState
            mainText={
              props.resourceID
                ? "No audit logs for this resource"
                : "No audit logs matching your filters"
            }
            icon="scan"
          />
        }
        paginationOptions={{
          type: "prevNext",
          paginationButtons: [
            {
              page: "prev",
              onClick: () => {
                setPage(page - 1);
              },
              disabled: page === 0,
            },
            {
              page: "next",
              disabled: noMoreLogs,
              onClick: async () => {
                if (
                  page * TABLE_PAGE_SIZE + TABLE_PAGE_SIZE >=
                  auditLogs.length
                ) {
                  const { data } = await getAuditLogs({
                    variables: {
                      ...variables,
                      cursor,
                    },
                  });
                  if (!data) {
                    return;
                  }
                  const newLogs = (data.audit_logs?.data).filter(
                    (newLog) =>
                      !auditLogs.some((prevLog) => prevLog.id === newLog.id),
                  );
                  setAuditLogs([...auditLogs, ...newLogs]);
                  setCursor(data.audit_logs.next_cursor);
                  if (!data.audit_logs.next_cursor) {
                    setNoMoreLogs(true);
                  }
                }
                setPage(page + 1);
              },
            },
          ],
        }}
        loading={loading}
        data={auditLogs.slice(
          page * TABLE_PAGE_SIZE,
          (page + 1) * TABLE_PAGE_SIZE,
        )}
        rowRoutePath={(row) => {
          const newParams = new URLSearchParams(urlSearchParams);
          newParams.set("id", row.original.id);
          newParams.set("request_timestamp", row.original.request_timestamp);
          return `${location.pathname}?${newParams.toString()}`;
        }}
        columns={[
          {
            id: "timestamp",
            header: "Time (UTC)",
            accessorFn: (row) => row.request_timestamp,
            cell: (props) => (
              <Timestamp dateTime={new Date(props.getValue())} />
            ),
            enableSorting: false,
          },
          {
            id: "actor",
            header: "Actor",
            accessorFn: (row) => row.actor,
            cell: (props) => <AvatarWithName {...props.getValue()} />,
            enableSorting: false,
          },
          {
            id: "status",
            header: "Status",
            accessorFn: (row) => row.status,
            cell: (props) => (
              <AuditLogStatusPill status={props.getValue()} size="md" />
            ),
            enableSorting: false,
          },
          {
            id: "action",
            header: "Action",
            accessorFn: (row) => row.action,
            cell: (props) => <Badge label={props.getValue()} theme="gray" />,
            enableSorting: false,
          },
          {
            id: "resource",
            header: "Resource",
            accessorFn: (row) => ({
              resourceType: row.resource_type,
              resourceID: row.resource_id,
            }),
            cell: (props) => {
              const { resourceType, resourceID } = props.getValue();
              if (resourceType === "client") {
                return <ClientResourceTooltip />;
              }
              const linkHref = linkFromResource(resourceType, resourceID);

              return linkHref ? (
                <Button
                  linkTo={linkHref}
                  text={prettyResourceType(resourceType)}
                  theme="linkGray"
                  trailingIcon="linkExternal01"
                />
              ) : (
                <>{prettyResourceType(resourceType)}</>
              );
            },
            supportingText: (row) => {
              if (row.resource_type === "client") {
                return "";
              }
              return `ID: ${row.resource_id}`;
            },
            enableSorting: false,
          },
        ]}
      />
      {openAuditLogID && openAuditLogTS ? (
        <AuditLogPane
          id={openAuditLogID}
          requestTimestamp={openAuditLogTS}
          onClose={() => {
            const newParams = new URLSearchParams(urlSearchParams);
            newParams.delete("id");
            newParams.delete("request_timestamp");
            navigate(`${location.pathname}?${newParams.toString()}`);
          }}
        />
      ) : null}
    </>
  );
};
