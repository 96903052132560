import React, { useState } from "react";
import { Button } from "tenaissance/components/Button";
import { EmptyState } from "tenaissance/components/EmptyState";
import { Takeover } from "tenaissance/components/Takeover";
import { useNavigate } from "lib/useNavigate";
import { SectionLabel } from "tenaissance/components/SectionLabel";
import { Icon } from "tenaissance/components/Icon";
import { useUIMode } from "../../../../lib/useUIMode";

export const shouldShowPreviewPage = () =>
  localStorage.getItem("show-product-create-preview") === null;

interface ProductCreateLandingPageProps {
  setShowPreviewPage: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProductCreateLandingPage: React.FC<
  ProductCreateLandingPageProps
> = ({ setShowPreviewPage }) => {
  const navigate = useNavigate();

  const [dontShowPreviewAgainClicked, setDontShowPreviewAgainClicked] =
    useState(false);

  const handleContinueClick = () => {
    if (dontShowPreviewAgainClicked)
      localStorage.setItem("show-product-create-preview", "false");
    setShowPreviewPage(false);
  };

  const { newUIEnabled } = useUIMode();
  return (
    <Takeover
      maxContainerWidth="max-w-[616px]"
      isOpen={true}
      onClose={() =>
        navigate(
          newUIEnabled ? "/offering/products" : "/contract-pricing/products",
        )
      }
      title="Create a product"
      footerTrailingButtons={[
        <Button
          leadingIcon={dontShowPreviewAgainClicked ? "xCircle" : "circle"}
          onClick={() =>
            setDontShowPreviewAgainClicked(!dontShowPreviewAgainClicked)
          }
          text="Don't show again"
          theme="tertiary"
        />,
        <Button
          className="w-[128px] justify-center"
          onClick={handleContinueClick}
          text="Continue"
          theme="primary"
          type="submit"
        />,
      ]}
      footerLeadingButton={
        <Button
          className="w-[128px] justify-center"
          text="Back"
          onClick={() => {
            navigate(
              newUIEnabled
                ? "/offering/products"
                : "/contract-pricing/products",
            );
          }}
          theme="secondary"
        />
      }
      children={
        <div className="flex flex-col items-center">
          <EmptyState
            className="w-[616px]"
            icon="file06"
            mainText="Welcome to the Metronome product creator"
            supportingText="A product is a line item on a customer’s invoice. Once you’ve created a product or set of products, they can then be added to a rate card, where you’ll price them."
          />
          <div className="flex h-[330px] w-[560px] flex-col items-start gap-[50px]">
            <div className="inline-flex">
              <Icon icon="content" className="mr-[16px] shrink-0" />
              <SectionLabel
                title="Select a product type"
                subtitle="Products can be usage-based, subscriptions, composite, or fixed."
              />
            </div>
            <div className="inline-flex">
              <Icon icon="content" className="mr-[16px] shrink-0" />
              <SectionLabel
                title="Select a billable metric for usage products"
                subtitle="Usage products are priced based on events that match it. Event matching and aggregation is determined by the product’s underlying billable metric."
              />
            </div>
            <div className="inline-flex">
              <Icon icon="content" className="mr-[16px] shrink-0" />
              <SectionLabel
                title="Select applicable products for a composite product"
                subtitle="Composite products are percent-based. The percent is based on the line item cost of the applicable usage products your choose."
              />
            </div>
          </div>
        </div>
      }
    ></Takeover>
  );
};
