import React, { PropsWithChildren } from "react";
import { useGigaRateCardUsageCheckQuery } from "./data.graphql";
import { useFeatureFlag } from "lib/launchdarkly";

const Context = React.createContext<boolean>(false);

export function useGigaRateCardEnabled() {
  return React.useContext(Context);
}

export const GigaRateCardUsageCheckProdiver: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const enabled = useFeatureFlag("giga-rate-card-support", false);

  // this is an extra check that is only enabled for confluent envs
  const additionalCheckForPricingGroupKeyInUse = useFeatureFlag(
    "check-for-existing-pricing-keys-to-enable-giga-rate-card",
    false,
  );

  const resp = useGigaRateCardUsageCheckQuery({
    skip: !enabled,
  });

  if (!enabled || !resp.loading) {
    const value = additionalCheckForPricingGroupKeyInUse
      ? resp.data?.contract_pricing.pricing_group_keys_in_use
      : enabled;
    return <Context.Provider value={!!value}>{children}</Context.Provider>;
  }

  return null;
};
