import React, { useState } from "react";
import { Toggle } from "design-system";
import { StripeSettingsToggleProps } from ".";

export const IncludeZeroQuantitySublineItemsToggle: React.FC<
  StripeSettingsToggleProps
> = ({
  isChecked,
  handleToggleOnChange,
  displayLineItems,
  canSaveClientConfig,
  hasContractsEnabled,
}) => {
  const [isCheckedValue, setIsCheckedValue] = useState(isChecked);

  return (
    <>
      <Toggle
        checked={isCheckedValue}
        label="Display sub-line items that have zero quantity on invoices"
        onChange={(value) =>
          handleToggleOnChange(
            value,
            "include_zero_quantity_sub_line_items",
            setIsCheckedValue,
          )
        }
        disabled={
          (!displayLineItems && !hasContractsEnabled) || !canSaveClientConfig
        }
      />
      <div className="leading-tight text-sm font-normal text-gray-600">
        Items that have a quantity of zero are shown on the invoices
      </div>
    </>
  );
};
