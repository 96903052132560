import Decimal from "decimal.js";
import React from "react";
import { z } from "zod";
import { Dayjs, dayjs } from "lib/dayjs";

import { Button } from "tenaissance/components/Button";
import { useSnackbar } from "components/Snackbar";
import { RowTheme } from "components/Table";
import { groupByAndMap } from "lib/util";
import { isInRange, useNow } from "lib/date";

import { Section } from "pages/Contracts/Customer/Contracts/Create/components/Section";

import { LegacyRateCardPanel } from "../../components/LegacyRateCardPanel";
import { Price, RateRow } from "../../components/LegacyRatesTable";
import { ProductListItem } from "../../lib/ProductListItem";

import { Schema } from "../Schema";
import { RateFlyout } from "./RateFlyout";
import { useRateCardCreateController } from "./RateCardCreate";
import { useProductsQuery } from "./data.graphql";
import { useSearchParam } from "lib/routes/useSearchParam";
import { USD_CREDIT_TYPE } from "lib/credits";
import { CreditType } from "types/credit-types";
import * as Sentry from "@sentry/react";

type Product = RateRow["product"];

/** @deprecated No support for giga-rate-cards, will be removing shortly */
export type SearchableRateRow = RateRow & {
  _productNames: string[];
  _productId: string;
  _productTags: string[];
};

function convertPrice(priceData: z.infer<typeof Schema.Rate>["price"]): Price {
  switch (priceData.type) {
    case "flat":
      return {
        type: "fixed",
        unitPrice: new Decimal(priceData.price),
      };
    case "subscription":
      return {
        type: "subscription",
        unitPrice: new Decimal(priceData.price),
        quantity: new Decimal(priceData.quantity),
        isProrated: priceData.isProrated,
      };
    case "percentage":
      return {
        type: "multiple",
        multiplier: new Decimal(priceData.fraction / 100),
      };
    case "tiered":
      return {
        type: "tiered",
        tiers: priceData.tiers.map((tier) => ({
          unitPrice: new Decimal(tier.unitPrice),
          lastUnit: tier.lastUnit ? new Decimal(tier.lastUnit) : undefined,
        })),
      };
    case "custom":
      return { type: "custom" };
    default:
      throw new Error(`Unrecognized rate price type`);
  }
}

/** @deprecated No support for giga-rate-cards, will be removing shortly */
export function toRateRows(
  rates: (Schema.Types.Rate & { rowTheme?: RowTheme })[],
  products: Product[],
  now: Dayjs,
) {
  const productById = (id: string) => products.find((p) => p.id === id);
  const rowsByProductId = groupByAndMap(
    rates,
    (r) => r.productId,
    (ratesForProduct, productId) => {
      const product = productById(productId);
      if (!product) {
        throw new Error(`Product ${productId} not found`);
      }
      const ratesSorted = ratesForProduct.sort((a, b) =>
        // If two rates are for the same time, we want the order to remain the
        // same (since rates added later take priority over rates added earlier.)
        // That means we have to make sure to return 0 here if the timestamps are
        // equal. `localeCompare` does that for us.
        a.startingAt.localeCompare(b.startingAt),
      );
      const current = ratesSorted.findLast((rate) =>
        isInRange(now, rate.startingAt, rate.endingBefore),
      );

      return ratesSorted.map((r, idx): SearchableRateRow => {
        let price = convertPrice(r.price);

        let commitPrice = undefined;
        if (r.commitPrice) {
          commitPrice = convertPrice(r.commitPrice);
        }

        return {
          id: r.id,
          rowTheme: r.rowTheme,
          type: idx === 0 ? "base" : "rateChange",
          product,
          isCurrent: r === current,
          from: dayjs.utc(r.startingAt),
          to: r.endingBefore ? dayjs.utc(r.endingBefore) : undefined,
          entitled: r.entitled === "enable",
          price,
          commitPrice,
          useListPrices:
            r.price.type === "percentage" ? r.price.useListPrices : undefined,
          creditType: r.creditType ? r.creditType : USD_CREDIT_TYPE,
          _productId: r.productId,
          _productNames: ProductListItem.getAllNames(product),
          _productTags: ProductListItem.getTags(product, now),
        };
      });
    },
  );

  return Object.values(rowsByProductId).flat();
}

export const Rates: React.FC<{
  ctrl: ReturnType<typeof useRateCardCreateController>;
  existingRates?: Schema.Types.Rate[];
  fiatCreditType: CreditType;
  customCreditTypes: CreditType[];
  creditTypesLoading?: boolean;
}> = ({
  ctrl,
  existingRates,
  fiatCreditType,
  customCreditTypes,
  creditTypesLoading,
}) => {
  const now = useNow();
  const pushMessage = useSnackbar();
  const {
    data: productData,
    loading: productsLoading,
    error: _productsError,
  } = useProductsQuery();

  const nonFixedProducts = (
    productData?.contract_pricing.products ?? []
  ).flatMap((p) => (p.__typename !== "FixedProductListItem" ? p : []));

  const rates: Schema.Types.Rate[] = ctrl.get("rates") ?? [];

  const [addParam] = useSearchParam("add");

  const [flyover, setFlyover] = React.useState<
    { type: `new_rate` } | { type: `edit_rate`; id: string } | undefined
  >(addParam === "true" ? { type: "new_rate" } : undefined);

  const onSave = (
    update: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
  ) => {
    const current = ctrl.get("rates") ?? [];
    const originalIndex = current.findIndex((r) => r.id === update.id);

    ctrl.update({
      rates:
        originalIndex === -1
          ? [...current, update]
          : [
              ...current.slice(0, originalIndex),
              update,
              ...current.slice(originalIndex + 1),
            ],
    });
  };

  function removeRateById(id: string) {
    return () => {
      ctrl.update({
        rates: (ctrl.get("rates") ?? []).filter((t) => t.id !== id),
      });
      setFlyover(undefined);
    };
  }

  const existingRateRows = (existingRates ?? []).map((r) => ({
    ...r,
    rowTheme: "disabled",
  }));
  const rateRows = productsLoading
    ? []
    : toRateRows([...existingRateRows, ...rates], nonFixedProducts, now);

  const validCreditTypeConversions =
    ctrl
      .get("creditTypeConversions")
      ?.filter((c) => !!c.custom_credit_type_id) ?? [];
  const relevantFiatCreditTypes = creditTypesLoading ? [] : [fiatCreditType];
  const relevantCustomCreditTypes = creditTypesLoading
    ? []
    : validCreditTypeConversions.map((c) => {
        const ct = customCreditTypes.find(
          (ct) => ct.id === c.custom_credit_type_id,
        );
        if (!ct) {
          Sentry.setContext("context", {
            conversion: JSON.stringify(c, null, 2),
            customCreditTypes: JSON.stringify(customCreditTypes, null, 2),
          });
          throw new Error(
            "Credit type from conversion not found in `customCreditTypes`, this is unexpected",
          );
        }
        return ct;
      });

  return (
    <Section
      title="Rates"
      actions={
        <Button
          loading={creditTypesLoading}
          onClick={() => {
            setFlyover({ type: "new_rate" });
          }}
          text="Add rate"
          theme="primary"
          leadingIcon="plus"
        />
      }
    >
      <LegacyRateCardPanel
        loading={productsLoading}
        name="Products"
        rateRows={rateRows}
        onSelectRow={(row) => {
          if (row.id && rates.find((r) => r.id === row.id)) {
            setFlyover({ type: "edit_rate", id: row.id });
          } else if (existingRates?.find((r) => r.id === row.id)) {
            pushMessage({
              content: "Existing rates may not be edited",
              type: "warning",
            });
          }
        }}
      />
      {((): React.ReactElement | null => {
        switch (flyover?.type) {
          case "new_rate":
            return (
              <RateFlyout
                rateRows={rateRows}
                products={nonFixedProducts.filter(
                  (p) => p.archived_at === null,
                )}
                onClose={() => setFlyover(undefined)}
                onSave={onSave}
                fiatCreditTypes={relevantFiatCreditTypes}
                customCreditTypes={relevantCustomCreditTypes}
              />
            );

          case "edit_rate":
            return (
              <RateFlyout
                rateRows={rateRows}
                products={nonFixedProducts}
                onSave={onSave}
                edit={(ctrl.get("rates") ?? []).find(
                  (o) => o.id === flyover.id,
                )}
                onClose={() => setFlyover(undefined)}
                onDelete={removeRateById(flyover.id)}
                fiatCreditTypes={relevantFiatCreditTypes}
                customCreditTypes={relevantCustomCreditTypes}
              />
            );
          case undefined:
            return null;
        }
      })()}
    </Section>
  );
};
