import React, { useEffect } from "react";
import "/src/tenaissance/tenaissance.css";
import Editor from "@monaco-editor/react";
import { Button } from "../Button";
import { twMerge } from "tenaissance/twMerge";
import useDebounce from "lib/debounce";
import { ConditionalWrapper } from "lib/conditionalWrapper";

interface CodeBlockProps {
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /** If the parent element of the CodeBlock will have an adjustable width, setting this to true will allow the CodeBlock to appropriately adjust its width */
  canAdjustWidth?: boolean;
  /** Text content of the CodeBlock. */
  code: string;
  /** Default - 580px */
  height?: number;
  /** Currently supporting sql syntax, will allow for other options later on. */
  language?: "sql";
  /** Provide a call back to receive code changes if the editor is being updated by the user. */
  onChange?: (code: string) => void;
  /** Provide a call back to receive debounced code changes if the editor is being updated by the user. */
  onTypingComplete?: (code: string) => void;
  /** If there is code being provided that should not be changed or interacted with, set `isReadOnly` to `true` */
  isReadOnly?: boolean;
  /**
   * Provide a callback to be executed by the user to run the code block. This will render a button on top of the
   * editor for them to kick it off.
   */
  onRun?: (code: string) => void;
  /**
   * Whether or not we should disable the run button.
   */
  runButtonDisabled?: boolean;
  /**
   * A light or dark theme for visualizing the `CodeBlock` currently supporting "vs" and "vs-dark" themes.
   * This will be updated to support metronome colors/themes in the future.
   * */
  theme?: "light" | "dark";
}

/**
 * !! PARTIAL IMPLEMENTATION !!
 * CodeBlock is both an editor and a code viewer. You can drop it in to a page that will allow users
 * to provide their own SQL for a Billable Metric, or render an example API response for consumers of
 * an endpoint.
 */
export const CodeBlock: React.FC<CodeBlockProps> = ({
  className,
  code,
  height = 580,
  language = "sql",
  onChange,
  onTypingComplete,
  onRun,
  isReadOnly,
  theme = "light",
  canAdjustWidth = false,
  runButtonDisabled = false,
}) => {
  const debouncedCode = useDebounce(code, 1000);

  useEffect(() => {
    if (onTypingComplete) {
      onTypingComplete(debouncedCode);
    }
  }, [debouncedCode, onTypingComplete]);

  return (
    <div
      className={twMerge(
        "[&_.monaco-editor]:rounded-xl [&_.overflow-guard]:rounded-xl relative font-mono",
        className,
      )}
    >
      {!!onRun && (
        <Button
          className="absolute right-[5px] top-[5px] z-[1]"
          text="Run"
          leadingIcon="play"
          theme="secondary"
          size="sm"
          onClick={() => onRun(code)}
          disabled={runButtonDisabled}
        />
      )}
      <ConditionalWrapper
        condition={canAdjustWidth}
        wrapper={(children) => (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {children}
          </div>
        )}
      >
        <Editor
          height={height}
          className={twMerge(
            "rounded-xl border [&_div.view-lines_span]:font-mono",
            theme === "light"
              ? "border-gray-200 bg-white"
              : "border-core-slate bg-black",
          )}
          theme={theme === "light" ? "vs" : "vs-dark"}
          defaultLanguage={language}
          defaultValue={code}
          onChange={(value) => (onChange ? onChange(value ?? "") : undefined)}
          options={{
            codeLens: false,
            contextmenu: false,
            fontFamily: "Roboto Mono, monospace", // does not work?
            fontSize: 14,
            overviewRulerBorder: false,
            renderLineHighlight: "none",
            minimap: {
              enabled: false,
            },
            language: language,
            padding: {
              top: !!onRun ? 45 : 24,
              bottom: 24,
            },
            readOnly: isReadOnly,
            wordWrap: "bounded",
          }}
        />
      </ConditionalWrapper>
    </div>
  );
};
