import Decimal from "decimal.js";
import { ReportConfig } from "../..";
import { dayjs } from "lib/dayjs";
import { creditMath } from "lib/credits";
import {
  CreditGrantRevenueRecognitionDocument,
  CreditGrantRevenueRecognitionQuery,
} from "./queries.graphql";
import { DEFAULT_PAGE_SIZE, REPORT_DATE_FORMAT } from "../consts";

const report: ReportConfig<CreditGrantRevenueRecognitionQuery> = {
  pageSize: DEFAULT_PAGE_SIZE,
  name: "Credit Grant Usage",
  needsDates: true,
  needsEnvironment: false,
  dataToCSV: (pages, inclusiveStartDate: Date, exclusiveEndDate: Date) => {
    const header = [
      "Start date",
      "End date",
      "Grant ID",
      "Customer name",
      "Customer ingest alias",
      "Grant name",
      "Grant reason",
      "Starting credits",
      "Used",
      "Expired",
      "Ending credits",
      "Amount paid (USD cents)",
      "Cost basis",
      "Priority",
    ];
    const rows = pages.flatMap((page) =>
      page.CreditGrant.map((creditGrant) => {
        const [startingCredits, endingCredits, expiredCredits, creditsUsed] =
          creditMath({
            startingDeductions: new Decimal(
              creditGrant.starting_deductions.aggregate?.sum?.amount ?? 0,
            ),
            endingDeductions: new Decimal(
              creditGrant.ending_deductions.aggregate?.sum?.amount ?? 0,
            ),
            amountGranted: new Decimal(creditGrant.amount_granted),
            expiresBefore: dayjs.utc(creditGrant.expires_before),
            snapshotStartsAt: dayjs.utc(inclusiveStartDate),
            snapshotEndsBefore: dayjs.utc(exclusiveEndDate),
          });
        return [
          dayjs.utc(inclusiveStartDate).format(REPORT_DATE_FORMAT),
          dayjs
            .utc(exclusiveEndDate)
            .subtract(1, "day")
            .format(
              REPORT_DATE_FORMAT,
            ) /* This is in beginning of next day format and we need to put it back in display format */,
          creditGrant.id,
          creditGrant.Customer.name,
          creditGrant.Customer.CustomerIngestAliases.map(
            (alias) => alias.ingest_alias,
          ).join(", "),
          creditGrant.name,
          creditGrant.reason ?? "",
          startingCredits.toString(),
          creditsUsed.toString(),
          expiredCredits.toString(),
          endingCredits.toString(),
          creditGrant.amount_paid.toString(),
          new Decimal(creditGrant.cost_basis).toDecimalPlaces(12).toString(),
          creditGrant.priority,
        ];
      }),
    );

    return [header, ...rows];
  },
  queryDocument: CreditGrantRevenueRecognitionDocument,
  isAllowedForUser: (ldClient) =>
    ldClient
      ?.variation("available-report-types", [])
      .includes("CREDIT_REVENUE"),
  nextCursor: (page) => page.CreditGrant.at(-1)?.id ?? null,
};

export default report;
