import React, { useEffect, useState } from "react";
import { flushSync } from "react-dom";
import { useParams } from "react-router-dom";
import { useLocationState } from "lib/routes/state";
import { useNavigate } from "lib/useNavigate";
import NotFoundPage from "pages/404";
import { DraftPlan } from "lib/plans/types";
import {
  serializeDraftPlan,
  deserializeDraftPlan,
  getDraftPlanInsert,
  removeProductsFromDraftPlan,
} from "lib/plans/draftPlan";
import { Interstitial } from "../components/Interstitial";
import { Wizard, WizardSection, getActiveStep } from "components/Wizard";
import { RevisionConflictModal } from "../components/RevisionConflictPopup";
import { useSnackbar } from "components/Snackbar";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";
import { Body, Subtitle, Hyperlink } from "design-system";
import { DraftPlanContext } from "../context";
import { planTermsSection } from "../steps/PlanTerms";
import { addProductsSection } from "../steps/AddProducts";
import { pricingSection } from "../steps/Pricing";
import { previewPlanSection } from "../steps/PreviewPlan";
import {
  useCreatePlanDataQuery,
  CreatePlanDataQuery,
  useFetchDraftPlanQuery,
  useInsertDraftPlanMutation,
  useUpdateDraftPlanMutation,
  useOverwriteDraftPlanMutation,
  useNewPlanMutation,
  useFetchCustomerPlansQuery,
  FetchCustomerPlansQuery,
} from "../data/queries.graphql";
import {
  AddPlanToCustomerMutationVariables,
  useAddPlanToCustomerMutation,
} from "pages/AddPlanToCustomer/queries.graphql";
import { getUtcEndDate, getUtcStartOfDay } from "lib/time";
import { MAX_ALLOWED_CUSTOMERS_FOR_ADD_PLAN_TO_CUSTOMER } from "../steps/PlanTerms/components/AddCustomerToPlan";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { PlanInput } from "types/generated-graphql/__types__";
import { addRecurringCreditGrantSection } from "../steps/AddRecurringCreditGrant";
import { useFeatureFlag } from "../../../lib/launchdarkly";
import { useUIMode } from "../../../lib/useUIMode";

const getSections: (
  draftPlan: DraftPlan,
  hasPreviewedPlan: boolean,
  hasCustomers: boolean,
  data: CreatePlanDataQuery,
  existingPlansData: FetchCustomerPlansQuery | undefined,
  onPreviewPlan: () => void,
  allowAnnualSeats: boolean,
) => WizardSection[] = (
  draftPlan,
  hasPreviewedPlan,
  hasCustomers,
  data,
  existingPlansData,
  onPreviewPlan,
  allowAnnualSeats,
) => [
  planTermsSection(draftPlan, data, hasCustomers, false, existingPlansData),
  addProductsSection(draftPlan, data, allowAnnualSeats, false),
  pricingSection(draftPlan, data),
  ...(draftPlan.hasRecurringGrant
    ? [addRecurringCreditGrantSection(draftPlan, data)]
    : []),
  previewPlanSection(draftPlan, hasPreviewedPlan, onPreviewPlan, data),
];

function getAddPlanToCustomerVars(
  draftPlan: DraftPlan,
): Omit<AddPlanToCustomerMutationVariables, "plan_id"> | undefined {
  if (!draftPlan.hasCustomer) {
    return undefined;
  }

  if (
    !draftPlan.customerPlanInfo ||
    !draftPlan.customerPlanInfo.startDate ||
    draftPlan.customerPlanInfo.endDate === undefined
  ) {
    throw new Error(
      "Cannot add a customer to this plan without customer name, id, or start/end date",
    );
  }

  return {
    start_date: getUtcStartOfDay(
      draftPlan.customerPlanInfo.startDate,
    ).toISOString(),
    end_date: draftPlan.customerPlanInfo.endDate
      ? getUtcEndDate(draftPlan.customerPlanInfo.endDate).toISOString()
      : null,
    customer_id: draftPlan.customerPlanInfo.customerId,
  };
}

const NewPlan: React.FC = () => {
  const navigate = useNavigate();
  const isCopy = !!useLocationState()?.copy;
  const { id: planId } = useParams<{ id?: string }>();

  const [newPlanMutation, newPlanResult] = useNewPlanMutation({
    update(cache) {
      cache.evict({
        fieldName: "Plan",
      });
      cache.evict({
        fieldName: "DraftPlan",
      });
      cache.evict({
        fieldName: "plans",
      });
      // Products could become un-archiveable because they're now being used by
      // the new plan.
      cache.evict({
        fieldName: "products",
      });
    },
  });

  const [addPlanToCustomerMutation] = useAddPlanToCustomerMutation({
    update(cache) {
      cache.evict({ fieldName: "CustomerPlan" });
      cache.evict({ fieldName: "CustomerPlans_aggregate" });
    },
  });
  const pushMessage = useSnackbar();

  const { environmentType } = useEnvironment();

  const { data, loading: productsLoading } = useCreatePlanDataQuery({
    variables: {
      limit: MAX_ALLOWED_CUSTOMERS_FOR_ADD_PLAN_TO_CUSTOMER,
      environment_type: environmentType,
    },
  });
  const [plan, setPlan] = useState<DraftPlan>({
    revision: 0,
    billingProvider: null,
  });
  const [hasPreviewedPlan, setHasPreviewedPlan] = useState<boolean>(false);

  // Used for validating plan dates if a user is adding this plan to a specific customer,
  // we fetch it here so it's ready before the user navigates to the set billing schedule step
  const { data: existingPlansData } = useFetchCustomerPlansQuery({
    variables: {
      customer_id: plan.customerPlanInfo?.customerId ?? "",
    },
    skip: !plan.customerPlanInfo?.customerId,
  });

  const { loading, data: draftPlanData } = useFetchDraftPlanQuery({
    variables: {
      id: planId || "",
    },
    // If we're already working on this plan locally, don't fetch a draft from
    // the server and clobber our local state.
    skip: !planId || planId === plan.id,
  });

  // If we've loaded a draft plan then load it into the state
  useEffect(() => {
    if (planId && draftPlanData?.DraftPlan_by_pk && data) {
      let draftPlan = deserializeDraftPlan(draftPlanData?.DraftPlan_by_pk);

      // Remove all products in the draft plan that no longer exist on the server
      // (because they may have been archived).
      const missingProductIds: string[] = [];
      draftPlan.selectedProductIds?.forEach((id) => {
        if (!data.products.find((p) => p.id === id)) {
          missingProductIds.push(id);
        }
      });
      if (missingProductIds.length > 0) {
        draftPlan = removeProductsFromDraftPlan(draftPlan, missingProductIds);
      }

      setPlan(draftPlan);
    }
  }, [draftPlanData?.DraftPlan_by_pk, planId, data]);

  const [insertDraftPlan, insertDraftPlanResults] = useInsertDraftPlanMutation({
    update(cache) {
      cache.evict({
        fieldName: "DraftPlan",
      });
    },
  });
  const [updateDraftPlan, updateDraftPlanResults] =
    useUpdateDraftPlanMutation();
  const [overwriteDraftPlan, overwriteDraftPlanResults] =
    useOverwriteDraftPlanMutation();

  const [passedLandingPage, setPassedLandingPage] = useState(isCopy);
  const [showDoneModal, setShowDoneModal] = useState(false);
  // When we're changing the URL (aka navigating from /plans/new to /plans/new/id after a save) we need to not
  // prompt the user if they want to lose any unsaved changes, so track it as state here.
  const [isChangingURL, setIsChangingURL] = useState(false);

  // This context is used to allow "blocking" a save call until the user takes action in the revision conflict modal.
  const [revisionConflictModalContext, setRevisionConflictModalContext] =
    useState<{
      overwrite: () => void;
      saveNew: () => void;
    } | null>(null);
  const allowAnnualSeats = useFeatureFlag("annual-seats", false);

  const { newUIEnabled } = useUIMode();

  if (loading || productsLoading) {
    return null;
  }
  if (!data || !data?.products) {
    navigate("/plans");
    return null;
  }
  // If we get here, we've loaded any state from the server that we're going
  // to. At this point, either we have the right plan in our state, or we
  // didn't find a draft plan with that ID on the server (hence 404).
  if (plan.id !== planId) {
    return <NotFoundPage />;
  }

  if (!passedLandingPage) {
    return (
      <Interstitial
        onClose={() => navigate(newUIEnabled ? "/offering/plans" : "/plans")}
        onContinue={() => setPassedLandingPage(true)}
        existingPlanName={plan.name}
      />
    );
  }

  const sections = getSections(
    plan,
    hasPreviewedPlan,
    false,
    data,
    existingPlansData,
    () => setHasPreviewedPlan(true),
    allowAnnualSeats ?? false,
  );

  const doneModalActions = (
    <Button
      key="primary"
      onClick={() =>
        navigate(
          `${newUIEnabled ? "/offering" : ""}/plans/${newPlanResult.data?.create_plan?.id}`,
        )
      }
      text="Done"
      theme="primary"
    />
  );

  const onDone = async () => {
    let planInsert: PlanInput;
    let addPlanToCustomerVars;
    let mutationResult;
    try {
      if (!plan.id) {
        throw new Error("Cannot save plan without draft plan ID");
      }
      planInsert = getDraftPlanInsert(plan);
      addPlanToCustomerVars = getAddPlanToCustomerVars(plan);
    } catch (err: any) {
      pushMessage({ content: err.message, type: "error" });
      return;
    }
    try {
      mutationResult = await newPlanMutation({
        variables: {
          environment_type: environmentType,
          plan: planInsert,
          draftPlanID: plan.id,
        },
      });
    } catch (err: any) {
      pushMessage({
        content: `Failed to create plan: ${err.message}`,
        type: "error",
      });
      return;
    }

    if (mutationResult.errors?.length) {
      pushMessage({
        content: `Failed to create plan: ${mutationResult.errors[0].message}`,
        type: "error",
      });
      return;
    } else {
      const newPlanId = mutationResult.data?.create_plan.id;
      if (plan.hasCustomer && newPlanId && addPlanToCustomerVars) {
        try {
          await addPlanToCustomerMutation({
            variables: {
              ...addPlanToCustomerVars,
              plan_id: newPlanId,
            },
          });
        } catch (e) {
          pushMessage({
            content: `Failed to add plan to a customer: ${e}`,
            type: "error",
          });
        }
      }
    }

    setShowDoneModal(true);
  };

  const isSaving =
    insertDraftPlanResults.loading ||
    updateDraftPlanResults.loading ||
    overwriteDraftPlanResults.loading;

  return (
    <DraftPlanContext.Provider
      value={{
        draftPlan: plan,
        setDraftPlan: (draftPlan: DraftPlan) => {
          setPlan(draftPlan);
          setHasPreviewedPlan(false);
        },
      }}
    >
      {revisionConflictModalContext && (
        <RevisionConflictModal
          close={() => setRevisionConflictModalContext(null)}
          {...revisionConflictModalContext}
        />
      )}
      <Wizard
        startAt={
          isCopy
            ? { section: 0, group: 0, subStep: 0 }
            : getActiveStep(sections)
        }
        sections={sections}
        save={async () => {
          if (!plan.id) {
            // If there is no ID on the plan, then it's not yet been persisted (as a draft plan). As such, we call `insert`.
            const results = await insertDraftPlan({
              variables: {
                data: serializeDraftPlan(plan),
              },
            });

            // Load the resulting draft plan into the state, which will set the `id` so subsequent saves won't try to insert a new plan.
            if (results.data?.insert_DraftPlan?.returning[0]) {
              const plan = deserializeDraftPlan(
                results.data?.insert_DraftPlan?.returning[0],
              );

              flushSync(() => {
                setPlan(plan);
                setIsChangingURL(true);
              });
              navigate(
                `${newUIEnabled ? "/offering" : ""}/plans/new/${plan.id}`,
                { replace: true },
              );
              flushSync(() => {
                setIsChangingURL(false);
              });
            }
            return;
          }

          // If we got here it means we're editing a draft plan that already exists in the DB.
          try {
            // We try to save with an incremented revision.
            const response = await updateDraftPlan({
              variables: {
                id: plan.id,
                revision: plan.revision + 1,
                data: serializeDraftPlan(plan),
              },
            });
            // If we've got here it means the update wasn't rejected, so we can persist the new data to the state.
            if (response.data?.update_DraftPlan_by_pk) {
              setPlan(
                deserializeDraftPlan(response.data.update_DraftPlan_by_pk),
              );
            }
          } catch (err: any) {
            // If the error isn't a revision mismatch then throw it - something unexpected happened.
            if (!err?.message?.includes("plan_revision_mismatch")) {
              throw err;
            }

            // Return a promise to "block" progression. Until the Promise is resolved the wizard won't progress.
            return new Promise((resolve) => {
              // Set the revision conflict modal context - the 2 methods (saveNew and overWrite) handle persisting the draft plan
              // and then calling `resolve` (allowing the wizard to progress).
              setRevisionConflictModalContext({
                overwrite: async () => {
                  // If the user chooses overwrite, we call overwrite, which does an update with passing revision (thus blowing away whatever is in the DB)
                  const results = await overwriteDraftPlan({
                    variables: {
                      id: plan.id || "",
                      data: serializeDraftPlan(plan),
                    },
                  });
                  // Now that the overwrite is done, hide the modal...
                  setRevisionConflictModalContext(null);
                  // And update the state (with the revision and data)
                  if (results.data?.update_DraftPlan_by_pk) {
                    setPlan(
                      deserializeDraftPlan(results.data.update_DraftPlan_by_pk),
                    );
                  }
                  // And then resolve the promise, so that the wizard will progress
                  resolve();
                },
                saveNew: async () => {
                  // If the user chooses to save the draft as a new plan, we instead want to call `insert` again, but with a new plan name.
                  const results = await insertDraftPlan({
                    variables: {
                      data: {
                        ...serializeDraftPlan(plan),
                        name: `${plan.name} (copy)`,
                      },
                    },
                  });
                  if (results.data?.insert_DraftPlan?.returning[0]) {
                    const newPlan = deserializeDraftPlan(
                      results.data?.insert_DraftPlan?.returning[0],
                    );
                    flushSync(() => {
                      setPlan(newPlan);
                      setIsChangingURL(true);
                    });
                    navigate(
                      `${newUIEnabled ? "/offering" : ""}/plans/new/${newPlan.id}`,
                      { replace: true },
                    );
                    flushSync(() => {
                      setIsChangingURL(false);
                    });
                  }
                  // Once the new plan is persisted and loaded into the state we can close the modal and resolve the promise.
                  setRevisionConflictModalContext(null);
                  resolve();
                },
              });
            });
          }
        }}
        saveInProgress={isSaving}
        onClose={() => navigate(newUIEnabled ? "/offering/plans" : "/plans")}
        onDone={onDone}
        closeWarning={
          isChangingURL
            ? undefined
            : "Are you sure you want to leave the plan creation wizard? You'll lose any unsaved changes."
        }
        title="Create a plan"
        subtitle={
          plan?.name ? <Subtitle level={4}>{plan.name}</Subtitle> : null
        }
        loading={newPlanResult.loading}
      />
      {showDoneModal && (
        <Popup
          actions={doneModalActions}
          isOpen={showDoneModal}
          title="New plan created"
        >
          <Body level={2}>Your plan is ready to be used with customers!</Body>
          <Body level={2}>
            Customers can be added to this plan with the Metronome UI or the
            API.{" "}
            <Hyperlink routePath="https://docs.metronome.com/api#tag/Plans">
              Learn more about our plans API.
            </Hyperlink>
          </Body>
        </Popup>
      )}
    </DraftPlanContext.Provider>
  );
};

export default NewPlan;
