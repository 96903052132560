import React, { useState } from "react";

import styles from "./index.module.less";
import { Body } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";
import { Input } from "design-system";
import { useUpsertSalesforceAccountIdMutation } from "./queries.graphql";
import { useSnackbar } from "components/Snackbar";

type SalesforceConnectionModalProps = {
  isOpen: boolean;
  onClose: (deleteConfig?: boolean) => void;
  initialAccountId: string | undefined;
  customerId: string;
};

export const SalesforceConnectionModal: React.FC<
  SalesforceConnectionModalProps
> = ({ isOpen, onClose, initialAccountId, customerId }) => {
  const [accountId, setAccountId] = useState(initialAccountId ?? "");
  const pushMessage = useSnackbar();
  const [upsertSalesforceAccountId, { loading }] =
    useUpsertSalesforceAccountIdMutation();
  /* Salesforce Account ID spec: https://salesforce.stackexchange.com/questions/1653/what-are-salesforce-ids-composed-of */
  const accountIdRegex = new RegExp("^[a-zA-Z0-9]{15}(?:[a-zA-Z0-9]{3})?$");
  const validate = (accountId: string) => accountIdRegex.test(accountId);
  const isEdit = initialAccountId !== undefined;

  return (
    <Popup
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      actions={
        <>
          {isEdit ? (
            <Button
              onClick={() => onClose(true)}
              text="Delete Account ID"
              theme="linkGray"
            />
          ) : (
            <Button onClick={() => onClose()} text="Cancel" theme="linkGray" />
          )}

          <Button
            onClick={async () => {
              await upsertSalesforceAccountId({
                variables: {
                  customer_id: customerId,
                  salesforce_account_id: accountId,
                },
                update: (cache) => {
                  cache.evict({
                    fieldName: "CustomerConfig",
                  });
                },
              });
              pushMessage({
                content: "Salesforce account ID saved",
                type: "success",
              });
              onClose();
            }}
            loading={loading}
            disabled={loading || !validate(accountId)}
            text="Set Account ID"
            theme="primary"
          />
        </>
      }
      title="Salesforce account ID"
      className={styles.salesforceConnectionModal}
    >
      <Body level={1} className={styles.description}>
        To link this customer to Salesforce you need to enter the Salesforce
        account ID. The Metronome Salesforce Connector app will need to be
        installed first to push data to Salesforce.
      </Body>
      <Input
        className={styles.salesforceAccountIdInput}
        placeholder="0015500001WO1ZiABL"
        value={accountId}
        onChange={setAccountId}
        name="Account ID"
        error={accountId && !validate(accountId)}
        success={validate(accountId)}
      />
    </Popup>
  );
};
