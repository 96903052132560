import React, { useEffect, useMemo, useState } from "react";
import { Schema } from "../Schema";
import { RateCardContext, RateProductEnum } from "./RateCardContext";
import { Column, Table } from "tenaissance/components/Table";
import { DatePicker } from "tenaissance/components/DatePicker";
import { Tooltip } from "tenaissance/components/Tooltip";
import { Icon } from "tenaissance/components/Icon";
import { dayjs } from "lib/dayjs";
import { getDateStringInUTC } from "lib/time";
import { twMerge } from "tenaissance/twMerge";
import { Toggle } from "tenaissance/components/Toggle";
import { RateInput } from "./RateInput";
import { IconButton } from "tenaissance/components/IconButton";
import {
  Dropdown,
  DropdownHR,
  DropdownItem,
} from "tenaissance/components/Dropdown";
import { v4 as uuid } from "uuid";

type Props = {
  rates: Schema.Types.Rate[];
  type: RateProductEnum;
  title: string;
};

export function RatesTable({ rates, type, title }: Props) {
  const {
    editRate,
    addRateOverride,
    removeRateOverride,
    removeProduct,
    conversionRateChange,
    customCreditTypes,
    fiatCreditType,
    setRateStartingAtDate,
    setRateEndingBeforeDate,
    productsMap,
    creditTypeConversions,
    loading,
  } = RateCardContext.useContainer();

  const [newRateId, setNewRateId] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (newRateId) {
      window.setTimeout(() => {
        const newRow = document.getElementById(newRateId);
        if (newRow) {
          const currClass = newRow.getAttribute("class");
          newRow.setAttribute("class", `${currClass} bg-gray-50`);
        }
      }, 100);

      window.setTimeout(() => {
        const newRow = document.getElementById(newRateId);
        if (newRow) {
          const currClass = newRow.getAttribute("class");
          newRow.setAttribute(
            "class",
            `${currClass} transition-all duration-1000 !bg-white`,
          );
        }
      }, 200);

      setNewRateId(undefined);
    }
  }, [newRateId]);

  const productRateColumns: Column<Schema.Types.Rate>[] = useMemo(
    () => [
      {
        id: "1",
        header: "Product",
        accessorFn: (props) => props,
        cell: (props: { getValue: () => Schema.Types.Rate }) => {
          const row = props.getValue();
          const disabled = !!row.isRemovedSubrate;
          return (
            <div className="flex flex-col">
              <div
                className={`text-core-slate text-sm font-medium ${disabled ? "!text-gray-400" : ""}`}
              >
                {productsMap.get(row.productId)?.current.name ?? ""}
              </div>
              {row.pricingGroupValues && (
                <div
                  className={`text-xs font-normal text-gray-600 ${disabled ? "!text-gray-400" : ""}`}
                >
                  {row.pricingGroupValues
                    ? Object.values(row.pricingGroupValues).join(", ")
                    : ""}
                </div>
              )}
            </div>
          );
        },
        enableSorting: false,
        cellClassName: "align-top",
      },
      {
        id: "2",
        header: () => {
          return (
            <div className="flex items-center">
              <DatePicker
                icon={{
                  icon: "edit01",
                  theme: "tertiary",
                }}
                onDateApply={(date) => {
                  setRateStartingAtDate(type, date);
                }}
              />
              <span className="px-4">Starting at (UTC)</span>
              <Tooltip label="The start and end dates of a product enable you to pricing products on a schedule. For example, in the future if you wish to change pricing on a product you do determine the exact time that change should occur.">
                <Icon icon="helpCircle" size={15} />
              </Tooltip>
            </div>
          );
        },
        enableSorting: false,
        cellClassName: "align-top !px-12",
        headerClassName: "!px-12",
        size: 220,
        accessorFn: (props) => props,
        cell: (props: { getValue: () => Schema.Types.Rate }) => {
          const row = props.getValue();
          const date = row.startingAt
            ? dayjs.utc(row.startingAt).toDate()
            : undefined;
          const disabled = !!row.isRemovedSubrate;
          const text = date ? getDateStringInUTC(date) : "--";
          return (
            <div
              className={twMerge(
                "group flex items-center",
                disabled && "text-gray-400",
              )}
            >
              <DatePicker
                icon={{
                  icon: "edit01",
                  theme: "tertiary",
                  className: `invisible ${!disabled ? "group-hover:visible" : ""}`,
                }}
                disabled={disabled}
                openToDate={date}
                onDateApply={(date) => {
                  if (date) {
                    editRate(type, {
                      ...row,
                      startingAt: date.toISOString(),
                    });
                  }
                }}
              />
              <span className="px-4">{text}</span>
            </div>
          );
        },
      },
      {
        id: "3",
        header: () => {
          return (
            <div className="flex items-center">
              <DatePicker
                icon={{
                  icon: "edit01",
                  theme: "tertiary",
                }}
                onDateApply={(date) => {
                  setRateEndingBeforeDate(type, date);
                }}
              />
              <span className="pl-4">Ending before (UTC)</span>
            </div>
          );
        },
        enableSorting: false,
        cellClassName: "align-top !px-12",
        headerClassName: "!px-12",
        size: 220,
        accessorFn: (props) => props,
        cell: (props: { getValue: () => Schema.Types.Rate }) => {
          const row = props.getValue();
          const date = row.endingBefore
            ? dayjs.utc(row.endingBefore).toDate()
            : undefined;
          const disabled = !!row.isRemovedSubrate;
          const text = date ? getDateStringInUTC(date) : "--";
          return (
            <div
              className={twMerge(
                "group flex items-center",
                disabled && "text-gray-400",
              )}
            >
              <DatePicker
                icon={{
                  icon: "edit01",
                  theme: "tertiary",
                  className: `invisible ${!disabled ? "group-hover:visible" : ""}`,
                }}
                disabled={disabled}
                openToDate={date}
                onDateApply={(date) => {
                  if (date) {
                    editRate(type, {
                      ...row,
                      endingBefore: date.toISOString(),
                    });
                  } else {
                    editRate(type, {
                      ...row,
                      endingBefore: undefined,
                    });
                  }
                }}
              />
              <span className="px-4">{text}</span>
            </div>
          );
        },
      },
      {
        id: "4",
        header: () => {
          return (
            <div className="flex items-center gap-4">
              Entitlement
              <Tooltip label="Entitlements determine if a customer is given access to a product by default. Entitlements are often applied to add-on products customers generally don’t receive by default.">
                <Icon icon="helpCircle" size={15} />
              </Tooltip>
            </div>
          );
        },
        enableSorting: false,
        cellClassName: "align-top !px-12 !pt-24",
        headerClassName: "!px-12",
        accessorFn: (props) => props,
        cell: (props: { getValue: () => Schema.Types.Rate }) => {
          const row = props.getValue();
          return (
            <Toggle
              disabled={!!row.isRemovedSubrate}
              toggled={row.entitled === "enable"}
              onChange={() => {
                const entitled =
                  row.entitled === "enable" ? "disable" : "enable";
                editRate(type, {
                  ...row,
                  entitled,
                });
              }}
              label=""
            />
          );
        },
      },
      {
        id: "5",
        header: "Rate",
        enableSorting: false,
        cellClassName: "align-top !pl-12 !pr-0",
        headerClassName: "!px-12",
        size: 400,
        accessorFn: (props) => props,
        cell: (props: { getValue: () => Schema.Types.Rate }) => {
          const row = props.getValue();
          return (
            <RateInput
              disabled={!!row.isRemovedSubrate}
              rate={row}
              fiatCreditType={fiatCreditType}
              customCreditTypes={customCreditTypes}
              creditTypeConversions={creditTypeConversions}
              onChange={(rate) => {
                editRate(type, rate);
              }}
              key={row.id}
              onConversionRateChange={conversionRateChange}
            />
          );
        },
      },
      {
        id: "6",
        header: "",
        isDisplay: true,
        cellClassName: "align-top !px-12",
        headerClassName: "!px-12",
        accessorFn: (props) => props,
        cell: (props: { getValue: () => Schema.Types.Rate }) => {
          const row = props.getValue();
          const disabled = !!row.isRemovedSubrate;
          const hasTiers = row.price.type === "tiered";
          return (
            <div className="flex flex-row gap-4">
              {!row.isOverrideRate ? (
                <Tooltip disabled={disabled} label="Add a rate change">
                  <IconButton
                    disabled={disabled}
                    onClick={() => {
                      const id = uuid();
                      setNewRateId(id);
                      addRateOverride(type, row, {
                        ...row,
                        id,
                        isOverrideRate: true,
                      });
                    }}
                    icon="calendarPlus02"
                    theme="tertiary"
                  />
                </Tooltip>
              ) : (
                <IconButton
                  disabled={disabled}
                  onClick={() => {
                    removeRateOverride(type, row);
                  }}
                  icon="calendarMinus02"
                  theme="tertiary"
                  className="text-error-500"
                />
              )}
              <Dropdown
                buttonTheme="tertiary"
                icon="dotsVertical"
                headerContent="Rate options"
                hideChevron={true}
              >
                {type === "usageRates" && (
                  <>
                    <DropdownItem
                      label={hasTiers ? "Remove tiers" : "Add tiers"}
                      value="tiers"
                      icon="dotpoints01"
                      disabled={!!row.isRemovedSubrate}
                      onClick={() => {
                        // toggles from flat to tiered and vice versa
                        let newPrice: Schema.Types.Rate["price"];
                        if (row.price.type === "flat") {
                          newPrice = {
                            type: "tiered",
                            tiers: [
                              {
                                lastUnit: 1000,
                                unitPrice: row.price.price,
                              },
                              {
                                unitPrice: 0,
                              },
                            ],
                          };
                        } else if (row.price.type === "tiered") {
                          newPrice = {
                            type: "flat",
                            price: row.price.tiers[0].unitPrice,
                          };
                        } else {
                          return;
                        }

                        editRate(type, {
                          ...row,
                          price: newPrice,
                        });
                      }}
                    />
                    <DropdownHR />
                  </>
                )}
                {row.pricingGroupValues && (
                  <DropdownItem
                    label={
                      disabled ? "Undo sub-rate removal" : "Remove sub-rate"
                    }
                    value={row.id}
                    icon={disabled ? "flipBackward" : "trash01"}
                    onClick={(data) => {
                      editRate(type, {
                        ...row,
                        isRemovedSubrate: !disabled,
                      });
                    }}
                  />
                )}
                <DropdownItem
                  label="Remove product"
                  value={row.productId}
                  icon="trash01"
                  onClick={(data) => {
                    removeProduct(data.value);
                  }}
                />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [
      fiatCreditType,
      customCreditTypes,
      productsMap,
      creditTypeConversions,
      newRateId,
    ],
  );
  return (
    <Table
      title={title}
      key={type}
      data={rates}
      columns={productRateColumns}
      loading={loading}
    />
  );
}
