import React from "react";
import styles from "./index.module.less";
import { Icon, Subtitle } from "design-system";
import { EmptyState } from "components/EmptyState";
import { LeaveInvoicesInDraftCheckbox } from "./components/LeaveInvoicesInDraftCheckbox";
import { ClientConfigCheckbox } from "./components/ClientConfigCheckbox";
import { LineItemOptions } from "./components/LineItemOptions";
import { ClientConfigKeyEnum_Enum } from "types/generated-graphql/__types__";
import { InvoiceDaysUntilDueToggle } from "./components/InvoiceDaysUntilDueToggle";

export const StripeInvoiceSettings: React.FC<{}> = () => {
  const [errorLoadingData, setErrorLoadingData] = React.useState(false);

  function handleErrorLoadingData(value: boolean) {
    setErrorLoadingData(value);
  }

  if (errorLoadingData) {
    return (
      <EmptyState
        title="We ran into an issue loading this page"
        subtitle="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
        icon="layersThree02"
      />
    );
  }

  return (
    <>
      <div className={styles.settingsSection}>
        <div className={styles.settingHeader}>
          <Icon className={styles.settingIcon} icon="settings" />
          <Subtitle level={2}>Invoice settings</Subtitle>
        </div>
        <InvoiceDaysUntilDueToggle
          onErrorLoadingData={handleErrorLoadingData}
        />
        <LeaveInvoicesInDraftCheckbox
          onErrorLoadingData={handleErrorLoadingData}
        />
        <ClientConfigCheckbox
          clientConfigKey={ClientConfigKeyEnum_Enum.SkipZeroDollarInvoices}
          successMessages={{
            true: "Saved. Zero-total invoices will be skipped",
            false: "Saved. Zero-total invoices will be sent",
          }}
          errorMessage="Failed to save change to skipping zero-total invoices"
          onErrorLoadingData={handleErrorLoadingData}
          label="Skip zero-total invoices"
          helpText="By default, all invoices are sent to Stripe, regardless of their total. This option instead only sends Stripe invoices when there's a non-zero total due."
        />
      </div>
      <div className={styles.settingsSection}>
        <div className={styles.settingHeader}>
          <Icon className={styles.settingIcon} icon="receipt" />
          <Subtitle level={2}>Invoice presentation</Subtitle>
        </div>
        <LineItemOptions onErrorLoadingData={handleErrorLoadingData} />
      </div>
    </>
  );
};
