import { renderDateRange } from "lib/time";
import React, { useMemo } from "react";
import { Button } from "tenaissance/components/Button";
import { CopyableID } from "tenaissance/components/CopyableID";
import { DatePicker } from "tenaissance/components/DatePicker";
import { IconButton } from "tenaissance/components/IconButton";
import { TextInput } from "tenaissance/components/Input";
import { SideSheet } from "tenaissance/components/SideSheet";
import { RateCardAliasScheduleSegment } from "types/generated-graphql/__types__";

type Props = {
  onClose: () => void;
  onSave: (aliases: RateCardAliasScheduleSegment[]) => Promise<void>;
  aliases: RateCardAliasScheduleSegment[];
};
export default function RateCardAliasesFlyout({
  onClose,
  onSave,
  aliases,
}: Props) {
  const [isSaving, setIsSaving] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [editedAliases, setEditedAliases] =
    React.useState<RateCardAliasScheduleSegment[]>(aliases);

  const trailingActions:
    | [React.ReactElement]
    | [React.ReactElement, React.ReactElement] = useMemo(() => {
    if (isEditing) {
      return [
        <Button
          loading={isSaving}
          onClick={async () => {
            try {
              setIsSaving(true);
              await onSave(editedAliases);
            } finally {
              setIsSaving(false);
            }
          }}
          text="save"
        />,
        <Button
          loading={isSaving}
          onClick={() => {
            setIsEditing(false);
          }}
          text="cancel"
          theme="secondary"
        />,
      ];
    } else {
      return [<Button onClick={onClose} text="done" />];
    }
  }, [isEditing, editedAliases, onSave, setIsEditing]);

  const leadingAction = useMemo(() => {
    if (isEditing) {
      return undefined;
    } else {
      return (
        <Button
          theme="tertiary"
          leadingIcon="pencil01"
          onClick={() => setIsEditing(true)}
          text="Edit aliases"
        />
      );
    }
  }, [isEditing, setIsEditing]);

  return (
    <SideSheet
      isOpen={true}
      onClose={onClose}
      title="Rate card aliases"
      supportingText="Reference a rate card via an alias. This alias can be used when provisioning a customer to a contract."
      trailingActions={trailingActions}
      leadingAction={leadingAction}
    >
      <div className="gap-3xl flex flex-col">
        {isEditing ? (
          <div className="gap-3xl flex flex-col">
            {editedAliases.map((alias, index) => (
              <div key={index} className="gap-lg flex flex-col">
                <div className="gap-lg flex flex-row">
                  <TextInput
                    type="text"
                    disableClear
                    fullWidth
                    helpText="Alias name"
                    label="Alias"
                    value={alias.data.alias}
                    onChange={(e) => {
                      setEditedAliases((currentAliases) => [
                        ...currentAliases.slice(0, index),
                        {
                          ...currentAliases[index],
                          data: {
                            ...currentAliases[index].data,
                            alias: e.value,
                          },
                        },
                        ...currentAliases.slice(index + 1),
                      ]);
                    }}
                  />
                  <IconButton
                    className="self-end"
                    icon="trash01"
                    theme="secondary"
                    onClick={() => {
                      setEditedAliases((currentAliases) => {
                        const newAliases = [...currentAliases];
                        newAliases.splice(index, 1);
                        return newAliases;
                      });
                    }}
                  />
                </div>
                <div className="gap-lg flex flex-row">
                  <DatePicker
                    openToDate={
                      alias.starting_at
                        ? new Date(alias.starting_at)
                        : undefined
                    }
                    onDateApply={(value) => {
                      setEditedAliases((currentAliases) => [
                        ...currentAliases.slice(0, index),
                        {
                          ...currentAliases[index],
                          starting_at: value?.toISOString() ?? null,
                        },
                        ...currentAliases.slice(index + 1),
                      ]);
                    }}
                    value={
                      alias.starting_at
                        ? new Date(alias.starting_at)
                        : undefined
                    }
                  />
                  <DatePicker
                    text="Ending before"
                    openToDate={
                      alias.ending_before
                        ? new Date(alias.ending_before)
                        : undefined
                    }
                    onDateApply={(value) => {
                      setEditedAliases((currentAliases) => [
                        ...currentAliases.slice(0, index),
                        {
                          ...currentAliases[index],
                          ending_before: value?.toISOString() ?? null,
                        },
                        ...currentAliases.slice(index + 1),
                      ]);
                    }}
                    value={
                      alias.ending_before
                        ? new Date(alias.ending_before)
                        : undefined
                    }
                  />
                </div>
              </div>
            ))}
            <Button
              text="Add alias"
              leadingIcon="plus"
              onClick={() => {
                setEditedAliases((aliases) => [
                  ...aliases,
                  {
                    data: {
                      alias: "",
                    },
                    starting_at: null,
                    ending_before: null,
                  },
                ]);
              }}
            />
          </div>
        ) : (
          editedAliases.map((alias, idx) => (
            <div key={idx}>
              <div className="text-sm font-semibold text-gray-700">
                {renderDateRange(
                  new Date(alias.starting_at ?? "2024-01-01"),
                  new Date(alias.ending_before ?? "2024-01-01"),
                  {
                    isUtc: true,
                  },
                  false /*isRichText */,
                )}
              </div>
              <CopyableID
                id={alias.data.alias}
                className="font-normal text-gray-600"
              />
            </div>
          ))
        )}
      </div>
    </SideSheet>
  );
}
