/* f0a87bd89e0111f2dfa7c1b78b0a11cb3c3304cf
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientOverviewQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ClientOverviewQuery = { __typename?: 'Query', client_overview: { __typename?: 'ClientOverview', total_billings: { __typename?: 'Billings', ending_before: string, results: Array<{ __typename?: 'Billings_Result', amount: string, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } }> } | null, current_period_billings: { __typename?: 'BillingsWithMoMChange', ending_before: string, results: Array<{ __typename?: 'BillingsWithMoMChange_Result', amount: string, credit_type: { __typename?: 'CreditType', id: string, name: string, environment_type: Types.EnvironmentTypeEnum_Enum | null } }> } | null, top_products: { __typename?: 'TopProducts', ending_before: string, results: Array<{ __typename?: 'TopProducts_Result', amount_billed: string, credit_type: { __typename?: 'CreditType', id: string, name: string, environment_type: Types.EnvironmentTypeEnum_Enum | null }, product: { __typename?: 'TopProducts_Result_Product', id: string, name: string | null }, month_over_month_change: { __typename?: 'MonthOverMonthChange', percent_change: string | null, previous_period_total_billed: string | null, previous_period_ending_before: string } }> } | null, top_plans: { __typename?: 'TopPlans', ending_before: string, results: Array<{ __typename?: 'TopPlans_Result', amount_billed: string, credit_type: { __typename?: 'CreditType', id: string, name: string, environment_type: Types.EnvironmentTypeEnum_Enum | null }, plan: { __typename?: 'TopPlans_Result_Plan', id: string, name: string | null }, month_over_month_change: { __typename?: 'MonthOverMonthChange', percent_change: string | null, previous_period_total_billed: string | null, previous_period_ending_before: string } }> } | null, new_customers: { __typename?: 'NewCustomers', ending_before: string, total: string, by_day: Array<{ __typename?: 'NewCustomers_ByDay', date: string, count: string }> } | null } };

export type CustomerCountQueryVariables = Types.Exact<{
  environmentType?: Types.InputMaybe<Types.EnvironmentTypeEnum_Enum>;
  isArchived?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type CustomerCountQuery = { __typename?: 'Query', Client: Array<{ __typename?: 'Client', id: string, customer_count: { __typename?: 'CustomerCount', count: number, estimated: boolean } }> };


export const ClientOverviewDocument = gql`
    query ClientOverview {
  client_overview {
    total_billings {
      ending_before
      results {
        credit_type {
          id
          name
          client_id
          environment_type
          __environment_type: environment_type
        }
        amount
      }
    }
    current_period_billings {
      ending_before
      results {
        credit_type {
          id
          name
          environment_type
          __environment_type: environment_type
        }
        amount
      }
    }
    top_products {
      results {
        credit_type {
          id
          name
          environment_type
          __environment_type: environment_type
        }
        product {
          id
          name
        }
        amount_billed
        month_over_month_change {
          percent_change
          previous_period_total_billed
          previous_period_ending_before
        }
      }
      ending_before
    }
    top_plans {
      results {
        credit_type {
          id
          name
          environment_type
          __environment_type: environment_type
        }
        plan {
          id
          name
        }
        amount_billed
        month_over_month_change {
          percent_change
          previous_period_total_billed
          previous_period_ending_before
        }
      }
      ending_before
    }
    new_customers {
      ending_before
      total
      by_day {
        date
        count
      }
    }
  }
}
    `;

/**
 * __useClientOverviewQuery__
 *
 * To run a query within a React component, call `useClientOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientOverviewQuery(baseOptions?: Apollo.QueryHookOptions<ClientOverviewQuery, ClientOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientOverviewQuery, ClientOverviewQueryVariables>(ClientOverviewDocument, options);
      }
export function useClientOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientOverviewQuery, ClientOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientOverviewQuery, ClientOverviewQueryVariables>(ClientOverviewDocument, options);
        }
export type ClientOverviewQueryHookResult = ReturnType<typeof useClientOverviewQuery>;
export type ClientOverviewLazyQueryHookResult = ReturnType<typeof useClientOverviewLazyQuery>;
export type ClientOverviewQueryResult = Apollo.QueryResult<ClientOverviewQuery, ClientOverviewQueryVariables>;
export const CustomerCountDocument = gql`
    query CustomerCount($environmentType: EnvironmentTypeEnum_enum, $isArchived: Boolean) {
  Client {
    id
    customer_count(environment_type: $environmentType, is_archived: $isArchived) {
      count
      estimated
    }
  }
}
    `;

/**
 * __useCustomerCountQuery__
 *
 * To run a query within a React component, call `useCustomerCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCountQuery({
 *   variables: {
 *      environmentType: // value for 'environmentType'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useCustomerCountQuery(baseOptions?: Apollo.QueryHookOptions<CustomerCountQuery, CustomerCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerCountQuery, CustomerCountQueryVariables>(CustomerCountDocument, options);
      }
export function useCustomerCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerCountQuery, CustomerCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerCountQuery, CustomerCountQueryVariables>(CustomerCountDocument, options);
        }
export type CustomerCountQueryHookResult = ReturnType<typeof useCustomerCountQuery>;
export type CustomerCountLazyQueryHookResult = ReturnType<typeof useCustomerCountLazyQuery>;
export type CustomerCountQueryResult = Apollo.QueryResult<CustomerCountQuery, CustomerCountQueryVariables>;