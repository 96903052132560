import React, { useState } from "react";
import { Button } from "tenaissance/components/Button";
import { EmptyState } from "tenaissance/components/EmptyState";
import { Takeover } from "tenaissance/components/Takeover";
import { useNavigate } from "lib/useNavigate";
import { SectionLabel } from "tenaissance/components/SectionLabel";
import { Icon } from "tenaissance/components/Icon";

export const shouldShowPreviewPage = () =>
  localStorage.getItem("show-rate-card-create-preview") === null;

interface RateCardCreateLandingPageProps {
  setShowPreviewPage: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RateCardCreateLandingPage: React.FC<
  RateCardCreateLandingPageProps
> = ({ setShowPreviewPage }) => {
  const navigate = useNavigate();

  const [dontShowPreviewAgainClicked, setDontShowPreviewAgainClicked] =
    useState(false);

  const handleContinueClick = () => {
    if (dontShowPreviewAgainClicked)
      localStorage.setItem("show-rate-card-create-preview", "false");
    setShowPreviewPage(false);
  };

  return (
    <Takeover
      maxContainerWidth="max-w-[616px]"
      isOpen={true}
      onClose={() => navigate("/contract-pricing/rate-cards")}
      title="Create a rate card"
      footerTrailingButtons={[
        <Button
          leadingIcon={dontShowPreviewAgainClicked ? "xCircle" : "circle"}
          onClick={() =>
            setDontShowPreviewAgainClicked(!dontShowPreviewAgainClicked)
          }
          text="Don't show again"
          theme="tertiary"
        />,
        <Button
          className="w-[128px] justify-center"
          onClick={handleContinueClick}
          text="Continue"
          theme="primary"
          type="submit"
        />,
      ]}
      footerLeadingButton={
        <Button
          className="w-[128px] justify-center"
          text="Back"
          onClick={() => {
            navigate("/contract-pricing/rate-cards");
          }}
          theme="secondary"
        />
      }
      children={
        <div className="flex flex-col items-center">
          <EmptyState
            className="w-[616px]"
            icon="file06"
            mainText="Welcome to the Metronome rate card creator"
            supportingText="A rate card outlines the customer's product access and pricing. After naming and describing your rate card, you’ll be asked to select the applicable products and rate them."
          />
          <div className="flex h-[330px] w-[560px] flex-col items-start gap-[50px]">
            <div className="inline-flex">
              <Icon icon="content" className="mr-[16px] shrink-0" />
              <SectionLabel
                title="Add products"
                subtitle="Select the products that are applicable to this rate card. Products can be usage-based, subscriptions, and composite."
              />
            </div>
            <div className="inline-flex">
              <Icon icon="content" className="mr-[16px] shrink-0" />
              <SectionLabel
                title="Add dimensional pricing (optional)"
                subtitle="If you select products with dimensional pricing keys you’ll have a additional step to define values for each pricing key. This will enable a single product to have variable rates based on the properties of an event."
              />
            </div>
            <div className="inline-flex">
              <Icon icon="content" className="mr-[16px] shrink-0" />
              <SectionLabel
                title="Rate products"
                subtitle="Products are rated on a schedule meaning you can build in pricing/entitlement changes over time. Entitlements determine if customers associated to this rate card should get access to a product be default."
              />
            </div>
          </div>
        </div>
      }
    ></Takeover>
  );
};
