import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PageContainer } from "components/PageContainer";
import {
  Body,
  Label,
  Headline,
  Input,
  Icon,
  Subtitle,
  Caption,
  HelpCircleTooltip,
  LoadingSpinner,
} from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { useNavigate } from "lib/useNavigate";
import {
  Condition,
  jsonSchemaToConditions,
  conditionsToJsonSchema,
} from "@metronome-industries/json-schema-conditions";
import ConditionInput from "./components/ConditionInput";
import { useDebounce } from "lib/debounce";
import {
  ArchivedFilter,
  BillingMetricAggregateEnum_Enum,
  SimulatedFancyMetricUsageData,
} from "types/generated-graphql/__types__";
import { Select } from "design-system";
import classnames from "classnames";
import {
  useInsertBillableMetricMutation,
  useBillableMetricDetailQuery,
  useInsertSeatMetricMutation,
  useSeatMetricDetailQuery,
  useValidateFancyBillableMetricLazyQuery,
  useInsertFancyMetricMutation,
  useSimulateFancyMetricUsageLazyQuery,
} from "./queries.graphql";
import { useSnackbar } from "components/Snackbar";
import { useFeatureFlag } from "lib/launchdarkly";
import { Tooltip } from "design-system";
import JsonSchemaValidator from "components/JsonSchemaValidator";
import { useParams } from "react-router-dom";
import { TextSkeleton } from "components/Skeleton";
import { MetricType } from "lib/billableMetrics/types";
import { useGigaRateCardEnabled } from "pages/Contracts/lib/GigaRateCard";
import { ButtonGroup } from "tenaissance/components/ButtonGroup";
import { twMerge } from "design-system/twMerge";
import { CodeBlock } from "tenaissance/components/CodeBlock";
import { useEventsQuery } from "pages/Events/queries.graphql";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { dayjs } from "lib/dayjs";
import { DataColumn, Table } from "tenaissance/components/Table";
import { useSearchCustomersQuery } from "pages/Contracts/CustomersList/data.graphql";
import { ReactComponent as EmptyResult } from "./emptyState.svg";

const MAX_ENUMS = 30;
const MAX_PROP_FILTERS = 15;
const NUM_EVENT_ROWS = 1000;

const startingAfter = dayjs().subtract(30, "day").toISOString();
const ending_before = dayjs().toISOString();

interface SimulatedUsageTableRow {
  usageData: SimulatedFancyMetricUsageData;
  id: string;
}

export const BillableMetricV2: React.FC<{ metricType: MetricType }> = ({
  metricType,
}) => {
  /* Attempt to pull in id parameter and populate form */
  const { id: metricId } = useParams<{ id?: string }>();
  const { data: draftMetricData, loading } = useBillableMetricDetailQuery({
    variables: {
      billable_metric_id: metricId || "",
    },
    skip: !metricId || metricType !== "billable",
  });
  const { environmentType } = useEnvironment();
  const { data: draftSeatMetricData, loading: seatLoading } =
    useSeatMetricDetailQuery({
      variables: {
        seat_metric_id: metricId || "",
      },
      skip: !metricId || metricType !== "seat",
    });
  const gigaRateCardEnabled = useGigaRateCardEnabled();
  const enableSeats = useFeatureFlag<boolean>("seats", false);
  const flexAggEnabled = useFeatureFlag<boolean>("flex-agg-ui", false);
  const navigate = useNavigate();
  const [metricName, setMetricName] = useState("");
  const [aggregate, setAggregate] = useState<
    BillingMetricAggregateEnum_Enum | undefined
  >(undefined);
  const [aggregateKey, setAggregateKey] = useState<string | undefined>(
    undefined,
  );
  const [groupKeys, setGroupKeys] = useState<Set<string>[]>([]);
  const [eventFilter, setEventFilter] = useState<string>("");
  const [conditions, setConditions] = useState<(Condition & { id: string })[]>([
    {
      id: Math.random().toString(),
      field: ["event_type"],
      enum: {
        not: false,
        values: [],
      },
      required: true,
    },
  ]);
  const [BMCategory, setBMCategory] = useState<"basic" | "sql">("basic");
  const [showEventsTable, setShowEventsTable] = useState<boolean>(true);
  const [showQueryResults, setShowQueryResults] = useState<boolean>(true);
  const [queryResult, setQueryResult] = useState<
    SimulatedUsageTableRow[] | undefined
  >(undefined);
  const [queryResultValueColumnName, setQueryResultValueColumnName] = useState<
    string | undefined
  >(undefined);
  const [loadingQueryResult, setLoadingQueryResult] = useState<boolean>(false);
  const [queryValidation, setQueryValidation] = useState<string | undefined>(
    undefined,
  );
  const [code, setCode] = useState<string>(
    "select count(event_type) from events where event_type = 'call api v1'",
  );
  const [eventTableState, setEventTableState] = useState<
    Record<string, boolean>
  >({});
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [customerId, setCustomerId] = useState<string | undefined>();
  const [showCustomerFilter, setShowCustomerFilter] = useState<boolean>(true);

  const [createMetric, { loading: insertLoading }] =
    useInsertBillableMetricMutation();

  const [createFancyMetric, { loading: insertFancyLoading }] =
    useInsertFancyMetricMutation();

  const [createSeatMetric, { loading: insertSeatLoading }] =
    useInsertSeatMetricMutation();

  const pushMessage = useSnackbar();

  const { data: eventData, loading: eventsLoading } = useEventsQuery({
    variables: {
      limit: NUM_EVENT_ROWS,
      environment_type: environmentType,
      starting_after: startingAfter,
      ending_before: ending_before,
    },
  });

  const [validateFancyMetric] = useValidateFancyBillableMetricLazyQuery();
  const [simulateFancyMetricUsage] = useSimulateFancyMetricUsageLazyQuery({
    fetchPolicy: "network-only", // don't cache this since we might want live data
  });

  /**
   * Given a query for the user's events table, we want to render a list of
   * event_types: [{ property_name: example_value }]. This memo will generate
   * a map or maps to track these values, as well as handling the search filter
   * case which removes any string that doesnt match the filter
   */
  const eventsProperties = useMemo(() => {
    const propertiesMap: Map<string, Map<string, string>> = new Map();
    if (!eventsLoading) {
      const events = eventData?.mri_events || [];
      events.forEach((event) => {
        let eventTypeMatches = event.event_type.includes(eventFilter);
        const eventPropertiesMap = new Map<string, string>();

        for (const [key, value] of Object.entries(event.properties)) {
          if (
            eventTypeMatches ||
            key.includes(eventFilter) ||
            value.includes(eventFilter)
          ) {
            eventPropertiesMap.set(key, value);
          }
        }

        if (eventTypeMatches || eventPropertiesMap.size > 0) {
          propertiesMap.set(event.event_type, eventPropertiesMap);
          setEventTableState((prev) => ({
            ...prev,
            [event.event_type as string]: eventFilter !== "" ? true : false,
          }));
        }
      });
    }

    const result: { [key: string]: [string, string][] } = {};
    propertiesMap.forEach((properties, eventType) => {
      result[eventType] = Array.from(properties.entries());
    });

    return result;
  }, [eventData, eventsLoading, eventFilter]);

  const propertiesValidation = (propertyName: string | undefined) => {
    if (!propertyName) {
      return true;
    }
    return !(
      conditions.filter((condition) => propertyName === condition.field[1])
        .length > 1
    );
  };

  const propertyFields: string[] = Array.from(
    conditions
      .reduce((agg: Set<string>, condition) => {
        /* Only properties that exist count towards our set */
        if (condition.field[1] && condition.required === true) {
          agg.add(condition.field[1]);
        }
        return agg;
      }, new Set<string>())
      .values(),
  );

  const updateGroupAndAggregateKeys = (condition: Condition) => {
    /* Blank strings are valid properties but we shouldn't update our keys based on them */
    if (condition.field[1] && propertiesValidation(condition.field[1])) {
      if (condition.field[1] === aggregateKey) {
        setAggregateKey(undefined);
      }
      setGroupKeys(
        // remove condition property from all existing group keys
        groupKeys
          .map((groupKey) => {
            if (groupKey.has(condition.field[1])) {
              const updatedGroupKey = new Set<string>(groupKey);
              updatedGroupKey.delete(condition.field[1]);
              return updatedGroupKey;
            }
            return groupKey;
          })
          .filter((groupKeys) => groupKeys.size > 0),
      );
    }
  };

  // Check if the group key at argument groupKeysIndex is a duplicate of one
  // earlier in the list
  const groupKeyError = (groupKeysIndex: number) =>
    !!groupKeys
      .slice(0, groupKeysIndex)
      .find(
        (otherGroupKey) =>
          otherGroupKey.size === groupKeys[groupKeysIndex].size &&
          [...groupKeys[groupKeysIndex]].reduce(
            (agg, curGroupKeyItem) => agg && otherGroupKey.has(curGroupKeyItem),
            true,
          ),
      );

  const aggregateSelectDisabled =
    !aggregate ||
    !propertyFields.length ||
    aggregate === BillingMetricAggregateEnum_Enum.Count;

  const allGroupKeysValid = !groupKeys
    .map((_, index) => groupKeyError(index))
    .reduce((agg, groupKeyError) => agg || groupKeyError, false);

  const isMetricInvalid =
    (BMCategory === "basic" &&
      !(
        metricName &&
        (aggregate === BillingMetricAggregateEnum_Enum.Count ||
          (aggregate && aggregateKey)) &&
        conditions &&
        allGroupKeysValid
      )) ||
    (BMCategory === "sql" && !metricName);

  const saveMetric = async () => {
    /* We toss away empty property names, otherwise we technically have duplicate fields */
    const filteredConditions = conditions.filter((c) => c.field[1] !== "");
    try {
      if (BMCategory === "sql") {
        const metric = await createFancyMetric({
          variables: {
            object: {
              name: metricName,
              sql: code,
            },
          },
          update(cache) {
            cache.evict({
              fieldName: "BillableMetric",
            });
            cache.evict({
              fieldName: "billable_metrics",
            });
          },
        });
        if (metric.data?.create_fancy_metric) {
          pushMessage({
            content: `Successfully created metric: ${metric.data.create_fancy_metric.name}`,
            type: "success",
          });
          navigate(`/billable-metrics/${metric.data.create_fancy_metric.id}`);
        }
        return;
      }
      const jsonSchema = conditionsToJsonSchema(filteredConditions);
      if (aggregate === BillingMetricAggregateEnum_Enum.Latest) {
        const metric = await createSeatMetric({
          variables: {
            input: {
              name: metricName,
              filter: jsonSchema,
              is_draft: false,
              aggregate_key: aggregateKey as string,
            },
          },
          update(cache) {
            cache.evict({
              fieldName: "SeatMetric",
            });
            cache.evict({
              fieldName: "seat_metrics",
            });
          },
        });

        if (metric.data?.create_seat_metric) {
          pushMessage({
            content: `Successfully created metric: ${metric.data.create_seat_metric.name}`,
            type: "success",
          });
          navigate(
            `/billable-metrics/seats/${metric.data.create_seat_metric.id}`,
          );
        }
      } else {
        const nonCompositeGroupKeys = groupKeys
          .filter((groupKey) => groupKey.size === 1)
          .flatMap((groupKeyItems) => [...groupKeyItems]);
        const compositeGroupKeys = groupKeys
          .filter((groupKey) => groupKey.size > 1)
          .map((groupKeyItems) => [...groupKeyItems]);
        const metric = await createMetric({
          variables: {
            object: {
              aggregate: aggregate as BillingMetricAggregateEnum_Enum,
              aggregate_key:
                aggregate === BillingMetricAggregateEnum_Enum.Count
                  ? undefined
                  : aggregateKey,
              filter: jsonSchema,
              group_keys:
                nonCompositeGroupKeys.length > 0
                  ? nonCompositeGroupKeys
                  : undefined,
              composite_group_keys:
                compositeGroupKeys.length > 0 ? compositeGroupKeys : undefined,
              name: metricName,
              is_draft: false,
            },
          },
          update(cache) {
            cache.evict({
              fieldName: "BillableMetric",
            });
            cache.evict({
              fieldName: "billable_metrics",
            });
          },
        });
        if (metric.data?.create_billable_metric) {
          pushMessage({
            content: `Successfully created metric: ${metric.data.create_billable_metric.name}`,
            type: "success",
          });
          navigate(
            `/billable-metrics/${metric.data.create_billable_metric.id}`,
          );
        }
      }
    } catch (error: any) {
      pushMessage({
        content: `Failed to create billable metric: ${error.message}`,
        type: "error",
      });
    }
  };

  const getUsageTableColumns = (): DataColumn<SimulatedUsageTableRow>[] => {
    const valueColumn: DataColumn<SimulatedUsageTableRow> = {
      id: "__value_column",
      isDisplay: false,
      header: queryResultValueColumnName,
      accessorFn: (row) => row.usageData.value,
      cell: (c) => c.getValue(),
    };

    if (queryResult === undefined || queryResult.length === 0) {
      return [valueColumn];
    }

    const groupKeyColumns: DataColumn<SimulatedUsageTableRow>[] = queryResult[0]
      .usageData.groups
      ? Object.keys(queryResult[0].usageData.groups).map((groupKey) => ({
          id: groupKey,
          isDisplay: false,
          header: groupKey,
          accessorFn: (row) => row.usageData.groups?.[groupKey] ?? "<empty>",
          cell: (c) => c.getValue(),
        }))
      : [];
    return [valueColumn, ...groupKeyColumns];
  };

  useEffect(() => {
    if (draftMetricData?.BillableMetric) {
      setMetricName(`${draftMetricData.BillableMetric.name} (copy)`);
      if (draftMetricData.BillableMetric.sql) {
        setBMCategory("sql");
        setCode(draftMetricData.BillableMetric.sql);
      } else {
        setConditions(
          [
            ...jsonSchemaToConditions(
              draftMetricData.BillableMetric.filter,
            ).map((c) => {
              return { ...c, id: Math.random().toString() };
            }),
            /* Remove property conditional */
          ].filter((c) => c.field.length === 2 || c.field[0] !== "properties"),
        );
        const deserializedGroupKeys = draftMetricData.BillableMetric
          .group_keys as (string | string[])[] | null;
        setGroupKeys(
          deserializedGroupKeys?.map((groupKey) =>
            typeof groupKey === "string"
              ? new Set<string>([groupKey])
              : new Set<string>(groupKey),
          ) ?? [],
        );
        if (draftMetricData.BillableMetric.aggregate === "unique") {
          setAggregate(draftMetricData.BillableMetric.aggregate);
        } else {
          setAggregate(draftMetricData.BillableMetric.aggregate);
        }
        setAggregateKey(
          (draftMetricData.BillableMetric.aggregate_keys as string[])?.[0],
        );
      }
    }
  }, [draftMetricData]);

  useEffect(() => {
    if (draftSeatMetricData?.seat_metric) {
      setMetricName(`${draftSeatMetricData?.seat_metric.name} (copy)`);
      setConditions(
        [
          ...jsonSchemaToConditions(
            draftSeatMetricData?.seat_metric.filter,
          ).map((c) => {
            return { ...c, id: Math.random().toString() };
          }),
          /* Remove property conditional */
        ].filter((c) => c.field.length === 2 || c.field[0] !== "properties"),
      );
      setAggregate(BillingMetricAggregateEnum_Enum.Latest);
    }
  }, [draftSeatMetricData]);

  /* Group keys and the Unique aggregate are incompatible */
  useEffect(() => {
    if (aggregate === BillingMetricAggregateEnum_Enum.Unique) {
      setGroupKeys([]);
    }
  }, [aggregate === BillingMetricAggregateEnum_Enum.Unique]);

  const handleSQLRun = useCallback(
    async (code: string) => {
      if (!customerId) {
        return;
      }
      setShowQueryResults(true);
      setLoadingQueryResult(true);
      setQueryValidation(undefined);
      setQueryResult(undefined);
      setQueryResultValueColumnName(undefined);
      const { data } = await validateFancyMetric({
        variables: {
          sql: code,
        },
      });
      if (
        !data?.validate_fancy_metric.success &&
        data?.validate_fancy_metric.errors?.length &&
        data.validate_fancy_metric.errors.length > 0
      ) {
        setQueryValidation(data.validate_fancy_metric.errors.join(", "));
        setLoadingQueryResult(false);
        return;
      }

      const { data: simulateData, error } = await simulateFancyMetricUsage({
        variables: {
          input: {
            sql: code,
            customer_id: customerId,
            start_date: dayjs
              .utc()
              .subtract(7, "day")
              .startOf("day")
              .toISOString(),
          },
        },
      });
      if (error) {
        setQueryValidation(error?.message);
      } else {
        setQueryResult(
          simulateData?.simulate_fancy_metric_usage?.usage?.map((usage) => ({
            usageData: usage,
            id: usage.groups ? JSON.stringify(usage.groups) : "<empty>",
          })),
        );
        setQueryResultValueColumnName(
          simulateData?.simulate_fancy_metric_usage?.value_column_name,
        );
      }
      setLoadingQueryResult(false);
    },
    [customerId],
  );

  const renderGroupKeys = useMemo(() => {
    // todo: put in a tooltip help icon
    let bodyCopy =
      "Specify properties this metric should be grouped by. Grouping a metric by various properties allow you to customize how events are grouped on an invoice. It also gives you additional cardinality to group/filter usage through this metric.";
    if (gigaRateCardEnabled)
      bodyCopy = bodyCopy.concat(
        " Group individually or combine properties for compound groups.",
      );

    return (
      <>
        {aggregate !== BillingMetricAggregateEnum_Enum.Latest && (
          <div>
            <div className="mb-8 flex items-center text-xs font-medium leading-1 text-grey-600">
              Add group keys <span className="font-normal">(optional)</span>
              <HelpCircleTooltip content={bodyCopy} />
            </div>
            <div>
              {aggregate === BillingMetricAggregateEnum_Enum.Unique ? (
                <div className="flex justify-center px-0 py-[28px]">
                  <Label className="text-grey-200">
                    The "Unique" aggregate and grouping are incompatible.
                  </Label>
                </div>
              ) : gigaRateCardEnabled ? (
                <>
                  {groupKeys.map((curGroupKey, groupKeysIndex) => (
                    <div
                      className="mb-[10px]"
                      key={`group-key-${groupKeysIndex}`}
                    >
                      <div className="flex items-end justify-between">
                        <Select
                          name="Group key"
                          disabled={
                            aggregate &&
                            [
                              BillingMetricAggregateEnum_Enum.Unique,
                              BillingMetricAggregateEnum_Enum.Latest,
                            ].includes(aggregate)
                          }
                          multiSelect
                          className="min-w-[400px] flex-1"
                          value={[...curGroupKey]}
                          placeholder="Enter one or more of your property filters"
                          error={groupKeyError(groupKeysIndex)}
                          onChange={(values) => {
                            const newGroupKeys = [
                              ...groupKeys.slice(0, groupKeysIndex),
                              new Set(values),
                              ...groupKeys.slice(
                                groupKeysIndex + 1,
                                groupKeys.length,
                              ),
                            ];
                            setGroupKeys(newGroupKeys);
                            for (const property of values) {
                              if (property === aggregateKey) {
                                setAggregateKey(undefined);
                              }
                            }
                          }}
                          options={[...propertyFields].map((v) => ({
                            label: v,
                            value: v,
                          }))}
                          __internalComponentOverrides={{
                            DropdownIndicator: () => null,
                          }}
                        />
                        <div className="flex justify-end">
                          <IconButton
                            onClick={() =>
                              setGroupKeys([
                                ...groupKeys.slice(0, groupKeysIndex),
                                ...groupKeys.slice(
                                  groupKeysIndex + 1,
                                  groupKeys.length,
                                ),
                              ])
                            }
                            theme="tertiary"
                            icon="xClose"
                            className="mb-[-4px]"
                          />
                        </div>
                      </div>

                      {curGroupKey.size > 1 && (
                        <Body level={2} className="text-grey-600">
                          This is a compound group key:{" "}
                          {JSON.stringify([...curGroupKey])}
                        </Body>
                      )}
                    </div>
                  ))}
                  <Button
                    text="Add group key"
                    leadingIcon="plus"
                    theme="secondary"
                    disabled={
                      propertyFields.length === 0 ||
                      (aggregate &&
                        [
                          BillingMetricAggregateEnum_Enum.Unique,
                          BillingMetricAggregateEnum_Enum.Latest,
                        ].includes(aggregate))
                    }
                    onClick={() =>
                      setGroupKeys([...groupKeys, new Set<string>()])
                    }
                  />
                </>
              ) : (
                propertyFields.map((property) => {
                  const selected = !!groupKeys.find(
                    (newGroupKey) =>
                      newGroupKey.size === 1 && newGroupKey.has(property),
                  );
                  return (
                    <button
                      key={property}
                      className={classnames(
                        "mx-0 my-4 flex w-full cursor-pointer flex-row justify-between rounded-large border border-grey-100 bg-white px-12 py-8 font-default font-normal",
                        ...(selected
                          ? [
                              "border-primary-100 bg-primary-50 text-primary-600",
                            ]
                          : []),
                      )}
                      onClick={() => {
                        if (selected) {
                          setGroupKeys(
                            groupKeys.filter(
                              (groupKey) =>
                                !(
                                  groupKey.size === 1 && groupKey.has(property)
                                ),
                            ),
                          );
                        } else {
                          const newGroupKey = new Set([property]);
                          setGroupKeys([...groupKeys, newGroupKey]);
                          if (property === aggregateKey) {
                            setAggregateKey(undefined);
                          }
                        }
                      }}
                    >
                      {property}
                      <Icon
                        icon="checkmarkCircle"
                        className={classnames(
                          "h-[16px] w-[16px]",
                          ...(selected ? [] : ["text-grey-200"]),
                        )}
                      />
                    </button>
                  );
                })
              )}
            </div>
          </div>
        )}
      </>
    );
  }, [aggregate, gigaRateCardEnabled, groupKeys, propertyFields, aggregateKey]);

  // customer search
  const debouncedSearchQuery = useDebounce(searchQuery ?? "a", 300);
  const searchCustomersResponse = useSearchCustomersQuery({
    variables: {
      environment_type: environmentType,
      query: debouncedSearchQuery,
      archived: ArchivedFilter.NotArchived,
    },
  });

  const customerRows = searchCustomersResponse.data?.searchCustomers ?? [];
  const showEmptyQueryResultState =
    !queryResult && !queryValidation && !loadingQueryResult && showQueryResults;

  /* If we don't wait for the launchdarkly flags the page jumps  */
  if (loading || seatLoading) {
    return (
      <PageContainer title="Loading ...">
        <div>
          <TextSkeleton />
          <TextSkeleton />
          <TextSkeleton />
          <TextSkeleton />
        </div>
      </PageContainer>
    );
  }
  return (
    <PageContainer
      disableContainerScroll
      action={
        <Button
          text="View Documentation"
          leadingIcon="linkExternal01"
          size="sm"
          isExternalLink={true}
          linkTo="https://docs.metronome.com/invoicing/how-billing-works/set-up-billable-metrics/"
          theme="linkGray"
        />
      }
      title="Design your new billable metric"
    >
      <div className="flex grow flex-row gap-[50px] overflow-auto pr-12 pt-24">
        <div className="flex max-w-[1700px] grow flex-col gap-[12px]">
          <div className="max-w-[350px]">
            <Input
              className="mb-16 [&_label>div]:text-gray-600"
              placeholder="Enter billable metric name"
              value={metricName ?? ""}
              onChange={(v) => setMetricName(v)}
              name="Name"
            />
            {flexAggEnabled && (
              <>
                <Subtitle className="mb-4 text-grey-600" level={4}>
                  Define metric with
                </Subtitle>
                <ButtonGroup
                  buttons={[
                    {
                      text: "Basic filters",
                      onClick: () => setBMCategory("basic"),
                      isActive: BMCategory === "basic",
                    },
                    {
                      text: "SQL query",
                      onClick: () => setBMCategory("sql"),
                      isActive: BMCategory === "sql",
                    },
                  ]}
                />
              </>
            )}
          </div>
          {BMCategory === "sql" ? (
            <div className="flex h-full max-h-[550px] rounded-medium border border-gray-100">
              <div
                className={twMerge(
                  "overflow-hidden border-r border-gray-100 p-16 font-mono",
                  showEventsTable ? "w-[285px]" : "w-[40px] px-0",
                )}
              >
                <div>
                  <div className="flex items-center justify-between">
                    <Caption
                      className={twMerge(
                        "text-grey-800",
                        !showEventsTable && "hidden",
                      )}
                      level={2}
                    >
                      Events Table
                    </Caption>
                    <IconButton
                      icon={
                        showEventsTable
                          ? "chevronLeftDouble"
                          : "chevronRightDouble"
                      }
                      size="sm"
                      theme="tertiary"
                      onClick={() => setShowEventsTable((prev) => !prev)}
                    />
                  </div>
                  <div
                    className={twMerge(
                      "relative",
                      !showEventsTable && "hidden",
                    )}
                  >
                    <Input
                      placeholder="Search"
                      leftIcon="search"
                      className={twMerge(
                        "mb-8",
                        eventFilter !== "" && "[&_input]:pr-34",
                      )}
                      value={eventFilter}
                      onChange={(v) => setEventFilter(v)}
                      disabled={eventsLoading}
                    />
                    {eventFilter !== "" && (
                      <IconButton
                        onClick={() => setEventFilter("")}
                        className="absolute right-4 top-4"
                        theme="tertiary"
                        icon="xClose"
                        size="sm"
                      />
                    )}
                  </div>
                </div>
                {eventsLoading ? (
                  <div id="spinner" />
                ) : (
                  <div
                    className={twMerge(
                      "h-[calc(100%-65px)] w-full overflow-y-scroll",
                      !showEventsTable && "hidden",
                    )}
                  >
                    {eventFilter !== "" &&
                    Object.keys(eventsProperties).length === 0 ? (
                      <Subtitle level={4} className="leading-1 text-grey-600">
                        {eventFilter} returned 0 results
                      </Subtitle>
                    ) : (
                      <div className="">
                        <ul>
                          {Object.entries(eventsProperties).map(
                            ([type, properties]) => (
                              <Fragment key={type}>
                                <li
                                  className="mb-8 flex items-center truncate font-mono text-xs text-gray-800"
                                  title={type}
                                >
                                  {properties.length > 0 && (
                                    <>
                                      <label
                                        className="mr-8 h-12 cursor-pointer"
                                        htmlFor={type}
                                      >
                                        <Icon
                                          icon={
                                            eventTableState[type]
                                              ? "caretDown"
                                              : "caretForward"
                                          }
                                        />
                                      </label>
                                      <input
                                        type="checkbox"
                                        id={type}
                                        className="hidden"
                                        onChange={(e) =>
                                          setEventTableState((prev) => ({
                                            ...prev,
                                            [type]: e.target.checked,
                                          }))
                                        }
                                      />
                                    </>
                                  )}
                                  {type}
                                </li>
                                {Array.isArray(properties) &&
                                  properties.length > 0 && (
                                    <div className="mb-4">
                                      <div
                                        className={twMerge(
                                          "w-full grid-cols-[auto_auto] gap-8",
                                          eventTableState[type]
                                            ? "ml-[5px] inline-grid border-l-[1px] border-grey-200 pl-20"
                                            : "hidden",
                                        )}
                                      >
                                        {properties.map(
                                          ([propName, propValue], idx) => (
                                            <Fragment key={idx}>
                                              <div
                                                title={propName}
                                                className="truncate text-xs text-gray-800"
                                              >
                                                {propName}
                                              </div>
                                              <div
                                                title={propValue}
                                                className="truncate text-[10px] text-gray-600"
                                              >
                                                {propValue}
                                              </div>
                                            </Fragment>
                                          ),
                                        )}
                                      </div>
                                    </div>
                                  )}
                              </Fragment>
                            ),
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="flex flex-1 flex-col justify-between">
                <CodeBlock
                  code={code}
                  onChange={setCode}
                  onRun={handleSQLRun}
                  runButtonDisabled={!customerId}
                  height={showQueryResults ? 142 : 480}
                  className="[&_section>div]:rounded-[0] [&_section>div]:border-0"
                  canAdjustWidth={true}
                />
                <div
                  className={twMerge(
                    "w-full border-t-[1px] border-gray-100 px-16 py-[14px]",
                    showQueryResults ? "h-[calc(100%-142px)]" : "h-[54px]",
                  )}
                >
                  <div className="mb-12 flex flex-row-reverse justify-between">
                    <Button
                      text={showQueryResults ? "Collapse" : "Expand"}
                      trailingIcon={
                        showQueryResults ? "minimize01" : "maximize01"
                      }
                      theme="linkGray"
                      onClick={() => setShowQueryResults((prev) => !prev)}
                    />
                    <div
                      className={twMerge(
                        "flex items-center text-sm text-gray-600",
                        !showQueryResults && "hidden",
                      )}
                    >
                      Filter by{" "}
                      {customerId && (
                        <Button
                          onClick={() => setShowCustomerFilter(true)}
                          className={twMerge(
                            "mx-4 underline",
                            showCustomerFilter && "hidden",
                          )}
                          text={
                            customerRows.find((c) => c.id === customerId)
                              ?.name ?? ""
                          }
                          theme="linkGray"
                        />
                      )}
                      {showCustomerFilter && (
                        <Select
                          className="mx-4 w-[200px]"
                          clearable
                          onBlur={() => {
                            if (customerRows.length === 0) setSearchQuery("");
                            if (!!customerId) setShowCustomerFilter(false);
                          }}
                          placeholder="Search customer"
                          noOptionsMessage="No results"
                          options={customerRows.map((c) => ({
                            label: c.name,
                            value: c.id,
                          }))}
                          value={customerId ?? ""}
                          onSearch={(q) => {
                            if (q.length > 0) setSearchQuery(q);
                          }}
                          onChange={(c) => setCustomerId(c)}
                        />
                      )}
                      in the
                      <span className="ml-4 font-semibold leading-2">
                        {" "}
                        last 7 days
                      </span>
                    </div>
                  </div>
                  {showEmptyQueryResultState && (
                    <div className="mt-8 flex flex-col items-center text-gray-700">
                      <EmptyResult />
                      <div>Run query to generate results</div>
                    </div>
                  )}
                  {loadingQueryResult && (
                    <div className="mt-8 flex flex-col items-center text-gray-700">
                      <LoadingSpinner />
                    </div>
                  )}
                  {queryValidation && (
                    <div className={!showQueryResults ? "invisible" : ""}>
                      <div className="mb-12 text-sm font-semibold text-gray-900">
                        Query Validation
                      </div>
                      <div className="flex items-center font-mono text-xs">
                        <Icon
                          icon="alertCircle"
                          className="mr-[6px] h-[16px] w-[16px] text-error-600"
                        />
                        {queryValidation}
                      </div>
                    </div>
                  )}
                  {queryResult && (
                    <div className={!showQueryResults ? "hidden" : ""}>
                      <Table
                        data={queryResult}
                        columns={getUsageTableColumns()}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <>
              {conditions.map((condition, index) => {
                return (
                  <div key={condition.id} data-testid={`condition-${index}`}>
                    <ConditionInput
                      className={twMerge(
                        "mb-0 border-none bg-white p-0",
                        condition.field[0] === "event_type" && "pr-[46px]",
                      )}
                      label={
                        condition.field[0] === "event_type"
                          ? "Select event type"
                          : "Property filter"
                      }
                      hideLabels={true}
                      condition={condition}
                      validation={propertiesValidation}
                      flexAggsLayout={true}
                      onChange={(newCondition) => {
                        updateGroupAndAggregateKeys(conditions[index]);
                        setConditions([
                          ...conditions.slice(0, index),
                          { ...conditions[index], ...newCondition },
                          ...conditions.slice(index + 1),
                        ]);
                      }}
                      onDelete={(condition: Condition) => {
                        /* Try to delete group keys and aggregate keys based on properties changing */
                        updateGroupAndAggregateKeys(conditions[index]);
                        setConditions([
                          ...conditions.slice(0, index),
                          ...conditions.slice(index + 1),
                        ]);
                      }}
                      maxEnums={MAX_ENUMS}
                    />
                  </div>
                );
              })}
              <div className="flex items-center">
                <Tooltip
                  content={`Only ${MAX_PROP_FILTERS} property filters can be created at one time`}
                  disabled={conditions.length <= MAX_PROP_FILTERS}
                >
                  <Button
                    text="Add property filter"
                    leadingIcon="plus"
                    theme="secondary"
                    disabled={conditions.length > MAX_PROP_FILTERS}
                    onClick={() =>
                      setConditions([
                        ...conditions,
                        {
                          id: Math.random().toString(),
                          field: ["properties", ""],
                          enum: undefined,
                          required: undefined,
                        },
                      ])
                    }
                  />
                </Tooltip>
              </div>
              <Subtitle level={4} className="leading-1 text-grey-600">
                Aggregate by
              </Subtitle>
              <ButtonGroup
                buttons={[
                  {
                    text: "Count",
                    isActive:
                      aggregate === BillingMetricAggregateEnum_Enum.Count,
                    onClick: () =>
                      setAggregate(BillingMetricAggregateEnum_Enum.Count),
                  },
                  {
                    text: "Sum",
                    isActive: aggregate === BillingMetricAggregateEnum_Enum.Sum,
                    onClick: () =>
                      setAggregate(BillingMetricAggregateEnum_Enum.Sum),
                  },
                  {
                    text: "Max",
                    isActive: aggregate === BillingMetricAggregateEnum_Enum.Max,
                    onClick: () =>
                      setAggregate(BillingMetricAggregateEnum_Enum.Max),
                  },
                  {
                    text: "Unique",
                    isActive:
                      aggregate === BillingMetricAggregateEnum_Enum.Unique,
                    onClick: () =>
                      setAggregate(BillingMetricAggregateEnum_Enum.Unique),
                  },
                  ...(!!enableSeats
                    ? [
                        {
                          text: "Seat",
                          isActive:
                            aggregate ===
                            BillingMetricAggregateEnum_Enum.Latest,
                          onClick: () =>
                            setAggregate(
                              BillingMetricAggregateEnum_Enum.Latest,
                            ),
                        },
                      ]
                    : []),
                ]}
              />

              {!aggregateSelectDisabled && (
                <Select
                  value={aggregateKey ?? ""}
                  options={propertyFields.map((v) => ({
                    value: v,
                    label: v,
                  }))}
                  onChange={(v) => {
                    setAggregateKey(v);
                    /* An aggregate can not also be a group key */
                    /* TODO(GET-1870): Come up with a better UI/UX that explains the problem */
                    setGroupKeys(
                      groupKeys.filter((groupKey) => !groupKey.has(v)),
                    );
                  }}
                  name="Property"
                  disabled={aggregateSelectDisabled}
                  placeholder="Select"
                />
              )}
              {renderGroupKeys}
            </>
          )}
        </div>
        {BMCategory === "basic" && (
          <div className="w-[425px]">
            <Headline className="text-grey-900" level={6}>
              Test your metric with your own events
            </Headline>
            <Body className="mb-[18px]" level={1}>
              Paste an event payload below to determine if it matches this
              billable metric
            </Body>
            <JsonSchemaValidator
              conditions={conditions}
              showCreateExampleButton={true}
            />
          </div>
        )}
      </div>
      <div className="-mx-12 flex flex-row items-center justify-end gap-8 bg-white px-24 py-12 shadow-inner">
        <Button
          onClick={() => navigate("/billable-metrics")}
          text="Cancel"
          theme="linkGray"
        />
        <Button
          onClick={saveMetric}
          disabled={
            isMetricInvalid ||
            insertLoading ||
            insertSeatLoading ||
            insertFancyLoading
          }
          loading={insertLoading || insertSeatLoading}
          text="Save"
          theme="primary"
        />
      </div>
    </PageContainer>
  );
};
