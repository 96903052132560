import React from "react";
import { Button } from "tenaissance/components/Button";
import { useProvisionSalesforceCensusWorkspaceMutation } from "./queries.graphql";
import { GetSalesforceWorkspaceDocument } from "../IntegrationsTab/queries.graphql";
import { CensusDestinationTypeEnum } from "types/generated-graphql/__types__";
import { Icon } from "tenaissance/components/Icon";
import { ApolloError } from "@apollo/client";

interface CompleteSetupProps {
  isWorkspaceCreated: boolean;
  isSalesforceDestinationLinked: boolean;
  syncOnlyLinkedAccounts: boolean | undefined;
  onSuccess: () => void;
}

export const CompleteSetup: React.FC<CompleteSetupProps> = ({
  isWorkspaceCreated,
  isSalesforceDestinationLinked,
  syncOnlyLinkedAccounts,
  onSuccess,
}) => {
  const [provisionSalesforceCensusWorkspaceMutation, { loading, error }] =
    useProvisionSalesforceCensusWorkspaceMutation();
  const completeSetupDisabled =
    !isWorkspaceCreated ||
    !isSalesforceDestinationLinked ||
    syncOnlyLinkedAccounts === undefined;

  const completeSetup = async () => {
    if (
      !loading &&
      isWorkspaceCreated &&
      isSalesforceDestinationLinked &&
      syncOnlyLinkedAccounts !== undefined
    ) {
      const response = await provisionSalesforceCensusWorkspaceMutation({
        variables: { only_linked_accounts: syncOnlyLinkedAccounts },
        // Refetch the workspace to move the salesforce integration
        // into the Active integrations list without a page refresh
        refetchQueries: [
          {
            query: GetSalesforceWorkspaceDocument,
            variables: {
              destinationType: CensusDestinationTypeEnum.Salesforce,
            },
          },
        ],
      });
      if (response.data?.provision_salesforce_census_workspace.success) {
        onSuccess();
      }
    }
  };

  const formatErrorMsg = (e: ApolloError) => {
    if (e.message.startsWith("Salesforce sync creation failed for entity")) {
      return "The Metronome Salesforce package was not installed or the authenticated user does not have permissions to all required objects. Please contact your Salesforce administrator.";
    }
    return "There was an error provisioning this workspace. Please try again. If the problem persists reach out to your Metronome representative.";
  };

  return (
    <div className="flex flex-col">
      {error && (
        <div className="p-xl rounded-xl mb-3xl flex border-2 border-error-200 bg-error-50 text-error-600">
          <Icon icon="alertCircle" className="mr-lg" />
          <span className="text-sm">{formatErrorMsg(error)}</span>
        </div>
      )}
      {loading && !error && (
        <div className="mb-lg text-sm text-gray-600">
          We are setting up your workspace. This may take a minute.
        </div>
      )}
      <Button
        text="Complete setup"
        disabled={completeSetupDisabled}
        loading={loading}
        onClick={completeSetup}
      />
    </div>
  );
};
