/* 888efc133e0ff82d5e6e555f4ad06bc8012b957a
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinalizeBillableMetricMutationVariables = Types.Exact<{
  billable_metric_id: Types.Scalars['uuid'];
}>;


export type FinalizeBillableMetricMutation = { __typename?: 'Mutation', update_BillableMetric_by_pk: { __typename?: 'BillableMetric', id: string, is_draft: boolean } | null };

export type BackfillBillableMetricMutationVariables = Types.Exact<{
  billable_metric_id: Types.Scalars['uuid'];
}>;


export type BackfillBillableMetricMutation = { __typename?: 'Mutation', backfill_billable_metric: { __typename?: 'MutationResponse', success: boolean } };

export type BillableMetricDetailsFragment = { __typename?: 'BillableMetric', id: string, name: string, aggregate: Types.BillingMetricAggregateEnum_Enum, aggregate_keys: object | null, group_keys: object | null, filter: object, created_at: string, is_draft: boolean, deleted_at: string | null, active_plan_count: number, active_product_count: number, sql: string | null, Creator: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null };

export type SeatMetricDetailsFragment = { __typename?: 'SeatMetric', id: string, name: string, aggregate_key: string, filter: object, created_at: string, is_draft: boolean, deleted_at: string | null, active_plan_count: number, active_product_count: number, Creator: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } };

export const BillableMetricDetailsFragmentDoc = gql`
    fragment BillableMetricDetails on BillableMetric {
  id
  name
  aggregate
  aggregate_keys
  group_keys
  filter
  created_at
  is_draft
  deleted_at
  Creator {
    name
    id
    deprecated_at
  }
  active_plan_count
  active_product_count
  sql
  __environment_type: environment_type
}
    `;
export const SeatMetricDetailsFragmentDoc = gql`
    fragment SeatMetricDetails on SeatMetric {
  id
  name
  aggregate_key
  filter
  created_at
  is_draft
  deleted_at
  Creator {
    name
    id
    deprecated_at
  }
  active_plan_count
  active_product_count
}
    `;
export const FinalizeBillableMetricDocument = gql`
    mutation FinalizeBillableMetric($billable_metric_id: uuid!) {
  update_BillableMetric_by_pk(
    pk_columns: {id: $billable_metric_id}
    _set: {is_draft: false}
  ) {
    id
    is_draft
    __environment_type: environment_type
  }
}
    `;
export type FinalizeBillableMetricMutationFn = Apollo.MutationFunction<FinalizeBillableMetricMutation, FinalizeBillableMetricMutationVariables>;

/**
 * __useFinalizeBillableMetricMutation__
 *
 * To run a mutation, you first call `useFinalizeBillableMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeBillableMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeBillableMetricMutation, { data, loading, error }] = useFinalizeBillableMetricMutation({
 *   variables: {
 *      billable_metric_id: // value for 'billable_metric_id'
 *   },
 * });
 */
export function useFinalizeBillableMetricMutation(baseOptions?: Apollo.MutationHookOptions<FinalizeBillableMetricMutation, FinalizeBillableMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizeBillableMetricMutation, FinalizeBillableMetricMutationVariables>(FinalizeBillableMetricDocument, options);
      }
export type FinalizeBillableMetricMutationHookResult = ReturnType<typeof useFinalizeBillableMetricMutation>;
export type FinalizeBillableMetricMutationResult = Apollo.MutationResult<FinalizeBillableMetricMutation>;
export type FinalizeBillableMetricMutationOptions = Apollo.BaseMutationOptions<FinalizeBillableMetricMutation, FinalizeBillableMetricMutationVariables>;
export const BackfillBillableMetricDocument = gql`
    mutation BackfillBillableMetric($billable_metric_id: uuid!) {
  backfill_billable_metric(billable_metric_id: $billable_metric_id) {
    success
  }
}
    `;
export type BackfillBillableMetricMutationFn = Apollo.MutationFunction<BackfillBillableMetricMutation, BackfillBillableMetricMutationVariables>;

/**
 * __useBackfillBillableMetricMutation__
 *
 * To run a mutation, you first call `useBackfillBillableMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackfillBillableMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backfillBillableMetricMutation, { data, loading, error }] = useBackfillBillableMetricMutation({
 *   variables: {
 *      billable_metric_id: // value for 'billable_metric_id'
 *   },
 * });
 */
export function useBackfillBillableMetricMutation(baseOptions?: Apollo.MutationHookOptions<BackfillBillableMetricMutation, BackfillBillableMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BackfillBillableMetricMutation, BackfillBillableMetricMutationVariables>(BackfillBillableMetricDocument, options);
      }
export type BackfillBillableMetricMutationHookResult = ReturnType<typeof useBackfillBillableMetricMutation>;
export type BackfillBillableMetricMutationResult = Apollo.MutationResult<BackfillBillableMetricMutation>;
export type BackfillBillableMetricMutationOptions = Apollo.BaseMutationOptions<BackfillBillableMetricMutation, BackfillBillableMetricMutationVariables>;