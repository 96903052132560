import { useApolloClient } from "@apollo/client";
import {
  Body,
  Checkbox,
  Headline,
  Icon,
  Input,
  LoadingSpinner,
  Option,
  Select,
  Subtitle,
  TextArea,
  Toggle,
} from "design-system";
import { Button } from "tenaissance/components/Button";
import {
  Destination,
  ExistingDestination,
  DestinationVendor,
  PreparedDestination,
  VendorField,
  prepareDestination,
  useCreateDestination,
  useDestinationVendors,
  useGetDestinations,
  useModelConfigs,
  useProductConfigs,
  useTestConnection,
  DestinationType,
} from "@prequel/react";
import classnames from "classnames";
import { EmptyState } from "components/EmptyState";
import { AppShell, PageContainer } from "components/PageContainer";
import { CheckboxSkeleton } from "components/Skeleton";
import { PREQUEL_API_URL, useDraftInvoicesExportEnabled } from "lib/dataExport";
import { useFeatureFlag } from "lib/launchdarkly";
import { useNavigate } from "lib/useNavigate";
import NotFoundPage from "pages/404";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "../../components/Snackbar";
import styles from "./index.module.less";
import {
  GeneratePrequelAuthTokenDocument,
  GeneratePrequelAuthTokenMutation,
  GeneratePrequelAuthTokenMutationVariables,
  useGetClientQuery,
} from "./queries.graphql";
import { reportToSentry } from "lib/errors/sentry";
import { useUIMode } from "../../lib/useUIMode";

const V1_PRODUCT = "v1";
const EVENTS_PRODUCT = "events_v1";
const DRAFT_INVOICES_PRODUCT = "draft_invoices_v1";
const SERVICE_ACCOUNT_KEY_PLACEHOLDER =
  'service account key as JSON Token (i.e. {"type": "service_account","project_id": "xxx","private_key_id": "XXX", "private_key": "XXX",... } ). ';

const BIGQUERY_ALTERNATE_FIELDS = [
  {
    name: "username",
    is_required: true,
    label: "Destination Service Account Email",
    placeholder: "",
    help: "",
  },
  {
    name: "service_account_key",
    is_required: true,
    label: "Service Account Key",
    placeholder: SERVICE_ACCOUNT_KEY_PLACEHOLDER,
    help: "",
  },
];

const DATABRICKS_OPTIONS = [
  {
    label: "AWS",
    value: "aws",
  },
  {
    label: "Azure",
    value: "azure",
  },
];

const DATABRICKS_AWS_ONLY_FIELD_NAMES = ["aws_iam_role", "bucket_region"];
const DATABRICKS_AZURE_ONLY_FIELDS = [
  {
    name: "bucket_access_id",
    is_required: true,
    label: "Bucket Access Id",
    placeholder: "",
    help: "",
  },
  {
    name: "bucket_secret_key",
    is_required: true,
    label: "Bucket Secret Key",
    placeholder: "",
    help: "",
  },
];
const DATABRICKS_EMPTY_FIELDS = {
  username: "",
  aws_iam_role: "",
  database: "",
  bucket_vendor: "",
  use_ssh_tunnel: false,
  host: "",
  port: "",
  password: "",
  bucket_name: "",
  bucket_region: "",
  schema: "",
  bucket_access_id: "",
  bucket_secret_key: "",
};

const DEFAULT_PRODUCTS = [V1_PRODUCT, EVENTS_PRODUCT, DRAFT_INVOICES_PRODUCT];

const NewDataExportDestination: React.FC = () => {
  const { newUIEnabled } = useUIMode();
  const dataExportPricingAndPackagingEnabled = useFeatureFlag<
    boolean | undefined
  >("data-export-pricing-and-packaging", undefined);
  const dataExportRawEventsEnabled = useFeatureFlag<boolean | undefined>(
    "data-export-raw-events",
    undefined,
  );
  const maxPrequelDestinations = useFeatureFlag<number>(
    "max-prequel-destinations",
    1,
  );
  const dataExportDraftInvoicesEnabled = useDraftInvoicesExportEnabled();

  const navigate = useNavigate();
  const pushMessage = useSnackbar();

  const apolloClient = useApolloClient();
  const applicationOrigin = window.location.origin;

  const [showEmptyStateErrorPage, setShowEmptyStateErrorPage] =
    useState<boolean>(false);

  const {
    data: clientData,
    loading: clientDataLoading,
    error: clientDataError,
  } = useGetClientQuery();
  const client = clientData?.Client[0];
  const clientId = client?.id || "";
  const clientName = client?.name || "";

  const [alreadyConnected, setAlreadyConnected] = useState<boolean>(false);
  const [vendorOptions, setVendorOptions] = useState<Option[]>([]);
  const [vendorsList, setVendorsList] = useState<DestinationVendor[]>();
  const [selectedVendor, setSelectedVendor] = useState<DestinationVendor>();
  const destinationVendorsResponse = useDestinationVendors(PREQUEL_API_URL);
  const [connectionValid, setConnectionValid] = useState(false);
  const [availableModels, setAvailableModels] = useState<string[] | undefined>(
    undefined,
  );
  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [dataExportDestinations, setDataExportDestinations] = useState<
    ExistingDestination[] | undefined
  >();
  const [showUserSAKFields, setShowUserSAKFields] = useState<boolean>(false);
  const [selectedDatabricksVersion, setSelectedDatabricksVersion] =
    useState<Option>(DATABRICKS_OPTIONS[0]); //Azure Databricks or AWS Databricks
  const [destination, setDestination] = useState<Destination>({
    name: "",
    schema: "",
    host: "",
    port: "",
    database: "",
    username: "",
    password: "",
    vendor: selectedVendor?.vendor_name || "",
    recipient_id: "",
    bucket_name: "",
    bucket_region: "",
    bucket_access_id: "",
    bucket_secret_key: "",
    enabled_models: [],
    products: [],
    use_ssh_tunnel: false,
    ssh_tunnel_host: "",
    ssh_tunnel_port: "",
    ssh_tunnel_username: "",
    bucket_vendor: "",
    is_enabled: true,
    service_account_key: "",
    recipient_identifier: "recipient_id",
    auth_method: "credentials",
    type: DestinationType.Destination,
  });
  const [testConnectionDetails, setTestConnectionDetails] = useState<{
    message?: string;
    type?: "success" | "error";
    loading: boolean;
  }>({
    message: "",
    type: undefined,
    loading: false,
  });
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [allRequiredFieldsFilledOut, setAllRequiredFieldsFilledOut] =
    useState<boolean>(false);

  const selectedProducts = DEFAULT_PRODUCTS;

  async function fetchPrequelAuthToken(_clientId: string): Promise<string>;
  async function fetchPrequelAuthToken(
    destination?: PreparedDestination,
  ): Promise<string>;
  async function fetchPrequelAuthToken(
    arg?: string | PreparedDestination,
  ): Promise<string> {
    try {
      const argIsClientId = typeof arg === "string";
      const data = (
        await apolloClient.mutate<
          GeneratePrequelAuthTokenMutation,
          GeneratePrequelAuthTokenMutationVariables
        >({
          mutation: GeneratePrequelAuthTokenDocument,
          variables: {
            applicationOrigin: applicationOrigin,
            vendor: argIsClientId ? undefined : arg?.vendor,
            host: argIsClientId ? undefined : arg?.host,
            bucketName: argIsClientId ? undefined : arg?.bucket_name,
            products: selectedProducts,
          },
        })
      ).data;
      if (!data) {
        throw new Error(
          "Failed to generate scoped auth token: no data returned",
        );
      }
      if (!data.generate_prequel_auth_token.token_string) {
        throw new Error(
          "Failed to generate scoped auth token: token_string was not returned",
        );
      }
      return data.generate_prequel_auth_token.token_string;
    } catch (e) {
      setShowEmptyStateErrorPage(true);
      throw new Error(`Failed to generate scoped auth token: ${e}`);
    }
  }

  const dataExportDestinationResponse = useGetDestinations(
    fetchPrequelAuthToken,
    applicationOrigin,
    clientId,
    PREQUEL_API_URL,
  );
  const testConnection = useTestConnection(
    fetchPrequelAuthToken,
    applicationOrigin,
    PREQUEL_API_URL,
  );
  const createDestination = useCreateDestination(
    fetchPrequelAuthToken,
    applicationOrigin,
    PREQUEL_API_URL,
  );
  const { model_configs: modelConfigs } = useModelConfigs(
    fetchPrequelAuthToken,
    applicationOrigin,
    PREQUEL_API_URL,
  );
  const { product_configs: productConfigs } = useProductConfigs(
    fetchPrequelAuthToken,
    applicationOrigin,
    PREQUEL_API_URL,
  );

  // get a list of distinct models enabled for the products
  const productModelsMap =
    productConfigs?.reduce((acc: { [name: string]: string[] }, product) => {
      acc[product.product_name] = product.models;
      return acc;
    }, {}) ?? {};

  const v1Models = dataExportPricingAndPackagingEnabled
    ? modelConfigs
        ?.filter((model) =>
          productModelsMap[V1_PRODUCT]?.includes(model.model_name),
        )
        .map((model) => model.model_name)
    : undefined;

  const eventsModels = dataExportRawEventsEnabled
    ? modelConfigs
        ?.filter((model) =>
          productModelsMap[EVENTS_PRODUCT]?.includes(model.model_name),
        )
        .map((model) => model.model_name)
    : undefined;

  const draftInvoicesModels = dataExportDraftInvoicesEnabled
    ? modelConfigs
        ?.filter((model) =>
          productModelsMap[DRAFT_INVOICES_PRODUCT]?.includes(model.model_name),
        )
        .map((model) => model.model_name)
    : undefined;

  const selectedVendorHasField = (field: string) =>
    selectedVendor?.fields.find((f: VendorField) => f.name === field);
  const getSelectedVendorField = (field: string) =>
    selectedVendor?.fields.find((f: VendorField) => f.name === field);
  const getDestinationField = (key: keyof Destination) => destination[key];

  const setDestinationField = (
    key: keyof Destination,
    value: string | string[] | boolean | number,
  ) => {
    setDestination((oldDestination: Destination) => ({
      ...oldDestination,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (dataExportDestinationResponse) {
      const filteredDataExportDestinationResponse =
        dataExportDestinationResponse?.filter(
          (destination) => destination.id_in_provider_system === clientId,
        );
      setDataExportDestinations(filteredDataExportDestinationResponse);
    }
  }, [dataExportDestinationResponse]);

  // N.B. In most cases we are allowing only 1 vendor destination for clients to export data to.
  // see max-prequel-destinations on LaunchDarkly or follow up at #data-export for more details.
  useEffect(() => {
    if (dataExportDestinations && dataExportDestinations.length > 0) {
      if (
        (!maxPrequelDestinations && dataExportDestinations.length > 0) ||
        (maxPrequelDestinations &&
          dataExportDestinations.length >= maxPrequelDestinations)
      ) {
        setAlreadyConnected(true);
      } else if (dataExportDestinations.length === 0) {
        setAlreadyConnected(false);
      }
    }
  }, [dataExportDestinations, maxPrequelDestinations]);

  useEffect(() => {
    if (clientDataError || !(client || clientDataLoading)) {
      setShowEmptyStateErrorPage(true);
    }
    if (clientId) {
      setDestinationField("id_in_provider_system", clientId);
    }
    if (clientName && selectedVendor?.display_name) {
      setDestinationField(
        "name",
        `${clientName} ${selectedVendor.display_name}`,
      );
    }
  }, [clientDataError, client, selectedVendor]);

  useEffect(() => {
    if (destinationVendorsResponse?.destinations && dataExportDestinations) {
      let destinationList = destinationVendorsResponse?.destinations;
      if (dataExportDestinations.length > 0) {
        const connectedVendorList = dataExportDestinations?.map(
          (d) => d.vendor,
        );
        destinationList = destinationList.filter(
          (d) => !connectedVendorList.includes(d.vendor_name),
        );
      }
      setVendorsList(destinationList);
      setVendorOptions(
        destinationList.map((dest) => ({
          label: dest.display_name,
          value: dest.vendor_name,
        })),
      );
    }
  }, [destinationVendorsResponse?.destinations, dataExportDestinations]);

  useEffect(() => {
    if (vendorsList) {
      setSelectedVendor(vendorsList[0]);
    }
  }, [vendorsList]);

  useEffect(() => {
    if (availableModels) {
      setSelectedModels(availableModels);
    }
  }, [availableModels]);

  useEffect(() => {
    if (selectedVendor) {
      setDestinationField("vendor", selectedVendor.vendor_name);
    }
  }, [selectedVendor]);

  useEffect(() => {
    // Update available models if selectedProducts changes
    if (productConfigs && modelConfigs) {
      let modelNames: string[] = [];
      productConfigs.forEach(({ product_name, models }) => {
        // If the product is selected, append all models
        if (selectedProducts.includes(product_name)) {
          modelNames = [...modelNames, ...models];
        }
      });

      // Deduplicate the models by validating against the model configs list
      const models = modelConfigs.filter(({ model_name }) =>
        modelNames.includes(model_name),
      );
      setAvailableModels(models.map(({ model_name }) => model_name));
    }
  }, [selectedProducts, productConfigs, modelConfigs]);

  const requiredFields: VendorField[] = useMemo(() => {
    if (!selectedVendor) return [];
    return selectedVendor.fields
      .filter((field) => field.is_required)
      .filter(
        (field) =>
          !(
            selectedVendor.vendor_name === "databricks" &&
            selectedDatabricksVersion.value === "azure" &&
            DATABRICKS_AWS_ONLY_FIELD_NAMES.includes(field.name)
          ),
      )
      .filter(
        (field) =>
          !(
            selectedVendor.vendor_name === "bigquery" &&
            showUserSAKFields &&
            field.name === "gcp_iam_role"
          ),
      )
      .concat(
        selectedVendor.vendor_name === "databricks" &&
          selectedDatabricksVersion.value === "azure"
          ? DATABRICKS_AZURE_ONLY_FIELDS
          : [],
      )
      .concat(
        selectedVendor.vendor_name === "bigquery" && showUserSAKFields
          ? BIGQUERY_ALTERNATE_FIELDS
          : [],
      )
      .concat(
        selectedVendor.supports_ssh_tunnel &&
          getDestinationField("use_ssh_tunnel")
          ? selectedVendor.fields.filter(
              (field) =>
                field.name === "ssh_tunnel_host" ||
                field.name === "ssh_tunnel_username" ||
                field.name === "ssh_tunnel_port",
            )
          : [],
      );
  }, [
    selectedVendor,
    destination,
    showUserSAKFields,
    selectedDatabricksVersion,
  ]);

  useEffect(() => {
    const requiredFieldsNames = requiredFields.map((f) => f.name);
    if (
      requiredFieldsNames.includes("aws_iam_role") ||
      requiredFieldsNames.includes("gcp_iam_role")
    ) {
      setDestinationField("auth_method", "iam_role");
    } else {
      setDestinationField("auth_method", "credentials");
    }
  }, [requiredFields]);

  useEffect(() => {
    // Determine which destination fields are required and set value of allRequiredFieldsFilledOut
    if (selectedVendor) {
      for (const requiredField of requiredFields) {
        if (!getDestinationField(requiredField.name as keyof Destination)) {
          setAllRequiredFieldsFilledOut(false);
          return;
        }
      }
      setAllRequiredFieldsFilledOut(true);
    }
  }, [
    selectedVendor,
    destination,
    showUserSAKFields,
    selectedDatabricksVersion,
  ]);

  const showField = useCallback(
    (fieldName: string) => {
      switch (selectedVendor?.vendor_name) {
        case "bigquery":
          const bqFields = BIGQUERY_ALTERNATE_FIELDS.map((f) => f.name);

          if (showUserSAKFields) {
            if (bqFields.includes(fieldName) && fieldName !== "gcp_iam_role") {
              return true;
            } else if (fieldName === "gcp_iam_role") {
              return false;
            }
          }

          return selectedVendorHasField(fieldName);
          break;
        case "databricks":
          const dbFields = DATABRICKS_AZURE_ONLY_FIELDS.map((f) => f.name);
          if (selectedDatabricksVersion.value === "azure") {
            if (
              dbFields.includes(fieldName) &&
              !DATABRICKS_AWS_ONLY_FIELD_NAMES.includes(fieldName)
            ) {
              return true;
            } else if (DATABRICKS_AWS_ONLY_FIELD_NAMES.includes(fieldName)) {
              return false;
            }
          }

          return selectedVendorHasField(fieldName);
          break;
        default:
          return selectedVendorHasField(fieldName);
          break;
      }
    },
    [selectedVendor?.vendor_name, showUserSAKFields, selectedDatabricksVersion],
  );

  useEffect(() => {
    // Reset ssh tunnel destination fields if use_ssh_tunnel is false
    if (getDestinationField("use_ssh_tunnel") === false) {
      setDestinationField("ssh_tunnel_host", "");
      setDestinationField("ssh_tunnel_username", "");
      setDestinationField("ssh_tunnel_port", "");
    }
  }, [getDestinationField("use_ssh_tunnel")]);

  const updateSelectedModels = (isSelected: boolean, modelName: string) => {
    let newModels: string[] = [];
    if (isSelected) {
      newModels = [...selectedModels, modelName];
    } else {
      newModels = selectedModels.filter((m) => m !== modelName);
    }

    setSelectedModels(newModels);
  };

  const fetchTestConnection = async () => {
    const prepared: PreparedDestination = prepareDestination(destination);
    setTestConnectionDetails({
      loading: true,
    });
    const response = await testConnection(prepared);
    if (response.status === "success" && response.data?.status === "success") {
      setTestConnectionDetails({
        message: "Connection successful",
        type: "success",
        loading: false,
      });
      setConnectionValid(true);
    } else {
      setTestConnectionDetails({
        message: `Connection failed: ${response.message}`,
        type: "error",
        loading: false,
      });
      setConnectionValid(false);

      reportToSentry(
        new Error(
          "Error in testing connection: " + response.message || "Unknown",
        ),
      );
    }
  };

  const saveDestination = async () => {
    setSubmitLoading(true);
    const prepared: PreparedDestination = prepareDestination(destination);
    if (connectionValid) {
      prepared.enabled_models = selectedModels;
      const response = await createDestination(prepared);
      if (response.status === "success") {
        pushMessage({
          content: "Successfully set up data export integration",
          type: "success",
        });
        navigate(
          newUIEnabled
            ? "/connections/data-export"
            : "/settings/general/data-export",
        );
      } else {
        pushMessage({
          content: `Error setting up data export integration, please try again`,
          type: "error",
        });

        reportToSentry(
          new Error(
            "Error in creating destination: " + response.message || "Unknown",
          ),
        );
      }
    }
  };

  const renderControl = useCallback(() => {
    if (!selectedVendor) return null;
    switch (selectedVendor.vendor_name) {
      case "bigquery":
        return (
          <Toggle
            label="Auth with Username/Service Account Key"
            checked={showUserSAKFields}
            onChange={() => setShowUserSAKFields(!showUserSAKFields)}
          />
        );
        break;
      case "databricks":
        return (
          <div className="mb-8 max-w-[250px]">
            <Select
              name="Select Cloud Provider"
              placeholder=""
              options={DATABRICKS_OPTIONS}
              value={selectedDatabricksVersion.value}
              onChange={(version: string) => {
                setSelectedDatabricksVersion(
                  DATABRICKS_OPTIONS.find((v) => v.value === version) ||
                    DATABRICKS_OPTIONS[0],
                );
              }}
            />
          </div>
        );
        break;
      default:
        return null;
        break;
    }
  }, [
    selectedVendor?.vendor_name,
    showUserSAKFields,
    selectedDatabricksVersion,
  ]);

  useEffect(() => {
    // clear Databricks fields when you switch cloud providers
    setDestination({ ...destination, ...DATABRICKS_EMPTY_FIELDS });
  }, [selectedDatabricksVersion]);

  if (
    dataExportPricingAndPackagingEnabled === false &&
    dataExportRawEventsEnabled === false &&
    dataExportDraftInvoicesEnabled === false
  ) {
    return <NotFoundPage />;
  }
  const content = (
    <>
      {showEmptyStateErrorPage ? (
        <EmptyState
          title="We ran into an issue loading this page"
          subtitle="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
          icon="database02"
        />
      ) : null}
      {clientDataLoading || dataExportDestinations === undefined ? (
        <div id={styles.spinner}></div>
      ) : null}
      {alreadyConnected ? (
        <EmptyState
          title="Your account is already connected"
          subtitle="This account has already established a connection for data export."
          icon="database02"
        />
      ) : null}
      {client && !alreadyConnected ? (
        <div className={styles.container}>
          <Headline level={6} className={styles.step}>
            Step 1: Select a destination type
          </Headline>
          <Body level={1} className={styles.maxWidth}>
            Metronome supports data export to the most common data warehouses,
            databases, and object storage providers. Data can be sent to a
            single destination and is pulled across all environments.
          </Body>
          <Select
            className={styles.vendorDropdown}
            name="Destination type"
            placeholder=""
            options={vendorOptions}
            value={selectedVendor?.vendor_name ?? ""}
            onChange={(vendor: string) => {
              const found = vendorsList?.find((v) => v.vendor_name === vendor);
              setSelectedVendor(found);
            }}
          />
          <Headline level={6} className={styles.step}>
            Step 2: Map Metronome to your destination
          </Headline>
          <Body level={1}>Enter your destination credentials.</Body>
          {client && selectedVendor && (
            <div className={styles.destinationForm}>
              {renderControl()}
              {selectedVendorHasField("host") && (
                <Input
                  name={getSelectedVendorField("host")?.label}
                  placeholder={`Enter ${getSelectedVendorField(
                    "host",
                  )?.label.toLowerCase()}`}
                  type="text"
                  value={destination.host}
                  onChange={(v) => setDestinationField("host", v)}
                  className={styles.formInput}
                />
              )}
              {selectedVendorHasField("port") && (
                <Input
                  name={getSelectedVendorField("port")?.label}
                  placeholder={`Enter ${getSelectedVendorField(
                    "port",
                  )?.label.toLowerCase()}`}
                  type="text"
                  value={destination.port}
                  onChange={(v) => setDestinationField("port", v)}
                  className={styles.formInput}
                />
              )}
              {selectedVendorHasField("database") && (
                <Input
                  name={getSelectedVendorField("database")?.label}
                  placeholder={`Enter ${getSelectedVendorField(
                    "database",
                  )?.label.toLowerCase()} name`}
                  type="text"
                  value={destination.database}
                  onChange={(v) => setDestinationField("database", v)}
                  className={styles.formInput}
                />
              )}
              {selectedVendorHasField("schema") && (
                <Input
                  name={getSelectedVendorField("schema")?.label}
                  placeholder={`Enter ${getSelectedVendorField(
                    "schema",
                  )?.label.toLowerCase()} to create (if needed) and write data to`}
                  type="text"
                  value={destination.schema}
                  onChange={(v) => setDestinationField("schema", v)}
                  className={styles.formInput}
                />
              )}
              {showField("username") && (
                <Input
                  name={
                    getSelectedVendorField("username")?.label ||
                    (selectedVendor.vendor_name === "bigquery" &&
                      "Destination Service Account Email") ||
                    "Username"
                  }
                  placeholder={`Enter ${
                    getSelectedVendorField("username")?.label ||
                    (selectedVendor.vendor_name === "bigquery" &&
                      "Destination Service Account Email") ||
                    "Username"
                  }`}
                  type="text"
                  value={destination.username}
                  onChange={(v) => setDestinationField("username", v)}
                  className={styles.formInput}
                />
              )}
              {selectedVendorHasField("password") && (
                <Input
                  name={getSelectedVendorField("password")?.label}
                  placeholder={`Enter ${getSelectedVendorField(
                    "password",
                  )?.label.toLowerCase()}`}
                  type="password"
                  value={destination.password}
                  onChange={(v) => setDestinationField("password", v)}
                  className={styles.formInput}
                />
              )}
              {showField("bucket_region") && (
                <Input
                  name={
                    getSelectedVendorField("bucket_region")?.label ||
                    "Bucket Region"
                  }
                  placeholder={`Enter ${
                    getSelectedVendorField(
                      "bucket_region",
                    )?.label.toLowerCase() || "bucket region"
                  }`}
                  type="text"
                  value={destination.bucket_region}
                  onChange={(v) => setDestinationField("bucket_region", v)}
                  className={styles.formInput}
                />
              )}
              {showField("bucket_access_id") && (
                <Input
                  name={
                    getSelectedVendorField("bucket_access_id")?.label ||
                    "Bucket Access ID"
                  }
                  placeholder={`Enter ${
                    getSelectedVendorField(
                      "bucket_access_id",
                    )?.label.toLowerCase() || "bucket access id"
                  }`}
                  type="text"
                  value={destination.bucket_access_id}
                  onChange={(v) => setDestinationField("bucket_access_id", v)}
                  className={styles.formInput}
                />
              )}
              {selectedVendorHasField("bucket_name") && (
                <Input
                  name={getSelectedVendorField("bucket_name")?.label}
                  placeholder={`Enter ${getSelectedVendorField(
                    "bucket_name",
                  )?.label.toLowerCase()}`}
                  type="text"
                  value={destination.bucket_name}
                  onChange={(v) => setDestinationField("bucket_name", v)}
                  className={styles.formInput}
                />
              )}
              {showField("bucket_secret_key") && (
                <Input
                  name={
                    getSelectedVendorField("bucket_secret_key")?.label ||
                    "Bucket Secret Key"
                  }
                  placeholder={`Enter ${
                    getSelectedVendorField(
                      "bucket_secret_key",
                    )?.label.toLowerCase() || "bucket secret key"
                  }`}
                  type="password"
                  value={destination.bucket_secret_key}
                  onChange={(v) => setDestinationField("bucket_secret_key", v)}
                  className={styles.formInput}
                />
              )}
              {showField("aws_iam_role") && (
                <Input
                  name={
                    getSelectedVendorField("aws_iam_role")?.label ||
                    "AWS IAM Role ARN"
                  }
                  placeholder={`Enter ${
                    getSelectedVendorField(
                      "aws_iam_role",
                    )?.label.toLowerCase() || "aws iam role arn"
                  }`}
                  type="text"
                  value={destination.aws_iam_role}
                  onChange={(v) => setDestinationField("aws_iam_role", v)}
                  className={styles.formInput}
                />
              )}
              {showField("gcp_iam_role") && (
                <Input
                  name={getSelectedVendorField("gcp_iam_role")?.label}
                  placeholder={`Enter ${getSelectedVendorField(
                    "gcp_iam_role",
                  )?.label.toLowerCase()}`}
                  type="text"
                  value={destination.gcp_iam_role}
                  onChange={(v) => setDestinationField("gcp_iam_role", v)}
                  className={styles.formInput}
                />
              )}
              {selectedVendorHasField("cluster") && (
                <Input
                  name={getSelectedVendorField("cluster")?.label}
                  placeholder={`Enter ${getSelectedVendorField(
                    "cluster",
                  )?.label.toLowerCase()}`}
                  type="text"
                  value={destination.cluster}
                  onChange={(v) => setDestinationField("cluster", v)}
                  className={styles.formInput}
                />
              )}
              {showField("service_account_key") && (
                <TextArea
                  name={
                    getSelectedVendorField("service_account_key")?.label ||
                    "Service Account Key (JSON Token)"
                  }
                  placeholder={`Enter ${
                    getSelectedVendorField(
                      "service_account_key",
                    )?.label.toLowerCase() || SERVICE_ACCOUNT_KEY_PLACEHOLDER
                  }`}
                  value={destination.service_account_key}
                  onChange={(v) =>
                    setDestinationField("service_account_key", v)
                  }
                  className={styles.formInput}
                />
              )}
              {selectedVendorHasField("bucket_vendor") && (
                <Input
                  name={getSelectedVendorField("bucket_vendor")?.label}
                  placeholder={`Enter ${getSelectedVendorField(
                    "bucket_vendor",
                  )?.label.toLowerCase()}`}
                  type="text"
                  value={destination.bucket_vendor}
                  onChange={(v) => setDestinationField("bucket_vendor", v)}
                  className={styles.formInput}
                />
              )}
              {selectedVendor.supports_ssh_tunnel &&
                selectedVendorHasField("use_ssh_tunnel") && (
                  <Checkbox
                    label="Use SSH Tunnel"
                    checked={destination.use_ssh_tunnel}
                    onChange={() =>
                      setDestinationField(
                        "use_ssh_tunnel",
                        !destination.use_ssh_tunnel,
                      )
                    }
                    className={styles.formInput}
                  />
                )}
              {destination.use_ssh_tunnel &&
                selectedVendorHasField("ssh_tunnel_host") && (
                  <Input
                    name="SSH Tunnel Host"
                    placeholder="Enter SSH tunnel host"
                    type="text"
                    value={destination.ssh_tunnel_host}
                    onChange={(v) => setDestinationField("ssh_tunnel_host", v)}
                    className={styles.formInput}
                  />
                )}
              {destination.use_ssh_tunnel &&
                selectedVendorHasField("ssh_tunnel_username") && (
                  <Input
                    name="SSH Tunnel Username"
                    placeholder="Enter SSH tunnel username"
                    type="text"
                    value={destination.ssh_tunnel_username}
                    onChange={(v) =>
                      setDestinationField("ssh_tunnel_username", v)
                    }
                    className={styles.formInput}
                  />
                )}
              {destination.use_ssh_tunnel &&
                selectedVendorHasField("ssh_tunnel_port") && (
                  <Input
                    name="SSH Tunnel Port"
                    placeholder="Enter SSH tunnel port"
                    type="text"
                    value={destination.ssh_tunnel_port}
                    onChange={(v) => setDestinationField("ssh_tunnel_port", v)}
                    className={styles.formInput}
                  />
                )}
              <Headline level={6} className={styles.step}>
                Step 3: Select Metronome data to sync
              </Headline>
              <Body level={1}>
                Select the Metronome data to sync to your destination.
              </Body>
              {availableModels &&
              (v1Models || eventsModels || draftInvoicesModels) ? (
                <div className={styles.models}>
                  {v1Models ? (
                    <div>
                      <Subtitle level={3}>Billing data</Subtitle>
                      {v1Models.map((modelName) => (
                        <Checkbox
                          key={modelName}
                          label={modelName}
                          checked={selectedModels.includes(modelName)}
                          onChange={() =>
                            updateSelectedModels(
                              !selectedModels.includes(modelName),
                              modelName,
                            )
                          }
                        />
                      ))}
                    </div>
                  ) : null}
                  {eventsModels ? (
                    <div>
                      <Subtitle level={3}>Usage data</Subtitle>

                      {eventsModels.map((modelName) => (
                        <Checkbox
                          key={modelName}
                          label={modelName}
                          checked={selectedModels.includes(modelName)}
                          onChange={() =>
                            updateSelectedModels(
                              !selectedModels.includes(modelName),
                              modelName,
                            )
                          }
                        />
                      ))}
                    </div>
                  ) : null}
                  {draftInvoicesModels ? (
                    <div>
                      <Subtitle level={3}>Draft Invoice data</Subtitle>

                      {draftInvoicesModels.map((modelName) => (
                        <Checkbox
                          key={modelName}
                          label={modelName}
                          checked={selectedModels.includes(modelName)}
                          onChange={() =>
                            updateSelectedModels(
                              !selectedModels.includes(modelName),
                              modelName,
                            )
                          }
                        />
                      ))}
                    </div>
                  ) : null}
                </div>
              ) : availableModels === undefined ||
                !v1Models ||
                !eventsModels ||
                !draftInvoicesModels ? (
                <>
                  <CheckboxSkeleton />
                  <CheckboxSkeleton />
                  <CheckboxSkeleton />
                </>
              ) : (
                ""
              )}
              <Headline level={6} className={styles.step}>
                Step 4: Test connection to your destination
              </Headline>
              <Body level={1}>
                Testing the connection will create the specified schema if it
                doesn't already exist. Running this test is required.
              </Body>
              <div className={styles.testConnection}>
                <Button
                  onClick={fetchTestConnection}
                  loading={testConnectionDetails.loading}
                  disabled={
                    testConnectionDetails.loading || !allRequiredFieldsFilledOut
                  }
                  text="Test Connection"
                  theme="primary"
                />
                {testConnectionDetails.message && (
                  <div
                    className={classnames(styles.testConnectionDetails, {
                      [styles.success]:
                        testConnectionDetails.type === "success",
                      [styles.error]: testConnectionDetails.type !== "success",
                    })}
                  >
                    <Icon
                      icon={
                        testConnectionDetails.type === "success"
                          ? "checkmarkCircle"
                          : "closeCircle"
                      }
                      className={styles.icon}
                    />
                    <Subtitle level={4}>
                      {testConnectionDetails.message}
                    </Subtitle>
                  </div>
                )}
                {testConnectionDetails.loading && (
                  <LoadingSpinner className="ml-12" />
                )}
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
  return newUIEnabled ? (
    <AppShell
      title="New Data Export Destination"
      headerProps={{
        actions: [
          <Button
            onClick={() => navigate("/connections/data-export")}
            className={styles.cancel}
            text="Cancel"
            theme="linkGray"
          />,
          <Button
            onClick={saveDestination}
            disabled={!connectionValid || submitLoading}
            loading={submitLoading}
            text="Save"
            theme="primary"
          />,
        ],
      }}
    >
      {content}
    </AppShell>
  ) : (
    <PageContainer
      title="Data export integration"
      action={
        <div>
          <Button
            onClick={() => navigate("/settings/general/data-export")}
            className={styles.cancel}
            text="Cancel"
            theme="linkGray"
          />
          <Button
            onClick={saveDestination}
            disabled={!connectionValid || submitLoading}
            loading={submitLoading}
            text="Save"
            theme="primary"
          />
        </div>
      }
    >
      {content}
    </PageContainer>
  );
};

export default NewDataExportDestination;
