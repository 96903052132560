import { createContext, useContext } from "react";
import { Schema } from "../Schema";
import { FormController } from "lib/FormController";

interface RateCardContextType {
  ctrl: RateCardRootFormController;
}

export const RateCardContext = createContext<RateCardContextType | null>(null);

export const useRateCardContext = (): RateCardContextType => {
  const ctx = useContext(RateCardContext);
  if (ctx === null) {
    throw new Error(
      "Cannot useRateCardContext in a context that doesn't have a rate card",
    );
  }
  return ctx;
};

export type RateCardRootFormController = ReturnType<
  typeof useRateCardCreateV2Controller
>;

export const useRateCardCreateV2Controller = FormController.createHook(
  Schema.RateCardInput,
  {
    init(snapshotKey: string) {
      return FormController.parseJsonSnapshot(
        Schema.RateCardInput,
        sessionStorage.getItem(snapshotKey),
      );
    },
  },
);
