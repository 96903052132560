import React from "react";
import { useRequiredParam } from "lib/routes/params";

import { CustomerTab } from "pages/Customer/components/CustomerTab";
import { BillingProviderSection } from "./sections/BillingProvider";
import { IdentifierSection } from "./sections/Identifiers";

const CustomerSettings: React.FC = () => {
  const customerId = useRequiredParam("customerId");

  return (
    <CustomerTab>
      <IdentifierSection customerId={customerId} />
      <BillingProviderSection customerId={customerId} />
    </CustomerTab>
  );
};

export default CustomerSettings;
