import React, { useState } from "react";

import { Avatar, Tooltip } from "design-system";
import { useCurrentUser } from "lib/auth";
import { useFeatureFlag } from "lib/launchdarkly";
import { renderDateTime } from "lib/time";
import { UserAuthTypeEnum_Enum } from "types/generated-graphql/__types__";
import {
  CreateUserDocument,
  useGetAllUsersQuery,
} from "pages/Team/queries.graphql";
import { GatedButton } from "components/GatedButton";
import { CreateUserModal } from "pages/Team/components/CreateUserModal";
import { Table } from "tenaissance/components/Table";
import { Timestamp } from "../../../../components/Timestamp";
import { ArchiveUserButton } from "../../../../../pages/Team/components/ArchiveUserButton";

export const Team: React.FC<{}> = () => {
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
  const { data, loading, refetch } = useGetAllUsersQuery();
  const { user: currentUser } = useCurrentUser();
  const ssoEnabled = useFeatureFlag("sso-entitlement", false);

  const addButton = (
    <Tooltip
      content="Clients with SSO enabled can not invite new users through Metronome"
      disabled={!ssoEnabled}
    >
      <GatedButton
        doc={CreateUserDocument}
        onClick={() => setCreateUserModalOpen(true)}
        disabled={ssoEnabled ?? true}
        text="Add"
        theme="primary"
        leadingIcon="plus"
        size="sm"
      />
    </Tooltip>
  );

  return (
    <>
      {createUserModalOpen && (
        <CreateUserModal
          onClose={() => setCreateUserModalOpen(false)}
          refetchUsers={refetch}
          existingUserEmailDomains={
            new Set(
              (data?.all_users ?? []).map(
                (user) => user.email.split("@").slice(-1)[0],
              ),
            )
          }
        />
      )}
      <Table
        loading={loading}
        data={data?.all_users || []}
        paginationOptions={{ type: "clientSide" }}
        defaultSort={[
          {
            id: "name",
            desc: true,
          },
        ]}
        title="Team"
        topBarActions={[addButton]}
        columns={[
          {
            id: "name",
            header: "Name",
            cell: (props) => (
              <div className="gap-md flex items-center">
                <Avatar
                  userName={props.getValue().name}
                  size={32}
                  userID={props.getValue().id}
                />
                <div className="flex flex-col">
                  <span className="text-core-slate text-sm font-medium">
                    {props.getValue().name}
                  </span>
                  <span className="text-xs">{props.getValue().email}</span>
                </div>
              </div>
            ),
            accessorFn: (r) => r,
          },
          {
            header: "Role",
            id: "role",
            cell: (props) => props.getValue(),
            accessorKey: "role",
          },
          {
            header: "Created",
            id: "createdAt",
            cell: (props) => (
              <Timestamp dateTime={new Date(props.getValue())} />
            ),
            accessorKey: "created_at",
          },
          {
            header: "",
            id: "actions",
            cell: (props) => {
              const u = props.getValue();
              return u.deprecated_at === null ? (
                <ArchiveUserButton
                  user={u}
                  disabled={
                    u.id === currentUser?.id ||
                    u.auth_type === UserAuthTypeEnum_Enum.Saml
                  }
                />
              ) : (
                `Deactivated on ${renderDateTime(
                  new Date(u.deprecated_at),
                  false,
                )}`
              );
            },
            enableSorting: false,
            accessorFn: (r) => r,
          },
        ]}
      />
    </>
  );
};
