import React from "react";
import { Schema } from "../Schema";
import { TextInput } from "tenaissance/components/Input";

interface Props {
  disabled?: boolean;
  rate: Schema.Types.Rate;
  onChange: (rate: Schema.Types.Rate) => void;
}

export const RatePercentage: React.FC<Props> = ({
  rate,
  disabled,
  onChange,
}) => {
  const currentPrice =
    rate.price.type === "percentage" ? rate.price.fraction : 0;
  return (
    <TextInput
      disabled={disabled}
      value={currentPrice.toString()}
      fullWidth
      disableClear
      type="text"
      variantOptions={{
        icon: "sale02",
        variant: "icon",
      }}
      onChange={({ value }) => {
        onChange({
          ...rate,
          price: {
            type: "percentage",
            fraction: Number(value),
            useListPrices: false,
          },
        });
      }}
    />
  );
};
