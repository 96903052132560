import React from "react";

import { useNavigate } from "lib/useNavigate";

import { PageContainer } from "components/PageContainer";
import { EmptyState } from "components/EmptyState";

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <PageContainer title="404" hideHeader>
      <EmptyState
        title="404 — Page not found."
        subtitle="Oops, we couldn’t find the page you’re looking for."
        buttonText="View customers"
        onClick={() => navigate("/")}
        icon="faceFrown"
      />
    </PageContainer>
  );
};

export default NotFoundPage;
