import React from "react";
import { CreditType } from "types/credit-types";
import { RateFlat } from "./RateFlat";
import { Schema } from "../Schema";
import { RateTiered } from "./RateTiered";
import { RatePercentage } from "./RatePercentage";
import { RateSubscription } from "./RateSubscription";

interface Props {
  disabled?: boolean;
  rate: Schema.Types.Rate;
  fiatCreditType: CreditType;
  customCreditTypes: CreditType[];
  creditTypeConversions: Schema.Types.CreditTypeConversion[];
  onChange: (rate: Schema.Types.Rate) => void;
  onConversionRateChange: (
    conversion: Schema.Types.CreditTypeConversion,
  ) => void;
}

export function RateInput({
  fiatCreditType,
  customCreditTypes,
  creditTypeConversions,
  rate,
  onChange,
  onConversionRateChange,
  disabled,
}: Props) {
  const type = rate.price.type;
  return type === "tiered" ? (
    <RateTiered
      rate={rate}
      fiatCreditType={fiatCreditType}
      customCreditTypes={customCreditTypes}
      onChange={onChange}
      onConversionRateChange={onConversionRateChange}
      creditTypeConversions={creditTypeConversions}
      isCommitRate={false}
      disabled={disabled}
    />
  ) : type === "percentage" ? (
    <RatePercentage rate={rate} onChange={onChange} disabled={disabled} />
  ) : type === "subscription" ? (
    <RateSubscription
      rate={rate}
      fiatCreditType={fiatCreditType}
      customCreditTypes={customCreditTypes}
      onChange={onChange}
      onConversionRateChange={onConversionRateChange}
      creditTypeConversions={creditTypeConversions}
      disabled={disabled}
    />
  ) : (
    <RateFlat
      rate={rate}
      isCommitRate={false}
      fiatCreditType={fiatCreditType}
      customCreditTypes={customCreditTypes}
      onChange={onChange}
      onConversionRateChange={onConversionRateChange}
      creditTypeConversions={creditTypeConversions}
      disabled={disabled}
    />
  );
}
