import React, { useState } from "react";

import styles from "./index.module.less";
import { IntegrationCard } from "pages/GeneralSettings/components/IntegrationCard";
import { renderDateTime } from "lib/time";
import { Badge, Subtitle, Body } from "design-system";
import { CopyableID } from "components/CopyableID";
import { NetSuiteConnectionModal } from "../../components/NetSuiteConnectionModal";
import { DeleteNetSuiteConnectionModal } from "../../components/DeleteNetSuiteConnectionModal";
import { UpsertNetSuiteCustomerIdDocument } from "../../components/NetSuiteConnectionModal/queries.graphql";

type NetSuiteConnectionProps = {
  customerId: string;
  customerName?: string;
  connectionDate?: Date;
  netSuiteCustomerId?: string;
  loading?: boolean;
};

export const NetSuiteConnection: React.FC<NetSuiteConnectionProps> = ({
  customerId,
  customerName,
  connectionDate,
  netSuiteCustomerId,
  loading,
}) => {
  const [editmodalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  let content: JSX.Element;

  if (loading) {
    content = (
      <IntegrationCard
        type="NETSUITE"
        titleNode={
          <Subtitle className={styles.title} level={2}>
            NetSuite
          </Subtitle>
        }
        info={
          <Body level={2} className={styles.notConfigured}>
            Loading...
          </Body>
        }
        buttonText="Loading"
        buttonTheme="secondary"
        buttonDisabled
      />
    );
  } else if (!netSuiteCustomerId) {
    content = (
      <IntegrationCard
        type="NETSUITE"
        titleNode={
          <Subtitle className={styles.title} level={2}>
            NetSuite
          </Subtitle>
        }
        info={
          <Body level={2} className={styles.notConfigured}>
            {customerName || "This customer"} doesn't have NetSuite configured
          </Body>
        }
        buttonText="Connect"
        buttonTheme="secondary"
        buttonAuthDoc={UpsertNetSuiteCustomerIdDocument}
        buttonOnClick={() => setEditModalOpen(true)}
      />
    );
  } else {
    content = (
      <IntegrationCard
        type="NETSUITE"
        titleNode={
          <>
            <Subtitle className={styles.title} level={2}>
              NetSuite
            </Subtitle>
            <Badge theme="success" type="dark" className={styles.activeBadge}>
              ACTIVE
            </Badge>
          </>
        }
        info={
          <div className={styles.providerInfo}>
            <CopyableID id={netSuiteCustomerId} label="NetSuite customer ID" />
          </div>
        }
        connectionDetails={renderDateTime(
          connectionDate || new Date(),
          true,
          "Connected on",
        )}
        buttonText="Edit"
        buttonAuthDoc={UpsertNetSuiteCustomerIdDocument}
        buttonOnClick={() => setEditModalOpen(true)}
      />
    );
  }

  return (
    <>
      {editmodalOpen && (
        <NetSuiteConnectionModal
          onClose={(deleteConfig?: boolean) => {
            setEditModalOpen(false);
            setDeleteModalOpen(deleteConfig ?? false);
          }}
          isOpen={editmodalOpen}
          initialNetSuiteCustomerId={netSuiteCustomerId}
          customerId={customerId}
        />
      )}
      {deleteModalOpen && (
        <DeleteNetSuiteConnectionModal
          onClose={() => setDeleteModalOpen(false)}
          isOpen={deleteModalOpen}
          customerId={customerId}
        />
      )}
      {content}
    </>
  );
};
