import React from "react";
import Decimal from "decimal.js";
import { Badge } from "design-system";

import { InPanelTable } from "components/TablePanel";
import { EmptyState } from "components/EmptyState";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { InternalLink } from "components/Typography";
import { toDayjs, printDate, useNow } from "lib/date";

import { ColWidths } from "../../../lib/ColWidths";
import type { TabProps } from "./CommitCard";
import { RoundedCurrency } from "lib/credits";
import {
  PrepaidCommitLedgerEntryFragment,
  PostpaidCommitLedgerEntryFragment,
  ContractCommitLedgersDocument,
  CustomerCommitLedgersDocument,
  ContractCommitLedgersQuery,
  CustomerCommitLedgersQuery,
} from "./data.graphql";
import { Invoice } from "pages/Contracts/lib/Invoice";
import { Commit } from "pages/Contracts/lib/Commit";
import { Contract } from "pages/Contracts/lib/Contract";
import { useQuery } from "@apollo/client";

type LedgerEntry =
  | PrepaidCommitLedgerEntryFragment
  | PostpaidCommitLedgerEntryFragment;

const LinkInCell = (props: {
  routePath: string;
  children: React.ReactNode;
}) => (
  <InternalLink
    routePath={props.routePath}
    className="!text-primary-500 hover:!underline"
  >
    {props.children}
  </InternalLink>
);

const InvoiceLink = (
  invoice: Invoice.RoutePathFragment & Invoice.DateFragment,
) => (
  <LinkInCell routePath={Invoice.getRoutePath(invoice)}>
    Invoice ({Invoice.renderDate(invoice, useNow())})
  </LinkInCell>
);

export const TabLedger: React.FC<TabProps> = ({ commit }) => {
  const resp = useQuery<
    ContractCommitLedgersQuery | CustomerCommitLedgersQuery
  >(
    commit.contract
      ? ContractCommitLedgersDocument
      : CustomerCommitLedgersDocument,
    {
      variables: {
        customerId: commit.customer.id ?? commit?.contract?.customer.id,
        contractId: commit.contract?.id,
      },
    },
  );

  const now = useNow();

  if (resp.error) {
    return (
      <ErrorEmptyState
        title="Unable to load commit ledger"
        error={resp.error}
      />
    );
  }

  const loading = !resp.called || resp.loading;
  const commits = !resp.data?.customer
    ? []
    : "commits" in resp.data.customer
      ? resp.data.customer.commits
      : [
          ...(resp.data.customer.contract?.commits_union ?? []),
          ...(resp.data.customer.contract?.amendments.flatMap(
            (a) => a.commits_union,
          ) || []),
        ];
  const ledger: LedgerEntry[] | undefined = commits.find(
    (c) => c.id === commit.id,
  )?.ledger;

  let balance = new Decimal(0);
  const tableData = (ledger ?? [])
    // add a balance to each ledger entry that is the sum of all previous entries
    .map((entry) => {
      balance = balance.add(entry.amount);
      return { ...entry, balance: Decimal.max(balance, 0) };
    });

  if (!loading && !ledger) {
    return <EmptyState title="Commit ledger not found" subtitle="" />;
  }

  return (
    <InPanelTable
      loading={loading}
      data={tableData}
      emptyState={
        <EmptyState
          title="No ledger entries"
          subtitle="This commit does not have any ledger entries yet"
        />
      }
      theadClassName="bg-gray-lightest"
      columns={[
        {
          id: "amount",
          header: "Amount",
          align: "left",
          cellClassName: ColWidths.CREDITS,
          render: (row) => {
            let amt = new Decimal(row.amount);

            if (amt.eq(0)) {
              return "--";
            }

            const amsAmt = amt.abs();
            const rounded = (
              <RoundedCurrency
                amount={amsAmt}
                creditType={commit.access_schedule.credit_type}
              />
            );
            return amt.gt(0) ? (
              <span className="text-green-medium">{rounded}</span>
            ) : (
              <span className="text-red-medium">({rounded})</span>
            );
          },
        },
        {
          id: "remaining",
          header: "Total remaining",
          align: "left",
          cellClassName: ColWidths.CREDITS,
          render: (row) => (
            <RoundedCurrency
              amount={row.balance}
              creditType={commit.access_schedule.credit_type}
            />
          ),
        },
        {
          id: "reason",
          header: "Reason",
          align: "right",
          render: (row): string | React.ReactElement => {
            switch (row.__typename) {
              case "PostpaidCommitAutomatedInvoiceDeductionLedgerEntry":
              case "PrepaidCommitAutomatedInvoiceDeductionLedgerEntry":
                return (
                  <>
                    Automated deduction for <InvoiceLink {...row.invoice} />
                  </>
                );
              case "PostpaidCommitTrueupLedgerEntry":
                return (
                  <>
                    Postpaid commit true-up <InvoiceLink {...row.invoice} />
                  </>
                );
              case "PostpaidCommitManualLedgerEntry":
              case "PrepaidCommitManualLedgerEntry": {
                return `Manual entry: ${row.reason}`;
              }
              case "PrepaidCommitSegmentStartLedgerEntry":
              case "PostpaidCommitInitialBalanceLedgerEntry": {
                const date = printDate(toDayjs(row.segment.date));
                return `Access to segment ${date} started`;
              }
              case "PrepaidCommitExpirationLedgerEntry":
              case "PostpaidCommitExpirationLedgerEntry":
                const date = printDate(toDayjs(row.segment.date));
                return `Access to segment ${date} ended`;
              case "PrepaidCommitRolloverLedgerEntry":
              case "PostpaidCommitRolloverLedgerEntry":
                if (!row.new_commit.contract) {
                  throw new Error("Commit has no contract");
                }
                return (
                  <>
                    Rolled over to new commit:{" "}
                    <LinkInCell routePath={Commit.getRoutePath(row.new_commit)}>
                      {Commit.getName(row.new_commit, now)} on contract{" "}
                      {row.new_commit.contract
                        ? Contract.getName(row.new_commit.contract)
                        : null}
                    </LinkInCell>
                  </>
                );
              case "PrepaidCommitCanceledLedgerEntry":
                return (
                  <>
                    Commit canceled, <InvoiceLink {...row.invoice} />
                  </>
                );
              case "PrepaidCommitCreditedLedgerEntry":
                return (
                  <>
                    Commit credited, <InvoiceLink {...row.invoice} />
                  </>
                );
              default: // TODO: Remove after [PRA-2529]
                return "";
            }
          },
        },
        {
          id: "date",
          header: "Date",
          align: "right",
          cellClassName: ColWidths.DATE,
          render: (row) => {
            const date = printDate(toDayjs(row.timestamp));

            if (
              row.__typename === "PrepaidCommitExpirationLedgerEntry" ||
              row.__typename === "PostpaidCommitExpirationLedgerEntry"
            ) {
              return (
                <Badge theme="error" type="light">
                  Expired ({date})
                </Badge>
              );
            }

            return <>{date}</>;
          },
        },
      ]}
    />
  );
};
