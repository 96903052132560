import React, { useState, useEffect } from "react";
import {
  PostpaidCommitTerms,
  PrepaidCommitTerms,
  useCommitFlyoverController,
} from ".";
import {
  Select,
  Headline,
  Body,
  Subtitle,
  Input,
  TextArea,
  ButtonDropdown,
} from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { FormController } from "lib/FormController";
import { RightPane } from "components/Popup";

import { Schema } from "../../Schema";
import { FooterBar } from "../../components/FooterBar";
import { useGetContractsQuery, useGetProductsQuery } from "./data.graphql";

import styles from "./CommitFlyover.module.less";
import classnames from "classnames";

import { useConfirmClose } from "../../components/ConfirmClose";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";
import { useNow } from "lib/date";
import { CommitType } from "types/generated-graphql/__types__";
import { useSnackbar } from "components/Snackbar";
import { CreditType } from "types/credit-types";

interface Props {
  edit: Schema.Types.CommitFlyoverRoot | undefined;
  onSave: (commit: Schema.Types.CommitFlyoverRoot) => void | Promise<void>;
  onClose: () => void;
  onDelete?: () => void;
  options?: {
    netsuiteEnabled?: boolean;
    level?: "customer" | "contract";
    asCredit?: boolean;
    customerId?: string;
  };
  fiatCreditTypes: CreditType[];
  customCreditTypes: CreditType[];
  defaultCreditType: CreditType;
}

export const CommitFlyover: React.FC<Props> = (props) => {
  const ctrl = useCommitFlyoverController(props.edit);
  const [confirmingClose, confirmClose] = useConfirmClose(ctrl, props.onClose);
  const [submitting, setSubmitting] = useState(false);

  const now = useNow();
  const pushMessage = useSnackbar();

  const level = props?.options?.level ?? "contract";
  const commitOrCredit = props?.options?.asCredit ? "credit" : "commit";
  const CommitOrCredit = commitOrCredit === "commit" ? "Commit" : "Credit";

  const contractsReq = useGetContractsQuery({
    variables: {
      customerId: props?.options?.customerId ?? "",
    },
    skip: level !== "customer" || !props?.options?.customerId,
  });

  const [showCustomDisplayNameInput, setShowCustomDisplayNameInput] =
    useState<boolean>(!!ctrl.get("name"));
  const productsReq = useGetProductsQuery();
  const allProducts = productsReq.data?.products_and_rate_cards.products ?? [];
  const fixedProducts = allProducts
    .filter((product) => product.__typename === "FixedProductListItem")
    .map((product) => ({
      __typename: product.__typename,
      id: product.id,
      name: ProductListItem.getName(product, now),
    }));
  const nonFixedProducts = allProducts
    .filter((product) => product.__typename !== "FixedProductListItem")
    .map((product) => ({
      __typename: product.__typename,
      id: product.id,
      name: ProductListItem.getName(product, now),
    }));
  const tags = [
    ...new Set(
      allProducts.flatMap((product) => ProductListItem.getTags(product, now)),
    ),
  ].sort();
  const onSubmit = FormController.useSubmitHandler(ctrl, async (valid) => {
    setSubmitting(true);
    await props.onSave(valid);
    props.onClose();
    setSubmitting(false);
  });

  const onSubmitAndAddAnother = FormController.useSubmitHandler(
    ctrl,
    async (valid) => {
      setSubmitting(true);
      await props.onSave(valid);
      setSubmitting(false);
      pushMessage({
        content: `Successfully saved ${commitOrCredit}`,
        type: "success",
      });
      ctrl.reset();
    },
  );

  useEffect(() => {
    if (!showCustomDisplayNameInput) {
      ctrl.update({
        name: undefined,
      });
    }
  }, [showCustomDisplayNameInput]);

  return (
    <>
      {confirmingClose}
      <RightPane
        isOpen
        onRequestClose={confirmClose}
        size="xl"
        contentClassName="!p-0"
      >
        <form className="flex h-full flex-col" onSubmit={onSubmit}>
          <header className="flex items-center border-b border-gray-100 bg-gray-50 px-12 py-8">
            <Headline level={6} className="grow">
              {props.edit
                ? `Edit ${commitOrCredit}`
                : `Add a ${commitOrCredit}`}
            </Headline>
            <IconButton
              className="m-0"
              onClick={() => props.onClose()}
              theme="secondary"
              icon="xClose"
            />
          </header>
          <div
            className={classnames(
              styles.grey600,
              "flex grow flex-col gap-32 overflow-y-auto p-12",
            )}
          >
            <div className="flex flex-col gap-12">
              <Body className="text-gray-700">
                Add a {commitOrCredit} to this {level}.
              </Body>
              <Subtitle>{CommitOrCredit} details</Subtitle>
              <div className="flex gap-12">
                <Select
                  className="grow"
                  {...ctrl.props.Select("productId", {
                    name: props.edit ? "Commit product" : "Search products",
                    placeholder: "Search by name or ID",
                    loading: productsReq.loading,
                    disabled: productsReq.loading,
                    autoFocus: true,
                    noOptionsMessage: "No eligible products found",
                    options: fixedProducts.map((product) => ({
                      label: product.name,
                      value: product.id,
                    })),
                  })}
                />
                <ButtonDropdown
                  className="mt-[20px]"
                  buttonTheme="gray"
                  buttonType="fill"
                  icon="ellipsisVertical"
                  items={[
                    {
                      label: `${
                        showCustomDisplayNameInput ? "Remove" : "Add a"
                      } custom name`,
                      onClick: () => {
                        setShowCustomDisplayNameInput(
                          !showCustomDisplayNameInput,
                        );
                      },
                    },
                  ]}
                />
              </div>
              <Input
                {...ctrl.props.Input("name", {
                  name: "Custom display name",
                  placeholder: `Customer facing ${commitOrCredit} name`,
                  className: showCustomDisplayNameInput ? "" : "hidden",
                })}
              />
              <TextArea
                {...ctrl.props.TextArea("description", {
                  name: "Description (optional)",
                  placeholder: `Internal description of ${commitOrCredit}`,
                })}
              />
              <Select
                {...ctrl.props.MultiSelect("applicableProductIds", {
                  name: "Applicable products (optional, defaults to all products)",
                  placeholder: "Search by name or ID",
                  loading: productsReq.loading,
                  disabled: productsReq.loading,
                  options: nonFixedProducts.map((product) => ({
                    label: product.name,
                    value: product.id,
                  })),
                })}
              />
              <Select
                {...ctrl.props.MultiSelect("applicableProductTags", {
                  name: "Applicable tags (optional, defaults to all products)",
                  placeholder: "Search by tag",
                  loading: productsReq.loading,
                  disabled: productsReq.loading,
                  options: tags.map((tag) => ({
                    label: tag,
                    value: tag,
                  })),
                })}
              />
              {level === "customer" && (
                <Select
                  {...ctrl.props.MultiSelect("applicableContractIds", {
                    name: "Applicable contracts (optional, defaults to all contracts)",
                    placeholder: "Search by name or ID",
                    loading: contractsReq.loading,
                    disabled: contractsReq.loading,
                    options:
                      contractsReq.data?.customer?.contracts?.map((c) => ({
                        label: c.name ?? c.id,
                        value: c.id,
                      })) ?? [],
                  })}
                />
              )}
              {props.options?.netsuiteEnabled && (
                <div className="grid grid-cols-3 gap-12">
                  <Input
                    {...ctrl.props.Input("netsuiteSalesOrderId", {
                      name: "NetSuite Sales Order ID (optional)",
                      placeholder: "Enter ID",
                    })}
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-12">
              <Subtitle>{CommitOrCredit} terms</Subtitle>
              {commitOrCredit === "commit" && (
                <div className="mb-[20px] grid grid-cols-3">
                  <Select
                    {...ctrl.props.Select("type", {
                      name: "Commit type",
                      placeholder: "",
                      options: [
                        {
                          label: "Prepaid",
                          value: CommitType.Prepaid,
                        },
                        {
                          label: "Postpaid",
                          value: CommitType.Postpaid,
                        },
                      ],
                    })}
                  />
                </div>
              )}
              {ctrl.get("type") === CommitType.Postpaid ? (
                <PostpaidCommitTerms
                  parent={ctrl}
                  level={level}
                  contracts={contractsReq.data?.customer?.contracts}
                />
              ) : ctrl.get("type") === CommitType.Prepaid ? (
                <PrepaidCommitTerms
                  parent={ctrl}
                  level={level}
                  asCredit={!!props.options?.asCredit}
                  contracts={contractsReq.data?.customer?.contracts}
                  fiatCreditTypes={props.fiatCreditTypes}
                  customCreditTypes={props.customCreditTypes}
                  defaultCreditType={props.defaultCreditType}
                />
              ) : null}
            </div>
          </div>
          <FooterBar
            left={
              props.onDelete ? (
                <Button
                  onClick={props.onDelete}
                  text="Delete"
                  theme="linkDestructive"
                  leadingIcon="trash03"
                />
              ) : null
            }
            right={
              <>
                <Button
                  onClick={() => props.onClose()}
                  text="Cancel"
                  theme="linkGray"
                />
                {!props.edit && level !== "customer" ? (
                  <Button
                    disabled={!ctrl.appearsValid() || submitting}
                    onClick={() => {
                      onSubmitAndAddAnother();
                    }}
                    text="Add another"
                    theme="secondary"
                  />
                ) : (
                  ""
                )}
                <Button
                  disabled={!ctrl.appearsValid() || submitting}
                  text={props.edit ? "Save" : "Add"}
                  theme="primary"
                  type="submit"
                />
              </>
            }
          />
        </form>
      </RightPane>
    </>
  );
};
