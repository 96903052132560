import React from "react";
import {
  Body,
  Headline,
  Subtitle,
  DateInput,
  RadioGroup,
  Tooltip,
  Icon,
} from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { RightPane } from "components/Popup";
import {
  InvoiceExternalTypeEnum,
  InvoiceFinalizationInput,
} from "types/generated-graphql/__types__";

type InvoiceFinalizationFlyoutProps = {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
  invoicesToFinalizeCount: number;
  finalizationInput: Partial<InvoiceFinalizationInput>;
  updateFinalizationInput: (input: Partial<InvoiceFinalizationInput>) => void;
};

const externalTypeValues = Object.values(InvoiceExternalTypeEnum);
function isInvoiceExternalTypeEnum(
  value: string,
): value is InvoiceExternalTypeEnum {
  return externalTypeValues.includes(value as InvoiceExternalTypeEnum);
}
const InvoiceFinalizationFlyout: React.FC<InvoiceFinalizationFlyoutProps> = ({
  isOpen,
  onSubmit,
  onClose,
  invoicesToFinalizeCount,
  finalizationInput,
  updateFinalizationInput,
}) => {
  const { invoice_type, finalization_date } = finalizationInput;
  return (
    <RightPane isOpen={isOpen} contentClassName="!p-0 flex flex-col">
      <div className="flex h-full flex-col">
        <header className="flex flex-row items-center justify-between border-b border-gray-100 bg-gray-50 px-12 py-8">
          <Headline level={6}>Finalize Invoices</Headline>
          <IconButton onClick={onClose} theme="secondary" icon="xClose" />
        </header>
        <div className="flex flex-grow flex-col px-12 py-8">
          <div className="flex flex-grow flex-col gap-16">
            <div>
              <Headline className="text-gray-900" level={6}>
                Invoice cohort
              </Headline>
              <Body level={2} className="text-gray-700">
                Select the type of invoices to send to their billing provider.
              </Body>
              <RadioGroup
                value={invoice_type}
                options={[
                  {
                    value: InvoiceExternalTypeEnum.Usage,
                    label: "Contract usage statements",
                  },
                  {
                    value: InvoiceExternalTypeEnum.PlanArrears,
                    label: "Plan usage statements",
                  },
                  {
                    value: InvoiceExternalTypeEnum.Scheduled,
                    label: "Postpaid commit true-up invoices",
                  },
                ]}
                onChange={(value: string) =>
                  isInvoiceExternalTypeEnum(value) &&
                  updateFinalizationInput({
                    invoice_type: value,
                  })
                }
              />
            </div>
            <div>
              <Headline className="text-gray-900" level={6}>
                Finalization date
              </Headline>
              <Body level={2} className="text-gray-700">
                Finalize invoices with end dates on or before this date
              </Body>
              <Subtitle level={4} className="flex items-center text-grey-600">
                Invoices ending before
                <Tooltip content="Usage invoices are issued at 12:00 AM UTC of the day after billing period end. For example, June usage invoices will have an issue date of July 1. Therefore, to finalize June usage invoices, set the finalization date to July 1. To finalize July usage invoices, set the finalization date to August 1.">
                  <Icon icon="helpCircle" />
                </Tooltip>
              </Subtitle>
              <div className="w-2/3">
                <DateInput
                  maxDate={new Date()}
                  value={
                    finalization_date ? new Date(finalization_date) : undefined
                  }
                  onChange={(date) =>
                    updateFinalizationInput({
                      finalization_date: date.toISOString(),
                    })
                  }
                />
                {!!invoice_type && !!finalization_date ? (
                  <Subtitle
                    level={4}
                    className="flex items-center text-green-medium"
                  >
                    {invoicesToFinalizeCount} invoices
                  </Subtitle>
                ) : null}
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-8">
            <Button onClick={onClose} text="Cancel" theme="secondary" />
            <Button
              disabled={!invoice_type || !finalization_date}
              onClick={onSubmit}
              text="Finalize"
              theme="primary"
              type="submit"
            />
          </div>
        </div>
      </div>
    </RightPane>
  );
};

export default InvoiceFinalizationFlyout;
