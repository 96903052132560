import React, { ReactElement, useState } from "react";
import type { RouteObject } from "react-router";
import { useNavigate } from "lib/useNavigate";
import { useRequiredParam } from "lib/routes/params";

import NotFoundPage from "pages/404";

import { PageContainer } from "components/PageContainer";
import { Breadcrumbs } from "lib/breadcrumbs";
import { useContractsEnabled } from "lib/contracts/useContractsEnabled";
import { PlanPreview } from "components/PlanPreview";
import { BlockSkeleton } from "components/Skeleton";
import { MinimalStatistic } from "components/Statistic";
import { PopoverMenu } from "components/PopoverMenu";
import { IconButton } from "tenaissance/components/IconButton";
import ArchivePlanModal from "../Plans/components/ArchivePlanModal";

import { useCopyPlan } from "lib/plans/hooks";

import {
  PlanAlertsInfoFragment,
  PlanInfoMinusAlertsFragment,
  usePlanAlertsQuery,
  usePlanMinusAlertsQuery,
} from "./data/plan.graphql";
import { Tooltip } from "design-system";
import { CustomerTable } from "pages/Overview/components/CustomerTable";
import { ALL_BILLING_CONFIG_FILTERS } from "pages/Overview/filters";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { AlertsTable } from "components/AlertsTable";
import { EmptyState } from "components/EmptyState";
import { useFeatureFlag } from "lib/launchdarkly";
import CopyPlanModal from "components/CopyAssetToEnvironmentModal/CopyPlanModal";
import { gatedAction, useAuthCheck } from "lib/useAuthCheck";
import { InsertPlanDocument } from "components/CopyAssetToEnvironmentModal/data/queries.graphql";
import { EditPlanDocument } from "pages/PlanWizards/data/queries.graphql";
import { ArchiveAlertDocument } from "pages/Alert/queries.graphql";
import { CreateAlertDocument } from "pages/NewAlert/queries.graphql";
import { AuditLogTable } from "components/AuditLogTable";

const BACK_BUTTON = Breadcrumbs.from({
  type: "back",
  label: "Back to plans",
  routePath: "/plans",
});

type Plan = PlanInfoMinusAlertsFragment;

type PlanView = React.FC<{
  plan: NonNullable<Plan>;
  alerts?: PlanAlertsInfoFragment["Alerts"];
}>;

interface PlanTabProps {
  View: PlanView;
}
const PlanTab: React.FC<PlanTabProps> = ({ View }) => {
  const contractsEnabled = useContractsEnabled();
  const planId = useRequiredParam("id");
  const showAlertsTab = useFeatureFlag<boolean>("plan-alerts-info", false);
  const { data, loading, error } = usePlanMinusAlertsQuery({
    variables: {
      id: planId,
    },
  });
  const { data: planAlertsData } = usePlanAlertsQuery({
    variables: {
      id: planId,
    },
    skip: !showAlertsTab,
  });

  const navigate = useNavigate();
  const sandboxMode = useEnvironment();
  const { environments, environment } = sandboxMode;
  const { copyPlan } = useCopyPlan();
  const [planToArchive, setPlanToArchive] = useState<Plan | null>(null);
  const [planToCopy, setPlanToCopy] = useState<Plan | null>(null);

  const canCreatePlan = !!useAuthCheck(InsertPlanDocument, true).allowed;
  const canEditPlan = !!useAuthCheck(EditPlanDocument, true).allowed;
  const canArchivePlan = !!useAuthCheck(ArchiveAlertDocument, true).allowed;

  const plan = data?.Plan_by_pk;
  const copyAssetsFF = useFeatureFlag<string[]>(
    "copy-assets-to-environment",
    [],
  );
  const copyPlanToEnvironmentEnabled = copyAssetsFF?.includes("PLAN");

  if (loading || error) {
    return (
      <PageContainer
        title={Breadcrumbs.from(
          contractsEnabled ? BACK_BUTTON : null,
          Breadcrumbs.loading,
        )}
      >
        <BlockSkeleton />
      </PageContainer>
    );
  }
  if (!plan) {
    return <NotFoundPage />;
  }

  const actions: {
    content: string | ReactElement;
    onClick: () => void;
    disabled?: boolean;
  }[] = [
    gatedAction(canCreatePlan, {
      content: "Duplicate plan...",
      onClick: async () => copyPlan(plan.id),
    }),
  ];

  if (copyPlanToEnvironmentEnabled && environments.length > 1) {
    const text =
      environments.length === 2
        ? `Copy to ${environments.filter((e) => e !== environment)[0].name}`
        : "Copy to another environment...";
    actions.push({
      content: text,
      onClick: () => setPlanToCopy(plan),
    });
  }

  actions.push({
    content: "Manage custom fields...",
    onClick: () => navigate(`/custom-fields/plan/${plan.id}`),
  });

  actions.push(
    gatedAction(canEditPlan, {
      content: "Edit plan...",
      onClick: () => navigate(`/plans/edit/${plan.id}`),
    }),
  );

  const activeAndFutureCustomers =
    plan.active_customer_count + plan.future_customer_count;

  if (plan.deprecated_at === null) {
    actions.push(
      gatedAction(canArchivePlan, {
        disabled: activeAndFutureCustomers > 0,
        content:
          activeAndFutureCustomers === 0 ? (
            "Archive plan..."
          ) : (
            <Tooltip content="Plans in use cannot be archived">
              Archive plan...
            </Tooltip>
          ),
        onClick: () => setPlanToArchive(plan),
      }),
    );
  }

  const planMenu = (
    <PopoverMenu positions={["bottom"]} align="end" options={actions}>
      {(onClick) => (
        <IconButton onClick={onClick} theme="secondary" icon="dotsVertical" />
      )}
    </PopoverMenu>
  );

  return (
    <PageContainer
      title={Breadcrumbs.from(contractsEnabled ? BACK_BUTTON : null, {
        label: plan.name,
      })}
      action={contractsEnabled ? null : planMenu}
      tabsAction={
        contractsEnabled ? (
          planMenu
        ) : (
          <MinimalStatistic
            icon="people"
            value={activeAndFutureCustomers}
            label="customers"
          />
        )
      }
      badge={
        plan.deprecated_at !== null
          ? { theme: "warning", type: "dark", children: "ARCHIVED" }
          : undefined
      }
      tabs={[
        {
          name: "Plan Summary",
          routePath: `/plans/${planId}`,
        },
        {
          name: "Customers",
          routePath: `/plans/${planId}/customers`,
        },
        ...(planAlertsData
          ? [
              {
                name: "Alerts",
                routePath: `/plans/${planId}/alerts`,
              },
            ]
          : []),
        {
          name: "Audit Logs",
          routePath: `/plans/${planId}/audit-logs`,
        },
      ]}
    >
      {planToArchive && (
        <ArchivePlanModal
          onClose={() => {
            setPlanToArchive(null);
          }}
          plan={planToArchive}
        />
      )}
      {planToCopy && (
        <CopyPlanModal
          onClose={() => {
            setPlanToCopy(null);
          }}
          planId={planToCopy.id}
          planName={planToCopy.name}
        />
      )}
      {plan && <View plan={plan} alerts={planAlertsData?.Plan_by_pk?.Alerts} />}
    </PageContainer>
  );
};

const PlanCustomers: PlanView = ({ plan }) => (
  <CustomerTable
    planId={plan.id}
    billingConfigFilters={ALL_BILLING_CONFIG_FILTERS}
  />
);

const PlanAlerts: PlanView = ({ plan, alerts }) => {
  const navigate = useNavigate();

  return !alerts || (alerts.length ?? 0) === 0 ? (
    <EmptyState
      title="This plan has no alerts."
      subtitle="Create an alert."
      buttonAuthDoc={CreateAlertDocument}
      buttonText="Create alert"
      onClick={() => navigate("/alerts/new")}
      icon="bell03"
      buttonIcon="plus"
    />
  ) : (
    <AlertsTable
      alerts={
        alerts.map((alert) => ({
          ...alert,
          Plan: {
            id: plan.id,
            name: plan.name,
          },
        })) ?? []
      }
      loading={false}
      singleCustomer={false}
    />
  );
};

const PlanOverview: PlanView = ({ plan }) => (
  <div className="mt-12">
    <PlanPreview plan={plan} collapsible={false} />
  </div>
);

const PlanAuditLogs: PlanView = ({ plan }) => (
  <div className="mt-12">
    <AuditLogTable resourceType="plan" resourceID={plan.id} />
  </div>
);

export const PlanRoute: RouteObject = {
  path: "plans/:id",
  children: [
    { index: true, element: <PlanTab View={PlanOverview} /> },
    { path: "customers", element: <PlanTab View={PlanCustomers} /> },
    { path: "alerts", element: <PlanTab View={PlanAlerts} /> },
    { path: "audit-logs", element: <PlanTab View={PlanAuditLogs} /> },
    { path: "*", element: <PlanTab View={NotFoundPage} /> },
  ],
};
