import React, { useEffect, useState } from "react";
import { PageContainer } from "components/PageContainer";
import { Body, Label, Headline, Input, Icon } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { useNavigate } from "lib/useNavigate";
import {
  Condition,
  jsonSchemaToConditions,
  conditionsToJsonSchema,
} from "@metronome-industries/json-schema-conditions";
import ConditionInput from "./components/ConditionInput";
import { ToggleButtons } from "components/ToggleButtons";
import { BillingMetricAggregateEnum_Enum } from "types/generated-graphql/__types__";
import { Select } from "design-system";
import classnames from "classnames";
import {
  useInsertBillableMetricMutation,
  useBillableMetricDetailQuery,
  useInsertSeatMetricMutation,
  useSeatMetricDetailQuery,
} from "./queries.graphql";
import { useSnackbar } from "components/Snackbar";
import { useFeatureFlag } from "lib/launchdarkly";
import { DefinitionDisplay } from "components/BillableMetricsDefinition";
import { Tooltip } from "design-system";
import JsonSchemaValidator from "components/JsonSchemaValidator";
import { RouteObject, useParams } from "react-router-dom";
import { TextSkeleton } from "components/Skeleton";
import { MetricType } from "lib/billableMetrics/types";
import { useGigaRateCardEnabled } from "pages/Contracts/lib/GigaRateCard";
import { BillableMetricV2Switch } from "./BillableMetricV2Switch";
import { BillableMetricV2 } from "./BillableMetricV2";

const MAX_ENUMS = 30;
const MAX_PROP_FILTERS = 15;

const NewBillableMetric: React.FC<{ metricType: MetricType }> = ({
  metricType,
}) => {
  /* Attempt to pull in id parameter and populate form */
  const { id: metricId } = useParams<{ id?: string }>();
  const { data: draftMetricData, loading } = useBillableMetricDetailQuery({
    variables: {
      billable_metric_id: metricId || "",
    },
    skip: !metricId || metricType !== "billable",
  });

  const { data: draftSeatMetricData, loading: seatLoading } =
    useSeatMetricDetailQuery({
      variables: {
        seat_metric_id: metricId || "",
      },
      skip: !metricId || metricType !== "seat",
    });

  const gigaRateCardEnabled = useGigaRateCardEnabled();
  const enableSeats = useFeatureFlag<boolean>("seats", false);
  const navigate = useNavigate();
  const [metricName, setMetricName] = useState("");
  const [aggregate, setAggregate] = useState<
    BillingMetricAggregateEnum_Enum | undefined
  >(undefined);
  const [aggregateKey, setAggregateKey] = useState<string | undefined>(
    undefined,
  );
  const [groupKeys, setGroupKeys] = useState<Set<string>[]>([]);
  const [conditions, setConditions] = useState<(Condition & { id: string })[]>([
    {
      id: Math.random().toString(),
      field: ["event_type"],
      enum: {
        not: false,
        values: [],
      },
      required: true,
    },
  ]);

  const [createMetric, { loading: insertLoading }] =
    useInsertBillableMetricMutation();

  const [createSeatMetric, { loading: insertSeatLoading }] =
    useInsertSeatMetricMutation();

  const pushMessage = useSnackbar();

  const propertiesValidation = (propertyName: string | undefined) => {
    if (!propertyName) {
      return true;
    }
    return !(
      conditions.filter((condition) => propertyName === condition.field[1])
        .length > 1
    );
  };

  const propertyFields: string[] = Array.from(
    conditions
      .reduce((agg: Set<string>, condition) => {
        /* Only properties that exist count towards our set */
        if (condition.field[1] && condition.required === true) {
          agg.add(condition.field[1]);
        }
        return agg;
      }, new Set<string>())
      .values(),
  );

  const updateGroupAndAggregateKeys = (condition: Condition) => {
    /* Blank strings are valid properties but we shouldn't update ours keys based on them */
    if (condition.field[1] && propertiesValidation(condition.field[1])) {
      if (condition.field[1] === aggregateKey) {
        setAggregateKey(undefined);
      }
      setGroupKeys(
        // remove condition property from all existing group keys
        groupKeys.map((groupKey) => {
          if (groupKey.has(condition.field[1])) {
            const updatedGroupKey = new Set<string>(groupKey);
            updatedGroupKey.delete(condition.field[1]);
            return updatedGroupKey;
          }
          return groupKey;
        }),
      );
    }
  };

  // Check if the group key at argument groupKeysIndex is a duplicate of one
  // earlier in the list
  const groupKeyError = (groupKeysIndex: number) =>
    !!groupKeys
      .slice(0, groupKeysIndex)
      .find(
        (otherGroupKey) =>
          otherGroupKey.size === groupKeys[groupKeysIndex].size &&
          [...groupKeys[groupKeysIndex]].reduce(
            (agg, curGroupKeyItem) => agg && otherGroupKey.has(curGroupKeyItem),
            true,
          ),
      );

  const aggregateSelectDisabled =
    !aggregate ||
    !propertyFields.length ||
    aggregate === BillingMetricAggregateEnum_Enum.Count;

  const filteredGroupKeys = groupKeys.filter((groupKey) => groupKey.size > 0);

  const allGroupKeysValid = !groupKeys
    .map((_, index) => groupKeyError(index))
    .reduce((agg, groupKeyError) => agg || groupKeyError, false);

  const isMetricValid = !(
    metricName &&
    (aggregate === BillingMetricAggregateEnum_Enum.Count ||
      (aggregate && aggregateKey)) &&
    conditions &&
    allGroupKeysValid
  );

  const saveMetric = async () => {
    /* We toss away empty property names, otherwise we technically have duplicate fields */
    const filteredConditions = conditions.filter((c) => c.field[1] !== "");
    try {
      const jsonSchema = conditionsToJsonSchema(filteredConditions);
      if (aggregate === BillingMetricAggregateEnum_Enum.Latest) {
        const metric = await createSeatMetric({
          variables: {
            input: {
              name: metricName,
              filter: jsonSchema,
              is_draft: false,
              aggregate_key: aggregateKey as string,
            },
          },
          update(cache) {
            cache.evict({
              fieldName: "SeatMetric",
            });
            cache.evict({
              fieldName: "seat_metrics",
            });
          },
        });

        if (metric.data?.create_seat_metric) {
          pushMessage({
            content: `Successfully created metric: ${metric.data.create_seat_metric.name}`,
            type: "success",
          });
          navigate(
            `/billable-metrics/seats/${metric.data.create_seat_metric.id}`,
          );
        }
      } else {
        const nonCompositeGroupKeys = groupKeys
          .filter((groupKey) => groupKey.size === 1)
          .flatMap((groupKeyItems) => [...groupKeyItems]);
        const compositeGroupKeys = groupKeys
          .filter((groupKey) => groupKey.size > 1)
          .map((groupKeyItems) => [...groupKeyItems]);
        const metric = await createMetric({
          variables: {
            object: {
              aggregate: aggregate as BillingMetricAggregateEnum_Enum,
              aggregate_key:
                aggregate === BillingMetricAggregateEnum_Enum.Count
                  ? undefined
                  : aggregateKey,
              filter: jsonSchema,
              group_keys:
                nonCompositeGroupKeys.length > 0
                  ? nonCompositeGroupKeys
                  : undefined,
              composite_group_keys:
                compositeGroupKeys.length > 0 ? compositeGroupKeys : undefined,
              name: metricName,
              is_draft: false,
            },
          },
          update(cache) {
            cache.evict({
              fieldName: "BillableMetric",
            });
            cache.evict({
              fieldName: "billable_metrics",
            });
          },
        });
        if (metric.data?.create_billable_metric) {
          pushMessage({
            content: `Successfully created metric: ${metric.data.create_billable_metric.name}`,
            type: "success",
          });
          navigate(
            `/billable-metrics/${metric.data.create_billable_metric.id}`,
          );
        }
      }
    } catch (error: any) {
      pushMessage({
        content: `Failed to create billable metric: ${error.message}`,
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (draftMetricData?.BillableMetric) {
      setMetricName(`${draftMetricData.BillableMetric.name} (copy)`);
      setConditions(
        [
          ...jsonSchemaToConditions(draftMetricData.BillableMetric.filter).map(
            (c) => {
              return { ...c, id: Math.random().toString() };
            },
          ),
          /* Remove property conditional */
        ].filter((c) => c.field.length === 2 || c.field[0] !== "properties"),
      );
      const deserializedGroupKeys = draftMetricData.BillableMetric
        .group_keys as (string | string[])[] | null;
      setGroupKeys(
        deserializedGroupKeys?.map((groupKey) =>
          typeof groupKey === "string"
            ? new Set<string>([groupKey])
            : new Set<string>(groupKey),
        ) ?? [],
      );
      if (draftMetricData.BillableMetric.aggregate === "unique") {
        setAggregate(draftMetricData.BillableMetric.aggregate);
      } else {
        setAggregate(draftMetricData.BillableMetric.aggregate);
      }
      setAggregateKey(
        (draftMetricData.BillableMetric.aggregate_keys as string[])?.[0],
      );
    }
  }, [draftMetricData]);

  useEffect(() => {
    if (draftSeatMetricData?.seat_metric) {
      setMetricName(`${draftSeatMetricData?.seat_metric.name} (copy)`);
      setConditions(
        [
          ...jsonSchemaToConditions(
            draftSeatMetricData?.seat_metric.filter,
          ).map((c) => {
            return { ...c, id: Math.random().toString() };
          }),
          /* Remove property conditional */
        ].filter((c) => c.field.length === 2 || c.field[0] !== "properties"),
      );
      setAggregate(BillingMetricAggregateEnum_Enum.Latest);
    }
  }, [draftSeatMetricData]);

  /* Group keys and the Unique aggregate are incompatible */
  useEffect(() => {
    if (aggregate === BillingMetricAggregateEnum_Enum.Unique) {
      setGroupKeys([]);
    }
  }, [aggregate === BillingMetricAggregateEnum_Enum.Unique]);

  /* If we don't wait for the launchdarkly flags the page jumps  */
  if (loading || seatLoading) {
    return (
      <PageContainer title="Loading ...">
        <div>
          <TextSkeleton />
          <TextSkeleton />
          <TextSkeleton />
          <TextSkeleton />
        </div>
      </PageContainer>
    );
  }

  return (
    <PageContainer
      disableContainerScroll
      action={
        <>
          <IconButton
            onClick={() => navigate("/billable-metrics")}
            className="mr-8"
            loading={insertLoading || insertSeatLoading}
            theme="secondary"
            icon="xClose"
          />
        </>
      }
      title="Design your new metric"
    >
      <div className="flex grow flex-row gap-[50px] overflow-auto pr-12 pt-24">
        <div className="flex max-w-[1700px] grow flex-col gap-[18px]">
          <div>
            <div className="mb-8 w-full border-b border-b-grey-100 pb-8">
              <Headline className="text-grey-900" level={6}>
                Step 1: Name and define your metric
              </Headline>
            </div>
            <Body className="mb-[18px]" level={1}>
              This is the default name that will appear on customer invoices.
              You can customize it when you create a product.
            </Body>
            <Input
              name="Metric name"
              placeholder="Enter name"
              value={metricName ?? ""}
              onChange={(v) => setMetricName(v)}
            />
          </div>

          <div>
            <div className="mb-8 w-full border-b border-b-grey-100 pb-8">
              <Headline level={6}>Step 2: Define filters</Headline>
            </div>
            <Body className="mb-[18px]" level={1}>
              Filters determine which usage events will be aggregated by this
              billable metric. You can filter based on the event_type field as
              well as custom event properties.
            </Body>
            {conditions.map((condition, index) => {
              return (
                <div key={condition.id} data-testid={`condition-${index}`}>
                  <Label>
                    {condition.field[0] === "event_type"
                      ? "Event type"
                      : "Property filter"}
                  </Label>
                  <ConditionInput
                    condition={condition}
                    validation={propertiesValidation}
                    onChange={(newCondition) => {
                      updateGroupAndAggregateKeys(conditions[index]);
                      setConditions([
                        ...conditions.slice(0, index),
                        { ...conditions[index], ...newCondition },
                        ...conditions.slice(index + 1),
                      ]);
                    }}
                    onDelete={(condition: Condition) => {
                      /* Try to delete group keys and aggregate keys based on properties changing */
                      updateGroupAndAggregateKeys(conditions[index]);
                      setConditions([
                        ...conditions.slice(0, index),
                        ...conditions.slice(index + 1),
                      ]);
                    }}
                    maxEnums={MAX_ENUMS}
                  />
                </div>
              );
            })}
            <div className="flex items-center">
              <Tooltip
                content={`Only ${MAX_PROP_FILTERS} property filters can be created at one time`}
                disabled={conditions.length <= MAX_PROP_FILTERS}
              >
                <Button
                  onClick={() =>
                    setConditions([
                      ...conditions,
                      {
                        id: Math.random().toString(),
                        field: ["properties", ""],
                        enum: undefined,
                        required: undefined,
                      },
                    ])
                  }
                  disabled={conditions.length > MAX_PROP_FILTERS}
                  text="Add another filter"
                  theme="primary"
                  leadingIcon="plus"
                />
              </Tooltip>
            </div>
          </div>
          <div>
            <div className="mb-8 w-full border-b border-b-grey-100 pb-8">
              <Headline level={6}>Step 3: Define aggregation</Headline>
            </div>
            <Body className="mb-12" level={1}>
              Determine how these events should be aggregated into a single
              metric.{" "}
              {enableSeats &&
                "Seat is a unique aggregation that tallies a customer's current seat count and max count during a billing period."}
            </Body>
            <ToggleButtons
              buttonProps={[
                {
                  value: BillingMetricAggregateEnum_Enum.Count,
                  label: "Count",
                },
                {
                  value: BillingMetricAggregateEnum_Enum.Sum,
                  label: "Sum",
                },
                {
                  value: BillingMetricAggregateEnum_Enum.Max,
                  label: "Max",
                },
                {
                  value: BillingMetricAggregateEnum_Enum.Unique,
                  label: "Unique",
                },
                ...(!!enableSeats
                  ? [
                      {
                        value: BillingMetricAggregateEnum_Enum.Latest,
                        label: "Seat",
                      },
                    ]
                  : []),
              ]}
              value={aggregate}
              defaultButtonProps={{
                useGreyBackground: true,
                onChange: (v) => {
                  if (v === BillingMetricAggregateEnum_Enum.Count) {
                    setAggregate(undefined);
                  }

                  setAggregate(v as BillingMetricAggregateEnum_Enum);
                },
              }}
            />
            <Tooltip
              content={
                aggregate === BillingMetricAggregateEnum_Enum.Count
                  ? "The 'Count' aggregator does not require an aggregate key"
                  : "An aggregate key can not be selected until at least one property filter has been defined"
              }
              disabled={!aggregateSelectDisabled}
            >
              <div className="w-full">
                <Select
                  value={aggregateKey ?? ""}
                  options={propertyFields.map((v) => ({
                    value: v,
                    label: v,
                  }))}
                  onChange={(v) => {
                    setAggregateKey(v);
                    /* An aggregate can not also be a group key */
                    /* TODO(GET-1870): Come up with a better UI/UX that explains the problem */
                    setGroupKeys(
                      groupKeys.filter((groupKey) => !groupKey.has(v)),
                    );
                  }}
                  name="Property"
                  disabled={aggregateSelectDisabled}
                  placeholder="Select"
                />
              </div>
            </Tooltip>
          </div>
          {aggregate !== BillingMetricAggregateEnum_Enum.Latest && (
            <div>
              <div className="mb-8 w-full border-b border-b-grey-100 pb-8">
                <Headline level={6}>Optional: Add group key</Headline>
              </div>
              <Body className="mb-[18px]" level={1}>
                Specify properties this metric should be grouped by. Grouping a
                metric by various properties allow you to customize how events
                are grouped on an invoice. It also gives you additional
                cardinality to group/filter usage through this metric.{" "}
                {gigaRateCardEnabled &&
                  "Group individually or combine properties for compound groups."}
              </Body>
              <div>
                {aggregate === BillingMetricAggregateEnum_Enum.Unique ? (
                  <div className="flex justify-center px-0 py-[28px]">
                    <Label className="text-grey-200">
                      The "Unique" aggregate and grouping are incompatible.
                    </Label>
                  </div>
                ) : gigaRateCardEnabled ? (
                  <>
                    {groupKeys.map((curGroupKey, groupKeysIndex) => (
                      <div
                        className="mb-[10px]"
                        key={`group-key-${groupKeysIndex}`}
                      >
                        <div className="flex items-end justify-between">
                          <Select
                            name="Group key"
                            multiSelect
                            className="min-w-[400px] flex-1"
                            value={[...curGroupKey]}
                            placeholder="Enter one or more of your property filters"
                            error={groupKeyError(groupKeysIndex)}
                            onChange={(values) => {
                              const newGroupKeys = [
                                ...groupKeys.slice(0, groupKeysIndex),
                                new Set(values),
                                ...groupKeys.slice(
                                  groupKeysIndex + 1,
                                  groupKeys.length,
                                ),
                              ];
                              setGroupKeys(newGroupKeys);
                              for (const property of values) {
                                if (property === aggregateKey) {
                                  setAggregateKey(undefined);
                                }
                              }
                            }}
                            options={[...propertyFields].map((v) => ({
                              label: v,
                              value: v,
                            }))}
                            __internalComponentOverrides={{
                              DropdownIndicator: () => null,
                            }}
                          />
                          <div className="flex justify-end">
                            <IconButton
                              onClick={() =>
                                setGroupKeys([
                                  ...groupKeys.slice(0, groupKeysIndex),
                                  ...groupKeys.slice(
                                    groupKeysIndex + 1,
                                    groupKeys.length,
                                  ),
                                ])
                              }
                              theme="tertiary"
                              icon="xClose"
                              className="mb-[-4px]"
                            />
                          </div>
                        </div>

                        {curGroupKey.size > 1 && (
                          <Body level={2} className="text-grey-600">
                            This is a compound group key:{" "}
                            {JSON.stringify([...curGroupKey])}
                          </Body>
                        )}
                      </div>
                    ))}
                    <Button
                      onClick={() =>
                        setGroupKeys([...groupKeys, new Set<string>()])
                      }
                      className="mb-[60px]"
                      disabled={propertyFields.length === 0}
                      text="Add another group key"
                      theme="primary"
                      leadingIcon="plus"
                    />
                  </>
                ) : (
                  propertyFields.map((property) => {
                    const selected = !!groupKeys.find(
                      (newGroupKey) =>
                        newGroupKey.size === 1 && newGroupKey.has(property),
                    );
                    return (
                      <button
                        key={property}
                        className={classnames(
                          "mx-0 my-4 flex w-full cursor-pointer flex-row items-center justify-between rounded-large border border-grey-100 bg-white px-12 py-8 font-default font-normal",
                          ...(selected
                            ? [
                                "border-primary-100 bg-primary-50 text-primary-600",
                              ]
                            : []),
                        )}
                        onClick={() => {
                          if (selected) {
                            setGroupKeys(
                              groupKeys.filter(
                                (groupKey) =>
                                  !(
                                    groupKey.size === 1 &&
                                    groupKey.has(property)
                                  ),
                              ),
                            );
                          } else {
                            const newGroupKey = new Set([property]);
                            setGroupKeys([...groupKeys, newGroupKey]);
                            if (property === aggregateKey) {
                              setAggregateKey(undefined);
                            }
                          }
                        }}
                      >
                        {property}
                        <Icon
                          icon="checkmarkCircle"
                          className={classnames(
                            "h-[16px] w-[16px]",
                            ...(selected ? [] : ["text-grey-200"]),
                          )}
                        />
                      </button>
                    );
                  })
                )}
              </div>
            </div>
          )}
        </div>
        <div className="min-w-[560px]">
          <Headline className="text-grey-900" level={6}>
            Preview your metric
          </Headline>
          <div className="mb-24">
            <DefinitionDisplay
              aggregate={aggregate}
              aggregateKeys={aggregateKey ? [aggregateKey] : []}
              groupKeys={
                filteredGroupKeys.length > 0 ? filteredGroupKeys : undefined
              }
              allowGroupKeys={
                aggregate !== BillingMetricAggregateEnum_Enum.Latest
              }
              conditions={conditions}
              vertical
            />
          </div>
          <Headline className="text-grey-900" level={6}>
            Test your metric with your own events
          </Headline>
          <Body className="mb-[18px]" level={1}>
            Paste an event payload below to determine if it matches this
            billable metric
          </Body>
          <JsonSchemaValidator
            conditions={conditions}
            showCreateExampleButton={true}
          />
        </div>
      </div>
      <div className="-mx-12 flex flex-row items-center justify-end gap-8 bg-white px-24 py-12 shadow-inner">
        <Button
          onClick={() => navigate("/billable-metrics")}
          text="Cancel"
          theme="linkGray"
        />
        <Button
          onClick={saveMetric}
          disabled={isMetricValid || insertLoading || insertSeatLoading}
          loading={insertLoading || insertSeatLoading}
          text="Save"
          theme="primary"
        />
      </div>
    </PageContainer>
  );
};

export const NewMetricRoutes: RouteObject[] = [
  {
    path: "billable-metrics/new/:id?",
    element: (
      <BillableMetricV2Switch
        enabled={<BillableMetricV2 metricType="billable" />}
        disabled={<NewBillableMetric metricType="billable" />}
      />
    ),
  },
  {
    path: "billable-metrics/seats/new/:id?",
    element: (
      <BillableMetricV2Switch
        enabled={<BillableMetricV2 metricType="seat" />}
        disabled={<NewBillableMetric metricType="seat" />}
      />
    ),
  },
];
