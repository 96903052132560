import React, { useEffect } from "react";

import { useNow, Dayjs } from "lib/date";
import { useRequiredParam } from "lib/routes/params";
import { Breadcrumbs } from "lib/breadcrumbs";

import { CustomerLayout } from "../CustomerLayout";
import { RecentInvoicesTable } from "./RecentInvoicesTable";
import { ConnectedContractsAndPlans } from "./ConnectedContractsAndPlans";
import {
  useCustomerBillingOverviewQuery,
  useRecentCustomerInvoicesQuery,
} from "./data.graphql";
import { ActivityList } from "../../components/ActivityList";
import { ContractOrPlan } from "../../lib/ContractOrPlan";
import { useApolloResp } from "../../lib/ApolloResp";
import { RecentInvoicesBarChart } from "./RecentInvoicesBarChart";
import { Icon, Select, Subtitle } from "design-system";
import { components, ValueContainerProps } from "react-select";
import { findCreditType } from "pages/Contracts/lib/CreditTypes";
import { USD_CREDIT_TYPE, displayCreditTypeName } from "lib/credits";
import { InvoiceStatusEnum } from "types/generated-graphql/__types__";

const DATE_RANGES = {
  "3m": "Last 3 months",
  "6m": "Last 6 months",
  "12m": "Last 12 months",
  all: "All months",
};

type InvoiceDateRange = keyof typeof DATE_RANGES;
const DATE_RANGE_OPTIONS: { label: string; value: InvoiceDateRange }[] =
  Object.entries(DATE_RANGES).map(([value, label]) => ({
    label,
    value: value as InvoiceDateRange,
  }));

function ValueContainerWithCalendar<T>({
  children,
  ...props
}: ValueContainerProps<T>) {
  return (
    <components.ValueContainer
      {...props}
      className="!flex !flex-row !flex-nowrap !items-center"
    >
      <Icon icon="calendar" className="mr-4 h-12 w-24" />
      <div className="relative h-[20px] w-[150px]">{children}</div>
    </components.ValueContainer>
  );
}

interface State {
  invoiceDateRange: InvoiceDateRange;
  creditTypeId: string | undefined;
}

const INITIAL_STATE: State = {
  invoiceDateRange: "6m",
  creditTypeId: undefined,
};

function toDateRange(
  now: Dayjs,
  dateRange: InvoiceDateRange,
): { start: string | null; end: string | null } {
  switch (dateRange) {
    case "3m":
      return {
        start: now.subtract(3, "months").startOf("month").toISOString(),
        end: now.toISOString(),
      };
    case "6m":
      return {
        start: now.subtract(6, "months").startOf("month").toISOString(),
        end: now.toISOString(),
      };
    case "12m":
      return {
        start: now.subtract(12, "months").startOf("month").toISOString(),
        end: now.toISOString(),
      };
    case "all":
      return { start: null, end: null };
  }
}

export const CustomerBillingOverview: React.FC = () => {
  const now = useNow();
  const today = now.startOf("day");

  const [state, setState] = React.useState<State>(INITIAL_STATE);
  const customerId = useRequiredParam("customerId");

  const { start, end } = toDateRange(
    today.add(1, "day"),
    state.invoiceDateRange,
  );

  const req = useApolloResp(
    useCustomerBillingOverviewQuery({ variables: { id: customerId } }),
  );

  const recentInvoicesReq = useRecentCustomerInvoicesQuery({
    variables: {
      customerId,
      inclusiveStartDate: start,
      exclusiveEndDate: end,
    },
  });
  const recentInvoicesForSelectedCreditType =
    state.creditTypeId === "all"
      ? recentInvoicesReq.data?.Customer_by_pk?.recentInvoices.invoices ?? []
      : recentInvoicesReq.data?.Customer_by_pk?.recentInvoices.invoices.filter(
          (i) => i.credit_type.id === state.creditTypeId,
        ) ?? [];
  const hasNoInvoices =
    !recentInvoicesReq.loading &&
    recentInvoicesForSelectedCreditType.length === 0;

  const creditTypesFromRecentInvoices =
    recentInvoicesReq.data?.Customer_by_pk?.recentInvoices.invoices.map(
      (i) => i.credit_type,
    ) ?? [];
  const creditTypeOptions = hasNoInvoices
    ? [
        {
          label: displayCreditTypeName(USD_CREDIT_TYPE),
          value: USD_CREDIT_TYPE.id,
        },
      ]
    : Array.from(
        new Set(
          recentInvoicesReq.data?.Customer_by_pk?.recentInvoices.invoices.map(
            (i) => i.credit_type.id,
          ),
        ),
      ).map((ctId) => {
        const creditType = findCreditType(ctId, creditTypesFromRecentInvoices);
        return {
          label: displayCreditTypeName(creditType),
          value: ctId,
        };
      }) ?? [];
  const selectedCreditType =
    state.creditTypeId && state.creditTypeId !== "all"
      ? findCreditType(state.creditTypeId, creditTypesFromRecentInvoices)
      : undefined;

  const hasInvoicesToDisplayInBarChart =
    recentInvoicesForSelectedCreditType.length !== 0 &&
    recentInvoicesForSelectedCreditType.some(
      (i) => i.status !== InvoiceStatusEnum.Void,
    );
  const shouldDisplayBarChart =
    state.creditTypeId !== "all" &&
    !recentInvoicesReq.error &&
    (recentInvoicesReq.loading || hasInvoicesToDisplayInBarChart);

  useEffect(() => {
    if (
      recentInvoicesReq.data?.Customer_by_pk?.recentInvoices.invoices.length &&
      !state.creditTypeId
    ) {
      // Default to credit type of first recent invoice
      setState({
        ...state,
        creditTypeId:
          recentInvoicesReq.data.Customer_by_pk.recentInvoices.invoices[0]
            .credit_type.id,
      });
    }
  }, [recentInvoicesReq]);

  return (
    <CustomerLayout
      rootReq={req}
      breadcrumbs={({ customer }) =>
        Breadcrumbs.from(
          {
            type: "back",
            label: "Back to customer list",
            routePath: "/customers",
          },
          {
            label: customer.name,
            routePath: `/customers/${customer.id}`,
          },
        )
      }
      sidebar={({ customer }) => (
        <ActivityList
          loading={req.state === "loading"}
          title="Recent account activity"
          subtitle="Activity across this customer"
          className="m-12 ml-0"
          activity={
            customer
              ? [
                  ...customer.CustomerPlans.flatMap((plan) =>
                    ContractOrPlan.getActivityItems(plan, now),
                  ),
                  ...customer.contracts.flatMap((contract) =>
                    ContractOrPlan.getActivityItems(contract, now),
                  ),
                ]
              : []
          }
        />
      )}
      content={
        <div className="mt-12 flex flex-col gap-24">
          <div className="rounded-large border border-gray-light">
            <div className="flex items-center justify-between rounded-t-large bg-gray-lightest p-12">
              <div>
                <Subtitle>Recent invoices</Subtitle>
              </div>
              <div className="flex gap-12">
                <Select
                  options={DATE_RANGE_OPTIONS}
                  value={state.invoiceDateRange}
                  placeholder="Select a date range"
                  className="w-[150px]"
                  onChange={(v) => {
                    setState((prev) => ({
                      ...prev,
                      invoiceDateRange: v as InvoiceDateRange,
                      creditTypeId: undefined,
                    }));
                  }}
                  __internalComponentOverrides={{
                    ValueContainer: ValueContainerWithCalendar,
                  }}
                />
                <Select
                  options={
                    creditTypeOptions.length > 1
                      ? [
                          ...creditTypeOptions,
                          {
                            label: "All",
                            value: "all",
                          },
                        ]
                      : creditTypeOptions
                  }
                  value={
                    hasNoInvoices ? USD_CREDIT_TYPE.id : state.creditTypeId
                  }
                  placeholder=""
                  className="w-[150px]"
                  loading={recentInvoicesReq.loading}
                  disabled={
                    recentInvoicesReq.loading ||
                    !!recentInvoicesReq.error ||
                    hasNoInvoices
                  }
                  onChange={(v) => {
                    setState((prev) => ({
                      ...prev,
                      creditTypeId: v,
                    }));
                  }}
                />
              </div>
            </div>
            {shouldDisplayBarChart ? (
              <div className="p-12">
                <RecentInvoicesBarChart
                  loading={recentInvoicesReq.loading}
                  invoices={recentInvoicesForSelectedCreditType}
                  creditType={selectedCreditType}
                  dateRange={state.invoiceDateRange}
                />
              </div>
            ) : (
              ""
            )}
            <RecentInvoicesTable
              error={recentInvoicesReq.error}
              loading={recentInvoicesReq.loading}
              truncated={
                !!recentInvoicesReq.data?.Customer_by_pk?.recentInvoices.cursor
              }
              invoices={recentInvoicesForSelectedCreditType}
              defaultSortBy={[{ id: "date", desc: true }]}
            />
          </div>

          <ConnectedContractsAndPlans
            loading={req.state === "loading"}
            customer={req.state === "success" ? req.customer : undefined}
            defaultSortBy={[{ id: "dates", desc: true }]}
          />
        </div>
      }
    />
  );
};
