import React, { PropsWithChildren } from "react";

import formatDate from "date-fns/format";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";

import ReactDatePicker from "react-datepicker";

import styles from "./DateInput.module.css";
import "react-datepicker/dist/react-datepicker.css";
import * as Popper from "@popperjs/core";

import { Subtitle } from "../Typography";
import { InputContainer, InputContainerProps } from "./InputContainer";

import { twMerge } from "../../twMerge";
import { dayjs } from "lib/dayjs";
import { Button } from "../../../tenaissance/components/Button";
import { IconButton } from "../../../tenaissance/components/IconButton";

const Container: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

type DateInputProps = InputContainerProps & {
  onChange: (d: Date) => void;
  onBlur?: () => void;
  value?: Date;
  placeholder?: string;
  openToDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  placement?: Popper.Placement;
  isUTC?: boolean;
  className?: string;
};

export const DateInput: React.FC<DateInputProps> = (props) => {
  const fromUTC = (date?: Date) =>
    date !== undefined
      ? dayjs.utc(date).subtract(dayjs(date).utcOffset(), "minute").toDate()
      : date;
  const toUTC = (date: Date) =>
    dayjs.utc(date).add(dayjs(date).utcOffset(), "minute").toDate();

  const maybeFromUTC = (date?: Date) => (props.isUTC ? fromUTC(date) : date);
  const maybeToUTC = (date: Date) => (props.isUTC ? toUTC(date) : date);

  return (
    <InputContainer {...props} leftIcon="calendar">
      {/* wrap in div with e.preventDefault due to https://github.com/Hacker0x01/react-datepicker/issues/1012 */}
      <div
        onClick={(e) => e.preventDefault()}
        className={twMerge(styles.datepickerWrapper, props.className)}
      >
        <ReactDatePicker
          disabled={props.disabled}
          placeholderText={props.placeholder}
          popperClassName={styles.popper}
          popperPlacement={props.placement ?? "bottom-end"}
          popperProps={{ positionFixed: true }}
          onChange={(date) =>
            date instanceof Date && props.onChange(maybeToUTC(date))
          }
          onBlur={props.onBlur}
          onCalendarClose={props.onBlur}
          selected={maybeFromUTC(props.value)}
          calendarContainer={Container}
          weekDayClassName={() => styles.weekdayLabel}
          dayClassName={() => styles.day}
          formatWeekDay={(weekDay) => weekDay.slice(0, 1)}
          openToDate={maybeFromUTC(props.value || props.openToDate)}
          minDate={maybeFromUTC(props.minDate)}
          maxDate={maybeFromUTC(props.maxDate)}
          shouldCloseOnSelect
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            changeMonth,
            changeYear,
          }) => (
            <div className={styles.header}>
              <Subtitle level={3}>{formatDate(date, "MMMM yyyy")}</Subtitle>
              <div className={styles.monthButtons}>
                <IconButton
                  className={styles.monthButton}
                  onClick={decreaseMonth}
                  icon="chevronLeft"
                  theme="tertiary"
                />
                <Button
                  className={styles.monthButton}
                  onClick={() => {
                    changeMonth(getMonth(new Date()));
                    changeYear(getYear(new Date()));
                  }}
                  theme="tertiary"
                  text="•"
                />
                <IconButton
                  className={styles.monthButton}
                  onClick={increaseMonth}
                  icon="chevronRight"
                  theme="tertiary"
                />
              </div>
            </div>
          )}
        />
      </div>
    </InputContainer>
  );
};
