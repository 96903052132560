import React from "react";

import { Breadcrumbs } from "lib/breadcrumbs";
import { BillingProviderSection } from "pages/Customer/tabs/Settings/sections/BillingProvider";
import { IdentifierSection } from "pages/Customer/tabs/Settings/sections/Identifiers";

import { useCustomerFromRoute } from "../../lib/Customer";
import { CustomerLayout } from "../CustomerLayout";

export const CustomerSettings: React.FC = () => {
  const req = useCustomerFromRoute();

  return (
    <CustomerLayout
      rootReq={req}
      breadcrumbs={({ customer }) =>
        Breadcrumbs.from(
          {
            type: "back",
            label: "Back to customer list",
            routePath: "/customers",
          },
          {
            label: customer.name,
            routePath: `/customers/${customer.id}`,
          },
        )
      }
      content={({ customer }) => (
        <div className="flex h-full flex-col pt-12">
          <IdentifierSection customerId={customer.id} />
          <BillingProviderSection customerId={customer.id} />
        </div>
      )}
    />
  );
};
