import React, { ReactNode } from "react";
import { useInRouterContext, Link } from "react-router-dom";

import { Popover } from "react-tiny-popover";
import classNames from "classnames";
import { Tooltip } from "../Tooltip";
import { IconName } from "../Icon";
import { useDesignSystemConfig } from "../../config";
import { Deprecated_Button, Deprecated_ButtonThemes } from "../Button";

export type ButtonDropdownItem = {
  label: string | React.ReactElement;
  onClick?: () => void;
  routePath?: string;
  hidden?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
};

interface CommonButtonDropdownProps {
  items: ButtonDropdownItem[];
  buttonType: "fill" | "outline" | "link";
  buttonTheme: Deprecated_ButtonThemes;
  disabled?: boolean;
  /** Which side of the button should the dropdown align with? Defaults to "end" */
  align?: "start" | "end";
  className?: string;
}

export interface ButtonDropdownProps extends CommonButtonDropdownProps {
  title?: string | ReactNode;
  icon?: IconName;
  faket10?: boolean;
}

const Item: React.FC<{ item: ButtonDropdownItem; className: string }> = ({
  item,
  className,
}): React.ReactElement => {
  const { routePathToHref } = useDesignSystemConfig();
  const inRouterContext = useInRouterContext();

  const href =
    item.disabled || !item.routePath
      ? undefined
      : routePathToHref(item.routePath);

  const onClickOrKeyDown = item.disabled
    ? undefined
    : (e: React.MouseEvent | React.KeyboardEvent) => {
        if (
          !item.onClick ||
          ("key" in e && !(e.key === "Enter" || e.key === " "))
        ) {
          return;
        }

        e.preventDefault();
        item.onClick();
      };

  return href && inRouterContext ? (
    <Link to={href} className={classNames("block", className)} tabIndex={0}>
      {item.label}
    </Link>
  ) : (
    <div
      onClick={onClickOrKeyDown}
      onKeyDown={onClickOrKeyDown}
      className={classNames(className)}
      tabIndex={0}
    >
      {item.label}
    </div>
  );
};

function getDomState(container: HTMLDivElement) {
  const els = Array.from(container.querySelectorAll('[tabindex="0"]')).flatMap(
    (el) => (el instanceof HTMLElement ? el : []),
  );
  const focusI = els.findIndex((el) => el === document.activeElement);
  return { els, focusI };
}

export const ButtonDropdown: React.FC<ButtonDropdownProps> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const openedWithKeyboard = React.useRef(false);

  const keyboardNav = (e: React.KeyboardEvent) => {
    e.preventDefault();

    if (
      !isOpen &&
      (e.key === "Enter" || e.key === "ArrowDown" || e.key === " ")
    ) {
      openedWithKeyboard.current = true;
      setIsOpen(true);
      return;
    }

    if (!divRef.current) {
      return;
    }

    switch (e.key) {
      case "ArrowDown": {
        const { els, focusI } = getDomState(divRef.current);
        // get the value at `focusI + 1`, and wrap around to
        // the first item if we are at the end of the list
        els.at((focusI + 1) % els.length)?.focus();
        break;
      }
      case "ArrowUp": {
        const { els, focusI } = getDomState(divRef.current);
        // if there is no focused element, start at the bottom of the list
        // otherwise get the value at `focusI - 1`. .at() will wrap around
        // to the last item if we pass -1
        els.at(focusI === -1 ? -1 : focusI - 1)?.focus();
        break;
      }
      case "Escape":
        setIsOpen(false);
        divRef.current.focus();
        break;
    }
  };

  return (
    <Popover
      isOpen={isOpen}
      positions={["bottom"]}
      align={props.align ?? "end"}
      onClickOutside={() => setIsOpen(false)}
      padding={8}
      content={({ childRect }) => {
        return (
          <div
            tabIndex={0}
            ref={(el) => {
              divRef.current = el;
              if (openedWithKeyboard.current) {
                openedWithKeyboard.current = false;
                setTimeout(() => {
                  if (
                    el &&
                    document.body.contains(el) &&
                    !el.contains(document.activeElement)
                  ) {
                    el.focus();
                  }
                }, 0);
              }
            }}
            className="rounded-medium bg-white pb-4 pt-4 shadow-lg"
            style={{ minWidth: `${Math.min(childRect.width, 150)}px` }}
            onKeyDown={keyboardNav}
          >
            {props.items.map((item, i) => {
              if (item.hidden) {
                return null;
              }

              const el = (
                <Item
                  className={classNames(
                    "px-12 py-8 text-xs leading-1",
                    item.disabled
                      ? "cursor-not-allowed text-grey-300"
                      : "cursor-pointer hover:bg-grey-50 focus:bg-primary-50 focus:text-primary-600 focus:outline-none",
                  )}
                  item={{
                    ...item,
                    onClick: item.onClick
                      ? () => {
                          setIsOpen(false);
                          item.onClick?.();
                        }
                      : undefined,
                  }}
                />
              );

              return item.disabled && item.disabledTooltip ? (
                <Tooltip content={item.disabledTooltip} key={`item-${i}`}>
                  {el}
                </Tooltip>
              ) : (
                <React.Fragment key={`item-${i}`}>{el}</React.Fragment>
              );
            })}
          </div>
        );
      }}
    >
      <Deprecated_Button
        type={props.buttonType}
        theme={props.buttonTheme ?? "gray"}
        disabled={props.disabled}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className={classNames(
          !props.icon && "pr-12",
          isOpen && [
            `outline outline-[3px]`,
            props.buttonTheme === "primary" && `outline-primary-100`,
            props.buttonTheme === "gray" && `outline-gray-100`,
            props.buttonTheme === "error" && `outline-error-100`,
          ],
          props.faket10 && [
            "bg-core-slate h-40 hover:bg-gray-950 rounded-l-large rounded-r-large font-semibold ",
          ],
          props.className,
        )}
        icon={props.icon ?? (isOpen ? "caretUp" : "caretDown")}
        iconEdge="trailing"
        children={
          !props.title ? null : (
            <span
              onKeyDown={keyboardNav}
              tabIndex={0}
              className="flex items-center"
            >
              {props.title}
            </span>
          )
        }
      />
    </Popover>
  );
};
