import React, { ReactNode } from "react";
import ReactSelect, {
  components,
  Props as ReactSelectProps,
  MenuPlacement,
} from "react-select";

import { twMerge } from "../../twMerge";
import { Label } from "../Typography";
import { Icon } from "../Icon";
import { HelpCircleTooltip } from "../HelpCircleTooltip";
import styles from "./index.module.css";

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon="caretDown" className={styles.dropdownIndicator} />
    </components.DropdownIndicator>
  );
};

const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props} className={styles.multiValueRemove}>
      <Icon icon="closeCircle" className="fill-primary-600" />
    </components.MultiValueRemove>
  );
};

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props} className={styles.clearIndicator}>
      <Icon icon="closeCircle" />
    </components.ClearIndicator>
  );
};

export type Option = {
  label: string | ReactNode;
  value: string;
  disabled?: boolean;
};

export type OptGroup = {
  label: string;
  options: Option[];
};

export type Options = (Option | OptGroup)[];

// These are the components of ReactSelect that we override to customize the styles/behavior
const ComponentOverrides = {
  ClearIndicator: () => null,
  IndicatorSeparator: () => null,
  MultiValueRemove,
  DropdownIndicator,
};

// These are the remaining components of ReactSelect that we don't override (so it's safe for consumers to override them)
type ExtendableComponents = Omit<
  ReactSelectProps["components"],
  keyof typeof ComponentOverrides
>;

type SelectProps = {
  clearable?: boolean;
  name?: string;
  tooltip?: string | JSX.Element;
  className?: string;
  error?: string | boolean;
  placeholder: string;
  disabled?: boolean;
  autoFocus?: boolean;
  loading?: boolean;
  options: Options;
  noOptionsMessage?: string;
  onSearch?: (search: string) => void;
  menuPlacement?: MenuPlacement;
  defaultValue?: Option | OptGroup;
  __internalComponentOverrides?: ExtendableComponents;
} & Pick<ReactSelectProps, "onBlur" | "onFocus" | "menuPosition"> &
  (
    | {
        multiSelect?: false;
        onChange: (value: string) => void;
        value?: string;
      }
    | {
        multiSelect: true;
        onChange: (value: string[]) => void;
        value: string[];
      }
  );

export const Select: React.FC<SelectProps> = (props) => {
  const options = props.loading ? undefined : props.options;
  const selectedOptionIDs = props.multiSelect ? props.value : [props.value];

  const flatOptions = (options ?? []).flatMap((o) =>
    "options" in o ? o.options : o,
  );

  const selectedOptions = selectedOptionIDs.reduce<Option[]>(
    (selectedOpts, optId) => {
      const matchingOption = flatOptions.find((o) => o.value === optId);
      if (matchingOption) {
        selectedOpts.push(matchingOption);
      }
      return selectedOpts;
    },
    [],
  );

  const disabledOptions = flatOptions
    .filter((o) => o.disabled)
    .map((o) => o.value);

  return (
    <Label
      className={twMerge(
        styles.select,
        props.error ? styles.hasError : null,
        props.disabled ? styles.disabled : null,
        props.className,
      )}
    >
      {(props.name || props.tooltip) && (
        <div className={styles.name}>
          {props.name}
          {props.tooltip && <HelpCircleTooltip content={props.tooltip} />}
        </div>
      )}
      <ReactSelect
        menuPlacement={props.menuPlacement}
        menuPosition={props.menuPosition}
        isMulti={props.multiSelect}
        isClearable
        classNamePrefix="select"
        placeholder={props.placeholder}
        noOptionsMessage={() => props.noOptionsMessage ?? null}
        components={{
          ...(props.__internalComponentOverrides as any),
          ...ComponentOverrides,
          ...(props.clearable
            ? { ClearIndicator }
            : { ClearIndicator: () => null }),
        }}
        filterOption={(option, needle) => {
          // If the caller has specified an onSearch function, we assume that the caller will do the filtering, so we always return true,
          // however if onSearch is not specified, we use the default filtering function.
          if (props.onSearch) {
            return true;
          }
          // If label is a ReactNode/non string we can't filter on it, so we return true
          if (typeof option.label !== "string") {
            return true;
          }

          needle = needle.toLowerCase().trim();
          return (
            option.value.toLowerCase() === needle ||
            option.label.toLowerCase().includes(needle)
          );
        }}
        onInputChange={props.onSearch}
        options={options}
        // must pass 'null' instead of 'undefined' to clear previously selected value
        value={
          (props.multiSelect ? selectedOptions : selectedOptions[0]) ??
          (props.defaultValue ? undefined : null)
        }
        onChange={(o) => {
          if (props.multiSelect) {
            props.onChange(((o as Option[]) || []).map((o) => o.value));
          } else {
            props.onChange(o ? (o as any).value : null);
          }
        }}
        isDisabled={props.disabled}
        isOptionDisabled={(o) => {
          if ("options" in o) {
            return false;
          }
          return disabledOptions.includes(o.value);
        }}
        name={props.name}
        autoFocus={props.autoFocus}
        isLoading={props.loading}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        defaultValue={props.defaultValue}
      />
      {!!props.error && typeof props.error === "string" && (
        <div className={styles.error}>{props.error}</div>
      )}
    </Label>
  );
};
