import React from "react";
import styles from "../../index.module.less";
import { HelpCircleTooltip } from "design-system";

interface StripeSettingProps extends React.PropsWithChildren {
  setting: React.ReactNode;
  helpText: string;
}
export const StripeSetting: React.FC<StripeSettingProps> = (props) => {
  return (
    <div className={styles.settingContainer}>
      <div className={styles.setting}>
        {props.setting}
        {props.helpText && <HelpCircleTooltip content={props.helpText} />}
      </div>
      {props.children}
    </div>
  );
};
