import React, { useState } from "react";

import { Input } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";

import { Body } from "design-system";
import { useSnackbar } from "components/Snackbar";
import { Select } from "design-system";

import {
  useUpdateStripeBillingProviderCustomerMutation,
  useGetStripeCustomerQuery,
  useIsDeltaStreamEnabledQuery,
  useInsertCustomerBillingProviderConfigurationMutation,
} from "./queries.graphql";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";
import { ApolloCache } from "@apollo/client";

export interface CustomerSettingsWritePlansAndOrContracts {
  // Support either:
  // 1) writing BPCustomer and CustomerBPConfig upon setting up the
  //   first integration for a customer, setting the default BP for the plan and
  //   allowing new contracts to attach the same BP configuration OR
  // 2) only writing a new CustomerBPConfig when setting up a new integration
  //    for a contract on a customer that already has a BPCustomer or
  //    CustomerBPConfig
  plansAndOrContracts?: "plans_and_contracts" | "contracts_only";
}

type StripeSettingsModalProps = {
  onClose: (
    deleteConfig?: boolean,
    status?: {
      isSuccess: true;
    },
  ) => void;
  customerId: string;
  edit?: boolean;
  stripeCustomerID?: string;
  stripeCollectionMethod?: string;
} & CustomerSettingsWritePlansAndOrContracts;

const evictCacheOptions = {
  update(cache: ApolloCache<any>) {
    cache.evict({
      fieldName: "stripe_billing_provider_configs",
    });
    cache.evict({
      fieldName: "customer_billing_provider_configurations",
    });
    cache.evict({
      fieldName: "BillingProviderCustomer",
    });
    cache.evict({
      fieldName: "CustomerConfig",
    });
    cache.evict({
      fieldName: "Customer_aggregate",
    });
  },
};

export const PLANS_ONLY_EDITING_MESSAGE =
  "NOTE: Editing configuration only applies to plans, configuration for contracts will remain unchanged.";

export const StripeSettingsModal: React.FC<StripeSettingsModalProps> = ({
  onClose,
  customerId,
  edit = false,
  stripeCustomerID = "",
  stripeCollectionMethod = "charge_automatically",
  plansAndOrContracts = "plans_and_contracts",
}) => {
  const { environmentType } = useEnvironment();
  const pushMessage = useSnackbar();
  const [updatedStripeCustomerId, setUpdatedStripeCustomerId] = useState<
    string | null
  >(edit ? stripeCustomerID : null);
  const [updatedStripeCollectionMethod, setUpdatedStripeCollectionMethod] =
    useState<string | null>(edit ? stripeCollectionMethod : null);
  const [updateSettings] =
    useUpdateStripeBillingProviderCustomerMutation(evictCacheOptions);
  const [updateSettingsContractsOnly] =
    useInsertCustomerBillingProviderConfigurationMutation(evictCacheOptions);
  const { data, loading } = useGetStripeCustomerQuery({
    skip: updatedStripeCustomerId === null,
    variables: {
      environment_type: environmentType,
      stripe_customer_id: updatedStripeCustomerId ?? "",
    },
  });

  const isDeltaStreamEnabled =
    useIsDeltaStreamEnabledQuery().data?.is_delta_stream_enabled;

  const updateSettingsAction = async () => {
    if (updatedStripeCustomerId && updatedStripeCollectionMethod) {
      if (
        isDeltaStreamEnabled ||
        (plansAndOrContracts === "contracts_only" && !edit)
      ) {
        await updateSettingsContractsOnly({
          variables: {
            customer_id: customerId,
            configuration: {
              stripe_customer_id: updatedStripeCustomerId,
              stripe_collection_method: updatedStripeCollectionMethod,
            },
          },
        });
      } else {
        // write to both plans and contracts tables
        await updateSettings({
          variables: {
            billing_provider_customer_id: updatedStripeCustomerId,
            stripe_collection_method: updatedStripeCollectionMethod,
            customer_id: customerId,
          },
        });
        await updateSettingsContractsOnly({
          variables: {
            customer_id: customerId,
            configuration: {
              stripe_customer_id: updatedStripeCustomerId,
              stripe_collection_method: updatedStripeCollectionMethod,
            },
          },
        });
      }
      pushMessage({
        content: "Stripe configuration saved",
        type: "success",
      });

      onClose(undefined, {
        isSuccess: true,
      });
    }
  };
  const actionButtons = (
    <>
      {edit && (
        <Button
          key="delete"
          onClick={() => onClose(true)}
          text="Disable Stripe"
          theme="linkGray"
        />
      )}
      <Button
        key="primary"
        disabled={!updatedStripeCustomerId || !updatedStripeCollectionMethod}
        onClick={updateSettingsAction}
        text={edit ? "Save changes" : "Add Stripe"}
        theme="primary"
      />
    </>
  );

  return (
    <Popup
      actions={actionButtons}
      isOpen={true}
      onRequestClose={() => onClose(false)}
      title="Stripe configuration"
    >
      <Body level={2}>
        {edit
          ? "Configuration details. " + PLANS_ONLY_EDITING_MESSAGE
          : `To add the Stripe profile you need the customer’s associated Stripe customer ID. Once entered we’ll display the customer name.${
              environmentType === EnvironmentTypeEnum_Enum.Sandbox
                ? " This is a sandbox customer so it can only be linked to a Stripe test mode account."
                : ""
            }`}
      </Body>
      <Input
        name="Stripe ID"
        placeholder="Enter Stripe customer ID"
        value={updatedStripeCustomerId ?? ""}
        onChange={setUpdatedStripeCustomerId}
        autoFocus
        success={data?.stripeCustomer?.name != null}
        error={
          updatedStripeCustomerId != null &&
          !loading &&
          data?.stripeCustomer?.name == null
            ? "Enter a valid Stripe ID"
            : undefined
        }
      />
      <Body level={2}>{data?.stripeCustomer?.name ?? ""}</Body>
      <Select
        name="Collection Method"
        options={[
          { label: "Charge automatically", value: "charge_automatically" },
          { label: "Send invoice", value: "send_invoice" },
        ]}
        onChange={setUpdatedStripeCollectionMethod}
        value={updatedStripeCollectionMethod ?? ""}
        placeholder="Select"
      />
    </Popup>
  );
};

export default StripeSettingsModal;
