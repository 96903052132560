import React, { useState } from "react";
import { SideSheet } from "tenaissance/components/SideSheet";
import { renderDate } from "lib/time";
import { Button } from "tenaissance/components/Button";
import { useSetupAwsMarketplaceCredentialsMutation } from "./queries.graphql";
import { useSnackbar } from "components/Snackbar";
import { TextInput } from "tenaissance/components/Input";
import { handleCopyClick } from "pages/GeneralSettings/hooks/copyToken";
import { BadgeGroup } from "../../../../tenaissance/components/BadgeGroup";
import { useEnvironment } from "../../../../lib/environmentSwitcher/context";
import { useCurrentUser } from "../../../../lib/auth";
import {
  EnvironmentTypeEnum_Enum,
  BillingProviderEnum_Enum,
} from "../../../../types/generated-graphql/__types__";
import { v5 as uuidV5 } from "uuid";

const AWS_DOCS_LINK =
  "https://docs.metronome.com/invoicing/how-invoicing-works/invoicing-with-aws-marketplace";

interface AWSSideSheetProps {
  onClose: () => void;
  isOpen: boolean;
  connectedOn?: Date;
  awsIAMRoleARN?: string;
  awsExternalID?: string;
}

function deterministicallyGenerateAWSExternalID(
  clientID: string,
  environmentType: EnvironmentTypeEnum_Enum,
) {
  const billingProvider = BillingProviderEnum_Enum.AwsMarketplace;
  return uuidV5(`${billingProvider}|${environmentType}`, clientID);
}

export const AWSSideSheet = ({
  onClose,
  isOpen,
  connectedOn,
  awsIAMRoleARN,
  awsExternalID: existingAWSExternalID,
}: AWSSideSheetProps) => {
  const { clientID, loading: currentUserLoading } = useCurrentUser();
  const { environment } = useEnvironment();
  const environmentType = environment.type;
  const pushMessage = useSnackbar();
  const [newAWSIAMRoleARN, setNewAWSIAMRoleARN] = useState(awsIAMRoleARN ?? "");
  const [setupAWSMarketplaceCredentialsMutation, { loading }] =
    useSetupAwsMarketplaceCredentialsMutation({
      update(cache) {
        cache.evict({
          fieldName: "BillingProviderToken",
        });
        cache.evict({
          fieldName: "BillingProviderCustomer",
        });
      },
    });

  const setupAWSMarketplaceCredentials = async (
    awsExternalID: string,
    awsIAMRoleARN: string,
  ) => {
    try {
      if (!awsExternalID || !awsIAMRoleARN) {
        return;
      }
      await setupAWSMarketplaceCredentialsMutation({
        variables: {
          external_id: awsExternalID,
          iam_role_arn: newAWSIAMRoleARN,
        },
      });
      pushMessage({
        content: "AWS Marketplace has been enabled",
        type: "success",
      });
      onClose();
    } catch (e: any) {
      pushMessage({
        content: e.message,
        type: "error",
      });
    }
  };

  const authLoading = !clientID || !environmentType;
  const disableSave =
    !newAWSIAMRoleARN || loading || currentUserLoading || authLoading;

  const generatedAWSExternalID = authLoading
    ? ""
    : deterministicallyGenerateAWSExternalID(clientID, environmentType);

  // If AWS is already setup, use the external ID in the DB, otherwise generate one
  const awsExternalID = existingAWSExternalID || generatedAWSExternalID;

  const trailingActionButtons:
    | [React.ReactElement, React.ReactElement]
    | undefined = [
    <Button
      key="save"
      text="Save Changes"
      disabled={disableSave}
      onClick={async () => {
        await setupAWSMarketplaceCredentials(awsExternalID, newAWSIAMRoleARN);
      }}
    />,
    <Button key="cancel" text="Cancel" theme="secondary" onClick={onClose} />,
  ];

  return (
    <SideSheet
      title="Connect AWS Marketplace"
      onClose={onClose}
      isOpen={isOpen}
      supportingText={
        connectedOn
          ? `Connected on: ${renderDate(connectedOn, { isUtc: true, excludeUtcLabel: true })}`
          : ""
      }
      trailingActions={trailingActionButtons}
    >
      <div className="leading-tight grid items-start gap-[24px] self-stretch font-['Inter'] text-sm font-normal ">
        <div className="w-96 text-gray-600">
          The AWS marketplace enables qualified partners to market and sell
          their software to AWS customers.
        </div>

        <div className="h-14 w-96 text-core-slate grid gap-[12px]">
          <div className="font-semibold">Get started</div>
          <div>
            To setup AWS marketplace you’ll first need to create your
            marketplace listing inside AWS.{" "}
            <a className="underline" href={AWS_DOCS_LINK} target="_blank">
              Learn more
            </a>{" "}
            about setting up your listing.
          </div>
        </div>

        <div className="w-96 text-core-slate grid gap-[12px]">
          <div className="font-semibold">
            1. Link Metronome to your marketplace listing
          </div>
          <div>
            This unique ID should be used in the External ID field during the
            AWS role creator phase of the set up flow inside AWS.
          </div>
          <div>
            Navigate to the <span className="underline">role creator</span>.
            Choose Another AWS account. Enter Metronome's account ID and enter
            the external ID below. Keep Require MFA unchecked.
          </div>
          <div>
            <BadgeGroup
              mainLabel="Copy External ID"
              badgeLabel={awsExternalID}
              onClick={async () => {
                await handleCopyClick(awsExternalID, (_) => {});
                pushMessage({
                  content: "Copied External ID",
                  type: "success",
                });
              }}
              icon="copy01"
            ></BadgeGroup>
          </div>
        </div>

        <div className="text-core-slate w-96 grid gap-[12px]">
          <div className="font-semibold">2. Add your AWS IAM Role ARN</div>
          <div>
            Once you’ve completed creating your new AWS role, you’ll be given an
            ARN. This key is needed to finalize setting up your marketplace
            integration.
          </div>
          <TextInput
            disabled={false}
            placeholder="Enter AWS IAM Role ARN"
            value={newAWSIAMRoleARN}
            label="AWS IAM Role ARN"
            onChange={(meta: { value: string }) =>
              setNewAWSIAMRoleARN(meta.value)
            }
            hintText="Your AWS ARN found in the IAM role creator"
            fullWidth={true}
          />
        </div>
      </div>
    </SideSheet>
  );
};
