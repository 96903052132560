import React, { ReactElement, useMemo } from "react";
import classnames from "classnames";
import { formatDeliveryMethod } from "lib/billingProvider/formatDeliveryMethod";
import { renderDateTimeInUTC } from "lib/time";
import { Badge, Subtitle, Body, Tooltip } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Statistic } from "components/Statistic";
import { CopyableID } from "components/CopyableID";
import { subHours } from "date-fns";
import { BillingProviderDeliveryMethod_Enum } from "types/generated-graphql/__types__";

interface TokenID {
  id?: string;
  label?: string;
}

interface ButtonOptions {
  buttonText?: string;
  buttonOnClick: () => void;
}

interface CustomerMarketplaceCardProps {
  additionalActiveParams?: boolean[];
  integrationId?: string;
  isConnected?: boolean;
  isDeltaStream?: boolean;
  expirationTimestamp?: Date;
  buttonOptions?: ButtonOptions;
  connectionDate?: Date;
  logo: ReactElement;
  meteringDisabled?: boolean;
  name: string;
  tokenIDs?: TokenID[];
  deliveryMethods?: BillingProviderDeliveryMethod_Enum[];
}
export const CustomerMarketplaceCard: React.FC<
  CustomerMarketplaceCardProps
> = ({
  additionalActiveParams,
  integrationId,
  isConnected,
  isDeltaStream,
  buttonOptions,
  connectionDate,
  expirationTimestamp: propExpirationTimestamp,
  logo,
  meteringDisabled,
  name,
  tokenIDs,
  deliveryMethods = [],
}) => {
  const now = new Date();
  const expirationTimestamp = propExpirationTimestamp || now;
  const expirationPrefix =
    expirationTimestamp <= now ? "Contract expired on" : "Contract expires on";
  const isActive =
    isDeltaStream ||
    (expirationTimestamp > subHours(now, 2) &&
      !meteringDisabled &&
      (additionalActiveParams?.reduce(
        (accumulator, currentValue) => accumulator && currentValue,
        true,
      ) ??
        true));

  const showButtonOptions = !isDeltaStream && buttonOptions;
  const showTokenIDs = !isDeltaStream && tokenIDs && tokenIDs.length > 0;

  const formattedDeliveryMethods = deliveryMethods.map((method) =>
    formatDeliveryMethod(method),
  );

  const ConfigureButton = useMemo(
    () =>
      buttonOptions && (
        <Button
          className={classnames("ml-8", !integrationId && "self-center")}
          onClick={buttonOptions.buttonOnClick}
          disabled={!!integrationId}
          text={buttonOptions.buttonText ?? "Configure"}
          theme="secondary"
        />
      ),
    [buttonOptions, integrationId],
  );

  return (
    <div
      className={classnames(
        "relative w-full rounded-medium border border-gray-100 p-12",
        !!tokenIDs && "pb-[7px]",
      )}
    >
      <div className="flex">
        <div className="mr-12 h-[50px] w-[50px] rounded-small bg-[#f4f5f7]">
          {logo}
        </div>
        <div className="flex grow flex-col justify-center">
          <Subtitle level={4} className="font-medium">
            {name}
          </Subtitle>
          {isConnected ? (
            <>
              <Body
                level={2}
                className="mb-0 min-w-[285px] font-normal text-grey-800"
              >
                {connectionDate
                  ? `Connected on ${renderDateTimeInUTC(new Date(connectionDate), false)} | ${expirationPrefix} ${renderDateTimeInUTC(expirationTimestamp, false)}`
                  : isDeltaStream
                    ? "Connected"
                    : "Not connected"}
              </Body>
            </>
          ) : (
            <Body level={2} className="mb-0">
              Not connected
            </Body>
          )}
        </div>
        {isConnected && (
          <div className="flex flex-col self-center">
            <Badge theme={isActive ? "success" : "grey"} type="light">
              {isActive ? "ACTIVE" : "INACTIVE"}
            </Badge>
          </div>
        )}
        {showButtonOptions &&
          (integrationId ? (
            <Tooltip content="Contact Metronome to edit">
              {ConfigureButton}
            </Tooltip>
          ) : (
            ConfigureButton
          ))}
      </div>

      {showTokenIDs && (
        <div className="mt-12 flex flex-col lg:flex-row">
          {tokenIDs.map(
            ({ id, label }) =>
              id && (
                <Statistic
                  className="mb-4 mr-12 lg:mb-0"
                  key={id}
                  caption={label ?? "ID"}
                >
                  <CopyableID id={id} label="" />
                </Statistic>
              ),
          )}
        </div>
      )}

      {isDeltaStream && (
        <Subtitle level={4} className="mt-12 text-grey-700">
          {`Delivery method for ${name} is via
          ${formattedDeliveryMethods.length > 0 ? formattedDeliveryMethods.join(", ") : "unknown"}.`}
        </Subtitle>
      )}
    </div>
  );
};
