import React from "react";

import { PageContainer } from "components/PageContainer";
import { ParagraphSkeleton } from "components/Skeleton";

import { useNavigate } from "lib/useNavigate";
import { useSearchParam } from "lib/routes/useSearchParam";
import { useRequiredParam } from "lib/routes/params";
import { Breadcrumbs } from "lib/breadcrumbs";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";

import { useApolloResp } from "pages/Contracts/lib/ApolloResp";
import NotFoundPage from "pages/404";

import { CommitCard, parseCommitTab } from "./CommitCard";
import { useAsyncAllCommitUsage } from "../Usage";

import { useAllCommitsQuery } from "./data.graphql";
import { ExternalCommitType } from "types/generated-graphql/__types__";

export const CustomerCommitOverview: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const commitId = useRequiredParam("commitId");

  const navigate = useNavigate();
  const activeTabParam = parseCommitTab(useSearchParam("tab")[0]);

  const req = useApolloResp(
    useAllCommitsQuery({
      variables: { customerId },
    }),
  );
  const asyncUsage = useAsyncAllCommitUsage({ customerId });

  React.useEffect(() => {
    if (req.state === "success") {
      const commit = req.customer.commits.find((c) => c.id === commitId);
      if (commit?.contract?.id) {
        navigate(
          `/customers/${customerId}/contracts/${commit.contract.id}/commits-and-credits`,
          {
            replace: true,
          },
        );
      }
    }
  }, [commitId, req]);

  if (req.state === "error") {
    return (
      <PageContainer title="Failed to load customer" hideHeader>
        <ErrorEmptyState
          title="We ran into an error loading this customer"
          error={req.error}
        />
      </PageContainer>
    );
  }

  if (req.state === "loading") {
    return <ParagraphSkeleton numLines={6} className="w-4/5 xl:w-3/4" />;
  }

  if (req.state === "not found") {
    return <NotFoundPage />;
  }

  const customer = req.customer;
  const commit = customer.commits.find((c) => c.id === commitId);
  const routePath = `/customers/${customerId}/commits-and-credits/${commitId}`;
  if (!commit) {
    return <NotFoundPage />;
  }

  return (
    <PageContainer
      title={Breadcrumbs.from(
        {
          type: "back",
          label: "Back to customer list",
          routePath: "/customers",
        },
        {
          label: customer.name,
          routePath: `/customers/${customer.id}/commits-and-credits`,
        },
        {
          label:
            commit.name ??
            commit.product.initial.name ??
            (commit.__typename === "PrepaidCommit" &&
            commit.external_type === ExternalCommitType.Credit
              ? "Credit"
              : "Commit"),
          routePath,
        },
      )}
    >
      <CommitCard
        key={commit.id}
        commit={commit}
        focus={commit.id === commitId}
        activeTab={activeTabParam || "ledger"}
        asyncUsage={asyncUsage}
        onTabChange={(tab) => {
          navigate(
            `/customers/${customerId}/commits-and-credits/${commit.id}?tab=${tab}`,
            {
              replace: true,
            },
          );
        }}
      />
    </PageContainer>
  );
};
