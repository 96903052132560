import React, { Dispatch, SetStateAction } from "react";
import { BadgeGroup } from "tenaissance/components/BadgeGroup";
import { useGetSalesforceDestinationLinkMutation } from "./queries.graphql";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { SalesforceCensusBadgeProps } from "./CreateCensusWorkspaceStep";

interface ActivateSalesforceDestinationStepProps {
  isWorkspaceCreated: boolean;
  isSalesforceDestinationLinked: boolean;
  setIsSalesforceDestinationLinked: Dispatch<SetStateAction<boolean>>;
}
export const ActivateSalesforceDestinationStep: React.FC<
  ActivateSalesforceDestinationStepProps
> = ({
  isWorkspaceCreated,
  isSalesforceDestinationLinked,
  setIsSalesforceDestinationLinked,
}) => {
  const { prefixUrl } = useEnvironment();
  const [
    getSalesforceDestinationLinkMutation,
    {
      data: getSalesforceDestinationLinkData,
      loading: salesforceDestinatonLinkLoading,
      error: salesforceDestinationLinkError,
    },
  ] = useGetSalesforceDestinationLinkMutation();

  const createSalesforceDestinationLink = async () => {
    try {
      if (!isWorkspaceCreated) return;
      const result = await getSalesforceDestinationLinkMutation({
        variables: {
          redirect_uri: `${window.location.origin}${prefixUrl(
            "/settings/general/integrations?salesforce_redirect_callback=true",
          )}`,
        },
      });
      const uri = result?.data?.generate_census_destination_connect_link.uri;
      if (uri) {
        // We want to redirect the user instead of opening a new tab so that the user
        // doesn't have multiple tabs open to the onboarding form. Since we don't want
        // them clicking the generate link button multiple times we redirect them to
        // Census and then back to the onboarding form after they link Salesforce.
        window.location.href = uri;
      }
    } catch (e) {
      setIsSalesforceDestinationLinked(false);
    }
  };

  const getBadgeProps = (): SalesforceCensusBadgeProps => {
    if (salesforceDestinationLinkError) {
      return {
        badgeGroupMainLabel:
          "Failed to link Salesforce. Click to refresh and try again.",
        badgeGroupIcon: "arrowNarrowRight",
        badgeTheme: "error",
        onClick: async () =>
          (window.location.href = prefixUrl(
            "/settings/general/integrations?salesforce_redirect_callback=true",
          )),
      };
    } else if (
      salesforceDestinatonLinkLoading ||
      getSalesforceDestinationLinkData?.generate_census_destination_connect_link
        .uri
    ) {
      return {
        badgeGroupMainLabel: "Generating Salesforce link",
        badgeGroupIcon: "loading02",
        badgeTheme: "gray",
        onClick: undefined,
      };
    } else if (
      isSalesforceDestinationLinked &&
      !salesforceDestinatonLinkLoading
    ) {
      return {
        badgeGroupMainLabel: "Salesforce linked",
        badgeGroupIcon: "check",
        badgeTheme: "success",
        onClick: undefined,
      };
    } else {
      const isButtonDisabled = !isWorkspaceCreated;
      return {
        badgeGroupMainLabel: "Link Salesforce",
        badgeGroupIcon: "arrowNarrowRight",
        badgeTheme: "gray",
        onClick: isButtonDisabled
          ? undefined
          : async () => createSalesforceDestinationLink(),
      };
    }
  };

  const badgeProps = getBadgeProps();
  return (
    <div className="mb-3xl">
      <h4 className="mb-lg text-sm font-semibold text-black">
        3. Connect Salesforce
      </h4>
      <div className="mb-lg text-sm text-gray-600">
        After your workspace is activated, you'll need to link salesforce as a
        destination via Census.
      </div>
      <BadgeGroup
        badgeLabel="Salesforce"
        mainLabel={badgeProps.badgeGroupMainLabel}
        icon={badgeProps.badgeGroupIcon}
        theme={badgeProps.badgeTheme}
        onClick={badgeProps.onClick}
      />
    </div>
  );
};
