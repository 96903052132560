import React from "react";
import { twMerge } from "../../twMerge";
import { DocumentNode } from "graphql";
import { useAuthCheck } from "../../../lib/useAuthCheck";
import { Tooltip } from "../Tooltip";
import { NavLink } from "react-router-dom";

export const HorizontalTabs: React.FC<{
  tabs: TabProps[];
  classNames?: string;
}> = ({ tabs, classNames }) => {
  return (
    <div
      className={twMerge(
        "rounded-lg gap-xs p-xs mb-lg flex border border-gray-200 bg-gray-50",
        classNames,
        "flex-row", // enforce horizontal layout
      )}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.path}
          {...tab}
          exactUrlMatch={
            // Look for any other tabs that have a superset of this tab's path;
            // if one is found, we need to force exactUrlMatch so this tab's
            // path doesn't also route to the other tab.
            tabs
              .map((t) => t.path)
              .some((p) => p != tab.path && p.startsWith(tab.path))
          }
        />
      ))}
    </div>
  );
};

type TabProps = {
  name: string;
  path: string;
  authDocument?: DocumentNode;
  authVariables?: any;
  exactUrlMatch?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
  classNames?: string;
};

const Tab: React.FC<TabProps> = ({
  name,
  path,
  authDocument,
  authVariables,
  exactUrlMatch,
  disabled,
  disabledTooltip,
  classNames,
}) => {
  const { allowed } = useAuthCheck(authDocument, true, authVariables);

  if (!allowed) {
    return null;
  }

  if (disabled) {
    return (
      <Tooltip label={disabledTooltip ?? "This page doesn't exist yet"}>
        <div
          className={twMerge(
            "p-md rounded-md block cursor-not-allowed bg-none text-sm font-semibold text-gray-400",
            classNames,
          )}
        >
          {name}
        </div>
      </Tooltip>
    );
  }

  return (
    <NavLink
      end={exactUrlMatch}
      to={path}
      className={({ isActive }) =>
        twMerge(
          "p-md rounded-md block text-sm font-semibold text-gray-500 ring-gray-100 focus:ring-2",
          isActive
            ? "bg-white text-black shadow-sm"
            : "hover:bg-white hover:text-black hover:shadow-sm",
          classNames,
        )
      }
    >
      {name}
    </NavLink>
  );
};
