import { RowTheme, Table, TableProps } from "components/Table";
import { Dayjs, isInRange, printDateTime, useNow } from "lib/date";
import React, { ReactNode } from "react";
import { CellWithSubtitle } from "../CellWithSubtitle";
import { Badge, Icon, Tooltip } from "design-system";
import { ColWidths } from "pages/Contracts/lib/ColWidths";
import { TablePanel, TablePanelProps } from "components/TablePanel";
import { strictShallowEqual } from "fast-equals";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";
import classNames from "classnames";
import Decimal from "decimal.js";
import {
  displayCreditsInCurrencyWithoutRounding,
  RoundedCurrency,
  roundedCurrencyString,
  USD_CREDIT_TYPE,
} from "lib/credits";
import { PricingGroupValues } from "pages/Contracts/lib/PricingGroupValues";
import { Base, Override, Schedule } from "@metronome-industries/schedule-utils";
import { RecordMap, RecordSet } from "@metronome-industries/record-utils";
import { Overridden } from "@metronome-industries/schedule-utils/lib/Override/Overridden";
import { Tier } from "../LegacyRatesTable";
import styles from "./index.module.less";
import {
  EnvironmentTypeEnum_Enum,
  ScalarRateScheduleSegment,
} from "types/generated-graphql/__types__";
import { CreditType } from "types/credit-types";
import isOverridenRate = Overridden.isOverridenRate;
import isOverridenEntitlement = Overridden.isOverridenEntitlement;
import { deepEqualsWithDecimalSupport } from "../../../../lib/equals";
import { TieredOverride } from "./TieredOverride";

type PresentationGroups = Record<string, string>;

export interface RateScheduleRow {
  startingAt: Date | null;
  endingBefore: Date | null;
  product: {
    id: string;
    /** The primary text to print in the name column */
    name: string;
    /** The text to print under the product name describing the type of the product */
    type: string;
  };
  /** optional set of key/value pairs which distinguish this rate row from other rates for this same product */
  pricingGroupValues: PricingGroupValues;

  entitlement: Overridden.Entitlement | Base.Entitlement;
  rate: Overridden.Rate | Base.Rate;
  presentationGroupValueOverrides: RecordMap<
    PresentationGroups,
    Override.RateWithOverride
  >;
}

export interface RateScheduleProps<T extends RateScheduleRow> {
  title?: string;
  titleBadge?: ReactNode;
  className?: string;
  controls?: ReactNode;
  rows: T[];
  loading?: boolean;
  onRowClick?: (row: T) => void;
  getRowTheme?: (row: T) => RowTheme;
  basicPagination?: TablePanelProps<T>["basicPagination"];
  emptyState?: TablePanelProps<T>["emptyState"];
  noOverrides?: boolean;
}

type Categorized<T extends RateScheduleRow> = {
  row: T;
  isCurrent: boolean;
  isChange: boolean;
  isChangeComing: boolean;
};

const isChange = (from: RateScheduleRow, to: RateScheduleRow) => {
  return (
    from.product.id === to.product.id &&
    strictShallowEqual(from.pricingGroupValues, to.pricingGroupValues)
  );
};

const categorize = <T extends RateScheduleRow>(now: Dayjs, rows: T[]) => {
  return rows.map((row, i, list): Categorized<T> => {
    const isCurrent = isInRange(now, row.startingAt, row.endingBefore);
    const prev = i > 0 ? list.at(i - 1) : null;
    const next = list.at(i + 1);

    return {
      row,
      isCurrent,
      isChange: prev ? isChange(prev, row) : false,
      isChangeComing: next ? isChange(row, next) : false,
    };
  });
};

const Subscription = (props: {
  unitPrice: Decimal;
  quantity: Decimal;
  isProrated: boolean;
  creditType: CreditType;
  shouldRound?: boolean;
}) => (
  <>
    {props.shouldRound ? (
      <RoundedCurrency creditType={props.creditType} amount={props.unitPrice} />
    ) : (
      displayCreditsInCurrencyWithoutRounding(props.unitPrice, props.creditType)
    )}
    {` (x${props.quantity}${props.isProrated ? ", prorated" : ""})`}
  </>
);

export const Custom = () => <span>Custom</span>;

export const Tiered: React.FC<{
  tiers: Tier[];
  creditType: CreditType;
  shouldRound?: boolean;
}> = ({ tiers, creditType, shouldRound }) => {
  let prevEnd = new Decimal(0);
  const tierDescriptions = tiers.map((tier, index) => {
    const startUnit = prevEnd;

    const endUnit =
      tier.lastUnit !== undefined
        ? new Decimal(tier.lastUnit)
        : tier.size !== undefined
          ? new Decimal(tier.size).plus(startUnit)
          : undefined;

    prevEnd = endUnit || prevEnd;
    const range = endUnit ? `(>${startUnit} - ${endUnit})` : `(>${startUnit})`;

    const price = shouldRound
      ? roundedCurrencyString(tier.unitPrice, creditType)
      : displayCreditsInCurrencyWithoutRounding(
          new Decimal(tier.unitPrice),
          creditType,
        );

    return `Tier ${index + 1} ${range}\n${price}`;
  });

  // Tooltip content combines all tier descriptions
  const tooltipContent = (
    <div>
      {tierDescriptions.map((desc, idx) => (
        <React.Fragment key={idx}>
          {desc.split("\n").map((line, lineIdx) => (
            <div key={lineIdx}>{line}</div>
          ))}
          {idx < tierDescriptions.length - 1 ? <br /> : null}
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <div className="flex items-center justify-end gap-4">
      <Tooltip inline={true} content={tooltipContent}>
        <Icon icon="listCircle" className={styles.tieredRateIcon} />
      </Tooltip>
      <p>Tiered</p>
    </div>
  );
};

const printFraction = (value: Decimal) => {
  const trimmed = value.mul(100).toDecimalPlaces(2, Decimal.ROUND_FLOOR);
  return `${trimmed.eq(value) ? value : trimmed}%`;
};

const Percent = (props: { fraction: Decimal; useListPrices?: boolean }) => (
  <>
    {`${printFraction(props.fraction)}${props.useListPrices ? " (uses list prices)" : ""}`}
  </>
);

const DescribeDiscount = (props: { value: Decimal; base: Decimal }) => {
  if (props.base.eq(0)) {
    return <></>;
  }

  return (
    <>{`${printFraction(props.value.sub(props.base).div(props.base))} of `}</>
  );
};

const printRateOverrideType = (override: Override.Change.Rate) => {
  switch (override.type) {
    case "flat_overwrite":
    case "percentage_overwrite":
    case "tiered_overwrite":
    case "subscription_overwrite":
      return `Overwrite`;
    case "multiplier":
      return `Multiplier`;
    case "tiered_override":
      return "Tiered override";
  }
};

export function RateScheduleTable<T extends RateScheduleRow>(
  props: RateScheduleProps<T>,
) {
  const now = useNow();

  const tableProps: TableProps<Categorized<T>> = {
    onRowClick: props.onRowClick
      ? ({ row }) => props.onRowClick?.(row)
      : undefined,
    noPageReset: true,
    data: categorize(now, props.rows),
    loading: props.loading,
    skeletonRows: 3,
    emptyState: props.emptyState,
    basicPagination: props.basicPagination,
    columns: [
      {
        id: "product",
        header: "Product",
        cellClassName: "relative align-top",
        render({ isCurrent, isChange, isChangeComing, row }) {
          return (
            <CellWithSubtitle
              title={
                <div className="flex flex-wrap items-center gap-8">
                  {row.product.name}
                  {isCurrent && (isChange || isChangeComing) && (
                    <Badge theme="primary" type="light">
                      CURRENT
                    </Badge>
                  )}
                </div>
              }
              subtitle={
                <span className="inline-flex flex-wrap gap-4">
                  {row.product.type}
                  {Object.entries(row.pricingGroupValues ?? {}).length > 0 &&
                    ` - ${Object.entries(row.pricingGroupValues ?? {})
                      .map(([key, value]) => `${key}: ${value}`)
                      .join(", ")}`}
                </span>
              }
            />
          );
        },
      },
      {
        id: "entitled",
        header: "Entitlement",
        align: "right",
        cellClassName: "w-[100px] align-top",
        render({ row }) {
          return row.entitlement.entitled ? "Enabled" : "Disabled";
        },
      },
      {
        id: "effectiveStart",
        header: "Effective start",
        align: "right",
        cellClassName: classNames(ColWidths.DATE, "align-top"),
        render({ row }) {
          if (row.startingAt == null) {
            return "--";
          }

          return printDateTime(row.startingAt);
        },
      },
      {
        id: "effectiveEnd",
        header: "Effective end",
        align: "right",
        cellClassName: classNames(ColWidths.DATE, "align-top"),
        render({ row }) {
          if (row.endingBefore == null) {
            return "--";
          }

          return printDateTime(row.endingBefore);
        },
      },
      {
        id: "override",
        header: "Override",
        align: "right",
        cellClassName: classNames(ColWidths.RATE, "align-top"),
        disabled: props.noOverrides,
        render({ row }): string | React.ReactElement {
          const overrides = buildOverridesMap(row);

          const overrideKeys: PresentationGroups[] = Array.from(
            overrides.keys(),
          );
          const baseRate = findBaseRate(row.rate);

          if (overrideKeys.length === 0) {
            return "--";
          } else if (overrideKeys.length === 1) {
            const rate: Overridden.Rate | Base.Rate =
              overrides.get(overrideKeys[0])?.rate ?? baseRate;
            const entitlement: Overridden.Entitlement | undefined =
              overrides.get(overrideKeys[0])?.entitlement;
            const overrideKey = overrideKeys[0];
            const isDefaultOverride = isDefaultOverrideCriteria(overrideKey);
            const presGroupValueSuffix = isDefaultOverride
              ? ""
              : ` if ${formatOverrideKeyForPresentationGroupValue(overrideKey)}`;
            switch (rate.type) {
              case "flat":
              case "percentage":
              case "subscription":
              case "tiered":
              case "custom":
                return "--";

              case "overridden_flat_rate":
                const base = rate.base;
                switch (base.type) {
                  case "flat":
                    return (
                      <CellWithSubtitle
                        title={
                          <>
                            <DescribeDiscount
                              value={rate.unitPrice}
                              base={base.unitPrice}
                            />
                            {displayCreditsInCurrencyWithoutRounding(
                              base.unitPrice,
                              transformRateCreditType(base.creditType),
                            )}
                            {presGroupValueSuffix}
                          </>
                        }
                        subtitle={[
                          rate.override.rateChange
                            ? printRateOverrideType(rate.override.rateChange)
                            : null,
                          entitlement && `Entitlement`,
                        ]
                          .filter(Boolean)
                          .join(" • ")}
                      />
                    );
                  case "tiered":
                    return (
                      <CellWithSubtitle
                        title={
                          <>
                            <Tiered
                              tiers={base.tiers}
                              creditType={transformRateCreditType(
                                base.creditType,
                              )}
                            />
                            {presGroupValueSuffix}
                          </>
                        }
                        subtitle={[
                          rate.override.rateChange
                            ? printRateOverrideType(rate.override.rateChange)
                            : null,
                          entitlement && `Entitlement`,
                        ]
                          .filter(Boolean)
                          .join(" • ")}
                      />
                    );
                  default:
                    base satisfies never;
                    return "invalid";
                }

              case "overridden_percentage_rate":
                return (
                  <CellWithSubtitle
                    title={
                      <>
                        <DescribeDiscount
                          value={rate.fraction}
                          base={rate.base.fraction}
                        />
                        <Percent {...rate.base} />
                        {presGroupValueSuffix}
                      </>
                    }
                    subtitle={[
                      rate.override.rateChange
                        ? printRateOverrideType(rate.override.rateChange)
                        : null,
                      entitlement && `Entitlement`,
                    ]
                      .filter(Boolean)
                      .join(" • ")}
                  />
                );

              case "overridden_subscription_rate":
                return (
                  <CellWithSubtitle
                    title={
                      <>
                        <DescribeDiscount
                          value={rate.unitPrice}
                          base={rate.base.unitPrice}
                        />
                        <Subscription
                          unitPrice={rate.base.unitPrice}
                          creditType={transformRateCreditType(
                            rate.base.creditType,
                          )}
                          isProrated={rate.base.isProrated}
                          quantity={rate.base.quantity}
                        />
                        {presGroupValueSuffix}
                      </>
                    }
                    subtitle={[
                      rate.override.rateChange
                        ? printRateOverrideType(rate.override.rateChange)
                        : null,
                      entitlement && `Entitlement`,
                    ]
                      .filter(Boolean)
                      .join(" • ")}
                  />
                );

              case "overridden_custom_rate":
                return <CellWithSubtitle title="Custom" subtitle="" />;

              case "overridden_tiered_rate":
                if (rate.base.type === "flat") {
                  return (
                    <CellWithSubtitle
                      title={displayCreditsInCurrencyWithoutRounding(
                        rate.base.unitPrice,
                        transformRateCreditType(rate.base.creditType),
                      )}
                      subtitle={[
                        rate.override.rateChange
                          ? printRateOverrideType(rate.override.rateChange)
                          : null,
                        entitlement && `Entitlement`,
                      ]
                        .filter(Boolean)
                        .join(" • ")}
                    />
                  );
                } else if (rate.base.type === "tiered") {
                  return (
                    <CellWithSubtitle
                      title={
                        <>
                          <Tiered
                            tiers={rate.base.tiers}
                            creditType={transformRateCreditType(
                              rate.base.creditType,
                            )}
                          />
                          {presGroupValueSuffix}
                        </>
                      }
                      subtitle={[
                        rate.override.rateChange
                          ? printRateOverrideType(rate.override.rateChange)
                          : null,
                        entitlement && `Entitlement`,
                      ]
                        .filter(Boolean)
                        .join(" • ")}
                    />
                  );
                } else {
                  rate.base satisfies never;
                  return "invalid";
                }

              case "overridden_multiplier_rate":
                const multiplierPrefix = `${printFraction(rate.multiplier)} of`;
                let baseRateDesc = "";

                switch (rate.base.type) {
                  case "flat":
                  case "subscription":
                    baseRateDesc = displayCreditsInCurrencyWithoutRounding(
                      rate.base.unitPrice,
                      transformRateCreditType(rate.base.creditType),
                    );
                    break;
                  case "percentage":
                    baseRateDesc = printFraction(rate.base.fraction);
                    break;
                  case "custom":
                    baseRateDesc = "custom rate";
                    break;
                  case "tiered":
                    baseRateDesc = "tiered rate";
                    break;
                  default:
                    rate.base satisfies never;
                    throw new Error(`Invalid base rate type ${rate}`);
                }
                return (
                  <CellWithSubtitle
                    title={`${multiplierPrefix} ${baseRateDesc}${presGroupValueSuffix}`}
                    subtitle="Multiplier"
                  />
                );
              case "tiered_override":
                return (
                  <TieredOverride
                    tiers={
                      rate.override.rateChange?.type === "tiered_override"
                        ? rate.override.rateChange.tiers
                        : []
                    }
                  />
                );
              default:
                return "";
            }
          } else {
            switch (baseRate.type) {
              case "flat":
              case "subscription":
                const baseRateText = `of ${displayCreditsInCurrencyWithoutRounding(baseRate.unitPrice, transformRateCreditType(baseRate.creditType))}`;
                return (
                  <CellWithSubtitle
                    title={
                      <div className="flex flex-row items-center justify-end">
                        <Tooltip
                          content={overrideKeys
                            .flatMap((k) => {
                              const overriddenRate = overrides.get(k)?.rate;
                              if (!overriddenRate) {
                                return [];
                              }
                              if (
                                overriddenRate.type ===
                                "overridden_multiplier_rate"
                              ) {
                                return [
                                  `${printFraction(overriddenRate.multiplier)} ${baseRateText} ${tooltipPrefix(k)}`,
                                ];
                              } else if (
                                overriddenRate.type === "tiered_override"
                              ) {
                                return [`Tiered override ${tooltipPrefix(k)}`];
                              } else {
                                return [`overwritten ${tooltipPrefix(k)}`];
                              }
                            })
                            .map((s) => (
                              <>
                                {s}
                                <br />
                              </>
                            ))}
                        >
                          <Icon
                            icon="listCircle"
                            className="mr-8 text-primary-600"
                          />
                        </Tooltip>
                        {`${overrideKeys.length} overrides`}
                      </div>
                    }
                    subtitle=""
                  />
                );
              case "percentage":
                return (
                  <CellWithSubtitle
                    title={`${overrideKeys.length} overrides`}
                    subtitle={`on ${printFraction(baseRate.fraction)}`}
                  />
                );
              case "custom":
                return (
                  <CellWithSubtitle
                    title={`${overrideKeys.length} overrides`}
                    subtitle="on custom rate"
                  />
                );
              case "tiered":
                return (
                  <CellWithSubtitle
                    title={`${overrideKeys.length} overrides`}
                    subtitle="on tiered rate"
                  />
                );
            }
          }
        },
      },
      {
        id: "rate",
        header: "Rate",
        align: "right",
        cellClassName: classNames(ColWidths.RATE, "align-top"),
        render({ row }): string | React.ReactElement {
          const overrides = buildOverridesMap(row);
          const overrideKeys: PresentationGroups[] = Array.from(
            overrides.keys(),
          );

          // check if there are any presentation group value overrides
          if (overrideKeys.some((k) => !isDefaultOverrideCriteria(k))) {
            type PresoKeyOverride = {
              criteria: PresentationGroups;
              prices: Decimal[];
              rate: Overridden.Rate | Base.Rate;
            };
            const unitPrices = overrideKeys.flatMap<PresoKeyOverride>((k) => {
              const override = overrides.get(k)?.rate;

              if (!override) {
                return [];
              }
              switch (override.type) {
                case "tiered_override":
                  return [
                    {
                      criteria: k,
                      prices: override.tiers.map((t) => t.unitPrice),
                      rate: override,
                    },
                  ];
                case "overridden_multiplier_rate":
                  switch (override.base.type) {
                    case "flat":
                    case "subscription":
                      return [
                        {
                          criteria: k,
                          prices: [
                            override.base.unitPrice.mul(override.multiplier),
                          ],
                          rate: override,
                        },
                      ];
                    case "percentage":
                      return [
                        {
                          criteria: k,
                          prices: [
                            override.base.fraction.mul(override.multiplier),
                          ],
                          rate: override,
                        },
                      ];
                    case "tiered":
                      return [
                        {
                          criteria: k,
                          prices: override.base.tiers.map((t) =>
                            t.unitPrice.mul(override.multiplier),
                          ),
                          rate: override,
                        },
                      ];
                    case "custom":
                      return [];
                  }
                case "overridden_custom_rate":
                  return [];
                case "overridden_flat_rate":
                  return [
                    {
                      criteria: k,
                      prices: [override.unitPrice],
                      rate: override,
                    },
                  ];
                case "overridden_percentage_rate":
                  return [
                    {
                      criteria: k,
                      prices: [override.fraction],
                      rate: override,
                    },
                  ];
                case "overridden_subscription_rate":
                  return [
                    {
                      criteria: k,
                      prices: [override.unitPrice],
                      rate: override,
                    },
                  ];
                case "overridden_tiered_rate":
                  return [
                    {
                      criteria: k,
                      prices: override.tiers.map((t) => t.unitPrice),
                      rate: override,
                    },
                  ];
                default:
                  override satisfies never;
                  return [];
              }
            });

            // Include the default rate even if its not a multiplier (otherwise it would be handled below)
            const defaultRate = row.rate;
            if (
              defaultRate.type !== "overridden_multiplier_rate" &&
              defaultRate.type !== "tiered_override"
            ) {
              switch (defaultRate.type) {
                case "flat":
                case "subscription":
                case "overridden_flat_rate":
                  unitPrices.push({
                    criteria: {},
                    prices: [defaultRate.unitPrice],
                    rate: defaultRate,
                  });
                  break;
                case "percentage":
                case "overridden_percentage_rate":
                  unitPrices.push({
                    criteria: {},
                    prices: [defaultRate.fraction],
                    rate: defaultRate,
                  });
                  break;
                case "tiered":
                case "overridden_tiered_rate":
                  unitPrices.push({
                    criteria: {},
                    prices: defaultRate.tiers.map((t) => t.unitPrice),
                    rate: defaultRate,
                  });
                  break;
                case "custom":
                case "overridden_custom_rate":
                case "overridden_subscription_rate":
                  break;
                default:
                  defaultRate satisfies never;
              }
            }

            const baseRate = findBaseRate(defaultRate);

            const baseRateType = baseRate.type;

            const creditType =
              "creditType" in baseRate
                ? transformRateCreditType(baseRate.creditType)
                : USD_CREDIT_TYPE;

            switch (baseRateType) {
              case "flat": {
                const { minPrice, maxPrice } = unitPrices.reduce(
                  (acc, prev) => {
                    return {
                      minPrice: Decimal.min(acc.minPrice, ...prev.prices),
                      maxPrice: Decimal.max(acc.maxPrice, ...prev.prices),
                    };
                  },
                  {
                    minPrice: unitPrices[0].prices[0],
                    maxPrice: unitPrices[0].prices[0],
                  },
                );
                return (
                  <Tooltip
                    inline={true}
                    content={unitPrices.map((price) => (
                      <>
                        {`${tooltipPrefix(price.criteria)}: ${price.prices.length > 1 ? "Tiered price" : displayCreditsInCurrencyWithoutRounding(price.prices[0], creditType)}`}
                        <br />
                      </>
                    ))}
                  >
                    {`${displayCreditsInCurrencyWithoutRounding(minPrice, creditType)} - ${displayCreditsInCurrencyWithoutRounding(maxPrice, creditType)}`}
                  </Tooltip>
                );
              }
              case "percentage": {
                const { minPrice, maxPrice } = unitPrices.reduce(
                  (acc, prev) => {
                    return {
                      minPrice: Decimal.min(acc.minPrice, ...prev.prices),
                      maxPrice: Decimal.max(acc.maxPrice, ...prev.prices),
                    };
                  },
                  {
                    minPrice: unitPrices[0].prices[0],
                    maxPrice: unitPrices[0].prices[0],
                  },
                );
                return (
                  <Tooltip
                    inline={true}
                    content={unitPrices.map((price) => {
                      const useListPrices =
                        price.rate.type === "percentage" ||
                        price.rate.type === "overridden_percentage_rate"
                          ? price.rate.useListPrices
                          : baseRate.useListPrices;
                      return (
                        <>
                          {`${tooltipPrefix(price.criteria)}: `}
                          {price.prices.length > 1 ? (
                            `Tiered price ${useListPrices ? " (uses list prices)" : ""}`
                          ) : (
                            <Percent
                              fraction={price.prices[0]}
                              useListPrices={useListPrices}
                            />
                          )}
                          <br />
                        </>
                      );
                    })}
                  >
                    <Percent fraction={minPrice} /> -{" "}
                    <Percent fraction={maxPrice} />
                  </Tooltip>
                );
              }
              case "subscription": {
                const { minPrice, maxPrice } = unitPrices.reduce(
                  (acc, prev) => {
                    return {
                      minPrice: Decimal.min(acc.minPrice, ...prev.prices),
                      maxPrice: Decimal.max(acc.maxPrice, ...prev.prices),
                    };
                  },
                  {
                    minPrice: unitPrices[0].prices[0],
                    maxPrice: unitPrices[0].prices[0],
                  },
                );
                return (
                  <Tooltip
                    inline={true}
                    content={unitPrices.map((price) => (
                      <>
                        {`${tooltipPrefix(price.criteria)}: `}
                        <Subscription
                          unitPrice={price.prices[0]}
                          quantity={
                            price.rate.type === "subscription" ||
                            price.rate.type === "overridden_subscription_rate"
                              ? price.rate.quantity
                              : baseRate.quantity
                          }
                          isProrated={
                            price.rate.type === "subscription" ||
                            price.rate.type === "overridden_subscription_rate"
                              ? price.rate.isProrated
                              : baseRate.isProrated
                          }
                          creditType={creditType}
                        />
                        <br />
                      </>
                    ))}
                  >
                    <Subscription
                      unitPrice={minPrice}
                      isProrated={false}
                      quantity={baseRate.quantity}
                      creditType={creditType}
                    />
                    -
                    <Subscription
                      unitPrice={maxPrice}
                      isProrated={false}
                      quantity={baseRate.quantity}
                      creditType={creditType}
                    />
                  </Tooltip>
                );
              }
              case "tiered":
                return <CellWithSubtitle title="Tiered rate" subtitle="" />;
              case "custom":
                return "invalid";
              default:
                baseRate satisfies never;
                return "invalid";
            }
          } else {
            const rate = row.rate;
            switch (rate.type) {
              case "flat":
                return displayCreditsInCurrencyWithoutRounding(
                  rate.unitPrice,
                  transformRateCreditType(rate.creditType),
                );

              case "percentage":
                return <Percent {...rate} />;

              case "subscription":
                return (
                  <Subscription
                    {...rate}
                    creditType={transformRateCreditType(rate.creditType)}
                  />
                );

              case "custom":
                return <Custom />;

              case "tiered":
                return (
                  <Tiered
                    tiers={rate.tiers}
                    creditType={transformRateCreditType(rate.creditType)}
                  />
                );

              case "overridden_flat_rate":
                return displayCreditsInCurrencyWithoutRounding(
                  rate.unitPrice,
                  transformRateCreditType(rate.base.creditType),
                );

              case "overridden_percentage_rate":
                return <Percent {...rate} />;

              case "overridden_subscription_rate":
                return (
                  <Subscription
                    {...rate}
                    creditType={transformRateCreditType(rate.base.creditType)}
                  />
                );

              case "overridden_custom_rate":
                return <Custom />;

              case "overridden_tiered_rate":
                return (
                  <Tiered
                    tiers={rate.tiers}
                    creditType={transformRateCreditType(rate.base.creditType)}
                  />
                );

              case "overridden_multiplier_rate":
                switch (rate.base.type) {
                  case "flat":
                    return (
                      <RoundedCurrency
                        creditType={transformRateCreditType(
                          rate.base.creditType,
                        )}
                        amount={rate.base.unitPrice.mul(rate.multiplier)}
                      />
                    );
                  case "percentage":
                    return (
                      <>
                        {printFraction(rate.base.fraction.mul(rate.multiplier))}
                      </>
                    );
                  case "subscription":
                    return (
                      <Subscription
                        {...rate.base}
                        unitPrice={rate.base.unitPrice.mul(rate.multiplier)}
                        creditType={transformRateCreditType(
                          rate.base.creditType,
                        )}
                        shouldRound
                      />
                    );
                  case "custom":
                    return <Custom />;

                  case "tiered":
                    return (
                      <Tiered
                        tiers={rate.base.tiers.map((tier: Tier) => ({
                          unitPrice: tier.unitPrice.mul(rate.multiplier),
                          lastUnit: tier.lastUnit,
                          size: tier.size,
                        }))}
                        creditType={transformRateCreditType(
                          rate.base.creditType,
                        )}
                        shouldRound
                      />
                    );
                }
              case "tiered_override":
                return (
                  <Tiered
                    tiers={rate.tiers}
                    creditType={transformRateCreditType(rate.base.creditType)}
                  />
                );
              default:
                return "";
            }
          }
        },
      },
    ],
  };

  return props.title !== undefined ? (
    <TablePanel<Categorized<T>>
      title={props.title}
      badge={props.titleBadge}
      className={props.className}
      controls={props.controls}
      {...tableProps}
    />
  ) : (
    <Table<Categorized<T>> {...tableProps} />
  );
}

export namespace RateScheduleTable {
  import RateWithOverridesSchedule = Override.RateWithOverridesSchedule;

  function findCreditType(
    creditTypeId: string,
    creditTypes: CreditType[],
  ): Base.CreditType {
    const creditType = creditTypes.find((ct) => ct.id === creditTypeId);
    if (!creditType) {
      throw new Error(
        `Credit type ${creditTypeId} not found in creditTypes array, this is unexpected`,
      );
    }
    return {
      id: creditType.id,
      name: creditType.name,
      clientId: creditType.client_id ?? null,
      environmentType: creditType.environment_type ?? null,
    };
  }

  function parseBaseRate(
    rate: ScalarRateScheduleSegment["rate"],
    creditTypes: CreditType[],
  ): Base.Rate {
    let creditType: Base.CreditType | undefined;
    switch (rate.__typename) {
      case undefined:
        throw new Error("Rate type is undefined");
      case "ScalarFlatRate":
        creditType = findCreditType(rate.credit_type_id, creditTypes);
        return {
          type: "flat",
          unitPrice: new Decimal(rate.unit_price),
          creditType,
        };
      case "PercentageRate":
        return {
          type: "percentage",
          fraction: new Decimal(rate.fraction),
          useListPrices: rate.use_list_prices,
        };
      case "SubscriptionRate":
        creditType = findCreditType(rate.credit_type.id, creditTypes);
        return {
          type: "subscription",
          unitPrice: new Decimal(rate.unit_price),
          quantity: new Decimal(rate.quantity),
          isProrated: rate.is_prorated,
          creditType,
        };
      case "CustomRate":
        creditType = findCreditType(rate.credit_type.id, creditTypes);
        return {
          type: "custom",
          creditType,
        };
      case "TieredRate":
        creditType = findCreditType(rate.credit_type.id, creditTypes);
        return {
          type: "tiered",
          tiers: rate.tiers.map((tier) => ({
            size: tier.size ? new Decimal(tier.size) : undefined,
            unitPrice: new Decimal(tier.unit_price),
          })),
          creditType,
        };
      default:
        rate satisfies never;
        throw new Error(`Unexpected rate type for rate ${rate}`);
    }
  }

  /**
   * Applies the overrides to the list of rate schedule segments.
   */
  export function applyOverrides(
    rows: RateScheduleRow[],
    products: Array<ProductListItem.IdFragment & ProductListItem.TagsFragment>,
    overrides: Override.Description[],
  ) {
    const overrideSchedule = Override.getOverrideSchedule(overrides);

    // split rows by product + pricing group values
    const segmentsPerProdSelector = new RecordMap<
      {
        product: RateScheduleRow["product"];
        pricingGroupValues: PricingGroupValues;
      },
      RateScheduleRow[]
    >();

    const productIdTargetedPresentationKeys = new Map<
      string,
      RecordSet<Record<string, string>>
    >();

    const nonProductIdPresentationKeys = new RecordSet<
      Record<string, string>
    >();

    for (const override of overrides) {
      for (const overrideSpecifier of override.appliesTo ?? []) {
        if (
          overrideSpecifier.productId &&
          overrideSpecifier.presentationGroupValues
        ) {
          let presentationKeys = productIdTargetedPresentationKeys.get(
            overrideSpecifier.productId,
          );
          if (!presentationKeys) {
            presentationKeys = new RecordSet();
            productIdTargetedPresentationKeys.set(
              overrideSpecifier.productId,
              presentationKeys,
            );
          }
          presentationKeys.add(overrideSpecifier.presentationGroupValues);
        } else if (overrideSpecifier.presentationGroupValues) {
          nonProductIdPresentationKeys.add(
            overrideSpecifier.presentationGroupValues,
          );
        }
      }
    }

    // generate a tag for each product found in the rows
    const tags = new Map<string, ProductListItem.TagsSchedule>();

    for (const row of rows) {
      const tagSchedule = tags.get(row.product.id);
      if (!tagSchedule) {
        const product = products.find((p) => p.id === row.product.id);
        if (!product) {
          throw new Error(
            `unable to to determine tags for product ${row.product.id}`,
          );
        }

        tags.set(product.id, ProductListItem.getTagsSchedule(product));
      }

      const selector = {
        product: row.product,
        pricingGroupValues: row.pricingGroupValues,
      };
      const segments = segmentsPerProdSelector.get(selector);
      if (segments) {
        segments.push(row);
      } else {
        segmentsPerProdSelector.set(selector, [row]);
      }
    }

    // convert each group of segments into a schedule that describes the rate and tags for the given product over the time of the segments
    return Array.from(segmentsPerProdSelector).flatMap(
      ([selector, groupOfRows]) => {
        const tagSchedule = tags.get(selector.product.id);
        if (!tagSchedule) {
          throw new Error(
            `unable to to determine tags for product ${selector.product.id}`,
          );
        }

        // Note that at this point we have not applied overrides yet, so we should only have the defaults populated
        const baseRateSchedule = Schedule.from(
          groupOfRows.map((row) => {
            if (
              Overridden.isOverridenRate(row.rate) ||
              Overridden.isOverridenEntitlement(row.entitlement)
            ) {
              throw new Error("overrides cannot be applied to overrides");
            }

            return {
              startingAt: row.startingAt,
              endingBefore: row.endingBefore,
              data: {
                rate: row.rate,
                entitled: row.entitlement,
              },
            };
          }),
        );
        const schedules: Record<string, RateWithOverridesSchedule> = {};

        // add the overrides excluding presentation group values
        schedules["default"] = Override.apply(
          selector.product.id,
          selector.pricingGroupValues ?? undefined,
          undefined,
          baseRateSchedule.startingAt ?? null,
          baseRateSchedule.endingBefore ?? null,
          tagSchedule,
          baseRateSchedule,
          overrideSchedule,
        );

        const potentialPresentationValuesForProduct = new RecordSet<
          Record<string, string>
        >(productIdTargetedPresentationKeys.get(selector.product.id));
        for (const presentationGroupValue of nonProductIdPresentationKeys) {
          potentialPresentationValuesForProduct.add(presentationGroupValue);
        }

        for (const presentationGroupValue of potentialPresentationValuesForProduct) {
          const presentationOverrideSchedule = Override.apply(
            selector.product.id,
            selector.pricingGroupValues ?? undefined,
            presentationGroupValue,
            baseRateSchedule.startingAt ?? null,
            baseRateSchedule.endingBefore ?? null,
            tagSchedule,
            baseRateSchedule,
            overrideSchedule,
          );
          schedules[JSON.stringify(presentationGroupValue)] =
            presentationOverrideSchedule;
        }

        const mergedSchedule = Schedule.combineAll(schedules);

        // convert schedule into `RateScheduleRow`s
        return mergedSchedule.segments.map((segment): RateScheduleRow => {
          const applicablePresentationKeyOverrides = Object.keys(
            segment.data,
          ).filter((k) => k !== "default");

          const presentationGroupValueOverrides = new RecordMap<
            Record<string, string>,
            Override.RateWithOverride
          >();

          for (const presentationGroupValue of applicablePresentationKeyOverrides) {
            // We want to remove any overrides that aren't actually different from the default override for the same segment
            if (
              !deepEqualsWithDecimalSupport(
                segment.data[presentationGroupValue].entitlement,
                segment.data.default.entitlement,
              ) ||
              !deepEqualsWithDecimalSupport(
                segment.data[presentationGroupValue].rate,
                segment.data.default.rate,
              )
            ) {
              presentationGroupValueOverrides.set(
                JSON.parse(presentationGroupValue),
                {
                  entitlement: segment.data[presentationGroupValue].entitlement,
                  rate: segment.data[presentationGroupValue].rate,
                },
              );
            }
          }

          const returnVal = {
            startingAt: segment.startingAt,
            endingBefore: segment.endingBefore,
            pricingGroupValues: selector.pricingGroupValues,
            product: selector.product,
            entitlement: segment.data.default.entitlement,
            rate: segment.data.default.rate,
            presentationGroupValueOverrides,
          };

          return returnVal;
        });
      },
    );
  }

  export function rowsFromSegments(
    now: Dayjs,
    segments: ScalarRateScheduleSegment[],
    products: Array<
      ProductListItem.IdFragment &
        ProductListItem.NameFragment &
        ProductListItem.TypeFragment
    >,
    creditTypes: CreditType[],
  ) {
    return segments.map((segment): RateScheduleRow => {
      const product = products.find((p) => p.id === segment.product_id);
      if (!product) {
        throw new Error(`Missing product with id "${segment.product_id}"`);
      }

      return {
        product: {
          id: product.id,
          name: ProductListItem.getName(product, now),
          type: ProductListItem.printType(product),
        },

        pricingGroupValues: segment.pricing_groups ?? null,

        startingAt:
          segment.starting_at == null ? null : new Date(segment.starting_at),

        endingBefore:
          segment.ending_before == null
            ? null
            : new Date(segment.ending_before),

        entitlement: {
          type: "entitlement",
          entitled: segment.entitled,
        },
        rate: parseBaseRate(segment.rate, creditTypes),
        presentationGroupValueOverrides: new RecordMap(),
      };
    });
  }
}

function findBaseRate(rate: Base.Rate | Overridden.Rate): Base.Rate {
  if (Overridden.isOverridenRate(rate)) {
    return findBaseRate(rate.base);
  }
  return rate;
}

function transformRateCreditType(ct: Base.CreditType | undefined): CreditType {
  return ct
    ? {
        ...ct,
        client_id: ct.clientId,
        environment_type: ct.environmentType as EnvironmentTypeEnum_Enum,
      }
    : USD_CREDIT_TYPE;
}

function formatOverrideKeyForPresentationGroupValue(
  presentationGroupValue: PresentationGroups,
) {
  return Object.entries(presentationGroupValue)
    .map(([k, v]) => `${k}: ${v}`)
    .join(", ");
}

function isDefaultOverrideCriteria(criteria: PresentationGroups): boolean {
  return Object.keys(criteria).length === 0;
}

function tooltipPrefix(criteria: PresentationGroups): string {
  if (isDefaultOverrideCriteria(criteria)) {
    return "otherwise";
  } else {
    return `for ${formatOverrideKeyForPresentationGroupValue(criteria)}`;
  }
}

function buildOverridesMap(
  row: RateScheduleRow,
): RecordMap<
  PresentationGroups,
  { rate?: Overridden.Rate; entitlement?: Overridden.Entitlement }
> {
  const overrides: RecordMap<
    PresentationGroups,
    { rate?: Overridden.Rate; entitlement?: Overridden.Entitlement }
  > = new RecordMap();
  for (const [criteria, override] of row.presentationGroupValueOverrides) {
    const rateOverride = isOverridenRate(override.rate)
      ? override.rate
      : undefined;
    const entitlementOverride = isOverridenEntitlement(override.entitlement)
      ? override.entitlement
      : undefined;

    if (rateOverride || entitlementOverride) {
      overrides.set(criteria, {
        rate: rateOverride,
        entitlement: entitlementOverride,
      });
    }
  }

  const defaultRateOverride = isOverridenRate(row.rate) ? row.rate : undefined;
  const defaultEntitlementOverride = isOverridenEntitlement(row.entitlement)
    ? row.entitlement
    : undefined;

  if (defaultRateOverride || defaultEntitlementOverride) {
    overrides.set(
      {},
      {
        rate: defaultRateOverride,
        entitlement: defaultEntitlementOverride,
      },
    );
  }

  return overrides;
}
