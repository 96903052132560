import { EmptyState } from "components/EmptyState";
import { Table } from "components/Table";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { useSearcher } from "lib/search/useSearcher";
import { useNavigate } from "lib/useNavigate";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { CellWithSubtitle } from "../../components/CellWithSubtitle";
import { Dayjs, printDateTime, useNow, toDayjs } from "lib/date";
import { ContractPricingWrapper } from "../ContractPricingWrapper";
import { ContractProductFlyover } from "../ContractProductFlyover";
import { EditProductModal } from "../CreateAndEditProductModal";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";

import {
  useContractProductListQuery,
  ContractProductList__ProductListItemFragment as ProductFragment,
} from "./data.graphql";
import { uniq } from "pages/Contracts/lib/array";
import { useUIMode } from "../../../../lib/useUIMode";

type CurrentProductStateRow = {
  type: "current";
  product: ProductFragment;
  name: string;
};

type ProductStateChangeRow = {
  type: "change";
  date: Dayjs;
  product: ProductFragment;
};

type ProductStateRow = CurrentProductStateRow | ProductStateChangeRow;

export const ContractProductList: React.FC = () => {
  const now = useNow();
  const req = useContractProductListQuery();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [editProductId, setEditProductId] = useState<string | undefined>();
  const { id: productId }: { id?: string } = useParams();

  const allProducts = React.useMemo(
    () =>
      (
        req.data?.contract_pricing.products?.map((p) => ({
          ...p,
          __search__allNames: ProductListItem.getAllNames(p),
        })) ?? []
      ).sort((a, b) =>
        ProductListItem.getName(a, now).localeCompare(
          ProductListItem.getName(b, now),
        ),
      ),
    [req.data, now],
  );

  const searcher = useSearcher(allProducts, {
    keys: ["__search__allNames"],
  });
  const products = searcher(searchTerm);

  const { newUIEnabled } = useUIMode();

  if (req.error) {
    return (
      <ErrorEmptyState
        title="We ran into an error loading your product list"
        error={req.error}
      />
    );
  }

  return (
    <ContractPricingWrapper
      searchPlaceholder="Search products"
      searchTerm={searchTerm}
      onSearchTermChange={setSearchTerm}
      newButtonText="product"
      newButtonRoutePath={newUIEnabled ? "/offering/products/create" : ""}
    >
      {productId && (
        <ContractProductFlyover
          productId={productId}
          onRequestClose={() =>
            navigate(
              newUIEnabled
                ? "/offering/products"
                : "/contract-pricing/products",
              { replace: true },
            )
          }
          editProduct={(product: string) => setEditProductId(product)}
        />
      )}
      {editProductId && (
        <EditProductModal
          onClose={() => setEditProductId(undefined)}
          editProductId={editProductId}
        />
      )}
      {!req.loading && products.length === 0 ? (
        searchTerm ? (
          <EmptyState
            title="No products match your query"
            subtitle=""
            icon="searchSm"
          />
        ) : (
          <EmptyState
            title="There are no products"
            subtitle="Please use the contract pricing API to create products"
            icon="file05"
          />
        )
      ) : (
        <Table
          loading={req.loading}
          maxPageSize={20}
          data={products.flatMap((product): ProductStateRow[] => [
            {
              type: "current",
              product: product,
              name: ProductListItem.getName(product, now),
            },
            ...uniq(
              product.updates
                .filter((u) => toDayjs(u.effective_at).isSameOrAfter(now))
                .map((u) => u.effective_at),
            )
              .map(
                (date): ProductStateChangeRow => ({
                  type: "change",
                  product,
                  date: toDayjs(date),
                }),
              )
              .sort((a, b) => b.date.diff(a.date)),
          ])}
          columns={[
            {
              id: "name",
              header: "Product",
              render: (row) =>
                row.type === "current" ? (
                  row.name
                ) : (
                  <CellWithSubtitle
                    title="Product update"
                    subtitle={`Effective ${printDateTime(row.date)}`}
                    withIndicator
                  />
                ),
            },
            {
              id: "type",
              header: "Type",
              cellClassName: "w-1/5",
              render: (row) =>
                row.type === "current"
                  ? ProductListItem.printType(row.product)
                  : "",
            },
            {
              id: "bm",
              header: "Associated billable metric",
              cellClassName: "w-1/5",
              render: (row) =>
                row.type === "current"
                  ? ProductListItem.getBillableMetricName(row.product, now) ??
                    "-"
                  : "",
            },
          ]}
          onRowClick={(row) => {
            let baseUrl = `${newUIEnabled ? "/offering" : "/contract-pricing"}/products/${row.product.id}`;
            if (row.type === "change") {
              baseUrl += `?effectiveAt=${row.date.toISOString()}`;
            }
            navigate(baseUrl, {
              replace: true,
            });
          }}
        />
      )}
    </ContractPricingWrapper>
  );
};
