import React from "react";
import { Button } from "tenaissance/components/Button";
import { FormController } from "lib/FormController";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { Takeover } from "tenaissance/components/Takeover";
import { useSnackbar } from "components/Snackbar";
import { useCreateRateCardMutation } from "./data.graphql";

import { reportToSentry } from "lib/errors/sentry";
import {
  formAliasesAsGraphqlAliases,
  formCreditTypeConversionsAsGraphqlCreditTypeConversions,
  formRatesAsGraphqlRates,
} from "./utils";
import { RateCardContext } from "./RateCardContext";

export const urlPrefix = "/contract-pricing/rate-cards/new2";

const RateCardCreate: React.FC = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const { ctrl, clearSnapshot } = RateCardContext.useContainer();

  const getCurrentStepName = () => {
    return location.pathname.split("rate-cards/new2/")[1];
  };

  const isFirstStep = getCurrentStepName() === "new-rate-card";
  const isLastStep = getCurrentStepName() === "rate-products";

  const pushMessage = useSnackbar();

  const [createRateCardMutation, createRateCardResult] =
    useCreateRateCardMutation();

  const backToRateCardList = () => {
    clearSnapshot();
    navigate("/contract-pricing/rate-cards");
  };

  const onSubmit = FormController.useSubmitHandler(ctrl, async (valid) => {
    try {
      const result = await createRateCardMutation({
        variables: {
          name: valid.name.trim(),
          description: valid.description?.trim(),
          rates: formRatesAsGraphqlRates(valid.rates),
          aliases: formAliasesAsGraphqlAliases(valid.aliases ?? []),
          fiatCreditTypeId: valid.fiatCreditTypeId,
          creditTypeConversions:
            formCreditTypeConversionsAsGraphqlCreditTypeConversions(
              valid.creditTypeConversions ?? [],
            ),
        },
        update(cache) {
          cache.evict({ fieldName: "products_and_rate_cards" });
        },
      });
      const id = result.data?.create_rate_card?.id;

      if (id) {
        pushMessage({
          type: "success",
          content: "Rate card created successfully",
        });
        clearSnapshot();
        navigate(`/contract-pricing/rate-cards/${id}`);
      }
    } catch (e) {
      reportToSentry(e);
      pushMessage({
        content: `Failed to create new rate card: ${e}`,
        type: "error",
      });
    }
  });

  return (
    <Takeover
      maxContainerWidth="max-w-[1400px]"
      isOpen={true}
      onClose={() => backToRateCardList()}
      footerTrailingButtons={[
        <Button
          onClick={() => {
            if (isLastStep) {
              // TODO: if there is no dimensional pricing,
              // go back to the first step
              navigate(`${urlPrefix}/dimensional-pricing`);
            }
            if (getCurrentStepName() === "dimensional-pricing") {
              navigate(`${urlPrefix}/new-rate-card`);
            }
          }}
          text="Back"
          theme="secondary"
          disabled={isFirstStep}
        />,
        <Button
          onClick={() => {
            if (isLastStep) {
              onSubmit();
            } else {
              if (isFirstStep) {
                // TODO: if there is no dimensional pricing,
                // skip to the last step
                navigate(`${urlPrefix}/dimensional-pricing`);
              }
              if (getCurrentStepName() === "dimensional-pricing") {
                navigate(`${urlPrefix}/rate-products`);
              }
            }
          }}
          text={isLastStep ? "Save" : "Next"}
          theme="primary"
          disabled={
            isLastStep
              ? !ctrl.appearsValid() || createRateCardResult.loading
              : false
          }
        />,
      ]}
      children={
        <div>
          {/* Display the current step */}
          <Outlet />
        </div>
      }
    ></Takeover>
  );
};
export const RateCardCreateV2: React.FC = () => {
  return (
    <RateCardContext.Provider>
      <RateCardCreate />
    </RateCardContext.Provider>
  );
};
