import React, { ReactElement } from "react";
import { formatDeliveryMethod } from "lib/billingProvider/formatDeliveryMethod";
import { renderDateTime } from "lib/time";
import { ButtonDropdown, Subtitle, Body } from "design-system";
import { CopyableID } from "components/CopyableID";
import { BillingProviderDeliveryMethod_Enum } from "types/generated-graphql/__types__";

interface TokenID {
  id?: string;
  label?: string;
}

interface ButtonOptions {
  buttonText?: string;
  buttonOnClick: () => void;
}

interface MarketplaceCardProps {
  buttonOptions?: ButtonOptions;
  createdAt?: string;
  logo: ReactElement;
  name: string;
  tokenIDs?: TokenID[];
  isDeltaStream?: boolean;
  deliveryMethods?: BillingProviderDeliveryMethod_Enum[];
}
export const MarketplaceCard: React.FC<MarketplaceCardProps> = ({
  buttonOptions,
  createdAt,
  logo,
  name,
  tokenIDs,
  isDeltaStream,
  deliveryMethods = [],
}) => {
  const formattedDeliveryMethods = deliveryMethods.map((method) =>
    formatDeliveryMethod(method),
  );
  return (
    <div className="min-h-[130px] w-full rounded-medium border border-gray-100 p-12">
      <div className="mb-12 flex">
        <div className="mr-12 h-[50px] w-[50px] rounded-small bg-[#f4f5f7]">
          {logo}
        </div>
        <div className="flex grow flex-col justify-center">
          <Subtitle level={4} className="font-medium">
            {name}
          </Subtitle>
          <Body level={2} className="mb-0 font-normal text-grey-800">
            {createdAt
              ? renderDateTime(new Date(createdAt), false, "Connected on")
              : isDeltaStream
                ? "Connected"
                : "Not connected"}
          </Body>
        </div>
        {buttonOptions && (
          <ButtonDropdown
            icon="ellipsisVertical"
            buttonTheme="gray"
            buttonType="outline"
            className="text-grey-800"
            items={[
              {
                label: buttonOptions.buttonText ?? "Configure",
                onClick: buttonOptions.buttonOnClick,
              },
            ]}
          />
        )}
      </div>

      {isDeltaStream ? (
        <Subtitle level={4} className="text-gray-700">
          {`Delivery method for ${name} is via
          ${formattedDeliveryMethods.length > 0 ? formattedDeliveryMethods.join(", ") : "unknown"}.`}
        </Subtitle>
      ) : (
        <div className="flex flex-col space-y-4">
          {tokenIDs && tokenIDs.length > 0 ? (
            tokenIDs.map(
              ({ id, label }) =>
                id && <CopyableID key={id} id={id} label={label ?? "ID"} />,
            )
          ) : (
            <Body level={2} className="mb-0 font-normal text-grey-800">
              Contact your Metronome representative to set up this marketplace
              integration.
            </Body>
          )}
        </div>
      )}
    </div>
  );
};
