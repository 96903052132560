import { Tooltip } from "design-system";
import type {
  DocumentNode,
  NameNode,
  ObjectFieldNode,
  ValueNode,
} from "graphql";
import React from "react";
import { AuthCheckType } from "types/generated-graphql/__types__";
import { useQuery } from "@apollo/client";
import { useEnvironment } from "lib/environmentSwitcher/context";

const resolversCache = new WeakMap<DocumentNode, any>();

type gqlVariables = { [key: string]: any };

// Apollo will cache the results of queries based on the doc that was passed in
// meaning if the doc changes on render it will [inconsistently] resend the
// query, and occasionally get stuck in an infinite loop. We don't want to
// blindly cache any query or the cache will grow unbounded so we use a WeakMap
// to cache the query for as long as the doc isn't garbage collected and let JS
// handle the cleanup. Since WeakMaps can only use a single object as a key, we
// use nested maps (ending with a sentinel) to cache the query based all of the
// docs and variables passed in, all of which will disappear when one of them
// is garbage collected.
const endSentinel = { _endSentinel: true };
type QueryCacheKey = DocumentNode | gqlVariables | typeof endSentinel;
type QueryCache = WeakMap<QueryCacheKey, DocumentNode | QueryCache>;
const queryCaches: { [env: string]: QueryCache } = {};

function getFromQueryCache(
  environment: string,
  docs: DocumentNode[],
  vars: gqlVariables[],
): DocumentNode | undefined {
  if (!queryCaches[environment]) {
    return undefined;
  }

  let map = queryCaches[environment];
  for (const doc of docs) {
    const nextMap = map.get(doc);
    if (!nextMap || !(nextMap instanceof WeakMap)) {
      return undefined;
    }
    map = nextMap;
  }
  for (const v of vars) {
    const nextMap = map.get(v);
    if (!nextMap || !(nextMap instanceof WeakMap)) {
      return undefined;
    }
    map = nextMap;
  }
  const result = map.get(endSentinel);
  if (!result || result instanceof WeakMap) {
    return undefined;
  }
  return result;
}

function setOnQueryCache(
  environment: string,
  docs: DocumentNode[],
  vars: gqlVariables[],
  result: DocumentNode,
): DocumentNode {
  if (!queryCaches[environment]) {
    queryCaches[environment] = new WeakMap();
  }

  let map = queryCaches[environment];
  for (const doc of docs) {
    let nextMap = map.get(doc);
    if (!nextMap || !(nextMap instanceof WeakMap)) {
      nextMap = new WeakMap();
      map.set(doc, nextMap);
    }
    map = nextMap;
  }
  for (const v of vars) {
    let nextMap = map.get(v);
    if (!nextMap || !(nextMap instanceof WeakMap)) {
      nextMap = new WeakMap();
      map.set(v, nextMap);
    }
    map = nextMap;
  }
  map.set(endSentinel, result);
  return result;
}

function getResolversFromDocument(doc: DocumentNode): {
  type: "query" | "mutation";
  resolver: string;
  args: { name: string; value: any }[];
}[] {
  if (resolversCache.has(doc)) {
    return resolversCache.get(doc);
  }

  const result = doc.definitions
    .map((def) => {
      if (def.kind !== "OperationDefinition") {
        return [];
      }
      return def.selectionSet.selections.map((sel) => {
        if (sel.kind !== "Field") {
          throw new Error("Fragments not supported at top level");
        }
        if (def.operation === "subscription") {
          throw new Error("Subscriptions not supported");
        }
        return {
          type: def.operation,
          resolver: sel.name.value,
          args:
            sel.arguments?.map((arg) => ({
              name: arg.name.value,
              value: arg.value,
            })) ?? [],
        };
      });
    })
    .flat();

  resolversCache.set(doc, result);
  return result;
}

function valueToValueNode(value: string | boolean | number | null): ValueNode {
  if (value === null) {
    return {
      kind: "NullValue",
    };
  } else if (typeof value === "string") {
    return {
      kind: "StringValue",
      value,
    };
  } else if (typeof value === "boolean") {
    return {
      kind: "BooleanValue",
      value,
    };
  } else if (typeof value === "number") {
    return {
      kind: "IntValue",
      value: value.toString(),
    };
  } else {
    // value satisfies never;
    throw new Error(`Unknown type ${typeof value} in valueToValueNode`);
  }
}

// Convert a variable node into a value node using the set of provided
// variables, or undefined if the variable is not defined.
function resolveNode(
  node: ValueNode | undefined,
  variables: any,
): ValueNode | undefined {
  if (!node) {
    return undefined;
  }
  if (
    node.kind === "StringValue" ||
    node.kind === "BooleanValue" ||
    node.kind === "EnumValue" ||
    node.kind === "IntValue" ||
    node.kind === "FloatValue" ||
    node.kind === "NullValue"
  ) {
    return node;
  }

  if (node.kind === "Variable") {
    return variables[node.name.value] === undefined
      ? undefined
      : valueToValueNode(variables[node.name.value]);
  }

  if (node.kind === "ObjectValue") {
    return {
      kind: "ObjectValue",
      fields: node.fields
        .map((f) => {
          const resolved = resolveNode(f.value, variables);
          return resolved
            ? {
                kind: "ObjectField",
                name: f.name,
                value: resolved,
              }
            : undefined;
        })
        .filter((f) => f) as ObjectFieldNode[],
    };
  }

  if (node.kind === "ListValue") {
    return {
      kind: "ListValue",
      values: node.values
        .map((v) => resolveNode(v, variables))
        .filter((v) => v) as ValueNode[],
    };
  }

  // node satisfies never;
}

function gqlName(name: string): NameNode {
  return {
    kind: "Name",
    value: name,
  };
}

// Converts a set of resolvers into a query that checks each against the
// auth_check resolver. The value is reference cached to prevent multiple
// queries for the same set of resolvers.
function resolversToAuthCheckQuery(
  environment: string,
  docs: DocumentNode[],
  variables?: gqlVariables[],
): DocumentNode {
  const cachedValue = getFromQueryCache(environment, docs, variables ?? []);
  if (cachedValue) {
    return cachedValue;
  }

  const resolvers = docs
    .map((doc, i) =>
      getResolversFromDocument(doc).map((resolvers) => ({
        resolvers,
        vars: variables?.[i] ?? {},
      })),
    )
    .flat();
  const authQuery: DocumentNode = {
    kind: "Document",
    definitions: [
      {
        kind: "OperationDefinition",
        operation: "query",
        name: gqlName("AuthCheck"),
        selectionSet: {
          kind: "SelectionSet",
          selections: resolvers.map(
            ({ resolvers: { type, resolver, args }, vars }, i) => ({
              kind: "Field",
              alias: gqlName(`r${i}`),
              name: gqlName("auth_check"),
              selectionSet: {
                kind: "SelectionSet",
                selections: [
                  {
                    kind: "Field",
                    name: gqlName("allowed"),
                  },
                ],
              },
              arguments: [
                {
                  kind: "Argument",
                  name: gqlName("type"),
                  value: {
                    kind: "EnumValue",
                    value:
                      type === "mutation"
                        ? AuthCheckType.Mutation
                        : AuthCheckType.Query,
                  },
                },
                {
                  kind: "Argument",
                  name: gqlName("resolver"),
                  value: {
                    kind: "StringValue",
                    value: resolver,
                  },
                },
                {
                  kind: "Argument",
                  name: gqlName("args"),
                  value: {
                    kind: "ObjectValue",
                    fields: (
                      args
                        .map(({ name, value }) => ({
                          kind: "ObjectField" as const,
                          name: gqlName(name),
                          value: resolveNode(value, vars),
                        }))
                        .filter((x) => x.value !== undefined) as any[]
                    ).concat({
                      // We add this to the query so that the query cache is tied to the current environment.
                      kind: "ObjectField" as const,
                      name: gqlName("__cache__environment"),
                      value: {
                        kind: "StringValue",
                        value: environment,
                      },
                    }),
                  },
                },
              ],
            }),
          ),
        },
      },
    ],
  };

  return setOnQueryCache(environment, docs, variables ?? [], authQuery);
}

function arrayWrap<T>(x?: T | T[]): T[] {
  return x == null ? [] : Array.isArray(x) ? x : [x];
}

const IS_STORYBOOK = Boolean(process.env.IS_STORYBOOK || "false");

export function useAuthCheck(
  doc?: DocumentNode | DocumentNode[],
  initial?: boolean,
  vars?: gqlVariables | gqlVariables[],
) {
  // short-circuit for auth-gated components in storybook
  if (IS_STORYBOOK) {
    return {
      loading: false,
      allowed: initial ?? true,
    };
  }
  // Disabling linter on the hook calls below because the above conditional will
  // evaluate consistently; the hooks will either always or never be called
  // in either full App or per-component Storybook builds

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { environment } = useEnvironment();

  const authCheckQuery = resolversToAuthCheckQuery(
    environment.id,
    arrayWrap(doc),
    arrayWrap(vars),
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const results = useQuery(authCheckQuery, {
    variables: { env: environment.id },
    skip: !doc,
  });

  if (!doc) {
    return {
      loading: false,
      allowed: initial ?? true,
    };
  }

  const loading = results.loading;
  const allowed = loading
    ? initial
    : results.error
      ? true // Fail open
      : Object.values(results.data).every((x: any) => x.allowed);

  return {
    loading,
    allowed,
  };
}

export function gatedAction<T extends { content: any }>(
  doc: DocumentNode | DocumentNode[] | boolean,
  action: T,
): T & { disabled?: boolean } {
  const allowed =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    typeof doc === "boolean" ? doc : useAuthCheck(doc, true).allowed;

  // If the action is already disabled, leave it alone as it may have a tooltip
  if (
    "disabled" in action &&
    typeof action.disabled === "boolean" &&
    action.disabled
  ) {
    return action as T & { disabled: true };
  }

  if (!allowed) {
    return {
      ...action,
      content: (
        <Tooltip content="You do not have permission to perform this action">
          {action.content}
        </Tooltip>
      ),
      disabled: true,
    };
  }

  return action;
}
