import React, { useState } from "react";

import { Table, SortFunctions } from "components/Table";
import { PopConfirm } from "components/PopConfirm";

import { CreateWebhookModal } from "./components/CreateWebhookModal";

import {
  useWebhooksQuery,
  useDeleteWebhookMutation,
  InsertWebhookDocument,
  TestWebhooksDocument,
  WebhooksDocument,
  DeleteWebhookDocument,
} from "./data/webhooks.graphql";
import { PageContainer } from "components/PageContainer";
import { renderDateTime } from "lib/time";
import { EmptyState } from "components/EmptyState";
import { useSnackbar } from "components/Snackbar";
import { Hyperlink } from "design-system";
import { AvatarWithName } from "design-system";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { TestWebhookModal } from "./components/TestWebhookModal";
import { GatedButton, GatedIconButton } from "../../components/GatedButton";

const Webhooks: React.FC = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [testModalOpen, setTestModalOpen] = useState(false);
  const pushMessage = useSnackbar();
  const { environmentType } = useEnvironment();

  const { data, loading } = useWebhooksQuery({
    variables: {
      environment_type: environmentType,
    },
  });
  const [deleteWebhook] = useDeleteWebhookMutation({
    update(cache) {
      cache.evict({ fieldName: "Webhook" });
    },
  });

  const headerAction = (
    <div className="flex flex-row items-center">
      <GatedButton
        doc={TestWebhooksDocument}
        className="ml-12"
        onClick={() => setTestModalOpen(true)}
        text="Send test notification"
        theme="secondary"
        leadingIcon="plus"
        size="sm"
      />
      <GatedButton
        doc={InsertWebhookDocument}
        className="ml-12"
        onClick={() => setCreateModalOpen(true)}
        text="Create webhook"
        theme="primary"
        leadingIcon="plus"
        size="sm"
      />
    </div>
  );

  return (
    <PageContainer
      title="Webhooks"
      action={headerAction}
      authDoc={WebhooksDocument}
    >
      {createModalOpen && (
        <CreateWebhookModal onClose={() => setCreateModalOpen(false)} />
      )}
      {testModalOpen && (
        <TestWebhookModal onClose={() => setTestModalOpen(false)} />
      )}
      {!loading && data?.Webhook.length == 0 ? (
        <EmptyState
          title="You haven't created any webhooks yet."
          subtitle={
            <>
              Create a{" "}
              <Hyperlink
                target="_blank"
                routePath="https://docs.metronome.com/using-the-api/webhooks"
              >
                webhook
              </Hyperlink>{" "}
              to start receiving programmatic notifications of events in your
              Metronome account.
            </>
          }
          buttonAuthDoc={InsertWebhookDocument}
          buttonText="Create webhook"
          onClick={() => setCreateModalOpen(true)}
          icon="gitBranch02"
          buttonIcon="plus"
        />
      ) : (
        <Table
          loading={loading}
          data={data?.Webhook || []}
          columns={[
            {
              header: "Name",
              id: "name",
              render: (h) => h.name,
              align: "left",
              textWrappable: true,
            },
            {
              header: "URL",
              id: "url",
              render: (h) => h.url,
              align: "left",
              textWrappable: true,
            },
            {
              header: "Created by",
              id: "createdBy",
              render: (h) =>
                h.creator ? <AvatarWithName {...h.creator} /> : null,
              sortable: true,
              comparator: SortFunctions.String((h) => h.creator?.name ?? ""),
              align: "left",
            },
            {
              header: "Created",
              id: "createdAt",
              render: (h) => renderDateTime(new Date(h.created_at), true),
              sortable: true,
              comparator: SortFunctions.Date((h) => new Date(h.created_at)),
              align: "left",
            },
            {
              header: "",
              id: "actions",
              render: (h) => (
                <>
                  <PopConfirm
                    content={
                      <>
                        Delete <b>{h.name}</b>? This cannot be undone, and
                        notifications will immediately stop being sent to this
                        URL.
                      </>
                    }
                    onConfirm={async () => {
                      await deleteWebhook({ variables: { id: h.id } });
                      pushMessage({
                        content: "Webhook deleted",
                        type: "success",
                      });
                    }}
                    type="error"
                  >
                    <GatedIconButton
                      doc={DeleteWebhookDocument}
                      theme="tertiary"
                      icon="trash03"
                      size="sm"
                    />
                  </PopConfirm>
                </>
              ),
              align: "right",
            },
          ]}
        />
      )}
    </PageContainer>
  );
};

export default Webhooks;
