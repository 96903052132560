import React from "react";

import { Panel } from "components/Panel";
import { CopyableID } from "components/CopyableID";
import { Definition, DefinitionList } from "components/DefinitionList";

import { useNow, toDayjs, printDateRange } from "lib/date";
import { TopLevelDetailsFragment } from "./data.graphql";
import { useIsDeltaStreamEnabledQuery } from "./data.graphql";

import { ContractStatusBadge } from "../components/ContractStatusBadge";
import { Icon } from "design-system";
import { InternalLink } from "components/Typography";
import { Contract } from "../../../lib/Contract";
import { ContractUsageFilterHistoryTable } from "../components/ContractUsageFilterHistoryTable";
import { formatBillingProvider } from "../../../../../lib/billingProvider/formatBillingProvider";

interface Props {
  contract: TopLevelDetailsFragment;
}

const BILLING_PROVIDER_LABEL = "Billing provider";

const formatFrequency = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const TopLevelDetails: React.FC<Props> = ({ contract }) => {
  const now = useNow();
  const { data: deltaStreamEnabled } = useIsDeltaStreamEnabledQuery();

  function filterBillingProviderIfNotDeltaStreamEnabled(listItem: Definition) {
    if (deltaStreamEnabled?.is_delta_stream_enabled) {
      return true;
    } else {
      return listItem.label !== BILLING_PROVIDER_LABEL;
    }
  }

  const transition = contract.transitions.find(
    (t) => t.from_contract.id === contract.id,
  );

  const billingProvider =
    contract.customer_billing_provider_configuration?.billing_provider;

  return (
    <Panel title="Contract details">
      <div className="flex gap-32 p-12">
        <DefinitionList
          list={[
            {
              label: "Contract ID",
              value: <CopyableID id={contract.id} label="contract ID" />,
            },

            {
              label: "Contract length",
              value: printDateRange(
                now,
                toDayjs(contract.starting_at),
                contract.ending_before
                  ? toDayjs(contract.ending_before)
                  : undefined,
              ),
              badge: <ContractStatusBadge contract={contract} />,
            },

            ...(!!transition
              ? [
                  {
                    label: "Next contract",
                    value: (
                      <InternalLink
                        routePath={Contract.getRoutePath(
                          transition.to_contract,
                        )}
                      >
                        {Contract.getName(transition.to_contract)}
                        <Icon icon="open" className="ml-4 text-primary-500" />
                      </InternalLink>
                    ),
                  },
                ]
              : []),
          ]}
        />
      </div>
      <div className="flex gap-32 p-12">
        <DefinitionList
          list={[
            {
              label: "Usage statement frequency",
              value: formatFrequency(contract.usage_invoice_schedule.frequency),
            },
            {
              label: "Net payment terms",
              value:
                contract.net_payment_terms_days != null
                  ? `${contract.net_payment_terms_days} days`
                  : "--",
            },
            ...(!!contract.usage_filter
              ? [
                  {
                    label: "Usage filter",
                    value: (
                      <ContractUsageFilterHistoryTable
                        usageFilter={contract.usage_filter}
                      />
                    ),
                  },
                ]
              : []),
            {
              label: BILLING_PROVIDER_LABEL,
              value: formatBillingProvider(billingProvider),
            },
          ].filter(filterBillingProviderIfNotDeltaStreamEnabled)}
        />
      </div>
    </Panel>
  );
};
