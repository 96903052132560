import React from "react";

import { NotFoundPage } from "pages/404";
import { useFeatureFlag } from "lib/launchdarkly";

interface Props {
  enabled?: React.ReactNode;
  disabled?: React.ReactNode;
}

/** Render one of the two elements based on whether to show the BM UI */
export const BillableMetricV2Switch: React.FC<Props> = (props) => {
  const createBMUIEnabled = useFeatureFlag<boolean>("create-bm-ui", false);

  return (
    <>
      {createBMUIEnabled
        ? props.enabled ?? <NotFoundPage />
        : props.disabled ?? <NotFoundPage />}
    </>
  );
};
