/* d7c3c378309476e8904f5d8ecf192bb4b0c8e9c4
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRateCardMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  description?: Types.InputMaybe<Types.Scalars['String']>;
  rates?: Types.InputMaybe<Array<Types.RateCardEntryInput> | Types.RateCardEntryInput>;
  aliases?: Types.InputMaybe<Array<Types.RateCardAliasInput> | Types.RateCardAliasInput>;
  fiatCreditTypeId: Types.Scalars['String'];
  creditTypeConversions?: Types.InputMaybe<Array<Types.RateCardCreditTypeConversionInput> | Types.RateCardCreditTypeConversionInput>;
}>;


export type CreateRateCardMutation = { __typename?: 'Mutation', create_rate_card: { __typename?: 'RateCard', id: string } };


export const CreateRateCardDocument = gql`
    mutation CreateRateCard($name: String!, $description: String, $rates: [RateCardEntryInput!], $aliases: [RateCardAliasInput!], $fiatCreditTypeId: String!, $creditTypeConversions: [RateCardCreditTypeConversionInput!]) {
  create_rate_card(
    name: $name
    description: $description
    rates: $rates
    aliases: $aliases
    fiatCreditTypeId: $fiatCreditTypeId
    creditTypeConversions: $creditTypeConversions
  ) {
    id
  }
}
    `;
export type CreateRateCardMutationFn = Apollo.MutationFunction<CreateRateCardMutation, CreateRateCardMutationVariables>;

/**
 * __useCreateRateCardMutation__
 *
 * To run a mutation, you first call `useCreateRateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRateCardMutation, { data, loading, error }] = useCreateRateCardMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      rates: // value for 'rates'
 *      aliases: // value for 'aliases'
 *      fiatCreditTypeId: // value for 'fiatCreditTypeId'
 *      creditTypeConversions: // value for 'creditTypeConversions'
 *   },
 * });
 */
export function useCreateRateCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateRateCardMutation, CreateRateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRateCardMutation, CreateRateCardMutationVariables>(CreateRateCardDocument, options);
      }
export type CreateRateCardMutationHookResult = ReturnType<typeof useCreateRateCardMutation>;
export type CreateRateCardMutationResult = Apollo.MutationResult<CreateRateCardMutation>;
export type CreateRateCardMutationOptions = Apollo.BaseMutationOptions<CreateRateCardMutation, CreateRateCardMutationVariables>;