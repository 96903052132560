import React from "react";
import { useLocation, matchPath } from "react-router-dom";
import { useEnvironment } from "lib/environmentSwitcher/context";

export function useRoutePathMatches(routePathPatterns: string[]) {
  const { prefixUrl } = useEnvironment();
  const { pathname } = useLocation();

  return React.useMemo(() => {
    return routePathPatterns.length
      ? routePathPatterns.some((pattern) =>
          matchPath(prefixUrl(pattern), pathname),
        )
      : false;
  }, [
    prefixUrl,
    pathname,
    // avoid new arrays from invalidating our result if all patterns are equal
    routePathPatterns.join("!"),
  ]);
}
