import React from "react";
import { CustomerTab } from "../../components/CustomerTab";
import { AlertsTable } from "components/AlertsTable";
import { useFetchCustomerAlertsQuery } from "./queries.graphql";
import { useRequiredParam } from "lib/routes/params";
import { EmptyState } from "components/EmptyState";
import { useNavigate } from "lib/useNavigate";
import NotFoundPage from "../../../404";
import { useHasAccessToAlerts } from "../../../../lib/alerts";
import { CreateAlertDocument } from "pages/NewAlert/queries.graphql";

export const AlertsTabContent: React.FC<{ customerId: string }> = ({
  customerId,
}) => {
  const navigate = useNavigate();
  const { data, loading } = useFetchCustomerAlertsQuery({
    variables: {
      customer_id: customerId,
    },
  });

  if (!loading && data?.Customer_by_pk?.alerts.length === 0) {
    return (
      <EmptyState
        title={"This customer doesn't have any alerts yet"}
        subtitle="Create your first alert."
        buttonText="Create alert"
        buttonAuthDoc={CreateAlertDocument}
        onClick={() => navigate("/alerts/new")}
        icon="bell03"
        buttonIcon="plus"
      />
    );
  }

  return (
    <AlertsTable
      alerts={
        data?.Customer_by_pk?.alerts.map((a) => ({
          customer_status: a.customer_status ?? undefined,
          ...a.alert,
        })) ?? []
      }
      loading={loading}
      singleCustomer={true}
    />
  );
};

export const AlertsTab: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const alertsEnabled = useHasAccessToAlerts();

  if (!alertsEnabled) {
    return <NotFoundPage />;
  }

  return (
    <CustomerTab>
      <div className="-mt-12">
        <AlertsTabContent customerId={customerId} />
      </div>
    </CustomerTab>
  );
};

export default AlertsTab;
