/* c6557f986e78d5e61849f19ef9cba127ecd8b797
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductActiveAndFutureCustomersQueryVariables = Types.Exact<{
  product_id: Types.Scalars['uuid'];
}>;


export type ProductActiveAndFutureCustomersQuery = { __typename?: 'Query', Product_by_pk: { __typename?: 'Product', id: string, active_customers_plan_count: number, active_customers_count: number } | null };

export type ProductArbitraryCustomerQueryVariables = Types.Exact<{
  product_id: Types.Scalars['uuid'];
}>;


export type ProductArbitraryCustomerQuery = { __typename?: 'Query', arbitrary_customer: Array<{ __typename?: 'Customer', id: string, name: string }> };


export const ProductActiveAndFutureCustomersDocument = gql`
    query ProductActiveAndFutureCustomers($product_id: uuid!) {
  Product_by_pk(id: $product_id) {
    id
    active_customers_plan_count
    active_customers_count
    __environment_type: environment_type
  }
}
    `;

/**
 * __useProductActiveAndFutureCustomersQuery__
 *
 * To run a query within a React component, call `useProductActiveAndFutureCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductActiveAndFutureCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductActiveAndFutureCustomersQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useProductActiveAndFutureCustomersQuery(baseOptions: Apollo.QueryHookOptions<ProductActiveAndFutureCustomersQuery, ProductActiveAndFutureCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductActiveAndFutureCustomersQuery, ProductActiveAndFutureCustomersQueryVariables>(ProductActiveAndFutureCustomersDocument, options);
      }
export function useProductActiveAndFutureCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductActiveAndFutureCustomersQuery, ProductActiveAndFutureCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductActiveAndFutureCustomersQuery, ProductActiveAndFutureCustomersQueryVariables>(ProductActiveAndFutureCustomersDocument, options);
        }
export type ProductActiveAndFutureCustomersQueryHookResult = ReturnType<typeof useProductActiveAndFutureCustomersQuery>;
export type ProductActiveAndFutureCustomersLazyQueryHookResult = ReturnType<typeof useProductActiveAndFutureCustomersLazyQuery>;
export type ProductActiveAndFutureCustomersQueryResult = Apollo.QueryResult<ProductActiveAndFutureCustomersQuery, ProductActiveAndFutureCustomersQueryVariables>;
export const ProductArbitraryCustomerDocument = gql`
    query ProductArbitraryCustomer($product_id: uuid!) {
  arbitrary_customer: Customer(
    limit: 1
    where: {CustomerPlans: {deleted_at: {_is_null: true}, _or: [{cancellation_date: {_gt: "now()"}}, {cancellation_date: {_is_null: true}}], Plan: {PricedProducts: {product_id: {_eq: $product_id}}}}}
  ) {
    id
    name
    __environment_type: environment_type
  }
}
    `;

/**
 * __useProductArbitraryCustomerQuery__
 *
 * To run a query within a React component, call `useProductArbitraryCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductArbitraryCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductArbitraryCustomerQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useProductArbitraryCustomerQuery(baseOptions: Apollo.QueryHookOptions<ProductArbitraryCustomerQuery, ProductArbitraryCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductArbitraryCustomerQuery, ProductArbitraryCustomerQueryVariables>(ProductArbitraryCustomerDocument, options);
      }
export function useProductArbitraryCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductArbitraryCustomerQuery, ProductArbitraryCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductArbitraryCustomerQuery, ProductArbitraryCustomerQueryVariables>(ProductArbitraryCustomerDocument, options);
        }
export type ProductArbitraryCustomerQueryHookResult = ReturnType<typeof useProductArbitraryCustomerQuery>;
export type ProductArbitraryCustomerLazyQueryHookResult = ReturnType<typeof useProductArbitraryCustomerLazyQuery>;
export type ProductArbitraryCustomerQueryResult = Apollo.QueryResult<ProductArbitraryCustomerQuery, ProductArbitraryCustomerQueryVariables>;