import {
  ChargeCustomFieldsDocument,
  ChargeCustomFieldsQuery,
  CreditGrantCustomFieldsDocument,
  CreditGrantCustomFieldsQuery,
  CustomerCustomFieldsDocument,
  CustomerCustomFieldsQuery,
  ProductCustomFieldsDocument,
  ProductCustomFieldsQuery,
} from "./queries.graphql";
import { DEFAULT_PAGE_SIZE, ReportConfig } from "../reportConfig";

type CustomFieldsData = {
  __typename?: string;
  id: string;
  CustomFields: Array<{
    __typename?: string;
    value: string;
    ManagedFieldKey: {
      __typename?: "ManagedFieldKey";
      id: string;
      key: string;
    };
  }>;
};

type EntityType = "Customer" | "Product" | "Credit Grant" | "Charge";

type MappedEntity = { [key: string]: string };

const generateCustomFieldsReportHelper = (
  datas: CustomFieldsData[],
  entityType: EntityType,
) => {
  const CUSTOM_FIELD_HEADERS = new Set<string>();

  const entities: MappedEntity[] = datas.map((entity) => {
    const customFieldsMap: MappedEntity = {};
    entity.CustomFields.forEach((field) => {
      const header = field.ManagedFieldKey.key;
      CUSTOM_FIELD_HEADERS.add(header);
      customFieldsMap[header] = field.value;
    });

    return {
      id: entity.id,
      ...customFieldsMap,
    };
  });
  // get list of keys in entities
  const customFieldHeaders: string[] = Array.from(CUSTOM_FIELD_HEADERS);

  const headers = ["Entity ID", "Entity Type", ...customFieldHeaders];
  const rows = entities.map((entity) => [
    entity.id,
    entityType,
    ...customFieldHeaders.map((header) => entity[header] ?? ""),
  ]);
  return [headers, ...rows];
};

export const customerCustomFieldsReport: ReportConfig<CustomerCustomFieldsQuery> =
  {
    pageSize: DEFAULT_PAGE_SIZE,
    name: "Customer Custom Fields",
    needsDates: false,
    needsEnvironment: false,
    dataToCSV: (pages) => {
      return generateCustomFieldsReportHelper(
        pages.flatMap((page) =>
          page.Customer.map((customer) => ({
            ...customer,
            CustomFields: customer.CustomerManagedFields,
          })),
        ),
        "Customer",
      );
    },
    queryDocument: CustomerCustomFieldsDocument,
    isAllowedForUser: () => true,
    nextCursor: (page) => page.Customer.at(-1)?.id ?? null,
  };

export const productCustomFieldsReport: ReportConfig<ProductCustomFieldsQuery> =
  {
    pageSize: DEFAULT_PAGE_SIZE,
    name: "Product Custom Fields",
    needsDates: false,
    needsEnvironment: false,
    dataToCSV: (pages) => {
      return generateCustomFieldsReportHelper(
        pages.flatMap((page) =>
          page.Product.map((product) => ({
            ...product,
            CustomFields: product.ProductManagedFields,
          })),
        ),
        "Product",
      );
    },
    queryDocument: ProductCustomFieldsDocument,
    isAllowedForUser: () => true,
    nextCursor: (page) => page.Product.at(-1)?.id ?? null,
  };

export const creditGrantCustomFieldsReport: ReportConfig<CreditGrantCustomFieldsQuery> =
  {
    pageSize: DEFAULT_PAGE_SIZE,
    name: "Credit Grant Custom Fields",
    needsDates: false,
    needsEnvironment: false,
    dataToCSV: (pages) => {
      return generateCustomFieldsReportHelper(
        pages.flatMap((page) =>
          page.CreditGrant.map((creditGrant) => ({
            ...creditGrant,
            CustomFields: creditGrant.CreditGrantManagedFields,
          })),
        ),
        "Credit Grant",
      );
    },
    queryDocument: CreditGrantCustomFieldsDocument,
    isAllowedForUser: () => true,
    nextCursor: (page) => page.CreditGrant.at(-1)?.id ?? null,
  };

export const chargeCustomFieldsReport: ReportConfig<ChargeCustomFieldsQuery> = {
  pageSize: DEFAULT_PAGE_SIZE,
  name: "Charge Custom Fields",
  needsDates: false,
  needsEnvironment: false,
  dataToCSV: (pages) => {
    return pages.flatMap((page) => {
      const pricingFactors = page.Product.flatMap(
        (product) => product.ProductPricingFactors,
      );
      return generateCustomFieldsReportHelper(
        pricingFactors.map((factor) => ({
          ...factor,
          CustomFields: factor.ChargeManagedFields,
        })),
        "Charge",
      );
    });
  },
  queryDocument: ChargeCustomFieldsDocument,
  isAllowedForUser: () => true,
  nextCursor: (page) => page.Product.at(-1)?.id ?? null,
};
