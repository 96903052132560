import React, { useEffect } from "react";
import { Schema } from "../Schema";
import { RateCtrl, useRateController } from "./RateCtrl";
import { CreditInput } from "components/Input";
import { USD_CREDIT_TYPE, displayCreditTypeName } from "lib/credits";
import { NumericInput, Select } from "design-system";
import { CreditType } from "types/credit-types";
import { findCreditType } from "pages/Contracts/lib/CreditTypes";

const useSubscriptionPriceController = useRateController.child(
  Schema.SubscriptionPrice,
  {
    read(parent, isCommitRate) {
      const price = parent.get(isCommitRate ? "commitPrice" : "price");
      return price?.type === "subscription" ? price : undefined;
    },
    write: (child, _parent, isCommitRate) => ({
      [isCommitRate ? "commitPrice" : "price"]: {
        type: "subscription",
        price: child.get("price"),
        quantity: child.get("quantity"),
        isProrated: child.get("isProrated"),
      },
    }),
  },
);

interface Props {
  parent: RateCtrl;
  isCommitRate: boolean;
  fiatCreditTypes: CreditType[];
  customCreditTypes: CreditType[];
}

export const RateSubscription: React.FC<Props> = (props) => {
  const allCreditTypes = [...props.fiatCreditTypes, ...props.customCreditTypes];
  const subscriptionCtrl = useSubscriptionPriceController(
    props.parent,
    props.isCommitRate,
  );
  const selectedCreditTypeId = props.parent.get("creditType")?.id;

  useEffect(() => {
    if (
      allCreditTypes.length === 1 &&
      props.parent.get("creditType")?.id !== allCreditTypes[0].id
    ) {
      props.parent.update({
        creditType: allCreditTypes[0],
      });
    }
  }, [props.parent.get("productId")]);

  return (
    <>
      <Select
        name="Pricing unit"
        placeholder=""
        disabled={props.isCommitRate}
        value={props.parent.get("creditType")?.id ?? allCreditTypes[0].id}
        options={[
          {
            label: "Currency",
            options: props.fiatCreditTypes.map((ct) => ({
              label: displayCreditTypeName(ct),
              value: ct.id,
            })),
          },
          {
            label: "Custom pricing unit",
            options: props.customCreditTypes.map((ct) => ({
              label: displayCreditTypeName(ct),
              value: ct.id,
            })),
          },
        ]}
        onChange={(v) => {
          if (v) {
            const updatedCreditType = findCreditType(v, allCreditTypes);
            props.parent.update({
              creditType: updatedCreditType,
            });
            if (selectedCreditTypeId) {
              subscriptionCtrl.update({
                price: undefined,
                quantity: undefined,
              });
            }
          }
        }}
      />
      <CreditInput
        {...subscriptionCtrl.props.CreditInput("price", {
          name: "Unit Price",
          placeholder: "123.45",
          tooltip: "Set the unit price of the product",
          creditType:
            allCreditTypes.find((ct) => ct.id === selectedCreditTypeId) ??
            USD_CREDIT_TYPE,
          allowZeroAmounts: true,
        })}
      />
      <NumericInput
        {...subscriptionCtrl.props.NumericInput("quantity", {
          name: "Quantity",
          placeholder: "1",
          tooltip: "Set the quantity of the product",
        })}
      />

      {/*
        TODO(ekaragiannis) - add proration toggle for subscriptions
        https://linear.app/getmetronome/project/add-proration-support-for-subscriptions-c7b13e32b192
      <Toggle
        {...subscriptionCtrl.props.Toggle("isProrated", {
          label: "Prorated",
        })}
      />
      */}
    </>
  );
};
