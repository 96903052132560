import React from "react";
import "/src/tenaissance/tenaissance.css";

interface SectionLabelProps {
  /** Label string for the main header */
  title: string;
  /** Label string for the sub-header */
  subtitle?: string;
}

/**
 * !! PARTIAL IMPLEMENTATION !!
 * Section headers provide content and actions related to a section
 * of the current screen.
 *
 * https://www.figma.com/design/qZYCfNZZdsFfxF7SrO8gaN/Metronome-Design-System-2.0?node-id=381-26078&node-type=frame&t=eH9keLRDeKlX0Ixu-0
 *
 * TODO:
 * - implement tooltip
 * - implement secondary text
 */
export const SectionLabel: React.FC<SectionLabelProps> = ({
  title,
  subtitle,
}) => {
  return (
    <div className="flex justify-between">
      <div className="flex-row">
        <div className="text-sm font-semibold text-gray-700">{title}</div>
        {subtitle ? (
          <div className="text-sm font-normal text-gray-600">{subtitle}</div>
        ) : null}
      </div>
    </div>
  );
};
