import React from "react";
import {
  BaseIntegrationCard,
  IntegrationCardProps,
} from "./BaseIntegrationCard";
import { ReactComponent as MicrosoftLogo } from "tenaissance/icons/microsoft.svg";

export type AzureIntegrationCardProps = {
  triggerSetupAction: () => void;
} & Partial<IntegrationCardProps>;

export const AzureIntegrationCard = ({
  triggerSetupAction,
  ...propOverrides
}: AzureIntegrationCardProps) => {
  return (
    <BaseIntegrationCard
      title="Azure Marketplace"
      subheader="Marketplace billing provider"
      description="The Azure marketplace enables qualified partners to market and sell their software to Azure customers."
      buttonOptions={{
        buttonOnClick: triggerSetupAction,
      }}
      {...propOverrides}
      logo={<MicrosoftLogo />}
    />
  );
};
