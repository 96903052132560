import React, { useState, useCallback, useEffect, useRef } from "react";
import styles from "../../index.module.less";
import {
  useDeleteClientConfigMutation,
  useGetClientConfigQuery,
  useSaveClientConfigMutation,
  GetClientConfigQuery,
  GetClientConfigQueryVariables,
  GetClientConfigDocument,
  SaveClientConfigDocument,
  DeleteClientConfigDocument,
} from "../../queries.graphql";
import { Input } from "design-system";
import { BlockSkeleton } from "components/Skeleton";
import { useSnackbar } from "components/Snackbar";
import pluralize from "pluralize";
import { Toggle } from "design-system";
import { ClientConfigKeyEnum_Enum } from "types/generated-graphql/__types__";
import { StripeSetting } from "../StripeSetting";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useAuthCheck } from "lib/useAuthCheck";

const validateInvoiceDaysUntilDueValue = (value: string) => {
  let isValid = true;
  let error: string | undefined = undefined;
  if (Number(value) < 0) {
    isValid = false;
    error = "Must be a non-negative integer.";
  } else if (Number(value) >= 1827) {
    isValid = false;
    error = "Must be less than 1827 days.";
  } else if (isNaN(Number(value))) {
    isValid = false;
    error = "Please enter a valid number.";
  }
  return {
    isValid,
    error,
  };
};

export const InvoiceDaysUntilDueToggle: React.FC<{
  onErrorLoadingData: (error: boolean) => void;
}> = ({ onErrorLoadingData }) => {
  const pushMessage = useSnackbar();
  const { environmentType } = useEnvironment();
  const canSaveClientConfig = !!useAuthCheck(
    [SaveClientConfigDocument, DeleteClientConfigDocument],
    true,
  ).allowed;

  const [
    saveClientConfigInvoiceDaysUntilDue,
    { data: savedDataInvoiceDaysUntilDue },
  ] = useSaveClientConfigMutation();

  const {
    data: dataInvoicesDaysUntilDueValue,
    loading: loadingInvoicesDaysUntilDueValue,
    error: errorInvoicesDaysUntilDueValue,
  } = useGetClientConfigQuery({
    variables: {
      environment_type: environmentType,
      client_config: ClientConfigKeyEnum_Enum.InvoiceDaysUntilDue,
    },
  });

  const [deleteClientConfigMutationInvoicesDaysUntilDueValue] =
    useDeleteClientConfigMutation({
      variables: {
        environment_type: environmentType,
        client_config: ClientConfigKeyEnum_Enum.InvoiceDaysUntilDue,
      },
    });

  const [useCustomDaysUntilDueValue, setUseCustomDaysUntilDueValue] =
    useState<boolean>(false);
  const [oldInvoiceDaysUntilDue, setOldInvoiceDaysUntilDue] = useState<
    string | undefined
  >(undefined);
  const [newInvoiceDaysUntilDue, setNewInvoiceDaysUntilDue] = useState<
    string | undefined
  >(undefined);
  const [daysUntilDueErrorMessage, setDaysUntilDueErrorMessage] = useState<
    string | undefined
  >(undefined);
  const [daysUntilDueSaveSuccessful, setDaysUntilDueSaveSuccessful] =
    useState<boolean>(false);
  const [daysUntilDueInputWidth, setDaysUntilDueInputWidth] =
    useState<number>(0);

  const hiddenDaysUntilDueInputRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        setDaysUntilDueInputWidth(node.clientWidth);
      }
    },
    [newInvoiceDaysUntilDue],
  );
  const daysUntilDueInputRef = useRef<HTMLInputElement>(null);

  const saveInvoiceDaysUntilDueClientConfig = async (configValue: string) => {
    try {
      await saveClientConfigInvoiceDaysUntilDue({
        variables: {
          environment_type: environmentType,
          client_config: ClientConfigKeyEnum_Enum.InvoiceDaysUntilDue,
          value: configValue,
        },
        optimisticResponse: {
          insert_ClientConfig_one: {
            __typename: "ClientConfig",
            id: dataInvoicesDaysUntilDueValue?.ClientConfig?.[0]?.id ?? "new",
            value: configValue,
          },
        },
        update: (store, { data }) => {
          // If we're inserting instead of updating, we need to add the new data to the store.
          // If we were updating then apollo will have already updated the store.
          if (
            !data?.insert_ClientConfig_one ||
            data?.insert_ClientConfig_one.id === "new"
          ) {
            return;
          }
          store.writeQuery<GetClientConfigQuery, GetClientConfigQueryVariables>(
            {
              data: {
                ClientConfig: [data?.insert_ClientConfig_one],
              },
              query: GetClientConfigDocument,
              variables: {
                environment_type: environmentType,
                client_config: ClientConfigKeyEnum_Enum.InvoiceDaysUntilDue,
              },
            },
          );
        },
      });

      setDaysUntilDueSaveSuccessful(true);
      pushMessage({
        content: (
          <span className={styles.snackbarSuccess}>
            Saved custom days until due:{" "}
            <strong>
              {newInvoiceDaysUntilDue}{" "}
              {pluralize("day", Number(newInvoiceDaysUntilDue))}
            </strong>
          </span>
        ),
        type: "success",
      });
    } catch (e) {
      pushMessage({
        content: "Failed to save change to custom days until due.",
        type: "error",
      });
      throw e;
    }
  };
  const daysUntilDueToggleOnChange = async (checked: boolean) => {
    setUseCustomDaysUntilDueValue(checked);
    if (checked) {
      setTimeout(() => daysUntilDueInputRef.current?.focus(), 0);
    } else {
      setNewInvoiceDaysUntilDue(undefined);
      setDaysUntilDueSaveSuccessful(false);
      setDaysUntilDueErrorMessage(undefined);
      if (oldInvoiceDaysUntilDue) {
        try {
          await deleteClientConfigMutationInvoicesDaysUntilDueValue({
            variables: {
              environment_type: environmentType,
              client_config: ClientConfigKeyEnum_Enum.InvoiceDaysUntilDue,
            },
          });
          setOldInvoiceDaysUntilDue(undefined);
          pushMessage({
            content: "Removed custom days until due",
            type: "success",
          });
        } catch (e) {
          pushMessage({
            content: "Failed to save change to custom days until due",
            type: "error",
          });
          throw e;
        }
      }
    }
  };
  const daysUntilDueInputOnKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      const { isValid, error } = validateInvoiceDaysUntilDueValue(
        event.currentTarget.value,
      );
      setDaysUntilDueErrorMessage(error);
      if (
        isValid &&
        event.currentTarget.value &&
        event.currentTarget.value !== oldInvoiceDaysUntilDue
      ) {
        await saveInvoiceDaysUntilDueClientConfig(event.currentTarget.value);
      }
    }
  };

  useEffect(() => {
    if (errorInvoicesDaysUntilDueValue) {
      return;
    }
    setOldInvoiceDaysUntilDue(
      dataInvoicesDaysUntilDueValue?.ClientConfig?.[0]?.value,
    );
    if (dataInvoicesDaysUntilDueValue?.ClientConfig?.[0]?.value) {
      setUseCustomDaysUntilDueValue(true);
      if (newInvoiceDaysUntilDue === undefined) {
        setNewInvoiceDaysUntilDue(
          dataInvoicesDaysUntilDueValue?.ClientConfig?.[0]?.value,
        );
      }
    } else if (
      savedDataInvoiceDaysUntilDue &&
      savedDataInvoiceDaysUntilDue?.insert_ClientConfig_one?.value
    ) {
      setOldInvoiceDaysUntilDue(
        savedDataInvoiceDaysUntilDue?.insert_ClientConfig_one?.value,
      );
    }
  }, [
    errorInvoicesDaysUntilDueValue,
    dataInvoicesDaysUntilDueValue,
    savedDataInvoiceDaysUntilDue,
  ]);

  useEffect(() => {
    if (
      !errorInvoicesDaysUntilDueValue &&
      newInvoiceDaysUntilDue !== undefined
    ) {
      const { isValid, error } = validateInvoiceDaysUntilDueValue(
        newInvoiceDaysUntilDue,
      );
      setDaysUntilDueErrorMessage(error);
      if (isValid && newInvoiceDaysUntilDue !== oldInvoiceDaysUntilDue) {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        const delayDebounceFn = setTimeout(async () => {
          await saveInvoiceDaysUntilDueClientConfig(newInvoiceDaysUntilDue);
        }, 3000);
        return () => clearTimeout(delayDebounceFn);
      }
    }
  }, [newInvoiceDaysUntilDue, oldInvoiceDaysUntilDue]);

  if (errorInvoicesDaysUntilDueValue) {
    onErrorLoadingData(true);
  }

  const toggle = (
    <Toggle
      disabled={!canSaveClientConfig}
      label="Customize days until invoices are due (default 30)"
      checked={useCustomDaysUntilDueValue}
      onChange={daysUntilDueToggleOnChange}
    />
  );
  return (
    <StripeSetting
      setting={toggle}
      helpText="Stripe invoices will be due this many days after creation. This setting has no effect for customers configured to be charged automatically."
    >
      {loadingInvoicesDaysUntilDueValue && useCustomDaysUntilDueValue && (
        <div className={styles.blockSkeleton}>
          <BlockSkeleton />
        </div>
      )}
      {!loadingInvoicesDaysUntilDueValue &&
        dataInvoicesDaysUntilDueValue &&
        useCustomDaysUntilDueValue && (
          <div className={styles.invoiceDaysUntilDue}>
            <Input
              className={styles.input}
              placeholder="Number of days"
              value={newInvoiceDaysUntilDue ?? ""}
              onChange={(value) => {
                setNewInvoiceDaysUntilDue(value);
                setDaysUntilDueSaveSuccessful(false);
              }}
              onKeyDown={daysUntilDueInputOnKeyDown}
              error={daysUntilDueErrorMessage}
              success={!daysUntilDueErrorMessage && daysUntilDueSaveSuccessful}
              maxLength={4}
              ref={daysUntilDueInputRef}
            />
            <div
              className={styles.days}
              style={{
                left: `${daysUntilDueInputWidth + 16}px`,
                display: newInvoiceDaysUntilDue?.length ? "flex" : "none",
              }}
            >
              {pluralize("day", Number(newInvoiceDaysUntilDue))}
            </div>
            <div ref={hiddenDaysUntilDueInputRef} className={styles.hidden}>
              {newInvoiceDaysUntilDue}
            </div>
          </div>
        )}
    </StripeSetting>
  );
};
