import React from "react";
import styles from "./index.module.less";
import { Body, Hyperlink } from "design-system";
import { ReactComponent as StripeLogo } from "../../stripe.svg";
import { ReactComponent as AWSMarketplaceLogo } from "../../aws_marketplace.svg";
import { ReactComponent as AzureMarketplaceLogo } from "../../azure.svg";
import { ReactComponent as SalesforceLogo } from "../../salesforce.svg";
import { ReactComponent as NetSuiteLogo } from "../../netsuite.svg";
import { ReactComponent as ChargebeeLogo } from "../../chargebee.svg";
import { ReactComponent as QuickBooksOnlineLogo } from "../../quickbooks_online.svg";
import { ReactComponent as WorkdayLogo } from "../../workday.svg";
import { BillingProviderEnum_Enum } from "types/generated-graphql/__types__";
import { Icon, Tooltip } from "design-system";
import classnames from "classnames";
import { DocumentNode } from "graphql";
import { GatedButton } from "../../../../components/GatedButton";
import { Button, ButtonProps } from "../../../../tenaissance/components/Button";

export type IntegrationCardType =
  | BillingProviderEnum_Enum
  | "SALESFORCE"
  | "NETSUITE"
  | "CHARGEBEE"
  | "QUICKBOOKS_ONLINE"
  | "WORKDAY";

type IntegrationCardProps = React.PropsWithChildren<{
  title?: string;
  titleNode?: React.ReactNode;
  type: IntegrationCardType;
  info?: React.ReactNode;
  connectionDetails?: React.ReactNode;
  buttonText?: string;
  buttonTooltip?: string;
  buttonTheme?: ButtonProps["theme"];
  buttonOnClick?: () => void;
  buttonDisabled?: boolean;
  buttonLoading?: boolean;
  buttonAuthDoc?: DocumentNode | DocumentNode[];
  docLink?: string;
  showDescription?: boolean;
}>;

export const renderLogo = (type: IntegrationCardType) => {
  switch (type) {
    case BillingProviderEnum_Enum.Stripe: {
      return (
        <div className={classnames(styles.logo, styles.stripeLogo)}>
          <StripeLogo />
        </div>
      );
    }
    case BillingProviderEnum_Enum.AwsMarketplace: {
      return (
        <div className={classnames(styles.logo, styles.awsLogo)}>
          <AWSMarketplaceLogo />
        </div>
      );
    }
    case BillingProviderEnum_Enum.Azure: {
      return (
        <div className={classnames(styles.logo, styles.azureLogo)}>
          <AzureMarketplaceLogo />
        </div>
      );
    }
    case "SALESFORCE": {
      return (
        <div className={classnames(styles.logo)}>
          <div className="h-[32px] w-[32px] pt-8">
            <SalesforceLogo />
          </div>
        </div>
      );
    }
    case "NETSUITE": {
      return (
        <div className={classnames(styles.logo)}>
          <NetSuiteLogo />
        </div>
      );
    }
    case "CHARGEBEE": {
      return (
        <div className={classnames(styles.logo)}>
          <ChargebeeLogo />
        </div>
      );
    }
    case "QUICKBOOKS_ONLINE": {
      return (
        <div className={classnames(styles.logo, styles.quickBooksOnlineLogo)}>
          <QuickBooksOnlineLogo />
        </div>
      );
    }
    case "WORKDAY": {
      return (
        <div className={classnames(styles.logo, styles.logo)}>
          <WorkdayLogo />
        </div>
      );
    }
  }
};

const getDescription = (type: IntegrationCardType) => {
  switch (type) {
    case BillingProviderEnum_Enum.Stripe:
      return "Use Stripe as a billing provider. Metronome will bill customers through Stripe.";
    case BillingProviderEnum_Enum.AwsMarketplace:
      return "Use AWS Marketplace as a billing provider. Metronome will bill customers through the Marketplace.";
    case BillingProviderEnum_Enum.Azure:
      return "Use Azure Marketplace as a billing provider. Metronome will bill customers through the Marketplace.";
    case "SALESFORCE":
      return "Connect this customer’s Metronome data to Salesforce. This will link their Metronome customer_id to your Salesforce account. Be sure to have the Metronome Salesforce Connector app installed first.";
    case "NETSUITE":
      return "Connect Metronome with NetSuite to sync billing and invoice data.";
    case "CHARGEBEE":
      return "Map Metronome invoice data with ChargeBee for easy lookups and seamless revenue recognition.";
    case "QUICKBOOKS_ONLINE":
      return "Forward invoices from Metronome to QuickBooks Online";
    case "WORKDAY":
      return "Forward invoices from Metronome to Workday";
  }
};

export const IntegrationCard: React.FC<IntegrationCardProps> = ({
  children,
  type,
  ...props
}) => {
  const { showDescription = true } = props;
  const buttonTheme = props.buttonTheme ?? "secondary";
  const buttonLoading = props.buttonLoading ?? false;

  const ButtonComponent = props.buttonAuthDoc ? GatedButton : Button;

  let button = props.buttonText ? (
    <ButtonComponent
      theme={buttonTheme}
      onClick={props.buttonOnClick}
      disabled={props.buttonDisabled}
      className={styles.button}
      loading={buttonLoading}
      doc={props.buttonAuthDoc}
      text={props.buttonText}
    />
  ) : null;

  if (button && props.buttonTooltip) {
    button = <Tooltip content={props.buttonTooltip}>{button}</Tooltip>;
  }

  return (
    <div className={styles.integrationCard}>
      <div className={styles.header}>
        {props.titleNode}
        {props.docLink && (
          <div className={styles.seeDocs}>
            <Hyperlink
              className={styles.seeDocs}
              routePath={props.docLink}
              target="_blank"
            >
              <Tooltip content="Open Metronome docs">
                <Icon icon="book" />
              </Tooltip>
            </Hyperlink>
          </div>
        )}
      </div>
      <Body level={2} className={styles.description}>
        {showDescription && getDescription(type)}
      </Body>
      <div className={styles.card}>
        <div className={styles.cardTop}>
          <div className={styles.left}>
            {renderLogo(type)}
            {props.info && props.info}
          </div>
          <div className={styles.right}>
            {props.connectionDetails && (
              <div className={styles.connectionDetails}>
                {props.connectionDetails}
              </div>
            )}
            {button}
          </div>
        </div>
        {children && <div className={styles.content}>{children}</div>}
      </div>
    </div>
  );
};
