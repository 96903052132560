import React, { forwardRef, useImperativeHandle } from "react";

import { useEventCountsQuery, useEventsQuery } from "../../queries.graphql";

import { Caption, Subtitle } from "design-system";

import styles from "./index.module.less";
import { useEnvironment } from "lib/environmentSwitcher/context";
import Graph from "components/Graph";
import classNames from "classnames";
import { renderDateRange } from "lib/time";
import { dayjs } from "lib/dayjs";

// How many events to search for to show as reference lines on the chart. In practice, this is way more than we need
// We'd usually expect only 1 or 2 events to match a txn ID, but we want to be safe
const MATCHING_TRANSACTION_IDS_LIMIT = 100;

type FigureProps = {
  value: number;
  label: string;
  className: string;
};

const Figure: React.FC<FigureProps> = (props) => (
  <div className={classNames(styles.figure, props.className)}>
    <div className={styles.labelLine}>
      <div className={styles.circle} />
      <Subtitle>
        {props.value.toLocaleString()}{" "}
        <span className={styles.small}>events</span>
      </Subtitle>
    </div>
    <Caption level={2}>{props.label}</Caption>
  </div>
);

export type EventsGraphRef = {
  refetch: () => void;
};

type EventsToShow = "only_duplicates" | "only_non_duplicates" | "all";

type EventsGraphProps = {
  startingAfter: Date;
  endingBefore: Date;
  ingest_aliases?: string[];
  duplicates?: boolean;
  transaction_ids?: string[];
  billableMetricIDs?: string[];
  ref?: React.Ref<EventsGraphRef>;
};

export const EventsGraph: React.FC<EventsGraphProps> = forwardRef(
  (props: EventsGraphProps, ref) => {
    const { environmentType } = useEnvironment();

    let eventsToShow: EventsToShow = "all";
    if (typeof props.duplicates === "boolean") {
      eventsToShow = props.duplicates
        ? "only_duplicates"
        : "only_non_duplicates";
    }

    const { data, loading, refetch } = useEventCountsQuery({
      variables: {
        starting_after: props.startingAfter.toISOString(),
        ending_before: props.endingBefore.toISOString(),
        ingest_aliases: props.ingest_aliases,
        environment_type: environmentType,
        billable_metric_ids: props.billableMetricIDs,
      },
    });

    const eventsMatchingTransactionIDs = useEventsQuery({
      variables: {
        limit: MATCHING_TRANSACTION_IDS_LIMIT,
        environment_type: environmentType,
        starting_after: props.startingAfter.toISOString(),
        ending_before: props.endingBefore.toISOString(),
        ingest_aliases: props.ingest_aliases,
        transaction_ids: props.transaction_ids,
        duplicates: props.duplicates,
        billable_metric_ids: props.billableMetricIDs,
      },
      skip: !props.transaction_ids,
    });

    useImperativeHandle(ref, () => ({
      refetch: () => {
        void refetch();
      },
    }));

    const totalNonDuplicates = (data?.non_duplicates ?? []).reduce(
      (acc, cur) => acc + Number(cur.count),
      0,
    );

    const totalDuplicates = (data?.duplicates ?? []).reduce(
      (acc, cur) => acc + Number(cur.count),
      0,
    );

    const totalEvents = totalNonDuplicates + totalDuplicates;

    let title = "Your recent usage";
    let subtitle = renderDateRange(
      props.startingAfter,
      props.endingBefore,
      { isUtc: true },
      false,
    );
    if (dayjs(props.endingBefore).isSame(dayjs().add(1, "day"), "day")) {
      // Is today
      const diff = dayjs(props.endingBefore).diff(
        dayjs(props.startingAfter),
        "day",
      );
      if (diff === 1) {
        subtitle = "Today";
      } else {
        subtitle = `Last ${diff} days`;
      }
    } else {
      title = "Your usage";
    }

    const duplicatesArea = {
      color: "#E2BE3A",
      name: "Duplicates",
      data: (data?.duplicates ?? []).map((e) => ({
        date: dayjs(e.starting_on).endOf("day").toDate(),
        value: Number(e.count),
      })),
    };

    const nonDuplicatesArea = {
      color: "#22B796",
      name: "Non duplicate events",
      data: (data?.non_duplicates ?? []).map((e) => ({
        date: dayjs(e.starting_on).endOf("day").toDate(),
        value: Number(e.count),
      })),
    };

    if (duplicatesArea.data.length !== nonDuplicatesArea.data.length) {
      throw new Error("Data length mismatch");
    }

    const totalEventsArea = {
      color: "#22B796",
      name: "Total events",
      data: duplicatesArea.data.map((e, i) => ({
        date: e.date,
        value: e.value + nonDuplicatesArea.data[i].value,
      })),
    };

    let areas = [totalEventsArea, duplicatesArea];
    if (eventsToShow === "only_duplicates") {
      areas = [duplicatesArea];
    } else if (eventsToShow === "only_non_duplicates") {
      areas = [nonDuplicatesArea];
    }

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div>
            <Subtitle>{title}</Subtitle>
            <Caption level={2}>{subtitle}</Caption>
          </div>
          {!loading ? (
            <div className={styles.totals}>
              {eventsToShow !== "only_duplicates" ? (
                <Figure
                  label="Total events"
                  value={
                    eventsToShow === "all" ? totalEvents : totalNonDuplicates
                  }
                  className={styles.totalEvents}
                />
              ) : null}
              {eventsToShow !== "only_non_duplicates" ? (
                <Figure
                  label="Duplicates"
                  value={totalDuplicates}
                  className={styles.duplicates}
                />
              ) : null}
            </div>
          ) : null}
        </div>
        <div className={styles.chart}>
          <Graph
            isUTC={true}
            loading={loading}
            areas={areas}
            referenceMarkers={(
              eventsMatchingTransactionIDs.data?.mri_events ?? []
            ).map((e) => ({
              date: new Date(e.timestamp),
              label: e.transaction_id,
            }))}
          />
        </div>
      </div>
    );
  },
);
