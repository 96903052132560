import { AppShell } from "../../../components/PageContainer";
import React from "react";
import { UI_MODE, useUIMode } from "../../../lib/useUIMode";
import NotFoundPage from "../../../pages/404";
import { useRequiredParam } from "../../../lib/routes/params";
import { useApolloResp } from "../../../pages/Contracts/lib/ApolloResp";
import { useGetCustomerNameQuery } from "../../../pages/Customer/components/EditCustomerNameButton/queries.graphql";
import { Breadcrumbs } from "../../../lib/breadcrumbs";

const tabs = (mode: UI_MODE) => {
  const allTabs = {
    overview: {
      name: "Overview",
      path: "overview",
      enabledModes: ["contracts-only", "plans-only", "contracts-and-plans"],
    },
    invoices: {
      name: "Invoices",
      path: `invoices`,
      enabledModes: ["contracts-only", "plans-only", "contracts-and-plans"],
    },
    "commits-and-credits": {
      name:
        mode === "contracts-only"
          ? "Commits and credits"
          : "Contract commits and credits",
      path: `commits-and-credits`,
      enabledModes: ["contracts-only", "contracts-and-plans"],
    },
    credits: {
      name: mode === "plans-only" ? "Credits" : "Plan credits",
      path: `credits`,
      enabledModes: ["plans-only", "contracts-and-plans"],
    },
    alerts: {
      name: "Alerts",
      path: `alerts`,
      enabledModes: ["contracts-only", "plans-only", "contracts-and-plans"],
    },
    usage: {
      name: "Usage",
      path: `usage`,
      enabledModes: ["contracts-only", "plans-only", "contracts-and-plans"],
    },
    settings: {
      name: "Settings",
      path: `settings`,
      enabledModes: ["contracts-only", "plans-only", "contracts-and-plans"],
    },
    "audit-logs": {
      name: "Audit log",
      path: `audit-logs`,
      enabledModes: ["contracts-only", "plans-only", "contracts-and-plans"],
    },
  };
  return tabPathsForMode[mode].map((path) => allTabs[path]);
};
const tabPathsForMode = {
  "contracts-only": [
    "overview",
    "invoices",
    "commits-and-credits",
    "alerts",
    "usage",
    "settings",
    "audit-logs",
  ],
  "plans-only": [
    "overview",
    "invoices",
    "credits",
    "alerts",
    "usage",
    "settings",
    "audit-logs",
  ],
  "contracts-and-plans": [
    "overview",
    "invoices",
    "commits-and-credits",
    "credits",
    "alerts",
    "usage",
    "settings",
    "audit-logs",
  ],
  off: [],
} as const;

export const Customer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { newUIEnabled, mode } = useUIMode();
  const customerId = useRequiredParam("customerId");
  const customerNameResp = useApolloResp(
    useGetCustomerNameQuery({ variables: { customer_id: customerId } }),
  );

  if (!newUIEnabled) {
    return <NotFoundPage />;
  }
  return (
    <AppShell
      title={
        customerNameResp.state === "success"
          ? customerNameResp.Customer.name
          : ""
      }
      headerProps={{
        tabs: tabs(mode),
        basePath: `customers/${customerId}`,
        breadcrumbs: Breadcrumbs.from({
          label: "Customers",
          routePath: "/customers",
        }),
      }}
    >
      {children}
    </AppShell>
  );
};
