import { PageContainer } from "components/PageContainer";
import { dayjs } from "lib/dayjs";
import { FormController } from "lib/FormController";
import { useNavigate } from "lib/useNavigate";
import React, { useMemo } from "react";

import { Schema } from "../Schema";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { RateCardDetailsSection } from "./RateCardDetails";
import { Rates } from "./Rates";
import { FooterBar } from "pages/Contracts/Customer/Contracts/Create/components/FooterBar";
import { useSnackbar } from "components/Snackbar";
import { useRequiredParam } from "lib/routes/params";
import {
  useGetCustomCreditTypesQuery,
  useRateCardEditDetailsQuery,
  useUpdateRateCardMutation,
} from "./data.graphql";
import {
  formAliasesAsGraphqlAliases,
  formRatesAsGraphqlRates,
} from "./RateCardCreate";
import { reportToSentry } from "lib/errors/sentry";
import { useGigaRateCardEnabled } from "pages/Contracts/lib/GigaRateCard";
import { useRateCardQuery } from "../RateCardsDetails/data.graphql";
import { Tier } from "types/generated-graphql/__types__";
import { CreditType } from "types/credit-types";
import { USD_CREDIT_TYPE } from "lib/credits";

function useController(
  rateCard: Partial<Schema.Types.RateCardInput>,
  snapshotKey: string,
) {
  const useRateCardEditController = FormController.createHook(
    Schema.RateCardInput,
    {
      init({
        existingRateCard,
        snapshotKey,
      }: {
        existingRateCard: Partial<Schema.Types.RateCardInput>;
        snapshotKey: string;
      }) {
        const snapshot = FormController.parseJsonSnapshot(
          Schema.RateCardInput,
          sessionStorage.getItem(snapshotKey),
        );
        return { ...existingRateCard, ...snapshot };
      },
    },
  );

  const ctrl = useRateCardEditController({
    existingRateCard: { ...rateCard, rates: [] },
    snapshotKey,
  });

  // save snapshot on every change of the form
  React.useEffect(() => {
    sessionStorage.setItem(snapshotKey, JSON.stringify(ctrl.snapshot()));
  }, [ctrl]);

  function clearSnapshot() {
    sessionStorage.removeItem(snapshotKey);
  }

  return {
    ctrl,
    clearSnapshot,
  };
}

const EditRateCardForm: React.FC<{
  rateCard: Schema.Types.RateCardInput;
  rateCardId: string;
  fiatCreditType: CreditType;
  customCreditTypes: CreditType[];
  creditTypesLoading?: boolean;
  creditTypesError?: Error;
}> = ({
  rateCard,
  rateCardId,
  fiatCreditType,
  customCreditTypes,
  creditTypesLoading,
  creditTypesError,
}) => {
  const snapshotKey = `rate-card-edit ${rateCardId}`;
  const { ctrl, clearSnapshot } = useController(
    { ...rateCard, rates: [] },
    snapshotKey,
  );

  const gigaRateCardEnabled = useGigaRateCardEnabled();

  const navigate = useNavigate();
  const backToRateCardList = () => {
    clearSnapshot();
    navigate("/contract-pricing/rate-cards");
  };

  const [updateRateCardMutation, updateRateCardResult] =
    useUpdateRateCardMutation();

  const pushMessage = useSnackbar();
  const onSubmit = FormController.useSubmitHandler(ctrl, async (valid) => {
    try {
      const result = await updateRateCardMutation({
        variables: {
          rateCardId,
          name: valid.name.trim(),
          description: valid.description?.trim(),
          additionalRates: formRatesAsGraphqlRates(valid.rates),
          aliases: formAliasesAsGraphqlAliases(valid.aliases ?? []),
        },
        update(cache) {
          cache.evict({ fieldName: "products_and_rate_cards" });
          cache.evict({ fieldName: "contract_pricing" });
        },
      });
      const id = result.data?.update_rate_card?.id;
      if (id) {
        clearSnapshot();
        navigate(`/contract-pricing/rate-cards/${rateCardId}`);
        pushMessage({
          type: "success",
          content: "Rate card updated",
        });
      }
    } catch (e) {
      reportToSentry(e);
      pushMessage({
        content: `Failed to edit rate card: ${e}`,
        type: "error",
      });
    }
  });

  return (
    <form className="h-full" onSubmit={onSubmit}>
      <div className="-mx-12 flex h-full flex-col overflow-hidden">
        <div className="grow overflow-auto">
          <RateCardDetailsSection
            ctrl={ctrl}
            isEdit
            customCreditTypes={customCreditTypes}
            creditTypesLoading={creditTypesLoading}
            creditTypesError={creditTypesError}
          />
          {!gigaRateCardEnabled && (
            <Rates
              ctrl={ctrl}
              existingRates={rateCard.rates}
              fiatCreditType={fiatCreditType}
              customCreditTypes={customCreditTypes}
              creditTypesLoading={creditTypesLoading}
            />
          )}
        </div>

        <FooterBar
          right={
            <>
              <Button
                onClick={backToRateCardList}
                text="Cancel"
                theme="linkGray"
              />
              <Button
                disabled={!ctrl.appearsValid() || updateRateCardResult.loading}
                text="Save"
                theme="primary"
                type="submit"
              />
            </>
          }
        ></FooterBar>
      </div>
    </form>
  );
};

export const EditRateCard: React.FC = () => {
  const rateCardId = useRequiredParam("id");
  const gigaRateCardEnabled = useGigaRateCardEnabled();

  const rateCardReq = useRateCardQuery({
    variables: { id: rateCardId },
    skip: !gigaRateCardEnabled,
  });

  const rateCardEditDetailsReq = useRateCardEditDetailsQuery({
    variables: { id: rateCardId, rateCountLimit: "1000" },
    skip: gigaRateCardEnabled,
  });

  // do not query for rates if giga rate card enabled
  const rateCard = gigaRateCardEnabled
    ? rateCardReq.data?.contract_pricing.rate_card
    : rateCardEditDetailsReq.data?.products_and_rate_cards.rate_card;

  const navigate = useNavigate();
  const backToRateCardList = () => {
    navigate("/contract-pricing/rate-cards");
  };

  const convertTieredRates = (tiers: Tier[]): Schema.Types.Tier[] => {
    return tiers.reduce<Schema.Types.Tier[]>((acc, t, index) => {
      // previous last unit will never be undefined, but need to do this to satisfy TS
      const prevLastUnit = index > 0 ? acc[index - 1].lastUnit || 0 : 0;

      const lastUnit =
        t.size !== undefined ? prevLastUnit + Number(t.size) : undefined;

      acc.push({
        lastUnit: lastUnit,
        unitPrice: Number(t.unit_price),
      });

      return acc;
    }, []);
  };

  const { data, loading, error } = useGetCustomCreditTypesQuery();
  const customCreditTypes = data?.CreditType ?? [];
  const fiatCreditType = rateCard?.fiat_credit_type ?? USD_CREDIT_TYPE;
  const allCreditTypesMapById = Object.fromEntries(
    [fiatCreditType, ...customCreditTypes].map((ct) => [ct.id, ct]),
  );

  const rateCardInput: Schema.Types.RateCardInput | undefined = useMemo(() => {
    if (!rateCard) return undefined;
    const input: Schema.Types.RateCardInput = {
      name: rateCard.name || "",
      description: rateCard.description || undefined,
      rates: [],
      fiatCreditTypeId: rateCard.fiat_credit_type.id,
      fiatCreditTypeName: rateCard.fiat_credit_type.name,
      creditTypeConversions: rateCard.credit_type_conversions?.map(
        (conversion) => ({
          custom_credit_type_id: conversion.custom_credit_type.id,
          custom_credit_type_name: conversion.custom_credit_type.name,
          fiat_per_custom_credit: Number(conversion.fiat_per_custom_credit),
        }),
      ),
    };

    if (
      !gigaRateCardEnabled &&
      rateCard.__typename === "RateCard" &&
      !rateCard.rate_schedule.next_page
    ) {
      const START_OF_TODAY_UTC = dayjs.utc().startOf("day").toISOString();
      input.rates = rateCard.rate_schedule.scalar_segments.map(
        (r): Schema.Types.Rate => {
          switch (r.rate.__typename) {
            case "ScalarFlatRate":
              return {
                productId: r.product_id,
                startingAt: r.starting_at ?? START_OF_TODAY_UTC,
                entitled: r.entitled ? "enable" : "disable",
                id: r.id,
                price: {
                  type: "flat",
                  price: Number(r.rate.unit_price),
                },
                creditType: allCreditTypesMapById[r.rate.credit_type_id],
              };
            case "SubscriptionRate":
              return {
                productId: r.product_id,
                startingAt: r.starting_at ?? START_OF_TODAY_UTC,
                entitled: r.entitled ? "enable" : "disable",
                id: r.id,
                price: {
                  type: "subscription",
                  price: Number(r.rate.unit_price),
                  quantity: Number(r.rate.quantity),
                  isProrated: r.rate.is_prorated,
                },
                creditType: allCreditTypesMapById[r.rate.credit_type.id],
              };
            case "PercentageRate":
              return {
                productId: r.product_id,
                startingAt: r.starting_at ?? START_OF_TODAY_UTC,
                entitled: r.entitled ? "enable" : "disable",
                id: r.id,
                price: {
                  type: "percentage",
                  useListPrices: r.rate.use_list_prices,
                  fraction: Number(r.rate.fraction),
                },
              };
            case "TieredRate":
              return {
                productId: r.product_id,
                startingAt: r.starting_at ?? START_OF_TODAY_UTC,
                id: r.id,
                entitled: r.entitled ? "enable" : "disable",
                price: {
                  tiers: convertTieredRates(r.rate.tiers),
                  type: "tiered",
                },
                creditType: allCreditTypesMapById[r.rate.credit_type.id],
              };
            case "CustomRate":
              return {
                productId: r.product_id,
                startingAt: r.starting_at ?? START_OF_TODAY_UTC,
                entitled: r.entitled ? "enable" : "disable",
                id: r.id,
                price: {
                  type: "custom",
                },
              };
            case undefined:
              throw new Error("Rate type is undefined");
          }
        },
      );
    }
    return input;
  }, [rateCard, gigaRateCardEnabled]);

  return (
    <PageContainer
      title="Edit your rate card"
      disableContainerScroll
      action={
        <IconButton
          onClick={backToRateCardList}
          theme="secondary"
          icon="xClose"
        />
      }
    >
      {rateCardInput ? (
        <EditRateCardForm
          rateCard={rateCardInput}
          rateCardId={rateCardId}
          fiatCreditType={fiatCreditType}
          customCreditTypes={customCreditTypes}
          creditTypesLoading={loading}
          creditTypesError={error}
        />
      ) : null}
    </PageContainer>
  );
};
