import React from "react";
import { BaseIntegrationCard } from "./BaseIntegrationCard";
import { ReactComponent as AWSLogo } from "tenaissance/icons/aws.svg";

export const AwsIntegrationCard = () => {
  return (
    <BaseIntegrationCard
      title="AWS Marketplace"
      subheader="Marketplace billing provider"
      description="The AWS marketplace enables qualified partners to market and sell their software to AWS customers."
      buttonOptions={{ buttonOnClick: () => {}, isDisabled: true }}
      logo={<AWSLogo />}
      hasToolTip={true}
      toolTipContent="Contact your Metronome representative to set up this marketplace integration."
    />
  );
};
