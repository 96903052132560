import React, { useCallback } from "react";
import { Schema } from "../Schema";
import { CreditType } from "types/credit-types";
import { TextInput } from "tenaissance/components/Input";
import { displayCreditTypeName, USD_CREDIT_ID } from "lib/credits";
import { ConversionModal } from "./ConversionModal";
import { DropdownHR, DropdownItem } from "tenaissance/components/Dropdown";
import { IconName } from "tenaissance/components/Icon";

interface Props {
  disabled?: boolean;
  price: number;
  rateCreditType: CreditType;
  rateCardFiatCreditType: CreditType;
  customCreditTypes: CreditType[];
  creditTypeConversions: Schema.Types.CreditTypeConversion[];
  onPriceChange: (price: number) => void;
  onCreditTypeChange: (
    creditType: CreditType,
    conversion?: Schema.Types.CreditTypeConversion,
  ) => void;
}

export const CurrencyInput: React.FC<Props> = ({
  price,
  rateCreditType,
  rateCardFiatCreditType,
  customCreditTypes,
  creditTypeConversions,
  onPriceChange,
  onCreditTypeChange,
  disabled,
}) => {
  const [showConversionForCreditType, setShowConversionForCreditType] =
    React.useState<{ creditType?: CreditType } | null>(null);

  const onChange = useCallback(
    ({ value }: { value: string }) => {
      onPriceChange(Number(value));
    },
    [onPriceChange],
  );

  const leadingIcon: IconName | undefined =
    rateCreditType.id === USD_CREDIT_ID ? "currencyDollar" : undefined;
  return (
    <>
      {showConversionForCreditType && (
        <ConversionModal
          onClose={() => {
            setShowConversionForCreditType(null);
          }}
          onSave={(conversion) => {
            // TODO(ekaragiannis) - save the conversion
            onCreditTypeChange(conversion.customCreditType, {
              custom_credit_type_id: conversion.customCreditType.id,
              custom_credit_type_name: conversion.customCreditType.name,
              fiat_per_custom_credit: conversion.conversionRate,
            });

            setShowConversionForCreditType(null);
          }}
          fiatCreditType={rateCardFiatCreditType}
          conversionRate={
            creditTypeConversions.find(
              (c) =>
                c.custom_credit_type_id ===
                showConversionForCreditType.creditType?.id,
            )?.fiat_per_custom_credit
          }
          customCreditType={showConversionForCreditType.creditType}
        />
      )}
      <TextInput
        disabled={disabled}
        value={price.toString()}
        fullWidth
        disableClear
        variantOptions={{
          leadingIcon,
          variant: "dropdown",
          dropdownOptions: {
            label: displayCreditTypeName(rateCreditType),
            children: [
              <DropdownItem
                label={displayCreditTypeName(rateCardFiatCreditType)}
                value={rateCardFiatCreditType.id}
                onClick={() => {
                  if (rateCreditType === rateCardFiatCreditType) {
                    return;
                  }

                  onCreditTypeChange(rateCardFiatCreditType);
                }}
              />,
              <DropdownHR />,
              ...customCreditTypes.map((creditType) => {
                const conversion = creditTypeConversions.find(
                  (c) => c.custom_credit_type_id === creditType.id,
                );
                return (
                  <DropdownItem
                    label={displayCreditTypeName(creditType)}
                    value={creditType.id}
                    subtext={
                      conversion
                        ? `${conversion.fiat_per_custom_credit} ${displayCreditTypeName(
                            rateCardFiatCreditType,
                          )} per ${displayCreditTypeName(creditType)}`
                        : undefined
                    }
                    onClick={() => {
                      if (rateCreditType?.id === creditType.id) {
                        return;
                      }

                      const customCreditType = customCreditTypes.find(
                        (c) => c.id === creditType.id,
                      );

                      if (conversion && customCreditType) {
                        onCreditTypeChange(customCreditType);
                      } else {
                        // we need to show the config so they can set a credit type conversion
                        setShowConversionForCreditType({ creditType });
                      }
                    }}
                    secondaryAction={
                      conversion
                        ? {
                            icon: "settings01",
                            theme: "tertiary",
                            size: "sm",
                            onClick: (e) => {
                              e.stopPropagation();
                              setShowConversionForCreditType({ creditType });
                            },
                          }
                        : undefined
                    }
                  />
                );
              }),
            ],
          },
        }}
        onChange={onChange}
      />
    </>
  );
};
