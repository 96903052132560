import React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "../IconButton";
import { Icon, IconName } from "../Icon";
import { twMerge } from "../../twMerge";
import { EnvironmentSwitcher } from "../EnvironmentSwitcher";
import { MetronomeLogo } from "../MetronomeLogo";
import { Dropdown, DropdownHR, DropdownItem } from "../Dropdown";
import { useRoutePathMatches } from "lib/routes/useRoutePathMatches";
import { useEnvironment } from "lib/environmentSwitcher/context";

const SidebarLink: React.FC<{
  /** The route to navigate to */
  to: string;
  /** The text to display in the Sidebar */
  name: string;
  /** The icon to display in the Sidebar */
  icon: IconName;
}> = ({ to, name, icon }) => {
  const match = useRoutePathMatches([to], false);
  const { prefixUrl } = useEnvironment();
  return (
    <Link
      to={prefixUrl(to)}
      className={twMerge(
        "rounded-sm text-md p-md flex font-semibold text-grey-600",
        match
          ? "bg-core-slate text-white"
          : "hover:bg-gray-50 hover:text-gray-800",
      )}
    >
      <>
        <Icon icon={icon} className={match ? "text-white" : "text-black"} />
        <div className="ml-lg">{name}</div>
      </>
    </Link>
  );
};

/**
 * The Sidebar is the primary left navigation of the website.
 * It contains links to the top-level pages of the application, the environment selector, and the user's account page
 */
export const Sidebar: React.FC = () => {
  return (
    <div className="p-xl flex h-full w-[240px] flex-col border-r border-r-gray-200">
      <Link to="/" className="h-[48px] border-b border-b-gray-200">
        <MetronomeLogo />
      </Link>
      <div className="my-lg gap-y-sm flex grow flex-col">
        <SidebarLink to="/overview" name="Overview" icon="grid01" />
        <SidebarLink to="/customers" name="Customers" icon="users01" />
        <SidebarLink to="/offering" name="Offering" icon="package" />
        <SidebarLink to="/reporting" name="Reporting" icon="barChartSquare03" />
        <SidebarLink to="/connections" name="Connections" icon="dataflow03" />
      </div>
      <SidebarLink to="/settings" name="Settings" icon="settings01" />
      <div className="pt-xl mt-lg flex gap-4 border-t border-t-gray-200">
        <EnvironmentSwitcher />
        <Dropdown icon="userCircle" buttonTheme="secondary">
          <DropdownItem
            icon="user01"
            label="Account Settings"
            linkTo="/account"
            value="account"
          />
          <DropdownHR />
          <DropdownItem
            icon="logOut01"
            label="Logout"
            linkTo="/logout"
            value="logout"
          />
        </Dropdown>
        <IconButton
          linkTo="https://docs.metronome.com"
          isExternalLink
          icon="bookOpen01"
          theme="secondary"
        />
      </div>
    </div>
  );
};
