import React, { useState } from "react";
import {
  useGetClientConfigQuery,
  useSaveClientConfigMutation,
  GetClientConfigDocument,
  GetClientConfigQuery,
  GetClientConfigQueryVariables,
  SaveClientConfigDocument,
} from "../../queries.graphql";
import { ClientConfigKeyEnum_Enum } from "types/generated-graphql/__types__";
import { Checkbox } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Body } from "design-system";
import { Popup } from "components/Popup";
import { useSnackbar } from "components/Snackbar";
import { StripeSetting } from "../StripeSetting";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useAuthCheck } from "lib/useAuthCheck";

interface LeaveInvoicesInDraftModalProps {
  checked: boolean;
  onClose: () => void;
}

const LeaveInvoicesInDraftModal: React.FC<LeaveInvoicesInDraftModalProps> = ({
  checked,
  onClose,
}) => {
  const { environmentType } = useEnvironment();
  const pushMessage = useSnackbar();

  const [saveClientConfig, saveConfigResults] = useSaveClientConfigMutation();

  const onSave = async () => {
    try {
      await saveClientConfig({
        variables: {
          environment_type: environmentType,
          client_config: ClientConfigKeyEnum_Enum.LeaveInvoicesInDraft,
          value: checked.toString(),
        },
        update: (store, { data }) => {
          if (!data?.insert_ClientConfig_one) {
            return;
          }
          store.writeQuery<GetClientConfigQuery, GetClientConfigQueryVariables>(
            {
              data: {
                ClientConfig: [data?.insert_ClientConfig_one],
              },
              query: GetClientConfigDocument,
              variables: {
                environment_type: environmentType,
                client_config: ClientConfigKeyEnum_Enum.LeaveInvoicesInDraft,
              },
            },
          );
        },
      });

      pushMessage({
        content: checked
          ? "Saved. Invoices will be left as drafts."
          : "Saved. Invoices will not be left as drafts.",
        type: "success",
      });
    } catch (error) {
      pushMessage({
        content: "Failed to save change to leaving invoices as drafts",
        type: "error",
      });
    } finally {
      onClose();
    }
  };

  return (
    <Popup
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            onClick={onSave}
            disabled={saveConfigResults.loading}
            loading={saveConfigResults.loading}
            text="Continue"
            theme="primary"
          />
        </>
      }
      isOpen={true}
      onRequestClose={onClose}
      title={
        checked
          ? `Are you sure you want to leave Stripe invoices as drafts?`
          : `Are you sure you want to auto-advance Stripe invoices?`
      }
    >
      <Body level={2}>
        {checked
          ? `Leaving Stripe invoices in draft will require you to manually finalize invoices for collection. This change will take effect immediately.`
          : `Enabling auto-advance means that Stripe invoices will automatically finalize. This change will take effect immediately.`}
      </Body>
    </Popup>
  );
};

export const LeaveInvoicesInDraftCheckbox: React.FC<{
  onErrorLoadingData: (error: boolean) => void;
}> = ({ onErrorLoadingData }) => {
  const { environmentType } = useEnvironment();
  const [leaveInvoicesInDraftModalState, setLeaveInvoicesInDraftModalState] =
    useState<boolean | null>(null);

  const canSaveClientConfig = !!useAuthCheck(SaveClientConfigDocument, true)
    .allowed;

  const { data, loading, error } = useGetClientConfigQuery({
    variables: {
      environment_type: environmentType,
      client_config: ClientConfigKeyEnum_Enum.LeaveInvoicesInDraft,
    },
  });

  if (error) {
    onErrorLoadingData(true);
  }

  const value = (data?.ClientConfig?.[0]?.value ?? "true") === "true";
  const checkbox = (
    <Checkbox
      disabled={loading || !canSaveClientConfig}
      label="Leave invoices as drafts"
      checked={value}
      onChange={(value) => setLeaveInvoicesInDraftModalState(value)}
    />
  );
  return (
    <>
      {leaveInvoicesInDraftModalState !== null && (
        <LeaveInvoicesInDraftModal
          checked={leaveInvoicesInDraftModalState}
          onClose={() => setLeaveInvoicesInDraftModalState(null)}
        />
      )}
      <StripeSetting
        setting={checkbox}
        helpText="If you leave Stripe invoices as drafts, you will need to manually advance them before they can be collected. Otherwise, Stripe invoices auto-advance, which means they're immediately sent to customers."
      />
    </>
  );
};
