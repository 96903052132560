import React from "react";
import classNames from "classnames";
import pluralize from "pluralize";

import { EmptyState } from "components/EmptyState";
import { TablePanel } from "components/TablePanel";
import { printDate, Dayjs } from "lib/date";

import { TermRate, TermRateProps } from "./TermRate";
import { ColWidths } from "../lib/ColWidths";
import { CellWithSubtitle } from "./CellWithSubtitle";

export type AdditionalTermRow = {
  name: string;
  type: string;
  onClick?: () => void;
  invoiceCount: number | null;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  rate: TermRateProps;
};

interface Props {
  className?: string;
  rows: AdditionalTermRow[];
}

export function AdditionalTermsTable(props: Props) {
  if (!props.rows.length) {
    return null;
  }

  return (
    <TablePanel
      title="Additional terms"
      data={props.rows}
      className={props.className}
      onRowClick={
        props.rows.some((r) => !!r.onClick)
          ? (row) => row.onClick?.()
          : undefined
      }
      emptyState={
        <EmptyState title="Contract has no additional terms" subtitle="" />
      }
      columns={[
        {
          id: "name",
          header: "Term",
          cellClassName: "!py-[8px]",
          render: (row) => (
            <CellWithSubtitle title={row.name} subtitle={row.type} />
          ),
        },
        {
          id: "invoiceSchedule",
          header: "Invoice schedule",
          align: "right",
          cellClassName: classNames(ColWidths.BILL_SCHEDULE, "align-top"),
          render: (row) => {
            return row.invoiceCount == null
              ? "--"
              : pluralize("invoice", row.invoiceCount, true);
          },
        },
        {
          id: "start",
          header: "Effective start",
          align: "right",
          cellClassName: classNames(ColWidths.DATE, "align-top"),
          render: (row) => {
            return row.startDate ? printDate(row.startDate) : "--";
          },
        },
        {
          id: "end",
          header: "Effective end",
          align: "right",
          cellClassName: classNames(ColWidths.DATE, "align-top"),
          render: (row) => {
            return row.endDate ? printDate(row.endDate) : "--";
          },
        },
        {
          id: "rate",
          header: "Rate",
          align: "right",
          cellClassName: classNames(ColWidths.RATE, "align-top"),
          render: (row) => <TermRate {...row.rate} />,
        },
      ]}
    />
  );
}
