import React from "react";
import { AppShell } from "components/PageContainer";
import { RouteObject } from "react-router";
import { Navigate } from "react-router-dom";
import Events from "pages/Events";
import { Alerts } from "./tabs/Alerts";
import Alert from "pages/Alert";
import NewAlert from "pages/NewAlert";
import { NewUIRouteSwitch } from "tenaissance/lib/routeSwitch";
import DataExportTab from "pages/GeneralSettings/components/DataExportTab";
import UpdateDataExportDestination from "pages/UpdateDataExportDestination";
import NewDataExportDestination from "pages/NewDataExportDestination";
import { RedesignedIntegrationsTab } from "pages/GeneralSettings/components/IntegrationsTab";
import { CensusDashboard } from "pages/GeneralSettings/CensusDashboard";
import { CensusDestinationTypeEnum } from "types/generated-graphql/__types__";
import { useFeatureFlag } from "lib/launchdarkly";
import { INVOICING_TAB_FEATURE_FLAG } from "pages/GeneralSettings";
import InvoicingTab from "pages/GeneralSettings/components/InvoicingTab";
import { APIKeysAndWebhooks } from "./tabs/APIKeysAndWebhooks";
import CustomFieldsTab from "pages/GeneralSettings/components/CustomFieldsTab";
import CustomFields from "../../../pages/CustomFields";

export const Connections: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const invoicingEnabled = useFeatureFlag(INVOICING_TAB_FEATURE_FLAG, false);
  return (
    <AppShell
      title="Connections"
      headerProps={{
        tabs: tabs.filter((t) => invoicingEnabled || t.name !== "Invoices"),
        basePath: "connections",
      }}
    >
      {children}
    </AppShell>
  );
};

const tabs = [
  {
    name: "Events",
    path: "events",
    element: (
      <NewUIRouteSwitch
        enabled={
          <Connections>
            <Events />
          </Connections>
        }
      />
    ),
  },
  {
    name: "Alerts",
    path: "alerts",
    children: [
      {
        index: true,
        element: (
          <NewUIRouteSwitch
            enabled={
              <Connections>
                <Alerts />
              </Connections>
            }
          />
        ),
      },
      { path: "new", element: <NewUIRouteSwitch enabled={<NewAlert />} /> },
      { path: ":id", element: <NewUIRouteSwitch enabled={<Alert />} /> },
    ],
  },
  {
    name: "Integrations",
    path: "integrations",
    children: [
      {
        index: true,
        element: (
          <NewUIRouteSwitch
            enabled={
              <Connections>
                <RedesignedIntegrationsTab tabs={[]} />
              </Connections>
            }
          />
        ),
      },
      {
        path: "salesforce",
        children: [
          {
            index: true,
            element: (
              <NewUIRouteSwitch
                enabled={
                  <Connections>
                    <CensusDashboard
                      destinationType={CensusDestinationTypeEnum.Salesforce}
                    />
                  </Connections>
                }
              />
            ),
          },
          {
            path: "incomplete",
            element: (
              <NewUIRouteSwitch
                enabled={
                  <Connections>
                    <CensusDashboard
                      destinationType={CensusDestinationTypeEnum.Salesforce}
                      showIncompleteSyncs={true}
                    />
                  </Connections>
                }
              />
            ),
          },
        ],
      },
    ],
  },
  {
    name: "Data Export",
    path: "data-export",
    children: [
      {
        index: true,
        element: (
          <NewUIRouteSwitch
            enabled={
              <Connections>
                <DataExportTab tabs={[]} />
              </Connections>
            }
          />
        ),
      },
      {
        path: "new",
        element: <NewUIRouteSwitch enabled={<NewDataExportDestination />} />,
      },
      {
        path: ":destinationId",
        element: <NewUIRouteSwitch enabled={<UpdateDataExportDestination />} />,
      },
    ],
  },
  {
    name: "Invoices",
    path: "invoices",
    element: (
      <NewUIRouteSwitch
        enabled={
          <Connections>
            <InvoicingTab tabs={[]} />
          </Connections>
        }
      />
    ),
  },
  {
    name: "Custom fields",
    path: "custom-fields",
    children: [
      {
        index: true,
        element: (
          <NewUIRouteSwitch
            enabled={
              <Connections>
                <CustomFieldsTab tabs={[]} />
              </Connections>
            }
          />
        ),
      },
      {
        path: ":entity/:id",
        element: <NewUIRouteSwitch enabled={<CustomFields />} />,
      },
    ],
  },
  {
    name: "API Keys & Webhooks",
    path: "api-keys-webhooks",
    element: (
      <NewUIRouteSwitch
        enabled={
          <Connections>
            <APIKeysAndWebhooks />
          </Connections>
        }
      />
    ),
  },
];

export const ConnectionsRoutes: RouteObject[] = [
  {
    path: "connections",
    children: [
      { index: true, element: <Navigate to="events" replace /> },
      ...tabs,
    ],
  },
];
