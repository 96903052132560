import {
  LDProvider as Provider,
  useLDClient,
} from "launchdarkly-react-client-sdk";

import { LDContext } from "launchdarkly-js-sdk-common";

import { useCurrentUser } from "lib/auth";
import React from "react";

const LDReadyGate: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [ready, setReady] = React.useState(false);
  const client = useLDClient();

  React.useEffect(() => {
    if (!client) {
      setReady(false);
      return;
    }

    const ctl = new AbortController();
    client.waitUntilReady().then(
      () => {
        if (ctl.signal.aborted) return;
        setReady(true);
      },
      (error) => {
        if (ctl.signal.aborted) return;
        console.error("Failed to initialize LaunchDarkly client", error);
        window.location.reload();
      },
    );
    return () => ctl.abort();
  }, [client]);

  return ready ? <>{children}</> : null;
};

export const LDProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const clientKey = process.env.LD_CLIENT_KEY;
  if (!clientKey) {
    throw new Error("Missing LD key");
  }
  const { user, clientID, isMetronomeAdmin } = useCurrentUser();

  let ldContext: LDContext | undefined = undefined;
  if (user && clientID) {
    ldContext = {
      key: user.id,
      email: user.email,
      name: user.name,
      custom: {
        client_id: clientID,
        isMetronomeAdmin,
      },
    };
  }

  return (
    <Provider
      clientSideID={clientKey}
      context={ldContext}
      deferInitialization={true}
    >
      <LDReadyGate>{children}</LDReadyGate>
    </Provider>
  );
};

export function useFeatureFlag<T>(key: string, defaultValue: T): T | undefined {
  const client = useLDClient();
  return client?.variation(key, defaultValue);
}
