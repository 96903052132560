import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useEnvironment } from "lib/environmentSwitcher/context";
import {
  GetClientConfigDocument,
  SaveClientConfigAndDeleteInvoiceDaysUntilDueMutation,
  SaveClientConfigAndDeleteInvoiceDaysUntilDueMutationVariables,
  SaveClientConfigWithInvoiceDaysUntilDueMutation,
  SaveClientConfigWithInvoiceDaysUntilDueMutationVariables,
  useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation,
  useGetClientConfigQuery,
  useGetCustomersWithStripeEnabledCountQuery,
  useSaveClientConfigAndDeleteInvoiceDaysUntilDueMutation,
  useSaveClientConfigWithInvoiceDaysUntilDueMutation,
} from "./queries.graphql";
import { Button } from "tenaissance/components/Button";
import { SideSheet, SideSheetProps } from "tenaissance/components/SideSheet";
import { useSnackbar } from "components/Snackbar";
import { FirstDeleteStep } from "./FirstDeleteStep";
import { renderDate } from "lib/time";
import { StripeInvoiceSettings } from "./StripeSettings";
import { useContractsEnabled } from "lib/contracts/useContractsEnabled";

interface StripeSettingsSideSheetProps {
  setStripeSettingsSlideSheetIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  connectedOn: Date;
  tokenID: string;
}

export interface StripeSettingsDataType {
  leave_invoices_in_draft?: boolean;
  skip_zero_dollar_invoices?: boolean;
  export_invoice_sub_line_items?: boolean;
  include_zero_quantity_sub_line_items?: boolean;
  stripe_invoice_quantity_always_string?: boolean;
  invoice_days_until_due?: string;
}

export const StripeSettingsSideSheet: React.FC<
  StripeSettingsSideSheetProps
> = ({ setStripeSettingsSlideSheetIsOpen, isOpen, connectedOn, tokenID }) => {
  const pushMessage = useSnackbar();
  const [disableStripeClicked, setDisableStripeClicked] = useState(false);
  const [hasPassedFirstDeleteStep, setHasPassedFirstDeleteStep] =
    useState(false);
  const [disableStripeButtonIsDisabled, setDisableStripeButtonIsDisabled] =
    useState(false);
  const [shouldSaveStripeChanges, setShouldSaveStripeChanges] = useState(false);
  const [stripeSettingsData, setStripeSettingsData] =
    useState<StripeSettingsDataType>({});

  const { environmentType } = useEnvironment();

  const { data: clientConfigData, error: clientConfigError } =
    useGetClientConfigQuery({
      variables: { environment_type: environmentType },
    });

  const setInitalConfigData = () => {
    if (clientConfigData) {
      const settingsData = clientConfigData.ClientConfig;
      const updatedClientConfigDataMap: StripeSettingsDataType = {};

      settingsData?.forEach((data) => {
        const key = data?.key;
        switch (key) {
          case "leave_invoices_in_draft":
            updatedClientConfigDataMap[key] =
              (data?.value ?? "true") === "true";
            break;
          case "skip_zero_dollar_invoices":
            updatedClientConfigDataMap[key] =
              (data?.value ?? "false") === "true";
            break;
          case "export_invoice_sub_line_items":
            updatedClientConfigDataMap[key] = data?.value === "true";
            break;
          case "include_zero_quantity_sub_line_items":
            // If the include_zero_quantity_sub_line_items is not set, we default to true
            // Must stay in sync with billing-provider-invoicer/src/lib/integrationOptions.ts
            updatedClientConfigDataMap[key] =
              (data?.value ?? "true") === "true";
            break;
          case "stripe_invoice_quantity_always_string":
            updatedClientConfigDataMap[key] = data?.value === "true";
            break;
          case "invoice_days_until_due":
            updatedClientConfigDataMap[key] = data?.value ?? "";
            break;
        }
      });
      setStripeSettingsData(updatedClientConfigDataMap);
    }
  };

  const { data: customerCount } = useGetCustomersWithStripeEnabledCountQuery({
    variables: {
      environment_type: environmentType,
    },
  });

  const [
    deleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation,
  ] = useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation(
    {
      update(cache) {
        cache.evict({
          fieldName: "BillingProviderToken",
        });
        cache.evict({
          fieldName: "BillingProviderCustomer",
        });
      },
    },
  );

  const numCustomers = Number(
    customerCount?.Customer_aggregate.aggregate?.count || "0",
  );

  const deleteBillingProviderTokenAndAllStripeBillingProviderCustomersAction =
    async () => {
      try {
        await deleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation();
        pushMessage({
          content: "Stripe has been disabled",
          type: "success",
        });
      } catch (e) {
        pushMessage({
          content: "Failed to disable Stripe",
          type: "error",
        });
        throw e;
      }
    };

  const [
    saveClientConfig,
    { loading: saveClientConfigLoading, error: saveClientConfigError },
  ] = useSaveClientConfigWithInvoiceDaysUntilDueMutation();

  const [
    saveClientConfigAndDeleteInvoiceUntilDueConfig,
    {
      loading: saveAndDeleteClientConfigLoading,
      error: saveAndDeleteClientConfigError,
    },
  ] = useSaveClientConfigAndDeleteInvoiceDaysUntilDueMutation();

  useEffect(() => {
    setInitalConfigData();
  }, [clientConfigData]);

  const handleCancelClick = () => {
    setHasPassedFirstDeleteStep(false);
    setDisableStripeClicked(false);
  };

  const handleDeleteStripe = async () => {
    await deleteBillingProviderTokenAndAllStripeBillingProviderCustomersAction();
    setStripeSettingsSlideSheetIsOpen(false);
  };

  const renderDeleteStep = () => {
    if (!hasPassedFirstDeleteStep) {
      return (
        <FirstDeleteStep
          numCustomers={numCustomers}
          setHasPassedFirstDeleteStep={setHasPassedFirstDeleteStep}
          setDisableStripeButtonIsDisabled={setDisableStripeButtonIsDisabled}
          disableStripeButtonIsDisabled={disableStripeButtonIsDisabled}
        />
      );
    } else {
      return <>Are you sure you want to disable Stripe?</>;
    }
  };

  const hasContractsEnabled = useContractsEnabled();
  const failedSnackbarMessage = "Failed to save changes. Please try again.";

  const isMutationReturnDataValid = (
    data:
      | SaveClientConfigWithInvoiceDaysUntilDueMutation
      | SaveClientConfigAndDeleteInvoiceDaysUntilDueMutation
      | null
      | undefined,
  ) => {
    if (!data) return false;
    let daysUntilDueHandledProperly: boolean;
    if ("deleted_invoices_days_until_due" in data) {
      daysUntilDueHandledProperly = !!data.deleted_invoices_days_until_due;
    } else if ("invoice_days_until_due" in data) {
      daysUntilDueHandledProperly = !!data.invoice_days_until_due;
    } else {
      daysUntilDueHandledProperly = false;
    }
    return (
      !!data.leave_invoices_in_draft &&
      !!data.skip_zero_dollar_invoices &&
      !!data.export_invoice_sub_line_items &&
      !!data.include_zero_quantity_sub_line_items &&
      !!data.stripe_invoice_quantity_always_string &&
      daysUntilDueHandledProperly
    );
  };
  const saveClientConfigWithInvoiceDaysUntilDue = async (
    variables: SaveClientConfigWithInvoiceDaysUntilDueMutationVariables,
  ) => {
    const { data } = await saveClientConfig({
      variables,
      refetchQueries: [
        {
          query: GetClientConfigDocument,
          variables: { environment_type: environmentType },
        },
      ],
    });
    if (saveClientConfigError) {
      pushMessage({
        content: failedSnackbarMessage,
        type: "error",
      });
    } else {
      if (!isMutationReturnDataValid(data)) {
        pushMessage({
          content: failedSnackbarMessage,
          type: "error",
        });
      } else {
        setStripeSettingsSlideSheetIsOpen(false);
        pushMessage({
          content: "Changes saved successfully",
          type: "success",
        });
      }
    }
  };

  const saveClientConfigAndDeleteInvoiceUntilDue = async (
    variables: SaveClientConfigAndDeleteInvoiceDaysUntilDueMutationVariables,
  ) => {
    const { data } = await saveClientConfigAndDeleteInvoiceUntilDueConfig({
      variables,
      refetchQueries: [
        {
          query: GetClientConfigDocument,
          variables: { environment_type: environmentType },
        },
      ],
    });
    if (saveAndDeleteClientConfigError) {
      pushMessage({
        content: failedSnackbarMessage,
        type: "error",
      });
    } else {
      if (!isMutationReturnDataValid(data)) {
        pushMessage({
          content: failedSnackbarMessage,
          type: "error",
        });
      } else {
        setStripeSettingsSlideSheetIsOpen(false);
        pushMessage({
          content: "Changes saved successfully",
          type: "success",
        });
      }
    }
  };

  const saveStripeSettings = async () => {
    if (!shouldSaveStripeChanges) return;

    if (
      stripeSettingsData.leave_invoices_in_draft === undefined ||
      stripeSettingsData.skip_zero_dollar_invoices === undefined ||
      stripeSettingsData.export_invoice_sub_line_items === undefined ||
      stripeSettingsData.include_zero_quantity_sub_line_items === undefined ||
      stripeSettingsData.stripe_invoice_quantity_always_string === undefined ||
      typeof stripeSettingsData.invoice_days_until_due !== "string"
    ) {
      pushMessage({
        content: failedSnackbarMessage,
        type: "error",
      });
      return;
    }

    const variables = {
      leave_invoices_in_draft_value:
        stripeSettingsData.leave_invoices_in_draft.toString(),
      skip_zero_dollar_invoices_value:
        stripeSettingsData.skip_zero_dollar_invoices.toString(),
      export_invoice_sub_line_items_value:
        stripeSettingsData.export_invoice_sub_line_items.toString(),
      include_zero_quantity_sub_line_items_value:
        stripeSettingsData.include_zero_quantity_sub_line_items.toString(),
      stripe_invoice_quantity_always_string_value:
        stripeSettingsData.stripe_invoice_quantity_always_string.toString(),
      invoice_days_until_due_value:
        stripeSettingsData.invoice_days_until_due || "",
    };

    /* 
      If export_invoice_sub_line_items is false and contracts are not enabled,
      we should not allow include_zero_quantity_sub_line_items
      and stripe_invoice_quantity_always_string to be true 
    */
    if (
      variables.export_invoice_sub_line_items_value === "false" &&
      !hasContractsEnabled
    ) {
      variables.include_zero_quantity_sub_line_items_value = "false";
      variables.stripe_invoice_quantity_always_string_value = "false";
    }
    // If invoice_days_until_due is not an empty string, we save the value
    if (!!variables.invoice_days_until_due_value) {
      await saveClientConfigWithInvoiceDaysUntilDue(variables);
    }
    // If invoice_days_until_due is an empty string, we delete the value
    if (!variables.invoice_days_until_due_value) {
      await saveClientConfigAndDeleteInvoiceUntilDue(variables);
    }
  };

  let props: Pick<
    SideSheetProps,
    "title" | "leadingAction" | "trailingActions"
  >;
  let children: React.ReactNode;

  if (disableStripeClicked) {
    props = {
      title: "Disable Stripe",
      trailingActions: [
        <Button
          className={
            !hasPassedFirstDeleteStep && disableStripeButtonIsDisabled
              ? "text-error-200"
              : "text-error-600"
          }
          text="Disable Stripe"
          theme="tertiary"
          leadingIcon="xSquare"
          disabled={disableStripeButtonIsDisabled}
          onClick={async () =>
            !hasPassedFirstDeleteStep
              ? setHasPassedFirstDeleteStep(true)
              : await handleDeleteStripe()
          }
        />,
        <Button
          text="Cancel"
          onClick={() => handleCancelClick()}
          theme="tertiary"
        />,
      ],
    };
    children = renderDeleteStep();
  } else {
    props = {
      title: "Manage Stripe",
      leadingAction: (
        <Button
          text="Disable"
          leadingIcon="xSquare"
          theme="tertiary"
          className="text-error-600"
          onClick={() => setDisableStripeClicked(true)}
        />
      ),
      trailingActions: [
        <Button
          text="Save Changes"
          onClick={async () => await saveStripeSettings()}
          disabled={
            !shouldSaveStripeChanges ||
            saveClientConfigLoading ||
            saveAndDeleteClientConfigLoading
          }
          loading={saveClientConfigLoading || saveAndDeleteClientConfigLoading}
        />,
        <Button
          text="Cancel"
          theme="secondary"
          onClick={() => setStripeSettingsSlideSheetIsOpen(false)}
        />,
      ],
    };
    children = (
      <StripeInvoiceSettings
        tokenID={tokenID}
        setShouldSaveStripeChanges={setShouldSaveStripeChanges}
        stripeSettingsData={stripeSettingsData}
        setStripeSettingsData={setStripeSettingsData}
        errorLoadingData={clientConfigError}
      />
    );
  }

  return (
    <SideSheet
      supportingText={`Connected on ${renderDate(connectedOn, { isUtc: true, excludeUtcLabel: true })}`}
      isOpen={isOpen}
      onClose={() => setStripeSettingsSlideSheetIsOpen(false)}
      {...props}
    >
      {children}
    </SideSheet>
  );
};
