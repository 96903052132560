import React, { useCallback, useEffect, useState } from "react";

import { Popup } from "components/Popup";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { Body } from "design-system";
import { Input } from "design-system";

interface EditNameButtonProps {
  title: string;
  body: string;
  label: string;
  currentName: string;
  updateName: (name: string) => Promise<void>;
  loading: boolean;
}

export const EditNameButton: React.FC<EditNameButtonProps> = ({
  title,
  body,
  label,
  currentName,
  updateName,
  loading,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState(currentName);

  const save = async () => {
    if (!name) {
      return;
    }
    await updateName(name);
    setModalOpen(false);
  };
  const close = async () => {
    // reset for next time the modal is opened
    setName(currentName);
    setModalOpen(false);
  };

  const ref = useCallback((node: HTMLInputElement) => {
    if (node) {
      node.select();
    }
  }, []);

  // This is mostly so when there are two edit buttons on the same page (e.g.
  // on the customer settings page), edits from one show up in the other.
  useEffect(() => {
    setName(currentName);
  }, [currentName]);

  const popup = (
    <Popup
      actions={
        <>
          <Button
            onClick={close}
            disabled={loading}
            text="Cancel"
            theme="linkGray"
          />
          <Button
            disabled={!name?.length || loading}
            loading={loading}
            onClick={save}
            text="Save"
            theme="primary"
          />
        </>
      }
      isOpen
      onRequestClose={close}
      title={title}
    >
      <Body level={2}>{body}</Body>
      <Input
        value={name || ""}
        onChange={setName}
        name={label}
        placeholder={currentName ?? label}
        autoFocus
        onKeyDown={async (e) => {
          if (e.key === "Enter") {
            await save();
          }
        }}
        ref={ref}
      />
    </Popup>
  );

  return (
    <>
      {modalOpen && popup}
      <IconButton
        onClick={() => setModalOpen(true)}
        theme="tertiary"
        icon="edit05"
        size="sm"
      />
    </>
  );
};
