import { SOXReport } from "../reportConfig";
import { CustomReportType } from "types/generated-graphql/__types__";

const soxComplianceInvoiceReport: SOXReport = {
  name: "SOX Compliance Invoice Report",
  needsDates: true,
  needsEnvironment: false,
  type: "email",
  isAllowedForUser: (ldClient) =>
    ldClient
      ?.variation("available-report-types", [])
      .includes("SOX_COMPLIANCE_INVOICE"),
  customReportType: CustomReportType.Sox,
};

const soxAuditLogReport: SOXReport = {
  name: "SOX Audit Log Report",
  needsDates: true,
  needsEnvironment: false,
  type: "email",
  isAllowedForUser: (ldClient) =>
    ldClient?.variation("available-report-types", []).includes("SOX_AUDIT_LOG"),
  customReportType: CustomReportType.SoxAuditReport,
};

const usageBasedBillingFinalizationReport: SOXReport = {
  name: "Usage Based Billing Invoice Finalization Report",
  needsDates: true,
  needsEnvironment: false,
  type: "email",
  isAllowedForUser: (ldClient) =>
    ldClient
      ?.variation("available-report-types", [])
      .includes("UBB_INVOICE_FINALIZATION"),
  customReportType: CustomReportType.UbbInvoiceFinalization,
};

export {
  soxComplianceInvoiceReport,
  soxAuditLogReport,
  usageBasedBillingFinalizationReport,
};
