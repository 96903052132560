import { useSnackbar } from "components/Snackbar";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { FormController } from "lib/FormController";
import React, { useEffect, useMemo, useState } from "react";

import { AvatarWithName, Body } from "design-system";
import { Toggle } from "tenaissance/components/Toggle";

import { Button } from "tenaissance/components/Button";
import { Icon } from "tenaissance/components/Icon";

import { useNow } from "lib/date";
import { ProductListItem } from "../../lib/ProductListItem";
import {
  useCreateCompositeProductMutation,
  useCreateFixedProductMutation,
  useCreateUsageProductMutation,
  useCreateSubscriptionProductMutation,
  useListBillableMetricsQuery,
  useCreateProServiceProductMutation,
} from "../CreateAndEditProductModal/data.graphql";
import { Schema } from "../Schema";
import { useFeatureFlag } from "lib/launchdarkly";
import {
  BillingMetricAggregateEnum_Enum,
  ConversionOperation,
  RoundingMethod,
} from "types/generated-graphql/__types__";
import { Link } from "react-router-dom";
import { useNavigate } from "lib/useNavigate";
import { components, type MenuProps } from "react-select";
import { type Option } from "design-system";
import { Takeover } from "tenaissance/components/Takeover";
import { TextInput } from "tenaissance/components/Input";
import { SectionHeader } from "tenaissance/components/SectionHeader";
import { OptionGroup } from "tenaissance/components/OptionGroup";
import { Checkbox } from "tenaissance/components/Checkbox";
import { Column, Table } from "tenaissance/components/Table";
import { ButtonGroup } from "tenaissance/components/ButtonGroup";
import { Timestamp } from "tenaissance/components/Timestamp";
import { Badge } from "tenaissance/components/Badge";
import { ProductsQuery, useProductsQuery } from "./data.graphql";
import { InputDropdown } from "tenaissance/components/InputDropdown";
import { DropdownItem } from "tenaissance/components/Dropdown";
import {
  ProductCreatedModal,
  shouldShowProductCreatedModal,
} from "./ProductCreatedModal";
import { ProductCreateLandingPage, shouldShowPreviewPage } from "./LandingPage";
import { useUIMode } from "../../../../lib/useUIMode";
import { getGroupKeys } from "lib/billableMetrics";
import { RadioButton } from "tenaissance/components/RadioButton";

type LinkOptions = {
  url: string;
  label: string | JSX.Element;
};

type Products = ProductsQuery["contract_pricing"]["products"];
type ProductListItem = Products[0];

/** CustomMenu will allow devs to prepend a clickable link to the top of a Select, ie BillableMetrics dropdown */
export const CustomMenu = ({
  menuProps,
  linkOptions,
}: {
  menuProps: MenuProps<Option>;
  linkOptions?: LinkOptions;
}) => {
  return (
    <components.Menu {...menuProps}>
      {!!linkOptions && (
        <Link
          className="flex items-center justify-between border-b border-grey-50 px-12 py-8 text-success-700 hover:bg-grey-50"
          to={linkOptions.url}
          relative="path"
          target="_blank"
        >
          {linkOptions.label}
          <Icon icon="share03" size={12} />
        </Link>
      )}
      {menuProps.children}
    </components.Menu>
  );
};

type CreateProductController = ReturnType<typeof useCreateProductController>;

interface ProductModalProps {
  onClose?: (newProductId?: string) => void;
  enforceType?: "usage" | "fixed" | "composite" | "subscription" | "proService";
}

export const fuseParams = {
  ignoreLocation: true,
  includeScore: true,
  threshold: 0.3,
};

const useCreateProductController = FormController.createHook(
  Schema.CreateProductInput,
  {
    init(
      snapshotKey: string,
      enforceType?:
        | "usage"
        | "fixed"
        | "composite"
        | "subscription"
        | "proService",
    ) {
      const snapshot = FormController.parseJsonSnapshot(
        Schema.CreateProductInput,
        sessionStorage.getItem(snapshotKey),
      );
      return { ...snapshot, type: enforceType || "usage" };
    },
  },
);

export const CreateProduct: React.FC<ProductModalProps> = (props) => {
  const [showProductCreatedModal, setShowProductCreatedModal] =
    React.useState(false);
  const [createdProductId, setCreatedProductId] = React.useState<string | null>(
    null,
  );

  const navigate = useNavigate();

  const { onClose, enforceType } = props;
  const { environmentType } = useEnvironment();
  const now = useNow();
  const nonGAContractFeaturesEnabled = useFeatureFlag<string[]>(
    "non-ga-contract-features",
    [],
  );
  const allowProfessionalServices = nonGAContractFeaturesEnabled?.includes(
    "PROFESSIONAL_SERVICES",
  );
  const netsuiteEnabled = nonGAContractFeaturesEnabled?.includes("NETSUITE");
  const refundableProductsEnabled = nonGAContractFeaturesEnabled?.includes(
    "REFUNDABLE_PRODUCTS",
  );
  const excludeFreeUsageEnabled = nonGAContractFeaturesEnabled?.includes(
    "COMPOSITE_EXCLUDE_FREE_USAGE",
  );

  const pushMessage = useSnackbar();
  const pushSuccessMessage = (type: string, productName: string) => {
    pushMessage({
      content: `Successfully created new ${type === "proService" ? "professional service" : type} product: ${productName}`,
      type: "success",
    });
  };
  const pushErrorMessage = (type: string, e: unknown) =>
    pushMessage({
      content: `Failed to create new ${type} product: ${e}`,
      type: "error",
    });

  const [createCompositeProductMutation, createCompositeProductResult] =
    useCreateCompositeProductMutation();
  const [createFixedProductMutation, createFixedProductResult] =
    useCreateFixedProductMutation();
  const [createUsageProductMutation, createUsageProductResult] =
    useCreateUsageProductMutation();
  const [createSubscriptionProductMutation, createSubscriptionProductResult] =
    useCreateSubscriptionProductMutation();
  const [createProServiceProductMutation, createProServiceProductResult] =
    useCreateProServiceProductMutation();

  const { newUIEnabled } = useUIMode();
  const getProductLastEditedDate = (product: ProductListItem) => {
    if (!product.updates.length) {
      return new Date(product.current.created_at);
    }

    const lastEditedDates = product.updates.map(
      (update) => new Date(update.created_at),
    );

    return lastEditedDates.reduce((a, b) => (a > b ? a : b));
  };

  // Get list of usage products for compositeProductIds selection
  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useProductsQuery();
  const allProducts = productsData?.contract_pricing.products ?? [];
  const usageProducts = allProducts.flatMap((p) =>
    p.__typename === "UsageProductListItem" ||
    p.__typename === "SubscriptionProductListItem"
      ? {
          id: p.id,
          name: p.current.name,
          type: {
            billableMetric:
              "billable_metric" in p.current
                ? p.current.billable_metric
                : undefined,
            pricingGroupKeys:
              "pricing_group_key" in p.current
                ? p.current.pricing_group_key
                : null,
            typeName: p.__typename,
          },
          lastEdited: getProductLastEditedDate(p),
        }
      : [],
  );
  const tagsFromDb = [
    ...new Set(
      allProducts.flatMap((product) => ProductListItem.getTags(product, now)),
    ),
  ];

  // Get billable metrics for usage product creation
  const {
    data: billableMetricsData,
    loading: billableMetricsLoading,
    error: billableMetricsError,
  } = useListBillableMetricsQuery({
    variables: {
      environment_type: environmentType,
    },
  });

  // We currently only support count, sum and max billable metrics
  const countSumAndMaxBillableMetrics = [
    ...(billableMetricsData?.billable_metrics ?? [])
      .filter((bm) => ["count", "sum", "max"].includes(bm.aggregate))
      .sort((a, b) => (a.name < b.name ? -1 : 1)),
  ];

  const snapshotKey = `product-create-v2`;
  const ctrl = useCreateProductController(snapshotKey, enforceType);
  const productType = ctrl.get("type");

  // save snapshot on every change of the form
  useEffect(() => {
    sessionStorage.setItem(snapshotKey, JSON.stringify(ctrl.snapshot()));
  }, [ctrl]);

  const clearSnapshot = () => {
    sessionStorage.removeItem(snapshotKey);
  };

  const isSubmitting =
    createCompositeProductResult.loading ||
    createFixedProductResult.loading ||
    createUsageProductResult.loading;

  const existingTagValues = new Set(
    tagsFromDb.concat(ctrl.state.fields["tags"].value ?? []),
  ); // we need the existing tag values in the options for them to render properly
  const tagOptions = Array.from(existingTagValues);

  const productTypeOptions: {
    label: string;
    value: "composite" | "fixed" | "usage" | "subscription" | "proService";
    hidden?: boolean | undefined;
  }[] = [
    { label: "Usage", value: "usage" as const },
    { label: "Fixed", value: "fixed" as const },
    { label: "Composite", value: "composite" as const },
    {
      label: "Subscription",
      value: "subscription" as const,
    },
  ];

  if (allowProfessionalServices) {
    productTypeOptions.push({
      label: "Professional service",
      value: "proService" as const,
    });
  }

  const createCompositeProduct = async (
    /* eslint-disable @typescript-eslint/no-explicit-any */ baseVariables: any,
    /* eslint-disable @typescript-eslint/no-explicit-any */ valid: any,
  ) => {
    const result = await createCompositeProductMutation({
      variables: {
        ...baseVariables,
        compositeProductIds: valid.product.compositeProductIds,
        compositeTags: valid.product.compositeTags,
        netSuiteOverageItemId: valid.product.netSuiteOverageItemId?.trim(),
        excludeFreeUsage: valid.product.excludeFreeUsage,
      },
      update(cache) {
        cache.evict({ fieldName: "contract_pricing" });
      },
    });
    return result.data?.create_composite_product_list_item?.id;
  };

  const createFixedProduct = async (
    /* eslint-disable @typescript-eslint/no-explicit-any */ baseVariables: any,
    /* eslint-disable @typescript-eslint/no-explicit-any */ valid: any,
  ) => {
    const fixedProductResult = await createFixedProductMutation({
      variables: baseVariables,
      update(cache) {
        cache.evict({ fieldName: "contract_pricing" });
      },
    });
    return fixedProductResult.data?.create_fixed_product_list_item?.id;
  };

  const createUsageProduct = async (
    /* eslint-disable @typescript-eslint/no-explicit-any */ baseVariables: any,
    /* eslint-disable @typescript-eslint/no-explicit-any */ valid: any,
  ) => {
    const usageProductResult = await createUsageProductMutation({
      variables: {
        ...baseVariables,
        billableMetricId: valid.product.billableMetricId,
        netSuiteOverageItemId: valid.product.netSuiteOverageItemId?.trim(),
        quantityConversion: valid.product.quantityConversion
          ? {
              conversion_factor:
                valid.product.quantityConversion.conversionFactor.toString(),
              name: valid.product.quantityConversion.name,
              operation:
                valid.product.quantityConversion.operation === "Divide"
                  ? ConversionOperation.Divide
                  : ConversionOperation.Multiply,
            }
          : undefined,
        quantityRounding: valid.product.quantityRounding
          ? {
              decimal_places: valid.product.quantityRounding.decimalPlaces,
              rounding_method: valid.product.quantityRounding.roundingMethod,
            }
          : undefined,
        pricingGroupKey: valid.product.pricingGroupKey,
        presentationGroupKey: valid.product.presentationGroupKey,
      },
      update(cache) {
        cache.evict({ fieldName: "contract_pricing" });
      },
    });
    return usageProductResult.data?.create_usage_product_list_item?.id;
  };

  const createSubscriptionProduct = async (
    /* eslint-disable @typescript-eslint/no-explicit-any */ baseVariables: any,
    /* eslint-disable @typescript-eslint/no-explicit-any */ valid: any,
  ) => {
    const subscriptionProductResult = await createSubscriptionProductMutation({
      variables: {
        ...baseVariables,
        netSuiteOverageItemId: valid.product.netSuiteOverageItemId?.trim(),
      },
      update(cache) {
        cache.evict({ fieldName: "contract_pricing" });
      },
    });
    return subscriptionProductResult.data?.create_subscription_product_list_item
      ?.id;
  };

  const createProServiceProduct = async (
    /* eslint-disable @typescript-eslint/no-explicit-any */ baseVariables: any,
    /* eslint-disable @typescript-eslint/no-explicit-any */ valid: any,
  ) => {
    const proServiceProductResult = await createProServiceProductMutation({
      variables: baseVariables,
      update(cache) {
        cache.evict({ fieldName: "contract_pricing" });
      },
    });
    return proServiceProductResult.data?.create_pro_service_product_list_item
      ?.id;
  };

  const onSubmitAndAddAnother = FormController.useSubmitHandler(
    ctrl,
    async (valid) => {
      const baseVariables = {
        ...valid,
        name: valid.name.trim(),
        tags: valid.tags,
        netSuiteInternalItemId: valid.netSuiteInternalItemId?.trim(),
      };
      switch (valid.product.type) {
        case "composite": {
          try {
            await createCompositeProduct(baseVariables, valid);
          } catch (e) {
            pushErrorMessage("composite", e);
            return;
          }
          break;
        }
        case "fixed": {
          try {
            await createFixedProduct(baseVariables, valid);
          } catch (e) {
            pushErrorMessage("fixed", e);
            return;
          }
          break;
        }
        case "usage": {
          try {
            await createUsageProduct(baseVariables, valid);
          } catch (e) {
            pushErrorMessage("usage", e);
            return;
          }
          break;
        }
        case "subscription":
          try {
            await createSubscriptionProduct(baseVariables, valid);
          } catch (e) {
            pushErrorMessage("subscription", e);
            return;
          }
          break;
        case "proService": {
          try {
            await createProServiceProduct(baseVariables, valid);
          } catch (e) {
            pushErrorMessage("proService", e);
            return;
          }
          break;
        }
        default: {
          valid.product satisfies never;
        }
      }
      sessionStorage.setItem(snapshotKey, "");
      ctrl.reset();

      pushSuccessMessage(valid.product.type, valid.name);
    },
  );
  const [showPreviewPage, setShowPreviewPage] = useState(shouldShowPreviewPage);

  const onSubmit = FormController.useSubmitHandler(ctrl, async (valid) => {
    const baseVariables = {
      ...valid,
      name: valid.name.trim(),
      tags: valid.tags,
      netSuiteInternalItemId: valid.netSuiteInternalItemId?.trim(),
    };

    switch (valid.product.type) {
      case "composite": {
        try {
          const newProductId = await createCompositeProduct(
            baseVariables,
            valid,
          );
          if (newProductId) {
            sessionStorage.setItem(snapshotKey, "");
            ctrl.reset();
            if (shouldShowProductCreatedModal()) {
              setCreatedProductId(newProductId);
              setShowProductCreatedModal(true);
            } else {
              pushSuccessMessage("composite", valid.name);
              onClose
                ? onClose(newProductId)
                : navigate(
                    `${newUIEnabled ? "/offering" : "/contract-pricing"}/products/${newProductId}`,
                  );
            }
          }
        } catch (e) {
          pushErrorMessage("composite", e);
        }
        return;
      }
      case "fixed": {
        try {
          const newProductId = await createFixedProduct(baseVariables, valid);
          if (newProductId) {
            sessionStorage.setItem(snapshotKey, "");
            ctrl.reset();
            if (shouldShowProductCreatedModal()) {
              setCreatedProductId(newProductId);
              setShowProductCreatedModal(true);
            } else {
              pushSuccessMessage("fixed", valid.name);
              onClose
                ? onClose(newProductId)
                : navigate(
                    `${newUIEnabled ? "/offering" : "/contract-pricing"}/products/${newProductId}`,
                  );
            }
          }
        } catch (e) {
          pushErrorMessage("fixed", e);
        }
        return;
      }
      case "usage": {
        try {
          const newProductId = await createUsageProduct(baseVariables, valid);
          if (newProductId) {
            sessionStorage.setItem(snapshotKey, "");
            ctrl.reset();
            if (shouldShowProductCreatedModal()) {
              setCreatedProductId(newProductId);
              setShowProductCreatedModal(true);
            } else {
              pushSuccessMessage("usage", valid.name);
              onClose
                ? onClose(newProductId)
                : navigate(
                    `${newUIEnabled ? "/offering" : "/contract-pricing"}/products/${newProductId}`,
                  );
            }
          }
        } catch (e) {
          pushErrorMessage("usage", e);
        }
        return;
      }
      case "subscription":
        try {
          const newProductId = await createSubscriptionProduct(
            baseVariables,
            valid,
          );

          if (newProductId) {
            sessionStorage.setItem(snapshotKey, "");
            ctrl.reset();
            if (shouldShowProductCreatedModal()) {
              setCreatedProductId(newProductId);
              setShowProductCreatedModal(true);
            } else {
              pushSuccessMessage("subscription", valid.name);
              onClose
                ? onClose(newProductId)
                : navigate(
                    `${newUIEnabled ? "/offering" : "/contract-pricing"}/products/${newProductId}`,
                  );
            }
          }
        } catch (e) {
          pushErrorMessage("subscription", e);
        }
        return;
      case "proService": {
        try {
          const newProductId = await createProServiceProduct(
            baseVariables,
            valid,
          );

          if (newProductId) {
            sessionStorage.setItem(snapshotKey, "");
            ctrl.reset();
            if (shouldShowProductCreatedModal()) {
              setCreatedProductId(newProductId);
              setShowProductCreatedModal(true);
            } else {
              pushSuccessMessage("proService", valid.name);
              onClose
                ? onClose(newProductId)
                : navigate(
                    `${newUIEnabled ? "/offering" : "/contract-pricing"}/products/${newProductId}`,
                  );
            }
          }
        } catch (e) {
          pushErrorMessage("proService", e);
        }
        return;
      }
      default: {
        valid.product satisfies never;
      }
    }
  });

  if (billableMetricsError) {
    return (
      <ErrorEmptyState
        title="Could not fetch billable metrics"
        error={billableMetricsError}
      />
    );
  }
  if (productsError) {
    return (
      <ErrorEmptyState title="Could not fetch products" error={productsError} />
    );
  }

  return (
    <Takeover
      maxContainerWidth="max-w-[1400px]"
      isOpen={true}
      onClose={() => {
        onClose
          ? onClose()
          : navigate(
              newUIEnabled
                ? "/offering/products"
                : "/contract-pricing/products",
            );
        clearSnapshot();
      }}
      title="Create a product"
      headerButtons={[
        <Button
          text="View docs"
          theme="secondary"
          leadingIcon="share03"
          onClick={() => {
            // TODO: add link to docs
          }}
        />,
      ]}
      footerTrailingButtons={[
        <Button
          onClick={onSubmitAndAddAnother}
          disabled={
            !ctrl.isValid() ||
            createCompositeProductResult.loading ||
            createFixedProductResult.loading ||
            createUsageProductResult.loading ||
            createSubscriptionProductResult.loading ||
            createProServiceProductResult.loading
          }
          text="Add another"
          theme="secondary"
        />,
        <Button
          onClick={onSubmit}
          disabled={
            !ctrl.isValid() ||
            createCompositeProductResult.loading ||
            createFixedProductResult.loading ||
            createUsageProductResult.loading ||
            createSubscriptionProductResult.loading ||
            createProServiceProductResult.loading
          }
          loading={isSubmitting}
          text="Save"
          theme="primary"
          type="submit"
        />,
      ]}
      footerLeadingButton={
        <Button
          className="w-[128px] justify-center"
          text="Back"
          onClick={() => {
            if (shouldShowPreviewPage()) {
              setShowPreviewPage(true);
            } else {
              navigate(
                newUIEnabled
                  ? "/offering/products"
                  : "/contract-pricing/products",
              );
            }
          }}
          theme="secondary"
        />
      }
      children={
        <div>
          {showProductCreatedModal && (
            <ProductCreatedModal
              onClose={() => {
                navigate(
                  `${newUIEnabled ? "/offering" : "/contract-pricing"}/products/${createdProductId}`,
                );
                setShowProductCreatedModal(false);
              }}
            />
          )}
          {showPreviewPage ? (
            <ProductCreateLandingPage setShowPreviewPage={setShowPreviewPage} />
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit;
              }}
            >
              <input type="submit" className="hidden" />
              <Body level={2}>
                Products are line items that will appear on invoices. Create a
                new product that can then be applied to a rate card or a
                contract.
              </Body>
              <div className="grid gap-12">
                <TextInput
                  fullWidth
                  placeholder="Product name"
                  label="Name"
                  helpText="Enter a name for the product"
                  value={ctrl.get("name")}
                  onChange={(meta: { value: string }) => {
                    ctrl.update({ name: meta.value });
                  }}
                />
                <InputDropdown
                  value={ctrl.get("tags") ?? []}
                  hintText="Tags help group and categorize products, allowing for actions like easy pricing changes on a contract"
                  label="Tags"
                  placeholder="Add tags"
                  tagsVariant={true}
                  onChange={(meta: { value: string[] }) => {
                    ctrl.update({ tags: meta.value });
                  }}
                  leadingIcon="searchSm"
                  disabled={productType === "proService"}
                  fullWidth
                  // TODO: add loading
                  // loading={productsLoading}
                  // TODO: add tooltip
                  // tooltip={
                  //   productType === "proService"
                  //     ? "Tags are not supported for professional service products"
                  //     : undefined
                  // }
                >
                  {tagOptions.map((u, index) => (
                    <DropdownItem
                      label={u}
                      value={u}
                      key={"tag" + index}
                      onClick={({ selected }) => {
                        const prevTags = ctrl.get("tags") ?? [];
                        selected
                          ? ctrl.update({
                              tags: prevTags.filter((t) => t !== u),
                            })
                          : ctrl.update({
                              tags: [...prevTags, u],
                            });
                      }}
                      selected={ctrl.get("tags")?.includes(u)}
                    />
                  ))}
                </InputDropdown>

                {netsuiteEnabled && (
                  <TextInput
                    fullWidth
                    placeholder="Enter ID"
                    label="NetSuite internal item ID"
                    value={ctrl.get("netSuiteInternalItemId")}
                    onChange={(meta: { value: string }) => {
                      ctrl.update({
                        netSuiteInternalItemId: meta.value.trim(),
                      });
                    }}
                  />
                )}
                <div className="mb-15 mt-20">
                  <SectionHeader
                    title="Select the product type"
                    subtitle="Metronome offers a variety of products that will influence how they are priced"
                    bottomBorder={false}
                  />
                </div>
                <div className="ml-4 mt-20">
                  <OptionGroup className="w-full">
                    <RadioButton
                      label="Usage"
                      checked={ctrl.get("type") === "usage"}
                      onChange={() => ctrl.update({ type: "usage" })}
                      supportingText="Usage products are priced based on events that match it. Event matching and aggregation is determined by the product’s underlying billable metric."
                      value=""
                    />
                    <RadioButton
                      label="Subscription"
                      checked={ctrl.get("type") === "subscription"}
                      onChange={() => ctrl.update({ type: "subscription" })}
                      supportingText="Subscription products are a fixed rate and billed on a schedule."
                      value=""
                    />
                    <RadioButton
                      label="Composite"
                      checked={ctrl.get("type") === "composite"}
                      onChange={() => ctrl.update({ type: "composite" })}
                      supportingText="Composite products are percent-based. The percent is based on the line item cost of the applicable usage products your choose."
                      value=""
                    />
                    <RadioButton
                      label="Fixed"
                      checked={ctrl.get("type") === "fixed"}
                      onChange={() => ctrl.update({ type: "fixed" })}
                      supportingText="Fixed products are used to power commit and credits. "
                      value=""
                    />
                    {allowProfessionalServices && (
                      <RadioButton
                        label="Professional Service"
                        checked={ctrl.get("type") === "proService"}
                        onChange={() => ctrl.update({ type: "proService" })}
                        supportingText=""
                        value=""
                      />
                    )}
                  </OptionGroup>
                </div>
                {(() => {
                  switch (productType) {
                    case "fixed":
                      return <FixedProductFields parent={ctrl} />;
                    case "usage":
                      return (
                        <UsageProductFields
                          parent={ctrl}
                          allBillableMetrics={countSumAndMaxBillableMetrics}
                          loading={billableMetricsLoading}
                          options={{ netsuiteEnabled }}
                        />
                      );
                    case "subscription":
                      return (
                        <SubscriptionProductFields
                          parent={ctrl}
                          options={{ netsuiteEnabled }}
                        />
                      );
                    case "composite":
                      return (
                        <CompositeProductFields
                          parent={ctrl}
                          usageProducts={usageProducts}
                          tags={tagsFromDb}
                          loading={productsLoading}
                          options={{
                            netsuiteEnabled,
                            excludeFreeUsageEnabled,
                          }}
                        />
                      );
                    case "proService":
                      return null;
                  }
                })()}

                {refundableProductsEnabled && (
                  <Toggle
                    toggled={ctrl.get("isRefundable")}
                    onChange={(meta: { toggled: boolean }) => {
                      ctrl.update({ isRefundable: meta.toggled });
                    }}
                    label="Refundable"
                    className="my-20"
                  />
                )}
              </div>
            </form>
          )}
        </div>
      }
    ></Takeover>
  );
};

const useFixedController = useCreateProductController.child(
  Schema.FixedProductInput,
  {
    read(parent) {
      const product = parent.get("product");
      return product?.type === "fixed" ? product : {};
    },
    write(self) {
      return { product: self.getUnvalidatedInputs() };
    },
  },
);

// Even if it doesn't have any custom fields, controller initialization is necessary to set initial values (type)
export const FixedProductFields: React.FC<{
  parent: CreateProductController;
  slot?: React.ReactNode;
}> = ({ parent, slot }) => {
  useFixedController(parent);
  return <>{slot}</>;
};

const useCompositeController = useCreateProductController.child(
  Schema.CompositeProductInput,
  {
    read(parent) {
      const product = parent.get("product");
      return product?.type === "composite" ? product : {};
    },
    write(self) {
      return { product: self.getUnvalidatedInputs() };
    },
  },
);
export const CompositeProductFields: React.FC<{
  parent: CreateProductController;
  usageProducts: {
    name: string;
    id: string;
    type?: {
      billableMetric?: {
        id: string;
        aggregate?: BillingMetricAggregateEnum_Enum;
        sql: string | null;
        name: string;
      };
      pricingGroupKeys: null | string[];
      typeName: string;
    };
    lastEdited: Date;
  }[];
  tags: string[];
  loading: boolean;
  slot?: React.ReactNode;
  options?: {
    netsuiteEnabled?: boolean;
    excludeFreeUsageEnabled?: boolean;
  };
}> = ({ parent, usageProducts, tags, loading, slot, options }) => {
  const ctrl = useCompositeController(parent);
  const [isProduct, setIsProduct] = React.useState(true);
  return (
    <>
      <div className="mb-15 mt-20 ">
        <SectionHeader
          title="Select the applicable products or tags"
          subtitle="These products define the rate of a composite product. The percent is based on the line item cost of the products selected."
          bottomBorder={false}
        />
      </div>
      <div className="ml-4 w-full">
        {isProduct && (
          <Table
            title={
              <ButtonGroup
                buttons={[
                  {
                    text: "Products",
                    onClick: () => setIsProduct(true),
                    isActive: isProduct,
                  },
                  {
                    text: "Tags",
                    onClick: () => setIsProduct(false),
                    isActive: !isProduct,
                  },
                ]}
              />
            }
            columns={[
              {
                id: "1",
                header: "Name",
                cell: ({ row }) => {
                  const { id, name } = row.original;
                  return (
                    <Checkbox
                      label={name}
                      checked={ctrl.get("compositeProductIds")?.includes(id)}
                      onClick={() => {
                        const ids = ctrl.get("compositeProductIds") || [];
                        if (ids.includes(id)) {
                          ctrl.update({
                            compositeProductIds: ids.filter((i) => i !== id),
                          });
                        } else {
                          ctrl.update({
                            compositeProductIds: ids.concat([id]),
                          });
                        }
                      }}
                    />
                  );
                },
                accessorKey: "name",
                enableSorting: false,
              },
              {
                id: "2",
                accessorKey: "type.typeName",
                cell: ({ row }) => {
                  const typeName = row.original.type?.typeName;
                  const getBadgeLabel = () => {
                    // Composite's can only have subscription and usage products as sub-products
                    switch (typeName) {
                      case "SubscriptionProductListItem":
                        return "Subscription";
                      case "UsageProductListItem":
                        return "Usage";
                      default:
                        return "";
                    }
                  };

                  const badgeLabel = getBadgeLabel();

                  const getBadgeTheme = (badgeLabel: string) => {
                    switch (badgeLabel) {
                      case "Subscription":
                        return "indigo";
                      case "Usage":
                        return "gray";
                      default:
                        return undefined;
                    }
                  };

                  const badgeTheme = getBadgeTheme(badgeLabel);

                  return <Badge label={badgeLabel} theme={badgeTheme} />;
                },
                header: "Type",
                enableSorting: false,
              },
              {
                id: "3",
                accessorKey: "lastEdited",
                cell: (props: { getValue: () => Date }) => {
                  // toDo: [PENG-893] Add date rendering logic
                  return <Timestamp dateTime={props.getValue()} />;
                },
                header: () => {
                  return <div className="flex">Last Edited (UTC)</div>;
                },
                enableSorting: false,
              },
            ]}
            searchOptions={{
              showSearch: true,
              onSearch: (query: string) => {
                // TODO: implement search
                console.log(query);
              },
            }}
            data={usageProducts}
            loading={loading}
            paginationOptions={{
              type: "clientSide",
              minimumPageSize: 10,
            }}
          />
        )}
        {!isProduct && (
          <Table
            title={
              <ButtonGroup
                buttons={[
                  {
                    text: "Products",
                    onClick: () => setIsProduct(true),
                    isActive: isProduct,
                  },
                  {
                    text: "Tags",
                    onClick: () => setIsProduct(false),
                    isActive: !isProduct,
                  },
                ]}
              />
            }
            columns={[
              {
                id: "name",
                header: "Name",
                cell: (props: { getValue: () => string }) => {
                  const tag = props.getValue();
                  return (
                    <Checkbox
                      label={tag}
                      checked={ctrl.get("compositeTags")?.includes(tag)}
                      onClick={() => {
                        const tags = ctrl.get("compositeTags") || [];
                        if (tags.includes(tag)) {
                          ctrl.update({
                            compositeTags: tags.filter((i) => i !== tag),
                          });
                        } else {
                          ctrl.update({
                            compositeTags: tags.concat([tag]),
                          });
                        }
                      }}
                    />
                  );
                },
                accessorKey: "id",
                enableSorting: false,
              },
            ]}
            searchOptions={{
              showSearch: true,
              onSearch: (query: string) => {
                // TODO: implement search
                console.log(query);
              },
            }}
            data={tags.map((tag) => ({ id: tag }))}
            loading={loading}
            paginationOptions={{
              type: "clientSide",
              minimumPageSize: 10,
            }}
          />
        )}
      </div>
      {slot}
      {options?.netsuiteEnabled && (
        <TextInput
          fullWidth
          placeholder="Enter ID"
          label="NetSuite overage item ID"
          value={ctrl.get("netSuiteOverageItemId")}
          onChange={(meta: { value: string }) => {
            ctrl.update({ netSuiteOverageItemId: meta.value.trim() });
          }}
        />
      )}
      {options?.excludeFreeUsageEnabled && (
        <Toggle
          toggled={ctrl.get("excludeFreeUsage")}
          onChange={(meta: { toggled: boolean }) => {
            ctrl.update({ excludeFreeUsage: meta.toggled });
          }}
          label="Exclude free usage"
        />
      )}
    </>
  );
};

const useUsageController = useCreateProductController.child(
  Schema.UsageProductInput,
  {
    read(parent) {
      const product = parent.get("product");
      return product?.type === "usage"
        ? {
            ...product,
          }
        : {};
    },
    write(self) {
      return {
        product: self.getUnvalidatedInputs(),
      };
    },
  },
);

export const UsageProductFields: React.FC<{
  parent: CreateProductController;
  allBillableMetrics: {
    name: string;
    id: string;
    aggregate: string;
    group_keys: object | null;
    Creator: { id: string; name: string; deprecated_at: string | null } | null;
    updated_at: string;
    sql: string | null;
    fancy_plan: object | null;
  }[];
  loading: boolean;
  slot?: React.ReactNode;
  options?: {
    netsuiteEnabled?: boolean;
  };
}> = ({ parent, allBillableMetrics, loading, slot, options }) => {
  const ctrl = useUsageController(parent);
  const billableMetric = allBillableMetrics.find(
    (bm) => bm.id === ctrl.get("billableMetricId"),
  );
  const groupKeys = billableMetric ? getGroupKeys(billableMetric) : [];
  const [conversionFactorError, setConversionFactorError] = React.useState<
    string | null
  >(null);
  const [roundQuantityError, setRoundQuantityError] = React.useState<
    string | null
  >(null);

  // Group keys are returned as a mix of compound keys and single keys
  // example: ["region", ["region", "provider"]]
  // the second one is a compound key. We need to be able to process both
  function getGroupKeyOptions(): {
    label: string;
    value: string;
    subtext: string | undefined;
  }[] {
    const input = groupKeys;

    const deduplicatedKeys = Array.from(new Set(input.flat()));

    return deduplicatedKeys.map((key) => {
      const applicableCompoundKeys = input
        .filter((item) => Array.isArray(item))
        .filter((item) => item.includes(key))
        .map((item) => {
          return Array.isArray(item) ? `[${item.join(", ")}]` : item;
        });

      return {
        label: key,
        value: key,
        subtext: applicableCompoundKeys.length
          ? `${applicableCompoundKeys.join(", ")}`
          : undefined,
      };
    });
  }

  const billableMetricColumns: Column<{
    name: string;
    id: string;
    aggregate: string;
    group_keys: object | null;
    Creator: { id: string; name: string; deprecated_at: string | null } | null;
    updated_at: string;
    sql: string | null;
  }>[] = useMemo(
    () => [
      {
        id: "1",
        header: "Name",
        cell: ({ row }) => {
          const { id, name } = row.original;
          return (
            <RadioButton
              label={name}
              checked={ctrl.get("billableMetricId") === id}
              onClick={() =>
                ctrl.update({
                  billableMetricId: id,
                  pricingGroupKey: undefined,
                  presentationGroupKey: undefined,
                })
              }
              value=""
            />
          );
        },
        accessorKey: "name",
        enableSorting: false,
      },
      {
        id: "2",
        accessorKey: "aggregate",
        cell: ({ row }) => {
          const aggregate = row.original.aggregate;
          const sql = row.original.sql;

          const getBadgeLabel = () => {
            if (sql) {
              return "SQL";
            }
            switch (aggregate) {
              case "sum":
                return "Sum";
              case "subscription":
                return "Subscription";
              case "count":
                return "Count";
              case "composite":
                return "Composite";
              case "max":
                return "Max";
              case "unique":
                return "Unique";
              default:
                return "";
            }
          };

          const badgeLabel = getBadgeLabel();

          const getBadgeTheme = () => {
            if (sql) {
              return "success";
            }
            switch (aggregate) {
              case "sum":
                return "indigo";
              case "subscription":
                return "indigo";
              case "count":
                return "deep-denim";
              case "composite":
                return "vibrant-magenta";
              case "max":
                return "warning";
              case "unique":
                return "midnight";
              default:
                return undefined;
            }
          };

          const badgeTheme = getBadgeTheme();

          return <Badge label={badgeLabel} theme={badgeTheme} />;
        },
        header: "Aggregate",
        enableSorting: false,
      },
      {
        id: "3",
        header: "User",
        cell: ({ row }) => {
          const creator = row.original.Creator;
          return (
            <div className="pl-8">
              {creator && <AvatarWithName {...creator} />}
            </div>
          );
        },
        accessorKey: "Creator.name",
        enableSorting: false,
      },
      {
        id: "4",
        accessorKey: "updated_at",
        cell: ({ row }) => {
          return <Timestamp dateTime={new Date(row.original.updated_at)} />;
        },
        header: () => {
          return <div className="flex">Last Edited (UTC)</div>;
        },
        enableSorting: false,
      },
    ],
    [ctrl],
  );

  const validateConversionFactor = (value: string): string | null => {
    if (!value) {
      return null;
    }

    const numberValue = Number(value);

    if (isNaN(numberValue)) {
      return "Conversion factor must be a number";
    } else if (numberValue <= 0) {
      return "Conversion factor must be greater than 0";
    }

    return null;
  };

  const handleConversionFactorChange = (meta: { value: string }) => {
    const error = validateConversionFactor(meta.value);
    setConversionFactorError(error);

    if (!error) {
      ctrl.update({
        quantityConversion: {
          ...ctrl.get("quantityConversion"),
          conversionFactor: parseFloat(meta.value),
        },
      });
    } else {
      ctrl.update({
        quantityConversion: {
          ...ctrl.get("quantityConversion"),
        },
      });
    }
  };

  const conversionFactorHintText = () => {
    if (conversionFactorError) {
      return conversionFactorError;
    } else {
      if (ctrl.get("quantityConversion")?.operation === "Divide") {
        return "The quantity will be divided by the conversion factor";
      } else if (ctrl.get("quantityConversion")?.operation === "Multiply") {
        return "The quantity will be multiplied by the conversion factor";
      } else {
        return "";
      }
    }
  };

  const validateRoundingConversion = (value: string): string | null => {
    const numberValue = Number(value);

    if (isNaN(numberValue)) {
      return "Decimal places must be a number";
    } else if (numberValue < 0) {
      return "Decimal places must be greater than or equal to 0";
    } else if (numberValue % 1 !== 0) {
      return "Decimal places must be an integer";
    }

    return null;
  };

  const handleRoundingChange = (meta: { value: string }) => {
    const error = validateRoundingConversion(meta.value);
    setRoundQuantityError(error);

    if (!error) {
      ctrl.update({
        quantityRounding: {
          ...ctrl.get("quantityRounding"),
          decimalPlaces: parseInt(meta.value),
        },
      });
    } else {
      ctrl.update({
        quantityRounding: {
          ...ctrl.get("quantityRounding"),
        },
      });
    }
  };

  const roundingHintText = () => {
    if (roundQuantityError) {
      return roundQuantityError;
    } else {
      return "The number of decimal place to round. Enter 0 to round to the nearest integer.";
    }
  };

  return (
    <>
      <div className="mb-15 mt-20 ">
        <SectionHeader
          title="Select a billable metric"
          subtitle="Usage products are associated to a billable metric. This will determine how events are filtered and aggregated."
          bottomBorder={false}
        />
      </div>

      <div className="ml-4 w-full">
        <Table
          title="Billable metrics"
          columns={billableMetricColumns}
          data={allBillableMetrics}
          loading={loading}
          paginationOptions={{
            type: "clientSide",
            minimumPageSize: 10,
          }}
          key="metrics-table"
          searchOptions={{
            showSearch: true,
          }}
        />
      </div>
      {groupKeys?.length > 0 && (
        <div className="mt-20">
          <SectionHeader
            title="Define presentation and dimensional pricing groups (optional)"
            subtitle="Presentation keys allow you to group events on an invoice. Dimensional pricing enables a single product to have variable rates based on the properties of an event."
            bottomBorder={true}
          />
          <div className="ml-4 mt-20">
            <InputDropdown
              value={ctrl.get("presentationGroupKey")}
              hintText="Presentation keys define how to group events on an invoice (i.e. group events by environment)"
              label="Presentation keys"
              placeholder="Select keys"
              tagsVariant={true}
              onChange={(meta: { value: string[] }) => {
                ctrl.update({ presentationGroupKey: meta.value });
              }}
              leadingIcon="searchSm"
              fullWidth
            >
              {getGroupKeyOptions().map((u, index) => (
                <DropdownItem
                  label={u.label}
                  value={u.value}
                  subtext={u.subtext}
                  key={"presentationGroupKey" + index}
                  onClick={({ selected }) => {
                    const prevTags = ctrl.get("presentationGroupKey") ?? [];
                    selected
                      ? ctrl.update({
                          presentationGroupKey: prevTags.filter(
                            (t) => t !== u.value,
                          ),
                        })
                      : ctrl.update({
                          presentationGroupKey: [...prevTags, u.value],
                        });
                  }}
                  selected={ctrl.get("presentationGroupKey")?.includes(u.value)}
                />
              ))}
            </InputDropdown>
          </div>
          <div className="ml-4 mt-20">
            <InputDropdown
              value={ctrl.get("pricingGroupKey")}
              hintText="Dimensional pricing keys enable you to price events uniquely based on their values"
              label="Dimensional pricing keys"
              placeholder="Select keys"
              tagsVariant={true}
              onChange={(meta: { value: string[] }) => {
                ctrl.update({ pricingGroupKey: meta.value });
              }}
              leadingIcon="searchSm"
              fullWidth
            >
              {getGroupKeyOptions().map((u, index) => (
                <DropdownItem
                  label={u.label}
                  value={u.value}
                  subtext={u.subtext}
                  key={"pricingGroupKey" + index}
                  onClick={({ selected }) => {
                    const prevTags = ctrl.get("pricingGroupKey") ?? [];
                    selected
                      ? ctrl.update({
                          pricingGroupKey: prevTags.filter(
                            (t) => t !== u.value,
                          ),
                        })
                      : ctrl.update({
                          pricingGroupKey: [...prevTags, u.value],
                        });
                  }}
                  selected={ctrl.get("pricingGroupKey")?.includes(u.value)}
                />
              ))}
            </InputDropdown>
          </div>
        </div>
      )}

      {slot}
      <div className="mt-20">
        {options?.netsuiteEnabled && (
          <TextInput
            fullWidth
            placeholder="Enter ID"
            label="NetSuite overage item ID"
            value={ctrl.get("netSuiteOverageItemId")}
            onChange={(meta: { value: string }) => {
              ctrl.update({ netSuiteOverageItemId: meta.value.trim() });
            }}
          />
        )}
        <div className="my-20">
          <Toggle
            toggled={!!ctrl.get("quantityConversion")}
            onChange={({ toggled }) => {
              if (toggled) {
                ctrl.update({
                  quantityConversion: {},
                });
              } else {
                ctrl.update({
                  quantityConversion: undefined,
                });
              }
            }}
            label="Convert quantity"
            supportingText="Convert the total quantity on the invoice to support use cases like, bytes to giga-bytes."
            // TODO: add tooltip
            // tooltip="You can specify a conversion factor which will apply to the quantity before pricing."
          />
          {ctrl.get("quantityConversion") && (
            <div className="gap-lg flex flex-col pt-[24px]">
              <ButtonGroup
                buttons={[
                  {
                    onClick: () => {
                      ctrl.update({
                        quantityConversion: {
                          operation: ConversionOperation.Multiply,
                        },
                      });
                    },
                    text: "Multiply quantity",
                    isActive:
                      ctrl.get("quantityConversion")?.operation === "Multiply",
                  },
                  {
                    onClick: () => {
                      ctrl.update({
                        quantityConversion: {
                          operation: ConversionOperation.Divide,
                        },
                      });
                    },
                    text: "Divide quantity",
                    isActive:
                      ctrl.get("quantityConversion")?.operation === "Divide",
                  },
                ]}
              />
              <div className="gap-lg flex flex-row">
                <TextInput
                  label="Conversion factor"
                  placeholder="Enter the conversion"
                  fullWidth
                  isInvalid={!!conversionFactorError}
                  value={
                    ctrl.get("quantityConversion")?.conversionFactor
                      ? ctrl
                          .get("quantityConversion")
                          ?.conversionFactor.toString()
                      : ""
                  }
                  onChange={handleConversionFactorChange}
                  hintText={conversionFactorHintText()}
                />
                <TextInput
                  label="Conversion name (optional"
                  placeholder="Enter name such as GB's"
                  fullWidth
                  value={ctrl.get("quantityConversion")?.name || ""}
                  onChange={(meta: { value: string }) => {
                    if (meta.value) {
                      ctrl.update({
                        quantityConversion: {
                          ...ctrl.get("quantityConversion"),
                          name: meta.value,
                        },
                      });
                    } else {
                      ctrl.update({
                        quantityConversion: {
                          ...ctrl.get("quantityConversion"),
                          name: undefined,
                        },
                      });
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="py-[32px]">
          <Toggle
            toggled={!!ctrl.get("quantityRounding")}
            onChange={({ toggled }) => {
              if (toggled) {
                ctrl.update({
                  quantityRounding: {},
                });
              } else {
                ctrl.update({
                  quantityRounding: undefined,
                });
              }
            }}
            label="Round quantity"
            supportingText="Rounding is applied after quantity conversion, supporting use cases including block pricing."
            // TODO: add tooltip
            // tooltip="You can specify a rounding configuration which will apply to the quantity before pricing."
          />
          {ctrl.get("quantityRounding") && (
            <div className="gap-lg flex flex-col pt-[24px]">
              <ButtonGroup
                buttons={[
                  {
                    onClick: () => {
                      ctrl.update({
                        quantityRounding: {
                          roundingMethod: RoundingMethod.Ceiling,
                        },
                      });
                    },
                    text: "Round up",
                    isActive:
                      ctrl.get("quantityRounding")?.roundingMethod ===
                      "Ceiling",
                  },
                  {
                    onClick: () => {
                      ctrl.update({
                        quantityRounding: {
                          roundingMethod: RoundingMethod.HalfUp,
                        },
                      });
                    },
                    text: "Round by half",
                    isActive:
                      ctrl.get("quantityRounding")?.roundingMethod === "HalfUp",
                  },
                  {
                    onClick: () => {
                      ctrl.update({
                        quantityRounding: {
                          roundingMethod: RoundingMethod.Floor,
                        },
                      });
                    },
                    text: "Round down",
                    isActive:
                      ctrl.get("quantityRounding")?.roundingMethod === "Floor",
                  },
                ]}
              />
              <div className="gap-lg flex flex-row">
                <TextInput
                  label="Decimal places"
                  placeholder="Enter the conversion"
                  fullWidth
                  isInvalid={!!roundQuantityError}
                  value={
                    ctrl.get("quantityRounding")?.decimalPlaces
                      ? ctrl.get("quantityRounding")?.decimalPlaces.toString()
                      : ""
                  }
                  onChange={handleRoundingChange}
                  hintText={roundingHintText()}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const useSubscriptionController = useCreateProductController.child(
  Schema.SubscriptionProductInput,
  {
    read(parent) {
      const product = parent.get("product");
      return product?.type === "subscription" ? product : {};
    },
    write(self) {
      return { product: self.getUnvalidatedInputs() };
    },
  },
);
export const SubscriptionProductFields: React.FC<{
  parent: CreateProductController;
  slot?: React.ReactNode;
  options?: {
    netsuiteEnabled?: boolean;
  };
}> = ({ parent, slot, options }) => {
  const ctrl = useSubscriptionController(parent);
  return (
    <>
      {slot}
      {options?.netsuiteEnabled && (
        <TextInput
          fullWidth
          placeholder="Enter ID"
          label="NetSuite overage item ID"
          value={ctrl.get("netSuiteOverageItemId")}
          onChange={(meta: { value: string }) => {
            ctrl.update({ netSuiteOverageItemId: meta.value.trim() });
          }}
        />
      )}
    </>
  );
};
