import Decimal from "decimal.js";
import React, { useEffect } from "react";
import { useDraftPlan } from "../../context";
import { PlanPreview, PlanPreviewInfo } from "components/PlanPreview";
import {
  useWizardContext,
  WizardFullPage,
  WizardSection,
} from "components/Wizard";
import { CreatePlanDataQuery } from "../../data/queries.graphql";
import {
  billingDayOfPeriodToEnum,
  collectionScheduleToEnum,
  DraftPlan,
  PlanDetails,
} from "lib/plans/types";
import { CustomCreditType, FiatCreditType } from "types/credit-types";
import { CustomerImpact } from "./components/CustomerImpact";
import { Popup } from "components/Popup";
import { Body, DateInput, HelpCircleTooltip, Toggle } from "design-system";
import { Button } from "tenaissance/components/Button";
import { BILLING_SCHEDULE_STEP_NAVIGATION_KEY } from "../PlanTerms/components/SetBillingSchedule";
import {
  BillingFrequencyEnum_Enum,
  ChargeTypeEnum_Enum,
  CompositeChargeTypeEnum_Enum,
  TieringModeEnum_Enum,
} from "types/generated-graphql/__types__";
import { PreviewCustomPricing } from "../PreviewCustomPricing";
import { getChargeType } from "lib/plans/draftPlan";

const isPreviewPlanDone = (draftPlan: DraftPlan, hasPreviewedPlan: boolean) => {
  return (
    hasPreviewedPlan &&
    !hasInvalidTrialCreditType(draftPlan) &&
    draftPlan.startingOn !== null
  );
};

function hasInvalidTrialCreditType(draftPlan: DraftPlan) {
  const validCreditTypesForTrial = new Set<string>();
  draftPlan.pricedProducts?.forEach((pp) => {
    if (pp.creditType && pp.pricingFactors.some((pf) => pf.startPeriod === 0)) {
      validCreditTypesForTrial.add(pp.creditType.id);
    }
  });
  draftPlan.minimums?.forEach((min) => {
    if (min.creditType && min.startPeriod === 0) {
      validCreditTypesForTrial.add(min.creditType.id);
    }
  });
  draftPlan.creditTypeConversions?.forEach((conv) => {
    if (conv.startPeriod === 0) {
      validCreditTypesForTrial.add(conv.customCreditType.id);
      validCreditTypesForTrial.add(conv.fiatCreditType.id);
    }
  });
  return !!(
    draftPlan.hasTrial &&
    draftPlan.trialSpec?.caps?.some(
      (cap) =>
        cap.creditTypeId && !validCreditTypesForTrial.has(cap.creditTypeId),
    )
  );
}

export const serializeDraftPlanToPlan = (
  draftPlan: DraftPlan,
  products: CreatePlanDataQuery["products"],
  creditType: CreatePlanDataQuery["CreditType"],
): PlanPreviewInfo => {
  const pricedProducts = (draftPlan.pricedProducts || [])
    .map((pp) => {
      const product = products.find((p) => p.id === pp.productId);
      return {
        id: pp.id,
        Product: {
          id: product?.id || "",
          name: product?.name || "",
          group_key: product?.group_key ?? null,
        },
        PricedProductPricingFactors: pp.pricingFactors.map((pf) => {
          const pricesWithMetricMinimums = [];
          const definedPrices = pf.prices || [
            {
              value: 0,
              metricMinimum: 0,
              block_size: null,
              block_rounding_behavior: null,
            },
          ];
          for (let i = 0; i < definedPrices.length; ++i) {
            const price = definedPrices[i];
            if (i === 0) {
              pricesWithMetricMinimums.push({
                value: (price.value || 0).toString(),
                metric_minimum: "0",
                block_size: pf.blockPricing?.size.toString() ?? null,
                block_rounding_behavior:
                  pf.blockPricing?.roundingBehavior ?? null,
              });
            } else if (i > 0) {
              pricesWithMetricMinimums.push({
                value: (price.value || 0).toString(),
                metric_minimum: price.metricMinimum?.toString() || "",
                block_size: pf.blockPricing?.size.toString() ?? null,
                block_rounding_behavior:
                  pf.blockPricing?.roundingBehavior ?? null,
              });
            }
          }
          const productPricingFactor = product?.ProductPricingFactors.find(
            (ppf) => ppf.id === pf.pricingFactorId,
          );

          return {
            start_period: (pf.startPeriod || 0).toString(),
            skip_ramp: !!pf.skipRamp,
            tiering_mode: pf.volumePricing
              ? TieringModeEnum_Enum.Volume
              : TieringModeEnum_Enum.Standard,
            tier_reset_frequency: pf.tierResetFrequency ?? 1,
            Prices:
              pf.chargeType === ChargeTypeEnum_Enum.Usage
                ? pricesWithMetricMinimums
                : [],
            SeatPrices: pf.seatPrices ?? null,
            FlatFees: pf.flatFees
              ? pf.flatFees.map((ff) => {
                  return {
                    metric_minimum: (ff.metricMinimum || 0).toString(),
                    value: (ff.value || 0).toString(),
                    quantity: (pf.flatFees?.[0]?.quantity ?? 1).toString(),
                    collection_schedule: collectionScheduleToEnum(
                      pf.flatFees?.[0]?.collectionSchedule ?? "ARREARS",
                    ),
                    collection_interval:
                      pf.flatFees?.[0]?.collectionInterval ?? 1,
                    is_prorated: pf.flatFees?.[0]?.isProrated ?? false,
                  };
                })
              : null,
            CompositeCharges: pf.compositeCharge
              ? [
                  {
                    quantity: new Decimal(
                      pf.compositeCharge?.[0]?.quantity ?? 0,
                    ).toNumber(),
                    CompositeChargeTiers: pf.compositeCharge.map((cc) => ({
                      value: (cc.value ?? 0).toString(),
                      composite_minimum: (cc.compositeMinimum ?? 0).toString(),
                    })),
                    CompositeChargePricingFactors:
                      pf.compositeCharge?.[0]?.pricingFactors?.map(
                        (pricingFactor) => ({
                          ProductPricingFactor: pricingFactor,
                        }),
                      ) ?? [],
                    type:
                      pf.compositeCharge?.[0]?.type ??
                      CompositeChargeTypeEnum_Enum.Percentage,
                  },
                ]
              : null,
            ProductPricingFactor: productPricingFactor
              ? {
                  id: productPricingFactor.id,
                  name: productPricingFactor.name,
                  charge_type_enum: getChargeType(pf),
                  BillableMetric: productPricingFactor.BillableMetric
                    ? { name: productPricingFactor.BillableMetric.name }
                    : null,
                }
              : {
                  id: "",
                  name: "",
                  charge_type_enum: getChargeType(pf),
                  BillableMetric: null,
                },
          };
        }),
        CreditType: creditType.find((ct) => ct.id === pp.creditType?.id) || {
          id: "",
          name: "",
          client_id: null,
          environment_type: null,
        },
      };
    })
    .sort((a, b) => {
      const selectedProductIds = draftPlan.selectedProductIds ?? [];
      return (
        selectedProductIds.indexOf(a.Product.id) -
        selectedProductIds.indexOf(b.Product.id)
      );
    });

  const minimums = (draftPlan.minimums || []).map((min) => {
    return {
      start_period: min.startPeriod.toString(),
      value: min.value || "0",
      CreditType: creditType.find((ct) => ct.id === min.creditType?.id) || {
        id: "",
        name: "",
        client_id: null,
        environment_type: null,
      },
    };
  });

  const creditTypeConversions = (draftPlan.creditTypeConversions || [])
    .map((conv) => {
      return {
        CustomCreditType: conv.customCreditType as CustomCreditType,
        FiatCreditType: conv.fiatCreditType as FiatCreditType,
        start_period: conv.startPeriod.toString(),
        to_fiat_conversion_factor:
          conv.toFiatConversionFactor?.toString() || "0",
      };
    })
    // Sorting by start_period, largest first, because the PlanPreview uses a
    // find(...) on this array, picking the first one that it sees with a
    // startPeriod <= the current ramp.
    .sort((a, b) => Number(b.start_period) - Number(a.start_period));

  return {
    name: draftPlan.name ?? "",
    description: draftPlan.description || "",
    billing_frequency:
      draftPlan.billingFrequency ?? BillingFrequencyEnum_Enum.Monthly,
    billing_provider: draftPlan.billingProvider ?? null,
    service_period_start_type: billingDayOfPeriodToEnum(
      draftPlan.billingDayOfPeriod ?? "FIRST_OF_MONTH",
    ),
    default_length_months: draftPlan.defaultLength ?? null,
    TrialSpec: draftPlan.hasTrial
      ? {
          id: "",
          length_in_days: String(draftPlan.trialSpec?.length ?? ""),
          TrialSpecSpendingCaps:
            draftPlan.trialSpec?.caps?.map((c) => ({
              id: "",
              CreditType: creditType.find((ct) => ct.id === c.creditTypeId) ?? {
                id: "",
                name: "",
                client_id: null,
                environment_type: null,
              },
              amount: c.amount?.toString() ?? "",
            })) ?? [],
        }
      : null,
    PricedProducts: pricedProducts,
    Minimums: minimums,
    CreditTypeConversions: creditTypeConversions,
    RecurringCreditGrants: draftPlan.recurringGrant
      ? [
          {
            id: "",
            amount_granted: draftPlan.recurringGrant.amountGranted ?? "0",
            AmountGrantedCreditType: draftPlan.recurringGrant
              .amountGrantedCreditType ?? {
              id: "",
              name: "",
              client_id: null,
              environment_type: null,
            },
            amount_paid: draftPlan.recurringGrant.amountPaid ?? "0",
            AmountPaidCreditType: draftPlan.recurringGrant
              .amountPaidCreditType ?? {
              id: "",
              name: "",
              client_id: null,
              environment_type: null,
            },
            effective_duration: draftPlan.recurringGrant.effectiveDuration ?? 1,
            name: draftPlan.recurringGrant.name ?? "",
            priority: draftPlan.recurringGrant.priority ?? "1",
            reason: draftPlan.recurringGrant.reason ?? null,
            recurrence_interval:
              draftPlan.recurringGrant.recurrence?.interval ?? null,
            recurrence_duration:
              draftPlan.recurringGrant.recurrence?.duration ?? null,
            send_invoice: draftPlan.recurringGrant.sendInvoice ?? false,
            product_ids: draftPlan.recurringGrant.productIds ?? null,
          },
        ]
      : [],
    seat_billing_frequency: draftPlan.seatBillingFrequency ?? null,
  };
};

interface Props {
  data: CreatePlanDataQuery;
  previousPlan?: PlanDetails;
  onPreviewPlan: () => void;
}
export const PreviewPlanPage: React.FC<Props> = (props) => {
  const { draftPlan, setDraftPlan } = useDraftPlan();
  const { goToStep } = useWizardContext();
  // Trigger the onPreviewPlan callback on the first new render of this component.
  useEffect(props.onPreviewPlan, [draftPlan.startingOn]);
  const previewPlan: PlanPreviewInfo = serializeDraftPlanToPlan(
    draftPlan,
    props.data.products,
    props.data.CreditType,
  );
  return (
    <WizardFullPage
      pageHeader={
        props.previousPlan
          ? {
              title: "Review your edits",
              subtitle:
                "Your edits will be highlighted. Hover over your new edits to preview what they were previously. If anything needs to be changed, use the navigation on the left to return to any step.",
            }
          : undefined
      }
    >
      {hasInvalidTrialCreditType(draftPlan) && (
        <Popup
          isOpen={true}
          title="Pricing unit mismatch"
          actions={[
            <Button
              onClick={() => goToStep(BILLING_SCHEDULE_STEP_NAVIGATION_KEY)}
              text="View trial terms"
              theme="primary"
            />,
          ]}
          onRequestClose={() => goToStep(BILLING_SCHEDULE_STEP_NAVIGATION_KEY)}
        >
          <Body level={2}>
            The pricing unit you applied to your trial is not being used on any
            product. Edit your trial to use a pricing unit that is found on your
            products.
          </Body>
        </Popup>
      )}
      {props.previousPlan ? (
        <div className="flex items-center">
          <Toggle
            checked={draftPlan.startingOn !== undefined}
            label="Plan edits should take effect on: "
            onChange={(v) =>
              setDraftPlan({ ...draftPlan, startingOn: v ? null : undefined })
            }
          />
          <DateInput
            onChange={(v) => setDraftPlan({ ...draftPlan, startingOn: v })}
            value={draftPlan.startingOn ?? undefined}
            isUTC={true}
            className="ml-4"
            disabled={draftPlan.startingOn === undefined}
          />
          <HelpCircleTooltip content="You can schedule plan edits to have them take effect for service periods starting on or after the specified date" />
        </div>
      ) : null}
      <PlanPreview
        plan={previewPlan}
        collapsible={false}
        previousPlan={props.previousPlan}
      />
    </WizardFullPage>
  );
};

export const previewPlanSection: (
  draftPlan: DraftPlan,
  hasPreviewedPlan: boolean,
  onPreviewPlan: () => void,
  data: CreatePlanDataQuery,
) => WizardSection = (draftPlan, hasPreviewedPlan, onPreviewPlan, data) => ({
  title: "Preview your new plan",
  icon: "receipt",
  isDone: isPreviewPlanDone(draftPlan, hasPreviewedPlan),
  subStepGroups: [
    {
      isDone: isPreviewPlanDone(draftPlan, hasPreviewedPlan),
      subSteps: [
        {
          header: "Preview plan",
          title: "Preview plan",
          component: (
            <PreviewPlanPage data={data} onPreviewPlan={onPreviewPlan} />
          ),
          isDone: isPreviewPlanDone(draftPlan, hasPreviewedPlan),
        },
      ],
    },
  ],
});

export const previewPlanEditsSection: (
  draftPlan: DraftPlan,
  hasPreviewedPlan: boolean,
  onPreviewPlan: () => void,
  data: CreatePlanDataQuery,
  previousPlan: PlanDetails,
  reviewCustomerImpact: boolean,
  hasCustomPricing: boolean,
) => WizardSection = (
  draftPlan,
  hasPreviewedPlan,
  onPreviewPlan,
  data,
  previousPlan,
  reviewCustomerImpact,
  hasCustomPricing,
) => ({
  title: "Preview edits",
  icon: "receipt",
  isDone: isPreviewPlanDone(draftPlan, hasPreviewedPlan),
  subStepGroups: [
    {
      isDone: isPreviewPlanDone(draftPlan, hasPreviewedPlan),
      subSteps: [
        {
          header: "Review your edits",
          title: "Review plan",
          component: (
            <PreviewPlanPage
              data={data}
              onPreviewPlan={onPreviewPlan}
              previousPlan={previousPlan}
            />
          ),
          isDone: isPreviewPlanDone(draftPlan, hasPreviewedPlan),
        },
        ...(hasCustomPricing
          ? [
              {
                header: "Review price adjustments",
                title: "Review price adjustments",
                component: (
                  <PreviewCustomPricing
                    data={data}
                    previousPlan={previousPlan}
                  />
                ),
                isDone: isPreviewPlanDone(draftPlan, hasPreviewedPlan),
              },
            ]
          : []),
        ...(reviewCustomerImpact
          ? [
              {
                header: "Review your edits",
                title: "Review customer impact",
                component: <CustomerImpact previousPlan={previousPlan} />,
                isDone: isPreviewPlanDone(draftPlan, hasPreviewedPlan),
              },
            ]
          : []),
      ],
    },
  ],
});
