/* 3e750ae0b0df96d14579d859370ee3da25b90e87
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegenerateInvoiceMutationVariables = Types.Exact<{
  invoice_id: Types.Scalars['uuid'];
}>;


export type RegenerateInvoiceMutation = { __typename?: 'Mutation', regenerate_invoice: { __typename?: 'AdHocPlanInvoice', id: string } | { __typename?: 'AdhocContractUsageInvoice', id: string } | { __typename?: 'AdvanceInvoice', id: string } | { __typename?: 'ArrearsInvoice', id: string } | { __typename?: 'ContractPostpaidTrueupInvoice', id: string } | { __typename?: 'ContractProServiceInvoice', id: string } | { __typename?: 'ContractRefundInvoice', id: string } | { __typename?: 'ContractScheduledInvoice', id: string } | { __typename?: 'ContractUsageInvoice', id: string } | { __typename?: 'CorrectionInvoice', id: string } | { __typename?: 'CreditPurchaseInvoice', id: string } | { __typename?: 'ParentInvoice', id: string } | { __typename?: 'SeatPurchaseInvoice', id: string } };


export const RegenerateInvoiceDocument = gql`
    mutation RegenerateInvoice($invoice_id: uuid!) {
  regenerate_invoice(id: $invoice_id) {
    id
  }
}
    `;
export type RegenerateInvoiceMutationFn = Apollo.MutationFunction<RegenerateInvoiceMutation, RegenerateInvoiceMutationVariables>;

/**
 * __useRegenerateInvoiceMutation__
 *
 * To run a mutation, you first call `useRegenerateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateInvoiceMutation, { data, loading, error }] = useRegenerateInvoiceMutation({
 *   variables: {
 *      invoice_id: // value for 'invoice_id'
 *   },
 * });
 */
export function useRegenerateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateInvoiceMutation, RegenerateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegenerateInvoiceMutation, RegenerateInvoiceMutationVariables>(RegenerateInvoiceDocument, options);
      }
export type RegenerateInvoiceMutationHookResult = ReturnType<typeof useRegenerateInvoiceMutation>;
export type RegenerateInvoiceMutationResult = Apollo.MutationResult<RegenerateInvoiceMutation>;
export type RegenerateInvoiceMutationOptions = Apollo.BaseMutationOptions<RegenerateInvoiceMutation, RegenerateInvoiceMutationVariables>;