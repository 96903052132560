/* 4db0220b1d21ca63ba7b380dee06127b9f50de92
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IssueCreditGrantInfoQueryVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
}>;


export type IssueCreditGrantInfoQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', id: string, name: string }>, customer: { __typename?: 'Customer', id: string, end_date_of_last_finalized_invoice: string | null } | null };

export type IssueCreditGrantMutationVariables = Types.Exact<{
  credit_grant: Types.CreditGrantInput;
}>;


export type IssueCreditGrantMutation = { __typename?: 'Mutation', create_credit_grant: { __typename?: 'CreditGrant', id: string } };


export const IssueCreditGrantInfoDocument = gql`
    query IssueCreditGrantInfo($customer_id: uuid!) {
  products(types: [ACTIVE]) {
    id
    name
    __environment_type: environment_type
  }
  customer: Customer_by_pk(id: $customer_id) {
    id
    end_date_of_last_finalized_invoice
    __environment_type: environment_type
  }
}
    `;

/**
 * __useIssueCreditGrantInfoQuery__
 *
 * To run a query within a React component, call `useIssueCreditGrantInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssueCreditGrantInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssueCreditGrantInfoQuery({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useIssueCreditGrantInfoQuery(baseOptions: Apollo.QueryHookOptions<IssueCreditGrantInfoQuery, IssueCreditGrantInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IssueCreditGrantInfoQuery, IssueCreditGrantInfoQueryVariables>(IssueCreditGrantInfoDocument, options);
      }
export function useIssueCreditGrantInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IssueCreditGrantInfoQuery, IssueCreditGrantInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IssueCreditGrantInfoQuery, IssueCreditGrantInfoQueryVariables>(IssueCreditGrantInfoDocument, options);
        }
export type IssueCreditGrantInfoQueryHookResult = ReturnType<typeof useIssueCreditGrantInfoQuery>;
export type IssueCreditGrantInfoLazyQueryHookResult = ReturnType<typeof useIssueCreditGrantInfoLazyQuery>;
export type IssueCreditGrantInfoQueryResult = Apollo.QueryResult<IssueCreditGrantInfoQuery, IssueCreditGrantInfoQueryVariables>;
export const IssueCreditGrantDocument = gql`
    mutation IssueCreditGrant($credit_grant: CreditGrantInput!) {
  create_credit_grant(credit_grant: $credit_grant) {
    id
    __environment_type: environment_type
  }
}
    `;
export type IssueCreditGrantMutationFn = Apollo.MutationFunction<IssueCreditGrantMutation, IssueCreditGrantMutationVariables>;

/**
 * __useIssueCreditGrantMutation__
 *
 * To run a mutation, you first call `useIssueCreditGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueCreditGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueCreditGrantMutation, { data, loading, error }] = useIssueCreditGrantMutation({
 *   variables: {
 *      credit_grant: // value for 'credit_grant'
 *   },
 * });
 */
export function useIssueCreditGrantMutation(baseOptions?: Apollo.MutationHookOptions<IssueCreditGrantMutation, IssueCreditGrantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueCreditGrantMutation, IssueCreditGrantMutationVariables>(IssueCreditGrantDocument, options);
      }
export type IssueCreditGrantMutationHookResult = ReturnType<typeof useIssueCreditGrantMutation>;
export type IssueCreditGrantMutationResult = Apollo.MutationResult<IssueCreditGrantMutation>;
export type IssueCreditGrantMutationOptions = Apollo.BaseMutationOptions<IssueCreditGrantMutation, IssueCreditGrantMutationVariables>;