import React from "react";

import { useNavigate } from "lib/useNavigate";
import { useOptionalParam } from "lib/routes/params";
import { useSearchParam } from "lib/routes/useSearchParam";
import { useRequiredParam } from "lib/routes/params";

import { Commit } from "pages/Contracts/lib/Commit";
import { CustomerContractLayout } from "../CustomerContractLayout";
import { CommitCard, CommitTab, parseCommitTab } from "./CommitCard";
import { EmptyState } from "components/EmptyState";
import { useAsyncCommitUsage } from "../Usage";
import { useApolloResp } from "pages/Contracts/lib/ApolloResp";
import {
  useContractCommitsQuery,
  ContractCommitDetailsFragment,
} from "./data.graphql";

function getAllCommits(contract: ContractCommitDetailsFragment) {
  const commits = [
    ...contract.commits_union,
    ...contract.amendments.flatMap((a) => a.commits_union),
  ];

  const rollover = commits.filter((c) => Commit.isRollover(c));
  const unexpired = commits.filter(
    (c) => !Commit.isRollover(c) && !Commit.isExpired(c),
  );
  const expired = commits
    .filter((c) => !Commit.isRollover(c) && Commit.isExpired(c))
    .sort((a, b) => {
      // Expired commits at the end, sorted by most recently expired
      const aEnd = Commit.getDateRange(a)?.end?.valueOf() ?? -Infinity;
      const bEnd = Commit.getDateRange(b)?.end?.valueOf() ?? -Infinity;
      return bEnd - aEnd;
    });

  return [...rollover, ...unexpired, ...expired];
}

export const CustomerContractCommits: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const contractId = useRequiredParam("contractId");
  const commitId = useOptionalParam("commitId");

  const navigate = useNavigate();
  const activeTabs = React.useRef<Record<string, CommitTab>>({});
  const activeTabParam = parseCommitTab(useSearchParam("tab")[0]);
  if (commitId && activeTabParam) {
    activeTabs.current[commitId] = activeTabParam;
  }

  const req = useApolloResp(
    useContractCommitsQuery({
      variables: { customerId, contractId },
    }),
  );
  const asyncUsage = useAsyncCommitUsage({ contractId, customerId });

  // validate and clear the focus param if it's invalid
  React.useEffect(() => {
    if (
      commitId &&
      req.state === "success" &&
      req.customer.contract &&
      !getAllCommits(req.customer.contract).some((c) => c.id === commitId)
    ) {
      navigate(
        `/customers/${customerId}/contracts/${contractId}/commits-and-credits`,
        {
          replace: true,
        },
      );
    }
  }, [commitId, req]);

  return (
    <CustomerContractLayout
      rootReq={req}
      disableContainerScroll={({ contract }) =>
        getAllCommits(contract).length === 0
      }
      content={({ contract }) => {
        const allCommits = getAllCommits(contract);

        return !allCommits?.length ? (
          <EmptyState
            title="No commits"
            subtitle="This contract has not been issued a commit."
            icon="bankNote03"
          />
        ) : (
          allCommits.map((commit) => (
            <CommitCard
              key={commit.id}
              commit={commit}
              focus={commit.id === commitId}
              activeTab={activeTabs.current[commit.id] || "ledger"}
              asyncUsage={asyncUsage}
              onTabChange={(tab) => {
                navigate(
                  `/customers/${customerId}/contracts/${contractId}/commits-and-credits/${commit.id}?tab=${tab}`,
                  { replace: true },
                );
              }}
            />
          ))
        );
      }}
    />
  );
};
