import React, { useState } from "react";

import { Input } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";

import { Body } from "design-system";
import { useSnackbar } from "components/Snackbar";
import { Select } from "design-system";

import {
  useUpdateStripeBillingProviderCustomerMutation,
  useGetStripeCustomerQuery,
  useIsDeltaStreamEnabledQuery,
  useInsertCustomerBillingProviderConfigurationMutation,
} from "./queries.graphql";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";
import { ApolloCache } from "@apollo/client";

interface StripeSettingsModalProps {
  onClose: (deleteConfig?: boolean) => void;
  customerId: string;
  edit: boolean;
  initialStripeId?: string;
  initialStripeCollectionMethod?: string;
}

const evictCacheOptions = {
  update(cache: ApolloCache<any>) {
    cache.evict({
      fieldName: "BillingProviderCustomer",
    });
    cache.evict({
      fieldName: "CustomerConfig",
    });
    cache.evict({
      fieldName: "Customer_aggregate",
    });
  },
};

export const StripeSettingsModal: React.FC<StripeSettingsModalProps> = ({
  onClose,
  customerId,
  edit,
  initialStripeId = "",
  initialStripeCollectionMethod = "charge_automatically",
}) => {
  const { environmentType } = useEnvironment();
  const pushMessage = useSnackbar();
  const [stripeCustomerId, setStripeCustomerId] = useState<string | null>(
    edit ? initialStripeId : null,
  );
  const [stripeCollectionMethod, setStripeCollectionMethod] = useState<
    string | null
  >(edit ? initialStripeCollectionMethod : null);
  const [updateSettings] =
    useUpdateStripeBillingProviderCustomerMutation(evictCacheOptions);
  const [insertCustomerBillingProviderConfiguration] =
    useInsertCustomerBillingProviderConfigurationMutation(evictCacheOptions);
  const { data, loading } = useGetStripeCustomerQuery({
    skip: stripeCustomerId === null,
    variables: {
      environment_type: environmentType,
      stripe_customer_id: stripeCustomerId ?? "",
    },
  });

  const isDeltaStreamEnabled =
    useIsDeltaStreamEnabledQuery().data?.is_delta_stream_enabled;

  const updateSettingsAction = async () => {
    if (stripeCustomerId && stripeCollectionMethod) {
      if (isDeltaStreamEnabled) {
        await insertCustomerBillingProviderConfiguration({
          variables: {
            customer_id: customerId,
            configuration: {
              stripe_customer_id: stripeCustomerId,
              stripe_collection_method: stripeCollectionMethod,
            },
          },
        });
      } else {
        await updateSettings({
          variables: {
            billing_provider_customer_id: stripeCustomerId,
            stripe_collection_method: stripeCollectionMethod,
            customer_id: customerId,
          },
        });
      }
      pushMessage({
        content: "Stripe configuration saved",
        type: "success",
      });
      onClose();
    }
  };
  const actionButtons = (
    <>
      {edit && (
        <Button
          key="delete"
          onClick={() => onClose(true)}
          text="Disable Stripe"
          theme="linkGray"
        />
      )}
      <Button
        key="primary"
        disabled={!stripeCustomerId || !stripeCollectionMethod}
        onClick={updateSettingsAction}
        text={edit ? "Save changes" : "Add Stripe"}
        theme="primary"
      />
    </>
  );

  return (
    <Popup
      actions={actionButtons}
      isOpen={true}
      onRequestClose={() => onClose(false)}
      title="Stripe configuration"
    >
      <Body level={2}>
        {edit
          ? "Configuration details"
          : `To add the Stripe profile you need the customer’s associated Stripe customer ID. Once entered we’ll display the customer name.${
              environmentType === EnvironmentTypeEnum_Enum.Sandbox
                ? " This is a sandbox customer so it can only be linked to a Stripe test mode account."
                : ""
            }`}
      </Body>
      <Input
        name="Stripe ID"
        placeholder="Enter Stripe customer ID"
        value={stripeCustomerId ?? ""}
        onChange={setStripeCustomerId}
        autoFocus
        success={data?.stripeCustomer?.name != null}
        error={
          stripeCustomerId != null &&
          !loading &&
          data?.stripeCustomer?.name == null
            ? "Enter a valid Stripe ID"
            : undefined
        }
      />
      <Body level={2}>{data?.stripeCustomer?.name ?? ""}</Body>
      <Select
        name="Collection Method"
        options={[
          { label: "Charge automatically", value: "charge_automatically" },
          { label: "Send invoice", value: "send_invoice" },
        ]}
        onChange={setStripeCollectionMethod}
        value={stripeCollectionMethod ?? ""}
        placeholder="Select"
      />
    </Popup>
  );
};

export default StripeSettingsModal;
