/* 8d82d724daaef32e250e44ad511270dac7ae4415
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddOneTimeChargeMutationVariables = Types.Exact<{
  customer_plan_id: Types.Scalars['uuid'];
  date: Types.Scalars['timestamptz'];
  product_pricing_factor_id: Types.Scalars['uuid'];
  price: Types.Scalars['numeric'];
  quantity: Types.Scalars['numeric'];
  description: Types.Scalars['String'];
}>;


export type AddOneTimeChargeMutation = { __typename?: 'Mutation', create_one_time_charge: { __typename?: 'OneTimeProductCharge', id: string } };

export type ProductsQueryVariables = Types.Exact<{
  environmentType: Types.EnvironmentTypeEnum_Enum;
}>;


export type ProductsQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', id: string, name: string, description: string, deprecated_at: string | null }> };

export type ProductPricingFactorsQueryVariables = Types.Exact<{
  productId: Types.Scalars['uuid'];
}>;


export type ProductPricingFactorsQuery = { __typename?: 'Query', Product_by_pk: { __typename?: 'Product', id: string, ProductPricingFactors: Array<{ __typename?: 'ProductPricingFactor', id: string, name: string, ordering: number | null }> } | null };


export const AddOneTimeChargeDocument = gql`
    mutation AddOneTimeCharge($customer_plan_id: uuid!, $date: timestamptz!, $product_pricing_factor_id: uuid!, $price: numeric!, $quantity: numeric!, $description: String!) {
  create_one_time_charge(
    customer_plan_id: $customer_plan_id
    service_period_start_date: $date
    product_pricing_factor_id: $product_pricing_factor_id
    price: $price
    quantity: $quantity
    description: $description
  ) {
    id
  }
}
    `;
export type AddOneTimeChargeMutationFn = Apollo.MutationFunction<AddOneTimeChargeMutation, AddOneTimeChargeMutationVariables>;

/**
 * __useAddOneTimeChargeMutation__
 *
 * To run a mutation, you first call `useAddOneTimeChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOneTimeChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOneTimeChargeMutation, { data, loading, error }] = useAddOneTimeChargeMutation({
 *   variables: {
 *      customer_plan_id: // value for 'customer_plan_id'
 *      date: // value for 'date'
 *      product_pricing_factor_id: // value for 'product_pricing_factor_id'
 *      price: // value for 'price'
 *      quantity: // value for 'quantity'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddOneTimeChargeMutation(baseOptions?: Apollo.MutationHookOptions<AddOneTimeChargeMutation, AddOneTimeChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOneTimeChargeMutation, AddOneTimeChargeMutationVariables>(AddOneTimeChargeDocument, options);
      }
export type AddOneTimeChargeMutationHookResult = ReturnType<typeof useAddOneTimeChargeMutation>;
export type AddOneTimeChargeMutationResult = Apollo.MutationResult<AddOneTimeChargeMutation>;
export type AddOneTimeChargeMutationOptions = Apollo.BaseMutationOptions<AddOneTimeChargeMutation, AddOneTimeChargeMutationVariables>;
export const ProductsDocument = gql`
    query Products($environmentType: EnvironmentTypeEnum_enum!) {
  products(
    environment_type: $environmentType
    types: [ACTIVE]
    charge_types: [FLAT]
  ) {
    id
    name
    description
    deprecated_at
    __environment_type: environment_type
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      environmentType: // value for 'environmentType'
 *   },
 * });
 */
export function useProductsQuery(baseOptions: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ProductPricingFactorsDocument = gql`
    query ProductPricingFactors($productId: uuid!) {
  Product_by_pk(id: $productId) {
    id
    ProductPricingFactors {
      id
      name
      ordering
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useProductPricingFactorsQuery__
 *
 * To run a query within a React component, call `useProductPricingFactorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductPricingFactorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductPricingFactorsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useProductPricingFactorsQuery(baseOptions: Apollo.QueryHookOptions<ProductPricingFactorsQuery, ProductPricingFactorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductPricingFactorsQuery, ProductPricingFactorsQueryVariables>(ProductPricingFactorsDocument, options);
      }
export function useProductPricingFactorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductPricingFactorsQuery, ProductPricingFactorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductPricingFactorsQuery, ProductPricingFactorsQueryVariables>(ProductPricingFactorsDocument, options);
        }
export type ProductPricingFactorsQueryHookResult = ReturnType<typeof useProductPricingFactorsQuery>;
export type ProductPricingFactorsLazyQueryHookResult = ReturnType<typeof useProductPricingFactorsLazyQuery>;
export type ProductPricingFactorsQueryResult = Apollo.QueryResult<ProductPricingFactorsQuery, ProductPricingFactorsQueryVariables>;