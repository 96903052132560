import React from "react";
import { Badge } from "design-system";
import { useNow, printDate, toDayjs } from "lib/date";
import { ContractOrPlan } from "../../lib/ContractOrPlan";
import { Customer } from "../../lib/Customer";
import { CustomerStatusFragment } from "pages/Contracts/lib/ContractOrPlan/ContractOrPlan";

type PorcByStatus = {
  "active-soon": CustomerStatusFragment[];
  active: CustomerStatusFragment[];
  "recently-ended": CustomerStatusFragment[];
};

interface Props {
  customer: Customer.StatusFragment;
}

const type = (porc: CustomerStatusFragment) =>
  porc.__typename === "CustomerContractStatus" ? "CONTRACT" : "PLAN";

const BadgeList: React.FC<React.PropsWithChildren> = ({ children }) => (
  <span className="space-x-8">{children}</span>
);

function renderDate(date: string | null): string {
  return date ? printDate(toDayjs(date)) : "";
}

/**
 * Renders a badge for each active contract and plan. If there are no active
 * contracts or plans, but one will start in the next 30 days then describe
 * that, otherwise describe the plans or contracts which ended in the last
 * 30 days. Finally, if none of these conditions match it will render a badge
 * that says "No active plan or contract".
 */
export const CustomerStatus: React.FC<Props> = ({ customer }) => {
  const now = useNow();

  const porcByStatus = React.useMemo((): PorcByStatus => {
    return Customer.getStatuses(customer, now);
  }, [customer, now]);

  if (porcByStatus.active.length) {
    const activePlans = porcByStatus.active.filter(
      (p) => p.__typename === "CustomerPlanStatus",
    );
    const activeContracts = porcByStatus.active.filter(
      (p) => p.__typename === "CustomerContractStatus",
    );
    return (
      <BadgeList>
        {activeContracts.length > 0 ? (
          <Badge
            theme={
              ContractOrPlan.getCustomerStatus(activeContracts[0], now) ===
              "ending-soon"
                ? "warning"
                : "primary"
            }
            type="light"
            className="uppercase"
          >
            {"CONTRACT "}
            {!activeContracts[0].ending_before || porcByStatus.active.length > 1
              ? "ACTIVE"
              : ` EXPIRY: ${renderDate(activeContracts[0].ending_before)}`}
          </Badge>
        ) : undefined}

        {activePlans.length > 0 ? (
          <Badge
            theme={
              ContractOrPlan.getCustomerStatus(activePlans[0], now) ===
              "ending-soon"
                ? "warning"
                : "primary"
            }
            type="light"
            className="uppercase"
          >
            {"PLAN "}
            {!activePlans[0].ending_before || porcByStatus.active.length > 1
              ? "ACTIVE"
              : ` EXPIRY: ${renderDate(activePlans[0].ending_before)}`}
          </Badge>
        ) : undefined}
      </BadgeList>
    );
  }

  if (porcByStatus["active-soon"].length) {
    return (
      <BadgeList>
        {porcByStatus["active-soon"].map((porc, idx) => (
          <Badge key={idx} theme="grey" type="light" className="uppercase">
            {type(porc)} STARTING: {renderDate(porc.starting_at)}
          </Badge>
        ))}
      </BadgeList>
    );
  }

  if (porcByStatus["recently-ended"].length) {
    return (
      <BadgeList>
        {porcByStatus["recently-ended"].map((porc, idx) => (
          <Badge key={idx} theme="grey" type="light" className="uppercase">
            {type(porc)} ENDED: {renderDate(porc.ending_before)}
          </Badge>
        ))}
      </BadgeList>
    );
  }

  return (
    <BadgeList>
      <Badge theme="grey" type="light">
        NO ACTIVE PLAN OR CONTRACT
      </Badge>
    </BadgeList>
  );
};
