import * as React from "react";
import { dayjs } from "lib/dayjs";
import { BilledRevenueQuery, BilledRevenueDocument } from "./queries.graphql";
import { CreditType } from "types/credit-types";
import Decimal from "decimal.js";
import { BillingProviderEnum_Enum } from "types/generated-graphql/__types__";
import { displayCreditTypeName, valueStringFromCredits } from "lib/credits";
import { Body } from "design-system";
import { ReportConfig } from "../reportConfig";

const report: ReportConfig<BilledRevenueQuery> = {
  pageSize: 50,
  name: "Billed Revenue Summary",
  additionalDescription: (
    <Body>
      Note: any IDs provided are the current IDs for the customer i.e. "Billing
      Provider Customer ID" and "Salesforce ID".
    </Body>
  ),
  needsDates: true,
  needsEnvironment: false,
  dataToCSV: (pages, inclusiveStartDate: Date, exclusiveEndDate: Date) => {
    const BILLED_REVENUE_CSV_EXTERNAL_DATA_HEADERS: Set<
      "Billing Provider Type" | "Billing Provider Customer ID" | "Salesforce ID"
    > = new Set();
    const creditTypeIDMap: { [key: string]: CreditType } = {};

    const customers = pages.flatMap((page) =>
      page.Customer.map((customer) => {
        const creditTypeIDToTotal: { [key: string]: Decimal } = {};
        const externalDataMap = {
          "Billing Provider Type": "",
          "Billing Provider Customer ID": "",
          "Salesforce ID": "",
        };

        customer.billing_providers.forEach((bp) => {
          const {
            billing_provider: billingProvider,
            billing_provider_customer_id: billingProviderCustomerId,
          } = bp;
          if (billingProvider !== BillingProviderEnum_Enum.Manual) {
            externalDataMap["Billing Provider Type"] = billingProvider;
            externalDataMap["Billing Provider Customer ID"] =
              billingProviderCustomerId;
            BILLED_REVENUE_CSV_EXTERNAL_DATA_HEADERS.add(
              "Billing Provider Type",
            );
            BILLED_REVENUE_CSV_EXTERNAL_DATA_HEADERS.add(
              "Billing Provider Customer ID",
            );
          }
        });

        if (customer.salesforce_account_id.length) {
          if (customer.salesforce_account_id.length > 1) {
            throw new Error(
              `Customer ${customer.id} has more than one 'salesforce_account_id' customer config value`,
            );
          }
          externalDataMap["Salesforce ID"] =
            customer.salesforce_account_id[0].value;
          BILLED_REVENUE_CSV_EXTERNAL_DATA_HEADERS.add("Salesforce ID");
        }

        if (customer.invoices.cursor) {
          throw new Error(
            `Customer ${customer.id} has more than one page of invoices`,
          );
        }

        customer.invoices.invoices.forEach((invoice) => {
          /* 
          Invoice service period end dates are in beginning of day format e.g. 2022-02-01T00:00:00.000Z
          - We also need to exclude snapshot start date since this will include the prior period
        */
          if (
            (invoice.__typename === "ArrearsInvoice" &&
              !dayjs(invoice.exclusive_end_date).isBetween(
                inclusiveStartDate,
                exclusiveEndDate,
                null,
                "(]",
              )) ||
            (invoice.__typename !== "ArrearsInvoice" &&
              !dayjs(invoice.issued_at).isBetween(
                inclusiveStartDate,
                exclusiveEndDate,
                null,
                "(]",
              ))
          ) {
            return;
          }
          if (invoice.credit_type.client_id) {
            // Non currency credit type, not expected.
            return;
          }
          if (!creditTypeIDToTotal[invoice.credit_type.id]) {
            creditTypeIDToTotal[invoice.credit_type.id] = new Decimal(0);
          }
          creditTypeIDToTotal[invoice.credit_type.id] = creditTypeIDToTotal[
            invoice.credit_type.id
          ].plus(invoice.total);
          creditTypeIDMap[invoice.credit_type.id] = invoice.credit_type;
        });

        return {
          id: customer.id,
          ...externalDataMap,
          name: customer.name,
          totals: creditTypeIDToTotal,
        };
      }),
    );

    const orderedCreditTypes = Object.keys(creditTypeIDMap);
    const externalDataHeaders = Array.from(
      BILLED_REVENUE_CSV_EXTERNAL_DATA_HEADERS,
    );

    const headers = [
      "Customer",
      "Customer ID",
      ...externalDataHeaders,
      ...orderedCreditTypes.map((id) =>
        displayCreditTypeName(creditTypeIDMap[id]),
      ),
    ];

    const rows = customers.map((customer) => [
      customer.name,
      customer.id,
      ...externalDataHeaders.map((header) => customer[header] ?? ""),
      ...orderedCreditTypes.map((id) =>
        valueStringFromCredits(customer.totals[id]?.toNumber() ?? 0, id),
      ),
    ]);

    return [headers, ...rows];
  },
  queryDocument: BilledRevenueDocument,
  isAllowedForUser: (ldClient) =>
    ldClient
      ?.variation("available-report-types", [])
      .includes("BILLED_REVENUE"),
  nextCursor: (page) => page.Customer.at(-1)?.id ?? null,
};

export default report;
