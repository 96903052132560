import React from "react";
import { Popover } from "react-tiny-popover";
import { twMerge } from "../../twMerge";
import { Icon, type IconName } from "../Icon";
import { Subtitle } from "../Typography";
import { Checkbox } from "../Control";
import { Button } from "../../../tenaissance/components/Button";

export type DropdownItem =
  | {
      type: "button";
      label: string;
      active: boolean;
      onClick: () => void;
    }
  | {
      type: "checkbox";
      label: string;
      checked: boolean;
      onClick: () => void;
    };

export interface DropdownGroup {
  label: string;
  items: DropdownItem[];
}

export interface DropdownProps {
  menu: DropdownGroup[];
  title: string;
  /** Which side of the button should the dropdown align with? Defaults to "end" */
  align?: "start" | "end";
  position?: "top" | "bottom" | "left" | "right";
  icon?: IconName;
  action?: {
    label: string;
    onClick: () => void;
  };
  size?: "small" | "normal";
  className?: string;
}

const Item: React.FC<DropdownItem> = (item): React.ReactElement => {
  const onClickOrKeyDown = (e: React.MouseEvent | React.KeyboardEvent) => {
    if ("key" in e && !(e.key === "Enter" || e.key === " ")) {
      return;
    }

    e.preventDefault();
    item.onClick();
  };

  switch (item.type) {
    case "button":
      return (
        <div
          onClick={onClickOrKeyDown}
          onKeyDown={onClickOrKeyDown}
          className={twMerge(
            "px-12 py-8 text-sm font-medium leading-1 focus:outline-none",
            item.active
              ? "bg-primary-50"
              : "cursor-pointer hover:bg-gray-50 focus:bg-gray-50",
          )}
          tabIndex={0}
        >
          {item.label}
        </div>
      );
    case "checkbox":
      return (
        <div
          onClick={onClickOrKeyDown}
          onKeyDown={onClickOrKeyDown}
          className="flex cursor-pointer flex-row items-center px-12 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
          tabIndex={0}
        >
          <Checkbox
            checked={item.checked}
            label={
              <Subtitle className="cursor-pointer" level={2}>
                {item.label}
              </Subtitle>
            }
          />
        </div>
      );
  }
};

function getDomState(container: HTMLDivElement) {
  const els = Array.from(container.querySelectorAll('[tabindex="0"]')).flatMap(
    (el) => (el instanceof HTMLElement ? el : []),
  );
  const focusI = els.findIndex((el) => el === document.activeElement);
  return { els, focusI };
}

export const Dropdown: React.FC<DropdownProps> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const openedWithKeyboard = React.useRef(false);

  const keyboardNav = (e: React.KeyboardEvent) => {
    e.preventDefault();

    if (
      !isOpen &&
      (e.key === "Enter" || e.key === "ArrowDown" || e.key === " ")
    ) {
      openedWithKeyboard.current = true;
      setIsOpen(true);
      return;
    }

    if (!divRef.current) {
      return;
    }

    switch (e.key) {
      case "ArrowDown": {
        const { els, focusI } = getDomState(divRef.current);
        // get the value at `focusI + 1`, and wrap around to
        // the first item if we are at the end of the list
        els.at((focusI + 1) % els.length)?.focus();
        break;
      }
      case "ArrowUp": {
        const { els, focusI } = getDomState(divRef.current);
        // if there is no focused element, start at the bottom of the list
        // otherwise get the value at `focusI - 1`. .at() will wrap around
        // to the last item if we pass -1
        els.at(focusI === -1 ? -1 : focusI - 1)?.focus();
        break;
      }
      case "Escape":
        setIsOpen(false);
        divRef.current.focus();
        break;
    }
  };

  return (
    <Popover
      isOpen={isOpen}
      positions={[props.position ?? "bottom"]}
      align={props.align ?? "end"}
      onClickOutside={() => setIsOpen(false)}
      padding={4}
      content={
        <div
          tabIndex={0}
          ref={(el) => {
            divRef.current = el;
            if (openedWithKeyboard.current) {
              openedWithKeyboard.current = false;
              setTimeout(() => {
                if (
                  el &&
                  document.body.contains(el) &&
                  !el.contains(document.activeElement)
                ) {
                  el.focus();
                }
              }, 0);
            }
          }}
          className="min-h-[50px] w-[260px] rounded-medium border border-gray-light bg-white shadow-lg"
          onKeyDown={keyboardNav}
        >
          <div className="ml-12 flex flex-row items-center justify-between border-b border-gray-100 py-4 pr-12">
            <Subtitle level={2}>{props.title}</Subtitle>
            {props.action && (
              <Button
                theme="secondary"
                onClick={props.action.onClick}
                text={props.action.label}
              />
            )}
          </div>
          <div className="flex max-h-[90vh] flex-col gap-12 overflow-y-auto pb-4 pt-8">
            {props.menu.map((group, i) => (
              <div key={`group-${i}`}>
                <div className="mb-[0.25em] pl-12 text-[75%] font-medium uppercase text-gray-200">
                  {group.label}
                </div>
                {group.items.map((item, i) => (
                  <Item key={`item-${i}`} {...item} />
                ))}
              </div>
            ))}
          </div>
        </div>
      }
    >
      <div
        tabIndex={0}
        onKeyDown={keyboardNav}
        className={twMerge(
          "flex cursor-pointer flex-row items-center justify-center rounded-medium border border-gray-200 text-left",
          "focus:border-primary-500 focus:shadow-[0_0_0_3px] focus:shadow-primary-100 focus:outline-none",
          props.size === "small"
            ? "gap-2 w-[100px] px-8 py-4 text-xs"
            : "w-[140px] gap-4 p-8",
          isOpen
            ? "border-primary-500 shadow-[0_0_0_3px] shadow-primary-100"
            : "",
          props.className,
        )}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {props.icon ? (
          <Icon icon={props.icon} className="mr-8 text-gray-600" />
        ) : null}
        <span className="grow cursor-pointer select-none text-xs text-gray-600">
          {props.title}
        </span>
        <Icon icon="caretDown" className="text-gray-600" />
      </div>
    </Popover>
  );
};
