import { useNow } from "lib/date";
import { FormController } from "lib/FormController";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";
import React from "react";

import { Body, DateInput, Input, NumericInput, Select } from "design-system";

import { DefaultTimeframe } from "../../lib/DefaultTimeframe";
import { Schema } from "../../Schema";

import { AdditionalTermFlyover } from "./AdditionalTermFlyover";
import { useGetAllProductsQuery } from "./data.graphql";

interface Props {
  edit?: Schema.Types.ResellerRoyalty;
  resellerRoyalties: Schema.Types.ResellerRoyalty[] | undefined;
  onSave: (resellerRoyalty: Schema.Types.ResellerRoyalty) => void;
  onCancel: () => void;
  onDelete?: () => void;
}

const useResellerRoyaltyController = FormController.createHook(
  Schema.ResellerRoyalty,
  {
    init(props: Props, timeframe: DefaultTimeframe) {
      return props.edit
        ? props.edit
        : {
            type: "aws",
            startingAt: timeframe.startingAt,
            metadata: { type: "aws" },
          };
    },
  },
);
type ResellerRoyaltyController = ReturnType<
  typeof useResellerRoyaltyController
>;

export const ResellerRoyaltyFlyover: React.FC<Props> = (props) => {
  const ctrl = useResellerRoyaltyController(
    props,
    DefaultTimeframe.useFromContext(),
  );
  const resellerType = ctrl.get("type");

  const now = useNow();
  const productsReq = useGetAllProductsQuery();
  const allProducts = productsReq.data?.products_and_rate_cards.products ?? [];
  const productTags = [
    ...new Set(
      allProducts.flatMap((product) => ProductListItem.getTags(product, now)),
    ),
  ].sort();

  const resellerTypesOfExistingRoyalties = (props.resellerRoyalties ?? []).map(
    (rr) => rr.type,
  );
  const resellerTypeOptions = [
    { label: "AWS", value: "aws" as const },
    { label: "GCP", value: "gcp" as const },
    { label: "AWS professional service", value: "awsProService" as const },
    { label: "GCP professional service", value: "gcpProService" as const },
  ].filter((o) =>
    props.edit ? true : !resellerTypesOfExistingRoyalties.includes(o.value),
  );

  const proServiceRoyaltySelected = ["awsProService", "gcpProService"].includes(
    resellerType ?? "",
  );

  return (
    <AdditionalTermFlyover
      {...props}
      ctrl={ctrl}
      title={props.edit ? "Edit reseller royalty" : "Add a reseller royalty"}
    >
      <Body level={1}>Define your reseller terms.</Body>

      <Select
        {...ctrl.props.MultiSelect("applicableProductIds", {
          name: "Applicable products",
          placeholder: "Enter product name or ID",
          loading: productsReq.loading,
          disabled: productsReq.loading,
          tooltip: `Must provide at least one applicable product ID${proServiceRoyaltySelected ? "" : " or applicable product tag"}`,
          options:
            allProducts.map((p) => ({
              value: p.id,
              label: ProductListItem.getName(p, now),
            })) ?? [],
        })}
      />

      <Select
        {...ctrl.props.MultiSelect("applicableProductTags", {
          name: "Applicable product tags",
          placeholder: "Search by tag",
          loading: productsReq.loading,
          disabled: productsReq.loading || proServiceRoyaltySelected,
          tooltip: proServiceRoyaltySelected
            ? "Tags are not supported for professional service royalties"
            : "Must provide at least one applicable product ID or applicable product tag",
          options: proServiceRoyaltySelected
            ? []
            : productTags.map((tag) => ({
                label: tag,
                value: tag,
              })),
        })}
      />

      <div className="flex gap-12">
        <NumericInput
          {...ctrl.props.NumericInput("percentage", {
            name: "Percentage",
            placeholder: "0.0",
            min: 0,
            suffix: "%",
            className: "w-[6rem]",
          })}
        />
        <DateInput
          {...ctrl.props.DateInput("startingAt", {
            name: "Starting at",
            tooltip: "Inclusive start date for the royalty.",
          })}
        />
        <DateInput
          {...ctrl.props.DateInput("endingBefore", {
            name: "Ending before",
            minDate: ctrl.get("startingAt"),
            tooltip: "Exclusive end date for the royalty.",
          })}
        />
      </div>

      <Input
        {...ctrl.props.Input("netSuiteResellerId", {
          name: "NetSuite reseller ID",
          placeholder: "Enter ID",
          className: "grid grid-cols-3 gap-12",
        })}
      />

      <div className="grid grid-cols-3 gap-12">
        <Select
          {...ctrl.props.Select("type", {
            name: "Reseller type",
            placeholder: "Select a reseller type",
            options: resellerTypeOptions,
            disabled: !!props.edit,
            tooltip:
              "A contract can have at most only one of each type of royalty.",
          })}
        />
      </div>

      {["aws", "awsProService"].includes(resellerType ?? "") && (
        <AwsRoyaltyMetadata parent={ctrl} />
      )}
      {["gcp", "gcpProService"].includes(resellerType ?? "") && (
        <GcpRoyaltyMetadata parent={ctrl} />
      )}
    </AdditionalTermFlyover>
  );
};

const useAwsRoyaltyController = useResellerRoyaltyController.child(
  Schema.AwsRoyaltyMetadata,
  {
    read(parent) {
      const metadata = parent.get("metadata");
      return metadata?.type === "aws" ? metadata : {};
    },
    write(self) {
      return { metadata: self.getUnvalidatedInputs() };
    },
  },
);
export const AwsRoyaltyMetadata: React.FC<{
  parent: ResellerRoyaltyController;
}> = ({ parent }) => {
  const ctrl = useAwsRoyaltyController(parent);
  return (
    <div className="grid grid-cols-3 gap-12">
      <Input
        {...ctrl.props.Input("awsAccountNumber", {
          name: "AWS account number",
          placeholder: "Enter account number",
        })}
      />
      <Input
        {...ctrl.props.Input("awsPayerReferenceId", {
          name: "AWS payer reference ID",
          placeholder: "Enter payer ID",
        })}
      />
      <Input
        {...ctrl.props.Input("awsOfferId", {
          name: "AWS offer ID",
          placeholder: "Enter offer ID",
        })}
      />
    </div>
  );
};

const useGcpRoyaltyController = useResellerRoyaltyController.child(
  Schema.GcpRoyaltyMetadata,
  {
    read(parent) {
      const metadata = parent.get("metadata");
      return metadata?.type === "gcp" ? metadata : {};
    },
    write(self) {
      return { metadata: self.getUnvalidatedInputs() };
    },
  },
);
export const GcpRoyaltyMetadata: React.FC<{
  parent: ResellerRoyaltyController;
}> = ({ parent }) => {
  const ctrl = useGcpRoyaltyController(parent);
  return (
    <div className="grid grid-cols-3 gap-12">
      <Input
        {...ctrl.props.Input("gcpAccountId", {
          name: "GCP account ID",
          placeholder: "Enter account ID",
        })}
      />
      <Input
        {...ctrl.props.Input("gcpOfferId", {
          name: "GCP offer ID",
          placeholder: "Enter offer ID",
        })}
      />
    </div>
  );
};
