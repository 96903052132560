import React from "react";
import { Navigate, type RouteObject } from "react-router";

import NotFoundPage from "pages/404";

import DataExportTab from "./components/DataExportTab";
import IntegrationsTab from "./components/IntegrationsTab";
import CustomFieldsTab from "./components/CustomFieldsTab";
import PricingUnitsTab from "./components/PricingUnitsTab";
import InvoicingTab from "./components/InvoicingTab";
import { TabProps } from "components/Tabs";
import { AuditLogsTab } from "./components/AuditLogsTab";

const tabs = [
  {
    link: "integrations",
    name: "Integrations",
    component: IntegrationsTab,
  },
  {
    link: "data-export",
    name: "Data Export",
    component: DataExportTab,
  },
  {
    link: "custom-fields",
    name: "Custom fields",
    component: CustomFieldsTab,
  },
  {
    link: "invoicing",
    name: "Invoicing",
    component: InvoicingTab,
  },
  {
    link: "pricing-units",
    name: "Pricing units",
    component: PricingUnitsTab,
  },
  {
    link: "audit-logs",
    name: "Audit Logs",
    component: AuditLogsTab,
  },
];

export const INVOICING_TAB_FEATURE_FLAG = "invoicing-settings-tab";

export class TabFilter {
  invoicingTabEnabled: boolean;
  constructor(invoicingTabEnabled: boolean) {
    this.invoicingTabEnabled = invoicingTabEnabled;
  }

  filter = (t: TabProps): boolean => {
    return !t.routePath?.endsWith("invoicing") || this.invoicingTabEnabled;
  };
}

export const GeneralSettingsRoute: RouteObject = {
  path: "settings/general",
  children: [
    { index: true, element: <Navigate to="integrations" replace /> },
    ...tabs.map((tab) => ({
      ...(tab.link ? { path: tab.link } : { index: true }),
      element: (
        // TODO: this probably needs to be wrapped in its own component which checks the feature flag in order to exclude
        // the invoicing tab?
        <tab.component
          tabs={tabs.map((t) => ({
            ...t,
            routePath: `/settings/general/${t.link}`,
          }))}
        />
      ),
    })),
    { path: "*", element: <NotFoundPage /> },
  ],
};
