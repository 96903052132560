import React from "react";
import classNames from "classnames";

import { Breadcrumbs } from "lib/breadcrumbs";
import { useRequiredParam } from "lib/routes/params";
import { PageContainer } from "components/PageContainer";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { NotFoundPage } from "pages/404";
import { ParagraphSkeleton } from "components/Skeleton";

import { Contract } from "../../../lib/Contract";
import {
  ApolloResp,
  ApolloSuccess,
  isLoaded,
} from "pages/Contracts/lib/ApolloResp";

type MinReq = ApolloResp<{
  customer: { name: string; contract: Contract.NameFragment | null };
}>;

type RenderProps<Req extends MinReq> = ApolloSuccess<Req> & {
  contract: NonNullable<ApolloSuccess<Req>["customer"]["contract"]>;
};

interface Props<Req extends MinReq> {
  rootReq: Req;
  tabsAction?: React.ReactNode;
  disableContainerScroll?: boolean | ((props: RenderProps<Req>) => boolean);
  content: (props: RenderProps<Req>) => React.ReactNode;
}

function toRenderProps<Req extends MinReq>(req: Req): RenderProps<Req> {
  if (!isLoaded(req) || !req.customer.contract) {
    throw new Error("req must be loaded");
  }

  return {
    ...req,
    contract: req.customer.contract,
  };
}

export function CustomerContractLayout<Cust extends MinReq>({
  rootReq,
  tabsAction,
  disableContainerScroll,
  content,
}: Props<Cust>) {
  const customerId = useRequiredParam("customerId");
  const contractId = useRequiredParam("contractId");

  if (
    rootReq.state === "not found" ||
    // customer loaded but contract id is invalid or not found
    (isLoaded(rootReq) && !rootReq.customer.contract)
  ) {
    return <NotFoundPage />;
  }

  if (rootReq.state === "error") {
    return (
      <PageContainer title="Unable to load contract" hideHeader>
        <ErrorEmptyState
          title="We were unable to load this contract"
          error={rootReq.error}
        />
      </PageContainer>
    );
  }
  const routePath = `/customers/${customerId}/contracts/${contractId}`;

  // disable container scroll if displaying empty state for contract commits
  const scrollDisabled =
    typeof disableContainerScroll === "function"
      ? isLoaded(rootReq)
        ? disableContainerScroll(toRenderProps(rootReq))
        : true
      : !!disableContainerScroll;

  return (
    <PageContainer
      disableContainerScroll={scrollDisabled}
      title={Breadcrumbs.from(
        {
          type: "back",
          label: "Go back to Customer",
          routePath: `/customers/${customerId}`,
        },
        rootReq.state === "loading"
          ? Breadcrumbs.loading
          : {
              label: rootReq.customer.name,
              routePath: `/customers/${customerId}`,
            },
        rootReq.state === "loading" || !rootReq.customer.contract
          ? Breadcrumbs.loading
          : {
              label: Contract.getName(rootReq.customer.contract),
              routePath,
            },
      )}
      tabs={[
        { name: "Contract overview", routePath },
        {
          name: "Invoices",
          routePath: `${routePath}/invoices`,
          activePatterns: [`${routePath}/invoices/*`],
        },
        {
          name: "Commits and Credits",
          routePath: `${routePath}/commits-and-credits`,
          activePatterns: [`${routePath}/commits-and-credits/*`],
        },
        {
          name: "Lifecycle",
          routePath: `${routePath}/lifecycle`,
          activePatterns: [`${routePath}/lifecycle/*`],
        },
        // TODO: PRA-192
        // { name: "Usage", link: `${routePath}/usage` },

        // TODO: PRA-201
        // { name: "Settings", link: `${routePath}/settings` },
      ]}
      tabsAction={tabsAction}
    >
      <div
        className={classNames("max-w-[2400px]", "mb-12", {
          ["flex h-full flex-col overflow-hidden"]: scrollDisabled,
        })}
      >
        {isLoaded(rootReq) ? (
          content(toRenderProps(rootReq))
        ) : (
          <ParagraphSkeleton numLines={6} className="w-4/5 xl:w-3/4" />
        )}
      </div>
    </PageContainer>
  );
}
