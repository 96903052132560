import React, { useState } from "react";
import { Toggle } from "design-system";
import { useAuthCheck } from "lib/useAuthCheck";
import { ClientConfigKeyEnum_Enum } from "types/generated-graphql/__types__";
import {
  SaveClientConfigAndDeleteInvoiceDaysUntilDueDocument,
  SaveClientConfigWithInvoiceDaysUntilDueDocument,
} from "../queries.graphql";
import { StripeSettingsToggleProps } from ".";

export const SkipZeroDollarInvoicesToggle: React.FC<
  StripeSettingsToggleProps
> = ({ isChecked, handleToggleOnChange }) => {
  const [isCheckedValue, setIsCheckedValue] = useState(isChecked);
  const canSaveClientConfig = !!useAuthCheck(
    [
      SaveClientConfigWithInvoiceDaysUntilDueDocument,
      SaveClientConfigAndDeleteInvoiceDaysUntilDueDocument,
    ],
    true,
    {
      client_config: ClientConfigKeyEnum_Enum.SkipZeroDollarInvoices,
    },
  ).allowed;

  return (
    <>
      <Toggle
        checked={isCheckedValue}
        label="Skip zero-total invoices"
        disabled={!canSaveClientConfig}
        onChange={(value) =>
          handleToggleOnChange(
            value,
            "skip_zero_dollar_invoices",
            setIsCheckedValue,
          )
        }
      />
      <div className="leading-tight text-sm font-normal text-[#475466]">
        Only send invoices for non-zero amounts
      </div>
    </>
  );
};
