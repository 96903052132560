/* 77d8bd530066a0fa1f779d5f0c568f2ceadad687
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreditGrantIssuanceSummaryQueryVariables = Types.Exact<{
  snapshot_start: Types.Scalars['timestamptz'];
  snapshot_end: Types.Scalars['timestamptz'];
  limit: Types.Scalars['Int'];
  cursor?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;


export type CreditGrantIssuanceSummaryQuery = { __typename?: 'Query', CreditGrant: Array<{ __typename?: 'CreditGrant', id: string, name: string, reason: string | null, amount_granted: string, cost_basis: string, amount_paid: string, created_at: string, effective_at: string, expires_before: string, voided_at: string | null, Customer: { __typename?: 'Customer', id: string, name: string } }> };


export const CreditGrantIssuanceSummaryDocument = gql`
    query CreditGrantIssuanceSummary($snapshot_start: timestamptz!, $snapshot_end: timestamptz!, $limit: Int!, $cursor: uuid) {
  CreditGrant(
    order_by: {id: asc}
    limit: $limit
    where: {id: {_gt: $cursor}, _and: [{effective_at: {_lt: $snapshot_end}}, {effective_at: {_gte: $snapshot_start}}]}
  ) {
    id
    Customer {
      id
      name
      __environment_type: environment_type
    }
    name
    reason
    amount_granted
    cost_basis
    amount_paid
    created_at
    effective_at
    expires_before
    voided_at
    __environment_type: environment_type
  }
}
    `;

/**
 * __useCreditGrantIssuanceSummaryQuery__
 *
 * To run a query within a React component, call `useCreditGrantIssuanceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditGrantIssuanceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditGrantIssuanceSummaryQuery({
 *   variables: {
 *      snapshot_start: // value for 'snapshot_start'
 *      snapshot_end: // value for 'snapshot_end'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useCreditGrantIssuanceSummaryQuery(baseOptions: Apollo.QueryHookOptions<CreditGrantIssuanceSummaryQuery, CreditGrantIssuanceSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreditGrantIssuanceSummaryQuery, CreditGrantIssuanceSummaryQueryVariables>(CreditGrantIssuanceSummaryDocument, options);
      }
export function useCreditGrantIssuanceSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditGrantIssuanceSummaryQuery, CreditGrantIssuanceSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreditGrantIssuanceSummaryQuery, CreditGrantIssuanceSummaryQueryVariables>(CreditGrantIssuanceSummaryDocument, options);
        }
export type CreditGrantIssuanceSummaryQueryHookResult = ReturnType<typeof useCreditGrantIssuanceSummaryQuery>;
export type CreditGrantIssuanceSummaryLazyQueryHookResult = ReturnType<typeof useCreditGrantIssuanceSummaryLazyQuery>;
export type CreditGrantIssuanceSummaryQueryResult = Apollo.QueryResult<CreditGrantIssuanceSummaryQuery, CreditGrantIssuanceSummaryQueryVariables>;