import React from "react";
import Decimal from "decimal.js";

import { InPanelTable } from "components/TablePanel";
import { EmptyState } from "components/EmptyState";
import { printDate, toDayjs } from "lib/date";

import type { TabProps } from "./CommitCard";
import { ColWidths } from "../../../lib/ColWidths";
import { RoundedCurrency, USD_CREDIT_TYPE } from "lib/credits";
import { useInvoiceStatusPillsForId } from "../../../lib/Invoice";

export const TabBillingSchedule: React.FC<TabProps> = ({ commit }) => {
  const scheduleItems =
    commit.__typename === "PrepaidCommit" && "invoice_schedule" in commit
      ? commit.invoice_schedule?.schedule_items ?? []
      : commit.__typename === "PostpaidCommit"
        ? commit.postpaid_commit_invoice_schedule_id.schedule_items
        : [];

  const statusPillsForIds = useInvoiceStatusPillsForId(
    scheduleItems.map((s) => s.invoice_id),
  );

  const contractId = commit.contract
    ? commit.contract.id
    : commit.__typename === "PrepaidCommit"
      ? commit.invoice_contract?.id
      : undefined;

  return scheduleItems.length > 0 ? (
    <InPanelTable
      data={scheduleItems}
      theadClassName="bg-gray-lightest"
      rowRoutePath={(row) =>
        contractId && commit.__typename === "PrepaidCommit"
          ? `/customers/${commit.customer.id}/contracts/${contractId}/invoices/${row.invoice_id}`
          : undefined
      }
      emptyState={
        <EmptyState
          title="No schedule"
          subtitle="This commit does not have any planned invoices"
        />
      }
      columns={[
        {
          id: "invoice",
          header: "Invoice",
          render: (row) => `${printDate(toDayjs(row.date))} invoice`,
        },
        {
          id: "amt",
          header: "Invoice amount",
          align: "right",
          cellClassName: ColWidths.CREDITS,
          render: (row) => (
            <RoundedCurrency
              amount={new Decimal(row.amount)}
              creditType={USD_CREDIT_TYPE}
            />
          ),
        },
        {
          id: "status",
          header: "Status",
          cellClassName: "w-[200px] !pl-[50px]",
          render: (row) => statusPillsForIds(row.invoice_id),
        },
      ]}
    />
  ) : (
    <EmptyState
      title="No schedule"
      subtitle="This commit does not have any planned invoices"
    />
  );
};
