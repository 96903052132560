import React from "react";
import { EnvironmentTypeEnum_Enum } from "types/generated-graphql/__types__";
import { Subtitle } from "design-system";
import styles from "./index.module.less";
import { IntegrationCard, IntegrationCardType } from ".";
import { GenerateWorkatoSsoUrlDocument } from "../IntegrationsTab/queries.graphql";
import {
  SaveVendorEntityMappingsDocument,
  DeleteVendorEntityMappingDocument,
} from "pages/GeneralSettings/queries.graphql";
import { GatedButton } from "../../../../components/GatedButton";

const MANAGED_INTEGRATIONS_DOCS_URL =
  "https://docs.metronome.com/api/#custom-fields"; // TODO(WKF-70): create managed integrations/custom fields docs

interface WorkatoIntegrationCardProps {
  integrationCardType: IntegrationCardType;
  humanReadableCardType: string;
  environmentType: EnvironmentTypeEnum_Enum;
  fetchWorkatoSsoUrl: () => Promise<string | undefined>;
  workatoSsoUrlLoading: boolean;
  setEntityMappingModalIsOpen?: (isOpen: boolean) => void;
}

export const WorkatoIntegrationCard: React.FC<WorkatoIntegrationCardProps> = ({
  integrationCardType,
  humanReadableCardType,
  environmentType,
  fetchWorkatoSsoUrl,
  workatoSsoUrlLoading,
  setEntityMappingModalIsOpen,
}) => (
  <IntegrationCard
    type={integrationCardType}
    titleNode={
      <div className={styles.title}>
        <Subtitle level={2}>{humanReadableCardType}</Subtitle>
      </div>
    }
    connectionDetails={
      <div className="flex flex-row items-center">
        <Subtitle level={4} className="pr-8 text-grey-600">
          {environmentType === EnvironmentTypeEnum_Enum.Production
            ? `Go to Workato to configure managed ${humanReadableCardType} integration.`
            : "Workato can only be accessed from a Production account. Switch environments in the sidebar."}
        </Subtitle>
        {setEntityMappingModalIsOpen ? (
          <GatedButton
            onClick={() => setEntityMappingModalIsOpen(true)}
            doc={[
              SaveVendorEntityMappingsDocument,
              DeleteVendorEntityMappingDocument,
            ]}
            text="Edit Mapping"
            theme="secondary"
          />
        ) : null}
      </div>
    }
    buttonText="Configure"
    buttonTheme="primary"
    buttonOnClick={async () => {
      const url = await fetchWorkatoSsoUrl();
      if (url) window.open(url, "_blank");
    }}
    buttonDisabled={
      workatoSsoUrlLoading ||
      environmentType !== EnvironmentTypeEnum_Enum.Production
    }
    buttonLoading={workatoSsoUrlLoading}
    buttonAuthDoc={GenerateWorkatoSsoUrlDocument}
    docLink={MANAGED_INTEGRATIONS_DOCS_URL}
  />
);
