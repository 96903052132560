import React, { useState } from "react";
import { NumericInput, Select } from "design-system";

import type { CreateContractCtrl } from "../../ContractCreate";
import { Section } from "../../components/Section";
import {
  ContractIntegrations,
  getIntegrationsDescription,
} from "../ContractIntegrations";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useGetBillableMetricsQuery } from "./data.graphql";

interface Props {
  ctrl: CreateContractCtrl;
  options?: {
    salesforceEnabled?: boolean;
    netsuiteEnabled?: boolean;
  };
}

export const ContractBillingConfigurationSection: React.FC<Props> = (props) => {
  const { environmentType } = useEnvironment();
  const [tags, setTags] = useState<Array<string>>([]);
  const [tagSearch, setTagSearch] = useState("");

  const { data, loading } = useGetBillableMetricsQuery({
    variables: {
      environment_type: environmentType,
    },
  });
  const groupKeys = Array.from(
    new Set(
      data?.billable_metrics.flatMap((bm) => {
        const keys = bm.group_keys as Array<string | string[]>;
        return keys?.flat();
      }),
    ),
  ).filter((key) => key);

  const { salesforceEnabled, netsuiteEnabled } = props.options ?? {};

  return (
    <Section
      title="Billing configuration"
      className="flex max-w-[1000px] flex-col gap-24 py-12"
      description="Set up billing and invoicing terms."
    >
      <div className="-mb-12 text-sm text-gray-700">
        <span className="font-medium">Net payment terms:</span> Set how long a
        customer has to remit payment upon an issued invoice.
      </div>
      <div className="grid grid-cols-3 gap-12">
        <NumericInput
          {...props.ctrl.props.NumericInput("netPaymentTermsDays", {
            placeholder: "30 days",
            name: "Net payment terms (optional)",
          })}
        />
      </div>
      {(salesforceEnabled || netsuiteEnabled) && (
        <>
          <div className="-mb-12 text-sm text-gray-700">
            <span className="font-medium">Integrations:</span>{" "}
            {getIntegrationsDescription({ salesforceEnabled, netsuiteEnabled })}
          </div>

          <div className="grid grid-cols-3 gap-12">
            <ContractIntegrations
              ctrl={props.ctrl}
              options={{
                salesforceEnabled,
                netsuiteEnabled,
              }}
            />
          </div>
        </>
      )}

      <div className="-mb-12 text-sm text-gray-700">
        <span className="font-medium">Usage filter (optional):</span> For
        customers with overlapping contracts, you can determine how to route
        usage to the correct contract by selecting a group key/value pair.
      </div>
      <div className="grid grid-cols-3 gap-12">
        <Select
          {...props.ctrl.props.Select("usageFilterGroupKey", {
            placeholder: "",
            name: "Group key",
            loading,
            disabled: loading,
            menuPlacement: "top",
            options: [
              {
                label: "None",
                value: "",
              },
            ].concat(
              groupKeys.map((k) => ({
                label: k,
                value: k,
              })),
            ),
          })}
        />
        <Select
          __internalComponentOverrides={{
            DropdownIndicator: () => null,
            Menu: () => null,
          }}
          placeholder="Type a group value and press enter"
          name="Group values"
          className="col-span-2"
          disabled={!props.ctrl.get("usageFilterGroupKey")}
          options={tags
            .map((t) => ({ label: t, value: t }))
            .concat(tagSearch ? [{ label: tagSearch, value: tagSearch }] : [])
            .concat(
              props.ctrl
                .get("usageFilterGroupValues")
                ?.split(",")
                .filter((v) => v !== "")
                .map((v) => ({
                  label: v,
                  value: v,
                })) ?? [],
            )}
          onSearch={setTagSearch}
          value={
            tags.length > 0
              ? tags
              : props.ctrl.get("usageFilterGroupValues")?.split(",") ?? []
          }
          multiSelect
          onChange={(v) => {
            setTags(v);
            props.ctrl.update({
              usageFilterGroupValues: v.join(","),
            });
          }}
        />
      </div>
    </Section>
  );
};
