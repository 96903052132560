import React, { useCallback } from "react";
import { Modal } from "tenaissance/components/Modal";

type Props = {
  onClose: () => void;
};

const localStorageKey = "dontShowRateCardCreatedModal";
export function shouldShowRateCardCreatedModal() {
  return localStorage.getItem(localStorageKey) !== "true";
}

export function RateCardCreatedModal({ onClose }: Props) {
  const [dontShowAgain, setDontShowAgain] = React.useState(false);

  const onCloseCallback = useCallback(() => {
    if (dontShowAgain) {
      localStorage.setItem(localStorageKey, "true");
    }
    onClose();
  }, [dontShowAgain, onClose]);

  return (
    <Modal
      icon="checkCircle"
      showCloseButton={true}
      title="New rate card created!"
      isOpen={true}
      headerLayout="horizontalLeft"
      buttonLayout="alignedRight"
      extraActions={{
        checkboxAction: {
          type: "checkbox",
          key: localStorageKey,
          props: {
            checked: dontShowAgain,
            label: "Don't show again",
            onClick: ({ checked }) => {
              setDontShowAgain(checked);
            },
          },
        },
      }}
      modalButtons={[
        {
          key: "ok",
          type: "button",
          props: {
            text: "View new rate card",
            theme: "primary",
            onClick: onCloseCallback,
          },
        },
      ]}
      size="lg"
      onClose={onCloseCallback}
    >
      <div className="ml-[80px] flex flex-col gap-12 text-sm text-gray-600">
        <div>
          Your new rate card is ready to go! Rate cards power what a customer is
          entitled to and their pricing by default.
        </div>
        <div>
          Your next step will be to create a contract. A contract is directly
          tied to a customer, and defines how a customer will be invoiced. A
          contract consists of a rate card,{" "}
          <a
            className="underline"
            href="https://docs.metronome.com/pricing-and-packaging/how-contracts-work/"
            target="_blank"
          >
            overrides
          </a>
          , and{" "}
          <a
            className="underline"
            href="https://docs.metronome.com/pricing-and-packaging/how-contracts-work/"
            target="_blank"
          >
            commits/credits
          </a>
          .
        </div>
      </div>
    </Modal>
  );
}
