import React from "react";
import { AvatarWithName } from "design-system";

import { toDayjs, printDate } from "lib/date";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { useSearcher } from "lib/search/useSearcher";
import { Table } from "components/Table";
import { EmptyState } from "components/EmptyState";

import { ColWidths } from "../../lib/ColWidths";
import { useContractRateCardsListQuery } from "./data.graphql";
import { ContractPricingWrapper } from "../ContractPricingWrapper";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";

export const RateCardsList: React.FC = () => {
  const req = useContractRateCardsListQuery();
  const [searchTerm, setSearchTerm] = React.useState("");

  const rateCards = (req.data?.contract_pricing.rate_cards ?? []).map((rc) => ({
    ...rc,
    __search__productNames: rc.products.flatMap((p) =>
      ProductListItem.getAllNames(p),
    ),
  }));

  const searcher = useSearcher(rateCards, {
    keys: ["name", "__search__productNames"],
  });

  const searchResults = searcher(searchTerm);

  if (req.error) {
    return (
      <ErrorEmptyState
        title="We ran into an error loading your rate cards"
        error={req.error}
      />
    );
  }

  return (
    <ContractPricingWrapper
      searchPlaceholder="Search rate cards"
      searchTerm={searchTerm}
      onSearchTermChange={setSearchTerm}
      newButtonRoutePath="/contract-pricing/rate-cards/new"
      newButtonText="rate card"
    >
      {!req.loading && searchResults.length === 0 ? (
        searchTerm ? (
          <EmptyState
            title="No rate cards match your query"
            subtitle=""
            icon="searchSm"
          />
        ) : (
          <EmptyState
            title="There are no rate cards"
            subtitle="Please use the contract pricing API to create rate cards"
            icon="file05"
          />
        )
      ) : (
        <Table
          loading={req.loading}
          data={searchResults}
          rowRoutePath={(rateCard) =>
            `/contract-pricing/rate-cards/${rateCard.id}`
          }
          maxPageSize={30}
          defaultSortBy={[
            {
              id: "name",
              desc: false,
            },
          ]}
          columns={[
            {
              id: "name",
              header: "Rate card name",
              render: (rateCard) => rateCard.name,
              sortable: true,
              comparator(a, b) {
                return a.name.localeCompare(b.name);
              },
            },
            {
              id: "products",
              header: "Product count",
              cellClassName: ColWidths.ITEM_COUNT,
              render: (rateCard) => `${rateCard.products.length}`,
              sortable: true,
              comparator(a, b) {
                return a.products.length - b.products.length;
              },
            },
            {
              id: "creator",
              header: "Created by",
              cellClassName: ColWidths.ACTOR,
              render: (rateCard) => <AvatarWithName {...rateCard.Creator} />,
              sortable: true,
              comparator(a, b) {
                return a.Creator.name.localeCompare(b.Creator.name);
              },
            },
            {
              id: "edited",
              cellClassName: ColWidths.DATE,
              header: "Last edited",
              sortable: true,
              comparator(a, b) {
                return toDayjs(a.updated_at).diff(b.updated_at);
              },
              render: (rateCard) => printDate(toDayjs(rateCard.updated_at)),
            },
          ]}
        />
      )}
    </ContractPricingWrapper>
  );
};
