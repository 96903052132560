import React, { useMemo, useState } from "react";
import { PageContainer } from "components/PageContainer";
import Fuse from "fuse.js";

import {
  useSearchCustomersQuery,
  SearchCustomersQuery,
  useCustomerByIdQuery,
  useBillableMetricsQuery,
  EventDetailsDocument,
} from "./queries.graphql";
import { useEnvironment } from "lib/environmentSwitcher/context";
import {
  RelativeDateRangeSelector,
  DateRange,
} from "components/RelativeDateRangeSelector";
import { Select, Option } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { EventsTable, EventsTableRef } from "./components/EventsTable";
import {
  FilterState,
  filterToQueryString,
  queryStringToFilter,
} from "./lib/query";
import { useLocation, useNavigate } from "react-router-dom";
import { EventsGraph, EventsGraphRef } from "./components/EventGraph";
import useDebounce from "lib/debounce";
import { EventDetail } from "./components/EventDetail";

// When a user types in a string we want to return a list of relevant customers AND if the string doesn't match the
// ingest alias of any of those customers we want to let the user filter by that string as an ingest alias. This
// function handles taking the result back from a search and returning the relevant options.
function getCustomerOptionsFromSearchResults(
  query: string,
  data: SearchCustomersQuery,
  selectedCustomerID?: string,
): Option[] {
  const seenIngestAliases = new Set<string>();

  for (const customer of data.searchCustomers) {
    if (customer.id === selectedCustomerID) {
      continue;
    }
    for (const alias of customer.CustomerIngestAliases) {
      seenIngestAliases.add(alias.ingest_alias);
    }
  }

  return [
    ...data.searchCustomers
      .filter((customer) => customer.id !== selectedCustomerID)
      .map((c) => ({
        label: c.name,
        value: `customer_id:${c.id}`,
      }))
      .slice(0, 5),
    ...(seenIngestAliases.has(query) || !query
      ? []
      : [
          {
            label: `Customer ID: ${query}`,
            value: `ingest_alias:${query}`,
          },
        ]),
  ];
}

export const Events = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { environmentType } = useEnvironment();
  const tableRef = React.useRef<EventsTableRef>(null);
  const graphRef = React.useRef<EventsGraphRef>(null);

  const filters = queryStringToFilter(location.search);
  const [search, setSearch] = React.useState("");
  const [dateRange, setDateRange] = useState<DateRange | null>(null);

  const debouncedSearch = useDebounce(search, 500);

  // Data loading
  const searchCustomerResults = useSearchCustomersQuery({
    variables: {
      query: debouncedSearch,
      environment_type: environmentType,
    },
    skip: !debouncedSearch,
  });

  const selectedCustomerResults = useCustomerByIdQuery({
    skip: !filters.customerID,
    variables: {
      id: filters.customerID ?? "",
    },
  });

  const billableMetricData = useBillableMetricsQuery({
    variables: {
      environment_type: environmentType,
    },
  });

  const bmFuse = useMemo(() => {
    return new Fuse(billableMetricData.data?.billable_metrics ?? [], {
      ignoreLocation: true,
      includeScore: true,
      useExtendedSearch: true,
      keys: [
        { name: "id", weight: 1.0 },
        { name: "name", weight: 1.0 },
      ],
      threshold: 0.3,
    });
  }, [billableMetricData.data?.billable_metrics]);
  // End data loading

  // This is the bare minimum set of options - It only includes options which the user already selected (thus need to be
  // shown. We'll add more options here depending on search results.
  const customerOptions: Option[] = (filters.ingestAliases || [])
    .map((alias) => ({
      label: `Customer ID: ${alias}`,
      value: `ingest_alias:${alias}`,
    }))
    .concat(
      filters.customerID
        ? [
            {
              label: selectedCustomerResults.loading
                ? search
                : selectedCustomerResults.data?.Customer_by_pk?.name ??
                  filters.customerID,
              value: `customer_id:${filters.customerID}`,
            },
          ]
        : [],
    );

  const transactionIDOptions: Option[] = (filters.transactionIDs || []).map(
    (txnID) => ({
      label: `Transaction ID: ${txnID}`,
      value: `txn_id:${txnID}`,
    }),
  );

  const billableMetricOptions: Option[] = filters.billableMetrics?.length
    ? filters.billableMetrics.map((bm) => ({
        label:
          billableMetricData.data?.billable_metrics.find((b) => b.id === bm)
            ?.name ?? "",
        value: `billable-metric:${bm}`,
      }))
    : [];

  if (!searchCustomerResults.loading) {
    if (searchCustomerResults.data) {
      customerOptions.push(
        ...getCustomerOptionsFromSearchResults(
          search,
          searchCustomerResults.data,
          filters.customerID,
        ),
      );
    }

    if (search) {
      transactionIDOptions.push({
        label: `Transaction ID: ${search}`,
        value: `txn_id:${search}`,
      });

      billableMetricOptions.push(
        ...bmFuse
          .search(search)
          .filter((bm) => !(filters.billableMetrics ?? []).includes(bm.item.id))
          .map((result) => ({
            label: result.item.name,
            value: `billable-metric:${result.item.id}`,
          })),
      );
    }
  }

  const options = [
    {
      label: "Customers",
      options: customerOptions,
    },
    {
      label: "Billable Metrics",
      options: billableMetricOptions,
    },
    {
      label: "Duplicates",
      options: [
        {
          label: "Only duplicates",
          value: "duplicates:true",
        },
        {
          label: "Only non duplicates",
          value: "duplicates:false",
        },
      ].filter((o) => {
        // If the user currently isn't filtering by duplicates then only show the options once they start
        // to type so the results don't jump around
        if (filters.duplicates === undefined) {
          return !!search;
        }
        return o.value === `duplicates:${filters.duplicates}`;
      }),
    },
    {
      label: "Transaction ID",
      options: transactionIDOptions,
    },
  ];

  const filteredIngestAliases = filters.customerID
    ? selectedCustomerResults.data?.Customer_by_pk?.CustomerIngestAliases.map(
        (alias) => alias.ingest_alias,
      ).concat([filters.customerID])
    : filters.ingestAliases;

  return (
    <PageContainer title="Events" authDoc={EventDetailsDocument}>
      <div className="mx-0 my-8 flex items-center gap-8">
        <Select
          className="w-full"
          multiSelect
          placeholder="Search by customer, duplicates, billable metric or transaction ID"
          onSearch={(v) => {
            setSearch(v);
            if (!v) {
              return;
            }
          }}
          loading={searchCustomerResults.loading}
          options={options}
          value={[
            ...(filters.billableMetrics || []).map(
              (txnID) => `billable-metric:${txnID}`,
            ),
            ...(filters.transactionIDs || []).map((txnID) => `txn_id:${txnID}`),
            ...(filters.customerID
              ? [`customer_id:${filters.customerID}`]
              : []),
            ...(filters.ingestAliases || []).map(
              (alias) => `ingest_alias:${alias}`,
            ),
            ...(typeof filters.duplicates != undefined
              ? [`duplicates:${filters.duplicates}`]
              : []),
          ]}
          onChange={(selectedOptions) => {
            let filters: FilterState = {};
            selectedOptions.forEach((option) => {
              const [part, ...rest] = option.split(":");
              const id = rest.join(":");
              if (part === "txn_id") {
                filters.transactionIDs = [id];
              } else if (part === "ingest_alias") {
                filters.ingestAliases = [id];
              } else if (part === "customer_id") {
                filters.customerID = id;
              } else if (part === "duplicates") {
                filters.duplicates = id === "true";
              } else if (part === "billable-metric") {
                filters.billableMetrics = [id];
              }
            });
            navigate(`${location.pathname}?${filterToQueryString(filters)}`, {
              replace: !!location.search,
            });
          }}
        />
        <RelativeDateRangeSelector
          utc={true}
          onChange={(range) => {
            setDateRange(range);
            const newFilters: FilterState = {
              ...filters,
              startingOn: range.inclusiveStart,
              endingBefore: range.exclusiveEnd,
            };
            navigate(`${location.pathname}?${filterToQueryString(newFilters)}`);
          }}
          defaultValue={
            filters.startingOn && filters.endingBefore
              ? {
                  inclusiveStart: filters.startingOn,
                  exclusiveEnd: filters.endingBefore,
                }
              : "7d"
          }
        />
        <IconButton
          onClick={() => {
            graphRef.current?.refetch();
            tableRef.current?.refetch();
          }}
          theme="secondary"
          icon="refreshCw01"
          size="sm"
        />
      </div>
      <EventsGraph
        ref={graphRef}
        startingAfter={dateRange?.inclusiveStart ?? new Date()}
        endingBefore={dateRange?.exclusiveEnd ?? new Date()}
        ingest_aliases={filteredIngestAliases}
        duplicates={filters.duplicates}
        billableMetricIDs={filters.billableMetrics}
        transaction_ids={filters.transactionIDs}
      />
      {filters.transactionIDs ? (
        <EventDetail
          eventID={filters.eventID}
          transactionIDs={filters.transactionIDs}
          startingAfter={dateRange?.inclusiveStart ?? new Date()}
          endingBefore={dateRange?.exclusiveEnd ?? new Date()}
          ingest_aliases={filteredIngestAliases}
          billableMetricIDs={filters.billableMetrics}
          duplicates={filters.duplicates}
        />
      ) : (
        <EventsTable
          ref={tableRef}
          startingAfter={dateRange?.inclusiveStart ?? new Date()}
          endingBefore={dateRange?.exclusiveEnd ?? new Date()}
          ingest_aliases={filteredIngestAliases}
          billableMetricIDs={filters.billableMetrics}
          duplicates={filters.duplicates}
        />
      )}
    </PageContainer>
  );
};

export default Events;
