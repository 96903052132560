import React from "react";
import classNames from "classnames";

import { Breadcrumbs } from "lib/breadcrumbs";
import { NotFoundPage } from "pages/404";
import { PageContainer } from "components/PageContainer";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { ParagraphSkeleton } from "components/Skeleton";
import { useRequiredParam } from "lib/routes/params";

import { CustomerFromRouteReq } from "../../lib/Customer";
import { CustomerPlanReq } from "./useCustomerPlanFromRoute";
import type { DetailedCustomerPlanFragmentReader } from "../../lib/CustomerPlan";

interface Props {
  customerReq: CustomerFromRouteReq;
  customerPlanReq: CustomerPlanReq;
  disableContainerScroll?: boolean;
  content: (ctx: {
    customer: (CustomerFromRouteReq & { state: "success" })["customer"];
    customerPlan: DetailedCustomerPlanFragmentReader;
  }) => React.ReactNode;
  tabsAction?: React.ReactNode;
}

export const ContractCustomerPlanContainer: React.FC<Props> = ({
  customerReq,
  customerPlanReq,
  disableContainerScroll,
  content,
  tabsAction,
}) => {
  const customerId = useRequiredParam("customerId");

  if (
    customerReq.state === "not found" ||
    customerPlanReq.state === "not found"
  ) {
    return <NotFoundPage />;
  }

  if (customerReq.state === "error") {
    return (
      <PageContainer hideHeader title="Failed to load customer">
        <ErrorEmptyState
          title="We were unable to load this customer"
          error={customerReq.error}
        />
      </PageContainer>
    );
  }
  if (customerPlanReq.state === "error") {
    return (
      <PageContainer hideHeader title="Failed to load plan">
        <ErrorEmptyState
          title="We were unable to load this plan"
          error={customerPlanReq.error}
        />
      </PageContainer>
    );
  }

  return (
    <PageContainer
      tabsAction={tabsAction}
      disableContainerScroll={disableContainerScroll}
      title={Breadcrumbs.from(
        {
          type: "back",
          label: "Back to customer overview",
          routePath: `/customers/${customerId}`,
        },
        customerReq.state === "loading"
          ? Breadcrumbs.loading
          : {
              label: customerReq.customer.name,
              routePath: `/customers/${customerId}`,
            },
        customerPlanReq.state === "loading"
          ? Breadcrumbs.loading
          : {
              label: customerPlanReq.customerPlan.name,
              routePath: `/customers/${customerId}/plans/${customerPlanReq.id}`,
            },
      )}
      tabs={[
        {
          name: "Plan overview",
          routePath: `/customers/${customerId}/plans/${customerPlanReq.id}`,
        },
        {
          name: "Invoices",
          routePath: `/customers/${customerId}/plans/${customerPlanReq.id}/invoices`,
          activePatterns: ["/customers/:a/plans/:b/invoices/*"],
        },
      ]}
    >
      <div
        className={classNames("max-w-[2400px]", {
          ["h-full overflow-hidden"]: disableContainerScroll,
        })}
      >
        {customerReq.state === "loading" ||
        customerPlanReq.state === "loading" ? (
          <ParagraphSkeleton />
        ) : (
          content({
            customer: customerReq.customer,
            customerPlan: customerPlanReq.customerPlan,
          })
        )}
      </div>
    </PageContainer>
  );
};
