import React from "react";
import styles from "./index.module.css";
import { Icon } from "../Icon";
import { Tooltip } from "../Tooltip";

export const HelpCircleTooltip: React.FC<{ content: string | JSX.Element }> = ({
  content,
}) => (
  <Tooltip content={content}>
    <Icon icon="helpCircle" className={styles.helpCircle} />
  </Tooltip>
);
