import { Checkbox } from "design-system";
import React from "react";
import { useSnackbar } from "components/Snackbar";

import {
  useGetLineItemOptionsQuery,
  useSaveLineItemOptionsMutation,
  GetLineItemOptionsQuery,
  GetLineItemOptionsDocument,
  SaveLineItemOptionsDocument,
} from "./queries.graphql";
import { ClientConfigKeyEnum_Enum } from "types/generated-graphql/__types__";
import { StripeSetting } from "../StripeSetting";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useAuthCheck } from "lib/useAuthCheck";
import { useContractsEnabled } from "lib/contracts/useContractsEnabled";

export const LineItemOptions: React.FC<{
  onErrorLoadingData: (error: boolean) => void;
}> = ({ onErrorLoadingData }) => {
  const { environmentType } = useEnvironment();
  const pushMessage = useSnackbar();
  const { data, loading, error } = useGetLineItemOptionsQuery({
    variables: {
      environment_type: environmentType,
    },
  });
  const [saveLineItemOptions] = useSaveLineItemOptionsMutation();
  const canSaveLineItemOptions = !!useAuthCheck(
    SaveLineItemOptionsDocument,
    true,
  ).allowed;
  const hasContractsEnabled = useContractsEnabled();

  const displaySubLineItems =
    data?.exportSubLineItemConfig?.[0]?.value === "true";

  // If the includeZeroQuantitySubLineItems is not set, we default to true
  // Must stay in sync with billing-provider-invoicer/src/lib/integrationOptions.ts
  const displayZeroQuantitySubLineItems =
    data?.includeZeroQuantitySubLineItems?.length === 0 ||
    data?.includeZeroQuantitySubLineItems?.[0]?.value === "true";
  const quantityAsString =
    data?.stripeQuantityAlwaysString?.[0]?.value === "true";

  if (error) {
    onErrorLoadingData(true);
  }

  const save = async (
    field:
      | ClientConfigKeyEnum_Enum.ExportInvoiceSubLineItems
      | ClientConfigKeyEnum_Enum.IncludeZeroQuantitySubLineItems
      | ClientConfigKeyEnum_Enum.StripeInvoiceQuantityAlwaysString,
    value: "true" | "false",
  ) => {
    const variables = {
      environment_type: environmentType,
      export_invoice_sub_line_items: (field ===
      ClientConfigKeyEnum_Enum.ExportInvoiceSubLineItems
        ? value
        : displaySubLineItems
      ).toString(),
      include_zero_quantity_sub_line_items: (field ===
      ClientConfigKeyEnum_Enum.IncludeZeroQuantitySubLineItems
        ? value
        : displayZeroQuantitySubLineItems
      ).toString(),
      stripe_invoice_quantity_always_string: (field ===
      ClientConfigKeyEnum_Enum.StripeInvoiceQuantityAlwaysString
        ? value
        : quantityAsString
      ).toString(),
    };

    if (
      field === ClientConfigKeyEnum_Enum.ExportInvoiceSubLineItems &&
      value === "false" &&
      !hasContractsEnabled
    ) {
      variables.include_zero_quantity_sub_line_items = "false";
      variables.stripe_invoice_quantity_always_string = "false";
    }

    try {
      await saveLineItemOptions({
        variables,
        optimisticResponse: {
          exportSubLineItem: {
            id: "new-exportSubLineItemConfig",
            value: variables.export_invoice_sub_line_items,
            key: ClientConfigKeyEnum_Enum.ExportInvoiceSubLineItems,
          },
          includeZeroQuantitySubLineItems: {
            id: "new-includeZeroQuantitySubLineItems",
            value: variables.include_zero_quantity_sub_line_items,
            key: ClientConfigKeyEnum_Enum.IncludeZeroQuantitySubLineItems,
          },
          stripeQuantityAlwaysString: {
            id: "new-stripeQuantityAlwaysString",
            value: variables.stripe_invoice_quantity_always_string,
            key: ClientConfigKeyEnum_Enum.StripeInvoiceQuantityAlwaysString,
          },
        },
        update: (store, { data }) => {
          if (
            !(
              data?.exportSubLineItem &&
              data?.includeZeroQuantitySubLineItems &&
              data?.stripeQuantityAlwaysString
            )
          ) {
            return;
          }
          store.writeQuery<GetLineItemOptionsQuery>({
            data: {
              exportSubLineItemConfig: [data.exportSubLineItem],
              includeZeroQuantitySubLineItems: [
                data.includeZeroQuantitySubLineItems,
              ],
              stripeQuantityAlwaysString: [data.stripeQuantityAlwaysString],
            },
            query: GetLineItemOptionsDocument,
          });
        },
      });
      const text = value === "true" ? "will" : "will not";
      pushMessage({
        content:
          field === ClientConfigKeyEnum_Enum.ExportInvoiceSubLineItems
            ? `Saved. Sub-line items ${text} be displayed on invoices`
            : field === ClientConfigKeyEnum_Enum.IncludeZeroQuantitySubLineItems
              ? `Saved. Sub-line items with a quantity of zero ${text} be displayed on invoices`
              : `Saved. Sub-line item descriptions ${text} always be displayed on invoices`,
        type: "success",
      });
    } catch (error) {
      pushMessage({
        content:
          field === ClientConfigKeyEnum_Enum.ExportInvoiceSubLineItems
            ? "Failed to save change to displaying sub-line items on invoices"
            : field === ClientConfigKeyEnum_Enum.IncludeZeroQuantitySubLineItems
              ? "Failed to save change to displaying sub-line items with a quantity of zero on invoices"
              : "Failed to save change to displaying quantities in sub-line item descriptions",
        type: "error",
      });
    }
  };

  const enableSubLineItemsCheckbox = (
    <Checkbox
      label={
        hasContractsEnabled
          ? "Display sub-line items (Plan invoices only)"
          : "Display sub-line items on invoices"
      }
      checked={displaySubLineItems}
      onChange={async (value) => {
        await save(
          ClientConfigKeyEnum_Enum.ExportInvoiceSubLineItems,
          value ? "true" : "false",
        );
      }}
      disabled={loading || !canSaveLineItemOptions}
    />
  );
  const includeZeroQuantitySubLineItemsCheckbox = (
    <Checkbox
      label={
        hasContractsEnabled
          ? "Display line items with zero quantity on Stripe invoices"
          : "Display sub-line items that have zero quantity on invoices"
      }
      checked={displayZeroQuantitySubLineItems}
      onChange={async (value) => {
        await save(
          ClientConfigKeyEnum_Enum.IncludeZeroQuantitySubLineItems,
          value ? "true" : "false",
        );
      }}
      disabled={
        loading ||
        (!displaySubLineItems && !hasContractsEnabled) ||
        !canSaveLineItemOptions
      }
    />
  );
  const displayQuantitiesCheckbox = (
    <Checkbox
      label={
        hasContractsEnabled
          ? "Always display price and quantity information in Stripe invoice line item descriptions"
          : "Always display quantities in sub-line item descriptions"
      }
      checked={quantityAsString}
      onChange={async (value) => {
        await save(
          ClientConfigKeyEnum_Enum.StripeInvoiceQuantityAlwaysString,
          value ? "true" : "false",
        );
      }}
      disabled={
        loading ||
        (!displaySubLineItems && !hasContractsEnabled) ||
        !canSaveLineItemOptions
      }
    />
  );
  return (
    <>
      <StripeSetting
        setting={enableSubLineItemsCheckbox}
        helpText={
          hasContractsEnabled
            ? "This setting only applies to Plan invoices. By default, Stripe invoices for Plans display only product names and totals. This setting will display the associated sub-line items for each product. Read the documentation for more information."
            : "By default, Stripe invoices display only product names and totals. This setting will display the associated sub-line items for each product. Read the documentation for more information."
        }
      />
      <StripeSetting
        setting={includeZeroQuantitySubLineItemsCheckbox}
        helpText={
          hasContractsEnabled
            ? "For Plan invoices, enabling this setting will display sub-line items with zero quantity on Stripe invoices. For Contract invoices, enabling this setting will display line items with zero quantity on Stripe invoices."
            : "Select this if you want to include sub-line items that have a quantity of zero in your Stripe invoices."
        }
      />
      <StripeSetting
        setting={displayQuantitiesCheckbox}
        helpText={
          hasContractsEnabled
            ? "For Plan invoices, enabling this setting will always display quantities in Stripe line item descriptions. For Contract invoices, enabling this setting will always display both prices and quantities in Stripe line item descriptions."
            : "Use this option if you sometimes have non-integer quantities, which are not supported by Stripe, and want all quantities to be included in the description of the line item. The documentation describes this option in detail with examples."
        }
      />
    </>
  );
};
