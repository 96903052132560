import React, { Dispatch, SetStateAction } from "react";
import { EmptyState } from "tenaissance/components/EmptyState";
import { LeaveInvoicesInDraftToggle } from "./LeaveInvoicesInDraftToggle";
import { SkipZeroDollarInvoicesToggle } from "./SkipZeroDollarInvoicesToggle";
import { StripeToken } from "./StripeToken";
import { ApolloError } from "@apollo/client";
import { SublineItemsToggle } from "./SublineItemsToggle";
import { IncludeZeroQuantitySublineItemsToggle } from "./IncludeZeroQuantitySublineItems";
import { useAuthCheck } from "lib/useAuthCheck";
import {
  SaveClientConfigAndDeleteInvoiceDaysUntilDueDocument,
  SaveClientConfigWithInvoiceDaysUntilDueDocument,
} from "../queries.graphql";
import { useContractsEnabled } from "lib/contracts/useContractsEnabled";
import { DisplaySublineItemQuantitiesToggle } from "./DisplaySublineItemQuantities";
import { InvoiceDaysUntilDue } from "./InvoiceDaysUntilDue";
import { StripeSettingsDataType } from "..";

interface StripeInvoiceSettingsProps {
  tokenID: string;
  setShouldSaveStripeChanges: Dispatch<SetStateAction<boolean>>;
  setStripeSettingsData: Dispatch<SetStateAction<StripeSettingsDataType>>;
  stripeSettingsData: StripeSettingsDataType;
  errorLoadingData?: ApolloError;
}

export interface StripeSettingsToggleProps {
  isChecked: boolean;
  handleToggleOnChange: (
    value: boolean,
    key: string,
    setIsCheckedValue: Dispatch<SetStateAction<boolean>>,
  ) => void;
  displayLineItems?: boolean;
  canSaveClientConfig?: boolean;
  hasContractsEnabled?: boolean;
}

export const StripeInvoiceSettings: React.FC<StripeInvoiceSettingsProps> = ({
  tokenID,
  setShouldSaveStripeChanges,
  setStripeSettingsData,
  stripeSettingsData,
  errorLoadingData,
}) => {
  const canSaveClientConfig = !!useAuthCheck(
    [
      SaveClientConfigAndDeleteInvoiceDaysUntilDueDocument,
      SaveClientConfigWithInvoiceDaysUntilDueDocument,
    ],
    true,
  ).allowed;
  const hasContractsEnabled = useContractsEnabled();
  if (errorLoadingData) {
    return (
      <EmptyState
        mainText="We ran into an issue loading this page"
        supportingText="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
        icon="data"
      />
    );
  }

  const handleToggleOnChange = (
    value: boolean,
    key: string,
    setIsCheckedValue: Dispatch<SetStateAction<boolean>>,
  ) => {
    setIsCheckedValue(value);
    setStripeSettingsData((data) => ({
      ...data,
      [key]: value,
    }));
    setShouldSaveStripeChanges(true);
  };

  return (
    <>
      {Object.values(stripeSettingsData).length > 0 && (
        <div className="flex flex-col justify-start gap-y-[24px]">
          <StripeToken tokenID={tokenID} />
          <div className="leading-tight gap-2 self-stretch text-sm font-semibold text-gray-600">
            Invoice settings
            <InvoiceDaysUntilDue
              invoiceDaysUntilDue={stripeSettingsData.invoice_days_until_due}
              setStripeSettingsData={setStripeSettingsData}
              setShouldSaveStripeChanges={setShouldSaveStripeChanges}
            />
            <LeaveInvoicesInDraftToggle
              isChecked={!!stripeSettingsData.leave_invoices_in_draft}
              handleToggleOnChange={handleToggleOnChange}
            />
            <SkipZeroDollarInvoicesToggle
              isChecked={!!stripeSettingsData?.skip_zero_dollar_invoices}
              handleToggleOnChange={handleToggleOnChange}
            />
          </div>
          <div className="leading-tight gap-2 self-stretch text-sm font-semibold text-gray-600">
            Invoice presentation
            <SublineItemsToggle
              isChecked={!!stripeSettingsData.export_invoice_sub_line_items}
              handleToggleOnChange={handleToggleOnChange}
            />
            <IncludeZeroQuantitySublineItemsToggle
              isChecked={
                !!stripeSettingsData.include_zero_quantity_sub_line_items
              }
              handleToggleOnChange={handleToggleOnChange}
              displayLineItems={
                !!stripeSettingsData.export_invoice_sub_line_items
              }
              canSaveClientConfig={canSaveClientConfig}
              hasContractsEnabled={hasContractsEnabled}
            />
            <DisplaySublineItemQuantitiesToggle
              isChecked={
                !!stripeSettingsData.stripe_invoice_quantity_always_string
              }
              handleToggleOnChange={handleToggleOnChange}
              displayLineItems={
                !!stripeSettingsData.export_invoice_sub_line_items
              }
              canSaveClientConfig={canSaveClientConfig}
              hasContractsEnabled={hasContractsEnabled}
            />
          </div>
        </div>
      )}
    </>
  );
};
