/* 2c31c9174f2cd4d71f7696f26d45498cda03e0c8
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBillingProvidersSettingsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  customer_id: Types.Scalars['uuid'];
}>;


export type GetBillingProvidersSettingsQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', id: string, name: string, BillingProviderCustomers: Array<{ __typename?: 'BillingProviderCustomer', billing_provider_customer_id: string, billing_provider: Types.BillingProviderEnum_Enum, created_at: string }> } | null, billingProviderTokens: Array<{ __typename?: 'BillingProviderToken', billing_provider: Types.BillingProviderEnum_Enum }>, customerConfigs: Array<{ __typename?: 'CustomerConfig', id: string, key: string, value: string, updated_at: string }>, stripe_billing_provider_configs: Array<{ __typename?: 'CustomerBillingProviderConfiguration', id: string, billing_provider: Types.BillingProviderEnum_Enum, configuration: { [key: string]: string | number | boolean }, created_at: string }>, delivery_methods: Array<{ __typename?: 'CustomerBillingProviderConfiguration', id: string, billing_provider: Types.BillingProviderEnum_Enum, delivery_method: Types.BillingProviderDeliveryMethod_Enum }> };

export type GetActiveMarketplaceContractsQueryVariables = Types.Exact<{
  billing_provider: Types.BillingProviderEnum_Enum;
  grace_period_hours: Types.Scalars['Int'];
  customer_id: Types.Scalars['uuid'];
}>;


export type GetActiveMarketplaceContractsQuery = { __typename?: 'Query', contracts_page: { __typename?: 'ContractPage', cursor: string | null, contracts: Array<{ __typename?: 'Contract', id: string, starting_at: string, ending_before: string | null, customer: { __typename?: 'Customer', id: string, environment_type: Types.EnvironmentTypeEnum_Enum } }> } };

export type GetClientConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetClientConfigQuery = { __typename?: 'Query', is_delta_stream_enabled: boolean, is_customer_billing_provider_configuration_enabled: boolean, ClientConfig: Array<{ __typename?: 'ClientConfig', id: string, key: Types.ClientConfigKeyEnum_Enum, value: string }> };


export const GetBillingProvidersSettingsDocument = gql`
    query GetBillingProvidersSettings($environment_type: EnvironmentTypeEnum_enum!, $customer_id: uuid!) {
  customer: Customer_by_pk(id: $customer_id) {
    id
    name
    BillingProviderCustomers(
      where: {billing_provider: {_in: [AWS_MARKETPLACE, AZURE, STRIPE]}}
    ) {
      billing_provider_customer_id
      billing_provider
      created_at
    }
    __environment_type: environment_type
  }
  billingProviderTokens: BillingProviderToken(
    where: {billing_provider: {_in: [AWS_MARKETPLACE, AZURE, STRIPE]}, environment_type: {_eq: $environment_type}}
  ) {
    billing_provider
    __environment_type: environment_type
  }
  customerConfigs: CustomerConfig(
    where: {customer_id: {_eq: $customer_id}, key: {_in: ["stripe_collection_method", "aws_product_code", "aws_region", "aws_expiration_timestamp", "aws_metering_disabled", "azure_plan_id", "azure_subscription_status", "azure_start_date", "azure_expiration_date", "azure_metering_disabled", "salesforce_account_id", "netsuite_customer_id"]}}
  ) {
    id
    key
    value
    updated_at
  }
  stripe_billing_provider_configs: customer_billing_provider_configurations(
    customer_id: $customer_id
    delivery_method: DIRECT_TO_BILLING_PROVIDER
    billing_provider: STRIPE
  ) {
    id
    billing_provider
    configuration
    created_at
  }
  delivery_methods: customer_billing_provider_configurations(
    customer_id: $customer_id
  ) {
    id
    billing_provider
    delivery_method
  }
}
    `;

/**
 * __useGetBillingProvidersSettingsQuery__
 *
 * To run a query within a React component, call `useGetBillingProvidersSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingProvidersSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingProvidersSettingsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useGetBillingProvidersSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetBillingProvidersSettingsQuery, GetBillingProvidersSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillingProvidersSettingsQuery, GetBillingProvidersSettingsQueryVariables>(GetBillingProvidersSettingsDocument, options);
      }
export function useGetBillingProvidersSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillingProvidersSettingsQuery, GetBillingProvidersSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillingProvidersSettingsQuery, GetBillingProvidersSettingsQueryVariables>(GetBillingProvidersSettingsDocument, options);
        }
export type GetBillingProvidersSettingsQueryHookResult = ReturnType<typeof useGetBillingProvidersSettingsQuery>;
export type GetBillingProvidersSettingsLazyQueryHookResult = ReturnType<typeof useGetBillingProvidersSettingsLazyQuery>;
export type GetBillingProvidersSettingsQueryResult = Apollo.QueryResult<GetBillingProvidersSettingsQuery, GetBillingProvidersSettingsQueryVariables>;
export const GetActiveMarketplaceContractsDocument = gql`
    query GetActiveMarketplaceContracts($billing_provider: BillingProviderEnum_enum!, $grace_period_hours: Int!, $customer_id: uuid!) {
  contracts_page: get_active_customer_contracts_for_billing_provider(
    billing_providers: [$billing_provider]
    customer_ids: [$customer_id]
    grace_period_hours: $grace_period_hours
    limit: 1
  ) {
    contracts {
      id
      starting_at
      ending_before
      customer {
        id
        environment_type
        __environment_type: environment_type
      }
    }
    cursor
  }
}
    `;

/**
 * __useGetActiveMarketplaceContractsQuery__
 *
 * To run a query within a React component, call `useGetActiveMarketplaceContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveMarketplaceContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveMarketplaceContractsQuery({
 *   variables: {
 *      billing_provider: // value for 'billing_provider'
 *      grace_period_hours: // value for 'grace_period_hours'
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useGetActiveMarketplaceContractsQuery(baseOptions: Apollo.QueryHookOptions<GetActiveMarketplaceContractsQuery, GetActiveMarketplaceContractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveMarketplaceContractsQuery, GetActiveMarketplaceContractsQueryVariables>(GetActiveMarketplaceContractsDocument, options);
      }
export function useGetActiveMarketplaceContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveMarketplaceContractsQuery, GetActiveMarketplaceContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveMarketplaceContractsQuery, GetActiveMarketplaceContractsQueryVariables>(GetActiveMarketplaceContractsDocument, options);
        }
export type GetActiveMarketplaceContractsQueryHookResult = ReturnType<typeof useGetActiveMarketplaceContractsQuery>;
export type GetActiveMarketplaceContractsLazyQueryHookResult = ReturnType<typeof useGetActiveMarketplaceContractsLazyQuery>;
export type GetActiveMarketplaceContractsQueryResult = Apollo.QueryResult<GetActiveMarketplaceContractsQuery, GetActiveMarketplaceContractsQueryVariables>;
export const GetClientConfigDocument = gql`
    query GetClientConfig {
  ClientConfig(where: {key: {_eq: grace_period_hours}}) {
    id
    key
    value
    __environment_type: environment_type
  }
  is_delta_stream_enabled
  is_customer_billing_provider_configuration_enabled
}
    `;

/**
 * __useGetClientConfigQuery__
 *
 * To run a query within a React component, call `useGetClientConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetClientConfigQuery, GetClientConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientConfigQuery, GetClientConfigQueryVariables>(GetClientConfigDocument, options);
      }
export function useGetClientConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientConfigQuery, GetClientConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientConfigQuery, GetClientConfigQueryVariables>(GetClientConfigDocument, options);
        }
export type GetClientConfigQueryHookResult = ReturnType<typeof useGetClientConfigQuery>;
export type GetClientConfigLazyQueryHookResult = ReturnType<typeof useGetClientConfigLazyQuery>;
export type GetClientConfigQueryResult = Apollo.QueryResult<GetClientConfigQuery, GetClientConfigQueryVariables>;