import { ReportConfig } from "../..";
import { dayjs } from "lib/dayjs";
import {
  CreditGrantIssuanceSummaryDocument,
  CreditGrantIssuanceSummaryQuery,
} from "./queries.graphql";
import { DEFAULT_PAGE_SIZE, REPORT_DATE_FORMAT } from "../consts";

const report: ReportConfig<CreditGrantIssuanceSummaryQuery> = {
  pageSize: DEFAULT_PAGE_SIZE,
  name: "Credit grant issuance",
  needsDates: true,
  needsEnvironment: false,
  dataToCSV: (pages) => {
    const header = [
      "Grant ID",
      "Customer",
      "Grant name",
      "Grant reason",
      "Credits granted",
      "Cost basis",
      "Amount paid (USD cents)",
      "Created at",
      "Effective at",
      "Expires before",
      "Voided at",
    ];

    const rows = pages.flatMap((page) =>
      page.CreditGrant.map((creditGrant) => {
        return [
          creditGrant.id,
          creditGrant.Customer.name,
          creditGrant.name,
          creditGrant.reason ?? "",
          creditGrant.amount_granted,
          creditGrant.cost_basis,
          creditGrant.amount_paid,
          dayjs.utc(creditGrant.created_at).format(REPORT_DATE_FORMAT),
          dayjs.utc(creditGrant.effective_at).format(REPORT_DATE_FORMAT),
          dayjs.utc(creditGrant.expires_before).format(REPORT_DATE_FORMAT),
          creditGrant.voided_at
            ? dayjs.utc(creditGrant.voided_at).format(REPORT_DATE_FORMAT)
            : "",
        ];
      }),
    );
    return [header, ...rows];
  },
  queryDocument: CreditGrantIssuanceSummaryDocument,
  isAllowedForUser: (ldClient) =>
    ldClient?.variation("available-report-types", []).includes("CREDIT_GRANT"),
  nextCursor: (page) => page.CreditGrant.at(-1)?.id ?? null,
};

export default report;
