import { useEffect } from "react";
import { Schema } from "../Schema";
import { FormController } from "lib/FormController";
import { useGetAllCreditTypesQuery } from "../CreateAndEditRateCard/data.graphql";
import { filterAndSortCreditTypes } from "pages/Contracts/lib/CreditTypes";
import { USD_CREDIT_TYPE } from "lib/credits";
import { createContainer } from "unstated-next";
import { useProductsQuery } from "./data.graphql";

const useRateCardCreateV2Controller = FormController.createHook(
  Schema.RateCardInput,
  {
    init(snapshotKey: string) {
      return FormController.parseJsonSnapshot(
        Schema.RateCardInput,
        sessionStorage.getItem(snapshotKey),
      );
    },
  },
);

function useRateCardContext() {
  const { data, loading: loadingCreditTypes } = useGetAllCreditTypesQuery();
  const { fiatCreditTypes, customCreditTypes } = filterAndSortCreditTypes(
    data?.CreditType ?? [],
  );
  const {
    data: products,
    loading: loadingProducts,
    error: productDataError,
  } = useProductsQuery();

  const snapshotKey = `rate-card-create-v2`;
  const ctrl = useRateCardCreateV2Controller(snapshotKey);

  // save snapshot on every change of the form
  useEffect(() => {
    sessionStorage.setItem(snapshotKey, JSON.stringify(ctrl.snapshot()));
  }, [ctrl]);

  useEffect(() => {
    ctrl.update({
      rates: [
        {
          id: "r-1",
          entitled: "enable",
          startingAt: "2024-09-01T00:00:00Z",
          productId: "pr-1",
          price: {
            type: "flat",
            price: 100,
          },
          creditType: {
            id: USD_CREDIT_TYPE.id,
            name: USD_CREDIT_TYPE.name,
          },
          pricingGroupValues: {
            deployable_name: "ibm-granite/granite-20b-code-instruct-8k",
            hardware: "gpu-2x-a100",
          },
          pricingModel: "flat usage",
        },
        {
          id: "r-2",
          entitled: "enable",
          startingAt: "2024-09-01T00:00:00Z",
          productId: "pr-1",
          price: {
            type: "tiered",
            // prep for tiered usage
            tiers: [
              {
                unitPrice: 100,
                lastUnit: 1000,
              },
              {
                unitPrice: 90,
                lastUnit: 2000,
              },
              {
                unitPrice: 80,
              },
            ],
          },
          creditType: {
            id: USD_CREDIT_TYPE.id,
            name: USD_CREDIT_TYPE.name,
          },
          pricingGroupValues: {
            deployable_name: "ibm-granite/granite-20b-code-instruct-r1.1",
            hardware: "gpu-2x-a100",
          },
          pricingModel: "tiered usage",
        },
        {
          id: "r-3",
          entitled: "enable",
          startingAt: "2024-09-01T00:00:00Z",
          productId: "pr-2",
          price: {
            type: "flat",
            price: 100,
          },
          creditType: {
            id: USD_CREDIT_TYPE.id,
            name: USD_CREDIT_TYPE.name,
          },
          pricingGroupValues: {
            deployable_name: "ibm-granite/granite-20b-code-instruct-8k",
            hardware: "gpu-2x-a40-large",
          },
          pricingModel: "flat usage",
        },
        {
          id: "r-4",
          entitled: "enable",
          startingAt: "2024-09-01T00:00:00Z",
          productId: "pr-2",
          price: {
            type: "flat",
            price: 100,
          },
          creditType: {
            id: USD_CREDIT_TYPE.id,
            name: USD_CREDIT_TYPE.name,
          },
          pricingGroupValues: {
            deployable_name: "ibm-granite/granite-20b-code-instruct-r1.1",
            hardware: "gpu-2x-a40-large",
          },
          pricingModel: "flat usage",
        },
        {
          id: "r-5",
          entitled: "enable",
          startingAt: "2024-09-01T00:00:00Z",
          productId: "pr-3",
          price: {
            type: "flat",
            price: 100,
          },
          creditType: {
            id: USD_CREDIT_TYPE.id,
            name: USD_CREDIT_TYPE.name,
          },
          pricingGroupValues: {
            deployable_name: "ibm-granite/granite-20b-code-instruct-8k",
          },
          pricingModel: "flat usage",
        },
        {
          id: "r-6",
          entitled: "enable",
          startingAt: "2024-09-01T00:00:00Z",
          productId: "pr-3",
          price: {
            type: "flat",
            price: 100,
          },
          creditType: {
            id: USD_CREDIT_TYPE.id,
            name: USD_CREDIT_TYPE.name,
          },
          pricingGroupValues: {
            deployable_name: "ibm-granite/granite-20b-code-instruct-r1.1",
          },
          pricingModel: "flat usage",
        },
        {
          id: "r-7",
          entitled: "enable",
          startingAt: "2024-09-01T00:00:00Z",
          productId: "pr-6",
          price: {
            type: "flat",
            price: 100,
          },
          creditType: {
            id: USD_CREDIT_TYPE.id,
            name: USD_CREDIT_TYPE.name,
          },
          pricingModel: "flat usage",
        },
        {
          id: "r-8",
          entitled: "enable",
          startingAt: "2024-09-01T00:00:00Z",
          productId: "pr-4",
          price: {
            type: "percentage",
            fraction: 0.05,
          },
          creditType: {
            id: USD_CREDIT_TYPE.id,
            name: USD_CREDIT_TYPE.name,
          },
          pricingModel: "flat usage",
        },
        /*
        {
          id: "r-9",
          entitled: "enable",
          startingAt: "2024-09-01T00:00:00Z",
          productId: "pr-5",
          price: {
            type: "subscription",
            price: 100,
            quantity: 1,
            isProrated: true,
          },
          creditType: {
            id: USD_CREDIT_TYPE.id,
            name: USD_CREDIT_TYPE.name,
          },
        },
        */
      ],
    });
  }, []);

  function clearSnapshot() {
    sessionStorage.removeItem(snapshotKey);
  }

  return {
    ctrl,
    loadingCreditTypes,
    fiatCreditTypes,
    customCreditTypes,
    clearSnapshot,
    products,
    loadingProducts,
    productDataError,
  };
}

export const RateCardContext = createContainer(useRateCardContext);
