import React from "react";
import { Schema } from "../Schema";
import { CreditInput } from "components/Input";
import { USD_CREDIT_TYPE } from "lib/credits";
import { CreditType } from "types/credit-types";

interface Props {
  rate: Schema.Types.Rate;
  isCommitRate: boolean;
  fiatCreditTypes: CreditType[];
  customCreditTypes: CreditType[];
  onChange: (rate: Schema.Types.Rate) => void;
}

export const RateFlat: React.FC<Props> = (props) => {
  const allCreditTypes = [...props.fiatCreditTypes, ...props.customCreditTypes];
  const selectedCreditTypeId = props.rate.creditType?.id;

  const [cachedRate, setCachedRate] = React.useState(props.rate);

  const currentPrice =
    props.rate.price.type === "flat" ? props.rate.price.price : 0;
  return (
    <CreditInput
      placeholder="123.45"
      creditType={
        allCreditTypes.find((ct) => ct.id === selectedCreditTypeId) ??
        USD_CREDIT_TYPE
      }
      allowZeroAmounts={true}
      initialValue={currentPrice.toString()}
      onBlur={() => {
        /*
        console.log("onBlur", cachedRate);
        props.onChange(cachedRate);
        */
      }}
      onChange={(credits) => {
        setCachedRate({
          ...cachedRate,
          price: {
            ...cachedRate.price,
            type: "flat",
            price: credits ?? 0,
          },
        });
      }}
    />
  );
};
