import { Subtitle, Icon, IconName } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import React from "react";

import classNames from "classnames";
import styles from "./index.module.less";
import { TextSkeleton } from "components/Skeleton";
import { MinimalStatistic, MinimalStatisticProps } from "components/Statistic";
import { Tooltip } from "design-system";
import Truncate from "react-text-truncate";
import { MenuItemProps, PopoverMenu } from "components/PopoverMenu";
import { InternalLink } from "components/Typography";

export type CardProps = {
  id?: string;
  title: string | React.ReactNode;
  subtitle?: React.ReactNode;
  content: React.ReactNode;
  statistics?: Array<MinimalStatisticProps>;

  tooltip?: {
    icon: IconName;
    content: string;
  };
  footer?: React.ReactNode;
  routePath?: string;
  onClick?: () => void;
  badge?: React.ReactNode;
  className?: string;
  actions?: MenuItemProps[];
};

type CardsProps =
  | {
      cards: CardProps[];
      gap?: "12" | "24";
      loading?: boolean;
    }
  | {
      loading: true;
      gap?: "12" | "24";
    };

export const Cards: React.FC<CardsProps> = (props) => {
  const containerClassnames = classNames(styles.cards, {
    [styles.gap24]: props.gap === "24",
  });

  if (props.loading) {
    return (
      <div className={containerClassnames}>
        <div className={styles.card}>
          <TextSkeleton />
          <TextSkeleton />
          <TextSkeleton />
          <TextSkeleton />
        </div>
      </div>
    );
  }

  return (
    <div className={containerClassnames}>
      {("cards" in props ? props.cards : []).map((card, i) => {
        const content =
          typeof card.content === "string" ? (
            <Truncate line={3} text={card.content} />
          ) : (
            card.content
          );

        const contentInContainer = (
          <>
            <div className={styles.header}>
              <Subtitle level={1} className={styles.title}>
                {card.title}
              </Subtitle>
              <div className={styles.headerActions}>
                {card.badge ? card.badge : null}
                {card.actions ? (
                  <PopoverMenu
                    positions={["bottom"]}
                    align="end"
                    options={card.actions}
                  >
                    {(onClick) => (
                      <div onClick={onClick}>
                        <IconButton
                          className={styles.actionButton}
                          theme="tertiary"
                          icon="dotsVertical"
                        />
                      </div>
                    )}
                  </PopoverMenu>
                ) : null}
              </div>
            </div>

            {card.subtitle ? (
              <div className={styles.subtitle}>{card.subtitle}</div>
            ) : null}
            <div className={styles.content}>
              {content}
              {card.statistics && (
                <div className={styles.statistics}>
                  {card.statistics.map((statistic, j) => (
                    <MinimalStatistic
                      key={(card.id || i).toString() + statistic.label}
                      {...statistic}
                    />
                  ))}
                </div>
              )}
              {card.tooltip && (
                <div className={styles.tooltip}>
                  <Tooltip content={card.tooltip.content}>
                    <Icon icon={card.tooltip.icon} />
                  </Tooltip>
                </div>
              )}
            </div>
            {card.footer && <div className={styles.footer}>{card.footer}</div>}
          </>
        );

        if (card.routePath) {
          return (
            <InternalLink
              key={card.id || i}
              routePath={card.routePath}
              className={classNames(
                styles.card,
                styles.clickable,
                card.className,
              )}
            >
              {contentInContainer}
            </InternalLink>
          );
        } else if (card.onClick) {
          return (
            <a
              key={card.id || i}
              onClick={card.onClick}
              className={classNames(
                styles.card,
                styles.clickable,
                card.className,
              )}
            >
              {contentInContainer}
            </a>
          );
        } else {
          return (
            <div
              key={card.id || i}
              className={classNames(styles.card, card.className)}
            >
              {contentInContainer}
            </div>
          );
        }
      })}
    </div>
  );
};
