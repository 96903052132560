/* 8d4fa57bf636e802a68966bfadafad440fe52fda
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsertCustomerMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  ingestAliases: Array<Types.Scalars['String']> | Types.Scalars['String'];
  environmentType?: Types.InputMaybe<Types.EnvironmentTypeEnum_Enum>;
}>;


export type InsertCustomerMutation = { __typename?: 'Mutation', create_customer: { __typename?: 'Customer', id: string } };


export const InsertCustomerDocument = gql`
    mutation InsertCustomer($name: String!, $ingestAliases: [String!]!, $environmentType: EnvironmentTypeEnum_enum) {
  create_customer(
    name: $name
    ingest_aliases: $ingestAliases
    environment_type: $environmentType
  ) {
    id
    __environment_type: environment_type
  }
}
    `;
export type InsertCustomerMutationFn = Apollo.MutationFunction<InsertCustomerMutation, InsertCustomerMutationVariables>;

/**
 * __useInsertCustomerMutation__
 *
 * To run a mutation, you first call `useInsertCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCustomerMutation, { data, loading, error }] = useInsertCustomerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      ingestAliases: // value for 'ingestAliases'
 *      environmentType: // value for 'environmentType'
 *   },
 * });
 */
export function useInsertCustomerMutation(baseOptions?: Apollo.MutationHookOptions<InsertCustomerMutation, InsertCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCustomerMutation, InsertCustomerMutationVariables>(InsertCustomerDocument, options);
      }
export type InsertCustomerMutationHookResult = ReturnType<typeof useInsertCustomerMutation>;
export type InsertCustomerMutationResult = Apollo.MutationResult<InsertCustomerMutation>;
export type InsertCustomerMutationOptions = Apollo.BaseMutationOptions<InsertCustomerMutation, InsertCustomerMutationVariables>;