import React, { useState } from "react";
import { Table } from "tenaissance/components/Table";
import { useNavigate } from "lib/useNavigate";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { generateAlertPolicyString } from "lib/alerts";
import { Badge } from "tenaissance/components/Badge";
import { BadgeGroup } from "tenaissance/components/BadgeGroup";
import { useAllAlertsQuery } from "./queries.graphql";
import { Button } from "tenaissance/components/Button";

export const Alerts: React.FC = () => {
  const { environmentType } = useEnvironment();
  const [search, setSearch] = useState<string | undefined>();
  const { data, loading } = useAllAlertsQuery({
    variables: {
      environment_type: environmentType,
      search: search,
    },
  });
  const navigate = useNavigate();
  return (
    <Table
      loading={loading}
      rowRoutePath={(row) => `/connections/alerts/${row.id}`}
      columns={[
        {
          id: "name",
          header: "Alert",
          cell: (props) => props.getValue(),
          accessorKey: "name",
        },
        {
          id: "policy",
          header: "Policy",
          cell: (props) => <Badge label={props.getValue()} theme="indigo" />,
          accessorFn: (row) =>
            generateAlertPolicyString(
              row.threshold,
              row.CreditType,
              row.alert_type,
              row.BillableMetric?.name,
            ),
        },
        {
          id: "customers",
          header: "Customers",
          cell: (props) => {
            const { label, count } = props.getValue();
            return <BadgeGroup badgeLabel={label} mainLabel={count} />;
          },
          accessorFn: (row) => {
            if (row.Customer) {
              return { label: row.Customer.name, count: 1 };
            }
            if (row.Plan) {
              return {
                label: `Plan: ${row.Plan.name}`,
                count: row.customer_count,
              };
            } else {
              return { label: "All", count: row.customer_count };
            }
          },
        },
      ]}
      searchOptions={{
        showSearch: true,
        onSearch: (v) => setSearch(v),
      }}
      data={data?.alerts ?? []}
      paginationOptions={{ type: "clientSide" }}
      topBarActions={[
        <Button
          text="Add"
          leadingIcon="plus"
          onClick={() => navigate("/connections/alerts/new")}
        />,
      ]}
    />
  );
};
