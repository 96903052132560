/* 73b81046e97835292852f9de2f1b2291b0f1a571
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetObjectSyncReportsQueryVariables = Types.Exact<{
  destination_type: Types.CensusDestinationTypeEnum;
}>;


export type GetObjectSyncReportsQuery = { __typename?: 'Query', object_sync_reports: Array<{ __typename?: 'CensusObjectSyncReport', object_name: string, sync_runs: Array<{ __typename?: 'CensusSyncRun', id: string, records_processed: number | null, records_updated: number | null, records_failed: number | null, completed_at: string | null, scheduled_execution_time: string | null, status: Types.CensusSyncRunStatusEnum, sampled_failed_records: Array<{ __typename?: 'CensusRecordSyncError', error_message: string, record_attributes: { [key: string]: string | number | boolean } | null }> | null }> }> };


export const GetObjectSyncReportsDocument = gql`
    query GetObjectSyncReports($destination_type: CensusDestinationTypeEnum!) {
  object_sync_reports: get_census_object_sync_runs(
    destination_type: $destination_type
  ) {
    object_name
    sync_runs {
      id
      records_processed
      records_updated
      records_failed
      sampled_failed_records {
        error_message
        record_attributes
      }
      completed_at
      scheduled_execution_time
      status
    }
  }
}
    `;

/**
 * __useGetObjectSyncReportsQuery__
 *
 * To run a query within a React component, call `useGetObjectSyncReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObjectSyncReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObjectSyncReportsQuery({
 *   variables: {
 *      destination_type: // value for 'destination_type'
 *   },
 * });
 */
export function useGetObjectSyncReportsQuery(baseOptions: Apollo.QueryHookOptions<GetObjectSyncReportsQuery, GetObjectSyncReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetObjectSyncReportsQuery, GetObjectSyncReportsQueryVariables>(GetObjectSyncReportsDocument, options);
      }
export function useGetObjectSyncReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetObjectSyncReportsQuery, GetObjectSyncReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetObjectSyncReportsQuery, GetObjectSyncReportsQueryVariables>(GetObjectSyncReportsDocument, options);
        }
export type GetObjectSyncReportsQueryHookResult = ReturnType<typeof useGetObjectSyncReportsQuery>;
export type GetObjectSyncReportsLazyQueryHookResult = ReturnType<typeof useGetObjectSyncReportsLazyQuery>;
export type GetObjectSyncReportsQueryResult = Apollo.QueryResult<GetObjectSyncReportsQuery, GetObjectSyncReportsQueryVariables>;