import React, { useState } from "react";

import { Body, Input } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";

import { useSnackbar } from "components/Snackbar";
import { useAddAzureMarketplaceToCustomerMutation } from "./queries.graphql";

interface AzureMarketplaceSettingsModalProps {
  onClose: () => void;
  customerId: string;
}

export const AzureMarketplaceSettingsModal: React.FC<
  AzureMarketplaceSettingsModalProps
> = ({ onClose, customerId }) => {
  const pushMessage = useSnackbar();
  const [azureSubscriptionId, setAzureSubscriptionId] = useState<string>();
  const [updateSettings, { loading, error }] =
    useAddAzureMarketplaceToCustomerMutation({
      update(cache) {
        cache.evict({
          fieldName: "BillingProviderCustomer",
        });
        cache.evict({
          fieldName: "CustomerConfig",
        });
        cache.evict({
          fieldName: "Customer_aggregate",
        });
      },
    });

  const updateSettingsAction = async () => {
    if (azureSubscriptionId) {
      await updateSettings({
        variables: {
          customer_id: customerId,
          azure_subscription_id: azureSubscriptionId,
        },
      });
      pushMessage({
        content: "Azure Marketplace configuration saved",
        type: "success",
      });
      onClose();
    }
  };
  const actionButtons = (
    <Button
      key="primary"
      disabled={!azureSubscriptionId || loading}
      onClick={updateSettingsAction}
      loading={loading}
      text="Add Azure Marketplace"
      theme="primary"
    />
  );

  return (
    <Popup
      actions={actionButtons}
      isOpen={true}
      onRequestClose={() => onClose()}
      title="Azure Marketplace configuration"
    >
      <Body level={2}>
        To add the Azure Marketplace profile you need the customer’s
        subscription ID associated with their marketplace purchase.
      </Body>
      <Input
        name="Azure subscription ID"
        placeholder="Enter Azure subscription ID"
        value={azureSubscriptionId ?? ""}
        className="mb-12"
        onChange={setAzureSubscriptionId}
        autoFocus
        error={!loading && error?.message}
      />
    </Popup>
  );
};

export default AzureMarketplaceSettingsModal;
