import React, { useState } from "react";

import styles from "./index.module.less";
import { IntegrationCard } from "pages/GeneralSettings/components/IntegrationCard";
import { renderDateTime } from "lib/time";
import { Badge, Subtitle, Body } from "design-system";
import { CopyableID } from "components/CopyableID";
import { SalesforceConnectionModal } from "../../components/SalesforceConnectionModal";
import { DeleteSalesforceConnectionModal } from "../../components/DeleteSalesforceConnectionModal";
import { UpsertSalesforceAccountIdDocument } from "../../components/SalesforceConnectionModal/queries.graphql";

type SalesforceConnectionSectionProps = {
  customerId: string;
  customerName?: string;
  connectionDate?: Date;
  salesforceAccountId?: string;
  loading?: boolean;
};

export const SalesforceConnection: React.FC<
  SalesforceConnectionSectionProps
> = ({
  customerId,
  customerName,
  connectionDate,
  salesforceAccountId,
  loading,
}) => {
  const [editmodalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  let content: JSX.Element;

  if (loading) {
    content = (
      <IntegrationCard
        type="SALESFORCE"
        titleNode={
          <Subtitle className={styles.title} level={2}>
            Salesforce
          </Subtitle>
        }
        info={
          <Body level={2} className={styles.notConfigured}>
            Loading...
          </Body>
        }
        buttonText="Loading"
        buttonTheme="secondary"
        buttonDisabled
      />
    );
  } else if (!salesforceAccountId) {
    content = (
      <IntegrationCard
        type="SALESFORCE"
        titleNode={
          <Subtitle className={styles.title} level={2}>
            Salesforce
          </Subtitle>
        }
        info={
          <Body level={2} className={styles.notConfigured}>
            {customerName || "This customer"} doesn't have Salesforce configured
          </Body>
        }
        buttonText="Connect"
        buttonTheme="secondary"
        buttonAuthDoc={UpsertSalesforceAccountIdDocument}
        buttonOnClick={() => setEditModalOpen(true)}
      />
    );
  } else {
    content = (
      <IntegrationCard
        type="SALESFORCE"
        titleNode={
          <>
            <Subtitle className={styles.title} level={2}>
              Salesforce
            </Subtitle>
            <Badge theme="success" type="dark" className={styles.activeBadge}>
              ACTIVE
            </Badge>
          </>
        }
        info={
          <div className={styles.providerInfo}>
            <CopyableID
              id={salesforceAccountId}
              label="Salesforce account ID"
            />
          </div>
        }
        connectionDetails={renderDateTime(
          connectionDate || new Date(),
          true,
          "Connected on",
        )}
        buttonText="Edit"
        buttonAuthDoc={UpsertSalesforceAccountIdDocument}
        buttonOnClick={() => setEditModalOpen(true)}
      />
    );
  }

  return (
    <>
      {editmodalOpen && (
        <SalesforceConnectionModal
          onClose={(deleteConfig?: boolean) => {
            setEditModalOpen(false);
            setDeleteModalOpen(deleteConfig ?? false);
          }}
          isOpen={editmodalOpen}
          initialAccountId={salesforceAccountId}
          customerId={customerId}
        />
      )}
      {deleteModalOpen && (
        <DeleteSalesforceConnectionModal
          onClose={() => setDeleteModalOpen(false)}
          isOpen={deleteModalOpen}
          customerId={customerId}
        />
      )}
      {content}
    </>
  );
};
