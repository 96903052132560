import React from "react";
import { Select, Headline, DateInput } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { FormController } from "lib/FormController";
import { useNow } from "lib/date";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";
import { RightPane } from "components/Popup";

import { Schema } from "../../Schema";
import { FooterBar } from "../../components/FooterBar";
import {
  useGetProductsForRateCardQuery,
  useGetProductsQuery,
  useCreditTypeForRateFromRateCardAndProductIdsQuery,
} from "./data.graphql";
import { DefaultTimeframe } from "../../lib/DefaultTimeframe";
import { useConfirmClose } from "../../components/ConfirmClose";

import { useOverrideCtrl } from "./OverrideCtrl";
import { PercentageRate } from "./PercentageRate";
import { MultiplierRate } from "./MultiplierRate";
import { OverwriteFlatRate } from "./OverwriteFlatRate";
import { OverwriteSubscriptionRate } from "./OverwriteSubscriptionRate";
import { RateSchedulePanel } from "pages/Contracts/components/RateSchedulePanel";
import { EmptyState } from "components/EmptyState";
import { Override } from "@metronome-industries/schedule-utils";
import { OverwriteTieredRate } from "./OverwriteTieredRate";
import { CreditType } from "types/credit-types";

export interface OverrideFlyoverProps {
  rateCardId: string;
  defaultProductId?: string;
  contract: {
    starting_at: string | null;
    ending_before: string | null;
    multiplier_override_prioritization: string | null;
  };
  overrides?: Override.Description[];
  edit?: Schema.Types.Override;
  onSave: (override: Schema.Types.Override) => void;
  onCancel: () => void;
  onDelete?: () => void;
  creditType?: CreditType;
}

export const OverrideFlyover: React.FC<OverrideFlyoverProps> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = useOverrideCtrl(props, timeframe);
  const [confirmingClose, confirmClose] = useConfirmClose(ctrl, props.onCancel);
  const now = useNow();
  const rateCard = useGetProductsForRateCardQuery({
    variables: { rateCardId: props.rateCardId },
  });

  const overrideType = ctrl.get("type");
  const productId = ctrl.get("productId");
  const tags = ctrl.get("tags");
  const products = rateCard.data?.contract_pricing.rate_card.products;

  const product = products?.find((p) => p.id === productId);
  const productsReq = useGetProductsQuery();
  const allProducts = productsReq.data?.products_and_rate_cards.products ?? [];
  const tagsFromDb = [
    ...new Set(
      allProducts?.flatMap((product) => ProductListItem.getTags(product, now)),
    ),
  ];

  const matchingProducts = allProducts.filter(
    (p) =>
      (productId && p.id === productId) ||
      (tags && ProductListItem.getTags(p, now).some((t) => tags.includes(t))),
  );

  const onSubmit = FormController.useSubmitHandler(ctrl, (valid) => {
    props.onSave(valid);
  });

  const rateCardId = rateCard.data?.contract_pricing.rate_card.id;
  const creditTypeForRateResp =
    useCreditTypeForRateFromRateCardAndProductIdsQuery({
      skip: !rateCardId || !productId || !!props.edit,
      variables: {
        rateCardId: rateCardId ?? "",
        productId: productId ?? "",
      },
    });
  const creditTypeFromRate =
    creditTypeForRateResp.data?.products_and_rate_cards.rate_card.rate_schedule
      .credit_types_on_segments[0];

  return (
    <>
      {confirmingClose}
      <RightPane
        isOpen
        onRequestClose={confirmClose}
        size="xl"
        contentClassName="!p-0"
      >
        <form className="flex h-full flex-col" onSubmit={onSubmit}>
          <header className="flex items-center border-b border-gray-100 bg-gray-50 px-12 py-8">
            <Headline level={6} className="grow">
              {props.edit ? "Edit override" : "Add an override"}
            </Headline>
            <IconButton
              className="m-0"
              onClick={() => props.onCancel()}
              theme="secondary"
              icon="xClose"
            />
          </header>
          <div className="flex grow flex-col gap-32 overflow-y-auto p-12">
            <Select
              {...ctrl.props.Select("productId", {
                name: "Product",
                placeholder: "Select a product",
                loading: rateCard.loading,
                options:
                  products?.map((p) => ({
                    label: ProductListItem.getName(p, now),
                    value: p.id,
                  })) ?? [],
              })}
            />
            <Select
              {...ctrl.props.MultiSelect("tags", {
                name: "Product tags",
                placeholder: "Select tags",
                loading: rateCard.loading,
                disabled: ctrl.get("type") === "overwrite",
                tooltip: "Cannot be used with overwrite adjustments.",
                options:
                  tagsFromDb?.map((tag) => ({
                    label: tag,
                    value: tag,
                  })) ?? [],
              })}
            />

            <>
              <div className="grid grid-cols-4 gap-12">
                <DateInput
                  {...ctrl.props.DateInput("startingAt", {
                    name: "Starting at",
                    tooltip: "Inclusive start date for the override.",
                  })}
                />
                <DateInput
                  {...ctrl.props.DateInput("endingBefore", {
                    name: "Ending before (optional)",
                    minDate: ctrl.get("startingAt"),
                    tooltip: "Exclusive end date for the override.",
                  })}
                />
                <Select
                  {...ctrl.props.Select("entitled", {
                    placeholder: "Select",
                    name: "Entitlement",
                    options: [
                      {
                        label: "Don't override",
                        value: "inherit",
                      },
                      {
                        label: "Enable",
                        value: "enable",
                      },
                      {
                        label: "Disable",
                        value: "disable",
                      },
                    ],
                  })}
                />
              </div>
              <div className="grid grid-cols-4 gap-12">
                <Select
                  {...ctrl.props.Select("type", {
                    name: "Adjustment type",
                    placeholder: "Select a type",
                    options:
                      (ctrl.get("tags") || []).length > 0
                        ? [
                            {
                              label: "Multiplier",
                              value: "multiplier",
                            },
                          ]
                        : [
                            {
                              label: "Don't override rate",
                              value: "",
                            },
                            {
                              label: "Multiplier",
                              value: "multiplier",
                            },
                            {
                              label: "Overwrite",
                              value: "overwrite",
                            },
                          ],
                    tooltip:
                      "Multiplier rates adjust the price by some factor, Overwrite rates define new prices.",
                    map(update) {
                      if (!update.type) {
                        return { ...update, rate: undefined };
                      }
                      return update;
                    },
                  })}
                />
                {overrideType === "overwrite" &&
                  product?.__typename === "UsageProductListItem" && (
                    <Select
                      {...ctrl.props.Select("pricingModel", {
                        name: "Pricing model",
                        placeholder: "Select",
                        options: [
                          { label: "Flat usage", value: "flat usage" },
                          { label: "Tiered usage", value: "tiered usage" },
                        ],
                        defaultValue: {
                          label: "Flat usage",
                          value: "flat usage",
                        },
                        className: "text-gray-600",
                      })}
                    />
                  )}
                {(() => {
                  switch (overrideType) {
                    case "multiplier":
                      return (
                        <MultiplierRate
                          parent={ctrl}
                          multiplierOverridePrioritization={
                            props.contract.multiplier_override_prioritization
                          }
                        />
                      );

                    case "overwrite":
                      return product?.__typename ===
                        "CompositeProductListItem" ? (
                        <PercentageRate parent={ctrl} />
                      ) : product?.__typename ===
                        "SubscriptionProductListItem" ? (
                        <OverwriteSubscriptionRate
                          parent={ctrl}
                          creditType={props.creditType ?? creditTypeFromRate}
                        />
                      ) : ctrl.get("pricingModel") === "tiered usage" ? (
                        <div className="col-span-3">
                          <OverwriteTieredRate
                            parent={ctrl}
                            creditType={props.creditType ?? creditTypeFromRate}
                          />
                        </div>
                      ) : (
                        <OverwriteFlatRate
                          parent={ctrl}
                          creditType={props.creditType ?? creditTypeFromRate}
                        />
                      );
                  }
                })()}
              </div>
            </>
            {(productId || tags) && (
              <RateSchedulePanel
                title="Current rate schedule"
                startingAt={
                  props.contract.starting_at
                    ? new Date(props.contract.starting_at)
                    : null
                }
                endingBefore={
                  props.contract.ending_before
                    ? new Date(props.contract.ending_before)
                    : null
                }
                rateCardId={props.rateCardId}
                overrides={props.overrides}
                disableSearch
                rateSelectors={matchingProducts.map((p) => ({
                  product_id: p.id,
                }))}
                emptyState={
                  <EmptyState
                    title="No matching products found"
                    subtitle="No products match the selected product ID or tags."
                  />
                }
              />
            )}
          </div>
          <FooterBar
            left={
              props.onDelete ? (
                <Button
                  onClick={props.onDelete}
                  text="Delete"
                  theme="linkDestructive"
                  leadingIcon="trash03"
                />
              ) : null
            }
            right={
              <>
                <Button
                  onClick={() => props.onCancel()}
                  text="Cancel"
                  theme="linkGray"
                />
                <Button
                  disabled={!ctrl.appearsValid()}
                  text={props.edit ? "Save" : "Create"}
                  theme="primary"
                  type="submit"
                />
              </>
            }
          />
        </form>
      </RightPane>
    </>
  );
};
