import React, { useState } from "react";

import { Table, SortFunctions } from "components/Table";

import { CreateTokenModal } from "./components/CreateTokenModal";
import {
  InsertTokenDocument,
  TokensDocument,
  TokensQuery,
  useTokensQuery,
} from "./data/tokens.graphql";
import { PageContainer } from "components/PageContainer";
import { renderDateTime } from "lib/time";
import { EmptyState } from "components/EmptyState";
import { Hyperlink, Icon } from "design-system";
import ArchiveTokenModal from "./components/ArchiveTokenModal";
import { Tooltip } from "design-system";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { ArchiveTokenDocument } from "./components/ArchiveTokenModal/queries.graphql";
import { GatedButton, GatedIconButton } from "../../components/GatedButton";

function HiddenAPIToken({
  token_display_suffix,
}: {
  token_display_suffix: string;
}) {
  const hidden = "•".repeat(58) + token_display_suffix;
  return <div className="inline-flex items-center font-mono">{hidden}</div>;
}

type Token = TokensQuery["Tokens"][0];

const APITokens: React.FC = () => {
  const { environmentType } = useEnvironment();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const { data, loading } = useTokensQuery({
    variables: {
      environment_type: environmentType,
    },
  });
  const [tokenToArchive, setTokenToArchive] = useState<Token | null>(null);

  const headerAction = (
    <GatedButton
      doc={InsertTokenDocument}
      onClick={() => setCreateModalOpen(true)}
      text="Create API token"
      theme="primary"
      leadingIcon="plus"
      size="sm"
    />
  );

  return (
    <PageContainer
      title="API Tokens"
      action={headerAction}
      authDoc={TokensDocument}
    >
      {createModalOpen && (
        <CreateTokenModal onClose={() => setCreateModalOpen(false)} />
      )}
      {!loading && data?.Tokens.length == 0 ? (
        <EmptyState
          title="You haven't created any API tokens yet."
          subtitle={
            <>
              Create an API token to start accessing the{" "}
              <Hyperlink
                target="_blank"
                routePath="https://docs.metronome.com/api"
              >
                Metronome API <Icon icon="openOutline" />
              </Hyperlink>
              .
            </>
          }
          buttonAuthDoc={InsertTokenDocument}
          buttonText="Create API token"
          onClick={() => setCreateModalOpen(true)}
          icon="key01"
          buttonIcon="plus"
        />
      ) : (
        <div>
          {tokenToArchive && (
            <ArchiveTokenModal
              onClose={() => {
                setTokenToArchive(null);
              }}
              token={tokenToArchive}
            />
          )}
          <Table
            loading={loading}
            data={data?.Tokens || []}
            getRowTheme={(t) =>
              t.deprecated_at === null ? "enabled" : "disabled"
            }
            flexLayout
            columns={[
              {
                header: "Token name",
                id: "name",
                textWrappable: true,
                render: (t) => t.name,
                align: "left",
              },
              {
                header: "Token string",
                id: "tokenString",
                render: (t) => (
                  <HiddenAPIToken
                    token_display_suffix={t.token_display_suffix}
                  />
                ),
                align: "left",
                width: 500,
              },
              {
                header: "Created",
                id: "createdAt",
                render: (t) => renderDateTime(new Date(t.created_at), true),
                sortable: true,
                comparator: SortFunctions.Date((t) => new Date(t.created_at)),
                align: "left",
              },
              {
                header: "",
                id: "actions",
                render: (t) =>
                  t.deprecated_at === null ? (
                    <>
                      <div className="inline-flex">
                        <Tooltip content="Deactivate token">
                          <GatedIconButton
                            doc={ArchiveTokenDocument}
                            onClick={() => setTokenToArchive(t)}
                            theme="tertiary"
                            icon="trash03"
                            size="sm"
                          />
                        </Tooltip>
                      </div>
                    </>
                  ) : (
                    `Deactivated on ${renderDateTime(
                      new Date(t.deprecated_at),
                      false,
                    )}`
                  ),
                align: {
                  header: "right",
                  row: (t) => (t.deprecated_at === null ? "right" : "left"),
                },
              },
            ]}
          />
        </div>
      )}
    </PageContainer>
  );
};

export default APITokens;
