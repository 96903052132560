import React from "react";
import { BaseIntegrationCard } from "./BaseIntegrationCard";
import { ReactComponent as StripeLogo } from "tenaissance/icons/stripe.svg";
import { stripeRedirect } from "lib/stripe";
import { useEnvironment } from "lib/environmentSwitcher/context";

export const StripeIntegrationCard = () => {
  const { environmentType, environment } = useEnvironment();
  return (
    <BaseIntegrationCard
      title="Stripe"
      subheader="Billing provider"
      description="Invoice customers directly through Stripe. Configuration is handled and managed directly inside Metronome."
      buttonOptions={{
        buttonOnClick: () => stripeRedirect(environmentType, environment),
      }}
      logo={<StripeLogo />}
    />
  );
};
