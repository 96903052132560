import React, { useState } from "react";
import { Popup } from "components/Popup";
import { Copy } from "components/Copy";

import { useInsertWebhookMutation } from "../../data/webhooks.graphql";
import { Body, Input, Icon, Hyperlink } from "design-system";

import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";

import styles from "./index.module.less";

type CreateWebhookModalProps = {
  onClose: () => void;
};

// returns either an error or undefined
function validateURL(url: string | null) {
  if (url === null) {
    return;
  }

  if (url === "") {
    return "You must provide a url";
  }

  if (!/^https:\/\/.+\..+$/.test(url)) {
    return "Must be a valid HTTPS URL";
  }
}

export const CreateWebhookModal: React.FC<CreateWebhookModalProps> = ({
  onClose,
}) => {
  const [name, setName] = useState<string | null>(null);
  const [url, setURL] = useState<string | null>(null);

  const [createWebhook, createWebhookResult] = useInsertWebhookMutation({
    update(cache) {
      cache.evict({ fieldName: "Webhook" });
    },
  });

  if (createWebhookResult.data && createWebhookResult.data.create_webhook) {
    return (
      <Popup
        title="Your new webhook"
        isOpen
        onRequestClose={onClose}
        actions={
          <Button key="primary" onClick={onClose} text="Done" theme="primary" />
        }
        className={styles.webhookPopup}
      >
        <Body level={2}>
          You have successfully created your new webhook.
          <br />
          <Icon icon="informationCircle" className={styles.infoIcon} />
          You can view the webhook secret below, which can be used for verifying
          requests made to your webhook URL. To learn more about verifying
          webhooks view the{" "}
          <Hyperlink
            routePath="https://docs.metronome.com/using-the-api/webhooks"
            target="_blank"
          >
            webhooks documentation
          </Hyperlink>
          . This is the only time you'll be able to see the secret for this
          webhook.
        </Body>
        <div className={styles.secretCopyBox}>
          <Input
            name="Webhook secret"
            value={createWebhookResult.data.create_webhook.secret}
            placeholder="Webhook secret"
            disabled
            className={styles.webhookCopyValue}
          />
          <Copy text={createWebhookResult.data.create_webhook.secret}>
            <IconButton
              className={styles.secretCopyButton}
              theme="tertiary"
              icon="copy02"
            />
          </Copy>
        </div>
      </Popup>
    );
  }

  const actionButton = (
    <>
      <Button onClick={onClose} text="Cancel" theme="linkGray" />
      <Button
        onClick={async () => {
          if (!name || !url) {
            // Button should be disabled in this case, but this is to appease Typescript below.
            return;
          }
          await createWebhook({
            variables: {
              name,
              url,
            },
          });
          if (
            createWebhookResult.data &&
            !createWebhookResult.data.create_webhook
          ) {
            onClose();
          }
        }}
        disabled={
          !name ||
          createWebhookResult.loading ||
          createWebhookResult.called ||
          !!validateURL(url)
        }
        text="Create webhook"
        theme="primary"
      />
    </>
  );
  return (
    <Popup
      title="Create new webhook"
      isOpen
      onRequestClose={onClose}
      actions={actionButton}
    >
      <Body level={2}>
        This URL will receive HTTP POST requests when events occur in your
        Metronome account. To learn more about setting webhooks, view the{" "}
        <Hyperlink
          routePath="https://docs.metronome.com/using-the-api/webhooks"
          target="_blank"
        >
          webhooks documentation
        </Hyperlink>
        .
      </Body>
      <Input
        name="Name"
        value={name || ""}
        onChange={setName}
        error={name !== null && !name ? "You must provide a name" : undefined}
        placeholder="Descriptive webhook name"
        autoFocus={true}
        className={styles.nameInput}
      />
      <Input
        name="URL"
        value={url || ""}
        onChange={setURL}
        error={validateURL(url)}
        placeholder="https://example.com"
      />
    </Popup>
  );
};
