import React from "react";
import { BillingProviderSection } from "pages/Customer/tabs/Settings/sections/BillingProvider";
import { useRequiredParam } from "lib/routes/params";
import { CustomerManagementSection } from "pages/Contracts/Customer/Settings/CustomerSettings";

export const CustomerSettings: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  return (
    <div className="space-x-xl flex">
      <CustomerManagementSection customerID={customerId} classNames="w-1/2" />
      <BillingProviderSection customerID={customerId} classNames="w-1/2" />
    </div>
  );
};
