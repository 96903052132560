import { Popup } from "components/Popup";
import { useSnackbar } from "components/Snackbar";
import { FormController } from "lib/FormController";
import React, { useState } from "react";
import {
  Body,
  Select,
  Option as OptionType,
  Label,
  Tooltip,
  Icon,
} from "design-system";
import { Button } from "tenaissance/components/Button";
import styles from "./index.module.less";

import {
  ContractOverviewQuery,
  useEditContractEndDateMutation,
} from "./data.graphql";
import { Schema } from "../Create/Schema";
import { ValueContainerWithCalendar } from "components/RelativeDateRangeSelector";
import {
  MenuProps as ReactSelectMenuProps,
  SingleValueProps,
  components,
  MenuProps as SelectMenuProps,
  OptionProps,
} from "react-select";
import { renderDateTimeInUTC } from "lib/time";
import classNames from "classnames";
import { StyledDatePicker } from "components/StyledDatePicker";
import { dayjs } from "lib/dayjs";

type MenuProps = ReactSelectMenuProps<any> & {
  isCustomSelected: boolean;
  selectedDate?: Date;
  minDate: Date;
  setIsCustomSelected: (value: boolean) => void;
  onDateChange: (date?: Date) => void;
};

const Menu = (props: MenuProps) => {
  const [date, setSelectedDate] = React.useState(props.selectedDate);

  return (
    <components.Menu
      {...props}
      className={classNames(
        props.className,
        props.isCustomSelected ? styles.menu : "",
      )}
    >
      <>
        {props.isCustomSelected && (
          <div className={styles.customDatePicker}>
            <StyledDatePicker
              inline
              onChange={(date) => {
                if (date instanceof Array) {
                  throw new Error("Got a date range in single select mode");
                }
                if (date != null) {
                  setSelectedDate(date);
                }
              }}
              selected={date}
              minDate={props.minDate}
            />
          </div>
        )}
        <div className={styles.menuList}>
          {props.children}
          {props.isCustomSelected && (
            <div className="m-12 flex justify-end gap-12">
              <Button
                onClick={() => props.setIsCustomSelected(false)}
                text="Cancel"
                theme="linkGray"
              />
              <Button
                disabled={!date}
                onClick={() => props.onDateChange(date)}
                text="Apply"
                theme="primary"
              />
            </div>
          )}
        </div>
      </>
    </components.Menu>
  );
};

const useEditContractEndDateController = FormController.createHook(
  Schema.EditContractEndDateInput,
  {
    init({ endingBefore }) {
      return {
        endingBefore: endingBefore,
      };
    },
  },
);

interface EditContractEndDateModalProps {
  onSuccess: () => void;
  onCancel: () => void;
  contract: Exclude<
    Exclude<ContractOverviewQuery["customer"], null>["contract"],
    null
  >;
}

type endContractOptions = "today" | "never" | "custom";

export const EditContractEndDateModal: React.FC<
  EditContractEndDateModalProps
> = ({ onSuccess, onCancel, contract }) => {
  const pushMessage = useSnackbar();

  const [editContractEndDateMutation, { loading }] =
    useEditContractEndDateMutation();

  const today = dayjs().utc().startOf("day");
  const parsedOption =
    contract.ending_before === null
      ? "never"
      : dayjs(contract.ending_before).isSame(today)
        ? "today"
        : "custom";
  const [selectedOption, setSelectedOption] =
    useState<endContractOptions>(parsedOption);

  const ctrl = useEditContractEndDateController({
    endingBefore: contract.ending_before
      ? new Date(contract.ending_before).toISOString()
      : "",
  });

  const onSubmit = FormController.useSubmitHandler(ctrl, async (valid) => {
    try {
      const res = await editContractEndDateMutation({
        variables: {
          contract_id: contract.id,
          customer_id: contract.customer.id,
          ending_before: valid.endingBefore,
        },
      });

      if (res.data?.update_contract_end_date.id) {
        pushMessage({
          content: "Successfully updated contract end date",
          type: "success",
        });
      }

      onSuccess();
    } catch (e) {
      pushMessage({
        content: `Failed to update contract end date: ${e}`,
        type: "error",
      });
    }
  });

  return (
    <Popup
      isOpen
      onRequestClose={onCancel}
      title="Edit contract end date"
      actions={
        <>
          <Button onClick={onCancel} text="Cancel" theme="linkGray" />
          <Button
            onClick={onSubmit}
            disabled={!ctrl.appearsValid() || loading}
            loading={loading}
            text="Save"
            theme="primary"
            type="submit"
          />
        </>
      }
    >
      <form onSubmit={onSubmit}>
        <input type="submit" className="hidden" />
        <Body level={2}>
          Ending a contract early will move all subsequent dates forward. Moving
          the date into the future will only extend the contract length. Commit
          and additional term dates remain unchanged.
        </Body>
        <div className="flex flex-row content-center">
          <Label>End before 00:00 (UTC)</Label>
          <Tooltip content="Timestamp indicating when the contract will end (exclusive). If not provided, the contract will be updated to be open-ended.">
            <Icon icon="helpCircle" />
          </Tooltip>
        </div>
        <Select
          __internalComponentOverrides={{
            ValueContainer: ValueContainerWithCalendar,
            Option: (props: OptionProps<OptionType>) => (
              <components.Option
                {...props}
                innerProps={{
                  ...props.innerProps,
                  onClick: (e) => {
                    if (props.data.value === "custom") {
                      setSelectedOption("custom");
                      e.preventDefault();
                      return;
                    }
                    props.selectOption(props.data);
                  },
                }}
              />
            ),
            SingleValue: (props: SingleValueProps<OptionType>) => {
              const haveValidDate = ctrl.getValid()?.endingBefore !== undefined;
              if (selectedOption !== "custom" || !haveValidDate) {
                return <components.SingleValue {...props} />;
              }
              return (
                <components.SingleValue
                  {...{
                    ...props,
                    children: renderDateTimeInUTC(
                      dayjs(ctrl.getValid()?.endingBefore).toDate(),
                      false,
                      false,
                    ),
                  }}
                />
              );
            },
            Menu: (props: SelectMenuProps<OptionType>) => (
              <Menu
                minDate={dayjs.utc(contract.starting_at).toDate()}
                isCustomSelected={selectedOption === "custom"}
                selectedDate={
                  ctrl.getValid() !== undefined
                    ? dayjs(ctrl.getValid()?.endingBefore).toDate()
                    : undefined
                }
                onDateChange={(d) => {
                  ctrl.update({
                    endingBefore: dayjs.utc(d).startOf("day").toISOString(),
                  });
                  props.selectOption({ value: "custom", label: "Custom" });
                }}
                setIsCustomSelected={() => {
                  setSelectedOption("never");
                }}
                {...props}
              />
            ),
          }}
          onChange={(value) => {
            switch (value) {
              case "today":
                ctrl.update({
                  endingBefore: today.toISOString(),
                });
                setSelectedOption("today");
                break;
              case "never":
                ctrl.update({
                  endingBefore: undefined,
                });
                setSelectedOption("never");
                break;
              case "custom":
                setSelectedOption("custom");
                break;
              default:
                throw new Error(`Unexpected value: ${value}`);
            }
          }}
          value={selectedOption}
          placeholder="Select date range"
          options={[
            {
              label: "Today",
              value: "today",
            },
            {
              label: "No end date (perpetual)",
              value: "never",
            },
            {
              label: "Custom",
              value: "custom",
            },
          ]}
        />
      </form>
    </Popup>
  );
};
