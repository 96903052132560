import { Schema } from "../Schema";

import {
  RateCardEntryInput,
  RateCardAliasInput,
  RateCardCreditTypeConversionInput,
} from "types/generated-graphql/__types__";

const PRICE_TYPE_TO_RATE_TYPE = {
  flat: "FLAT",
  subscription: "SUBSCRIPTION",
  percentage: "PERCENTAGE",
  tiered: "TIERED",
  custom: "CUSTOM",
};

export function formRatesAsGraphqlRates(
  rates: Schema.Types.Rate[],
): RateCardEntryInput[] {
  return rates.map((r) => {
    const rateType = PRICE_TYPE_TO_RATE_TYPE[r.price.type];
    return {
      product_list_item_id: r.productId,
      effective_at: r.startingAt,
      ending_before: r.endingBefore,
      entitled: r.entitled === "enable" ? true : false,
      rate: {
        type: rateType,
        flat_rate:
          r.price.type === "flat"
            ? {
                unit_price: String(r.price.price),
                credit_type_id: r.creditType?.id,
              }
            : undefined,
        subscription_rate:
          r.price.type === "subscription"
            ? {
                unit_price: String(r.price.price),
                quantity: String(r.price.quantity),
                is_prorated: r.price.isProrated,
                credit_type_id: r.creditType?.id,
              }
            : undefined,
        percentage_rate:
          r.price.type === "percentage"
            ? {
                fraction: String(r.price.fraction / 100),
                use_list_prices: r.price.useListPrices,
              }
            : undefined,
        tiered_rate:
          r.price.type === "tiered"
            ? {
                tiers: convertToGraphqlTiers(r.price.tiers),
                credit_type_id: r.creditType?.id,
              }
            : undefined,
      },
    };
  });
}

// For the first tier, size is equal to lastUnit
// For subsequent tiers, size is lastUnit - previous tier's lastUnit
export const convertToGraphqlTiers = (tiers: Schema.Types.Tier[]) => {
  return tiers.map((t, index) => {
    let size: number | undefined;
    if (index === 0) {
      size = t.lastUnit;
    } else {
      const prevLastUnit = tiers[index - 1].lastUnit;
      size =
        t.lastUnit !== undefined && prevLastUnit !== undefined
          ? t.lastUnit - prevLastUnit
          : undefined;
    }

    return {
      size: size !== undefined ? String(size) : undefined,
      unit_price: String(t.unitPrice),
    };
  });
};

export function formAliasesAsGraphqlAliases(
  aliases: Schema.Types.RateCardAlias[],
): RateCardAliasInput[] {
  return aliases.map((a) => ({
    name: a.name,
    starting_at: a.startingAt,
    ending_before: a.endingBefore,
  }));
}

export function formCreditTypeConversionsAsGraphqlCreditTypeConversions(
  creditTypeConversions: Schema.Types.CreditTypeConversion[],
): RateCardCreditTypeConversionInput[] {
  return creditTypeConversions.map((c) => ({
    custom_credit_type_id: c.custom_credit_type_id,
    fiat_per_custom_credit: c.fiat_per_custom_credit.toString(),
  }));
}
