import React from "react";
import { InvoicesLayout } from "pages/Contracts/components/Invoices";
import { useRequiredParam } from "lib/routes/params";
import { useParams, useSearchParams } from "react-router-dom";
import { NavigateOptions, useNavigate } from "lib/useNavigate";
import { useApolloResp } from "../../../../../pages/Contracts/lib/ApolloResp";
import { useCustomerInvoicesQuery } from "./queries.graphql";
import NotFoundPage from "../../../../../pages/404";
import { reportToSentry } from "../../../../../lib/errors/sentry";

export const CustomerInvoices: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const navigate = useNavigate();
  const activeInvoiceId = useParams<{ invoiceId: string }>().invoiceId;
  const [searchParams] = useSearchParams();

  const invoicesResp = useApolloResp(
    useCustomerInvoicesQuery({ variables: { customerId } }),
  );

  function setActiveInvoiceId(invoiceId?: string, navOpts?: NavigateOptions) {
    navigate(
      `/customers/${customerId}/invoices${
        invoiceId ? `/${invoiceId}` : ""
      }?${searchParams.toString()}`,
      navOpts,
    );
  }
  const invoices =
    invoicesResp.state === "success"
      ? invoicesResp.Customer_by_pk.mri_invoices.invoices
      : [];
  React.useEffect(() => {
    if (invoices.length >= 190) {
      reportToSentry(
        new Error(
          `Contract has >= 190 invoices, consider raising MAX_PAGE on mri_invoices and limit on mri_invoices query`,
        ),
      );
    }
    if (!activeInvoiceId && invoices.length > 0) {
      setActiveInvoiceId(invoices[0].id, {
        replace: true,
      });
    }
  }, [invoices, activeInvoiceId]);
  if (invoicesResp.state === "not found") {
    return <NotFoundPage />;
  }
  return (
    <InvoicesLayout
      customerId={customerId}
      loading={invoicesResp.state === "loading"}
      error={invoicesResp.state === "error" ? invoicesResp.error : undefined}
      invoices={
        invoicesResp.state === "success"
          ? invoicesResp.Customer_by_pk.mri_invoices.invoices
          : []
      }
      activeInvoiceId={activeInvoiceId}
      setActiveInvoiceId={setActiveInvoiceId}
    />
  );
};
