import { PageContainer } from "components/PageContainer";
import { FormController } from "lib/FormController";
import { useNavigate } from "lib/useNavigate";
import React from "react";

import { Schema } from "../Schema";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { RateCardDetailsSection } from "./RateCardDetails";
import { useSnackbar } from "components/Snackbar";
import { reportToSentry } from "lib/errors/sentry";
import {
  RateCardAliasInput,
  RateCardCreditTypeConversionInput,
  RateCardEntryInput,
  RateInput,
} from "types/generated-graphql/__types__";

import { FooterBar } from "pages/Contracts/Customer/Contracts/Create/components/FooterBar";
import { Rates } from "./Rates";
import {
  useCreateRateCardMutation,
  useGetAllCreditTypesQuery,
} from "./data.graphql";
import { USD_CREDIT_TYPE } from "lib/credits";
import { filterAndSortCreditTypes } from "pages/Contracts/lib/CreditTypes";

export const useRateCardCreateController = FormController.createHook(
  Schema.RateCardInput,
  {
    init(snapshotKey: string) {
      return FormController.parseJsonSnapshot(
        Schema.RateCardInput,
        sessionStorage.getItem(snapshotKey),
      );
    },
  },
);
export const useCreditTypeConversionController =
  useRateCardCreateController.child(Schema.CreditTypeConversion, {
    read(parent, index) {
      return parent.get("creditTypeConversions")?.[index];
    },
    write(child, parent, index) {
      const existingCreditTypeConversions =
        parent.get("creditTypeConversions") ?? [];
      return {
        creditTypeConversions: existingCreditTypeConversions.map((ctc, i) =>
          i === index ? child.getUnvalidatedInputs() : ctc,
        ),
      };
    },
  });
const PRICE_TYPE_TO_RATE_TYPE = {
  flat: "FLAT",
  subscription: "SUBSCRIPTION",
  percentage: "PERCENTAGE",
  tiered: "TIERED",
  custom: "CUSTOM",
};

function convertToGraphqlRateInput(
  rate: Schema.Types.Rate,
  isCommitRate: boolean,
): RateInput {
  const rateType = PRICE_TYPE_TO_RATE_TYPE[rate.price.type];
  const price = isCommitRate ? rate.commitPrice : rate.price;
  if (!price) {
    throw new Error("A valid price is required");
  }
  return {
    type: rateType,
    flat_rate:
      price.type === "flat"
        ? {
            unit_price: String(price.price),
            credit_type_id: rate.creditType?.id,
          }
        : undefined,
    subscription_rate:
      price.type === "subscription"
        ? {
            unit_price: String(price.price),
            quantity: String(price.quantity),
            is_prorated: price.isProrated,
            credit_type_id: rate.creditType?.id,
          }
        : undefined,
    percentage_rate:
      price.type === "percentage"
        ? {
            fraction: String(price.fraction / 100),
            use_list_prices: price.useListPrices,
          }
        : undefined,
    tiered_rate:
      price.type === "tiered"
        ? {
            tiers: convertToGraphqlTiers(price.tiers),
            credit_type_id: rate.creditType?.id,
          }
        : undefined,
  };
}

export function formRatesAsGraphqlRates(
  rates: Schema.Types.Rate[],
): RateCardEntryInput[] {
  return rates.map((r) => {
    return {
      product_list_item_id: r.productId,
      effective_at: r.startingAt,
      ending_before: r.endingBefore,
      entitled: r.entitled === "enable" ? true : false,
      rate: convertToGraphqlRateInput(r, false),
      commit_rate: r.commitPrice
        ? convertToGraphqlRateInput(r, true)
        : undefined,
    };
  });
}

// For the first tier, size is equal to lastUnit
// For subsequent tiers, size is lastUnit - previous tier's lastUnit
export const convertToGraphqlTiers = (tiers: Schema.Types.Tier[]) => {
  return tiers.map((t, index) => {
    let size: number | undefined;
    if (index === 0) {
      size = t.lastUnit;
    } else {
      const prevLastUnit = tiers[index - 1].lastUnit;
      size =
        t.lastUnit !== undefined && prevLastUnit !== undefined
          ? t.lastUnit - prevLastUnit
          : undefined;
    }

    return {
      size: size !== undefined ? String(size) : undefined,
      unit_price: String(t.unitPrice),
    };
  });
};

export function formAliasesAsGraphqlAliases(
  aliases: Schema.Types.RateCardAlias[],
): RateCardAliasInput[] {
  return aliases.map((a) => ({
    name: a.name,
    starting_at: a.startingAt,
    ending_before: a.endingBefore,
  }));
}

function formCreditTypeConversionsAsGraphqlCreditTypeConversions(
  creditTypeConversions: Schema.Types.CreditTypeConversion[],
): RateCardCreditTypeConversionInput[] {
  return creditTypeConversions.map((c) => ({
    custom_credit_type_id: c.custom_credit_type_id,
    fiat_per_custom_credit: c.fiat_per_custom_credit.toString(),
  }));
}

export type RootFormController = ReturnType<typeof useRateCardCreateController>;

export const RateCardCreate: React.FC = () => {
  const navigate = useNavigate();
  const backToRateCardList = () => {
    clearSnapshot();
    navigate("/contract-pricing/rate-cards");
  };

  const snapshotKey = `rate-card-create`;
  const ctrl = useRateCardCreateController(snapshotKey);

  // save snapshot on every change of the form
  React.useEffect(() => {
    sessionStorage.setItem(snapshotKey, JSON.stringify(ctrl.snapshot()));
  }, [ctrl]);

  function clearSnapshot() {
    sessionStorage.removeItem(snapshotKey);
  }

  const [createRateCardMutation, createRateCardResult] =
    useCreateRateCardMutation();
  const pushMessage = useSnackbar();
  const onSubmit = FormController.useSubmitHandler(ctrl, async (valid) => {
    try {
      const result = await createRateCardMutation({
        variables: {
          name: valid.name.trim(),
          description: valid.description?.trim(),
          rates: formRatesAsGraphqlRates(valid.rates),
          aliases: formAliasesAsGraphqlAliases(valid.aliases ?? []),
          fiatCreditTypeId: valid.fiatCreditTypeId,
          creditTypeConversions:
            formCreditTypeConversionsAsGraphqlCreditTypeConversions(
              valid.creditTypeConversions ?? [],
            ),
        },
        update(cache) {
          cache.evict({ fieldName: "products_and_rate_cards" });
        },
      });
      const id = result.data?.create_rate_card?.id;

      if (id) {
        pushMessage({
          type: "success",
          content: "Rate card created successfully",
        });
        clearSnapshot();
        navigate(`/contract-pricing/rate-cards/${id}`);
      }
    } catch (e) {
      reportToSentry(e);
      pushMessage({
        content: `Failed to create new rate card: ${e}`,
        type: "error",
      });
    }
  });

  const { data, loading, error } = useGetAllCreditTypesQuery();
  const { fiatCreditTypes, customCreditTypes } = filterAndSortCreditTypes(
    data?.CreditType ?? [],
  );

  return (
    <PageContainer
      title="Configure your rate card"
      disableContainerScroll
      action={
        <IconButton
          onClick={backToRateCardList}
          theme="secondary"
          icon="xClose"
        />
      }
    >
      <form className="h-full" onSubmit={onSubmit}>
        <div className="-mx-12 flex h-full flex-col overflow-hidden">
          <div className="grow overflow-auto">
            <RateCardDetailsSection
              ctrl={ctrl}
              fiatCreditTypes={fiatCreditTypes}
              customCreditTypes={customCreditTypes}
              creditTypesLoading={loading}
              creditTypesError={error}
            />
            <Rates
              ctrl={ctrl}
              fiatCreditType={USD_CREDIT_TYPE}
              customCreditTypes={customCreditTypes}
              creditTypesLoading={loading}
            />
          </div>

          <FooterBar
            right={
              <>
                <Button
                  onClick={backToRateCardList}
                  text="Cancel"
                  theme="linkGray"
                />
                <Button
                  disabled={
                    !ctrl.appearsValid() || createRateCardResult.loading
                  }
                  text="Save"
                  theme="primary"
                  type="submit"
                />
              </>
            }
          ></FooterBar>
        </div>
      </form>
    </PageContainer>
  );
};
