import React, { Dispatch, SetStateAction, useState } from "react";
import { SideSheet } from "tenaissance/components/SideSheet";
import { renderDate } from "lib/time";
import { Button } from "tenaissance/components/Button";
import { useSetupAzureMarketplaceConfigMutation } from "./queries.graphql";
import { useSnackbar } from "components/Snackbar";
import { TextInput } from "tenaissance/components/Input";
import { handleCopyClick } from "pages/GeneralSettings/hooks/copyToken";

interface AzureSideSheetProps {
  onClose: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  connectedOn?: Date;
  azureClientID?: string;
  azureTenantID?: string;
}

export const AzureSideSheet = ({
  onClose,
  isOpen,
  connectedOn,
  azureClientID,
  azureTenantID,
}: AzureSideSheetProps) => {
  const pushMessage = useSnackbar();
  const [newAzureTenantId, setNewAzureTenantId] = useState(azureTenantID ?? "");
  const [newAzureClientId, setNewAzureClientId] = useState(azureClientID ?? "");
  const [azureClientSecret, setAzureClientSecret] = useState("");
  const [copiedTokenID, setCopiedTokenID] = useState<string | null>(null);
  const [setupAzureMarketplaceConfigMutation, { loading }] =
    useSetupAzureMarketplaceConfigMutation({
      update(cache) {
        cache.evict({
          fieldName: "BillingProviderToken",
        });
        cache.evict({
          fieldName: "BillingProviderCustomer",
        });
      },
    });

  const setupAzureMarketplaceConfigMutationAction = async (
    azureTenantId: string,
    azureClientId: string,
    azureClientSecret: string,
  ) => {
    try {
      if (!azureTenantId || !azureClientId || !azureClientSecret) {
        return;
      }
      await setupAzureMarketplaceConfigMutation({
        variables: {
          tenant_id: azureTenantId,
          client_id: azureClientId,
          raw_client_secret: azureClientSecret,
        },
      });
      pushMessage({
        content: "Azure Marketplace has been enabled",
        type: "success",
      });
      onClose(false);
    } catch (e: any) {
      pushMessage({
        content: e.message,
        type: "error",
      });
    }
  };

  const disableSave =
    !newAzureTenantId || !newAzureClientId || !azureClientSecret || loading;

  const trailingActionButtons:
    | [React.ReactElement, React.ReactElement]
    | undefined = [
    <Button
      key="save"
      text="Save Changes"
      disabled={disableSave}
      onClick={async () => {
        await setupAzureMarketplaceConfigMutationAction(
          newAzureTenantId,
          newAzureClientId,
          azureClientSecret,
        );
      }}
    />,
    <Button
      key="cancel"
      text="Cancel"
      theme="secondary"
      onClick={() => {
        onClose(false);
      }}
    />,
  ];

  return (
    <SideSheet
      title="Manage Azure marketplace"
      onClose={() => onClose(false)}
      isOpen={isOpen}
      supportingText={
        connectedOn
          ? `Connected on: ${renderDate(connectedOn, { isUtc: true, excludeUtcLabel: true })}`
          : ""
      }
      trailingActions={trailingActionButtons}
    >
      <div className="flex flex-shrink-0 flex-grow basis-0 flex-col items-start gap-[24px] self-stretch">
        <div className="mt-4">
          Updating the Azure Marketplace configuration will fail validation if
          any subscriptions that were created with the previous configuration do
          not have access to the new credentials.
        </div>
        <div className="gap-sm flex flex-col self-stretch">
          <TextInput
            disabled={false}
            placeholder="Enter Azure client ID"
            value={newAzureClientId}
            label="ID"
            onChange={(meta: { value: string }) =>
              setNewAzureClientId(meta.value)
            }
            variantOptions={{
              button: {
                leadingIcon: "copy01",
                disabled: !newAzureClientId,
                onClick: async () => {
                  await handleCopyClick(newAzureClientId, setCopiedTokenID);
                },
                text: copiedTokenID === newAzureClientId ? "Copied" : "Copy",
                theme: "secondary",
              },
              variant: "button",
            }}
            fullWidth={true}
          />
        </div>
        <div className="gap-sm flex flex-col self-stretch">
          <TextInput
            disabled={false}
            placeholder="Enter Azure tenant ID"
            value={newAzureTenantId}
            label="Tenant ID"
            onChange={(meta: { value: string }) =>
              setNewAzureTenantId(meta.value)
            }
            variantOptions={{
              button: {
                leadingIcon: "copy01",
                disabled: !newAzureTenantId,
                onClick: async () => {
                  await handleCopyClick(newAzureTenantId, setCopiedTokenID);
                },
                text: copiedTokenID === newAzureTenantId ? "Copied" : "Copy",
                theme: "secondary",
              },
              variant: "button",
            }}
            fullWidth={true}
          />
        </div>
        <div className="gap-sm flex flex-col self-stretch">
          <TextInput
            disabled={false}
            value={azureClientSecret}
            label="Client Secret"
            placeholder="Enter Azure client secret"
            onChange={(meta: { value: string }) =>
              setAzureClientSecret(meta.value)
            }
            type="password"
            fullWidth={true}
          />
        </div>
      </div>
    </SideSheet>
  );
};
