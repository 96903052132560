import React, { useMemo } from "react";
import { Column, Table } from "tenaissance/components/Table";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";
import { useNow } from "lib/date";
import { Badge } from "tenaissance/components/Badge";
import { getDateStringInUTC } from "lib/time";
import { Button } from "tenaissance/components/Button";
import { Dropdown, DropdownItem } from "tenaissance/components/Dropdown";
import RateCell from "./RateCell";
import {
  RateScheduleRow,
  RateScheduleTable,
} from "pages/Contracts/components/RateScheduleTable";
import {
  GetRateScheduleQuery,
  useGetRateScheduleQuery,
} from "pages/Contracts/components/RateSchedulePanel/data.graphql";

type Props = {
  rateCardId: string;
};

type SegmentProduct = {
  id: string;
  name: string;
  typeName: string;
  type: GetRateScheduleQuery["contract_pricing"]["rate_card"]["rate_schedule"]["products_on_segments"][number]["__typename"];
};

export type RateScheduleRowWithId = RateScheduleRow & { id: string };

export default function RateCardTable({ rateCardId }: Props) {
  const now = useNow();
  const { loading, data } = useGetRateScheduleQuery({
    variables: {
      rateCardId,
      limit: "1000",
    },
  });

  const productsMap = useMemo(() => {
    const products = new Map<string, SegmentProduct>();
    if (data) {
      data.contract_pricing.rate_card.rate_schedule.products_on_segments.forEach(
        (product) => {
          products.set(product.id, {
            id: product.id,
            name: ProductListItem.getName(product, now),
            typeName: ProductListItem.printType(product),
            type: product.__typename,
          });
        },
      );
    }

    return products;
  }, [data]);

  const allSegmentRows = React.useMemo(() => {
    if (!data?.contract_pricing.rate_card.rate_schedule.scalar_segments) {
      return [];
    }

    const rateSchedule = data.contract_pricing.rate_card.rate_schedule;

    return RateScheduleTable.rowsFromSegments(
      now,
      rateSchedule.scalar_segments ?? [],
      rateSchedule.products_on_segments ?? [],
      rateSchedule.credit_types_on_segments ?? [],
    ).map((row, idx) => ({ ...row, id: rateSchedule.scalar_segments[idx].id }));
  }, [now, data]);

  const columns: Column<RateScheduleRowWithId>[] = useMemo(
    () => [
      {
        id: "1",
        header: "Name & dimensional pricing values",
        accessorKey: "product.name",
        supportingText: (row) => {
          return row.pricingGroupValues
            ? Object.values(row.pricingGroupValues).join(", ")
            : "";
        },
        cell: (props: { getValue: () => string }) => {
          return props.getValue();
        },
      },
      {
        id: "2",
        header: "Type",
        accessorFn: (props) => props,
        cell: (props: { getValue: () => RateScheduleRowWithId }) => {
          const row = props.getValue();
          const product = row.product;
          if (product) {
            const getBadgeTheme = () => {
              switch (product.type) {
                case "Subscription":
                  return "indigo";
                case "Usage":
                  return "gray";
                case "Composite":
                  return "vibrant-magenta";
                default:
                  return undefined;
              }
            };

            const badgeTheme = getBadgeTheme();

            return <Badge label={product.type} theme={badgeTheme} />;
          }
          return null;
        },
      },
      {
        id: "3",
        header: "Starting at (UTC)",
        accessorKey: "startingAt",
        size: 220,
        cell: (props: { getValue(): Date }) => {
          const date = props.getValue();
          const text = date ? getDateStringInUTC(date) : "--";
          return text;
        },
      },
      {
        id: "4",
        header: "Ending before (UTC)",
        accessorKey: "endingBefore",
        size: 220,
        cell: (props: { getValue(): Date }) => {
          const date = props.getValue();
          const text = date ? getDateStringInUTC(date) : "--";
          return text;
        },
      },
      {
        id: "entitled",
        header: "Entitlement",
        accessorKey: "entitlement",
        cell: (props: { getValue(): boolean }) => {
          const entitled = props.getValue();
          return entitled ? "Enabled" : "Disabled";
        },
      },
      {
        id: "rate",
        header: "Rate",
        accessorFn: (props) => props,
        cell: (props: { getValue(): RateScheduleRowWithId }) => {
          const rateSegment = props.getValue();
          return <RateCell rateSegment={rateSegment} isCommitRate={false} />;
        },
      },
      {
        id: "actions",
        isDisplay: true,
        header: "",
        accessorFn: (props) => props,
        cell: (props: { getValue(): RateScheduleRowWithId }) => {
          const row = props.getValue();
          return (
            <Dropdown
              buttonTheme="tertiary"
              icon="dotsVertical"
              hideChevron={true}
            >
              {row.pricingGroupValues && (
                <DropdownItem
                  label="Add values to dimensional keys"
                  icon="layersThree01"
                  onClick={(data) => {
                    // TODO(ekaragiannis) - implement editing pricing values
                  }}
                  value={row.product.id}
                />
              )}
              <DropdownItem
                label="Schedule a rate change"
                value={row.product.id}
                icon="calendarPlus02"
                onClick={(data) => {
                  // TODO(ekaragiannis) - implement scheduling a rate change
                }}
              />
            </Dropdown>
          );
        },
      },
    ],
    [productsMap],
  );

  return (
    <Table
      searchOptions={{
        showSearch: true,
        onSearch: (query) => {
          // TOOD(ekaragiannis) - implment server side search
        },
      }}
      topBarActions={[
        <Button
          text="Add"
          leadingIcon="plus"
          theme="primary"
          onClick={() => {
            // TODO(ekaragiannis) - implement adding a product to the rate card
          }}
        />,
      ]}
      title="Rates"
      columns={columns}
      data={allSegmentRows}
      loading={loading}
      paginationOptions={{
        type: "prevNext",
        paginationButtons: [
          {
            page: "prev",
            onClick: () => {
              // TODO(ekaragiannis) - implement server side pagination
            },
            disabled: true,
          },
          {
            page: "next",
            disabled: true,
            onClick: async () => {
              // TODO(ekaragiannis) - implement server side pagination
            },
          },
        ],
      }}
    />
  );
}
