import React from "react";
import {
  BaseIntegrationCard,
  IntegrationCardProps,
} from "./BaseIntegrationCard";
import { ReactComponent as AWSLogo } from "tenaissance/icons/aws.svg";

type AWSIntegrationCardProps = {
  triggerSetupAction: () => void;
} & Partial<IntegrationCardProps>;

export const AwsIntegrationCard = ({
  triggerSetupAction,
  ...propOverrides
}: AWSIntegrationCardProps) => {
  return (
    <BaseIntegrationCard
      title="AWS Marketplace"
      subheader="Marketplace billing provider"
      description="The AWS marketplace enables qualified partners to market and sell their software to AWS customers."
      buttonOptions={{
        buttonOnClick: triggerSetupAction,
      }}
      {...propOverrides}
      logo={<AWSLogo />}
    />
  );
};
