import { Select, Logo, Input } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import classnames from "classnames";
import { Popup } from "components/Popup";
import { useSnackbar } from "components/Snackbar";
import { getUserFacingErrorMessage } from "lib/errors/errorHandling";
import {
  GetVendorEntityMappingsQuery,
  useSaveVendorEntityMappingsMutation,
  useDeleteVendorEntityMappingMutation,
} from "pages/GeneralSettings/queries.graphql";
import React from "react";
import { useState } from "react";
import {
  ManagedVendorEnum_Enum,
  ManagedEntityEnum_Enum,
  ManagedFieldKey,
  Maybe,
} from "types/generated-graphql/__types__";
import { SUPPORTED_ENTITIES } from "pages/GeneralSettings/components/CreateOrEditCustomFieldModal";
import styles from "./index.module.less";
import { ReactComponent as ChargebeeLogo } from "pages/GeneralSettings/chargebee.svg";
import { ReactComponent as StripeLogo } from "pages/GeneralSettings/stripe.svg";
import { ReactComponent as NetsuiteLogo } from "pages/GeneralSettings/netsuite.svg";
import { v4 as uuid } from "uuid";
import { ALLOWED_VENDOR_MAPPINGS } from "./constraints";

export type EntityMapping = GetVendorEntityMappingsQuery["vendorMappings"][0];

const VENDOR_LOGOS: Record<ManagedVendorEnum_Enum, any> = {
  [ManagedVendorEnum_Enum.Chargebee]: ChargebeeLogo,
  [ManagedVendorEnum_Enum.Stripe]: StripeLogo,
  [ManagedVendorEnum_Enum.NetSuite]: NetsuiteLogo,
};

interface EntityMappingModalProps {
  vendor: ManagedVendorEnum_Enum;
  vendorEntityMappings: Array<EntityMapping> | null;
  customFieldKeys: Array<ManagedFieldKey>;
  onClose: () => void;
  error?: Error;
  loading?: boolean;
}
export const EntityMappingModal: React.FC<EntityMappingModalProps> = ({
  vendor,
  vendorEntityMappings,
  customFieldKeys,
  onClose,
  ...props
}) => {
  const emptyEntityMapping = {
    id: uuid(),
    isNew: true,
    vendor,
    vendor_entity: "",
    vendor_field: "",
    ManagedFieldKey: {
      id: "",
    },
  } as EntityMapping & { isNew: true };

  const [currentEntityMappings, setCurrentEntityMappings] = useState<
    Array<EntityMapping>
  >(vendorEntityMappings?.length ? vendorEntityMappings : [emptyEntityMapping]);

  const [
    saveVendorEntityMappings,
    {
      loading: saveVendorEntityMappingsLoading,
      error: saveVendorEntityMappingsError,
    },
  ] = useSaveVendorEntityMappingsMutation({
    update(cache) {
      cache.evict({
        fieldName: "ManagedFieldVendorMapping",
      });
    },
  });

  const [deletedMappingIds, setDeletedMappingIds] = useState<Array<string>>([]);
  const [deleteVendorEntityMapping] = useDeleteVendorEntityMappingMutation({
    update(cache) {
      cache.evict({
        fieldName: "ManagedFieldVendorMapping",
      });
    },
  });
  const filterDeletedMapping = ({ id }: EntityMapping) =>
    !deletedMappingIds.includes(id);

  const [error, setError] = useState<Error | null>(
    props.error ?? saveVendorEntityMappingsError ?? null,
  );
  const pushMessage = useSnackbar();

  const loading = props.loading || saveVendorEntityMappingsLoading;

  // NOTE: "mappingsEdited" is set when mappings are created/deleted too
  const [mappingsEdited, setMappingsEdited] = useState<boolean>(false);
  const actionButtons = (
    <>
      <Button onClick={onClose} text="Cancel" theme="secondary" />
      <Button
        onClick={async () => {
          try {
            if (currentEntityMappings) {
              validateManagedFieldVendorMappings(
                currentEntityMappings,
                deletedMappingIds,
              );
              await Promise.all(
                deletedMappingIds.map(async (mappingId) =>
                  deleteVendorEntityMapping({
                    variables: {
                      mapping_id: mappingId,
                    },
                  }),
                ),
              );
              await saveVendorEntityMappings({
                variables: {
                  objects: currentEntityMappings
                    .filter(filterDeletedMapping)
                    .map((mapping) => ({
                      key_id: mapping.ManagedFieldKey.id,
                      entity: mapping.entity,
                      vendor: mapping.vendor,
                      vendor_entity: mapping.vendor_entity,
                      vendor_field: mapping.vendor_field,
                    })),
                },
              });
            }
            onClose();
            pushMessage({
              type: "success",
              content: `Entity mapping for ${vendor} saved`,
              durationMS: 3000,
            });
          } catch (e) {
            pushMessage({
              type: "error",
              content: `Couldn't save mapping: ${getErrorMessage(e)}`,
              durationMS: 5000,
            });
          }
        }}
        loading={loading}
        disabled={
          !allowSaveMappings({
            error,
            loading,
            hasEditedMappings: mappingsEdited,
            currentMappings: currentEntityMappings,
            deletedMappingIds,
          })
        }
        text="Save"
        theme="primary"
      />
    </>
  );

  const VendorLogo = VENDOR_LOGOS[vendor];
  return (
    <Popup
      actions={actionButtons}
      isOpen={true}
      onRequestClose={onClose}
      title={`Edit ${vendor} mapping`}
      className={styles.popover}
    >
      <div className="flex flex-row-reverse">
        <div
          className={classnames(
            styles.mappingTitle,
            "flex w-1/2 flex-row items-center text-base",
          )}
        >
          <Logo
            className={styles.mappingLogo}
            width="30px"
            height="30px"
          ></Logo>
          Metronome
        </div>
        <div
          className={classnames(
            styles.mappingTitle,
            "flex w-1/2 flex-row items-center text-base",
          )}
        >
          <VendorLogo
            className={styles.mappingLogo}
            width="30px"
            height="30px"
          ></VendorLogo>
          {vendor}
        </div>
      </div>
      <div className={classnames(styles.formGroup)}>
        {currentEntityMappings
          .filter(filterDeletedMapping)
          .map((mapping: Maybe<EntityMapping> & { isNew?: boolean }, i) => (
            <MappingRow
              key={i}
              mapping={mapping}
              isNew={mapping["isNew"] ?? false}
              allMappings={currentEntityMappings}
              entityFieldKeysToIDs={Object.fromEntries(
                customFieldKeys
                  .filter((k) => k.entity == mapping.entity)
                  .map((k) => [k.key, k.id]),
              )}
              onUpdate={(updatedMappings: EntityMapping[]) => {
                setCurrentEntityMappings(
                  updatedMappings.filter(filterDeletedMapping),
                );
                setError(null);
                setMappingsEdited(true);
              }}
              onDelete={(deletedId: string) => {
                setDeletedMappingIds((ids) => [...ids, deletedId]);
                setMappingsEdited(true);
              }}
              error={error ?? undefined}
            />
          ))}
        <div className="ml-6 m-12 flex flex-col items-end">
          <Button
            onClick={() => {
              setCurrentEntityMappings((oldState) => {
                return [...oldState, emptyEntityMapping];
              });
            }}
            text="Add Mapping"
            theme="secondary"
            leadingIcon="plus"
          />
        </div>
      </div>
    </Popup>
  );
};

export function allowSaveMappings({
  error,
  loading,
  hasEditedMappings,
  currentMappings,
  deletedMappingIds,
}: {
  error: Error | null;
  loading: boolean;
  hasEditedMappings: boolean;
  currentMappings: EntityMapping[];
  deletedMappingIds: string[];
}) {
  if (error || loading) {
    return false;
  }
  // any mappings with blank fields?
  const hasIncompleteMappings = !!currentMappings
    .filter(({ id }: EntityMapping) => !deletedMappingIds.includes(id))
    .find(
      (m) => !m?.ManagedFieldKey?.id || !m.vendor_entity || !m.vendor_field,
    );
  return !hasIncompleteMappings && hasEditedMappings;
}

const MappingRow: React.FC<{
  mapping: EntityMapping & { isNew?: boolean };
  allMappings: EntityMapping[];
  entityFieldKeysToIDs: Record<string, string>; // map selected ManagedFieldKey value to key_id
  onUpdate: (ms: EntityMapping[]) => void;
  onDelete: (mappingId: string) => void;
  isNew: boolean;
  error?: Error;
}> = ({
  mapping,
  allMappings,
  entityFieldKeysToIDs,
  onUpdate,
  onDelete,
  error,
  isNew,
}) => {
  const updateMapping = (updatedFields: Partial<EntityMapping>) => {
    const updatedMapping = { ...mapping, ...updatedFields };
    const updatedMappings = allMappings.map((m) => {
      if (m.id === updatedMapping.id) {
        return updatedMapping;
      } else {
        return m;
      }
    });
    onUpdate(updatedMappings);
  };

  const vendor = mapping.vendor as ManagedVendorEnum_Enum;
  const {
    supported_entities: supportedEntities,
    allowed_vendor_fields: allowedVendorFields,
  } = ALLOWED_VENDOR_MAPPINGS[vendor].find(
    ({ vendor_entity }) => mapping.vendor_entity === vendor_entity,
  ) || { supported_entities: [], allowed_vendor_fields: [] };
  const allowedVendorEntities = ALLOWED_VENDOR_MAPPINGS[vendor].map(
    (v) => v.vendor_entity,
  );

  const toOption = (v: string) => ({
    label: v,
    value: v,
  });
  return (
    <div
      className={classnames(styles.mappingRow, "my-12 flex flex-row-reverse")}
    >
      <div className="flex items-center py-4">
        <IconButton
          className={styles.deleteMapping}
          onClick={async () => onDelete(mapping.id)}
          theme="tertiary"
          icon="xClose"
        />
      </div>
      <div className="flex-column flex w-1/2">
        <div className="flex flex-row">
          <Select
            name="Entity"
            placeholder="Select entity"
            className={classnames(styles.select, "w-1/2")}
            options={supportedEntities?.map(toOption) ?? SUPPORTED_ENTITIES}
            value={mapping.entity}
            onChange={(value: string) =>
              updateMapping({
                entity: value as ManagedEntityEnum_Enum,
                ManagedFieldKey: undefined,
              })
            }
          />
          <Select
            name="Key"
            placeholder="Select key"
            className={classnames(styles.select, "w-1/2")}
            options={Object.keys(entityFieldKeysToIDs ?? {}).map(toOption)}
            noOptionsMessage={
              mapping.entity
                ? `No custom field keys created for ${mapping.entity}`
                : `Select entity first`
            }
            value={mapping?.ManagedFieldKey?.key}
            onChange={(value: string) =>
              updateMapping({
                entity: mapping.entity,
                ManagedFieldKey: {
                  key: value,
                  id: entityFieldKeysToIDs[value],
                },
              })
            }
          />
        </div>
      </div>
      <div className={classnames(styles.spacer)}></div>
      <div className="flex-column flex w-1/2">
        <div className="flex flex-row">
          <Select
            name="Entity"
            placeholder="Select vendor entity"
            tooltip={
              !isNew ? "Entity is read-only after it's created" : undefined
            }
            className={classnames(styles.select, "w-1/2")}
            options={allowedVendorEntities.map(toOption)}
            disabled={!isNew}
            value={mapping.vendor_entity}
            onChange={(value: string) =>
              updateMapping({
                vendor_entity: value,
                vendor_field: "",
                entity: undefined,
                ManagedFieldKey: undefined,
              })
            }
          />
          {allowedVendorFields ? (
            <Select
              name="Key"
              placeholder="Select vendor key"
              tooltip={
                !isNew ? "Key is read-only after it's created" : undefined
              }
              className={classnames(styles.select, "w-1/2")}
              options={allowedVendorFields.map(toOption)}
              disabled={!isNew}
              value={mapping.vendor_field}
              onChange={(value: string) =>
                updateMapping({ vendor_field: value })
              }
              error={error ? getErrorMessage(error) : undefined}
            />
          ) : (
            <Input
              name="Key"
              placeholder="Select vendor key"
              tooltip={
                !isNew ? "Key is read-only after it's created" : undefined
              }
              className={classnames(
                styles.select,
                styles.vendorKeyFreeform,
                "w-1/2",
              )}
              disabled={!isNew}
              value={mapping.vendor_field}
              onChange={(value: string) =>
                updateMapping({ vendor_field: value })
              }
              error={error ? getErrorMessage(error) : undefined}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const MAX_LENGTH_MANAGED_FIELD_VENDOR_MAPPINGS = 50;
function validateManagedFieldVendorMappings(
  objects: Array<EntityMapping>,
  deletedMappingIds: Array<string>,
): void {
  if (objects.length > MAX_LENGTH_MANAGED_FIELD_VENDOR_MAPPINGS) {
    throw new Error(
      `Cannot have more than ${MAX_LENGTH_MANAGED_FIELD_VENDOR_MAPPINGS} managed field vendor mappings`,
    );
  }
  const seenVendorTuples = new Set();
  for (const object of objects.filter(
    ({ id }: EntityMapping) => !deletedMappingIds.includes(id),
  )) {
    const { vendor, vendor_entity, vendor_field } = object;
    const vendorTuple = JSON.stringify([vendor, vendor_entity, vendor_field]);
    if (seenVendorTuples.has(vendorTuple)) {
      throw new Error(
        `Cannot have multiple mappings to ${vendor} field ${vendor_entity} ${vendor_field}`,
      );
    }
    seenVendorTuples.add(vendorTuple);
  }
}

function getErrorMessage(e: any): string {
  const isDuplicateKeyError = getUserFacingErrorMessage(e).includes(
    "Uniqueness violation",
  );
  const KNOWN_ERROR_MSGS = ["Cannot have multiple mappings"];

  if (isDuplicateKeyError) {
    // because the error from resolvers isn't user-friendly
    return "A mapping to this vendor entity & field already exists";
  } else if (KNOWN_ERROR_MSGS.map((msg) => e.message.includes(msg))) {
    // pass certain errors through
    return e.message;
  } else {
    return "Unknown error occurred while saving entity mappings";
  }
}
