import React from "react";

import { useNavigate } from "lib/useNavigate";

import { AppShell, PageContainer } from "components/PageContainer";
import { EmptyState } from "components/EmptyState";
import { useUIMode } from "../../lib/useUIMode";

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const { newUIEnabled } = useUIMode();
  const pageContents = (
    <EmptyState
      title="404 — Page not found."
      subtitle="Oops, we couldn’t find the page you’re looking for."
      buttonText="View customers"
      onClick={() => navigate("/")}
      icon="faceFrown"
    />
  );
  return newUIEnabled ? (
    <AppShell title="404" hideHeader>
      {pageContents}
    </AppShell>
  ) : (
    <PageContainer title="404" hideHeader>
      {pageContents}
    </PageContainer>
  );
};

export default NotFoundPage;
