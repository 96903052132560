/* 4f555c65a80e35ae146e767dcbd8d7c2f3cf7c96
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCustomersWithStripeEnabledCountQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type GetCustomersWithStripeEnabledCountQuery = { __typename?: 'Query', Customer_aggregate: { __typename?: 'Customer_aggregate', aggregate: { __typename?: 'Customer_aggregate_fields', count: number } | null } };

export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation = { __typename?: 'Mutation', delete_BillingProviderToken: { __typename?: 'BillingProviderToken_mutation_response', returning: Array<{ __typename?: 'BillingProviderToken', account_id: string }> } | null, delete_BillingProviderCustomer: { __typename?: 'BillingProviderCustomer_mutation_response', returning: Array<{ __typename?: 'BillingProviderCustomer', billing_provider_customer_id: string }> } | null };


export const GetCustomersWithStripeEnabledCountDocument = gql`
    query GetCustomersWithStripeEnabledCount($environment_type: EnvironmentTypeEnum_enum!) {
  Customer_aggregate(
    where: {BillingProviderCustomers: {billing_provider: {_eq: STRIPE}}, environment_type: {_eq: $environment_type}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCustomersWithStripeEnabledCountQuery__
 *
 * To run a query within a React component, call `useGetCustomersWithStripeEnabledCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersWithStripeEnabledCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersWithStripeEnabledCountQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useGetCustomersWithStripeEnabledCountQuery(baseOptions: Apollo.QueryHookOptions<GetCustomersWithStripeEnabledCountQuery, GetCustomersWithStripeEnabledCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersWithStripeEnabledCountQuery, GetCustomersWithStripeEnabledCountQueryVariables>(GetCustomersWithStripeEnabledCountDocument, options);
      }
export function useGetCustomersWithStripeEnabledCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersWithStripeEnabledCountQuery, GetCustomersWithStripeEnabledCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersWithStripeEnabledCountQuery, GetCustomersWithStripeEnabledCountQueryVariables>(GetCustomersWithStripeEnabledCountDocument, options);
        }
export type GetCustomersWithStripeEnabledCountQueryHookResult = ReturnType<typeof useGetCustomersWithStripeEnabledCountQuery>;
export type GetCustomersWithStripeEnabledCountLazyQueryHookResult = ReturnType<typeof useGetCustomersWithStripeEnabledCountLazyQuery>;
export type GetCustomersWithStripeEnabledCountQueryResult = Apollo.QueryResult<GetCustomersWithStripeEnabledCountQuery, GetCustomersWithStripeEnabledCountQueryVariables>;
export const DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersDocument = gql`
    mutation DeleteBillingProviderTokenAndAllStripeBillingProviderCustomers {
  delete_BillingProviderToken(where: {billing_provider: {_eq: STRIPE}}) {
    returning {
      account_id
      __environment_type: environment_type
    }
  }
  delete_BillingProviderCustomer(where: {billing_provider: {_eq: STRIPE}}) {
    returning {
      billing_provider_customer_id
    }
  }
}
    `;
export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationFn = Apollo.MutationFunction<DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation, DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationVariables>;

/**
 * __useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation__
 *
 * To run a mutation, you first call `useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation, { data, loading, error }] = useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation, DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation, DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationVariables>(DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersDocument, options);
      }
export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationHookResult = ReturnType<typeof useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation>;
export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationResult = Apollo.MutationResult<DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation>;
export type DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationOptions = Apollo.BaseMutationOptions<DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation, DeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutationVariables>;