import React from "react";
import classnames from "classnames";
import { useNavigate } from "lib/useNavigate";
import { InternalLink } from "components/Typography";

import { Avatar, Icon, IconName } from "design-system";

import { useCurrentUser } from "lib/auth";
import { useRoutePathMatches } from "lib/routes/useRoutePathMatches";

import { Logo } from "components/Logo";
import styles from "./index.module.less";
import { PopoverMenu } from "components/PopoverMenu";
import { Caption } from "design-system";
import { useHasAccessToAlerts } from "../../lib/alerts";
import EnvironmentSwitcher from "lib/environmentSwitcher";
import { useContractsEnabled } from "lib/contracts/useContractsEnabled";
import { ContractsEnabledSwitch } from "lib/contracts/ContractsEnabledSwitch";
import { useHasAccessToReports } from "lib/reports";
import { useLDClient } from "launchdarkly-react-client-sdk";

interface SidebarProps {}

export const Sidebar: React.FC<SidebarProps> = () => {
  const contractsEnabled = useContractsEnabled();
  const alertsEnabled = useHasAccessToAlerts();
  const ldClient = useLDClient();
  const reportsEnabled = useHasAccessToReports(ldClient);

  return (
    <div className={styles.sidebar}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.content}>
        <SidebarActionGroup heading="Dashboard">
          <SidebarAction
            icon="people"
            text="Customers"
            routePath={contractsEnabled ? "/customers" : "/overview"}
            activePatterns={["/overview", "/customers/*"]}
          />
          {alertsEnabled && (
            <SidebarAction
              icon="notifications"
              text="Alerts"
              routePath="/alerts"
            />
          )}
          {reportsEnabled && (
            <SidebarAction
              icon="newspaper"
              text="Reports"
              routePath="/reports"
            />
          )}
        </SidebarActionGroup>

        {contractsEnabled && (
          <SidebarActionGroup heading="Pricing & Packaging">
            <SidebarAction
              icon="receipt"
              text="Contract pricing"
              routePath="/contract-pricing/rate-cards"
              activePatterns={["/contract-pricing/*"]}
            />
            <SidebarAction
              icon="cart"
              text="Plan pricing"
              routePath="/plans"
              activePatterns={["/products/*", "/plans/*"]}
            />
            <SidebarAction
              icon="analyticsSharp"
              text="Billable metrics"
              routePath="/billable-metrics"
            />
          </SidebarActionGroup>
        )}
        {!contractsEnabled && (
          <SidebarActionGroup heading="Billing">
            <SidebarAction icon="receipt" text="Plans" routePath="/plans" />
            <SidebarAction icon="cart" text="Products" routePath="/products" />
            <SidebarAction
              icon="analyticsSharp"
              text="Billable metrics"
              routePath="/billable-metrics"
            />
          </SidebarActionGroup>
        )}
        <SidebarActionGroup heading="Developer">
          <SidebarAction
            icon="layers"
            text="Events"
            routePath="/developer/events"
          />
          <SidebarAction
            icon="key"
            text="API tokens"
            routePath="/developer/tokens"
          />
          <SidebarAction
            icon="gitNetwork"
            text="Webhooks"
            routePath="/developer/webhooks"
          />
        </SidebarActionGroup>
        <SidebarActionGroup heading="Settings">
          <SidebarAction
            icon="browsers"
            text="General settings"
            routePath="/settings/general"
          />
          <SidebarAction
            icon="personCircle"
            text="Team"
            routePath="/settings/team"
          />
          <SidebarAction
            icon="book"
            text="Documentation"
            routePath="https://docs.metronome.com"
          />
        </SidebarActionGroup>
      </div>
      <EnvironmentSwitcher />
      <ContractsEnabledSwitch />
      <UserBox />
    </div>
  );
};

interface ActionGroupProps extends React.PropsWithChildren {
  heading: string;
}

const SidebarActionGroup: React.FC<ActionGroupProps> = ({
  heading,
  children,
}) => {
  return (
    <div className={styles.sidebarActionGroup}>
      <Caption>{heading}</Caption>
      <div className={styles.actions}>{children}</div>
    </div>
  );
};

interface SidebarActionProps {
  icon: IconName;
  text: string;
  routePath: string;
  className?: string;
  activePatterns?: string[];
}

// this needs to be a ref forwarding component so that it can attach a popover
const SidebarAction: React.FC<SidebarActionProps> = ({
  icon,
  text,
  routePath,
  className,
  activePatterns,
}) => {
  const active = useRoutePathMatches(activePatterns ?? [`${routePath}/*`]);

  if (routePath.startsWith("https://") || routePath.startsWith("http://")) {
    return (
      <a
        className={classnames(styles.sidebarAction, className)}
        href={routePath}
        target="_blank"
      >
        <Icon icon={icon} className={styles.icon} />
        {text}
        <Icon icon="openOutline" className={styles.rightIcon} />
      </a>
    );
  }

  const classes = classnames(styles.sidebarAction, className, {
    [styles.active]: active,
  });

  return (
    <InternalLink className={classes} routePath={routePath}>
      <Icon icon={icon} className={styles.icon} />
      {text}
    </InternalLink>
  );
};

const UserBox: React.FC<{}> = () => {
  const userInfo = useCurrentUser();
  const navigate = useNavigate();

  if (userInfo.loading) {
    return null;
  }

  return (
    <div className={styles.userBoxContainer}>
      <PopoverMenu
        positions={["top"]}
        align="end"
        options={[
          {
            content: (
              <>
                <Icon className={styles.paddedIcon} icon="settingsOutline" />
                Account settings
              </>
            ),
            onClick: () => navigate("/account"),
          },
          {
            content: (
              <>
                <Icon className={styles.paddedIcon} icon="logOut" />
                Logout
              </>
            ),
            onClick: () => navigate("/logout"),
          },
        ]}
      >
        {(onClick) => (
          <div className={styles.userBox} onClick={onClick}>
            <div className={styles.avatar}>
              <Avatar
                size={32}
                userName={userInfo.user?.name ?? ""}
                userID={userInfo.user?.id ?? ""}
              />
            </div>
            <span className={styles.label}>{userInfo.user?.name ?? ""}</span>
            <Icon className={styles.userBoxIcon} icon="settingsOutline" />
          </div>
        )}
      </PopoverMenu>
    </div>
  );
};
