import React from "react";
import {
  useGetClientConfigQuery,
  useSaveClientConfigMutation,
  GetClientConfigQuery,
  GetClientConfigQueryVariables,
  GetClientConfigDocument,
  SaveClientConfigDocument,
} from "../../queries.graphql";
import { useSnackbar } from "components/Snackbar";
import { Checkbox } from "design-system";
import { ClientConfigKeyEnum_Enum } from "types/generated-graphql/__types__";
import { StripeSetting } from "../StripeSetting";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { useAuthCheck } from "lib/useAuthCheck";

export const ClientConfigCheckbox: React.FC<{
  clientConfigKey: ClientConfigKeyEnum_Enum;
  successMessages: {
    true: string;
    false: string;
  };
  errorMessage: string;
  onErrorLoadingData: (error: boolean) => void;
  label: string;
  helpText: string;
}> = ({
  clientConfigKey,
  successMessages,
  errorMessage,
  onErrorLoadingData,
  label,
  helpText,
}) => {
  const { environmentType } = useEnvironment();
  const canSaveClientConfig = !!useAuthCheck(SaveClientConfigDocument, true, {
    client_config: clientConfigKey,
  }).allowed;

  const pushMessage = useSnackbar();
  const { data, loading, error } = useGetClientConfigQuery({
    variables: {
      environment_type: environmentType,
      client_config: clientConfigKey,
    },
  });

  if (error) {
    onErrorLoadingData(true);
  }

  const value = (data?.ClientConfig?.[0]?.value ?? "false") === "true";

  const [saveClientConfig] = useSaveClientConfigMutation();
  const checkbox = (
    <Checkbox
      label={label}
      checked={value}
      onChange={async (value) => {
        try {
          await saveClientConfig({
            variables: {
              environment_type: environmentType,
              client_config: clientConfigKey,
              value: value.toString(),
            },
            optimisticResponse: {
              insert_ClientConfig_one: {
                __typename: "ClientConfig",
                id: data?.ClientConfig?.[0]?.id ?? "new",
                value: value.toString(),
              },
            },
            update: (store, { data }) => {
              if (!data?.insert_ClientConfig_one) {
                return;
              }
              store.writeQuery<
                GetClientConfigQuery,
                GetClientConfigQueryVariables
              >({
                data: {
                  ClientConfig: [data.insert_ClientConfig_one],
                },
                query: GetClientConfigDocument,
                variables: {
                  environment_type: environmentType,
                  client_config: clientConfigKey,
                },
              });
            },
          });

          pushMessage({
            content: successMessages[value ? "true" : "false"],
            type: "success",
          });
        } catch (error) {
          pushMessage({
            content: errorMessage,
            type: "error",
          });
        }
      }}
      disabled={loading || !canSaveClientConfig}
    />
  );
  return <StripeSetting setting={checkbox} helpText={helpText} />;
};
