import React, { Dispatch, SetStateAction } from "react";
import { BaseIntegrationCard } from "./BaseIntegrationCard";
import { ReactComponent as MicrosoftLogo } from "tenaissance/icons/microsoft.svg";

interface AzureIntegrationCardProps {
  setAzureMarketplaceConfigModalIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const AzureIntegrationCard = ({
  setAzureMarketplaceConfigModalIsOpen,
}: AzureIntegrationCardProps) => {
  return (
    <BaseIntegrationCard
      title="Azure Marketplace"
      subheader="Marketplace billing provider"
      description="The Azure marketplace enables qualified partners to market and sell their software to Azure customers."
      buttonOptions={{
        buttonOnClick: () => setAzureMarketplaceConfigModalIsOpen(true),
      }}
      logo={<MicrosoftLogo />}
    />
  );
};
