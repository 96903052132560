import React from "react";
import { useGigaRateCardEnabled } from "./GigaRateCardUsage";

interface Props {
  enabled: React.ReactElement<any, any> | null;
  disabled: React.ReactElement<any, any> | null;
}

export const GigaRateCardSwitch: React.FC<Props> = ({ enabled, disabled }) => {
  return useGigaRateCardEnabled() ? enabled : disabled;
};
