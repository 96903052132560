import React, { useState } from "react";
import { Toggle } from "design-system";
import { StripeSettingsToggleProps } from ".";

export const DisplaySublineItemQuantitiesToggle: React.FC<
  StripeSettingsToggleProps
> = ({
  isChecked,
  handleToggleOnChange,
  displayLineItems,
  canSaveClientConfig,
  hasContractsEnabled,
}) => {
  const [isCheckedValue, setIsCheckedValue] = useState(isChecked);

  return (
    <>
      <Toggle
        checked={isCheckedValue}
        label="Always display quantities in sub-line item descriptions"
        onChange={(value) =>
          handleToggleOnChange(
            value,
            "stripe_invoice_quantity_always_string",
            setIsCheckedValue,
          )
        }
        disabled={
          (!displayLineItems && !hasContractsEnabled) || !canSaveClientConfig
        }
      />
      <div className="leading-tight text-sm font-normal text-gray-600">
        Use if you have non-integer items to display
      </div>
    </>
  );
};
