import { GetBillingProvidersSettingsQuery } from "../../pages/Customer/tabs/Settings/sections/BillingProvider/queries.graphql";
import { parseISO } from "date-fns";
import { BillingProviderEnum_Enum } from "../../types/generated-graphql/__types__";

export type SalesforceConnectionCustomerSettings = {
  salesforceAccountId?: string;
  connectionDate?: Date;
};

export type NetSuiteConnectionCustomerSettings = {
  netSuiteCustomerId?: string;
  connectionDate?: Date;
};

export type AWSMarketplaceCustomerSettings = {
  customerId?: string;
  productCode?: string;
  region?: string;
  expirationTimestamp?: Date;
  connectionDate?: Date;
  meteringDisabled?: boolean;
  isSubscriptionProduct?: boolean;
};

export type AzureMarketplaceCustomerSettings = {
  customerId?: string;
  subscriptionId?: string;
  subscriptionStatus?: string;
  planId?: string;
  startDate?: Date;
  expirationDate?: Date;
  connectionDate?: Date;
  meteringDisabled?: boolean;
};

export type StripeCustomerSettings = {
  customerId?: string;
  collectionMethod?: string;
  connectionDate?: Date;
};

export type GcpCustomerSettings = {
  connectionDate?: Date;
  expirationDate?: Date;
};

export type FirstClassIntegrations =
  | BillingProviderEnum_Enum.AwsMarketplace
  | BillingProviderEnum_Enum.Azure
  | BillingProviderEnum_Enum.Gcp
  | BillingProviderEnum_Enum.Stripe;

export type MultiBPCustomerSettings<
  T extends
    | AzureMarketplaceCustomerSettings
    | AWSMarketplaceCustomerSettings
    | StripeCustomerSettings
    | NetSuiteConnectionCustomerSettings
    | SalesforceConnectionCustomerSettings,
> = (T & {
  customerBillingProviderConfigurationID?: string;
})[];

export type CustomerIntegrationSettingsInfo = {
  connectedBPs?: FirstClassIntegrations[];

  clientHasAws: boolean;
  awsMultiSettings: MultiBPCustomerSettings<AWSMarketplaceCustomerSettings>;

  clientHasAzure: boolean;
  azureMultiSettings: MultiBPCustomerSettings<AzureMarketplaceCustomerSettings>;

  clientHasStripe: boolean;
  stripeMultiSettings: MultiBPCustomerSettings<StripeCustomerSettings>;

  salesforceConnectionSettings: SalesforceConnectionCustomerSettings;
  netSuiteConnectionSettings: NetSuiteConnectionCustomerSettings;
};

export const parseIntegrationCustomerSettings = (
  data: GetBillingProvidersSettingsQuery,
): CustomerIntegrationSettingsInfo => {
  const { clientHasStripe, stripeSettings } = parseStripeCustomerSettings(
    data.stripe_billing_provider_configs,
    data.billingProviderTokens,
    data.customer?.BillingProviderCustomers ?? [],
    data.customerConfigs,
  );
  const { clientHasAws, awsSettings } = parseAWSMarketplaceCustomerSettings(
    data.aws_billing_provider_configs,
    data.billingProviderTokens,
    data.customer?.BillingProviderCustomers ?? [],
    data.customerConfigs,
  );
  const { clientHasAzure, azureSettings } =
    parseAzureMarketplaceCustomerSettings(
      data.azure_billing_provider_configs,
      data.billingProviderTokens,
      data.customer?.BillingProviderCustomers ?? [],
      data.customerConfigs,
    );
  const { gcpSettings } = parseGcpCustomerSettings(
    data.billingProviderTokens,
    data.customer?.BillingProviderCustomers ?? [],
    data.customerConfigs,
  );
  const salesforceConnectionSettings =
    parseSalesforceConnectionCustomerSettings(data.customerConfigs);
  const netSuiteConnectionSettings = parseNetSuiteConnectionCustomerSettings(
    data.customerConfigs,
  );

  const connectedBPs = getConnectedBillingProviders({
    awsSettings,
    azureSettings,
    stripeSettings,
    gcpSettings,
  });

  return {
    connectedBPs,
    clientHasAws,
    awsMultiSettings: awsSettings,
    clientHasAzure,
    azureMultiSettings: azureSettings,
    clientHasStripe,
    stripeMultiSettings: stripeSettings,
    salesforceConnectionSettings,
    netSuiteConnectionSettings,
  };
};

export const billingProviderToName: Record<FirstClassIntegrations, string> = {
  [BillingProviderEnum_Enum.Azure]: "Azure",
  [BillingProviderEnum_Enum.AwsMarketplace]: "AWS",
  [BillingProviderEnum_Enum.Stripe]: "Stripe",
  [BillingProviderEnum_Enum.Gcp]: "GCP",
};

function getConnectedBillingProviders({
  azureSettings,
  awsSettings,
  stripeSettings,
  gcpSettings,
}: {
  azureSettings:
    | MultiBPCustomerSettings<AzureMarketplaceCustomerSettings>
    | undefined;
  awsSettings:
    | MultiBPCustomerSettings<AWSMarketplaceCustomerSettings>
    | undefined;
  stripeSettings: MultiBPCustomerSettings<StripeCustomerSettings> | undefined;
  gcpSettings: MultiBPCustomerSettings<GcpCustomerSettings> | undefined;
}): FirstClassIntegrations[] {
  const connectedBPs: FirstClassIntegrations[] = [];
  for (const bpSettings of [
    azureSettings,
    awsSettings,
    stripeSettings,
    gcpSettings,
  ].flat()) {
    if (bpSettings?.connectionDate) {
      if ("subscriptionId" in bpSettings && bpSettings?.subscriptionId) {
        connectedBPs.push(BillingProviderEnum_Enum.Azure);
      } else if ("productCode" in bpSettings && bpSettings?.productCode) {
        connectedBPs.push(BillingProviderEnum_Enum.AwsMarketplace);
      } else if (
        "collectionMethod" in bpSettings &&
        bpSettings?.collectionMethod
      ) {
        connectedBPs.push(BillingProviderEnum_Enum.Stripe);
      } else if ("expirationDate" in bpSettings && bpSettings?.expirationDate) {
        connectedBPs.push(BillingProviderEnum_Enum.Gcp);
      }
    }
  }
  return connectedBPs;
}

export function parseStripeCustomerSettings(
  stripe_billing_provider_configs: GetBillingProvidersSettingsQuery["stripe_billing_provider_configs"],
  billingProviderTokens: GetBillingProvidersSettingsQuery["billingProviderTokens"],
  billingProviderCustomers: NonNullable<
    GetBillingProvidersSettingsQuery["customer"]
  >["BillingProviderCustomers"],
  customerConfigs: GetBillingProvidersSettingsQuery["customerConfigs"],
): {
  stripeSettings: (StripeCustomerSettings & {
    customerBillingProviderConfigurationID?: string;
  })[];
  clientHasStripe: boolean;
} {
  let clientHasStripe = false;
  const stripeSettings: (StripeCustomerSettings & {
    customerBillingProviderConfigurationID?: string;
  })[] = [];

  if (stripe_billing_provider_configs?.length) {
    clientHasStripe = true;

    for (const stripeBillingProviderConfig of stripe_billing_provider_configs) {
      const stripeConfig = stripeBillingProviderConfig.configuration;
      stripeSettings.push({
        customerId: stripeConfig.stripe_customer_id.toString(),
        collectionMethod: stripeConfig.stripe_collection_method.toString(),
        connectionDate: parseISO(stripeBillingProviderConfig.created_at),
        customerBillingProviderConfigurationID:
          stripeBillingProviderConfig.id.toString(),
      });
    }
  } else {
    const stripeToken = billingProviderTokens?.find(
      (t) => t.billing_provider === BillingProviderEnum_Enum.Stripe,
    );

    if (stripeToken) {
      clientHasStripe = true;
    }

    const stripeBillingProviderCustomer = billingProviderCustomers?.find(
      (bpCustomer) =>
        bpCustomer.billing_provider === BillingProviderEnum_Enum.Stripe,
    );

    const stripeSettingsForPlan: StripeCustomerSettings = {};
    if (stripeBillingProviderCustomer) {
      stripeSettingsForPlan.customerId =
        stripeBillingProviderCustomer.billing_provider_customer_id;
      stripeSettingsForPlan.connectionDate = parseISO(
        stripeBillingProviderCustomer?.created_at,
      );
    }

    const stripeCollectionMethodConfig = customerConfigs?.find(
      (customerConfig) => customerConfig.key === "stripe_collection_method",
    );

    if (stripeCollectionMethodConfig) {
      stripeSettingsForPlan.collectionMethod =
        stripeCollectionMethodConfig.value;
    }
    if (Object.keys(stripeSettingsForPlan).length > 0) {
      stripeSettings.push(stripeSettingsForPlan);
    }
  }

  return {
    clientHasStripe,
    stripeSettings,
  };
}

export function parseAWSMarketplaceCustomerSettings(
  aws_billing_provider_configs: GetBillingProvidersSettingsQuery["azure_billing_provider_configs"],
  billingProviderTokens: GetBillingProvidersSettingsQuery["billingProviderTokens"],
  billingProviderCustomers: NonNullable<
    GetBillingProvidersSettingsQuery["customer"]
  >["BillingProviderCustomers"],
  customerConfigs: GetBillingProvidersSettingsQuery["customerConfigs"],
) {
  let clientHasAws = false;
  const awsSettings: (AWSMarketplaceCustomerSettings & {
    customerBillingProviderConfigurationID?: string;
  })[] = [];

  if (aws_billing_provider_configs?.length) {
    clientHasAws = true; // assume there's a delivery method if any cpbc rows exist

    for (const awsBillingProviderConfig of aws_billing_provider_configs) {
      const awsConfig = awsBillingProviderConfig.configuration;
      awsSettings.push({
        customerId: awsConfig.aws_customer_id.toString(),
        productCode: awsConfig.aws_product_code.toString(),
        region: awsConfig.aws_region.toString(),
        isSubscriptionProduct: awsConfig.aws_is_subscription_product === "true",
        connectionDate: parseISO(awsBillingProviderConfig.created_at),
        customerBillingProviderConfigurationID: awsBillingProviderConfig.id,
      });
    }
  } else {
    const awsToken = billingProviderTokens?.find(
      (t) => t.billing_provider === BillingProviderEnum_Enum.AwsMarketplace,
    );

    if (awsToken) {
      clientHasAws = true;
    }

    const awsBillingProviderCustomer = billingProviderCustomers?.find(
      (bpCustomer) =>
        bpCustomer.billing_provider === BillingProviderEnum_Enum.AwsMarketplace,
    );

    const plansAwsSettings: AWSMarketplaceCustomerSettings = {};
    if (awsBillingProviderCustomer) {
      plansAwsSettings.customerId =
        awsBillingProviderCustomer.billing_provider_customer_id;
      plansAwsSettings.connectionDate = parseISO(
        awsBillingProviderCustomer.created_at,
      );
    }

    for (const customerConfig of customerConfigs ?? []) {
      if (customerConfig.key === "aws_product_code") {
        plansAwsSettings.productCode = customerConfig.value;
      } else if (customerConfig.key === "aws_region") {
        plansAwsSettings.region = customerConfig.value;
      } else if (customerConfig.key === "aws_expiration_timestamp") {
        plansAwsSettings.expirationTimestamp = parseISO(customerConfig.value);
      } else if (customerConfig.key === "aws_metering_disabled") {
        plansAwsSettings.meteringDisabled = customerConfig.value === "true";
      } else if (customerConfig.key === "aws_is_subscription_product") {
        plansAwsSettings.isSubscriptionProduct =
          customerConfig?.value?.toString() === "true";
      }
    }

    if (Object.keys(plansAwsSettings).length > 0) {
      awsSettings.push(plansAwsSettings);
    }
  }

  return {
    clientHasAws,
    awsSettings,
  };
}

function parseGcpCustomerSettings(
  billingProviderTokens: GetBillingProvidersSettingsQuery["billingProviderTokens"],
  billingProviderCustomers: NonNullable<
    GetBillingProvidersSettingsQuery["customer"]
  >["BillingProviderCustomers"],
  customerConfigs: GetBillingProvidersSettingsQuery["customerConfigs"],
) {
  const gcpSettings: GcpCustomerSettings = {};

  const gcpBillingProviderCustomer = billingProviderCustomers?.find(
    (bpCustomer) =>
      bpCustomer.billing_provider === BillingProviderEnum_Enum.AwsMarketplace,
  );

  if (gcpBillingProviderCustomer) {
    gcpSettings.connectionDate = parseISO(
      gcpBillingProviderCustomer.created_at,
    );
  }
  for (const customerConfig of customerConfigs ?? []) {
    if (customerConfig.key === "gcp_expiration_date") {
      gcpSettings.expirationDate = parseISO(customerConfig.value);
    }
  }

  return {
    gcpSettings: Object.keys(gcpSettings).length > 0 ? [gcpSettings] : [],
  };
}

function parseAzureMarketplaceCustomerSettings(
  azure_billing_provider_configs: GetBillingProvidersSettingsQuery["azure_billing_provider_configs"],
  billingProviderTokens: GetBillingProvidersSettingsQuery["billingProviderTokens"],
  billingProviderCustomers: NonNullable<
    GetBillingProvidersSettingsQuery["customer"]
  >["BillingProviderCustomers"],
  customerConfigs: GetBillingProvidersSettingsQuery["customerConfigs"],
) {
  let clientHasAzure = false;
  const azureSettings: (AzureMarketplaceCustomerSettings & {
    customerBillingProviderConfigurationID?: string;
  })[] = [];

  if (azure_billing_provider_configs?.length) {
    clientHasAzure = true;

    for (const azureBillingProviderConfig of azure_billing_provider_configs) {
      const azureConfig = azureBillingProviderConfig.configuration;
      azureSettings.push({
        subscriptionId: azureConfig.azure_subscription_id.toString(),
        subscriptionStatus:
          azureConfig.azure_subscription_status?.toString() ?? "",
        planId: azureConfig.azure_plan_id.toString(),
        startDate: azureConfig.azure_start_date
          ? parseISO(azureConfig.azure_start_date?.toString())
          : undefined,
        expirationDate: azureConfig.azure_expiration_date
          ? parseISO(azureConfig.azure_expiration_date?.toString())
          : undefined,
        meteringDisabled: azureConfig.azure_metering_disabled === "true",
        connectionDate: parseISO(azureBillingProviderConfig.created_at),
      });
    }
  } else {
    const azureToken = billingProviderTokens?.find(
      (t) => t.billing_provider === BillingProviderEnum_Enum.Azure,
    );

    if (azureToken) {
      clientHasAzure = true;
    }

    const azureBillingProviderCustomer = billingProviderCustomers?.find(
      (bpCustomer) =>
        bpCustomer.billing_provider === BillingProviderEnum_Enum.Azure,
    );

    const plansAzureSettings: AzureMarketplaceCustomerSettings = {};
    if (azureBillingProviderCustomer) {
      plansAzureSettings.subscriptionId =
        azureBillingProviderCustomer.billing_provider_customer_id;
      plansAzureSettings.connectionDate = parseISO(
        azureBillingProviderCustomer.created_at,
      );
    }

    for (const customerConfig of customerConfigs ?? []) {
      if (customerConfig.key === "azure_subscription_status") {
        plansAzureSettings.subscriptionStatus = customerConfig.value;
      } else if (customerConfig.key === "azure_plan_id") {
        plansAzureSettings.planId = customerConfig.value;
      } else if (customerConfig.key === "azure_start_date") {
        plansAzureSettings.startDate = parseISO(customerConfig.value);
      } else if (customerConfig.key === "azure_expiration_date") {
        plansAzureSettings.expirationDate = parseISO(customerConfig.value);
      } else if (customerConfig.key === "azure_metering_disabled") {
        plansAzureSettings.meteringDisabled = customerConfig.value === "true";
      }
    }
    if (Object.keys(plansAzureSettings).length > 0) {
      azureSettings.push(plansAzureSettings);
    }
  }

  return {
    clientHasAzure,
    azureSettings,
  };
}

function parseSalesforceConnectionCustomerSettings(
  customerConfigs: GetBillingProvidersSettingsQuery["customerConfigs"],
) {
  const salesforceConnectionSettings: SalesforceConnectionCustomerSettings = {};

  for (const customerConfig of customerConfigs ?? []) {
    if (customerConfig.key === "salesforce_account_id") {
      salesforceConnectionSettings.salesforceAccountId = customerConfig.value;
      salesforceConnectionSettings.connectionDate = parseISO(
        customerConfig.updated_at,
      );
    }
  }

  return salesforceConnectionSettings;
}

function parseNetSuiteConnectionCustomerSettings(
  customerConfigs: GetBillingProvidersSettingsQuery["customerConfigs"],
) {
  const netSuiteConnectionSettings: NetSuiteConnectionCustomerSettings = {};

  for (const customerConfig of customerConfigs ?? []) {
    if (customerConfig.key === "netsuite_customer_id") {
      netSuiteConnectionSettings.netSuiteCustomerId = customerConfig.value;
      netSuiteConnectionSettings.connectionDate = parseISO(
        customerConfig.updated_at,
      );
    }
  }

  return netSuiteConnectionSettings;
}

/**
 When should we show each marketplace BP's card?

 ... when one of the marketplaces is configured
 as visible
 legacy: when connected via billingProviderToken || when none are connected
 deltaStream: when deltaStreamIsEnabled && has active contracts on BP
 with connected message
 legacy: when connected via billingProviderToken
 deltaStream: when deltaStreamIsEnabled && has active contracts on BP
 with active badge
 legacy: when connected via billingProviderToken && subscription is active/not expired
 deltaStream: always show active badge with connected message (same condition)

 ... when none of the marketplaces are configured
 as visible (always)
 legacy: display all cards as disconnected
 deltaStream: display all cards as disconnected
 */
export function getCustomerIntegrationCardVisibility(
  connectedBPs: FirstClassIntegrations[] | undefined,
  hasActiveContractsOrPlan: {
    aws: boolean;
    azure: boolean;
    gcp: boolean;
    stripe: boolean;
  },
  hasBPToken: {
    aws: boolean;
    azure: boolean;
    stripe: boolean;
    // no GCP since that isn't implemented yet
  },
  featureFlagEnabled: {
    deltaStream: boolean;
    gcp: boolean;
  },
) {
  const noActiveMarketplaceContracts =
    !hasActiveContractsOrPlan.aws &&
    !hasActiveContractsOrPlan.azure &&
    !hasActiveContractsOrPlan.gcp;

  const connectedBPsSet = new Set(connectedBPs);

  let awsIsConnected = connectedBPsSet.has(
    BillingProviderEnum_Enum.AwsMarketplace,
  );
  let showAwsCard = hasBPToken && (awsIsConnected || !connectedBPs?.length);

  let azureIsConnected = connectedBPsSet.has(BillingProviderEnum_Enum.Azure);
  let showAzureCard =
    hasBPToken.azure && (azureIsConnected || !connectedBPs?.length);

  let gcpIsConnected = connectedBPsSet.has(BillingProviderEnum_Enum.Gcp);
  let showGcpCard =
    featureFlagEnabled.gcp && (gcpIsConnected || !connectedBPs?.length);

  const stripeIsConnected = connectedBPsSet.has(
    BillingProviderEnum_Enum.Stripe,
  );
  const showStripeCard = hasBPToken.stripe && !stripeIsConnected;

  if (featureFlagEnabled.deltaStream) {
    awsIsConnected = hasActiveContractsOrPlan.aws;
    showAzureCard =
      hasActiveContractsOrPlan.azure || noActiveMarketplaceContracts;

    azureIsConnected = hasActiveContractsOrPlan.azure;
    showAzureCard =
      hasActiveContractsOrPlan.azure || noActiveMarketplaceContracts;

    gcpIsConnected = featureFlagEnabled.gcp && hasActiveContractsOrPlan.gcp;
    showGcpCard =
      featureFlagEnabled.gcp &&
      (hasActiveContractsOrPlan.gcp || noActiveMarketplaceContracts);
  }

  return {
    aws: {
      isConnected: awsIsConnected,
      showCard: showAwsCard,
    },
    azure: {
      isConnected: azureIsConnected,
      showCard: showAzureCard,
    },
    gcp: {
      isConnected: gcpIsConnected,
      showCard: showGcpCard,
    },
    stripe: {
      isConnected: stripeIsConnected,
      showCard: showStripeCard,
    },
  };
}
