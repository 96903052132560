import React, { useCallback, useEffect, useState } from "react";
import "/src/tenaissance/tenaissance.css";
import { twMerge } from "../../twMerge";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  flexRender,
  SortingState,
  type SortDirection,
  type SortingFn,
  type Row,
  DeepKeys,
} from "@tanstack/react-table";
import { Icon, IconName } from "tenaissance/components/Icon";
import { Tooltip } from "tenaissance/components/Tooltip";
import useDebounce from "lib/debounce";
import { Button } from "tenaissance/components/Button";
import Papa from "papaparse";
import { Link } from "react-router-dom";
import { EmptyState } from "../EmptyState";

const ELLIPSIS_PAGE_NUM = -1;

/**
 * Vanity Icons are optional for overviews with only a few rows
 */
const VANITY_ICONS: IconName[] = [
  "building01",
  "building02",
  "building03",
  "building04",
  "building05",
  "building06",
  "building07",
  "building08",
];
const VANITY_GRADIENTS: string[] = [
  "from-core-slate to-core-dark-green bg-gradient-to-tr text-white",
  "from-core-dark-green to-core-jade-green bg-gradient-to-r text-white",
  "from-core-mint to-core-jade-green bg-gradient-to-tr text-gray-950",
  "from-core-blue-mint to-core-hot-green bg-gradient-to-tr text-gray-950",
];

interface SortIconProps {
  dir: SortDirection | false;
}

const SortIcon: React.FC<SortIconProps> = ({ dir }) => {
  const icon = dir === "asc" ? "arrowUp" : "arrowDown";
  return <Icon className="ml-xs" icon={icon} size={14} />;
};

interface LoadingStateProps {
  columnCount: number;
}
const LoadingState: React.FC<LoadingStateProps> = ({ columnCount }) => {
  return (
    <>
      {new Array(5).fill(0).map((_, i) => {
        return (
          <tr key={i} className="animate-pulse">
            {new Array(columnCount).fill(0).map((c, j) => {
              return (
                <td key={j}>
                  <div className="py-xl px-3xl flex justify-end">
                    <div className="h-lg rounded-sm my-sm w-full max-w-[300px] bg-gray-200" />
                  </div>
                </td>
              );
            })}
            <td />
          </tr>
        );
      })}
    </>
  );
};

const PaginationButtons: React.FC<{ paginationOptions: PaginationOptions }> = ({
  paginationOptions,
}) => {
  const { type } = paginationOptions;

  if (type === "loadMore") {
    const { loadMoreButton } = paginationOptions;
    return (
      <Button
        text="Load More"
        theme="secondary"
        disabled={loadMoreButton.disabled}
        onClick={loadMoreButton.onClick}
      />
    );
  }
  if (type === "prevNext") {
    const { paginationButtons } = paginationOptions;
    return (
      <>
        {paginationButtons.map((button, i) => (
          <Button
            key={i}
            text={button.page === "prev" ? "Previous" : "Next"}
            leadingIcon={button.page === "prev" ? "arrowLeft" : "arrowRight"}
            theme="secondary"
            disabled={button.disabled}
            onClick={button.onClick}
          />
        ))}
      </>
    );
  }
  return null;
};

type CellProps = {
  /**
   * Typically the primitive value (number, string, boolean) that will be rendered to the table cell
   */
  getValue: () => any;
};

type AccessorKeyColumn<T> = {
  /**
   * The key of the row object to use when extracting the value for the column. The resulting
   * value should be a primitive to support built-in sorting.
   * (i.e. "starting_at" for a Date value or "rate_card.name" for the string value in an object)
   * */
  accessorKey: DeepKeys<T>;
};

type AccessorFnColumn<T> = {
  /**
   *  The function to use when extracting the value for the column, given the row is a deeply nested
   *  object or array. The returning value should be a primitive to support built-in sorting.
   * (i.e. `accessorFn: ({ rate_card }) => rate_card?.name ?? ""` which handles undefined | null cases )
   * */
  accessorFn: (row: T) => any;
};

export type BaseColumn<T> = {
  /** A unique identifier for the column */
  id: string;
  /**
   * A display column is used for things like row actions (edit, delete) as well as rendering related information.
   *  As such, these will not have filtering or sorting on it.
   * */
  isDisplay?: boolean;
  /**
   * A rendering function for the cell of a column, often as `cell: (props) => props.getValue()`.
   * This should result in a string or ReactElement.
   * */
  cell: (props: CellProps) => React.ReactNode;
  /** Header for the Column, rendered at the top */
  header?: (() => React.ReactNode) | string;
  /**
   *  Default: undefined - If the column requires some context, the supplied tooltipContent will
   *  surface next to the Column header as a help icon. Hovering over the icon will render the tooltip.
   * */
  tooltipContent?: string;
  /** Default: true - Whether the column should be sortable or not */
  enableSorting?: boolean;
  /**
   *  Provide a custom sorting function for the column, if it is a special property or if you want to sort on
   *  a nested object or array.
   * */
  sortingFn?: SortingFn<T>;
  /** Default: text - Lead text style applies a heavier font weight and darker text color */
  textStyle?: "text" | "leadText";
  /** Add a second, de-emphasized line of text underneath the main text */
  supportingText?: string | ((rowData: T) => string);
  /**
   *  Vanity icon will prepend a random icon to a cell using leadText style. These are optional,
   *  and are often used just for overviews with few rows.
   * */
  showVanityIcon?: boolean;
};

export type DisplayColumn<T> = BaseColumn<T> & {
  /**
   * A display column is used for things like row actions (edit, delete) as well as rendering related information.
   *  As such, these will not have filtering or sorting on it.
   * */
  isDisplay: true;
};

export type DataColumn<T> = BaseColumn<T> &
  (AccessorKeyColumn<T> | AccessorFnColumn<T>);

export type Column<T> = DisplayColumn<T> | DataColumn<T>;

export interface ObjectWithId {
  id: string;
}

type Page = "prev" | "next";

type PrevNextPaginationOptions = {
  /**
   * For pagination that is fetched based on a number of rows per page. Dev should provide both `prev` and `next`
   * button logic for better UX.
   */
  type: "prevNext";
  paginationButtons: {
    /** Submit whether this is the `prev` or `next` page.*/
    page: Page;
    /** Disable the button if the user is on the current page, or if there is no data to be fetched in that direction. */
    disabled?: boolean;
    /** Whether the page of data the user is on is the current page */
    selected?: boolean;
    onClick: () => void;
  }[];
  pageSize?: never;
};

type LoadMorePaginationOptions = {
  /**
   * Provide the user a single button to fetch more data from a query, at a pre-determined row size.
   * Additional data should be expected to be concatenated to the previous data set, so that rows
   * are added to the bottom of the Table.
   */
  type: "loadMore";
  loadMoreButton: {
    /** Fire off an additional fetch of data */
    onClick: () => void;
    /** Disable the Load More button if there is no more data to be fetched */
    disabled?: boolean;
  };
  pageSize?: never;
};

type ClientSidePaginationOptions = {
  /** Render pagination options at the bottom of the table and turn on basic client-side pagination. */
  type: "clientSide";
  paginationButtons?: never;
  /** Default: 15 - Supply to the table the full number of pages of data to help support client-side pagination */
  pageSize?: number;
};

type PaginationOptions =
  | LoadMorePaginationOptions
  | PrevNextPaginationOptions
  | ClientSidePaginationOptions;

export interface TableProps<T extends ObjectWithId> {
  /** Defintion of the Columns for the Table, this should be an array of objects */
  columns: Column<T>[];
  /** Data for the Table. Be sure to structure the data as an array of objects, who's keys correspond to an accessorKey in the Column */
  data: T[];
  /** Default sort for the table */
  defaultSort?: SortingState;
  /**
   * An `<EmptyState />` component can be passed to the `emptyState` prop. Use this as an opportunity to customize the case
   * of no data for a new user, or an empty search result for the Table.
   */
  emptyState?: React.ReactElement<typeof EmptyState>;
  /** Boolean value indicating whether the data being supplied is still loading or not */
  loading?: boolean;
  /** Add a clickable option for the table row that leads to an internal page */
  rowRoutePath?: string | ((row: Row<T>) => string | undefined);
  searchOptions?: {
    /**
     * Render a search bar and turn on basic client-side search, assuming all data is present.
     * If you want to use server-generated queries you must also pass an `onSearch` prop.
     * */
    showSearch?: boolean;
    /**
     * Requires showSearch: true; Pass a search function callback that will give the caller access to the query,
     *  while also using our debounced search input. Be sure that the `loading` prop is passed correctly while
     *  searching to give the user an intermediary state while data is being fetched. */
    onSearch?: (query: string) => void;
  };
  /**
   * Turning this on will render an Export button at the top of the table which will take the headers and data
   * and export to a CSV file. To provide a custom version, create a Button and add it to the `topBarActions` prop.
   */
  showExport?: boolean;
  /**
   * Set of options for pagination, with support for the following types:
   *  "loadMore" - single button to fetch more rows of data which should be concatenated to the previous set
   *  "cursor" - provides both previous and next buttons, along with page numbers if supplied
   *  "clientSide" - full set of paged buttons, but must provide pageSize as well.
   */
  paginationOptions?: PaginationOptions;
  /** Title for the table, rendered above the `<thead>` */
  title?: string;
  /** Set of options for various table-wide actions (search, filter, export, etc) */
  topBarActions?: React.ReactElement[];
}

/**
 * Data tables display information in a grid-like format of rows and columns.
 *  They organize information in a way that’s easy to scan so that users can look
 *  for patterns and develop insights from data.
 * */
export function Table<T extends ObjectWithId>({
  columns,
  data: propData,
  emptyState,
  loading,
  rowRoutePath,
  paginationOptions,
  showExport,
  searchOptions: { showSearch, onSearch } = {},
  title,
  topBarActions,
  defaultSort,
}: TableProps<T>) {
  const { type: paginationType, pageSize } = paginationOptions || {};

  const [data, setData] = useState(propData);
  const [searchQuery, setSearchQuery] = React.useState("");
  const debouncedQuery = useDebounce(searchQuery.trim(), 400);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize ?? 10,
  });

  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedQuery);
    }
  }, [debouncedQuery, onSearch]);

  useEffect(() => {
    setData(propData);
  }, [propData]);

  const exportCSV = useCallback(
    (rows: Row<T>[]) => {
      const rowData = rows.map((row) => {
        return row
          .getVisibleCells()
          .map((cell) => cell.renderValue()?.toString());
      });
      const csv = Papa.unparse([columns.map((c) => c.header), ...rowData], {
        header: true, // Specify that the first row should be treated as headers
      });

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "table_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    [title],
  );

  const showTopBar =
    !loading && (!!title || showExport || showSearch || topBarActions?.length);
  const table = useReactTable({
    defaultColumn: {
      minSize: 50,
      size: 50,
    },
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel:
      paginationType === "clientSide" ? getPaginationRowModel() : undefined,
    onPaginationChange: setPagination,
    manualPagination:
      paginationType === "prevNext" || paginationType === "loadMore",
    autoResetPageIndex: true,
    onGlobalFilterChange: showSearch && !onSearch ? setSearchQuery : undefined,
    globalFilterFn: "auto",
    getRowId: (originalRow) => {
      return originalRow.id;
    },
    state: {
      pagination,
      globalFilter: searchQuery,
      sorting: defaultSort,
    },
    meta: {
      rowRoutePath: (row) => row.original.rowRoutePath,
    },
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const calculatePagesToShow = useCallback(
    (totalPages: number): number[] => {
      const currentPage = pagination.pageIndex;
      if (totalPages < 7) {
        return Array.from(Array(totalPages), (_, i) => i);
      }

      if ([0, 1, totalPages - 2, totalPages - 1].includes(currentPage)) {
        return [
          0,
          1,
          2,
          ELLIPSIS_PAGE_NUM,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
        ];
      }

      if (currentPage === 2) {
        return [0, 1, 2, 3, ELLIPSIS_PAGE_NUM, totalPages - 2, totalPages - 1];
      }

      if (currentPage === totalPages - 3) {
        return [
          0,
          ELLIPSIS_PAGE_NUM,
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
        ];
      }

      const pagesToShow = [0, ELLIPSIS_PAGE_NUM];

      if (currentPage !== 1 && currentPage !== totalPages - 1) {
        for (
          let i = Math.max(2, currentPage - 1);
          i <= Math.min(currentPage + 1, totalPages);
          i++
        ) {
          pagesToShow.push(i);
        }
      }

      pagesToShow.push(ELLIPSIS_PAGE_NUM, totalPages - 1);

      return pagesToShow;
    },
    [pagination.pageIndex],
  );

  const tableClasses = twMerge(
    "border-spacing-none table-auto border-separate border border-gray-200 w-full rounded-xl",
    showTopBar && "rounded-t-none",
    !!paginationType && "rounded-b-none",
  );

  const renderLink = (
    rowRoutePath: TableProps<T>["rowRoutePath"],
    row: Row<T>,
  ) => {
    if (typeof rowRoutePath === "string") {
      return (
        <Link to={rowRoutePath} className="inset-none absolute block h-full" />
      );
    }
    if (typeof rowRoutePath === "function") {
      const rowRoutePathResult = rowRoutePath(row);
      if (typeof rowRoutePathResult === "string") {
        return (
          <Link
            to={rowRoutePathResult}
            className="inset-none absolute block h-full"
          />
        );
      }
    }
    return null;
  };

  return (
    <div className="rounded-xl min-w-[500px] overflow-auto shadow-sm">
      {showTopBar && (
        <div className="rounded-t-xl py-lg px-3xl flex items-center justify-between border border-b-0 border-gray-200">
          <span className="text-md truncate font-semibold text-black">
            {title}
          </span>
          <div className="flex justify-end space-x-[8px]">
            {showSearch && (
              // TODO(dalvarez) - update to tenaissance Input component
              <input
                value={searchQuery}
                onChange={handleSearchChange}
                className="p-sm font-lg border-block rounded-sm border"
                placeholder="Search all columns..."
              />
            )}
            {topBarActions}
            {showExport && (
              <>
                <Button
                  text="Export"
                  leadingIcon="share02"
                  theme="secondary"
                  onClick={() => exportCSV(table.getRowModel().rows)}
                />
              </>
            )}
          </div>
        </div>
      )}
      <table className={tableClasses}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="h-[44px] bg-gray-50">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={twMerge(
                    "py-xl px-3xl text-left text-xs font-medium text-gray-600",
                    !showTopBar && "first:rounded-tl-xl last:rounded-tr-xl",
                    header.column.columnDef.isDisplay &&
                      "w-xxs whitespace-nowrap",
                  )}
                  style={{
                    width: undefined,
                  }}
                >
                  <div
                    className={twMerge(
                      "inline-flex items-center",
                      header.column.getCanSort() &&
                        "rounded-4xl py-xs px-md hover:text-gray-950 cursor-pointer hover:bg-gray-200",
                    )}
                    role={
                      header.column.getCanSort() ? "button" : "columnheader"
                    }
                    onClick={
                      header.column.getCanSort()
                        ? header.column.getToggleSortingHandler()
                        : undefined
                    }
                  >
                    {header.column.columnDef.header &&
                      flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    {loading}
                    {header.column.columnDef.tooltipContent && (
                      <Tooltip label={header.column.columnDef.tooltipContent}>
                        <Icon icon="helpCircle" size={14} className="ml-xs" />
                      </Tooltip>
                    )}

                    {header.column.getIsSorted() && (
                      <SortIcon dir={header.column.getIsSorted()} />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {!loading && data.length === 0 ? (
          <tr>
            <td colSpan={columns.length}>
              <div className="flex h-full w-full items-center justify-center">
                {emptyState || <span>No data available</span>}
              </div>
            </td>
          </tr>
        ) : (
          <tbody>
            {loading ? (
              <LoadingState columnCount={columns.length} />
            ) : (
              table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className={twMerge(
                    "h-[52px]",
                    !!rowRoutePath && "hover:bg-gray-50",
                    !table.options.meta?.rowRoutePath(row) &&
                      rowRoutePath &&
                      "hover:bg-transparent",
                  )}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={twMerge(
                          "py-xl px-3xl whitespace-nowrap border-t border-gray-200 text-sm font-normal text-gray-600",
                          !!rowRoutePath && "relative",
                          cell.column.columnDef.textStyle === "leadText" &&
                            "text-core-slate font-medium",
                          cell.column.columnDef.isDisplay &&
                            "w-xxs whitespace-nowrap",
                        )}
                        style={{
                          height: 52,
                        }}
                      >
                        <div
                          className={twMerge(
                            "inline-flex items-center",
                            !!rowRoutePath && "relative",
                            cell.column.columnDef.isDisplay && "justify-end",
                          )}
                        >
                          {cell.column.columnDef.textStyle === "leadText" &&
                            cell.column.columnDef.showVanityIcon && (
                              <div
                                className={twMerge(
                                  "mr-lg h-4xl w-4xl rounded-sm p-md",
                                  VANITY_GRADIENTS[
                                    Math.floor(
                                      Math.random() * VANITY_GRADIENTS.length,
                                    )
                                  ],
                                )}
                              >
                                <Icon
                                  icon={
                                    VANITY_ICONS[
                                      Math.floor(
                                        Math.random() * VANITY_ICONS.length,
                                      )
                                    ]
                                  }
                                  size={16}
                                />
                              </div>
                            )}
                          <div
                            className={twMerge(
                              "flex",
                              !!cell.column.columnDef.supportingText
                                ? "flex-col"
                                : "items-center space-x-[4px]",
                            )}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                            {cell.column.columnDef.supportingText && (
                              <span className="text-xs font-normal">
                                {typeof cell.column.columnDef.supportingText ===
                                "string"
                                  ? cell.column.columnDef.supportingText
                                  : cell.column.columnDef.supportingText(
                                      row.original,
                                    )}
                              </span>
                            )}
                          </div>
                        </div>
                        {rowRoutePath && renderLink(rowRoutePath, row)}
                      </td>
                    );
                  })}
                </tr>
              ))
            )}
          </tbody>
        )}
      </table>
      {!loading && !!paginationType && (
        <div className="px-3xl pt-lg pb-xl rounded-b-xl flex h-[68px] justify-between border border-t-0 border-gray-200">
          {paginationType === "clientSide" ? (
            <>
              <Button
                text="Previous"
                leadingIcon="arrowLeft"
                theme="secondary"
                disabled={!table.getCanPreviousPage()}
                onClick={table.previousPage}
              />

              <div>
                {calculatePagesToShow(table.getPageCount()).map((p, idx) => {
                  if (p === ELLIPSIS_PAGE_NUM) {
                    return <div className="mx-sm inline-flex">...</div>;
                  }
                  return (
                    <Button
                      text={`${p + 1}`}
                      theme="tertiary"
                      key={idx}
                      disabled={pagination.pageIndex === p}
                      onClick={() => table.setPageIndex(p)}
                      className={
                        pagination.pageIndex === p ? "bg-gray-50" : undefined
                      }
                    />
                  );
                })}
              </div>
              <Button
                text="Next"
                trailingIcon="arrowRight"
                theme="secondary"
                disabled={!table.getCanNextPage()}
                onClick={table.nextPage}
              />
            </>
          ) : (
            !!paginationOptions && (
              <PaginationButtons paginationOptions={paginationOptions} />
            )
          )}
        </div>
      )}
    </div>
  );
}
