import React, { useState } from "react";

import styles from "./index.module.less";
import {
  BillingProviderEnum_Enum,
  EnvironmentTypeEnum_Enum,
} from "types/generated-graphql/__types__";
import { IntegrationCard } from "pages/GeneralSettings/components/IntegrationCard";
import { useNavigate } from "lib/useNavigate";
import { renderDateTime } from "lib/time";
import { Badge, Subtitle, Body, Icon, Tooltip } from "design-system";
import StripeSettingsModal from "../../components/StripeSettingsModal";
import { DisableStripeModal } from "../../components/DisableStripeModal";
import { CopyableID } from "../../../../../../components/CopyableID";
import classnames from "classnames";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { UpdateStripeBillingProviderCustomerDocument } from "../../components/StripeSettingsModal/queries.graphql";

type StripeBillingProviderSectionProps = {
  customerId: string;
  customerName?: string;
  hasClientIntegration: boolean;
  hasAnotherBillingProviderEnabled: boolean;
  stripeCustomerId?: string;
  stripeCollectionMethod?: string;
  connectionDate?: Date;
  clientHasDeltaStreamEnabled: boolean;
  clientHasBillingProviderConfigEnabled: boolean;
};

export const StripeBillingProvider: React.FC<
  StripeBillingProviderSectionProps
> = ({
  customerId,
  hasAnotherBillingProviderEnabled,
  hasClientIntegration,
  customerName,
  stripeCustomerId,
  stripeCollectionMethod,
  connectionDate,
  clientHasDeltaStreamEnabled,
  clientHasBillingProviderConfigEnabled,
}) => {
  const navigate = useNavigate();

  const [stripeSettingsModalOpen, setStripeSettingsModalOpen] = useState(false);
  const [stripeSettingsEditModalOpen, setStripeSettingsEditModalOpen] =
    useState(false);
  const [stripeSettingsDeleteModalOpen, setStripeSettingsDeleteModalOpen] =
    useState(false);

  const { environmentType } = useEnvironment();

  let content: JSX.Element;
  if (hasAnotherBillingProviderEnabled) {
    content = (
      <IntegrationCard
        type={BillingProviderEnum_Enum.Stripe}
        titleNode={
          <Subtitle className={styles.title} level={2}>
            Stripe
          </Subtitle>
        }
        info={
          <div className={styles.disabled}>
            Only one billing provider can be configured at a time.
          </div>
        }
        buttonText="Connect"
        buttonDisabled={true}
      />
    );
  } else if (
    clientHasDeltaStreamEnabled &&
    clientHasBillingProviderConfigEnabled
  ) {
    content = (
      <IntegrationCard
        type={BillingProviderEnum_Enum.Stripe}
        titleNode={
          <Subtitle className={styles.title} level={2}>
            Stripe
          </Subtitle>
        }
        info={
          <div className={styles.disabled}>
            Stripe cannot be configured in the Metronome UI for clients with
            active Delta Stream integrations.
          </div>
        }
        buttonText="Connect"
        buttonTheme="primary"
        buttonDisabled={true}
      />
    );
  } else if (!hasClientIntegration) {
    content = (
      <IntegrationCard
        type={BillingProviderEnum_Enum.Stripe}
        titleNode={
          <Subtitle className={styles.title} level={2}>
            Stripe
          </Subtitle>
        }
        info={
          <Body level={2} className={styles.notConfigured}>
            Enable Stripe in your Billing Configuration to invoice this customer
            via Stripe
          </Body>
        }
        buttonText="Enable Stripe"
        buttonOnClick={() => navigate(`/settings/general/integrations`)}
      />
    );
  } else if (!stripeCustomerId) {
    // Stripe is enabled at client level but this customer does not have Stripe enabled.
    content = (
      <IntegrationCard
        type={BillingProviderEnum_Enum.Stripe}
        titleNode={
          <Subtitle className={styles.title} level={2}>
            Stripe
          </Subtitle>
        }
        info={
          <Body level={2} className={styles.notConfigured}>
            {customerName || "This customer"} doesn't have Stripe configured
          </Body>
        }
        buttonText="Connect"
        buttonAuthDoc={UpdateStripeBillingProviderCustomerDocument}
        buttonOnClick={() => setStripeSettingsModalOpen(true)}
      />
    );
  } else {
    content = (
      <IntegrationCard
        type={BillingProviderEnum_Enum.Stripe}
        titleNode={
          <>
            <Subtitle className={styles.title} level={2}>
              Stripe
            </Subtitle>
            <Badge theme="success" type="dark" className={styles.activeBadge}>
              ACTIVE
            </Badge>
          </>
        }
        info={
          <div
            className={classnames(
              styles.providerInfo,
              "flex flex-row items-center space-x-8",
            )}
          >
            <CopyableID id={stripeCustomerId} label="Stripe ID" />
            <a
              href={`https://dashboard.stripe.com${
                environmentType !== EnvironmentTypeEnum_Enum.Production
                  ? "/test"
                  : ""
              }/customers/${stripeCustomerId}`}
              target="_blank"
            >
              <Tooltip content="Go to Customer in Stripe">
                <Icon icon="open" className="text-primary-600"></Icon>
              </Tooltip>
            </a>
          </div>
        }
        connectionDetails={renderDateTime(
          connectionDate || new Date(),
          true,
          "Connected on",
        )}
        buttonText="Edit"
        buttonOnClick={() => setStripeSettingsEditModalOpen(true)}
      />
    );
  }

  return (
    <>
      {stripeSettingsModalOpen && (
        <StripeSettingsModal
          onClose={() => setStripeSettingsModalOpen(false)}
          customerId={customerId}
          edit={false}
        />
      )}
      {stripeSettingsEditModalOpen && (
        <StripeSettingsModal
          onClose={(deleteConfig) => {
            setStripeSettingsEditModalOpen(false);
            setStripeSettingsDeleteModalOpen(deleteConfig ?? false);
          }}
          customerId={customerId}
          initialStripeCollectionMethod={stripeCollectionMethod}
          initialStripeId={stripeCustomerId}
          edit={true}
        />
      )}
      {stripeSettingsDeleteModalOpen && (
        <DisableStripeModal
          onClose={() => setStripeSettingsDeleteModalOpen(false)}
          customerId={customerId}
        />
      )}
      {content}
    </>
  );
};
