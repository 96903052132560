import pluralize from "pluralize";
import React from "react";
import { Badge, Headline } from "design-system";

import { TablePanel } from "components/TablePanel";
import { TextSkeleton } from "components/Skeleton";
import { getUserFacingErrorMessage } from "lib/errors/errorHandling";

import { TinyErrorState } from "lib/errors/TinyErrorState";
import { ProgressBar } from "../../../components/ProgressBar";
import { ColWidths } from "../../../lib/ColWidths";
import { printDateRange, useNow } from "lib/date";
import { RoundedCurrency } from "lib/credits";
import classNames from "classnames";
import styles from "./index.module.less";
import { CommitsOverviewFragment } from "./data.graphql";
import { Commit } from "pages/Contracts/lib/Commit";
import { AsyncCommitUsage } from "../Usage";
import { useUIMode } from "../../../../../lib/useUIMode";

interface Props {
  className?: string;
  level?: "customer" | "contract";
  asCredit?: boolean;
  commits: CommitsOverviewFragment["commits_union"];
  asyncUsage?: AsyncCommitUsage;
  shouldSortAndDisplayExpiredBadges?: boolean;
}

export const CommitsOverview: React.FC<Props> = ({
  className,
  commits,
  asyncUsage,
  level = "contract",
  asCredit = false,
  shouldSortAndDisplayExpiredBadges = false,
}) => {
  const now = useNow();
  const commitOrCredit = asCredit ? "credit" : "commit";
  const { newUIEnabled } = useUIMode();

  return (
    <TablePanel
      title={asCredit ? "Credits" : "Commits"}
      data={
        shouldSortAndDisplayExpiredBadges
          ? [
              ...commits.filter((c) => !Commit.isExpired(c)),
              ...commits
                .filter((c) => Commit.isExpired(c))
                .sort((a, b) => {
                  // Expired commits at the end, sorted by most recently expired
                  const aEnd =
                    Commit.getDateRange(a)?.end?.valueOf() ?? -Infinity;
                  const bEnd =
                    Commit.getDateRange(b)?.end?.valueOf() ?? -Infinity;
                  return bEnd - aEnd;
                }),
            ]
          : commits
      }
      className={className}
      rowRoutePath={(row) => Commit.getRoutePath(row, newUIEnabled)}
      emptyState={
        <div className="p-12 text-center">
          <Headline level={6} className="text-gray-400">
            {`This ${level} has no ${commitOrCredit}s.`}
          </Headline>
        </div>
      }
      columns={[
        {
          id: "name",
          header: asCredit ? "Credit" : "Commit",
          cellClassName: "align-top",
          render: (row) => (
            <div>
              <div className="flex gap-4">
                <div>{Commit.getName(row, now)}</div>
                {shouldSortAndDisplayExpiredBadges && Commit.isExpired(row) ? (
                  <Badge theme="error" type="light">
                    EXPIRED
                  </Badge>
                ) : (
                  ""
                )}
              </div>
              <div className="text-xxs font-normal text-gray-medium">
                {Commit.printType(row)}
              </div>
            </div>
          ),
        },
        ...(level === "customer"
          ? [
              {
                id: "level",
                header: "Level",
                cellClassName: classNames(ColWidths.LEVEL, "align-top"),
                render: (row: (typeof commits)[0]) => Commit.printLevel(row),
              },
            ]
          : []),
        {
          id: "priority",
          header: "Priority",
          cellClassName: classNames(ColWidths.PRIORITY, "align-top"),
          render: (row) => row.priority,
        },
        {
          id: "accessSchedule",
          header: "Access schedule",
          cellClassName: classNames(ColWidths.ACCESS_SCHEDULE, "align-top"),
          render: (row) => {
            const dates = Commit.getDateRange(row);
            if (!(dates?.start && dates.end)) {
              return "--";
            }
            return (
              <>
                {printDateRange(now, dates.start, dates.end)}
                {row.access_schedule.schedule_items.length > 1 ? (
                  <div className="text-xxs font-normal text-gray-medium">
                    {row.access_schedule.schedule_items.length} segments
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          },
        },
        ...(!asCredit
          ? [
              {
                id: "invoiceSchedule",
                header: "Invoice schedule",
                cellClassName: classNames(ColWidths.BILL_SCHEDULE, "align-top"),
                render: (row: Commit.InvoicesFragment) => {
                  const count = Commit.getInvoiceCount(row);
                  return count != null
                    ? pluralize("invoice", count, true)
                    : "--";
                },
              },
            ]
          : []),
        ...(!asCredit
          ? [
              {
                id: "totalInvoiced",
                header: "Total invoiced",
                align: "right" as const,
                cellClassName: classNames(ColWidths.INVOICED, "align-top"),
                render: (row: Commit.AmountFragment) =>
                  Commit.renderTotalCost(row),
              },
            ]
          : []),
        {
          id: "consumed",
          header: `Consumed / Total ${asCredit ? "credit" : "commit"}`,
          align: "right",
          cellClassName: classNames(
            ColWidths.CONSUMED,
            styles.consumed,
            "align-top",
          ),
          render: (row) => {
            if (!asyncUsage) {
              return null;
            }

            if (asyncUsage.loading) {
              return (
                <div className="flex flex-col">
                  <TextSkeleton width="66%" className="self-end" />
                  <TextSkeleton width="66%" className="self-end" />
                </div>
              );
            }

            if (asyncUsage.error) {
              const msg = getUserFacingErrorMessage(asyncUsage.error);
              return (
                <TinyErrorState message={`Unable to load usage: ${msg}`} />
              );
            }

            const usage = asyncUsage.forCommit(row.id);
            if (!usage) {
              return null;
            }

            return (
              <div className="grid grid-rows-2 items-center">
                <div>
                  <RoundedCurrency
                    amount={usage.used}
                    creditType={row.access_schedule.credit_type}
                    hideSuffix
                  />{" "}
                  / {Commit.renderTotalAmount(row)}
                </div>
                <div className="w-[120px] justify-self-end">
                  <ProgressBar progress={usage.used} total={usage.total} />
                </div>
              </div>
            );
          },
        },
      ]}
    />
  );
};
