import React from "react";
import { Select } from "design-system";

import { FormController } from "lib/FormController";
import { useNow } from "lib/date";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";

import { Schema } from "../../Schema";
import { BillingSchedule } from "../../components/BillingSchedule";

import { AdditionalTermFlyover } from "./AdditionalTermFlyover";
import { useGetFixedProductsQuery } from "./data.graphql";

const useScheduledChargeCtrl = FormController.createHook(
  Schema.ScheduledCharge,
  {
    init(props: Props) {
      return props.edit ?? {};
    },
  },
);

interface Props {
  edit?: Schema.Types.ScheduledCharge;
  onSave: (override: Schema.Types.ScheduledCharge) => void;
  onCancel: () => void;
  onDelete?: () => void;
}

export const ScheduledChargeFlyover: React.FC<Props> = (props) => {
  const ctrl = useScheduledChargeCtrl(props);
  const now = useNow();
  const products = useGetFixedProductsQuery();

  return (
    <AdditionalTermFlyover
      {...props}
      ctrl={ctrl}
      title={props.edit ? "Edit scheduled charge" : "Add a scheduled charge"}
    >
      <Select
        {...ctrl.props.Select("productId", {
          name: "Product",
          placeholder: "Select a product",
          options:
            products.data?.products_and_rate_cards.products.map((p) => ({
              value: p.id,
              label: ProductListItem.getName(p, now),
            })) ?? [],
        })}
      />

      {ctrl.get("productId") && <BillingSchedule ctrl={ctrl} mode="invoice" />}
    </AdditionalTermFlyover>
  );
};
