import React, { Dispatch, SetStateAction } from "react";
import { BaseIntegrationCard } from "./BaseIntegrationCard";
import { ReactComponent as SalesforceLogo } from "tenaissance/icons/salesforce.svg";

interface SalesforceIntegrationCardProps {
  setSalesforceSettingsSideSheetIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const SalesforceIntegrationCard: React.FC<
  SalesforceIntegrationCardProps
> = ({ setSalesforceSettingsSideSheetIsOpen }) => {
  return (
    <BaseIntegrationCard
      title="Salesforce"
      subheader="Customer relationship management"
      description="Enrich your customer data by automatically importing Metronome usage and invoicing data."
      buttonOptions={{
        buttonOnClick: () => setSalesforceSettingsSideSheetIsOpen(true),
      }}
      logo={
        <div className="h-[32px] w-[32px] pt-8">
          <SalesforceLogo />
        </div>
      }
    />
  );
};
