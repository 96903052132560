// File to hold utilities related to credits

// Anywhere that attempts to display credit values or credit names should be
// using displayCreditTypeName and displayCreditsInCurrency from this file.

import React from "react";
import { reportToSentry } from "lib/errors/sentry";

import currency from "currency.js";
import Decimal from "decimal.js";

import { FiatCreditType, CreditType } from "types/credit-types";

import { CreditTypeConversion } from "lib/plans/types";
import { Tooltip } from "design-system";
import { dayjs } from "lib/dayjs";

export const USD_CREDIT_ID = "2714e483-4ff1-48e4-9e25-ac732e8f24f2";
export const USD_CREDIT_TYPE: FiatCreditType = {
  id: USD_CREDIT_ID,
  name: "USD (cents)",
  client_id: null,
  environment_type: null,
};

const currencyPrefixMap: { [creditTypeId: string]: string } = {
  [USD_CREDIT_ID]: "$",
  "58f0be15-cc47-4220-bdaf-072ab0e44f96": "€", // EUR
  "0f99b795-a801-4653-ad4b-b99922be625d": "£", // GBP
  "03b7ee98-c62b-48e3-903b-a57accb1c633": "$", // CAD
  "a2c101c5-e8a6-416b-bb74-468161a864db": "R", // ZAR
  "171fa3c1-3646-4cfb-9d73-cc72a52d2e75": "CHF", // CHF
  "876f700e-bb2a-48d4-85e6-aa30f23fa150": "$", // AUD
  "8d302fe3-77b3-4c5d-bd59-d54169b63090": "$", // MXN
  "9b84d739-1b02-4e05-a10b-80452529bae9": "₹", // INR
  "d4b90387-f5d6-4bef-856f-6e14caafce83": "kr", // SEK
  "7712c360-f5cc-42b8-bbcd-7a3c1d4b3aed": "kr", // NOK
  "e5eb985e-fcf7-4fbe-a587-3fe0be1c7254": "zł", // PLN
  "2cd507d1-e48e-41e4-8ab3-0dd74aa6f1a6": "Kč", // CZK
  "c65bb345-99ff-40c3-ab03-73025d36b8cc": "₺", // TRY
  "aa2f977a-adc2-4feb-867a-26e331451d40": "R$", // BRL
};

export function prefixForCreditType(creditType: CreditType): string {
  return currencyPrefixMap[creditType.id] ?? "";
}

function parseCurrencyAmount(amount: Decimal.Value, creditType: CreditType) {
  if (creditType.id === USD_CREDIT_ID) {
    return new Decimal(amount).div(100);
  }
  return new Decimal(amount);
}

type CurrencyComponents = {
  currencyValue: string;
  suffix: string;
};

function getCurrencyComponents(
  numCredits: Decimal,
  creditType: CreditType,
  hideDecimals?: boolean,
): CurrencyComponents {
  if (numCredits.isNaN() || numCredits.decimalPlaces() < 0) {
    reportToSentry(
      new Error(
        `numCredits is somehow invalid; numCredits: ${numCredits.toString()} decimalPlaces: ${numCredits.decimalPlaces()} isNaN: ${numCredits.isNaN()}`,
      ),
    );
    return {
      currencyValue: "NaN",
      suffix: displayCreditTypeName(creditType),
    };
  }

  if (creditType.id !== USD_CREDIT_ID) {
    return {
      currencyValue: numCredits.toNumber().toLocaleString(undefined, {
        ...(creditType.client_id === null
          ? {
              currency: creditType.name ?? "--",
              style: "currency",
              currencyDisplay: "narrowSymbol",
              minimumFractionDigits: hideDecimals
                ? undefined
                : Math.max(2, Math.min(numCredits.decimalPlaces(), 20)),
              maximumFractionDigits: hideDecimals ? 0 : undefined,
            }
          : {
              minimumFractionDigits: hideDecimals
                ? undefined
                : Math.min(numCredits.decimalPlaces(), 20),
              maximumFractionDigits: hideDecimals ? 0 : undefined,
            }),
      }),
      suffix: displayCreditTypeName(creditType),
    };
  } else {
    // The value is stored in cents, though it may be fractional.
    const precision = hideDecimals
      ? 0
      : Math.min(numCredits.decimalPlaces() + 2, 20);
    return {
      currencyValue: currency(numCredits.div(100).toFixed(precision), {
        precision: precision,
      }).format(),
      suffix: displayCreditTypeName(creditType),
    };
  }
}

export function displayCreditTypeName(
  creditType: Pick<CreditType, "id" | "name">,
): string {
  // In the frontend, USD is always entered and displayed in dollars instead,
  // of cents, so call it "USD" instead of "USD (cents)".
  return creditType.id === USD_CREDIT_ID ? "USD" : creditType.name ?? "--";
}

export function displayCreditsInCurrencyWithoutRounding(
  numCredits: Decimal,
  creditType: CreditType,
  hideSuffix?: boolean,
  hideDecimals?: boolean,
): string {
  const { currencyValue, suffix } = getCurrencyComponents(
    numCredits,
    creditType,
    hideDecimals,
  );
  // prevent weird constructs like "CHF 123.99 CHF"
  hideSuffix = hideSuffix || currencyValue.startsWith(suffix);
  if (hideSuffix === true) {
    return currencyValue;
  }
  return `${currencyValue} ${suffix}`;
}

export function creditConversionString(
  creditTypeConversion: CreditTypeConversion,
): string {
  return `${displayCreditsInCurrencyWithoutRounding(
    new Decimal(creditTypeConversion.toFiatConversionFactor ?? 0),
    creditTypeConversion.fiatCreditType,
  )} per ${creditTypeConversion.customCreditType.name}`;
}

export function roundedCurrencyString(
  amount: Decimal,
  creditType: CreditType,
  hideSuffix?: boolean,
): string {
  const isUSD = creditType.id === USD_CREDIT_ID;
  const parsedAmount = parseCurrencyAmount(amount, creditType);

  // Keep the greater of:
  // * two decimal places
  // * two significant digits
  // Examples:
  // 1.2345 -> 1.23
  // 0.000012345 -> 0.000012
  const rounded = displayCreditsInCurrencyWithoutRounding(
    parsedAmount.abs().lessThan(1)
      ? amount.toSignificantDigits(2)
      : amount.toDecimalPlaces(isUSD ? 0 : 2),
    creditType,
    hideSuffix,
  );
  return rounded;
}

export const RoundedCurrency: React.FC<{
  amount: Decimal;
  creditType: CreditType;
  hideSuffix?: boolean;
}> = ({ amount, creditType, hideSuffix }) => {
  // better than showing NaN
  // there are cases from before Nov 2023 that we have NaNs in the database
  // These were only for zero dollar line items so doesnt affect anything
  if (amount.isNaN()) {
    return <>--</>;
  }

  const unrounded = displayCreditsInCurrencyWithoutRounding(
    amount,
    creditType,
    hideSuffix,
  );
  const rounded = roundedCurrencyString(amount, creditType, hideSuffix);

  if (rounded !== unrounded) {
    return (
      <Tooltip inline content={unrounded}>
        {rounded}
      </Tooltip>
    );
  } else {
    return <>{unrounded}</>;
  }
};

// We always treat USD values in cents when they're a Decimal value passed around.
// All user-inputted credit values should get put through this function to convert
// to cents (if USD).
export function creditsFromInput(
  value: string | number | null | undefined,
  creditType: CreditType,
): Decimal {
  return creditType?.id === USD_CREDIT_ID
    ? new Decimal(value || 0).mul(100)
    : new Decimal(value || 0);
}

// Number credit values can be put through this function to convert
// to string values (only really useful if USD, since the strings stored
// are in cents but the credits used in display are dollars).
export function valueStringFromCredits(
  value: string | number | null | undefined,
  creditTypeId: string | undefined,
): string {
  return value === "" || value == null
    ? ""
    : creditTypeId === USD_CREDIT_ID
      ? new Decimal(value).div(100).toFixed()
      : String(value);
}

type CreditMathArguments = {
  startingDeductions: Decimal;
  endingDeductions: Decimal;
  amountGranted: Decimal;
  expiresBefore: dayjs.Dayjs;
  snapshotStartsAt: dayjs.Dayjs;
  snapshotEndsBefore: dayjs.Dayjs;
};

export const creditMath = ({
  startingDeductions,
  endingDeductions,
  amountGranted,
  expiresBefore,
  snapshotStartsAt,
  snapshotEndsBefore,
}: CreditMathArguments) => {
  const creditsUsed = endingDeductions.minus(startingDeductions);
  const startingCredits = amountGranted.minus(startingDeductions);
  const expiredCredits = expiresBefore.isBetween(
    snapshotStartsAt,
    snapshotEndsBefore,
    null,
    "(]",
  )
    ? startingCredits.minus(creditsUsed)
    : new Decimal(0);
  const endingCredits = startingCredits
    .minus(creditsUsed)
    .minus(expiredCredits);

  if (creditsUsed.toNumber() < 0) {
    throw new Error("Credits used can not be negative");
  } else if (startingCredits.toNumber() < 0) {
    throw new Error("Starting credits can not be negative");
  } else if (expiredCredits.toNumber() < 0) {
    throw new Error("Expired credits can not be negative");
  } else if (endingCredits.toNumber() < 0) {
    throw new Error("Ending credits can not be negative");
  }

  return [startingCredits, endingCredits, expiredCredits, creditsUsed];
};

export function displayCostBasis(
  costBasis: Decimal,
  amountGrantedCreditType: CreditType,
  amountPaidCreditType: CreditType,
) {
  const adjustedCostBasis = displayCreditsInCurrencyWithoutRounding(
    costBasis.mul(amountGrantedCreditType.id === USD_CREDIT_ID ? 100 : 1),
    amountPaidCreditType,
    amountPaidCreditType.id === USD_CREDIT_ID,
  );
  return `${adjustedCostBasis} per ${displayCreditTypeName(
    amountGrantedCreditType,
  )}${amountGrantedCreditType.client_id ? " unit" : ""}`;
}
