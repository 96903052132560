import React from "react";
import { SectionHeader } from "tenaissance/components/SectionHeader";
import { TextInput } from "tenaissance/components/Input";
import { Button } from "tenaissance/components/Button";
import { Table } from "tenaissance/components/Table";
import { EmptyState } from "tenaissance/components/EmptyState";
import { useRateCardContext } from "./RateCardContext";

export const Step1: React.FC = () => {
  const { ctrl } = useRateCardContext();
  const rates = ctrl.get("rates") || [];

  const handleRateCardNameUpdate = (value: string) => {
    if (ctrl.get("name") !== value) ctrl.update({ name: value.trim() });
  };

  const handleRateCardDescriptionUpdate = (value: string) => {
    if (ctrl.get("description") !== value) ctrl.update({ description: value });
  };

  return (
    <div className="flex flex-col items-start gap-[40px]">
      <div className="display flex flex-col gap-[24px] self-stretch">
        <TextInput
          fullWidth
          label="Name"
          helpText="Enter the name of the product"
          placeholder="Product name"
          onChange={(meta: { value: string }) =>
            handleRateCardNameUpdate(meta.value)
          }
        />
        <TextInput
          fullWidth
          label="Description (optional)"
          helpText="Add an optional description to the product"
          placeholder="Enter description"
          onChange={(meta: { value: string }) => {
            handleRateCardDescriptionUpdate(meta.value);
          }}
        />
      </div>
      <div className="flex flex-col gap-[24px]">
        <SectionHeader
          title="Add aliases (optional)"
          subtitle="Reference a rate card via an alias. This alias can be used when
          provisioning a customer to a contract."
          bottomBorder={false}
        />
        <Button
          text="Add alias"
          leadingIcon="plus"
          onClick={() => {
            // toDo: [PENG-715] Set product alias
          }}
        />
      </div>
      <SectionHeader
        title="Products"
        subtitle="Add products to this rate card"
        bottomBorder={false}
      />
      <div className="w-full">
        <Table
          title="Select products to rate"
          columns={[]}
          data={rates}
          searchOptions={{
            showSearch: true,
            onSearch: () => {
              // toDo: [PENG-718] Add search functionality
            },
          }}
          emptyState={<EmptyState icon="x" mainText="Oops!" />}
          paginationOptions={{
            type: "clientSide",
          }}
          topBarActions={[
            <Button
              onClick={() => {
                // [PENG-717] Add filter functionality
              }}
              text="Add filter"
              leadingIcon="filterLines"
              theme="secondary"
            />,
          ]}
        />
      </div>
    </div>
  );
};
