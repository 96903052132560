import { CopyableID } from "components/CopyableID";
import { PageContainer } from "components/PageContainer";
import { ParagraphSkeleton } from "components/Skeleton";
import { Breadcrumbs } from "lib/breadcrumbs";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import {
  getUserFacingErrorMessage,
  isGqlNotFoundError,
} from "lib/errors/errorHandling";
import { useRequiredParam } from "lib/routes/params";
import { NotFoundPage } from "pages/404";
import React from "react";

import {
  AvatarWithName,
  Badge,
  Body,
  Caption,
  Subtitle,
  Tooltip,
} from "design-system";

import { IconButton } from "tenaissance/components/IconButton";

import { printDate, toDayjs, useNow } from "lib/date";
import { ContractProductFlyover } from "../ContractProductFlyover";
import { PopoverMenu } from "components/PopoverMenu";
import { SortProductsFlyover } from "../../components/SortProductsFlyover";
import {
  AddRateDocument,
  UpdateRateCardDocument,
} from "../CreateAndEditRateCard/data.graphql";
import {
  useRateCardQuery,
  useUpdateRateCardArchivedMutation,
} from "./data.graphql";
import { gatedAction, useAuthCheck } from "lib/useAuthCheck";
import { RateCardAliases } from "../CreateAndEditRateCard/RateCardAliasesFlyout";
import { EmptyState } from "components/EmptyState";
import { MoveRateCardProductsDocument } from "pages/Contracts/components/SortProductsFlyover/data.graphql";
import { ConfirmModal } from "pages/Contracts/components/ConfirmModal";
import { useSnackbar } from "components/Snackbar";
import { RateSchedulePanel } from "pages/Contracts/components/RateSchedulePanel";
import { RateCardDownloadModal } from "./RateCardDownloadModal";
import { RateCardCPUFlyover } from "./RateCardCPUFlyover";
import pluralize from "pluralize";
import {
  USD_CREDIT_TYPE,
  displayCreditsInCurrencyWithoutRounding,
} from "lib/credits";
import Decimal from "decimal.js";
import { useFeatureFlag } from "../../../../lib/launchdarkly";

export const RateCardDetails: React.FC = () => {
  const now = useNow();
  const rateCardId = useRequiredParam("id");

  const [flyover, setFlyover] = React.useState<
    | {
        type: "product";
        productId: string;
        pricingGroupValue: Record<string, string> | null;
      }
    | { type: "sortProducts" }
    | { type: "aliases" }
    | { type: "confirmArchive" }
    | { type: "pickDateAndDownload" }
    | { type: "customPricingUnits" }
    | undefined
  >();

  const rateCardReq = useRateCardQuery({
    variables: {
      id: rateCardId,
    },
  });
  const rateCard = rateCardReq.data?.contract_pricing.rate_card;
  const fiatCreditType = rateCard?.fiat_credit_type
    ? {
        ...rateCard?.fiat_credit_type,
        client_id: null,
        environment_type: null,
      }
    : USD_CREDIT_TYPE;
  const numCustomPricingUnits = rateCard?.credit_type_conversions?.length ?? 0;

  const pushMessage = useSnackbar();
  const [updateRateCardArchived, updateRateCardArchivedResp] =
    useUpdateRateCardArchivedMutation({
      update(cache) {
        cache.evict({ id: `RateCardMetadata:${rateCardId}` });
        cache.evict({ fieldName: "contract_pricing" });
      },
    });

  const canEditRateCard = !!useAuthCheck(UpdateRateCardDocument, true).allowed;
  const canEditRateCardProductOrder = !!useAuthCheck(
    MoveRateCardProductsDocument,
    true,
  ).allowed;

  const canArchiveRateCard = useFeatureFlag("enable-archive-rate-card", false);

  if (isGqlNotFoundError(rateCardReq.error)) {
    return <NotFoundPage />;
  }

  return (
    <PageContainer
      title={
        !rateCard
          ? Breadcrumbs.loading
          : Breadcrumbs.from(
              {
                type: "back",
                label: "Back to rate cards",
                routePath: "/contract-pricing/rate-cards",
              },
              {
                type: "breadcrumb",
                label: rateCard.name,
                badge: rateCard.archived ? (
                  <Badge theme="error" type="dark">
                    ARCHIVED
                  </Badge>
                ) : undefined,
              },
            )
      }
      action={
        <PopoverMenu
          positions={["bottom"]}
          align="end"
          options={[
            {
              content: "Manage custom fields...",
              routePath: `/custom-fields/rate-card/${rateCardId}`,
            },
            gatedAction(canEditRateCard, {
              content: "Edit rate card...",
              routePath: `/contract-pricing/rate-cards/${rateCardId}/edit`,
            }),
            gatedAction(canEditRateCardProductOrder, {
              content: "Edit product order...",
              onClick: () => {
                setFlyover({ type: "sortProducts" });
              },
            }),
            {
              content: "Download CSV ...",
              onClick: () => {
                setFlyover({ type: "pickDateAndDownload" });
              },
            },
            !canArchiveRateCard
              ? []
              : !rateCard?.archived
                ? gatedAction(canEditRateCard, {
                    content: "Archive rate card...",
                    onClick: () => {
                      setFlyover({ type: "confirmArchive" });
                    },
                  })
                : gatedAction(canEditRateCard, {
                    content: "Unarchive rate card",
                    onClick: () => {
                      void updateRateCardArchived({
                        variables: {
                          id: rateCardId,
                          archived: false,
                        },
                      }).then(
                        () => {
                          pushMessage({
                            type: "success",
                            content: "Rate card unarchived",
                          });
                        },
                        (error) => {
                          const msg = getUserFacingErrorMessage(error);
                          pushMessage({
                            type: "error",
                            content: `Failed to unarchive rate card: ${msg}`,
                          });
                        },
                      );
                    },
                  }),
          ].flat()}
        >
          {(onClick) => (
            <IconButton
              onClick={onClick}
              theme="secondary"
              icon="dotsVertical"
            />
          )}
        </PopoverMenu>
      }
      tabs={[
        {
          name: "Rate overview",
          routePath: `/contract-pricing/rate-cards/${rateCardId}`,
        },
      ]}
    >
      {flyover?.type === "pickDateAndDownload" && (
        <RateCardDownloadModal
          at={now}
          rateCardId={rateCardId}
          onStartDownload={() => {
            pushMessage({
              type: "info",
              content: "Preparing download...",
            });
          }}
          onComplete={() => {
            pushMessage({
              type: "success",
              content: "Downloaded CSV file",
            });
            setFlyover(undefined);
          }}
          onCancel={() => {
            setFlyover(undefined);
          }}
          onError={() => {
            pushMessage({
              type: "error",
              content: "Failed to download CSV file. Please try again.",
            });
            setFlyover(undefined);
          }}
        />
      )}
      {flyover?.type === "sortProducts" && (
        <SortProductsFlyover
          rateCardId={rateCardId}
          onClose={() => {
            setFlyover(undefined);
          }}
        />
      )}
      {flyover?.type === "product" && (
        <ContractProductFlyover
          productId={flyover.productId}
          pricingGroupValues={flyover.pricingGroupValue ?? undefined}
          onRequestClose={() => setFlyover(undefined)}
        />
      )}
      {flyover?.type === "aliases" && rateCard && (
        <RateCardAliases
          aliases={rateCard.aliases}
          onRequestClose={() => setFlyover(undefined)}
        />
      )}
      {flyover?.type === "confirmArchive" && canArchiveRateCard && (
        <ConfirmModal
          title="Archive rate card?"
          loading={updateRateCardArchivedResp.loading}
          onCancel={() => {
            setFlyover(undefined);
          }}
          onConfirm={() => {
            void updateRateCardArchived({
              variables: {
                id: rateCardId,
                archived: true,
              },
            })
              .then(
                () => {
                  pushMessage({
                    type: "success",
                    content: "Rate card archived",
                  });
                },
                (error) => {
                  const msg = getUserFacingErrorMessage(error);
                  pushMessage({
                    type: "error",
                    content: `Failed to archive rate card: ${msg}`,
                  });
                },
              )
              .finally(() => {
                setFlyover(undefined);
              });
          }}
        >
          <p>
            Are you sure you want to archive this rate card? Any contracts which
            are using this rate card will continue to function as normal, but it
            won't be a valid option for newly created contracts.
          </p>
          <p>You can always unarchive it later if you need to use it again.</p>
        </ConfirmModal>
      )}
      {flyover?.type === "customPricingUnits" &&
        fiatCreditType &&
        rateCard?.credit_type_conversions && (
          <RateCardCPUFlyover
            fiatCreditType={fiatCreditType}
            creditTypeConversions={rateCard.credit_type_conversions}
            onRequestClose={() => setFlyover(undefined)}
          />
        )}
      {rateCardReq.loading || !rateCard ? (
        <div className="pb-24 pl-12 pr-12 pt-24">
          <ParagraphSkeleton className="w-1/4 min-w-[400px]" />
        </div>
      ) : rateCardReq.error ? (
        <ErrorEmptyState
          title="We ran into an issue loading this rate card"
          error={rateCardReq.error}
        />
      ) : (
        <div className="grid gap-x-12 gap-y-24 py-24">
          <div className="flex gap-[72px]">
            <div>
              <Caption className="mb-[6px]">Created by</Caption>
              <div className="flex items-center gap-12">
                <AvatarWithName
                  id={rateCard.Creator.id}
                  name={rateCard.Creator.name}
                  deprecated_at={rateCard.Creator.deprecated_at}
                />
                <Body level={2} className="mb-0 text-grey-700">
                  Created {printDate(toDayjs(rateCard.created_at))}
                </Body>
              </div>
            </div>
            <div>
              <Caption className="mb-[5px]">Rate card ID</Caption>
              <CopyableID id={rateCard.id} label="rate card ID" />
            </div>
            {rateCard.aliases.length ? (
              <div>
                <div>
                  <Caption>Rate card alias</Caption>
                </div>
                <div
                  className={
                    rateCard.aliases.length
                      ? "flex items-center"
                      : "mt-8 flex items-center"
                  }
                >
                  <Subtitle level={4}>
                    {rateCard.aliases.length}{" "}
                    {pluralize("alias", rateCard.aliases.length)}
                  </Subtitle>
                  {rateCard.aliases.length ? (
                    <Tooltip
                      content={
                        <>
                          View rate card{" "}
                          {pluralize("alias", rateCard.aliases.length)}
                        </>
                      }
                    >
                      <IconButton
                        onClick={() => setFlyover({ type: "aliases" })}
                        theme="tertiary"
                        icon="dotsVertical"
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="flex gap-[72px]">
            <div>
              <Caption className="mb-[9px]">Currency</Caption>
              <Subtitle level={4}>{fiatCreditType.name}</Subtitle>
            </div>
            {numCustomPricingUnits > 0 && fiatCreditType ? (
              <div>
                <Caption>Custom pricing units</Caption>
                <div className="flex">
                  <Subtitle level={4} className="flex items-center">
                    {rateCard.credit_type_conversions?.map((conversion, i) => (
                      <span key={i}>
                        <Tooltip
                          inline
                          content={
                            <>
                              {displayCreditsInCurrencyWithoutRounding(
                                new Decimal(conversion.fiat_per_custom_credit),
                                fiatCreditType,
                              )}{" "}
                              per {conversion.custom_credit_type.name}
                            </>
                          }
                        >
                          {conversion.custom_credit_type.name}
                        </Tooltip>
                        <span
                          key={i}
                          className={
                            i === numCustomPricingUnits - 1 ? "hidden" : "pr-4"
                          }
                        >
                          ,
                        </span>
                      </span>
                    ))}
                  </Subtitle>
                  <IconButton
                    onClick={() => setFlyover({ type: "customPricingUnits" })}
                    theme="tertiary"
                    icon="dotsVertical"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div>
            <RateSchedulePanel
              title="Rates"
              design="noPanel"
              startingAt={now.toDate()}
              endingBefore={null}
              rateCardId={rateCardId}
              noTruncate
              // explicitly disable overrides column in table
              overrides={false}
              onRowClick={(row) =>
                setFlyover({
                  type: "product",
                  productId: row.product.id,
                  pricingGroupValue: row.pricingGroupValues,
                })
              }
              emptyState={({ search }) => (
                <EmptyState
                  title="No rates found"
                  {...(search
                    ? { subtitle: "Try searching for something else" }
                    : {
                        buttonAuthDoc: AddRateDocument,
                        buttonIcon: "plusCircle",
                        buttonText: "Add a rate",
                        buttonRoutePath: "edit?add=true",
                      })}
                />
              )}
            />
          </div>
        </div>
      )}
    </PageContainer>
  );
};
