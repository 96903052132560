import React, { useState } from "react";
import { MenuItemProps } from "components/PopoverMenu";
import { Tooltip } from "design-system";
import { useNavigate } from "lib/useNavigate";
import ArchiveBillableMetricModal from "lib/billableMetrics/components/ArchiveBillableMetricModal";
import { useFeatureFlag } from "lib/launchdarkly";
import { useEnvironment } from "lib/environmentSwitcher/context";
import CopyBillableMetricModal from "components/CopyAssetToEnvironmentModal/CopyBillableMetricModal";
import { gatedAction, useAuthCheck } from "lib/useAuthCheck";
import { InsertBillableMetricDocument } from "pages/NewBillableMetric/queries.graphql";
import { ArchiveBillableMetricDocument } from "./components/ArchiveBillableMetricModal/queries.graphql";
import { MetricMetadata } from "./types";

type Metric = MetricMetadata;

export const useActions = () => {
  const [archiveMetric, setArchiveMetric] = useState<Metric | null>(null);
  const [metricToCopy, setMetricToCopy] = useState<Metric | null>(null);
  const navigate = useNavigate();
  const { environments, environment } = useEnvironment();
  const canCreateBillableMetric = !!useAuthCheck(
    InsertBillableMetricDocument,
    true,
  ).allowed;
  const canArchiveBillableMetric = !!useAuthCheck(
    ArchiveBillableMetricDocument,
    true,
  ).allowed;

  const copyAssetsFF = useFeatureFlag<string[]>(
    "copy-assets-to-environment",
    [],
  );
  const copyMetricToEnvironmentEnabled =
    copyAssetsFF?.includes("BILLABLE_METRIC");

  const getActions = (metric: Metric) => {
    let actions: MenuItemProps[] = [];
    if (!metric) {
      throw new Error("Error creating Billable Metric actions");
    }

    actions.push(
      gatedAction(canCreateBillableMetric, {
        onClick: () =>
          navigate(
            `/billable-metrics/${
              metric.metricType === "seat" ? "seats/" : ""
            }new/${metric.id}`,
          ),
        content: "Duplicate...",
      }),
    );

    if (copyMetricToEnvironmentEnabled && environments.length > 1) {
      const text =
        environments.length === 2
          ? `Copy to ${
              environments.filter((e) => e !== environment)[0].name
            }...`
          : "Copy to another environment...";
      actions.push({
        content: text,
        onClick: () => setMetricToCopy(metric),
      });
    }

    if (metric.deleted_at === null) {
      actions.push(
        gatedAction(canArchiveBillableMetric, {
          disabled: !!metric.active_product_count,
          onClick: () => setArchiveMetric(metric),
          content: metric.active_product_count ? (
            <Tooltip content="Billable metrics in use cannot be archived">
              Archive billable metric...
            </Tooltip>
          ) : (
            "Archive..."
          ),
        }),
      );
    }

    return actions;
  };

  const archiveModal = archiveMetric ? (
    <ArchiveBillableMetricModal
      billableMetricId={archiveMetric.id}
      billableMetricName={archiveMetric.name}
      metricType={archiveMetric.metricType}
      onClose={() => setArchiveMetric(null)}
    />
  ) : null;

  const copyMetricModal = metricToCopy ? (
    <CopyBillableMetricModal
      onClose={() => setMetricToCopy(null)}
      metricId={metricToCopy.id}
      metricName={metricToCopy.name}
      metricType={metricToCopy.metricType}
    />
  ) : null;

  return { getActions, archiveModal, copyMetricModal };
};
