import React, { useEffect, useState } from "react";
import { ButtonDropdown } from "design-system";

import { useRequiredParam } from "lib/routes/params";

import { ActivityList } from "pages/Contracts/components/ActivityList";
import { useNow, toDayjs } from "lib/date";
import { useApolloResp } from "pages/Contracts/lib/ApolloResp";
import { ContractProductFlyover } from "pages/Contracts/Pricing/ContractProductFlyover";

import { CustomerContractLayout } from "../CustomerContractLayout";
import { useAsyncCommitUsage } from "../Usage";
import { TopLevelDetails } from "./TopLevelDetails";
import { CommitsOverview } from "./CommitsOverview";
import { AdditionalTermsTable } from "../../../components/AdditionalTermsTable";
import { getTerms, getRowsFromTerms } from "./AdditionalTermRows";
import {
  DiscountTermFlyover,
  ProServiceTermFlyover,
  ResellerRoyaltyTermFlyover,
  ScheduledChargeTermFlyover,
} from "./ContractTermFlyover";

import {
  ContractMetadataFragment,
  ContractOverviewQuery,
  DiscountTermFlyoverFragment,
  ProServiceTermFlyoverFragment,
  ScheduledChargeTermFlyoverFragment,
  useContractOverviewQuery,
} from "./data.graphql";
import { Contract } from "pages/Contracts/lib/Contract";
import { EditContractEndDateModal } from "./EditContractEndDateModal";
import { ContractMetadataFlyover } from "./ContractMetadataFlyover";
import { ButtonDropdownItem } from "design-system/components/ButtonDropdown";
import { ArchiveContractModal } from "./ArchiveContractModal";
import { RateSchedulePanel } from "pages/Contracts/components/RateSchedulePanel";
import { describeOverride } from "pages/Contracts/lib/Override";
import { Commit } from "pages/Contracts/lib/Commit";
import { ContractRatesDownloadModal } from "./ContractRatesDownloadModal";

export const CustomerContractOverviewPage: React.FC = () => {
  const now = useNow();
  const contractId = useRequiredParam("contractId");
  const customerId = useRequiredParam("customerId");
  const contractQuery = useContractOverviewQuery({
    variables: { contractId, customerId },
  });
  const { refetch } = contractQuery;
  const req = useApolloResp(contractQuery);

  const [showArchiveContractModal, setShowArchiveContractModal] =
    useState(false);

  const [showDownloadRatesModal, setShowDownloadRatesModal] = useState(false);
  const [showEditContractEndDateModal, setShowEditContractEndDateModal] =
    useState(false);
  const [showContractMetadataFlyover, setShowContractMetadataFlyover] =
    useState(false);
  const routePath = Contract.getRoutePath({
    id: contractId,
    customer: { id: customerId },
  });

  const dropdownItems: ButtonDropdownItem[] = [];
  if (req.state === "success") {
    dropdownItems.push({
      label: "View metadata...",
      onClick: () => {
        setShowContractMetadataFlyover(true);
      },
    });
    dropdownItems.push({
      label: "Manage custom fields...",
      routePath: `/custom-fields/contract/${contractId}`,
    });
    dropdownItems.push({
      label: "Add an amendment...",
      routePath: `${routePath}/amend`,
      disabled: !!(
        req.customer.contract?.ending_before &&
        toDayjs(req.customer.contract.ending_before).isSameOrBefore(now)
      ),
    });
    dropdownItems.push({
      label: "Edit contract end date...",
      onClick: () => {
        setShowEditContractEndDateModal(true);
      },
    });
    dropdownItems.push({
      label: "Download rates...",
      onClick() {
        setShowDownloadRatesModal(true);
      },
    });
    if (!req.customer.contract?.archived_at) {
      const activeNextContract = req.customer.contract?.transitions.find(
        (transition) =>
          req.customer.contract?.id == transition.from_contract.id &&
          transition.to_contract.archived_at == null,
      )?.to_contract;
      dropdownItems.push({
        label: "Archive contract...",
        onClick: async () => {
          setShowArchiveContractModal(true);
        },
        disabled: activeNextContract != null,
        disabledTooltip:
          activeNextContract &&
          `Transition contract ${activeNextContract.name} must be archived before this contract may be archived`,
      });
    }
  }

  return (
    <CustomerContractLayout
      rootReq={req}
      tabsAction={
        <ButtonDropdown
          buttonTheme="gray"
          buttonType="fill"
          icon="ellipsisVertical"
          disabled={req.state !== "success"}
          items={dropdownItems}
          align="end"
        />
      }
      content={({ contract }) => (
        <>
          {showEditContractEndDateModal && (
            <EditContractEndDateModal
              onSuccess={() => {
                refetch().catch(console.error);
                setShowEditContractEndDateModal(false);
              }}
              onCancel={() => setShowEditContractEndDateModal(false)}
              contract={contract}
            />
          )}
          {showDownloadRatesModal && (
            <ContractRatesDownloadModal
              onComplete={() => {
                refetch().catch(console.error);
                setShowDownloadRatesModal(false);
              }}
              onCancel={() => setShowDownloadRatesModal(false)}
              onStartDownload={() => setShowDownloadRatesModal(false)}
              onError={() => {}}
              contractId={contract.id}
              customerId={customerId}
              at={now}
            />
          )}
          {showArchiveContractModal && (
            <ArchiveContractModal
              onSuccess={() => {
                setShowArchiveContractModal(false);
                refetch().catch(console.error);
              }}
              onCancel={() => setShowArchiveContractModal(false)}
              contract_id={contract.id}
              customer_id={customerId}
            ></ArchiveContractModal>
          )}
          <CustomerContractOverview
            contract={contract}
            customerId={customerId}
            showActivityList
            showAmendments
            showContractMetadataFlyover={showContractMetadataFlyover}
            setShowContractMetadataFlyover={setShowContractMetadataFlyover}
          />
        </>
      )}
    />
  );
};

export function CustomerContractOverview({
  contract,
  customerId,
  showContractMetadataFlyover = false,
  setShowContractMetadataFlyover = () => {},
  showActivityList = false,
  showAmendments = false,
}: {
  contract: Exclude<
    Exclude<ContractOverviewQuery["customer"], null>["contract"],
    null
  >;

  customerId: string;
  showContractMetadataFlyover?: boolean;
  setShowContractMetadataFlyover?: (show: boolean) => void;
  showActivityList?: boolean;
  showAmendments?: boolean;
}) {
  const now = useNow();
  const usage = useAsyncCommitUsage({ customerId, contractId: contract.id });

  const [flyoverTarget, setFlyoverTarget] = React.useState<
    | { type: "product"; id: string }
    | { type: "discount"; term: DiscountTermFlyoverFragment }
    | { type: "scheduled_charge"; term: ScheduledChargeTermFlyoverFragment }
    | { type: "pro_service"; term: ProServiceTermFlyoverFragment }
    | { type: "reseller_royalty"; term: Contract.ResellerRoyaltyState }
    | { type: "contract"; term: ContractMetadataFragment }
  >();

  useEffect(() => {
    if (showContractMetadataFlyover) {
      setFlyoverTarget({ type: "contract", term: contract });
    } else {
      setFlyoverTarget(undefined);
    }
  }, [showContractMetadataFlyover]);

  const { commits, credits } = Commit.splitCommitsAndCredits(
    showAmendments
      ? [
          ...contract.commits_union,
          ...contract.amendments.flatMap((a) => a.commits_union),
        ]
      : contract.commits_union,
  );

  const termRows = getRowsFromTerms(
    getTerms(contract, now, { includeAmendments: showAmendments }),
    now,
    (term) => {
      switch (term.__typename) {
        case "Discount":
          setFlyoverTarget({ type: "discount", term });
          break;
        case "ScheduledCharge":
          setFlyoverTarget({ type: "scheduled_charge", term });
          break;
        case "ProService":
          setFlyoverTarget({ type: "pro_service", term });
          break;
        case "AWSRoyalty":
        case "AWSProServiceRoyalty":
        case "GCPRoyalty":
        case "GCPProServiceRoyalty":
          setFlyoverTarget({ type: "reseller_royalty", term });
          break;
        case "AWSRoyaltyUpdate":
        case "AWSProServiceRoyaltyUpdate":
        case "GCPRoyaltyUpdate":
        case "GCPProServiceRoyaltyUpdate":
          return;
        default:
          term satisfies never;
      }
    },
  );

  return (
    <div className="flex flex-col gap-12">
      {/* Flyover */}
      {((): React.ReactElement | null => {
        if (!flyoverTarget) {
          return null;
        }

        const onRequestClose = () => setFlyoverTarget(undefined);

        switch (flyoverTarget.type) {
          case "product":
            return (
              <ContractProductFlyover
                productId={flyoverTarget.id}
                onRequestClose={onRequestClose}
              />
            );
          case "discount":
            return (
              <DiscountTermFlyover
                term={flyoverTarget.term}
                onRequestClose={onRequestClose}
              />
            );
          case "scheduled_charge":
            return (
              <ScheduledChargeTermFlyover
                term={flyoverTarget.term}
                onRequestClose={onRequestClose}
              />
            );
          case "pro_service":
            return (
              <ProServiceTermFlyover
                term={flyoverTarget.term}
                onRequestClose={onRequestClose}
              />
            );
          case "reseller_royalty":
            return (
              <ResellerRoyaltyTermFlyover
                term={flyoverTarget.term}
                onRequestClose={onRequestClose}
              />
            );
          case "contract":
            return (
              <ContractMetadataFlyover
                contract={flyoverTarget.term}
                onRequestClose={() => setShowContractMetadataFlyover(false)}
                contractName={Contract.getName(contract)}
              />
            );
        }
      })()}

      {!showActivityList && <TopLevelDetails contract={contract} />}

      {showActivityList && (
        <div className="mt-12 flex flex-row gap-12">
          <div className="flex w-3/4 flex-col gap-12">
            <TopLevelDetails contract={contract} />
          </div>

          <div className="w-1/4">
            <ActivityList
              loading={false}
              title="Recent lifecycle activity"
              subtitle="Activity across this contract"
              hideContractOrPlanName
              activity={Contract.getActivityItems(contract, now, true)}
              itemCount={3}
            />
          </div>
        </div>
      )}

      {commits.length > 0 && (
        <CommitsOverview commits={commits} asyncUsage={usage} />
      )}

      {credits.length > 0 && (
        <CommitsOverview commits={credits} asyncUsage={usage} asCredit />
      )}

      <AdditionalTermsTable rows={termRows} />

      {contract.rate_card && (
        <RateSchedulePanel
          rateCardId={contract.rate_card.id}
          title={contract.rate_card.name}
          startingAt={new Date(contract.starting_at)}
          endingBefore={
            contract.ending_before ? new Date(contract.ending_before) : null
          }
          onRowClick={(row) =>
            setFlyoverTarget({ type: "product", id: row.product.id })
          }
          overrides={(showAmendments
            ? [
                ...contract.overrides,
                ...contract.amendments.flatMap((a) => a.overrides),
              ]
            : contract.overrides
          ).map((o) => describeOverride(now, o))}
        />
      )}
    </div>
  );
}
