/* 8a0b7f88895ccb3087b0d8a59b072e6b79a1a8e3
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddAzureMarketplaceToCustomerMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  azure_subscription_id: Types.Scalars['String'];
}>;


export type AddAzureMarketplaceToCustomerMutation = { __typename?: 'Mutation', add_azure_marketplace_to_customer: { __typename?: 'AddMarketplaceToCustomerMutationResponse', billingProviderCustomer: { __typename?: 'BillingProviderCustomer', billing_provider_customer_id: string }, customerConfigs: Array<{ __typename?: 'CustomerConfig', id: string, key: string, value: string }> } };


export const AddAzureMarketplaceToCustomerDocument = gql`
    mutation AddAzureMarketplaceToCustomer($customer_id: uuid!, $azure_subscription_id: String!) {
  add_azure_marketplace_to_customer(
    customer_id: $customer_id
    azure_subscription_id: $azure_subscription_id
  ) {
    billingProviderCustomer {
      billing_provider_customer_id
    }
    customerConfigs {
      id
      key
      value
    }
  }
}
    `;
export type AddAzureMarketplaceToCustomerMutationFn = Apollo.MutationFunction<AddAzureMarketplaceToCustomerMutation, AddAzureMarketplaceToCustomerMutationVariables>;

/**
 * __useAddAzureMarketplaceToCustomerMutation__
 *
 * To run a mutation, you first call `useAddAzureMarketplaceToCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAzureMarketplaceToCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAzureMarketplaceToCustomerMutation, { data, loading, error }] = useAddAzureMarketplaceToCustomerMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      azure_subscription_id: // value for 'azure_subscription_id'
 *   },
 * });
 */
export function useAddAzureMarketplaceToCustomerMutation(baseOptions?: Apollo.MutationHookOptions<AddAzureMarketplaceToCustomerMutation, AddAzureMarketplaceToCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAzureMarketplaceToCustomerMutation, AddAzureMarketplaceToCustomerMutationVariables>(AddAzureMarketplaceToCustomerDocument, options);
      }
export type AddAzureMarketplaceToCustomerMutationHookResult = ReturnType<typeof useAddAzureMarketplaceToCustomerMutation>;
export type AddAzureMarketplaceToCustomerMutationResult = Apollo.MutationResult<AddAzureMarketplaceToCustomerMutation>;
export type AddAzureMarketplaceToCustomerMutationOptions = Apollo.BaseMutationOptions<AddAzureMarketplaceToCustomerMutation, AddAzureMarketplaceToCustomerMutationVariables>;