import React, { useMemo, useState } from "react";

import styles from "./index.module.less";
import {
  GetBillingProvidersSettingsQuery,
  useGetActiveMarketplaceContractsQuery,
  useGetBillingProvidersSettingsQuery,
  useGetClientConfigQuery,
} from "./queries.graphql";
import {
  BillingProviderDeliveryMethod_Enum,
  BillingProviderEnum_Enum,
  ClientConfig,
  ClientConfigKeyEnum_Enum,
} from "types/generated-graphql/__types__";
import { StripeBillingProvider } from "./stripe";
import { parseISO } from "date-fns";
import { useEnvironment } from "lib/environmentSwitcher/context";
import { SalesforceConnection } from "./salesforce";
import { Body, Headline, Hyperlink } from "design-system";
import { NetSuiteConnection } from "./netsuite";
import { useFeatureFlag } from "../../../../../../lib/launchdarkly";
import { CustomerMarketplaceCard } from "./CustomerMarketplaceCard";
import { ReactComponent as AzureMarketplaceLogo } from "/src/pages/GeneralSettings/azure.svg";
import { ReactComponent as AWSLogo } from "/src/pages/GeneralSettings/aws.svg";
import { ReactComponent as GCPLogo } from "/src/pages/GeneralSettings/gcp.svg";
import AWSMarketplaceSettingsModal from "../../components/AWSMarketplaceSettingsModal";
import AzureMarketplaceSettingsModal from "../../components/AzureMarketplaceSettingsModal";
import { format } from "date-fns";
// import { subHours, addHours } from "date-fns";

type BillingProviderSectionProps = {
  customerId: string;
};

type SalesforceConnectionSettings = {
  salesforceAccountId?: string;
  connectionDate?: Date;
};

type NetSuiteConnectionSettings = {
  netSuiteCustomerId?: string;
  connectionDate?: Date;
};

type AWSMarketplaceSettings = {
  customerId?: string;
  productCode?: string;
  region?: string;
  expirationTimestamp?: Date;
  connectionDate?: Date;
  meteringDisabled?: boolean;
};

type AzureMarketplaceSettings = {
  customerId?: string;
  subscriptionId?: string;
  subscriptionStatus?: string;
  planId?: string;
  startDate?: Date;
  expirationDate?: Date;
  connectionDate?: Date;
  meteringDisabled?: boolean;
};

type StripeSettings = {
  customerId?: string;
  collectionMethod?: string;
  connectionDate?: Date;
};

type GcpSettings = {
  connectionDate?: Date;
  expirationDate?: Date;
};

type MarketplaceProviderName =
  | BillingProviderEnum_Enum.AwsMarketplace
  | BillingProviderEnum_Enum.Azure
  | BillingProviderEnum_Enum.Gcp
  | BillingProviderEnum_Enum.Stripe;

const parseBillingProviderSettings = (
  data: GetBillingProvidersSettingsQuery,
) => {
  let clientHasAws = false;
  let clientHasAzure = false;

  const { clientHasStripe, stripeSettings } = parseStripeSettings(data);

  for (const bpToken of data?.billingProviderTokens ?? []) {
    if (bpToken.billing_provider == BillingProviderEnum_Enum.AwsMarketplace) {
      clientHasAws = true;
    } else if (bpToken.billing_provider == BillingProviderEnum_Enum.Azure) {
      clientHasAzure = true;
    }
  }

  const awsSettings: AWSMarketplaceSettings = {};
  const azureSettings: AzureMarketplaceSettings = {};
  for (const bpCustomer of data?.customer?.BillingProviderCustomers ?? []) {
    if (
      bpCustomer.billing_provider === BillingProviderEnum_Enum.AwsMarketplace
    ) {
      awsSettings.customerId = bpCustomer.billing_provider_customer_id;
      awsSettings.connectionDate = parseISO(bpCustomer.created_at);
    } else if (bpCustomer.billing_provider === BillingProviderEnum_Enum.Azure) {
      azureSettings.subscriptionId = bpCustomer.billing_provider_customer_id;
      azureSettings.connectionDate = parseISO(bpCustomer.created_at);
    }
  }
  const salesforceConnectionSettings: SalesforceConnectionSettings = {};
  const netSuiteConnectionSettings: NetSuiteConnectionSettings = {};
  for (const customerConfig of data?.customerConfigs ?? []) {
    if (customerConfig.key === "aws_product_code") {
      awsSettings.productCode = customerConfig.value;
    } else if (customerConfig.key === "aws_region") {
      awsSettings.region = customerConfig.value;
    } else if (customerConfig.key === "aws_expiration_timestamp") {
      awsSettings.expirationTimestamp = parseISO(customerConfig.value);
    } else if (customerConfig.key === "aws_metering_disabled") {
      awsSettings.meteringDisabled = customerConfig.value === "true";
    } else if (customerConfig.key === "azure_subscription_status") {
      azureSettings.subscriptionStatus = customerConfig.value;
    } else if (customerConfig.key === "azure_plan_id") {
      azureSettings.planId = customerConfig.value;
    } else if (customerConfig.key === "azure_start_date") {
      azureSettings.startDate = parseISO(customerConfig.value);
    } else if (customerConfig.key === "azure_expiration_date") {
      azureSettings.expirationDate = parseISO(customerConfig.value);
    } else if (customerConfig.key === "azure_metering_disabled") {
      azureSettings.meteringDisabled = customerConfig.value === "true";
    } else if (customerConfig.key === "salesforce_account_id") {
      salesforceConnectionSettings.salesforceAccountId = customerConfig.value;
      salesforceConnectionSettings.connectionDate = parseISO(
        customerConfig.updated_at,
      );
    } else if (customerConfig.key === "netsuite_customer_id") {
      netSuiteConnectionSettings.netSuiteCustomerId = customerConfig.value;
      netSuiteConnectionSettings.connectionDate = parseISO(
        customerConfig.updated_at,
      );
    }
  }

  const deliveryMethodMapping: Record<
    BillingProviderEnum_Enum,
    BillingProviderDeliveryMethod_Enum[]
  > = data.delivery_methods.reduce(
    (acc, method) => {
      if (!(method.billing_provider in acc)) {
        acc[method.billing_provider as BillingProviderEnum_Enum] =
          [] as BillingProviderDeliveryMethod_Enum[];
      }
      acc[method.billing_provider as BillingProviderEnum_Enum].push(
        method.delivery_method,
      );
      return acc;
    },
    {} as Record<
      BillingProviderEnum_Enum,
      BillingProviderDeliveryMethod_Enum[]
    >,
  );

  return {
    clientHasAws,
    awsSettings,
    clientHasAzure,
    azureSettings,
    clientHasStripe,
    stripeSettings,
    salesforceConnectionSettings,
    netSuiteConnectionSettings,
    deliveryMethodMapping,
  };
};

function parseStripeSettings(data: GetBillingProvidersSettingsQuery): {
  clientHasStripe: boolean;
  stripeSettings: Readonly<StripeSettings>;
} {
  let clientHasStripe = false;
  const stripeSettings: StripeSettings = {};

  if (data.stripe_billing_provider_configs.length > 1) {
    throw new Error(
      `Expected at most one stripe configuration, got multiple: ${JSON.stringify(data.stripe_billing_provider_configs)}`,
    );
  }

  if (data.stripe_billing_provider_configs.length) {
    clientHasStripe = true;
    const stripeConfig = data.stripe_billing_provider_configs[0].configuration;

    stripeSettings.customerId = stripeConfig.stripe_customer_id.toString();
    stripeSettings.collectionMethod =
      stripeConfig.stripe_collection_method.toString();
    stripeSettings.connectionDate = parseISO(
      data.stripe_billing_provider_configs[0].created_at,
    );
  } else {
    const stripeToken = data.billingProviderTokens.find(
      (t) => t.billing_provider === BillingProviderEnum_Enum.Stripe,
    );

    if (stripeToken) {
      clientHasStripe = true;
    }

    const stripeBillingProviderCustomer =
      data.customer?.BillingProviderCustomers.find(
        (bpCustomer) =>
          bpCustomer.billing_provider === BillingProviderEnum_Enum.Stripe,
      );

    if (stripeBillingProviderCustomer) {
      stripeSettings.customerId =
        stripeBillingProviderCustomer.billing_provider_customer_id;
      stripeSettings.connectionDate = parseISO(
        stripeBillingProviderCustomer?.created_at,
      );
    }

    const stripeCollectionMethodConfig = data?.customerConfigs.find(
      (customerConfig) => customerConfig.key === "stripe_collection_method",
    );

    if (stripeCollectionMethodConfig) {
      stripeSettings.collectionMethod = stripeCollectionMethodConfig.value;
    }
  }

  return {
    clientHasStripe,
    stripeSettings,
  };
}

const AWS_MARKETPLACE_DOCS_URL =
  "https://docs.metronome.com/aws-marketplace/overview/";

// const TEST_GCP_SETTINGS_OBJECT = () => {
//   const now = new Date();
//   const connectionDate = subHours(now, 2);
//   const expirationDate = addHours(now, 2);

//   return {
//     connectionDate,
//     expirationDate,
//   };
// };

export const BillingProviderSection: React.FC<BillingProviderSectionProps> = ({
  customerId,
}) => {
  const [awsMarketplaceSettingsModalOpen, setAWSMarketplaceSettingsModalOpen] =
    useState(false);
  const [
    azureMarketplaceSettingsModalOpen,
    setAzureMarketplaceSettingsModalOpen,
  ] = useState(false);
  const { environmentType } = useEnvironment();
  const { data, loading } = useGetBillingProvidersSettingsQuery({
    variables: {
      customer_id: customerId,
      environment_type: environmentType,
    },
  });
  const netSuiteEnabled = useFeatureFlag<boolean>("netsuite", false);
  const gcpMarketplaceEnabled = useFeatureFlag<boolean>(
    "gcp-marketplace-integration",
    false,
  );
  const customerName = data?.customer?.name;
  let clientHasAwsBpToken = false;
  let awsSettings: AWSMarketplaceSettings | undefined;
  let clientHasAzureBpToken = false;
  let azureSettings: AzureMarketplaceSettings | undefined;
  let clientHasStripeBpToken = false;
  let stripeSettings: StripeSettings | undefined;
  let gcpSettings: GcpSettings | undefined;
  let salesforceConnectionSettings: SalesforceConnectionSettings | undefined;
  let netSuiteConnectionSettings: NetSuiteConnectionSettings | undefined;
  let deliveryMethodMapping:
    | Record<BillingProviderEnum_Enum, BillingProviderDeliveryMethod_Enum[]>
    | undefined;

  if (!loading && data) {
    const parsedSettings = parseBillingProviderSettings(data);
    clientHasAwsBpToken = parsedSettings.clientHasAws;
    awsSettings = parsedSettings.awsSettings;
    clientHasAzureBpToken = parsedSettings.clientHasAzure;
    azureSettings = parsedSettings.azureSettings;
    clientHasStripeBpToken = parsedSettings.clientHasStripe;
    stripeSettings = parsedSettings.stripeSettings;
    salesforceConnectionSettings = parsedSettings.salesforceConnectionSettings;
    netSuiteConnectionSettings = parsedSettings.netSuiteConnectionSettings;
    deliveryMethodMapping = parsedSettings.deliveryMethodMapping;
  }

  /** For Testing Purposes Only:
   * Uncomment the following line to use stub data for gcpSettings.
   * Delete when moving GCP to production
   */

  // gcpSettings = TEST_GCP_SETTINGS_OBJECT();

  /**
   * End of Test Logic
   */

  const [connectedBP, connectedBPName] = useMemo<
    [MarketplaceProviderName, string] | [undefined, undefined]
  >(() => {
    if (!!azureSettings || !!awsSettings || !!stripeSettings) {
      if (azureSettings?.subscriptionId) {
        return [BillingProviderEnum_Enum.Azure, "Azure"];
      } else if (awsSettings?.customerId) {
        return [BillingProviderEnum_Enum.AwsMarketplace, "AWS"];
      } else if (stripeSettings?.customerId) {
        return [BillingProviderEnum_Enum.Stripe, "Stripe"];
      } else if (!!gcpSettings) {
        return [BillingProviderEnum_Enum.Gcp, "GCP"];
      }
    }
    return [undefined, undefined];
  }, [azureSettings, awsSettings, stripeSettings, gcpSettings]);

  const { data: clientConfig } = useGetClientConfigQuery();
  const clientHasDeltaStreamEnabled =
    clientConfig?.is_delta_stream_enabled ?? false;
  const clientHasBillingProviderConfigEnabled =
    clientConfig?.is_customer_billing_provider_configuration_enabled ?? false;
  const getConfigKey = (
    clientConfig: Partial<ClientConfig>[] | undefined,
    key: ClientConfigKeyEnum_Enum,
  ) =>
    clientConfig?.length
      ? clientConfig.find((c) => c.key === key)?.value
      : undefined;
  // note: for now we determine if delta stream is enabled based on client
  // config (across billingProviders), not client's delivery method (per billingProvider).
  // this works b/c only CFLT is using it & we'll configure all billingProviders at once.
  // future: modify list_delivery_method resolver to target a specific clients
  const clientGracePeriodHours = parseInt(
    getConfigKey(
      clientConfig?.ClientConfig,
      ClientConfigKeyEnum_Enum.GracePeriodHours,
    ) || "24",
  );

  const GRACE_PERIOD_BUFFER_HOURS = 2;
  const grace_period_hours = clientGracePeriodHours + GRACE_PERIOD_BUFFER_HOURS;

  const hasActiveAwsContracts = !!useGetActiveMarketplaceContractsQuery({
    variables: {
      billing_provider: BillingProviderEnum_Enum.AwsMarketplace,
      customer_id: customerId,
      grace_period_hours,
    },
  }).data?.contracts_page.contracts.length;

  const hasActiveAzureContracts = !!useGetActiveMarketplaceContractsQuery({
    variables: {
      billing_provider: BillingProviderEnum_Enum.Azure,
      customer_id: customerId,
      grace_period_hours,
    },
  }).data?.contracts_page.contracts.length;

  const hasActiveGcpContracts = !!useGetActiveMarketplaceContractsQuery({
    variables: {
      billing_provider: BillingProviderEnum_Enum.Gcp,
      customer_id: customerId,
      grace_period_hours,
    },
  }).data?.contracts_page.contracts.length;

  // When should we show each marketplace BP's card?
  // ... when one of the marketplaces is configured
  // as visible
  //   legacy: when connected via billingProviderToken || when none are connected
  //   deltaStream: when deltaStreamIsEnabled && has active contracts on BP
  // with connected message
  //   legacy: when connected via billingProviderToken
  //   deltaStream: when deltaStreamIsEnabled && has active contracts on BP
  // with active badge
  //   legacy: when connected via billingProviderToken && subscription is active/not expired
  //   deltaStream: always show active badge with connected message (same condition)
  // ... when none of the marketplaces are configured
  // as visible (always)
  //   legacy: display all cards as disconnected
  //   deltaStream: display all cards as disconnected

  const noActiveMarketplaceContracts =
    !hasActiveAwsContracts &&
    !hasActiveAzureContracts &&
    !hasActiveGcpContracts;

  const awsIsConnected = clientHasDeltaStreamEnabled
    ? hasActiveAwsContracts
    : connectedBP === BillingProviderEnum_Enum.AwsMarketplace;
  const showAwsCard = clientHasAwsBpToken
    ? connectedBP === BillingProviderEnum_Enum.AwsMarketplace || !connectedBP
    : clientHasDeltaStreamEnabled &&
      (hasActiveAwsContracts || noActiveMarketplaceContracts);

  const azureIsConnected = clientHasDeltaStreamEnabled
    ? hasActiveAzureContracts
    : connectedBP === BillingProviderEnum_Enum.Azure || !connectedBP;
  const showAzureCard = clientHasAzureBpToken
    ? connectedBP === BillingProviderEnum_Enum.Azure || !connectedBP
    : clientHasDeltaStreamEnabled &&
      (hasActiveAzureContracts || noActiveMarketplaceContracts);

  const gcpIsConnected = clientHasDeltaStreamEnabled
    ? hasActiveGcpContracts
    : connectedBP === BillingProviderEnum_Enum.Gcp;
  const showGcpCard =
    gcpMarketplaceEnabled &&
    (clientHasDeltaStreamEnabled
      ? hasActiveGcpContracts || noActiveMarketplaceContracts
      : connectedBP === BillingProviderEnum_Enum.Gcp || !connectedBP);

  return (
    <>
      <Headline level={5} className="mt-24">
        Linked accounts
      </Headline>
      <Body level={2} className="mb-12">
        Connect your Metronome account to Salesforce, AWS, NetSuite and more.
        Bill through marketplaces and billing providers.
        {connectedBPName &&
          ` Your account is connected to ${connectedBPName}. A customer can only be connected to one
        billing provider at a time. `}
        <Hyperlink target="_blank" routePath={AWS_MARKETPLACE_DOCS_URL}>
          Learn more.
        </Hyperlink>
      </Body>

      <div className="flex flex-col space-y-12">
        {showAwsCard && (
          <>
            <CustomerMarketplaceCard
              integrationId={awsSettings?.customerId}
              logo={<AWSLogo className="pt-[2px]" />}
              name="AWS Marketplace"
              connectionDate={awsSettings?.connectionDate}
              meteringDisabled={awsSettings?.meteringDisabled}
              expirationTimestamp={awsSettings?.expirationTimestamp}
              tokenIDs={
                awsSettings?.customerId
                  ? [
                      {
                        id: awsSettings?.customerId,
                        label: "AWS Customer ID",
                      },
                      {
                        id: awsSettings?.productCode,
                        label: "Product Code",
                      },
                      {
                        id: awsSettings?.region,
                        label: "Region",
                      },
                    ]
                  : undefined
              }
              buttonOptions={{
                buttonOnClick: () => setAWSMarketplaceSettingsModalOpen(true),
                buttonText: !connectedBP ? "Connect" : "Edit",
              }}
              isConnected={awsIsConnected}
              isDeltaStream={clientHasDeltaStreamEnabled}
              deliveryMethods={
                deliveryMethodMapping?.[BillingProviderEnum_Enum.AwsMarketplace]
              }
            />

            {awsMarketplaceSettingsModalOpen && (
              <AWSMarketplaceSettingsModal
                onClose={() => setAWSMarketplaceSettingsModalOpen(false)}
                customerId={customerId}
              />
            )}
          </>
        )}

        {showAzureCard && (
          <>
            <CustomerMarketplaceCard
              integrationId={azureSettings?.subscriptionId}
              logo={
                <AzureMarketplaceLogo className="h-[50px] w-[50px] p-[9px]" />
              }
              name="Azure Marketplace"
              additionalActiveParams={[
                azureSettings?.subscriptionStatus === "Subscribed",
              ]}
              connectionDate={azureSettings?.connectionDate}
              meteringDisabled={azureSettings?.meteringDisabled}
              expirationTimestamp={azureSettings?.expirationDate}
              tokenIDs={
                azureSettings?.subscriptionId
                  ? [
                      {
                        id: azureSettings?.subscriptionId,
                        label: "Subscription ID",
                      },
                      {
                        id: azureSettings?.planId,
                        label: "Plan ID",
                      },
                      {
                        id: azureSettings?.startDate
                          ? format(
                              azureSettings?.startDate,
                              "yyyy-MM-dd'T'HH:mm:ss'Z'",
                            )
                          : "",
                        label: "Start Date",
                      },
                      {
                        id: azureSettings?.subscriptionStatus,
                        label: "Subscription Status",
                      },
                    ]
                  : undefined
              }
              buttonOptions={{
                buttonOnClick: () => setAzureMarketplaceSettingsModalOpen(true),
                buttonText: !connectedBP ? "Connect" : "Edit",
              }}
              isConnected={azureIsConnected}
              isDeltaStream={clientHasDeltaStreamEnabled}
              deliveryMethods={
                deliveryMethodMapping?.[BillingProviderEnum_Enum.Azure]
              }
            />

            {azureMarketplaceSettingsModalOpen && (
              <AzureMarketplaceSettingsModal
                onClose={() => setAzureMarketplaceSettingsModalOpen(false)}
                customerId={customerId}
              />
            )}
          </>
        )}
        {/* 
            TODO: once GCP marketplace is available, this should get updated to use those settings
          */}
        {showGcpCard && (
          <>
            <CustomerMarketplaceCard
              logo={<GCPLogo className="h-[50px] w-[50px] p-8 pt-[10px]" />}
              name="GCP Marketplace"
              connectionDate={gcpSettings?.connectionDate}
              expirationTimestamp={gcpSettings?.expirationDate}
              isConnected={gcpIsConnected}
              isDeltaStream={clientHasDeltaStreamEnabled}
              deliveryMethods={
                deliveryMethodMapping?.[BillingProviderEnum_Enum.Gcp]
              }
            />
          </>
        )}
      </div>
      <div className={styles.billingProvider}>
        <SalesforceConnection
          customerId={customerId}
          customerName={customerName}
          connectionDate={salesforceConnectionSettings?.connectionDate}
          salesforceAccountId={
            salesforceConnectionSettings?.salesforceAccountId
          }
          loading={loading}
        />
      </div>

      {netSuiteEnabled && (
        <div className={styles.billingProvider}>
          <NetSuiteConnection
            customerId={customerId}
            customerName={customerName}
            netSuiteCustomerId={netSuiteConnectionSettings?.netSuiteCustomerId}
            connectionDate={netSuiteConnectionSettings?.connectionDate}
            loading={loading}
          />
        </div>
      )}
      <div className={styles.billingProvider}>
        <StripeBillingProvider
          customerId={customerId}
          customerName={customerName}
          hasClientIntegration={clientHasStripeBpToken}
          hasAnotherBillingProviderEnabled={
            !!awsSettings?.customerId || !!azureSettings?.subscriptionId
          }
          stripeCustomerId={stripeSettings?.customerId}
          stripeCollectionMethod={stripeSettings?.collectionMethod}
          connectionDate={stripeSettings?.connectionDate}
          clientHasDeltaStreamEnabled={clientHasDeltaStreamEnabled}
          clientHasBillingProviderConfigEnabled={
            clientHasBillingProviderConfigEnabled
          }
        />
      </div>
    </>
  );
};
