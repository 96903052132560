import React, { useState } from "react";
import { Body, Input } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";
import { useSnackbar } from "components/Snackbar";
import { useSetupAzureMarketplaceConfigMutation } from "./queries.graphql";
import { Link } from "react-router-dom";
import styles from "./index.module.less";

interface AzureMarketplaceConfigModalProps {
  onClose: () => void;
  isConfigured: boolean;
}

const AzureMarketplaceConfigModal: React.FC<
  AzureMarketplaceConfigModalProps
> = ({ onClose, isConfigured }) => {
  const pushMessage = useSnackbar();
  const [setupAzureMarketplaceConfigMutation, { loading }] =
    useSetupAzureMarketplaceConfigMutation({
      update(cache) {
        cache.evict({
          fieldName: "BillingProviderToken",
        });
        cache.evict({
          fieldName: "BillingProviderCustomer",
        });
      },
    });
  const [azureTenantId, setAzureTenantId] = useState("");
  const [azureClientId, setAzureClientId] = useState("");
  const [azureClientSecret, setAzureClientSecret] = useState("");
  const [azureSetupError, setAzureSetupError] = useState<Error | null>(null);
  const setupAzureMarketplaceConfigMutationAction = async (
    azureTenantId: string,
    azureClientId: string,
    azureClientSecret: string,
  ) => {
    try {
      setAzureSetupError(null);
      await setupAzureMarketplaceConfigMutation({
        variables: {
          tenant_id: azureTenantId,
          client_id: azureClientId,
          raw_client_secret: azureClientSecret,
        },
      });
      pushMessage({
        content: "Azure Marketplace has been enabled",
        type: "success",
      });
      onClose();
    } catch (e: any) {
      setAzureSetupError(e);
    }
  };

  const disableSave =
    loading || !azureTenantId || !azureClientId || !azureClientSecret;

  const actionButtons = (
    <>
      <Button onClick={onClose} text="Cancel" theme="linkGray" />
      <Button
        onClick={async () => {
          await setupAzureMarketplaceConfigMutationAction(
            azureTenantId,
            azureClientId,
            azureClientSecret,
          );
        }}
        loading={loading}
        disabled={disableSave || loading}
        text="Save"
        theme="primary"
      />
    </>
  );

  return (
    <Popup
      actions={actionButtons}
      isOpen={true}
      onRequestClose={onClose}
      title="Enable Azure Marketplace"
      className="z-modal"
    >
      <Body level={2}>
        To configure Azure you’ll need to find your Microsoft tenant (directory)
        and application (client) IDs. Learn more{" "}
        <Link
          className={styles.hyperlink}
          target="_blank"
          to="https://learn.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal#sign-in-to-the-application"
        >
          here
        </Link>
        .
        {isConfigured && (
          <div className="mt-4">
            NOTE: Updating the Azure Marketplace configuration will fail
            validation if any subscriptions that were created with the previous
            configuration do not have access to the new credentials.
          </div>
        )}
      </Body>
      <Input
        name="Tenant ID"
        placeholder="Enter Azure tenant ID"
        value={azureTenantId ?? ""}
        onChange={setAzureTenantId}
        autoFocus
        error={!!azureSetupError?.message}
        className={styles.input}
      />
      <Input
        name="Client ID"
        placeholder="Enter Azure client ID"
        value={azureClientId ?? ""}
        onChange={setAzureClientId}
        error={!!azureSetupError?.message}
        className={styles.input}
      />
      <Input
        name="Client Secret"
        placeholder="Enter Azure client secret"
        value={azureClientSecret ?? ""}
        onChange={setAzureClientSecret}
        error={!!azureSetupError?.message}
        className={styles.input}
        type="password"
      />
      {!!azureSetupError?.message && (
        <div className={styles.error}>{azureSetupError?.message}</div>
      )}
    </Popup>
  );
};

export default AzureMarketplaceConfigModal;
