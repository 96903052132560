import React from "react";

import { OverrideFlyover } from "../../Sections/Overrides";
import {
  ScheduledChargeFlyover,
  ResellerRoyaltyFlyover,
  DiscountFlyover,
} from "../../Sections/AdditionalTerms";
import { CommitFlyover } from "../../Sections/Commits";
import { upsertById } from "../upsertById";

import { Schema } from "../../Schema";
import { ViewOverridesFlyover } from "../../Sections/Overrides/ViewOverrideFlyover";
import {
  DescribeFragment,
  describeOverride,
} from "pages/Contracts/lib/Override";
import { useNow } from "lib/date";
import { describeNewOverride } from "../Override";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";
import { ProServiceFlyover } from "../../Sections/AdditionalTerms/ProServiceFlyover";
import { CreditType } from "types/credit-types";

type FlyoverObjType =
  | "override"
  | "commit"
  | "credit"
  | Schema.Types.AdditionalTermType;
type TermKey =
  | "commits"
  | "credits"
  | "overrides"
  | "scheduledCharges"
  | "discounts"
  | "resellerRoyalties"
  | "proServices";

export type Flyover =
  | { type: `new_override`; productId?: string; creditType?: CreditType }
  | {
      type: `new_${Extract<FlyoverObjType, "commit" | "credit">}`;
      fiatCreditTypes: CreditType[];
      customCreditTypes: CreditType[];
    }
  | {
      type: `new_${Exclude<FlyoverObjType, "override" | "commit">}`;
    }
  | {
      type: `edit_${Extract<FlyoverObjType, "commit" | "credit">}`;
      id: string;
      fiatCreditTypes: CreditType[];
      customCreditTypes: CreditType[];
    }
  | {
      type: `edit_${Exclude<FlyoverObjType, "commit">}`;
      id: string;
      creditType?: CreditType;
    }
  | { type: "view_overrides" };

interface Props {
  ctrl: Schema.Types.CreateSharedCtrl;
  rateCardId?: string;
  allProducts: Array<
    ProductListItem.IdFragment &
      ProductListItem.NameFragment &
      ProductListItem.TypeFragment
  >;
  contract?: {
    starting_at: string | null;
    ending_before: string | null;
    multiplier_override_prioritization: string | null;
    overrides?: DescribeFragment[];
    amendments?: Array<{
      overrides: DescribeFragment[];
    }>;
  };
  options?: {
    netsuiteEnabled?: boolean;
  };
  fiatCreditTypes?: CreditType[];
  customCreditTypes?: CreditType[];
}

export function useFlyovers({
  ctrl,
  rateCardId,
  allProducts,
  contract,
  options,
  fiatCreditTypes = [],
  customCreditTypes = [],
}: Props): {
  flyoverElement: React.ReactNode | null;
  setFlyover: (flyover: Flyover | undefined) => void;
  closeFlyover: () => void;
} {
  const [flyover, setFlyover] = React.useState<Flyover>();
  const closeFlyover = () => setFlyover(undefined);
  const now = useNow();

  function termSaveAndCloseHandler<K extends TermKey>(key: K) {
    return (
      update: NonNullable<Schema.Types.CreateContractInput[typeof key]>[number],
    ) => {
      const current: any[] = ctrl.get(key) ?? [];
      ctrl.update({
        [key]: Array.isArray(current) ? upsertById(current, update) : [update],
      });
      setFlyover(undefined);
    };
  }

  function termSaveHandler<K extends TermKey>(key: K) {
    return (
      update: NonNullable<Schema.Types.CreateContractInput[typeof key]>[number],
    ) => {
      const current: any[] = ctrl.get(key) ?? [];
      ctrl.update({
        [key]: Array.isArray(current) ? upsertById(current, update) : [update],
      });
    };
  }

  function termDeleteHandler<K extends TermKey>(key: K, id: string) {
    return () => {
      ctrl.update({
        [key]: (ctrl.get(key) ?? []).filter((t) =>
          key === "resellerRoyalties" && "type" in t
            ? t.type !== id
            : t.id !== id,
        ),
      });
      setFlyover(undefined);
    };
  }

  const flyoverElement = ((): React.ReactElement | null => {
    switch (flyover?.type) {
      case "new_override":
        return !rateCardId || !contract ? null : (
          <OverrideFlyover
            contract={contract}
            overrides={[
              (contract.overrides ?? []).map((o) => describeOverride(now, o)),
              (contract.amendments ?? []).flatMap((a) =>
                a.overrides.map((o) => describeOverride(now, o)),
              ),
              (ctrl.get("overrides") ?? []).map((o) =>
                describeNewOverride(now, allProducts, o),
              ),
            ].flat()}
            rateCardId={rateCardId}
            defaultProductId={flyover.productId}
            onCancel={closeFlyover}
            onSave={termSaveAndCloseHandler("overrides")}
            creditType={flyover.creditType}
          />
        );
      case "edit_override":
        return !rateCardId || !contract ? null : (
          <OverrideFlyover
            contract={contract}
            overrides={[
              (contract.overrides ?? []).map((o) => describeOverride(now, o)),
              (contract.amendments ?? []).flatMap((a) =>
                a.overrides.map((o) => describeOverride(now, o)),
              ),
              (ctrl.get("overrides") ?? []).map((o) =>
                describeNewOverride(now, allProducts, o),
              ),
            ].flat()}
            rateCardId={rateCardId}
            edit={(ctrl.get("overrides") ?? []).find(
              (o) => o.id === flyover.id,
            )}
            onCancel={closeFlyover}
            onDelete={termDeleteHandler("overrides", flyover.id)}
            onSave={termSaveAndCloseHandler("overrides")}
            creditType={flyover.creditType}
          />
        );
      case "view_overrides":
        return !rateCardId ? null : (
          <ViewOverridesFlyover
            rateCardId={rateCardId}
            overrides={ctrl.get("overrides") ?? []}
            onRequestClose={closeFlyover}
          />
        );

      case "new_scheduled_charge":
        return (
          <ScheduledChargeFlyover
            onCancel={closeFlyover}
            onSave={termSaveAndCloseHandler("scheduledCharges")}
          />
        );
      case "edit_scheduled_charge":
        return (
          <ScheduledChargeFlyover
            edit={(ctrl.get("scheduledCharges") ?? []).find(
              (o) => o.id === flyover.id,
            )}
            onCancel={closeFlyover}
            onDelete={termDeleteHandler("scheduledCharges", flyover.id)}
            onSave={termSaveAndCloseHandler("scheduledCharges")}
          />
        );

      case "new_reseller_royalty":
        return (
          <ResellerRoyaltyFlyover
            resellerRoyalties={ctrl.get("resellerRoyalties")}
            onCancel={closeFlyover}
            onSave={termSaveAndCloseHandler("resellerRoyalties")}
          />
        );
      case "edit_reseller_royalty":
        return (
          <ResellerRoyaltyFlyover
            edit={(ctrl.get("resellerRoyalties") ?? []).find(
              (rr) => rr.type === flyover.id,
            )}
            resellerRoyalties={ctrl.get("resellerRoyalties")}
            onCancel={closeFlyover}
            onDelete={termDeleteHandler("resellerRoyalties", flyover.id)}
            onSave={termSaveAndCloseHandler("resellerRoyalties")}
          />
        );

      case "new_commit":
        return (
          <CommitFlyover
            edit={undefined}
            onClose={closeFlyover}
            onSave={termSaveHandler("commits")}
            options={options}
            fiatCreditTypes={fiatCreditTypes}
            customCreditTypes={customCreditTypes}
            defaultCreditType={fiatCreditTypes[0]}
          />
        );
      case "edit_commit":
        return (
          <CommitFlyover
            edit={(ctrl.get("commits") ?? []).find((c) => c.id === flyover.id)}
            onClose={closeFlyover}
            onDelete={termDeleteHandler("commits", flyover.id)}
            onSave={termSaveAndCloseHandler("commits")}
            options={options}
            fiatCreditTypes={fiatCreditTypes}
            customCreditTypes={customCreditTypes}
            defaultCreditType={fiatCreditTypes[0]}
          />
        );
      case "new_credit":
        return (
          <CommitFlyover
            edit={undefined}
            onClose={closeFlyover}
            onSave={termSaveHandler("credits")}
            options={{ ...options, asCredit: true }}
            fiatCreditTypes={fiatCreditTypes}
            customCreditTypes={customCreditTypes}
            defaultCreditType={fiatCreditTypes[0]}
          />
        );
      case "edit_credit":
        return (
          <CommitFlyover
            edit={(ctrl.get("credits") ?? []).find((c) => c.id === flyover.id)}
            onClose={closeFlyover}
            onDelete={termDeleteHandler("credits", flyover.id)}
            onSave={termSaveAndCloseHandler("credits")}
            options={{ ...options, asCredit: true }}
            fiatCreditTypes={fiatCreditTypes}
            customCreditTypes={customCreditTypes}
            defaultCreditType={fiatCreditTypes[0]}
          />
        );
      case "new_discount":
        return (
          <DiscountFlyover
            onCancel={closeFlyover}
            onSave={termSaveAndCloseHandler("discounts")}
          />
        );
      case "edit_discount":
        return (
          <DiscountFlyover
            edit={ctrl.get("discounts")?.find((d) => d.id === flyover.id)}
            onCancel={closeFlyover}
            onDelete={termDeleteHandler("discounts", flyover.id)}
            onSave={termSaveAndCloseHandler("discounts")}
          />
        );
      case "new_pro_service":
        return (
          <ProServiceFlyover
            onCancel={closeFlyover}
            onSave={termSaveAndCloseHandler("proServices")}
            options={options}
          />
        );

      case "edit_pro_service":
        return (
          <ProServiceFlyover
            edit={ctrl.get("proServices")?.find((ps) => ps.id === flyover.id)}
            onCancel={closeFlyover}
            onDelete={termDeleteHandler("proServices", flyover.id)}
            onSave={termSaveAndCloseHandler("proServices")}
          />
        );

      case undefined:
        return null;
    }
  })();

  return { flyoverElement, setFlyover: setFlyover, closeFlyover };
}
