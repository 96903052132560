import React from "react";
import { ReactComponent as MicrosoftLogo } from "tenaissance/icons/microsoft.svg";
import { ReactComponent as AWSMarketPlaceLogo } from "tenaissance/icons/aws.svg";
import { ReactComponent as SalesForceLogo } from "tenaissance/icons/salesforce.svg";
import { ReactComponent as StripeLogo } from "tenaissance/icons/stripe.svg";
import { ReactComponent as NetsuiteLogo } from "tenaissance/icons/netsuite.svg";
import { ReactComponent as WorkdayLogo } from "tenaissance/icons/workday.svg";
import { ReactComponent as QuickBooksOnlineLogo } from "tenaissance/icons/quickbooks.svg";

interface IntegrationDetailProps {
  integrationName: string;
  supportingText: string;
}

const renderSVG = (integrationName: string) => {
  switch (integrationName) {
    case "Azure Marketplace":
      return <MicrosoftLogo />;
    case "AWS Marketplace":
      return <AWSMarketPlaceLogo />;
    case "Salesforce":
      return (
        <div className="h-[32px] w-[32px] pt-8">
          <SalesForceLogo />
        </div>
      );
    case "Stripe":
      return <StripeLogo />;
    case "NetSuite":
      return <NetsuiteLogo />;
    case "Workday":
      return <WorkdayLogo />;
    case "Quickbooks Online":
      return <QuickBooksOnlineLogo />;
    default:
      return null;
  }
};

export const IntegrationDetails: React.FC<IntegrationDetailProps> = ({
  integrationName,
  supportingText,
}) => {
  return (
    <div className="gap-lg flex">
      <div className="float-left flex">{renderSVG(integrationName)}</div>
      <div className="float-right flex flex-col justify-between">
        <div className="text-md text-core-slate font-medium leading-5">
          {integrationName}
        </div>
        <div className="text-xs font-normal leading-[18px]">
          {supportingText}
        </div>
      </div>
    </div>
  );
};
