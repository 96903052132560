import { ButtonGroup, ButtonProps } from "../../../../components/ButtonGroup";
import { Table } from "../../../../components/Table";
import React, { useMemo, useState } from "react";
import { useNavigate } from "../../../../../lib/useNavigate";
import { useEnvironment } from "../../../../../lib/environmentSwitcher/context";

import CustomerCountCell from "../../../../../pages/Plans/components/CustomerCountCell";
import pluralize from "pluralize";
import {
  DraftPlansQuery,
  PlansQuery,
  useActiveProductsQuery,
  useDraftPlansQuery,
  usePlansQuery,
} from "../../../../../pages/Plans/data/queries.graphql";
import { PlanTypeEnum } from "../../../../../types/generated-graphql/__types__";
import {
  AvatarWithName,
  Hyperlink,
  Input,
  Tooltip,
} from "../../../../../design-system";
import { Badge } from "../../../../components/Badge";
import { renderDate } from "../../../../../lib/time";
import StatusCell from "../../../../../pages/Plans/components/StatusCell";
import {
  ActionMenu,
  DraftPlanActionMenu,
} from "../../../../../pages/Plans/components/ActionMenu";
import DeleteDraftPlanModal from "../../../../../pages/Plans/components/DeletePlanModal";
import ArchivePlanModal from "../../../../../pages/Plans/components/ArchivePlanModal";
import CopyPlanModal from "../../../../../components/CopyAssetToEnvironmentModal/CopyPlanModal";
import { Filter, OptionType } from "../../../../../components/Filter";
import {
  PLAN_OPTIONS,
  PLAN_OPTIONS_DEFAULT,
} from "../../../../../pages/Plans/filters";
import { useSearchParam } from "../../../../../lib/routes/useSearchParam";
import useDebounce from "../../../../../lib/debounce";
import Fuse from "fuse.js";
import { GatedButton } from "../../../../../components/GatedButton";
import { NewPlanDocument } from "../../../../../pages/PlanWizards/data/queries.graphql";
import { EmptyState } from "../../../../../components/EmptyState";
import { NewProductDocument } from "../../../../../pages/NewProduct/queries.graphql";
import { SearchTooltip } from "../../../../../components/SearchTooltip";

interface PlansTableProps extends React.PropsWithChildren {
  titleButtons: ButtonProps[];
}
export type Plan = PlansQuery["plans"][0];
export type DraftPlan = DraftPlansQuery["DraftPlan"][0];

type DraftPlanData = {
  name?: string;
  description?: string;
  selectedProductIds?: string[];
};
const PROMPT_ADD_PRODUCT_COPY =
  "Once you create a product, then you can build plans.";

function isDraftPlan(plan: Plan | DraftPlan): plan is DraftPlan {
  return plan.__typename === "DraftPlan";
}

export const PlansTable: React.FC<PlansTableProps> = (props) => {
  const { environmentType } = useEnvironment();
  const navigate = useNavigate();
  const [planToCopy, setPlanToCopy] = useState<Plan | null>(null);
  const [planToDelete, setPlanToDelete] = useState<DraftPlan | null>(null);
  const [planToArchive, setPlanToArchive] = useState<Plan | null>(null);
  const [planFilters, setPlanFilters] =
    useState<OptionType[]>(PLAN_OPTIONS_DEFAULT);

  const [searchQuery, setSearchQuery] = useSearchParam("q");
  const debouncedSearchQuery = useDebounce(searchQuery.trim(), 400);
  const isPlanFilterSelected = (value: "active" | "draft" | "archived") =>
    planFilters.some((f) => f.group === "plan_status" && f.value === value);

  const {
    data: activePlans,
    loading: activePlansLoading,
    error: activePlansError,
  } = usePlansQuery({
    variables: {
      environment_type: environmentType,
      plan_type: PlanTypeEnum.ActiveOnly,
    },
    skip: false,
  });
  const {
    data: archivedPlans,
    loading: archivedPlansLoading,
    error: archivedPlansError,
  } = usePlansQuery({
    variables: {
      environment_type: environmentType,
      plan_type: PlanTypeEnum.ArchivedOnly,
    },
    skip: !isPlanFilterSelected("archived"),
  });
  const {
    data: draftPlansData,
    loading: draftPlansLoading,
    error: draftPlansError,
  } = useDraftPlansQuery({
    variables: { environment_type: environmentType },
  });
  const {
    data: activeProducts,
    loading: activeProductsLoading,
    error: activeProductsError,
  } = useActiveProductsQuery({
    variables: { environment_type: environmentType },
  });

  // Even if we aren't showing active and/or draft plans at the moment, we block
  // on them to show the page because we want to show the correct empty state
  // (no plans match filter vs no plans at all). However, we only block on the
  // archived plans query returning if we're going to show them.
  const loading =
    activePlansLoading ||
    draftPlansLoading ||
    activeProductsLoading ||
    (isPlanFilterSelected("archived") && archivedPlansLoading);

  const error =
    activePlansError ||
    draftPlansError ||
    activeProductsError ||
    archivedPlansError;

  const plans: Plan[] = [];
  if (isPlanFilterSelected("active") && activePlans) {
    plans.push(...activePlans?.plans);
  }
  if (isPlanFilterSelected("archived") && archivedPlans) {
    plans.push(...archivedPlans?.plans);
  }

  const planFuse = useMemo(() => {
    return new Fuse(plans ?? [], {
      ignoreLocation: true,
      includeScore: true,
      useExtendedSearch: true,
      keys: [
        { name: "id", weight: 1.0 },
        { name: "name", weight: 1.0 },
        { name: "description", weight: 0.7 },
        {
          name: "PricedProducts.PricedProductPricingFactors.ProductPricingFactor.name",
          weight: 0.4,
        },
        { name: "PricedProducts.Product.name", weight: 0.4 },
      ],
      threshold: 0.35,
    });
  }, [plans]);

  const draftPlans = isPlanFilterSelected("draft")
    ? draftPlansData?.DraftPlan ?? []
    : [];

  const draftPlanFuse = useMemo(() => {
    return new Fuse(draftPlans ?? [], {
      ignoreLocation: true,
      includeScore: true,
      useExtendedSearch: true,
      keys: [
        { name: "id", weight: 1.0 },
        { name: "data.name", weight: 1.0 },
        { name: "data.description", weight: 0.8 },
      ],
      threshold: 0.35,
    });
  }, [draftPlans]);

  const filteredPlans = [
    ...(debouncedSearchQuery
      ? planFuse.search(debouncedSearchQuery).map((match) => {
          return { ...match.item, score: match.score || 0 };
        })
      : plans.map((plan) => {
          return { ...plan, score: 0 };
        })),
  ];

  const filteredDraftPlans = [
    ...(debouncedSearchQuery
      ? draftPlanFuse.search(debouncedSearchQuery).map((match) => {
          return { ...match.item, score: match.score || 0 };
        })
      : draftPlans.map((draftPlan) => {
          return { ...draftPlan, score: 0 };
        })),
  ];

  const hasProducts =
    activeProducts?.products && activeProducts.products.length > 0;

  const addPlanButton = (
    <GatedButton
      doc={NewPlanDocument}
      className="ml-12"
      onClick={() =>
        hasProducts ? navigate("/offering/plans/new") : undefined
      }
      disabled={!hasProducts}
      text="Add"
      theme="primary"
      leadingIcon="plus"
      size="sm"
    />
  );

  const deleteDraftPlanModal = planToDelete && (
    <DeleteDraftPlanModal
      onClose={() => {
        setPlanToDelete(null);
      }}
      plan={planToDelete}
    />
  );

  const archivePlanModal = planToArchive && (
    <ArchivePlanModal
      onClose={() => {
        setPlanToArchive(null);
      }}
      plan={planToArchive}
    />
  );

  const copyPlanModal = planToCopy && (
    <CopyPlanModal
      onClose={() => {
        setPlanToCopy(null);
      }}
      planId={planToCopy.id}
      planName={planToCopy.name}
    />
  );
  const table = (
    <Table
      title={<ButtonGroup buttons={props.titleButtons} />}
      loading={loading}
      rowRoutePath={(row) => `/offering/plans/${row.id}`}
      columns={[
        {
          id: "name",
          header: "Name",
          cell: (props) => props.getValue(),
          accessorKey: "name",
        },
        {
          id: "customer-count",
          header: "Customer count",
          cell: (props) => props.getValue(),
          accessorFn: (plan) => {
            if (isDraftPlan(plan)) {
              return <span>0 customers</span>;
            }
            return <CustomerCountCell planId={plan.id} />;
          },
        },
        {
          id: "product-count",
          header: "Product count",
          cell: (props) => props.getValue(),
          accessorFn: (plan) => {
            const productCount = isDraftPlan(plan)
              ? (plan.data as DraftPlanData).selectedProductIds?.length ?? 0
              : plan.PricedProducts_aggregate.aggregate?.count ?? 0;
            return (
              <span>{`${productCount} ${pluralize("product", productCount)}`}</span>
            );
          },
        },
        {
          id: "user",
          header: "User",
          cell: (props) => props.getValue(),
          accessorFn: (plan) => {
            const creator = isDraftPlan(plan) ? plan.Creator : plan.Actor;
            return creator ? (
              <Tooltip
                content={
                  <>
                    Created by {creator.name}
                    <br />
                    {renderDate(new Date(plan.created_at), {
                      isUtc: false,
                    })}
                  </>
                }
              >
                <AvatarWithName {...creator} />
              </Tooltip>
            ) : null;
          },
        },
        {
          id: "last_edited",
          header: "Last edited",
          cell: (props) => props.getValue(),
          accessorFn: (plan) => {
            const availableDate = isDraftPlan(plan)
              ? plan.created_at
              : plan.updated_at;
            return renderDate(new Date(availableDate), {
              isUtc: false,
            });
          },
        },
        {
          id: "status",
          header: "Status",
          cell: (props) => props.getValue(),
          accessorFn: (plan) => {
            if (isDraftPlan(plan)) {
              return <Badge label="Draft" theme="gray" />;
            } else if (plan.deprecated_at !== null) {
              return <Badge label="Archived" theme="gray" />;
            } else {
              return <StatusCell planId={plan.id} />;
            }
          },
        },
        {
          id: "actions",
          header: "",
          cell: (props) => {
            if (isDraftPlan(props.getValue())) {
              return (
                <DraftPlanActionMenu
                  plan={props.getValue()}
                  setPlanToDelete={setPlanToDelete}
                />
              );
            } else {
              return (
                <ActionMenu
                  plan={props.getValue()}
                  setPlanToArchive={setPlanToArchive}
                  setPlanToCopy={setPlanToCopy}
                />
              );
            }
          },
          accessorFn: (r) => r,
        },
      ]}
      data={[...filteredPlans, ...filteredDraftPlans]}
      paginationOptions={{ type: "clientSide" }}
      topBarActions={[
        hasProducts ? (
          <div className="flex flex-row items-center">
            {!loading && (plans.length || draftPlansData?.DraftPlan.length) ? (
              <>
                <SearchTooltip searchText="plans">
                  <Input
                    type="search"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={setSearchQuery}
                    leftIcon="search"
                    className="w-[208px]"
                  />
                </SearchTooltip>
                <Filter
                  value={planFilters}
                  options={PLAN_OPTIONS}
                  onChange={setPlanFilters}
                  onReset={() => setPlanFilters(PLAN_OPTIONS_DEFAULT)}
                />
              </>
            ) : null}
            {addPlanButton}
          </div>
        ) : (
          <Tooltip content={PROMPT_ADD_PRODUCT_COPY}>{addPlanButton}</Tooltip>
        ),
      ]}
    />
  );

  let content = table;
  if (error) {
    content = (
      <>
        {table}
        <EmptyState
          title="We ran into an issue loading your plans"
          subtitle="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
          icon="shoppingCart01"
        />
      </>
    );
  } else if (!loading) {
    if (plans.length === 0 && draftPlansData?.DraftPlan.length === 0) {
      content = (
        <>
          {table}
          <EmptyState
            title="You don't have any plans."
            subtitle={
              hasProducts ? (
                <span>
                  To learn more about plans, read the{" "}
                  <Hyperlink
                    target="_blank"
                    routePath="https://docs.metronome.com/pricing/overview"
                  >
                    products and pricing documentation
                  </Hyperlink>
                  .
                </span>
              ) : (
                PROMPT_ADD_PRODUCT_COPY
              )
            }
            buttonAuthDoc={hasProducts ? NewPlanDocument : NewProductDocument}
            buttonText={hasProducts ? "Add New Plan" : "Add New Product"}
            onClick={
              hasProducts
                ? () => navigate("/plans/new")
                : () => navigate("/products/new")
            }
            icon="file05"
            buttonIcon="plus"
          />
        </>
      );
    } else if (filteredPlans.length === 0 && filteredDraftPlans.length === 0) {
      content = (
        <>
          {table}
          <EmptyState
            title="No matching plans found."
            subtitle={`Try a different ${
              searchQuery.length ? "search term" : "filter"
            }.`}
            icon="file05"
          />
        </>
      );
    } else {
      content = table;
    }
  }
  return (
    <>
      {deleteDraftPlanModal}
      {archivePlanModal}
      {copyPlanModal}
      {content}
    </>
  );
};
