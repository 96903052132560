// TODO(ekaragiannis) - pull these from css variables */
export const graphLineColors = [
  "#22B796",
  "#5F5DDA",
  "#E9884D",
  "#8459CA",
  "#E2BE3A",
  "#4BAAF2",
  "#875342",
  "#A1C644",
  "#C75EEA",
  "#65C3CD",
];

function hashString(str: string) {
  let hash = 0;
  let i = str.length;
  while (i) {
    hash += str.charCodeAt(--i);
  }
  return hash;
}

// Returns a consistent color given an ID
export function idToColor(id: string) {
  const hash = hashString(id);
  return graphLineColors[hash % graphLineColors.length];
}
