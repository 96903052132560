import { FormController } from "lib/FormController";
import { Schema } from "../../Schema";

export type CommitFlyoverController = ReturnType<
  typeof useCommitFlyoverController
>;

export const useCommitFlyoverController = FormController.createHook(
  Schema.CommitFlyoverRoot,
  {
    init: (edit?: Schema.Types.CommitFlyoverRoot) => edit ?? {},
  },
);
