import React from "react";
import { Badge, Input } from "design-system";

import { useEnvironment } from "lib/environmentSwitcher/context";
import { useSearchParam } from "lib/routes/useSearchParam";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import { useDebounce } from "lib/debounce";
import { Table } from "components/Table";
import { EmptyState } from "components/EmptyState";
import { AppShell, PageContainer } from "components/PageContainer";
import { Filter, FilterOptions, OptionType } from "components/Filter";
import { InsertCustomerDocument } from "pages/NewCustomer/newCustomer.graphql";

import { useNow } from "lib/date";
import { useSearchCustomersQuery, useListCustomerQuery } from "./data.graphql";
import { CustomerStatus } from "./components/CustomerStatus";
import {
  ArchivedFilter,
  Order_By,
  type Customer_Bool_Exp,
} from "types/generated-graphql/__types__";
import { Customer } from "../lib/Customer";
import { useFeatureFlag } from "lib/launchdarkly";
import { GatedButton } from "../../../components/GatedButton";
import { renderDate, renderDateTimeInUTC } from "lib/time";
import { useUIMode } from "../../../lib/useUIMode";

const FILTERS: FilterOptions = {
  customerStatus: {
    label: "CUSTOMER STATUS",
    options: [
      {
        label: "Active",
        type: "single",
        value: "notArchived",
        group: "customerStatus",
      },
      {
        label: "Archived",
        type: "single",
        value: "archived",
        group: "customerStatus",
      },
      {
        label: "All",
        type: "single",
        value: "all",
        group: "customerStatus",
      },
    ],
  },
};

const MAX_PAGE_COUNT = 1_000_000;

interface State {
  searchQuery: string;
  pageIndex: number;
  pageSize: number;
  filters: readonly OptionType[];
}

export const CustomersList: React.FC = () => {
  const now = useNow();
  const { environmentType } = useEnvironment();

  // sync search query with url
  const [searchQueryInUrl, setSearchQuery] = useSearchParam("s");
  const [filtersInUrl, setFilters] = useSearchParam("filters");

  // make initial state on mount, DON'T change with url updates
  const initialState = React.useMemo(
    (): State => ({
      searchQuery: searchQueryInUrl,
      filters: [FILTERS.customerStatus.options[0]],
      pageIndex: 0,
      pageSize: window.innerHeight >= 1600 ? 30 : 20,
    }),
    [],
  );
  const [state, updateState] = React.useState<State>(initialState);

  const { newUIEnabled } = useUIMode();

  // sync external changes of the URL with the state
  React.useEffect(() => {
    updateState((s): State => {
      let filtersFromUrl = filtersInUrl
        .split(",")
        .map((f) => FILTERS.customerStatus.options.find((o) => o.value === f))
        .filter((f): f is OptionType => !!f);
      if (filtersFromUrl.length === 0) {
        filtersFromUrl = [FILTERS.customerStatus.options[0]];
      }

      if (s.searchQuery === searchQueryInUrl && s.filters === filtersFromUrl) {
        return s;
      }

      return {
        ...s,
        searchQuery: searchQueryInUrl,
        filters: filtersFromUrl,
        pageIndex: 0,
      };
    });
  }, [searchQueryInUrl, filtersInUrl]);

  // debounce search query so we don't send too many queries
  const debouncedSearchQuery = useDebounce(state.searchQuery, 500);

  // when the debounced query updates, sync it to the URL
  React.useEffect(() => {
    if (debouncedSearchQuery !== searchQueryInUrl) {
      setSearchQuery(debouncedSearchQuery);
    }
  }, [
    debouncedSearchQuery,
    // disabled because we only want to invalidate the effect when the debounced query changes
    // if this was enabled then it would always store the search query in the URL when it is updated
    // by external sources, like the Customer link in the navigation.
    /*, searchQueryInUrl*/
  ]);

  const disableCustomerPage = useFeatureFlag("disabled-customer-page", false);
  const listFilterAndOrder = React.useMemo(() => {
    return {
      order_by: [{ name: Order_By.AscNullsLast }],
      filter: {
        _and: state.filters.flatMap((f): Customer_Bool_Exp | never[] => {
          if (f.value === "notArchived") {
            return {
              _or: [
                {
                  archived_at: {
                    _is_null: true,
                  },
                },
                {
                  archived_at: {
                    _gt: now.toISOString(),
                  },
                },
              ],
            };
          }

          if (f.value === "archived") {
            return {
              archived_at: {
                _lte: now.toISOString(),
              },
            };
          }

          return [];
        }),
      },
    };
  }, [state.filters]);

  const listCustomerResponse = useListCustomerQuery({
    skip: !!debouncedSearchQuery || disableCustomerPage,
    variables: {
      environment_type: environmentType,
      limit: state.pageSize,
      offset: state.pageIndex * state.pageSize,
      ...listFilterAndOrder,
    },
  });

  const archivedFilter = React.useMemo(() => {
    if (state.filters.some((f) => f.value === "archived")) {
      return ArchivedFilter.Archived;
    }
    if (state.filters.some((f) => f.value === "notArchived")) {
      return ArchivedFilter.NotArchived;
    }
    return ArchivedFilter.Both;
  }, [state.filters]);

  const searchCustomersResponse = useSearchCustomersQuery({
    skip: !debouncedSearchQuery,
    variables: {
      environment_type: environmentType,
      query: debouncedSearchQuery,
      archived: archivedFilter,
    },
  });

  const { req, totalCount, rows } = debouncedSearchQuery
    ? {
        req: searchCustomersResponse,
        totalCount: searchCustomersResponse.data?.searchCustomers.length ?? 0,
        rows: (searchCustomersResponse.data?.searchCustomers ?? []).slice(
          state.pageIndex * state.pageSize,
          (state.pageIndex + 1) * state.pageSize,
        ),
      }
    : {
        req: listCustomerResponse,
        totalCount: state.filters.some((filter) => filter.value === "archived")
          ? listCustomerResponse.data?.totalArchivedCustomers[0]?.customer_count
              .count ?? 0
          : listCustomerResponse.data?.totalNonArchivedCustomers[0]
              ?.customer_count.count ?? 0,
        rows: listCustomerResponse.data?.Customer ?? [],
      };

  const pageCount = React.useMemo(() => {
    const actualCount = Math.ceil(totalCount / state.pageSize);
    return actualCount > MAX_PAGE_COUNT ? MAX_PAGE_COUNT : actualCount;
  }, [totalCount, state.pageSize]);

  const displayPageContent = () => {
    if (req.error) {
      return (
        <ErrorEmptyState
          title="We ran into an issue loading your customers"
          error={req.error}
        />
      );
    }

    if (req.called && !req.loading && totalCount === 0) {
      if (debouncedSearchQuery) {
        return (
          <EmptyState
            title="No matching customers found."
            subtitle={`Try a different ${
              debouncedSearchQuery.length ? "search term" : "filter"
            }.`}
            icon="briefcase01"
          />
        );
      }

      return (
        <EmptyState
          title="You haven't added any customers to Metronome yet."
          subtitle="Start by adding your first customer to begin tracking customer information in Metronome."
          buttonAuthDoc={InsertCustomerDocument}
          buttonText="Add new customer"
          buttonRoutePath="/customers/new"
          icon="briefcase01"
          buttonIcon="plus"
        />
      );
    }

    return disableCustomerPage && !debouncedSearchQuery ? (
      <EmptyState
        title="We'll be back soon with a refreshed customer page"
        subtitle="Use the search at the top of the page to find customers."
        icon="searchSm"
      />
    ) : newUIEnabled ? (
      <>
        <div className="flex flex-row items-center gap-12">
          <Input
            value={state.searchQuery}
            onChange={(value) => {
              updateState(
                (s): State => ({
                  ...s,
                  searchQuery: value,
                  pageIndex: 0,
                }),
              );
            }}
            type="search"
            placeholder="Search"
            className="w-[300px]"
          />
          <Filter
            className="ml-0"
            value={state.filters}
            options={FILTERS}
            onChange={(filters) => {
              setFilters(
                filters.length > 1 ||
                  filters[0] !== FILTERS.customerStatus.options[0]
                  ? filters.map((f) => f.value).join(",")
                  : "",
              );
            }}
            onReset={() => {
              setFilters("");
            }}
          />
          <GatedButton
            doc={InsertCustomerDocument}
            className="h-[34px]"
            text="Add"
            theme="primary"
            leadingIcon="plus"
            linkTo="/customers/new"
          />
        </div>

        <Table
          loading={req.loading}
          data={rows}
          rowRoutePath={(row) => `/customers/${row.id}`}
          columns={[
            {
              id: "customer",
              header: "Customer/contract",
              render: (row) => (
                <span
                  className={
                    Customer.isInactive(row, now) ? "text-gray-400" : ""
                  }
                >
                  {Customer.isArchived(row, now) && (
                    <Badge theme="warning" type="dark" className="mr-8">
                      ARCHIVED
                    </Badge>
                  )}
                  {row.name}
                </span>
              ),
              textWrappable: true,
            },
            {
              id: "status",
              header: "Status",
              align: "right",
              render: (row) => <CustomerStatus customer={row} />,
            },
          ]}
        />
      </>
    ) : (
      <Table
        loading={req.loading}
        maxPageSize={state.pageSize}
        pageIndex={state.pageIndex}
        manualPagination={{
          numItems: totalCount,
          pageCount,
          onPageChanged(newPageState) {
            updateState(
              (s): State => ({
                ...s,
                pageIndex: newPageState.newPageIndex,
                pageSize: newPageState.newPageSize,
              }),
            );
          },
        }}
        data={rows}
        rowRoutePath={(row) => `/customers/${row.id}`}
        columns={[
          {
            id: "name",
            header: "Customer name",
            render: (row) => (
              <span
                className={Customer.isInactive(row, now) ? "text-gray-400" : ""}
              >
                {Customer.isArchived(row, now) && (
                  <Badge theme="warning" type="dark" className="mr-8">
                    ARCHIVED
                  </Badge>
                )}
                {row.name}
              </span>
            ),
            textWrappable: true,
          },
          {
            id: "status",
            header: "Contract & plan",
            align: "right",
            render: (row) => <CustomerStatus customer={row} />,
          },
          {
            id: "created_at",
            header: "Date Created (UTC)",
            align: "right",
            render: (row) => (
              <span
                title={renderDateTimeInUTC(new Date(row.created_at), false)}
              >
                {renderDate(new Date(row.created_at), {
                  isUtc: true,
                  excludeUtcLabel: true,
                })}
              </span>
            ),
          },
        ]}
      />
    );
  };
  return newUIEnabled ? (
    <AppShell title="Customers">{displayPageContent()}</AppShell>
  ) : (
    <PageContainer
      title="Customers"
      action={
        <div className="flex flex-row items-center gap-12">
          <Input
            value={state.searchQuery}
            onChange={(value) => {
              updateState(
                (s): State => ({
                  ...s,
                  searchQuery: value,
                  pageIndex: 0,
                }),
              );
            }}
            type="search"
            placeholder="Search"
            className="w-[300px]"
          />
          <Filter
            className="ml-0"
            value={state.filters}
            options={FILTERS}
            onChange={(filters) => {
              setFilters(
                filters.length > 1 ||
                  filters[0] !== FILTERS.customerStatus.options[0]
                  ? filters.map((f) => f.value).join(",")
                  : "",
              );
            }}
            onReset={() => {
              setFilters("");
            }}
          />
          <GatedButton
            doc={InsertCustomerDocument}
            className="h-[34px]"
            text="Add new customer"
            theme="primary"
            leadingIcon="plus"
            linkTo="/customers/new"
          />
        </div>
      }
    >
      {displayPageContent()}
    </PageContainer>
  );
};
