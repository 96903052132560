import React, { useState } from "react";
import { useUIMode } from "lib/useUIMode";
import NotFoundPage from "pages/404";
import { View } from "./view";
import { APIKeys } from "./APIKeys";
import { Webhooks } from "./Webhooks";

export const APIKeysAndWebhooks: React.FC = () => {
  const { newUIEnabled } = useUIMode();
  const [selectedView, setSelectedView] = useState<View>("API_KEYS");

  if (!newUIEnabled) {
    return <NotFoundPage />;
  }

  return selectedView === "API_KEYS" ? (
    <APIKeys setSelectedView={setSelectedView} />
  ) : (
    <Webhooks setSelectedView={setSelectedView} />
  );
};
