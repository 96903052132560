import React from "react";
import { DocumentNode } from "graphql";
import { useAuthCheck } from "../../lib/useAuthCheck";
import { EmptyState } from "../EmptyState";

interface AuthCheckProps extends React.PropsWithChildren {
  authDoc: DocumentNode | undefined;
  authVars?: any;
}

export const AuthCheck: React.FC<AuthCheckProps> = ({
  children,
  authDoc,
  authVars,
}) => {
  const { allowed } = useAuthCheck(authDoc, true, authVars);
  if (allowed) {
    return <>{children}</>;
  } else {
    return <EmptyState title="You do not have access to view this page" />;
  }
};
