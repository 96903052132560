import React, { useState } from "react";

import { Body, Input } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";

import { useSnackbar } from "components/Snackbar";
import {
  useAddAzureMarketplaceToCustomerMutation,
  useInsertCustomerBillingProviderConfigurationMutation,
} from "./queries.graphql";
import {
  CustomerSettingsWritePlansAndOrContracts,
  PLANS_ONLY_EDITING_MESSAGE,
} from "../StripeSettingsModal";

type AzureMarketplaceSettingsModalProps = {
  onClose: (status?: { isSuccess: true }) => void;
  customerID: string;
  edit?: boolean;
  azureSubscriptionID?: string;
} & CustomerSettingsWritePlansAndOrContracts;

const evictCacheOptions = {
  update(cache: any) {
    cache.evict({
      fieldName: "azure_billing_provider_configs",
    });
    cache.evict({
      fieldName: "customer_billing_provider_configurations",
    });
    cache.evict({
      fieldName: "BillingProviderCustomer",
    });
    cache.evict({
      fieldName: "CustomerConfig",
    });
    cache.evict({
      fieldName: "Customer_aggregate",
    });
  },
};

export const AzureMarketplaceSettingsModal: React.FC<
  AzureMarketplaceSettingsModalProps
> = ({ onClose, customerID, edit = false, ...props }) => {
  const pushMessage = useSnackbar();
  const [azureSubscriptionId, setAzureSubscriptionId] = useState<string>(
    edit ? props.azureSubscriptionID || "" : "",
  );
  const [
    updateSettingsPlansAndContracts,
    {
      loading: updateSettingsPlansAndContractsLoading,
      error: updateSettingsPlansAndContractsError,
    },
  ] = useAddAzureMarketplaceToCustomerMutation(evictCacheOptions);
  const [
    updateSettingsContractsOnly,
    {
      loading: updateSettingsContractsOnlyLoading,
      error: updateSettingsContractsOnlyError,
    },
  ] = useInsertCustomerBillingProviderConfigurationMutation(evictCacheOptions);

  const loading =
    updateSettingsPlansAndContractsLoading ||
    updateSettingsContractsOnlyLoading;
  const error =
    updateSettingsPlansAndContractsError || updateSettingsContractsOnlyError;

  const updateSettingsAction = async () => {
    if (azureSubscriptionId) {
      if (props.plansAndOrContracts === "contracts_only" && !edit) {
        await updateSettingsContractsOnly({
          variables: {
            customer_id: customerID,
            configuration: {
              azure_subscription_id: azureSubscriptionId,
            },
          },
        });
      } else {
        // TODO: modify resolver to only update configs for plans
        await updateSettingsPlansAndContracts({
          variables: {
            customer_id: customerID,
            azure_subscription_id: azureSubscriptionId,
          },
        });
      }
      pushMessage({
        content: "Azure Marketplace configuration saved",
        type: "success",
      });
      onClose({
        isSuccess: true,
      });
    }
  };
  const actionButtons = (
    <Button
      key="primary"
      disabled={!azureSubscriptionId || loading}
      onClick={updateSettingsAction}
      loading={loading}
      text={`${edit ? "Save" : "Add"} Azure Marketplace configuration`}
      theme="primary"
    />
  );

  return (
    <Popup
      actions={actionButtons}
      isOpen={true}
      onRequestClose={() => onClose()}
      title="Azure Marketplace configuration"
    >
      <Body level={2}>
        {edit
          ? `Edit Azure marketplace customer configuration. ` +
            PLANS_ONLY_EDITING_MESSAGE
          : `To add the Azure Marketplace profile you need the customer’s
        subscription ID associated with their marketplace purchase.`}
      </Body>
      <Input
        name="Azure subscription ID"
        placeholder="Enter Azure subscription ID"
        value={azureSubscriptionId ?? ""}
        className="mb-12"
        onChange={setAzureSubscriptionId}
        autoFocus
        error={!loading && error?.message}
      />
    </Popup>
  );
};

export default AzureMarketplaceSettingsModal;
