import { useCallback, useEffect, useMemo, useState } from "react";
import { Schema } from "../Schema";
import { FormController } from "lib/FormController";
import { useGetAllCreditTypesQuery } from "../CreateAndEditRateCard/data.graphql";
import { filterAndSortCreditTypes } from "pages/Contracts/lib/CreditTypes";
import { createContainer } from "unstated-next";
import { useParams } from "react-router-dom";
import { useRateCardQuery } from "../RateCardsDetails/data.graphql";
import { useProductsQuery } from "./data.graphql";
import { USD_CREDIT_TYPE } from "lib/credits";
import { FiatCreditType } from "types/credit-types";
import { MAX_SORTABLE_SEARCHABLE_SIZE } from "pages/Contracts/components/RateSchedulePanel";
import { useNow } from "lib/date";
import {
  useGetRateScheduleQuery,
  useGetRateCardProductsQuery,
} from "../../components/RateSchedulePanel/data.graphql";
import {
  categorizeProducts,
  getDefaultRates,
  ProductListItem,
} from "./contextUtils";
import { deepEqual } from "fast-equals";

type DimensionalProduct =
  Schema.Types.UnifiedRateCardInput["dimensionalProducts"][0];
/**
 * We will only be using the form controller for validation and snapshotting.
 */
export const useRateCardV2Controller = FormController.createHook(
  Schema.UnifiedRateCardInput,
  {
    init({
      existingRateCard,
      snapshotKey,
    }: {
      existingRateCard: Partial<Schema.Types.UnifiedRateCardInput>;
      snapshotKey: string;
    }) {
      const snapshot = FormController.parseJsonSnapshot(
        Schema.UnifiedRateCardInput,
        sessionStorage.getItem(snapshotKey),
      );

      if (snapshot && !snapshot?.fiatCreditTypeId) {
        snapshot.fiatCreditTypeId = USD_CREDIT_TYPE.id;
      }

      if (!existingRateCard) {
        return snapshot;
      }
      return { ...existingRateCard, ...snapshot };
    },
  },
);

export type RateCardV2Ctrl = ReturnType<typeof useRateCardV2Controller>;

export type RateProductEnum =
  | "usageRates"
  | "subscriptionRates"
  | "compositeRates";

function useRateCardContext() {
  const { data, loading: loadingCreditTypes } = useGetAllCreditTypesQuery();
  const { fiatCreditTypes, customCreditTypes } = useMemo(
    () => filterAndSortCreditTypes(data?.CreditType ?? []),
    [data?.CreditType],
  );
  const {
    data: products,
    loading: loadingProducts,
    error: productDataError,
  } = useProductsQuery();

  /**
   * Only contains products which show up on the rate card form.
   * For now, this removes professional services and fix products.
   */
  const filteredProductsByType = useMemo(() => {
    if (products) {
      const filteredProducts = products.contract_pricing.products.filter(
        (product) => {
          return (
            product.__typename === "UsageProductListItem" ||
            product.__typename === "SubscriptionProductListItem" ||
            product.__typename === "CompositeProductListItem"
          );
        },
      );
      return filteredProducts;
    } else {
      return [];
    }
  }, [products]);

  const params = useParams();
  const isEdit: boolean = params["id"] !== undefined;
  const existingRateCardId = params["id"] ?? "";

  const snapshotKey = isEdit
    ? `rate-card-edit-v2 ${existingRateCardId}`
    : `rate-card-create-v2`;

  const rateCardReq = useRateCardQuery({
    variables: { id: existingRateCardId },
    skip: !isEdit,
  });
  const now = useNow();

  // TODO - when reading existing rates, if USD_CENTS, then divide by 100:w
  const { data: existingRates, loading: existingRatesLoading } =
    useGetRateScheduleQuery({
      variables: {
        // TODO: pagination
        // cursor: pagination.pointer.cursor,
        cursor: null,
        limit: String(MAX_SORTABLE_SEARCHABLE_SIZE),
        rateCardId: existingRateCardId,
        startingAt: now.toISOString(),
        endingBefore: null,
        truncate: false,
        tryProductOrderSort: true,
        selectors: null,
      },
      skip: !isEdit,
    });

  const { data: existingProducts, loading: existingProductsLoading } =
    useGetRateCardProductsQuery({
      variables: {
        rateCardId: existingRateCardId,
      },
      skip: !isEdit,
    });
  // do not query for rates if giga rate card enabled
  const rateCard = rateCardReq.data?.contract_pricing.rate_card;

  const rateCardInput = useMemo(() => {
    if (!rateCard) return undefined;
    const input: Schema.Types.RateCardInput = {
      name: rateCard.name || "",
      description: rateCard.description || undefined,
      rates: [],
      fiatCreditTypeId: rateCard.fiat_credit_type.id,
      fiatCreditTypeName: rateCard.fiat_credit_type.name,
      creditTypeConversions: rateCard.credit_type_conversions?.map(
        (conversion) => ({
          custom_credit_type_id: conversion.custom_credit_type.id,
          custom_credit_type_name: conversion.custom_credit_type.name,
          fiat_per_custom_credit: Number(conversion.fiat_per_custom_credit),
        }),
      ),
    };
    return input;
  }, [rateCard]);

  const existingRateCard = isEdit ? rateCardInput : undefined;

  /**
   * This will be used to store the snapshot of the form and do final validation.
   */
  const ctrl = useRateCardV2Controller({
    existingRateCard: {
      ...existingRateCard,
      usageRates: [],
      compositeRates: [],
      subscriptionRates: [],
    },
    snapshotKey,
  });

  // save snapshot on every change of the form
  useEffect(() => {
    sessionStorage.setItem(snapshotKey, JSON.stringify(ctrl.snapshot()));
  }, [ctrl]);

  useEffect(() => {
    if (isEdit && !rateCardReq.loading) {
      const snapshot = FormController.parseJsonSnapshot(
        Schema.UnifiedRateCardInput,
        sessionStorage.getItem(snapshotKey),
      );
      ctrl.update({ ...existingRateCard, ...snapshot });
    }
  }, [rateCardReq]);

  function clearSnapshot() {
    sessionStorage.removeItem(snapshotKey);
  }

  const productsEqualsSelectedProducts = (
    products: string[],
    selectedProducts: string[],
  ) => {
    const productsSet = new Set(products);
    if (productsSet.size !== selectedProducts.length) return false;
    return selectedProducts.every((p) => productsSet.has(p));
  };

  useEffect(() => {
    if (isEdit && existingProducts) {
      const snapshot = FormController.parseJsonSnapshot(
        Schema.UnifiedRateCardInput,
        sessionStorage.getItem(snapshotKey),
      );
      const uniqueProductIds = new Set([
        ...existingProducts.contract_pricing.rate_card.products.map(
          (p) => p.id,
        ),
        ...(ctrl.get("selectedProducts") ?? []),
      ]);
      const uniqueProductIdsArray = Array.from(uniqueProductIds);
      // if there is a snapshot and the selected products are different from the snapshot (when the user refreshes the page)
      if (
        !productsEqualsSelectedProducts(
          snapshot?.selectedProducts ?? [],
          ctrl.get("selectedProducts") ?? [],
        )
      ) {
        setSelectedProducts(snapshot?.selectedProducts);
        // if there is no snapshot and the selected products are different from the existing products
      } else if (
        !productsEqualsSelectedProducts(
          uniqueProductIdsArray,
          ctrl.get("selectedProducts") ?? [],
        )
      ) {
        setSelectedProducts(uniqueProductIdsArray);
      }
    }
  }, [existingProducts, isEdit, snapshotKey]);

  const productsMap = useMemo(() => {
    const data = new Map<string, ProductListItem>();
    return (
      Array.from(filteredProductsByType.values()).reduce((acc, product) => {
        acc.set(product.id, product);
        return acc;
      }, data) ?? data
    );
  }, [filteredProductsByType]);

  const [creditTypeConversions, setCreditTypeConversions] = useState<
    Schema.Types.CreditTypeConversion[]
  >(ctrl.get("creditTypeConversions") ?? []);

  const conversionRateChange = useCallback(
    (conversion: Schema.Types.CreditTypeConversion) => {
      setCreditTypeConversions((conversions) => {
        const conversionIndex = conversions.findIndex(
          (c) => c.custom_credit_type_id === conversion.custom_credit_type_id,
        );
        if (conversionIndex >= 0) {
          return [
            ...conversions.slice(0, conversionIndex),
            conversion,
            ...conversions.slice(conversionIndex + 1),
          ];
        } else {
          return [...conversions, conversion];
        }
      });
    },
    [setCreditTypeConversions],
  );

  useEffect(() => {
    ctrl.update({ creditTypeConversions });
  }, [creditTypeConversions]);

  const [selectedProducts, setSelectedProductsInternal] = useState<string[]>(
    ctrl.get("selectedProducts") ?? [],
  );
  const [dimensionalProducts, setDimensionalProducts] = useState<
    DimensionalProduct[]
  >(ctrl.get("dimensionalProducts") ?? []);

  const [fiatCreditType, setFiatCreditType] =
    useState<FiatCreditType>(USD_CREDIT_TYPE);

  // update fiat credit type if changed and not a custom credit type
  useEffect(() => {
    // only update if it has changed though
    const currentFiatCreditType = ctrl.get("fiatCreditTypeId");
    if (currentFiatCreditType !== fiatCreditType.id) {
      const usageRates = rates.usageRates.map((rate) => ({
        ...rate,
        creditType: fiatCreditType,
      }));

      const subscriptionRates = rates.subscriptionRates.map((rate) => ({
        ...rate,
        creditType: fiatCreditType,
      }));

      const compositeRates = rates.compositeRates.map((rate) => ({
        ...rate,
        creditType: fiatCreditType,
      }));

      setRates({
        usageRates,
        subscriptionRates,
        compositeRates,
      });

      ctrl.update({
        fiatCreditTypeId: fiatCreditType.id,
        usageRates,
        subscriptionRates,
        compositeRates,
        creditTypeConversions: [],
      });
    }
  }, [fiatCreditType]);

  const setRateStartingAtDate = useCallback(
    (type: RateProductEnum, date?: Date) => {
      if (date) {
        setRates((rateTypes) => {
          return {
            ...rateTypes,
            [type]: rateTypes[type].map((rate) => {
              return {
                ...rate,
                startingAt: date.toISOString(),
              };
            }),
          };
        });

        ctrl.update({
          [type]: ctrl.get(type)?.map((rate) => {
            return {
              ...rate,
              startingAt: date?.toISOString() ?? undefined,
            };
          }),
        });
      }
    },
    [],
  );

  const setRateEndingBeforeDate = useCallback(
    (type: RateProductEnum, date?: Date) => {
      setRates((rateTypes) => {
        return {
          ...rateTypes,
          [type]: rateTypes[type].map((rate) => {
            return {
              ...rate,
              endingBefore: date?.toISOString(),
            };
          }),
        };
      });

      ctrl.update({
        [type]: ctrl.get(type)?.map((rate) => {
          return {
            ...rate,
            endingBefore: date?.toISOString() ?? undefined,
          };
        }),
      });
    },
    [],
  );

  const [rates, setRates] = useState<{
    usageRates: Schema.Types.Rate[];
    subscriptionRates: Schema.Types.Rate[];
    compositeRates: Schema.Types.Rate[];
  }>({
    usageRates: ctrl.get("usageRates") ?? [],
    subscriptionRates: ctrl.get("subscriptionRates") ?? [],
    compositeRates: ctrl.get("compositeRates") ?? [],
  });

  useEffect(() => {
    ctrl.update({
      usageRates: rates.usageRates,
      subscriptionRates: rates.subscriptionRates,
      compositeRates: rates.compositeRates,
    });
  }, [rates]);

  const editRate = useCallback(
    (
      type: RateProductEnum,
      update: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
    ) => {
      setRates((rateTypes) => {
        const rates = rateTypes[type];
        // TODO(ekaragiannis) - we need to be able to updates the credit type for all ones in the product
        const originalIndex = rates.findIndex((r) => r.id === update.id);
        if (originalIndex >= 0) {
          // if the change was to the credit type, then we need to update all subrates for that product
          const currentRate = rates[originalIndex];
          if (currentRate.creditType?.id !== update.creditType?.id) {
            return {
              ...rateTypes,
              [type]: rates.map((r, index) => {
                if (index === originalIndex) {
                  return update;
                } else if (r.productId === update.productId) {
                  return {
                    ...r,
                    creditType: update.creditType,
                  };
                } else {
                  return r;
                }
              }),
            };
          } else {
            return {
              ...rateTypes,
              [type]: [
                ...rates.slice(0, originalIndex),
                update,
                ...rates.slice(originalIndex + 1),
              ],
            };
          }
        } else {
          return rateTypes;
        }
      });
    },
    [setRates],
  );

  const addRateOverride = useCallback(
    (
      type: RateProductEnum,
      previousRate: Schema.Types.Rate,
      update: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
    ) => {
      setRates((rateTypes) => {
        const rates = rateTypes[type];
        const originalIndex = rates.findLastIndex(
          (r) =>
            r.productId === previousRate.productId &&
            deepEqual(r.pricingGroupValues, previousRate.pricingGroupValues),
        );
        return {
          ...rateTypes,
          [type]: [
            ...rates.slice(0, originalIndex + 1),
            update,
            ...rates.slice(originalIndex + 1),
          ],
        };
      });
    },
    [setRates],
  );

  const removeRateOverride = useCallback(
    (
      type: RateProductEnum,
      update: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
    ) => {
      setRates((rateTypes) => {
        const rates = rateTypes[type];
        const originalIndex = rates.findIndex((r) => r.id === update.id);
        if (originalIndex >= 0) {
          return {
            ...rateTypes,
            [type]: [
              ...rates.slice(0, originalIndex),
              ...rates.slice(originalIndex + 1),
            ],
          };
        }

        return rateTypes;
      });
    },
    [setRates],
  );

  /**
   * Update the selected products and set the dimensional products.
   * This will also ensure we have rate entries for each product.
   * This may add / remove rates / sub-rates based on the selected products.
   */
  const setSelectedProducts = useCallback(
    (selectedProducts: string[]) => {
      const currentDimensionalProducts = dimensionalProducts;
      const {
        dimensionalProducts: newDimensionalProducts,
        nonDimensionalProducts,
      } = categorizeProducts(selectedProducts, productsMap);

      const finalDimensionalProducts = newDimensionalProducts.map((dp) => {
        const currentProduct = currentDimensionalProducts.find(
          (cdp) =>
            cdp.id === dp.id &&
            dp.pricingGroupKeyValues.every((pair) =>
              cdp.pricingGroupKeyValues.some((cpair) => cpair.key === pair.key),
            ),
        );
        if (currentProduct) {
          return currentProduct;
        } else {
          return dp;
        }
      });
      const { usageRates, subscriptionRates, compositeRates } = getDefaultRates(
        rates.usageRates,
        rates.subscriptionRates ?? [],
        rates.compositeRates ?? [],
        nonDimensionalProducts,
        finalDimensionalProducts,
        fiatCreditType,
      );

      setDimensionalProducts(finalDimensionalProducts);
      setSelectedProductsInternal(selectedProducts);
      ctrl.update({
        selectedProducts,
        dimensionalProducts: finalDimensionalProducts,
        usageRates,
        subscriptionRates,
        compositeRates,
      });

      setRates({
        usageRates,
        subscriptionRates,
        compositeRates,
      });
    },
    [productsMap, rates],
  );

  const removeProduct = useCallback(
    (productId: string) => {
      setSelectedProducts(selectedProducts.filter((p) => p !== productId));
    },
    [setRates, selectedProducts, setSelectedProducts],
  );

  const [name, setNameInternal] = useState(ctrl.get("name") ?? "");
  const setName = useCallback(
    (val: string) => {
      setNameInternal(val);
      ctrl.update({ name: val });
    },
    [setNameInternal],
  );

  const [description, setDescriptionInternal] = useState(
    ctrl.get("description") ?? "",
  );
  const setDescription = useCallback(
    (val: string) => {
      setDescriptionInternal(val);
      ctrl.update({ description: val });
    },
    [setDescriptionInternal],
  );

  const [aliases, setAliasesInternal] = useState<Schema.Types.RateCardAlias[]>(
    ctrl.get("aliases") ?? [],
  );
  const setAliases = useCallback(
    (aliases: Schema.Types.RateCardAlias[]) => {
      setAliasesInternal(aliases);
      ctrl.update({ aliases });
    },
    [setAliasesInternal],
  );

  const hasValidRates = useMemo(() => {
    return ctrl.isValid();
  }, [ctrl]);

  return {
    loading: loadingCreditTypes || loadingProducts,
    fiatCreditTypes: fiatCreditTypes as FiatCreditType[],
    customCreditTypes,
    clearSnapshot,
    products: filteredProductsByType,
    productsMap,
    productDataError,
    fiatCreditType,
    editRate,
    addRateOverride,
    removeRateOverride,
    removeProduct,
    setFiatCreditType,
    conversionRateChange,
    setRateStartingAtDate,
    setRateEndingBeforeDate,
    aliases,
    setAliases,
    rates,
    name,
    setName,
    description,
    setDescription,
    dimensionalProducts,
    hasValidRates,
    isEdit,
    existingRateCard,
    existingRateCardId,
    existingRates,
    existingRatesLoading,
    existingProducts,
    existingProductsLoading,
    selectedProducts,
    creditTypeConversions,
    setSelectedProducts,
    setDimensionalProductKeyValues: (
      values: Array<{ productId: string; key: string; values: string[] }>,
    ) => {
      const dimensionalProducts = ctrl.get("dimensionalProducts") ?? [];
      const updatedDimensionalProducts = dimensionalProducts.map((dp) => {
        const product = values.find((v) => v.productId === dp.id);
        if (product) {
          return {
            ...dp,
            pricingGroupKeyValues: dp.pricingGroupKeyValues.map((pair) => {
              if (pair.key === product.key) {
                return {
                  ...pair,
                  values: product.values,
                };
              }

              return pair;
            }),
          };
        } else {
          return dp;
        }
      });

      const { nonDimensionalProducts } = categorizeProducts(
        selectedProducts,
        productsMap,
      );
      const { usageRates, subscriptionRates, compositeRates } = getDefaultRates(
        rates.usageRates ?? [],
        rates.subscriptionRates ?? [],
        rates.compositeRates ?? [],
        nonDimensionalProducts,
        updatedDimensionalProducts,
        fiatCreditType,
      );

      setRates({
        usageRates,
        subscriptionRates,
        compositeRates,
      });

      setDimensionalProducts(updatedDimensionalProducts);
      ctrl.update({
        dimensionalProducts: updatedDimensionalProducts,
        usageRates,
        subscriptionRates,
        compositeRates,
      });
    },
  };
}

export const RateCardContext = createContainer(useRateCardContext);
