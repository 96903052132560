import { REPORTS } from "pages/Reports";
import { LDClient } from "launchdarkly-js-client-sdk";

export const useHasAccessToReports = (ldClient: LDClient | undefined) => {
  if (!ldClient) {
    return false;
  }
  const availableReports = REPORTS.filter((report) =>
    report.isAllowedForUser(ldClient),
  );
  return availableReports.length > 0;
};

export { downloadCSV } from "./downloadCSV";
