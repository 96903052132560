import React, { useState } from "react";
import { Body } from "design-system";
import { Button } from "tenaissance/components/Button";
import { Popup } from "components/Popup";
import pluralize from "pluralize";
import { Input } from "design-system";
import { useSnackbar } from "components/Snackbar";
import {
  useGetCustomersWithStripeEnabledCountQuery,
  useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation,
} from "./queries.graphql";
import { useEnvironment } from "lib/environmentSwitcher/context";

interface DeleteStripeSettingsModalProps {
  onClose: () => void;
}

const DeleteStripeSettingsModal: React.FC<DeleteStripeSettingsModalProps> = ({
  onClose,
}) => {
  const sandboxMode = useEnvironment();
  const { data } = useGetCustomersWithStripeEnabledCountQuery({
    variables: {
      environment_type: sandboxMode.environmentType,
    },
  });
  const [hasPassedFirstDeleteStep, setHasPassedFirstDeleteStep] =
    useState(false);
  const [
    deleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation,
  ] = useDeleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation(
    {
      update(cache) {
        cache.evict({
          fieldName: "BillingProviderToken",
        });
        cache.evict({
          fieldName: "BillingProviderCustomer",
        });
      },
    },
  );

  const [
    numCustomersStripeSettingsToDelete,
    setNumCustomersStripeSettingsToDelete,
  ] = useState<string | null>(null);

  const numCustomers = Number(data?.Customer_aggregate.aggregate?.count || "0");
  const pushMessage = useSnackbar();

  const numCustomersStripeSettingsToDeleteValueIsValid = (value: string) => {
    return value == `${numCustomers} ${pluralize("customer", numCustomers)}`;
  };

  const deleteBillingProviderTokenAndAllStripeBillingProviderCustomersAction =
    async () => {
      try {
        await deleteBillingProviderTokenAndAllStripeBillingProviderCustomersMutation();
        pushMessage({
          content: "Stripe has been disabled",
          type: "success",
        });
      } catch (e) {
        pushMessage({
          content: "Failed to disable Stripe",
          type: "error",
        });
        throw e;
      }
    };

  const actionButtonsFirstStep = (
    <>
      <Button onClick={onClose} text="Cancel" theme="linkGray" />
      <Button
        onClick={() => {
          setHasPassedFirstDeleteStep(true);
        }}
        text="Yes, disable Stripe"
        theme="linkDestructive"
      />
    </>
  );

  const actionButtonsSecondStep = (
    <>
      <Button onClick={onClose} text="Cancel" theme="linkGray" />
      <Button
        disabled={
          numCustomers > 0 &&
          !numCustomersStripeSettingsToDeleteValueIsValid(
            numCustomersStripeSettingsToDelete || "",
          )
        }
        onClick={async () => {
          await deleteBillingProviderTokenAndAllStripeBillingProviderCustomersAction();
          onClose();
        }}
        text="Yes, disable Stripe"
        theme="linkDestructive"
      />
    </>
  );

  if (!hasPassedFirstDeleteStep) {
    return (
      <Popup
        actions={actionButtonsSecondStep}
        isOpen={true}
        onRequestClose={onClose}
        title="Disable Stripe"
      >
        <Body level={2}>
          Disabling Stripe will prevent you from sending invoices via Stripe to
          all your customers. There {numCustomers === 1 ? "is" : "are"}{" "}
          currently {numCustomers} {pluralize("customer", numCustomers)}{" "}
          connected to Stripe.{" "}
          {numCustomers > 0
            ? `To proceed enter the number of customers impacted.`
            : ``}
        </Body>
        {numCustomers > 0 ? (
          <Input
            placeholder={`${numCustomers} ${pluralize(
              "customer",
              numCustomers,
            )}`}
            value={numCustomersStripeSettingsToDelete || ""}
            onChange={(value) => {
              setNumCustomersStripeSettingsToDelete(value);
            }}
            error={
              numCustomersStripeSettingsToDelete !== null &&
              numCustomersStripeSettingsToDelete !==
                `${numCustomers} ${pluralize("customer", numCustomers)}`
                ? `Enter ${numCustomers} ${pluralize("customer", numCustomers)}`
                : undefined
            }
          />
        ) : null}
      </Popup>
    );
  }
  return (
    <Popup
      actions={actionButtonsFirstStep}
      isOpen={true}
      onRequestClose={onClose}
      title="Are you sure you want to disable Stripe?"
    >
      <Body level={2}>
        Disabling Stripe you prevent you to send invoices via Stripe across all
        your customers.
      </Body>
    </Popup>
  );
};

export default DeleteStripeSettingsModal;
