import React, { useState } from "react";
import { Toggle } from "design-system";
import { StripeSettingsToggleProps } from ".";

export const SublineItemsToggle: React.FC<StripeSettingsToggleProps> = ({
  isChecked,
  handleToggleOnChange,
}) => {
  const [isCheckedValue, setIsCheckedValue] = useState(isChecked);
  return (
    <>
      <Toggle
        checked={isCheckedValue}
        label="Display sub-line items on invoices"
        onChange={(value) =>
          handleToggleOnChange(
            value,
            "export_invoice_sub_line_items",
            setIsCheckedValue,
          )
        }
      />
      <div className="leading-tight text-sm font-normal text-gray-600">
        Display more info than just product names and totals
      </div>
    </>
  );
};
