import React, { useRef, useState } from "react";
import { DefaultInput, TextInput } from "../Input";
import { dayjs } from "lib/dayjs";

export const DateInput: React.FC<DefaultInput<Date | undefined>> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputState, setInputState] = useState<string>(
    props.value ? dayjs.utc(props.value).format("MM/DD/YYYY") : "",
  );
  const [prevValue, setPrevValue] = useState<Date | undefined>();
  if (props.value !== prevValue) {
    setPrevValue(props.value);
    setInputState(
      props.value ? dayjs.utc(props.value).format("MM/DD/YYYY") : "",
    );
  }

  const handleOnChange = ({ value: userValue }: { value: string }) => {
    if (!props.onChange) return;

    if (userValue === "" && props.value) {
      props.onChange({ value: undefined });
      return;
    }

    const value = userValue.replace(/\D/g, "");
    let formattedValue = "";

    if (value.length > 0) {
      formattedValue = value.substring(0, 2);
    }
    if (value.length >= 2) {
      formattedValue += "/" + value.substring(2, 4);
    }
    if (value.length >= 4) {
      formattedValue += "/" + value.substring(4, 8);
    }

    const finalDate = dayjs(formattedValue).utc();

    if (formattedValue.length === 10 && finalDate.isValid()) {
      props.onChange({ value: finalDate.toDate() });
    } else if (formattedValue.length === 0) {
      props.onChange({ value: undefined });
    }
    setInputState(formattedValue);
  };

  return (
    <TextInput
      size="sm"
      ref={inputRef}
      autofocus={props.autofocus}
      maxlength={10}
      placeholder="MM/DD/YYYY"
      onChange={handleOnChange}
      onFocus={() => inputRef?.current?.setSelectionRange(0, 0)}
      value={inputState}
      isInvalid={inputState !== "" && props.value === undefined}
      disableClear={true}
    />
  );
};
