/* b74c2708fd7ab51f92c94b66616cee67da244c89
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetClientConfigQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  client_config: Types.ClientConfigKeyEnum_Enum;
}>;


export type GetClientConfigQuery = { __typename?: 'Query', ClientConfig: Array<{ __typename?: 'ClientConfig', id: string, value: string }> };

export type SaveClientConfigMutationVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  client_config: Types.ClientConfigKeyEnum_Enum;
  value: Types.Scalars['String'];
}>;


export type SaveClientConfigMutation = { __typename?: 'Mutation', insert_ClientConfig_one: { __typename?: 'ClientConfig', id: string, value: string } | null };

export type DeleteClientConfigMutationVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  client_config: Types.ClientConfigKeyEnum_Enum;
}>;


export type DeleteClientConfigMutation = { __typename?: 'Mutation', delete_ClientConfig: { __typename?: 'ClientConfig_mutation_response', returning: Array<{ __typename?: 'ClientConfig', id: string }> } | null };


export const GetClientConfigDocument = gql`
    query GetClientConfig($environment_type: EnvironmentTypeEnum_enum!, $client_config: ClientConfigKeyEnum_enum!) {
  ClientConfig(
    where: {key: {_eq: $client_config}, environment_type: {_eq: $environment_type}}
  ) {
    id
    value
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetClientConfigQuery__
 *
 * To run a query within a React component, call `useGetClientConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientConfigQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      client_config: // value for 'client_config'
 *   },
 * });
 */
export function useGetClientConfigQuery(baseOptions: Apollo.QueryHookOptions<GetClientConfigQuery, GetClientConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientConfigQuery, GetClientConfigQueryVariables>(GetClientConfigDocument, options);
      }
export function useGetClientConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientConfigQuery, GetClientConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientConfigQuery, GetClientConfigQueryVariables>(GetClientConfigDocument, options);
        }
export type GetClientConfigQueryHookResult = ReturnType<typeof useGetClientConfigQuery>;
export type GetClientConfigLazyQueryHookResult = ReturnType<typeof useGetClientConfigLazyQuery>;
export type GetClientConfigQueryResult = Apollo.QueryResult<GetClientConfigQuery, GetClientConfigQueryVariables>;
export const SaveClientConfigDocument = gql`
    mutation SaveClientConfig($environment_type: EnvironmentTypeEnum_enum!, $client_config: ClientConfigKeyEnum_enum!, $value: String!) {
  insert_ClientConfig_one(
    object: {key: $client_config, value: $value}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value, where: {key: {_eq: $client_config}, environment_type: {_eq: $environment_type}}}
  ) {
    id
    value
    __environment_type: environment_type
  }
}
    `;
export type SaveClientConfigMutationFn = Apollo.MutationFunction<SaveClientConfigMutation, SaveClientConfigMutationVariables>;

/**
 * __useSaveClientConfigMutation__
 *
 * To run a mutation, you first call `useSaveClientConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveClientConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveClientConfigMutation, { data, loading, error }] = useSaveClientConfigMutation({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      client_config: // value for 'client_config'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSaveClientConfigMutation(baseOptions?: Apollo.MutationHookOptions<SaveClientConfigMutation, SaveClientConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveClientConfigMutation, SaveClientConfigMutationVariables>(SaveClientConfigDocument, options);
      }
export type SaveClientConfigMutationHookResult = ReturnType<typeof useSaveClientConfigMutation>;
export type SaveClientConfigMutationResult = Apollo.MutationResult<SaveClientConfigMutation>;
export type SaveClientConfigMutationOptions = Apollo.BaseMutationOptions<SaveClientConfigMutation, SaveClientConfigMutationVariables>;
export const DeleteClientConfigDocument = gql`
    mutation DeleteClientConfig($environment_type: EnvironmentTypeEnum_enum!, $client_config: ClientConfigKeyEnum_enum!) {
  delete_ClientConfig(
    where: {key: {_eq: $client_config}, environment_type: {_eq: $environment_type}}
  ) {
    returning {
      id
      __environment_type: environment_type
    }
  }
}
    `;
export type DeleteClientConfigMutationFn = Apollo.MutationFunction<DeleteClientConfigMutation, DeleteClientConfigMutationVariables>;

/**
 * __useDeleteClientConfigMutation__
 *
 * To run a mutation, you first call `useDeleteClientConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientConfigMutation, { data, loading, error }] = useDeleteClientConfigMutation({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      client_config: // value for 'client_config'
 *   },
 * });
 */
export function useDeleteClientConfigMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientConfigMutation, DeleteClientConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClientConfigMutation, DeleteClientConfigMutationVariables>(DeleteClientConfigDocument, options);
      }
export type DeleteClientConfigMutationHookResult = ReturnType<typeof useDeleteClientConfigMutation>;
export type DeleteClientConfigMutationResult = Apollo.MutationResult<DeleteClientConfigMutation>;
export type DeleteClientConfigMutationOptions = Apollo.BaseMutationOptions<DeleteClientConfigMutation, DeleteClientConfigMutationVariables>;