import { ApolloError } from "@apollo/client";
import { Caption, Headline } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { CopyableID } from "components/CopyableID";
import { RightPane } from "components/Popup";
import { TablePanel } from "components/TablePanel";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import React, { useState } from "react";
import { CommitDetailsFragment } from "./data.graphql";
import { Commit } from "pages/Contracts/lib/Commit";
import { useNow } from "lib/date";
import { ToggleButtons } from "components/ToggleButtons";
import { ProductListItem } from "pages/Contracts/lib/ProductListItem";

type CommitMetadataTab = "applicable-products" | "applicable-tags";

interface Props {
  onRequestClose: () => void;
  commit: CommitDetailsFragment;
  error?: ApolloError;
  loading?: boolean;
}

export const CommitMetadataFlyover: React.FC<Props> = ({
  onRequestClose,
  commit,
  error,
  loading,
}) => {
  const now = useNow();
  const [activeTab, setActiveTab] = useState<CommitMetadataTab>(
    "applicable-products",
  );
  const hasApplicableProducts =
    commit.applicable_products && commit.applicable_products.length > 0;
  const hasApplicableTags =
    commit.applicable_tags && commit.applicable_tags.length > 0;
  const appliesToAllProducts = !hasApplicableProducts && !hasApplicableTags;
  const hasBothApplicableProductsAndTags =
    hasApplicableProducts && hasApplicableTags;
  const applicableTags =
    commit.applicable_tags?.map((tag) => ({ name: tag })) ?? [];
  return (
    <RightPane
      isOpen
      onRequestClose={onRequestClose}
      size="lg"
      contentClassName="!p-0"
    >
      <header className="flex items-center bg-gray-50 px-12 py-8">
        {/* left */}
        <div className="grow">
          <Headline level={6} className="grow">
            {Commit.getName(commit, now)}
          </Headline>
        </div>

        {/* right */}
        <div className="flex items-center">
          <IconButton
            className="grow-0"
            onClick={onRequestClose}
            theme="secondary"
            icon="xClose"
          />
        </div>
      </header>
      <div className="p-12">
        {error ? (
          <ErrorEmptyState
            title="We were unable to load metadata for this commit"
            error={error}
          />
        ) : (
          <div className="flex flex-col gap-12">
            <div>
              <Caption>Commit ID</Caption>
              <CopyableID id={commit.id} label={commit.id} />
            </div>
            {commit.netsuite_sales_order_id ? (
              <div>
                <Caption>Netsuite Sales Order ID</Caption>
                <CopyableID
                  id={commit.netsuite_sales_order_id}
                  label={commit.netsuite_sales_order_id}
                />
              </div>
            ) : (
              ""
            )}
            {hasBothApplicableProductsAndTags || appliesToAllProducts ? (
              <>
                <ToggleButtons<CommitMetadataTab>
                  light
                  value={activeTab}
                  className="!mb-0"
                  buttonProps={[
                    {
                      value: "applicable-products",
                      label: "Applicable products",
                      onChange: () => setActiveTab("applicable-products"),
                    },
                    {
                      value: "applicable-tags",
                      label: "Applicable tags",
                      onChange: () => setActiveTab("applicable-tags"),
                    },
                  ]}
                />
                {activeTab === "applicable-products" ? (
                  <TablePanel
                    title="Product"
                    loading={loading}
                    columns={[
                      {
                        id: "id",
                        header: "Product ID",
                        render: (product) => {
                          return (
                            <CopyableID id={product.id} label={product.id} />
                          );
                        },
                      },
                      {
                        id: "name",
                        header: "Name",
                        textWrappable: true,
                        render: (product) =>
                          ProductListItem.getCurrent(product, "name", now),
                      },
                    ]}
                    data={loading ? [] : commit.applicable_products ?? []}
                    emptyState={
                      <p className="p-24 text-center text-sm text-gray-500">
                        {appliesToAllProducts ? "All products" : "No products"}
                      </p>
                    }
                  />
                ) : (
                  <TablePanel
                    title="Tag"
                    loading={loading}
                    columns={[
                      {
                        id: "name",
                        header: "Name",
                        textWrappable: true,
                        render: (tag) => tag.name,
                      },
                    ]}
                    data={loading ? [] : applicableTags}
                    emptyState={
                      <p className="p-24 text-center text-sm text-gray-500">
                        {appliesToAllProducts ? "All tags" : "No tags"}
                      </p>
                    }
                  />
                )}
              </>
            ) : hasApplicableProducts ? (
              <TablePanel
                title="Applicable products"
                loading={loading}
                columns={[
                  {
                    id: "id",
                    header: "Product ID",
                    render: (product) => {
                      return <CopyableID id={product.id} label={product.id} />;
                    },
                  },
                  {
                    id: "name",
                    header: "Name",
                    textWrappable: true,
                    render: (product) =>
                      ProductListItem.getCurrent(product, "name", now),
                  },
                ]}
                data={loading ? [] : commit.applicable_products ?? []}
              />
            ) : (
              <TablePanel
                title="Applicable tags"
                loading={loading}
                columns={[
                  {
                    id: "name",
                    header: "Name",
                    textWrappable: true,
                    render: (tag) => tag.name,
                  },
                ]}
                data={loading ? [] : applicableTags}
              />
            )}
          </div>
        )}
      </div>
    </RightPane>
  );
};
