import React, { forwardRef, useEffect, useImperativeHandle } from "react";

import { useEnvironment } from "lib/environmentSwitcher/context";

import { useEventsQuery } from "../../queries.graphql";
import { SimpleTable } from "components/SimpleTable";
import { renderDateTimeInUTC } from "lib/time";
import { TableSkeleton } from "components/Table";

import { EmptyState } from "components/EmptyState";
import { TransactionIDCell } from "../TransactionIDCell";
import { CustomerCell } from "../CustomerCell";
import { CSVDownloadButton } from "../CSVDownloadButton";
import { Subtitle } from "design-system";

const NUM_ROWS = 15;

export type EventsTableRef = {
  refetch: () => void;
};

type EventsTableProps = {
  startingAfter: Date;
  endingBefore: Date;
  ingest_aliases?: string[];
  duplicates?: boolean;
  billableMetricIDs?: string[];
  ref?: React.Ref<EventsTableRef>;
};

export const EventsTable: React.FC<EventsTableProps> = forwardRef(
  (props: EventsTableProps, ref) => {
    const { environmentType } = useEnvironment();
    const [currentPage, setCurrentPage] = React.useState(0);
    const [endingBefore, setEndingBefore] = React.useState(props.endingBefore);

    // This is a bit of a hack to work around the fact that when we increase the page number we use the event timestamp
    // of the last event on the previous page as the endingBefore. This works fine when the user is paging forward, but
    // when they go back to a previous page we need to use the timestamp of the first event on the previous page as the
    // endingBefore. In order for us to know this we need to keep a map of (previous) page number -> endingBefore for that page
    const [pageNumberToOffset, setPageNumberToOffset] = React.useState<Date[]>([
      props.endingBefore,
    ]);

    useEffect(() => {
      setCurrentPage(0);
      setEndingBefore(props.endingBefore);
    }, [props.endingBefore, props.startingAfter, props.ingest_aliases]);

    const { data, loading, refetch } = useEventsQuery({
      variables: {
        limit: NUM_ROWS + 1,
        environment_type: environmentType,
        starting_after: props.startingAfter.toISOString(),
        ending_before: endingBefore.toISOString(),
        ingest_aliases: props.ingest_aliases,
        duplicates: props.duplicates,
        billable_metric_ids: props.billableMetricIDs,
      },
    });

    useImperativeHandle(ref, () => ({
      refetch: () => {
        setEndingBefore(props.endingBefore);
        setCurrentPage(0);
        void refetch();
      },
    }));

    const events = (data?.mri_events || []).slice(0, NUM_ROWS);
    const hasMore = (data?.mri_events.length || 0) > NUM_ROWS;

    const header = (
      <div className="mx-0 my-12 flex items-center justify-between">
        <Subtitle>Recent events</Subtitle>
        <CSVDownloadButton
          disabled={loading}
          startingAfter={props.startingAfter}
          endingBefore={props.endingBefore}
          ingest_aliases={props.ingest_aliases}
          duplicates={props.duplicates}
          billableMetricIDs={props.billableMetricIDs}
        />
      </div>
    );

    if (loading) {
      return (
        <div>
          {header}
          <TableSkeleton
            numRows={NUM_ROWS}
            columnNames={[
              "Transaction ID",
              "Customer",
              "Event Type",
              "Timestamp",
            ]}
          />
        </div>
      );
    }

    if (!data?.mri_events.length) {
      return (
        <div>
          {header}
          <EmptyState
            title="No events found"
            subtitle="No events that matched the provided filters were found. Try adjusting the filters or date range."
            icon="searchSm"
          />
        </div>
      );
    }

    const goNextPage = () => {
      const newEndingBefore = new Date(events[events.length - 1].timestamp);
      setCurrentPage(currentPage + 1);
      setEndingBefore(newEndingBefore);
      setPageNumberToOffset([...pageNumberToOffset, newEndingBefore]);
    };

    const goPrevPage = () => {
      const newEndingBefore = pageNumberToOffset[currentPage - 1];
      if (!newEndingBefore) {
        setCurrentPage(0);
        setEndingBefore(props.endingBefore);
      } else {
        setEndingBefore(newEndingBefore);
        setCurrentPage(currentPage - 1);
      }
    };

    return (
      <div>
        {header}
        <SimpleTable
          paginationButtons={[
            {
              page: "prev",
              disabled: currentPage == 0,
              onClick: goPrevPage,
            },
            ...(currentPage > 0
              ? [
                  {
                    page: currentPage,
                    onClick: goPrevPage,
                  },
                ]
              : []),
            {
              page: currentPage + 1,
              onClick: () => {},
              selected: true,
            },
            ...(hasMore
              ? [
                  {
                    page: currentPage + 2,
                    onClick: goNextPage,
                  },
                ]
              : []),
            {
              page: "next",
              onClick: goNextPage,
              disabled: !hasMore,
            },
          ]}
          data={events}
          columns={[
            {
              header: "Transaction ID",
              render: (event) => (
                <TransactionIDCell
                  transaction_id={event.transaction_id}
                  duplicate={event.is_duplicate}
                  event_id={event.id}
                />
              ),
            },
            {
              header: "Customer",
              render: (event) => (
                <CustomerCell ingest_alias={event.customer_id} />
              ),
            },
            {
              header: "Event Type",
              render: (event) => event.event_type,
            },
            {
              header: "Timestamp (UTC)",
              render: (event) => {
                // note: designer wanted this date to be displayed in UTC, but only have the "(UTC)" label on the column title
                const hideUtcLabel = true;
                return renderDateTimeInUTC(
                  new Date(event.timestamp),
                  true,
                  hideUtcLabel,
                );
              },
            },
            {
              header: "Processed At (UTC)",
              render: (event) => {
                if (!event.processedAt) {
                  if (event.is_duplicate) {
                    // duplicate events don't get processed, so they don't have a processedAt date
                    return "Duplicate";
                  }
                  return "-";
                }
                const transitionDate = new Date("2022-09-23T00:00:00.000Z");
                const processedAt = new Date(event.processedAt);
                if (processedAt < transitionDate) {
                  // this value is not valid before Sep 23,2022, because that is when we migrated to druid
                  return "-";
                }
                return renderDateTimeInUTC(
                  new Date(event.processedAt),
                  true, // render as richDateTimeElement
                  true, // hideUtcLabel - designer wanted to display in UTC, but only have the "(UTC)" label on the column title
                );
              },
            },
          ]}
        />
      </div>
    );
  },
);
