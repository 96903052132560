import { dayjs } from "lib/dayjs";

import { BillingFrequencyEnum_Enum } from "types/generated-graphql/__types__";
// This file was basically copied unmodified out of the resolvers repo. It's not imperative that this code
// stays in sync as long as this code can accurately generate example service periods. Not worth making
// a package or network call, and we could refactor this code to be more use case specific if we wanted.
// For now though directly copying was easy enough.

export interface ServicePeriodDates {
  start_date: Date;
  end_date: Date;
}

function getTrialServicePeriod(
  rangeStart: dayjs.Dayjs,
  rangeEnd: dayjs.Dayjs,
  trialLength: number,
): {
  servicePeriod: ServicePeriodDates;
  trialEnd: dayjs.Dayjs;
} {
  const trialEnd = dayjs.min(rangeStart.add(trialLength, "day"), rangeEnd);
  return {
    servicePeriod: {
      start_date: rangeStart.toDate(),
      end_date: trialEnd.toDate(),
    },
    trialEnd,
  };
}

export function monthsPerServicePeriod(
  billingFrequency: BillingFrequencyEnum_Enum,
) {
  switch (billingFrequency) {
    case BillingFrequencyEnum_Enum.SemiMonthly:
      return 0.5;
    case BillingFrequencyEnum_Enum.Quarterly:
      return 3;
    case BillingFrequencyEnum_Enum.SemiAnnual:
      return 6;
    case BillingFrequencyEnum_Enum.Annual:
      return 12;
    default:
      // Monthly
      return 1;
  }
}

export function getServicePeriodDates(
  start: Date,
  end: Date,
  startOnFirst: boolean,
  frequency: "MONTHLY" | "QUARTERLY" | "SEMI_ANNUAL" | "ANNUAL",
  truncateLastPeriod: boolean,
  trialLength?: number,
): ServicePeriodDates[] {
  if (startOnFirst && frequency !== "MONTHLY" && frequency !== "QUARTERLY") {
    throw new Error(
      `Billing frequency ${frequency} is incompatible with StartOnFirst schedule`,
    );
  }

  const dates: ServicePeriodDates[] = [];

  if (end <= start) {
    return dates;
  }
  let rangeStart = dayjs.utc(start);
  const rangeEnd = dayjs.utc(end);

  if (trialLength) {
    const trialServicePeriod = getTrialServicePeriod(
      rangeStart,
      rangeEnd,
      trialLength,
    );
    dates.push(trialServicePeriod.servicePeriod);
    if (trialServicePeriod.trialEnd.isSame(rangeEnd)) {
      return dates;
    }
    rangeStart = trialServicePeriod.trialEnd;
  }

  const monthsPerPeriod = monthsPerServicePeriod(
    frequency as BillingFrequencyEnum_Enum,
  );
  let periodStart = rangeStart;
  let periodEnd = startOnFirst
    ? periodStart.add(monthsPerPeriod, "month").startOf("month")
    : periodStart.add(monthsPerPeriod, "month");

  // Edge case: the range is shorter than a full period
  if (rangeEnd < periodEnd) {
    dates.push({
      start_date: periodStart.toDate(),
      end_date: rangeEnd.toDate(),
    });
    return dates;
  }

  // Incrementing a count keeps us from skewing to different dates in different periods
  // (which would happen if we just added 1 period to the last set of dates each time)
  // E.g. if the billing cycle should start on the 30th every month, we don't want it
  // to switch to the 28th after February.
  let count = monthsPerPeriod;
  while (
    periodEnd <= rangeEnd ||
    (!truncateLastPeriod && periodStart < rangeEnd)
  ) {
    dates.push({
      start_date: periodStart.toDate(),
      end_date: periodEnd.toDate(),
    });

    if (startOnFirst) {
      periodStart = rangeStart.add(count, "month").startOf("month");
      periodEnd = rangeStart
        .add(count + monthsPerPeriod, "month")
        .startOf("month");
    } else {
      periodStart = rangeStart.add(count, "month");
      periodEnd = rangeStart.add(count + monthsPerPeriod, "month");
    }

    count += monthsPerPeriod;
  }

  if (periodStart < rangeEnd && rangeEnd < periodEnd) {
    dates.push({
      start_date: periodStart.toDate(),
      end_date: rangeEnd.toDate(),
    });
  }

  return dates;
}

export function getMonthlyServicePeriodDates(
  start: Date,
  end: Date,
  startOnFirst: boolean,
  truncateLastPeriod: boolean,
  trialLength?: number,
): ServicePeriodDates[] {
  return getServicePeriodDates(
    start,
    end,
    startOnFirst,
    "MONTHLY",
    truncateLastPeriod,
    trialLength,
  );
}

export function getQuarterlyServicePeriodDates(
  start: Date,
  end: Date,
  startOnFirst: boolean,
  truncateLastPeriod: boolean,
  trialLength?: number,
): ServicePeriodDates[] {
  return getServicePeriodDates(
    start,
    end,
    startOnFirst,
    "QUARTERLY",
    truncateLastPeriod,
    trialLength,
  );
}

export function getAnnualServicePeriodDates(
  start: Date,
  end: Date,
  truncateLastPeriod: boolean,
  trialLength?: number,
): ServicePeriodDates[] {
  return getServicePeriodDates(
    start,
    end,
    false,
    "ANNUAL",
    truncateLastPeriod,
    trialLength,
  );
}

export function getSemiAnnualServicePeriodDates(
  start: Date,
  end: Date,
  truncateLastPeriod: boolean,
  trialLength?: number,
): ServicePeriodDates[] {
  return getServicePeriodDates(
    start,
    end,
    false,
    "SEMI_ANNUAL",
    truncateLastPeriod,
    trialLength,
  );
}

export function getSemiMonthlyServicePeriodDates(
  start: Date,
  end: Date,
  truncateLastPeriod: boolean,
  trialLength?: number,
): ServicePeriodDates[] {
  const dates: ServicePeriodDates[] = [];

  if (end <= start) {
    return dates;
  }

  let rangeStart = dayjs.utc(start);
  const rangeEnd = dayjs.utc(end);

  if (trialLength) {
    const trialServicePeriod = getTrialServicePeriod(
      rangeStart,
      rangeEnd,
      trialLength,
    );
    dates.push(trialServicePeriod.servicePeriod);
    if (trialServicePeriod.trialEnd.isSame(rangeEnd)) {
      return dates;
    }
    rangeStart = trialServicePeriod.trialEnd;
  }

  let periodStart = rangeStart;
  while (periodStart < rangeEnd) {
    // The next period end is either the 16th of the current month or the 1st of the next month.
    let periodEnd =
      periodStart.date() < 16
        ? periodStart.date(16)
        : periodStart.add(1, "month").startOf("month");
    if (rangeEnd < periodEnd && truncateLastPeriod) {
      periodEnd = rangeEnd;
    }

    dates.push({
      start_date: periodStart.toDate(),
      end_date: periodEnd.toDate(),
    });
    periodStart = periodEnd;
  }

  return dates;
}
